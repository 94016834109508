import { Alert } from 'antd';
import React, { FC } from 'react';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { useLookupCodeSystemForAppointmentQuery } from '../hooks/useLookupCodeSystemForAppointmentQuery';
import styled from 'styled-components';

interface Props {
  engagementId: string;
}

const Styles = styled.div`
  padding-top: 16px;

  .instruction {
    margin-bottom: 8px;
  }
`;

export const AppointmentInstructions: FC<Props> = ({ engagementId }): JSX.Element => {
  const { data, error, loading } = useLookupCodeSystemForAppointmentQuery({
    variables: { appointmentId: engagementId },
  });

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  return (
    <Styles>
      {data.lookupCodeSystemForAppointment.map(value => (
        <Alert
          key={value.name}
          className="instruction"
          message="Appointment Instructions:"
          description={
            <pre
              style={{ whiteSpace: 'pre-wrap', fontSize: '14px', fontFamily: '"Lucida Console", Monaco, monospace' }}
            >
              {value.valueString}
            </pre>
          }
          type="info"
          showIcon
        />
      ))}
    </Styles>
  );
};
