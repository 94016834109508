import { Col, List, Row } from 'antd';
import React, { FC, useContext } from 'react';
import { Query } from '@apollo/client/react/components';
import { ReferralData } from './GetReferralQuery';
import {
  ListAuditLogsQueryData,
  ListAuditLogsVariables,
  LIST_AUDIT_LOG_QUERY,
  AuditLogEntry,
  RecordContext,
} from './ListAuditLogQuery';
import { AppContext } from '../../../core/contexts/AppContext';
import _ from 'lodash';
import moment from 'moment';
import { ViewerOrganization } from '../../../viewer';

type RecordContextDict = {
  newStatus?: string;
  oldStatus?: string;
  sendingOrganizationId?: string;
};

const AuditLogEntryItem: FC<{
  auditLogEntry: AuditLogEntry;
  currentOrganization: ViewerOrganization | null;
  referral: ReferralData;
}> = ({ auditLogEntry, currentOrganization, referral }): JSX.Element => {
  const context: RecordContextDict = {};
  const recordContext = _.reduce(
    auditLogEntry.recordContext,
    (context: RecordContextDict, item: RecordContext) => {
      if (item.key === 'new_status') {
        context.newStatus = item.value;
      } else if (item.key === 'old_status') {
        context.oldStatus = item.value;
      } else if (item.key === 'sending_organization_id') {
        context.sendingOrganizationId = item.value;
      }
      return context;
    },
    context
  );
  const displayDescription = (entry: AuditLogEntry, context: RecordContextDict): JSX.Element => {
    return (
      <p>
        On
        {' ' +
          moment
            .utc(entry.insertedAt)
            .tz(moment.tz.guess())
            .format('MM/DD/YYYY @ h:mmA z') +
          ' '}
        the status went from {context.oldStatus} to {context.newStatus}.
      </p>
    );
  };

  const displayUser = (
    entry: AuditLogEntry,
    currentOrganization: ViewerOrganization | null,
    context: RecordContextDict,
    referral: ReferralData
  ): JSX.Element | null => {
    if (entry.user.blockitAdmin) {
      return <p>Performed by Blockit Admin</p>;
    } else if (entry.user.name === 'Blockit System') {
      return <p>Performed by Blockit System</p>;
    } else if (
      currentOrganization === null ||
      context.sendingOrganizationId === undefined ||
      context.sendingOrganizationId === null
    ) {
      return null;
    } else if (context.sendingOrganizationId === currentOrganization.id) {
      let receivingOrganization;
      if (referral?.appointment?.profile?.organization) {
        receivingOrganization = referral?.appointment?.profile?.organization?.name;
      } else if (referral?.profileReferral?.profile?.organization) {
        receivingOrganization = referral?.profileReferral?.profile?.organization?.name;
      } else if (referral?.patientReferral?.profile?.organization) {
        receivingOrganization = referral?.patientReferral?.profile?.organization?.name;
      } else {
        receivingOrganization = 'receiving organization';
      }
      return <p>Performed by {receivingOrganization}</p>;
    } else {
      return <p>Performed by {entry.user.name}</p>;
    }
  };

  return (
    <li className="mb-3">
      {displayDescription(auditLogEntry, recordContext)}
      {displayUser(auditLogEntry, currentOrganization, recordContext, referral)}
    </li>
  );
};

export const AuditLogCard: FC<{ referral: ReferralData }> = ({ referral }): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);

  return (
    <Query<ListAuditLogsQueryData, ListAuditLogsVariables>
      query={LIST_AUDIT_LOG_QUERY}
      variables={{
        action: 'referral.status.updated',
        recordId: referral?.id,
        organizationId: currentOrganization?.id as string,
      }}
    >
      {({ loading, data }) => {
        if (loading || !data || !data.listAuditLogs) {
          return null;
        }
        return (
          <>
            <Row>
              <Col span={18}>
                <Row>
                  <Col span={24}>
                    <div>
                      <List
                        className="audit-log-list"
                        itemLayout="horizontal"
                        dataSource={data.listAuditLogs.entries}
                        renderItem={item => (
                          <AuditLogEntryItem
                            auditLogEntry={item}
                            currentOrganization={currentOrganization}
                            referral={referral}
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={6}></Col>
            </Row>
          </>
        );
      }}
    </Query>
  );
};
