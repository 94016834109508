import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import React, { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { ErrorAlert } from './ErrorAlert';

interface Props {
  error: ApolloError;
}

export const InternalServerErrorAlert: FC<Props> = ({ error }): JSX.Element => {
  Sentry.captureMessage(error.message, Severity.Error);

  if (error.networkError) {
    Sentry.captureMessage(
      `${error.networkError.name} - ${error.networkError.message} - ${error.networkError.stack}`,
      Severity.Error
    );
  }

  error.graphQLErrors.map(e => Sentry.captureMessage(e.message, Severity.Error));

  return (
    <ErrorAlert message="Something went wrong!" description="Please try again or contact us if the problem persists." />
  );
};
