import { gql } from '@apollo/client';

export const LIST_AUDIT_LOG_QUERY = gql`
  query listAuditLogs($recordId: ID!, $action: String!, $organizationId: ID!) {
    listAuditLogs(recordId: $recordId, action: $action, organizationId: $organizationId) {
      entries {
        id
        action
        recordId
        organizationId
        recordContext {
          key
          value
        }
        user {
          name
          blockitAdmin
        }
        insertedAt
      }
    }
  }
`;

export interface RecordContext {
  key: string;
  value: string;
}

export interface User {
  name: string;
  blockitAdmin: boolean;
}

export interface AuditLogEntry {
  id: string;
  action: string;
  recordId: string;
  recordContext: [RecordContext];
  user: User;
  insertedAt: string;
  organizationId: string;
}

export interface AuditLogData {
  entries: [AuditLogEntry];
}

export interface ListAuditLogsQueryData {
  listAuditLogs?: AuditLogData;
}

export interface ListAuditLogsVariables {
  recordId: string;
  action: string;
  organizationId: string;
}
