import { gql } from '@apollo/client';

const UpdateAppointment = gql`
  mutation UpdateAppointment($UpdateAppointmentInput: UpdateAppointmentInput!) {
    UpdateAppointment(UpdateAppointmentInput: $UpdateAppointmentInput) {
      id
      status
    }
  }
`;

export { UpdateAppointment };
