import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  required?: boolean;
}

export const InputPatientCity: FC<Props> = ({ form, required }): JSX.Element => (
  <Form.Item label="City">
    {form.getFieldDecorator('city', { rules: [{ required: required, message: "Please enter patient's city." }] })(
      <Input allowClear />
    )}
  </Form.Item>
);
