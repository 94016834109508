import { PageHeader, Spin, Card } from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { AppContext } from '../../core/contexts/AppContext';
import { EditUserForm } from '../components/EditUserForm';
import moment from 'moment';

const GET_USER = gql`
  query getUserWithLocations($id: ID!, $organizationId: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phone
      hasAllLocations(organizationId: $organizationId)
      isAdmin(organizationId: $organizationId)
      isEditor(organizationId: $organizationId)
      userOrganizations(organizationId: $organizationId) {
        organizationId
      }
      locations(organizationId: $organizationId) {
        id
        name
      }
      isActive
      lastLoginAt
    }
    getOrganization(organizationId: $organizationId) {
      id
      locations {
        id
        name
        address1
      }
    }
  }
`;

interface MatchParams {
  userId: string;
}

interface Variables {
  id: string;
  organizationId: string;
}

interface Data {
  getUser: {
    id: string;
    name: string;
    email: string;
    phone: string;
    hasAllLocations: boolean;
    isAdmin: boolean;
    isEditor: boolean;
    isActive: boolean;
    locations: {
      id: string;
      name: string;
    }[];
    lastLoginAt: string;
    userOrganizations: {
      organizationId: string;
    }[];
  };
  getOrganization: {
    id: string;
    locations: {
      id: string;
      name: string;
      address1: string;
    }[];
  };
}

export const EditUser: FC<RouteComponentProps<MatchParams>> = (props): JSX.Element | null => {
  const { currentOrganization } = useContext(AppContext);
  const { loading, data } = useQuery<Data, Variables>(GET_USER, {
    variables: { id: props.match.params.userId, organizationId: currentOrganization?.id || '' },
  });

  const history = useHistory();

  const locations =
    data && data.getOrganization && data.getOrganization.locations ? data.getOrganization.locations : [];

  if (!data || !data?.getUser) return null;

  const user = data.getUser;

  const lastLoginAt = user.lastLoginAt
    ? moment(data.getUser.lastLoginAt)
        .local()
        .format('MM/DD/YYYY @ h:mmA')
    : 'never';

  return (
    <Spin spinning={loading}>
      <PageHeader title="Manage User" subTitle={`Last Login: ${lastLoginAt}`} onBack={() => history.goBack()}>
        <Card>
          <EditUserForm user={user} organizationLocations={locations} />
        </Card>
      </PageHeader>
    </Spin>
  );
};
