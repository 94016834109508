import { Icon } from 'antd';
import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { Spinner } from '.';

const reactModalStyle = {
  content: {
    bottom: '20%',
    top: '0',
    left: '0',
    right: '0',
    margin: 'auto',
    height: '558px',
    width: '600px',
    padding: '0',
    border: 'none',
    background: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(128, 128, 128, 0.80)',
    zIndex: 2,
  },
};

interface Props {
  isOpen: boolean;
  closeModal: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  title: string;
  loading: boolean;
}

/**
 * @deprecated
 */
export const Modal: FC<Props> = props => (
  <ReactModal
    isOpen={props.isOpen}
    // onAfterOpen={this.afterOpenModal}
    onRequestClose={props.closeModal}
    style={reactModalStyle}
  >
    <div className="font-source-sans-pro flex justify-center items-center">
      <div className="card w-full h-auto">
        <div className="w-full card-head flex justify-between">
          <span>{props.title}</span>
          <span onClick={props.closeModal} className="cursor-pointer">
            <Icon type="close" />
          </span>
        </div>
        <div className="p-4 w-full">{props.loading ? <Spinner /> : props.children}</div>
      </div>
    </div>
  </ReactModal>
);
