import React, { FC, useState } from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import PatientCard from '../../Referral/PatientCard';
import { CoverageData, PatientData } from '../../Referral/GetReferralQuery';

interface Props {
  patient: PatientData;
  coverage: CoverageData;
  patientIsPreAuthorized: boolean;
}

const Styles = styled.div`
  .show-more-link {
    color: ${props => props.theme.primaryColor};
    cursor: pointer;
  }
`;

const ShowPatientDetails: FC<Props> = ({ patient, coverage, patientIsPreAuthorized }): JSX.Element => {
  const [show, setShow] = useState(false);

  return (
    <Styles>
      <span className="show-more-link" onClick={() => setShow(true)}>
        Show More
      </span>
      <Drawer title="Patient Information" width={500} onClose={() => setShow(false)} visible={show}>
        <PatientCard patient={patient} coverage={coverage} isPreauthorized={patientIsPreAuthorized} />
      </Drawer>
    </Styles>
  );
};

export default ShowPatientDetails;
