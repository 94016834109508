import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TextArea } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputBoardCertifications: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Board Certifications:">
    {form.getFieldDecorator('boardCertifications', { initialValue, rules: [{ required: false }] })(
      <TextArea allowClear placeholder="Board Certifications" rows={2} />
    )}
  </Form.Item>
);
