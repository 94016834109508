import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  required?: boolean;
}

export const InputPatientMiddleName: FC<Props> = ({ form, required = false }): JSX.Element => (
  <Form.Item label="Patient's Middle Name">
    {form.getFieldDecorator('givenName2', {
      rules: [
        {
          required: required,
          message: "Please enter patient's middle name.",
        },
      ],
    })(<Input allowClear />)}
  </Form.Item>
);
