import React, { FC, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Button, Col, message, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../core/contexts/AppContext';
import { defaultTheme } from '../../../themes/default';

const { Title } = Typography;

const Styles = styled.div`
  min-height: 100vh;
  height: 100%;

  .create-user-title-2 {
    color: ${props => props.theme.blueDarkest};
    font-weight: 400;
    text-align: center;
  }

  .create-user-title-4 {
    color: ${props => props.theme.blueDarkest};
    font-weight: 200;
    text-align: center;
  }
`;

export const FinishStep: FC = (): JSX.Element => {
  const history = useHistory();
  const { currentOrganization } = useContext(AppContext);

  const handleClickNewProfile = (): void => {
    if (currentOrganization) {
      history.push(`/organizations/${currentOrganization?.id}/profiles/new`);
    } else {
      message.error('Something went wrong!  Please refresh to try again or contact us if the problem persists.');
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Title level={2} className="create-user-title-2">
          That&apos;s it!
        </Title>
        <Title level={4} className="create-user-title-4">
          Now, let&apos;s get to work.
        </Title>
        <br />
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <Button block htmlType="button" onClick={handleClickNewProfile} size="large" type="primary">
              Create a Receiving Profile
            </Button>
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
