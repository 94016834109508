import { Card, Col, Input, Row, Typography } from 'antd';
import React, { FC } from 'react';

interface Props {
  consumerPortalLink: string;
  slugOrId: string;
}

export const ConsumerEmbedInstructionsCard: FC<Props> = ({ consumerPortalLink, slugOrId }): JSX.Element => {
  return (
    <Card title="Embed Instructions">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Title level={4} id="title">
            Direct Linking
          </Typography.Title>
          <Typography.Paragraph>
            You can share a direct link to your secure scheduling portal with your patients. This page can also be
            linked to from your website or via email.
          </Typography.Paragraph>
          <Input style={{ fontFamily: 'monospace', fontSize: 12 }} size="small" value={consumerPortalLink} />
        </Col>
        <Col span={24}>
          <Typography.Title level={4} id="title">
            Website Embed
          </Typography.Title>
          <Typography.Paragraph>
            Your secure scheduling portal may also be embedded on your website by adding the following HTML snippet just
            before the closing body tag on your website.
          </Typography.Paragraph>
          <Input.TextArea
            style={{ fontFamily: 'monospace', fontSize: 12 }}
            autoSize={{ minRows: 1, maxRows: 3 }}
            value={`<script type="text/javascript" src="https://app.blockitnow.com/js/embedded.js"></script>`}
          />
          <br />
          <br />
          <Typography.Paragraph>
            Then just add the following HTML snippet where you want the scheduling portal to appear on the page.
          </Typography.Paragraph>
          <Input.TextArea
            style={{ fontFamily: 'monospace', fontSize: 12 }}
            autoSize={{ minRows: 1, maxRows: 3 }}
            value={`<div id="blockit-consumer-embed" data-slug="${slugOrId}"></div>`}
          />
          <br />
          <br />
          <Typography.Paragraph>
            Note that the embedded scheduling portal has a height and width set to 100%. In order to properly fit on
            your page, you will need to style the container div element above accordingly.
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
