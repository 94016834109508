import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { getProfilesQuery } from '../components/CreateGroupProfileModal';

interface Profile {
  id: string;
  displayName: string;
  type: string;
  location: { id: string; name: string };
  organization: { id: string; name: string };
  profileProcedures: {
    id: string;
    procedure: { id: string; name: string; specialty: { id: string; name: string } };
  }[];
}

interface QueryData {
  getProfiles: Profile[];
}

interface QueryVariables {
  regionId: string;
  nameFilter?: string;
}

type Options = QueryHookOptions<QueryData, QueryVariables>;

type Result = QueryResult<QueryData, QueryVariables>;

export const useProfileSearch = (options: Options): Result => {
  return useQuery<QueryData, QueryVariables>(getProfilesQuery, options);
};
