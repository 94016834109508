import React, { FC, useEffect, useState, useContext } from 'react';
import { useGetProfileQuestionnaireQuery } from '../../hooks/queries/useGetProfileQuestionnaireQuery';
import { QuestionnaireFormComponent } from './QuestionnaireForm';
import {
  GetProfileQuestionnaireQueryData,
  useGetConsumerProfileQuestionnaireQuery,
} from '../../hooks/queries/useGetConsumerProfileQuestionnaireQuery';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { Button, Col, Row } from 'antd';
import { useGetConsumerQuestionnaire } from '../../hooks/queries/useGetConsumerQuestionnaire';
import { AppContext } from '../../modules/core/contexts/AppContext';

interface Props {
  profileId: string;
  procedureId: string;
  span?: number;
  form: WrappedFormUtils;
}
export const Questionnaire: FC<Props> = ({ profileId, procedureId, span, form }): JSX.Element | null => {
  const { currentOrganization } = useContext(AppContext);

  const { loading, data } = useGetProfileQuestionnaireQuery({
    variables: {
      profileId: profileId,
      procedureId: procedureId,
      sendingOrganizationId: currentOrganization && currentOrganization.id,
    },
  });
  if (loading || !data?.getProfile.prescreeningQuestionnaire) return null;

  return (
    <>
      {data.getProfile.prescreeningQuestionnaire.map(q => (
        <QuestionnaireFormComponent
          key={q.id}
          questionnaireName={q.title || q.name || 'Questionnaire'}
          questions={q.items}
          span={span || 24}
          form={form}
          questionnaireId={q.id}
        />
      ))}
    </>
  );
};

export const ConsumerQuestionnaireInline: FC<Props> = ({ profileId, procedureId, span, form }): JSX.Element | null => {
  const { loading, data } = useGetConsumerProfileQuestionnaireQuery({
    variables: { profileId: profileId, procedureId: procedureId },
  });
  if (loading || !data || !data.getConsumerSchedulingProfile.prescreeningQuestionnaire) return <></>;

  const questionnaires = data.getConsumerSchedulingProfile.prescreeningQuestionnaire.filter(q => q.type !== 'step');

  return (
    <>
      {questionnaires.map(q => (
        <QuestionnaireFormComponent
          key={q.id}
          questionnaireName={q.title || q.name || 'Questionnaire'}
          questionnaireId={q.id}
          questions={q.items}
          span={span || 24}
          form={form}
        />
      ))}
    </>
  );
};

export const ConsumerQuestionnaireBefore: FC<{
  profileId: string;
  procedureId: string;
  span?: number;
  handleSetQuestions: (
    arg0: { questionnaire: {}[] },
    questionnaireName?: string,
    questionnaireAnswerKeys?: { [key: string]: string }
  ) => void;
}> = ({ profileId, procedureId, span, handleSetQuestions, children }): JSX.Element | null => {
  const { loading, data } = useGetConsumerProfileQuestionnaireQuery({
    variables: { profileId: profileId, procedureId: procedureId },
  });

  const [showChildren, setShowChildren] = useState(false);

  if (loading || !data || !data.getConsumerSchedulingProfile.prescreeningQuestionnaire) return <></>;

  const questionnaires = data.getConsumerSchedulingProfile.prescreeningQuestionnaire.filter(q => q.type === 'step');

  return (
    <>
      {!showChildren && (
        <InnerBeforeForm
          span={span}
          questionnaires={questionnaires}
          handleDone={setShowChildren}
          handleSetQuestions={handleSetQuestions}
        />
      )}
      {showChildren && children}
    </>
  );
};

const InnerBefore: FC<{
  span?: number;
  form: WrappedFormUtils;
  questionnaires: GetProfileQuestionnaireQueryData[];
  handleDone: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetQuestions: (
    arg0: { questionnaire: {}[] },
    questionnaireName?: string,
    questionnaireAnswerKeys?: { [key: string]: string }
  ) => void;
}> = ({ span, questionnaires, form, handleDone, handleSetQuestions }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [dynamicStep, setDynamicStep] = useState('');
  const [current, setCurrent] = useState<GetProfileQuestionnaireQueryData | null>(null);

  if (currentStep >= questionnaires.length) {
    handleDone(true);
    return null;
  }

  const handleStepComplete = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    // sorry
    form.validateFieldsAndScroll((err, values: { questionnaire: {}[] }) => {
      if (!err) {
        let newDynamicStep = '';
        Object.entries(values.questionnaire[0]).forEach(([k, v]) => {
          const c = dynamicStep === '' ? questionnaires[currentStep] : current;

          c?.items.forEach(i => {
            if (i.text === k) {
              i.answerValidators?.forEach(av => {
                if (av.value === v) {
                  newDynamicStep = av.questionnaireId;
                }
              });
            }
          });
        });
        handleSetQuestions(values);

        if (newDynamicStep === '') {
          setDynamicStep('');
          setCurrentStep(currentStep + 1);
        } else {
          setDynamicStep(newDynamicStep);
        }
      }
    });
  };

  const nextQuestionnaire = questionnaires[currentStep];

  return (
    <Form onSubmit={handleStepComplete}>
      {dynamicStep !== '' && (
        <QuestionnaireDynamicComponent
          questionnaireId={dynamicStep}
          span={span || 24}
          form={form}
          setCurrent={setCurrent}
        />
      )}
      {dynamicStep === '' && (
        <QuestionnaireFormComponent questions={nextQuestionnaire.items} span={span || 24} form={form} />
      )}
      <Row>
        <Col span={24}>
          <Button className="book-appointment-button" type="primary" htmlType="submit" block>
            Continue
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

interface DyanmicProps {
  questionnaireId: string;
  form: WrappedFormUtils;
  span: number;
  setCurrent: React.Dispatch<React.SetStateAction<GetProfileQuestionnaireQueryData | null>>;
}

const QuestionnaireDynamicComponent: FC<DyanmicProps> = ({
  questionnaireId,
  span,
  form,
  setCurrent,
}): JSX.Element | null => {
  const { loading, data } = useGetConsumerQuestionnaire({
    variables: { questionnaireId: questionnaireId },
  });

  useEffect(() => {
    if (data?.getQuestionnaire) {
      setCurrent(data.getQuestionnaire);
    }
  }, [data, setCurrent]);

  if (loading || !data?.getQuestionnaire) return null;

  return <QuestionnaireFormComponent questions={data.getQuestionnaire.items} span={span || 24} form={form} />;
};

export const InnerBeforeForm = Form.create<{
  span?: number;
  form: WrappedFormUtils;
  questionnaires: GetProfileQuestionnaireQueryData[];
  handleDone: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetQuestions: (
    arg0: { questionnaire: {}[] },
    questionnaireName?: string,
    questionnaireAnswerKeys?: { [key: string]: string }
  ) => void;
}>({ name: 'Questionnaire' })(InnerBefore);
