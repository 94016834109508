import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Row, Col } from 'antd';
import AntDesignStyleOverrides from '../../core/components/App/AntDesignStyleOverrides';
import { defaultTheme } from '../../../themes/default';

const Styles = styled.div`
  min-height: 100vh;
  height: 100%;

  .blockit-logo {
    margin-bottom: 2rem;
    width: 100%;
  }

  .landing-body {
    background-color: ${props => props.theme.primaryColor};
    height: 100%;
    min-height: 100%;
  }

  .left-panel {
    background-color: ${props => props.theme.primaryColor};
    padding: 2rem 1rem 2rem 1rem;
  }

  .right-panel {
    background-color: ${props => props.theme.white};
    height: 100%;
    min-height: 100vh;
    padding: 4rem 1rem 4rem 1rem;
  }

  .splash-paragraph {
    color: ${props => props.theme.white};
    font-size: 18px;
  }

  .splash-title {
    color: ${props => props.theme.white};
    text-align: center;
  }

  @media only screen and (min-width: 768px) {
    .left-panel {
      min-height: 100vh;
    }
  }
`;

export const LandingLayout: FC = ({ children }): JSX.Element => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <AntDesignStyleOverrides>
        <Styles>
          <Row type="flex" className="landing-body">
            <Col xs={24} sm={24} md={10} className="left-panel">
              <Row type="flex" justify="center" align="middle">
                <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                  <img src="/images/logo.png" alt="Blockit" className="blockit-logo" />
                </Col>
              </Row>

              <Row type="flex" justify="center" align="middle">
                <Col xs={0} sm={0} md={18} lg={18} xl={20} style={{ paddingTop: '2rem' }}>
                  <img src="/images/signup-hero.png" alt="banner" />
                </Col>
              </Row>

              <Row type="flex" justify="center" align="middle">
                <Col xs={0} sm={0} md={24} lg={24} xl={20} style={{ paddingTop: '4rem' }}>
                  <h1 className="splash-title">Signup with Blockit Today!</h1>
                  <br />
                  <p className="splash-paragraph">
                    Select the configuration you need to attract, engage, and retain the right patients so you can care
                    for 50% more patients in half the time.
                    <br />
                    <br />
                    Rest easy, Blockit is a HIPAA-compliant environment.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={14} className="right-panel">
              {children}
            </Col>
          </Row>
        </Styles>
      </AntDesignStyleOverrides>
    </ThemeProvider>
  );
};
