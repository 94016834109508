import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

const EmptyButton = () => (
  <Button block className="time-btn dash-btn">
    —
  </Button>
);

const MIN_NUMBER_OF_TIMES = 4;

const SlotsForColumn = ({ times, handleSelectTimeSlot, profile }) => {
  const [showMore, setShowMore] = useState(false);
  const maxTimes = times.length > MIN_NUMBER_OF_TIMES && showMore ? times.length : MIN_NUMBER_OF_TIMES;

  return (
    <div>
      {_.fill(Array(maxTimes), 0).map((_item, index) => {
        if (times[index]) {
          return (
            <Button
              block
              type="primary"
              key={index}
              className="time-btn"
              onClick={() => handleSelectTimeSlot({ timeSlot: times[index], profile: profile })}
            >
              {moment(times[index].start).format('h:mma')}
            </Button>
          );
        } else {
          return <EmptyButton key={index} />;
        }
      })}
      {times.length > MIN_NUMBER_OF_TIMES && !showMore ? (
        <Button type="primary" block key="show-more" className="time-btn" onClick={() => setShowMore(true)}>
          More
        </Button>
      ) : null}
    </div>
  );
};

export default SlotsForColumn;
