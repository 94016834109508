import React, { FC } from 'react';
import { History } from 'history';
import { PageHeader } from 'antd';

interface Props {
  history: History;
  locationName: string;
  name: string;
  organizationName: string;
}

export const DirectoryProfileHeader: FC<Props> = ({ history, locationName, name, organizationName }): JSX.Element => (
  <PageHeader title={name} onBack={() => history.goBack()} subTitle={`${organizationName} - ${locationName}`} />
);
