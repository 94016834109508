import React, { FC } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CheckboxTos } from './CheckboxTos';
import { InputUserEmail } from './InputUserEmail';
import { InputUserName } from './InputUserName';
import { InputUserPasswords } from './InputUserPasswords';

export interface CreateUserFormData {
  name: string;
  email: string;
  password: string;
}

interface Props extends FormComponentProps {
  disabled: boolean;
  form: WrappedFormUtils<CreateUserFormData>;
  onSubmit: (values: CreateUserFormData) => void;
}

const CreateUserFormComponent: FC<Props> = ({ disabled, form, onSubmit }): JSX.Element => {
  const handleCreateUserFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Form onSubmit={handleCreateUserFormSubmit}>
      <Row type="flex" justify="center">
        <Col xs={24} md={12}>
          <InputUserName form={form} />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xs={24} md={12}>
          <InputUserEmail form={form} />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xs={24} md={12}>
          <InputUserPasswords form={form} />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xs={24} md={12}>
          <CheckboxTos form={form} />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xs={24} md={12}>
          <Button block disabled={disabled} htmlType="submit" size="large" type="primary">
            Create Account
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const CreateUserForm = Form.create<Props>()(CreateUserFormComponent);
