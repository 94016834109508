import { Icon } from 'antd';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps<{ organizationId: string }> {
  row: {
    id: string;
  };
}

class ProviderCrudActionsComponent extends Component<Props, {}> {
  render(): JSX.Element {
    const { row } = this.props;

    return (
      <div>
        <button
          className="btn-icon text-grey-darker hover:text-white hover:bg-grey p-2 mx-1 rounded"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.props.history.push(
              `/organizations/${this.props.match.params.organizationId}/providers/${row.id}/edit`
            );
          }}
        >
          <Icon type="edit" />
        </button>
      </div>
    );
  }
}

export const ProviderCrudActions = withRouter(ProviderCrudActionsComponent);
