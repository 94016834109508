import { Icon, Input, Pagination, Select, Spin } from 'antd';
import { gql } from '@apollo/client';
import debounce from 'lodash/debounce';
import React, { ChangeEvent, FC, useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Spinner } from '../../../components';
import { AppContext } from '../../core/contexts/AppContext';
import { DirectoryProfiles } from '../components/DirectoryProfiles';
import { DirectoryProfilesHeader } from '../components/DirectoryProfilesHeader';
import { DirectoryContext, PublicProfile } from '../containers/DirectoryContext';
import { useQueryParam, StringParam } from 'use-query-params';

const GET_PAGINATED_PROFILES = gql`
  query GetDirectoryProfiles(
    $organizationId: ID!
    $pageNumber: Int
    $pageSize: Int
    $specialtyId: String
    $nameFilter: String
    $regionId: String
  ) {
    GetDirectoryProfiles(
      organizationId: $organizationId
      pageNumber: $pageNumber
      pageSize: $pageSize
      specialtyId: $specialtyId
      nameFilter: $nameFilter
      regionId: $regionId
    )
      @connection(
        key: "PaginatedDirectoryProfiles"
        filter: ["organizationId", "pageNumber", "pageSize", "specialtyId", "nameFilter", "regionId", "specialtyId"]
      ) {
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        id
        displayName
        profileImgUrl
        specialty
        location {
          id
          name
          address1
          address2
          city
          state
          postalCode
        }
        organization {
          id
          name
        }
        specialties {
          id
          name
        }
      }
    }
  }
`;

const { Option } = Select;
const Styles = styled.div`
  .profile-card-col {
    margin-top: 16px;
  }

  .profile-filters {
    display: flex;
    justify-content: left;
  }

  .profile-pagination {
    margin-top: 16px;
  }
`;

interface PaginatedPublicProfiles {
  entries: PublicProfile[];
  pageNumber: number;
  pageSize: number;
  totalEntries: number;
  totalPages: number;
}

interface Data {
  GetDirectoryProfiles: PaginatedPublicProfiles;
}

interface Variables {
  organizationId: string;
  pageNumber: number;
  pageSize: number;
  nameFilter: string;
  specialtyId: string;
  regionId: string;
}

export const DirectoryProfilesPage: FC<RouteComponentProps> = (): JSX.Element => {
  const {
    directory: { organizations, specialties },
    profileNameFilter,
    selectedOrganizationId,
    selectedSpecialtyId,
    setOrganizationNameFilter,
    setProfileNameFilter,
    setSelectedOrganizationId,
    setSelectedSpecialtyId,
  } = useContext(DirectoryContext);
  const { currentOrganization } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [queryStringNameFilter, setQueryStringNameFilter] = useQueryParam('nameFilter', StringParam);

  useEffect(() => {
    if (queryStringNameFilter && queryStringNameFilter !== profileNameFilter) {
      setProfileNameFilter(queryStringNameFilter);
    }
  }, [queryStringNameFilter, profileNameFilter, setProfileNameFilter]);

  const { data, error, loading } = useQuery<Data, Variables>(GET_PAGINATED_PROFILES, {
    variables: {
      organizationId: selectedOrganizationId,
      pageNumber: page,
      pageSize: 10,
      nameFilter: profileNameFilter,
      specialtyId: selectedSpecialtyId,
      regionId: currentOrganization?.region?.id || '',
    },
  });

  if (!data && loading) return <Spinner />;
  if (error || !data) return <div>Error</div>;

  const handleSelectOrganization = (value: string): void => {
    setOrganizationNameFilter('');
    setSelectedOrganizationId(value);
    setPage(1);
  };

  const handleSelectSpecialty = (value: string): void => {
    setSelectedSpecialtyId(value);
    setPage(1);
  };

  const handleUpdateNameFilter = (value: string): void => {
    setQueryStringNameFilter(value);
    setProfileNameFilter(value);
    setPage(1);
  };

  const debounceFilterUpdate = debounce(handleUpdateNameFilter, 500);

  const handleInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    debounceFilterUpdate(value);
  };

  const handlePaginate = (newPage: number, newPageSize: number | undefined): void => {
    if (typeof newPageSize === 'undefined') {
      setPage(1);
      return;
    }

    setPage(newPage);
  };

  return (
    <Styles>
      <DirectoryProfilesHeader />
      <div className="profile-filters">
        <Input
          defaultValue={profileNameFilter}
          onChange={handleInputChange}
          placeholder="Search for profiles..."
          size="large"
          style={{ width: 300 }}
          suffix={<Icon type="search" />}
        />
        <Select<string>
          defaultValue={selectedOrganizationId ? selectedOrganizationId : ''}
          onSelect={handleSelectOrganization}
          size="large"
          style={{ paddingLeft: '16px', width: 300 }}
        >
          <Option value={''}>All Organizations</Option>
          {organizations.map(o => (
            <Option key={o.id} value={o.id}>
              {o.name}
            </Option>
          ))}
        </Select>
        <Select<string>
          defaultValue={selectedSpecialtyId ? selectedSpecialtyId : ''}
          onSelect={handleSelectSpecialty}
          size="large"
          style={{ paddingLeft: '16px', width: 300 }}
        >
          <Option value={''}>All Specialties</Option>
          {specialties.map(s => (
            <Option key={s.id} value={s.id}>
              {s.name}
            </Option>
          ))}
        </Select>
      </div>
      {loading ? (
        <Spin>
          <DirectoryProfiles profiles={data.GetDirectoryProfiles.entries} />
        </Spin>
      ) : (
        <>
          <DirectoryProfiles profiles={data.GetDirectoryProfiles.entries} />
        </>
      )}
      {/* TODO UPDATE THE VALUES */}
      <Pagination
        current={data.GetDirectoryProfiles.pageNumber}
        className="profile-pagination"
        pageSize={data.GetDirectoryProfiles.pageSize}
        total={data.GetDirectoryProfiles.totalEntries}
        hideOnSinglePage={true}
        onChange={handlePaginate}
      />
    </Styles>
  );
};
