import { Button, Col, Form, message, Row, Select } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import { Spinner } from '../../../../components';
import { GetSlotDuration, UpsertSlotDuration } from '../../../../GraphQL';
import { getProfileQuery } from '../../../profiles/hooks/useGetProfileQuery';

const { Option } = Select;

const slotDurationOptions = [3, 5, 6, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120];

class SlotDurationDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorMessage: null,
      durationInMins: null,
      saved: false,
    };
  }

  handleChange = async val => {
    this.setState({ durationInMins: parseInt(val, 10), saved: false });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { resetFields } = this.props.form;

    if (this.state.durationInMins === null) return;

    this.setState({ loading: true });
    try {
      await this.props.client.mutate({
        mutation: UpsertSlotDuration,
        variables: {
          UpsertSlotDurationInput: {
            scheduleId: this.props.scheduleId,
            durationInMins: parseInt(this.state.durationInMins, 10),
          },
        },
        refetchQueries: [
          {
            query: GetSlotDuration,
            variables: { scheduleId: this.props.scheduleId },
          },
          {
            query: getProfileQuery,
            variables: { id: this.props.profileId },
          },
        ],
      });

      resetFields();
      message.success('Slot Duration Updated');
      this.setState({ loading: false, saved: true });
    } catch (e) {
      message.warn('An error occured. Please wait a moment and try again.');
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Query query={GetSlotDuration} variables={{ scheduleId: this.props.scheduleId }}>
        {({ loading, error, data }) => {
          if (loading) return <Spinner size="2x" padding={0} />;
          if (error) return <div>Error</div>;

          const { getFieldDecorator } = this.props.form;
          const {
            GetSlotDuration: { durationInMins },
          } = data;

          return (
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col span={16}>
                  <Form.Item>
                    {getFieldDecorator('duration', {
                      validateTrigger: 'onChange',
                      initialValue: durationInMins,
                      rules: [{ required: true, message: 'required' }],
                    })(
                      <Select
                        placeholder="Select Slot Duration"
                        block
                        size="small"
                        onChange={this.handleChange}
                        disabled={!this.props.authorizedToEdit}
                      >
                        {slotDurationOptions.map(duration => (
                          <Option key={`duration-${duration}`} value={duration}>
                            {duration} mins
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={6} push={2}>
                  {this.props.authorizedToEdit ? (
                    <Button size="small" type="secondary" htmlType="submit">
                      {this.state.saved ? 'Saved' : 'Update'}
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Form>
          );
        }}
      </Query>
    );
  }
}

export default _.flowRight(withApollo, Form.create({ name: 'Slot Interval Form' }))(SlotDurationDropDown);
