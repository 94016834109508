import styled from 'styled-components';
import { DateTime, Interval } from 'luxon';

const navbarHeight = '(50px + 88px)';
const hourInPixels = 40;

const daysMap = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

const convertDayOfWeekToNum = day => daysMap[day.toLowerCase()];
const convertMinutesToPercent = mins => mins / 60;
const calcTop = ({ start }) => {
  const [startHour, startMin] = start.split(':');
  return `calc((${startHour - 5} * ${hourInPixels}px) + (${hourInPixels}px * ${convertMinutesToPercent(startMin)}))`;
};

const calcLeft = ({ weekDay }) => `calc(((100% / 8) * ${convertDayOfWeekToNum(weekDay)}))`;
const calcHeight = ({ start, end }) => {
  const duration = Interval.fromDateTimes(DateTime.fromISO(start), DateTime.fromISO(end))
    .toDuration(['hours', 'minutes'])
    .toObject();
  const heightMultiplier = duration.hours + convertMinutesToPercent(duration.minutes);
  return `calc(${hourInPixels}px * ${heightMultiplier})`;
};

export const CalendarAndLabelsWrapper = styled.div`
  min-height: calc(76vh - ${navbarHeight});
`;

export const CalendarWrapper = styled.div`
  position: relative;

  margin-right: 1em;
  margin-left: 1em;
  color: #8e8e8e;
`;

export const Table = styled.table`
  width: calc(100% - 1px);
  border-collapse: collapse;

  th {
    height: 40px;
  }

  tr {
    height: 40px;
  }

  td {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    outline: none;
    padding: 5px;
    width: 12.5%;
    text-align: center;
  }
`;

export const TableOverlay = styled.div`
  position: absolute;
  padding: 0.5em;
  background-color: #2783c450;
  width: calc(100% / 8);
  top: ${props => calcTop(props)};
  left: ${props => calcLeft(props)};
  height: ${props => calcHeight(props)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  color: #666;
`;

export const DaysOfWeekLabels = styled.div`
  width: calc(100% - 2em);
  height: ${hourInPixels / 2}px;
  margin-right: 1em;
  margin-left: 1em;
  margin-bottom: 1em;
  color: #8e8e8e;
  display: flex;
`;

export const DayOfWeekLabel = styled.div`
  width: calc(100% / 8);
  text-align: center;
  flex: 1;
  font-weight: bold;
  font-size: 16px;
`;
