import { PageHeader, Spin } from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../core/contexts/AppContext';
import { UserForm } from '../components/UserForm';

interface Data {
  getOrganization: {
    id: string;
    locations: {
      id: string;
      name: string;
      address1: string;
    }[];
  };
}

interface Variables {
  organizationId: string;
}

const ORGANIZATION_LOCATIONS_QUERY = gql`
  query($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      locations {
        id
        name
        address1
      }
    }
  }
`;

export const AddUser: FC<{}> = () => {
  const { currentOrganization } = useContext(AppContext);

  const { loading, data } = useQuery<Data, Variables>(ORGANIZATION_LOCATIONS_QUERY, {
    variables: { organizationId: currentOrganization?.id || '' },
  });

  const history = useHistory();

  const organizationLocations =
    data && data.getOrganization && data.getOrganization.locations ? data.getOrganization.locations : [];

  return (
    <Spin spinning={loading}>
      <PageHeader title="Add a new user" onBack={() => history.goBack()}>
        <UserForm organizationLocations={organizationLocations} />
      </PageHeader>
    </Spin>
  );
};
