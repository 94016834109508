import * as Sentry from '@sentry/browser';
import React, { FC, useState } from 'react';
import { UpdateReferralStatus } from '../../../../GraphQL';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import { deletePaginatedCacheItems } from '../../../../helpers/cache';
import { useMutation } from '@apollo/client';
import { ReferralData, QUERY } from './GetReferralQuery';
import { useAppContext } from '../../../../hooks';
import { LIST_AUDIT_LOG_QUERY } from './ListAuditLogQuery';

const { Option } = Select;

interface UpdateReferralStatusMutationVars {
  referralStatusInput: {
    id: string;
    status: string;
    rejectReason: string;
    rejectNote: string;
  };
}

interface UpdateReferralStatusMutationData {
  UpdateReferralStatus: {
    id: string;
    status: string;
  };
}

export const RejectReferral: FC<{
  referral?: ReferralData;
  onComplete: () => void;
}> = ({ referral, onComplete }): JSX.Element | null => {
  const { currentOrganization } = useAppContext();

  const reasons = currentOrganization?.referralRejectionReasons;

  let defaultReason = 'patient_declined_referral';
  if (reasons && reasons.length) {
    if (reasons[0].value) {
      defaultReason = reasons[0].value;
    } else {
      defaultReason = reasons[0].key;
    }
  }

  const [visible, setVisible] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(defaultReason);
  const [reasonText, setReasonText] = useState<string | null>(null);

  const [updateReferralStatusMutation, { error }] = useMutation<
    UpdateReferralStatusMutationData,
    UpdateReferralStatusMutationVars
  >(UpdateReferralStatus);

  if (error) {
    Sentry.captureException(error);
    message.error('Error rejecting referral');
  }

  const nextStatuses = referral?.nextStatuses as Array<string>;
  if (!referral || !nextStatuses.includes('rejected')) {
    return null;
  }

  const onOk = (): void => {
    setVisible(false);

    updateReferralStatusMutation({
      variables: {
        referralStatusInput: {
          id: referral.id,
          status: 'rejected',
          rejectReason: reason,
          rejectNote: reasonText || reason,
        },
      },
      refetchQueries: [
        {
          query: QUERY,
          variables: {
            id: referral.id,
            organizationId: currentOrganization?.id as string,
          },
        },
        {
          query: LIST_AUDIT_LOG_QUERY,
          variables: {
            action: 'referral.status.updated',
            recordId: referral?.id,
            organizationId: currentOrganization?.id as string,
          },
        },
      ],
      update: cache => {
        message.success('Referral has been rejected');
        deletePaginatedCacheItems(cache, 'ListReferrals');
        deletePaginatedCacheItems(cache, 'ListAppointments');
        onComplete();
      },
    });
  };

  return (
    <>
      <Button className="mt-1" onClick={() => setVisible(true)}>
        Reject Referral
      </Button>
      <Modal title="Reject Referral" visible={visible} onOk={() => onOk()} onCancel={() => setVisible(false)}>
        <Form>
          <Form.Item label="Reason for rejection">
            {reasons && reasons.length ? (
              <Select<string>
                placeholder="Choose reason"
                defaultValue={reasons[0].key}
                size="small"
                onChange={val => setReason(val)}
              >
                {reasons.map(r => (
                  <Select.Option key={r.key} value={r.value || r.key}>
                    {r.key}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select<string>
                size="small"
                defaultValue={'patient_declined_referral'}
                placeholder="Choose reason"
                style={{}}
                onChange={val => setReason(val)}
              >
                <Option value="patient_declined_referral" key="Patient Declined Referral">
                  Patient Declined Referral
                </Option>
                <Option value="patient_scheduled_with_other_provider" key="Patient Scheduled With Other Provider">
                  Patient Scheduled With Other Provider
                </Option>
                <Option
                  value="sub_specialty_service_required_for_patient"
                  key="Sub Specialty Service Required For Patient"
                >
                  Sub Specialty Service Required For Patient
                </Option>
                <Option value="out_of_network_provider" key="Out of NetWork Provider">
                  Out of NetWork Provider
                </Option>
                <Option value="requires_pcp_referral" key="Requires PCP Referral">
                  Requires PCP Referral
                </Option>
                <Option value="other" key="Other">
                  Other
                </Option>
              </Select>
            )}
            {reason === 'other' && (
              <Input
                maxLength={128}
                onChange={e => setReasonText(e.target.value)}
                placeholder="Additional detail"
                size="small"
              ></Input>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
