import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Option, Select } from '../../../system/components/Select';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

const timeZones = [
  { value: 'AMERICA_CHICAGO', label: 'CST (America/Chicago)' },
  { value: 'AMERICA_DETROIT', label: 'EST (America/Detroit)' },
  { value: 'AMERICA_NEW_YORK', label: 'EST (America/New York)' },
  { value: 'AMERICA_DENVER', label: 'MST (America/Denver)' },
  { value: 'AMERICA_PHOENIX', label: 'MST (America/Phoenix)' },
  { value: 'AMERICA_LOS_ANGELES', label: 'PST (America/Los Angeles)' },
  { value: 'AMERICA_ANCHORAGE', label: 'AKST (America/Anchorage)' },
  { value: 'AMERICA_CURACAO', label: 'AST (America/Curacao)' },
];

export const SelectTimeZone: FC<Props> = ({ form, initialValue }): JSX.Element => {
  return (
    <Form.Item label="Time Zone:">
      {form.getFieldDecorator('timeZone', {
        initialValue: initialValue,
        rules: [{ message: 'Please select the location time zone.', required: true }],
      })(
        <Select allowClear placeholder="Time Zone">
          {timeZones.map(tz => (
            <Option value={tz.value} key={tz.value}>
              {tz.label}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};
