import React, { FC } from 'react';
import { Button, Row, Col, Typography, Icon } from 'antd';

interface Props {
  pelitas?: boolean;
}

const { Title, Paragraph } = Typography;

export const SignupError: FC<Props> = ({ pelitas }) => {
  return (
    <div style={{ paddingTop: '4rem' }}>
      <Row type="flex" justify="center">
        <Col span={24} style={{ textAlign: 'center' }}>
          <Icon
            type="exclamation-circle"
            theme="twoTone"
            twoToneColor="#D92929"
            style={{ fontSize: 72, textAlign: 'center' }}
          />
        </Col>
      </Row>
      <br />
      <Title level={2} id="title">
        An error occurred during setup
      </Title>
      <Paragraph style={{ textAlign: 'center' }}>
        Make sure you do not have an existing Blockit account with the same email address.
      </Paragraph>
      <br />
      <br />
      <Row type="flex" justify="center" align="middle">
        <Col xs={12} style={{ textAlign: 'center' }}>
          <Button type="primary" shape="round" size="large" href={`${pelitas ? '/covid-pelitas' : '/covid-signup'}`}>
            Go back and try again
          </Button>
        </Col>
      </Row>
    </div>
  );
};
