import { useMutation, MutationTuple } from '@apollo/client';
import { gql } from '@apollo/client';

interface BootstrapData {
  bootstrapOrganization: {
    id: string;
  };
}

interface BootstrapVariables {
  bootstrapOrganizationInput: {
    name: string;
    email: string;
    password: string;
    organizationName: string;
    isCovid?: boolean;
    isFree?: boolean;
  };
}

type BootstrapMutationResult = MutationTuple<BootstrapData, BootstrapVariables>;

const BootstrapOrganizationMutation = gql`
  mutation BootstrapOrganization($bootstrapOrganizationInput: BootstrapOrganizationInput) {
    bootstrapOrganization(bootstrapOrganizationInput: $bootstrapOrganizationInput) {
      id
    }
  }
`;

export const useBootstrapOrganizationMutation = (): BootstrapMutationResult => {
  return useMutation<BootstrapData, BootstrapVariables>(BootstrapOrganizationMutation);
};
