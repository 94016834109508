import React, { FC } from 'react';
import { Icon, Tag, Tooltip } from 'antd';
import { capitalize } from '../../../helpers';
interface ColorTagInterface {
  status: string;
}

export const ReferralTag: FC<ColorTagInterface> = ({ status }): JSX.Element | null => {
  switch (status.toLowerCase()) {
    case 'scheduled':
      return (
        <Tooltip
          title="Appointment has been booked for the patient at the referred healthcare provider or facility"
          placement="right"
        >
          <Tag color="blue">{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'accepted':
      return (
        <Tooltip title="Receipt of sent referral has been acknowledged by referred provider" placement="right">
          <Tag color="yellow">{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'pending':
      return (
        <Tooltip title="Referral is waiting to be accepted" placement="right">
          <Tag color="orange">{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'patient_seen':
      return (
        <Tooltip
          title="The referral process was successful, the appointment was fulfilled, and the patient has been seen by the referred healthcare provider or facility"
          placement="right"
        >
          <Tag color="green">Patient Seen</Tag>
        </Tooltip>
      );
    case 'rejected':
      return (
        <Tooltip title="Unable to receive patient referral, rejected" placement="right">
          <Tag color="red">Rejected</Tag>
        </Tooltip>
      );
    case 'failed':
      return (
        <Tooltip title="The EMR failed to book the appointment" placement="right">
          <Tag color="red">Failed</Tag>
        </Tooltip>
      );
    case 'noshow':
      return (
        <Tooltip title="Patient failed to show" placement="right">
          <Tag color="red">No Show</Tag>
        </Tooltip>
      );
    case 'completed':
      return (
        <Tooltip title="Patient has been seen for referral" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'arrived':
      return (
        <Tooltip title="Patient has arrived and checked-in" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'checked_in':
      return (
        <Tooltip title="Patient has arrived (this could actually not include arrived)" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'proposed':
      return (
        <Tooltip
          title="Status of an appointment when waiting for EMR to accept or reject the appointment for booking"
          placement="right"
        >
          <Tag color="orange">
            Booking
            <Icon type="loading-3-quarters" spin style={{ marginLeft: '5px' }} />
          </Tag>
        </Tooltip>
      );
    case 'draft':
      return (
        <Tooltip
          title="The referral is being created and the progress can be saved while working on a referral"
          placement="right"
        >
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'fulfilled':
      return (
        <Tooltip title="Patient has been seen" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'sent_to_patient':
      return (
        <Tooltip title="Appointment information has been sent to patient for scheduling" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'sent_to_provider':
      return (
        <Tooltip title="Appointment information has been sent to provider for scheduling" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'cancelled':
      return (
        <Tooltip title="Appointment has been canceled" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'booking':
      return (
        <Tooltip title="Appointment is in progress" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'booked':
      return (
        <Tooltip title="Appointment scheduled successfully" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );
    case 'rescheduled':
      return (
        <Tooltip title="Patient has been rescheduled to a different time and/or provider" placement="right">
          <Tag>{capitalize(status)}</Tag>
        </Tooltip>
      );

    case undefined:
    case null:
      return null;
    default:
      return <Tag>{capitalize(status)}</Tag>;
  }
};
