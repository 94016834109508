import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getLanguagesQuery = gql`
  query GetLanguagesQuery {
    getLanguages {
      code
      name
    }
  }
`;

interface Language {
  code: string;
  name: string;
}

interface Data {
  getLanguages: Language[];
}

type Options = QueryHookOptions<Data>;

type Result = QueryResult<Data>;

export const useGetLanguagesQuery = (options: Options): Result => {
  return useQuery<Data>(getLanguagesQuery, options);
};
