import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getProcedureQuery = gql`
  query GetProcedure($procedureId: ID!) {
    getProcedure(procedureId: $procedureId) {
      id
      name
      specialty {
        id
        name
      }
    }
  }
`;

export interface Procedure {
  id: string;
  name: string;
  specialty: Specialty;
}

export interface Specialty {
  id: string;
  name: string;
  procedures: Procedure[];
}

interface Data {
  getProcedure: Procedure;
}

interface Variables {
  procedureId: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetProcedureQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getProcedureQuery, options);
};
