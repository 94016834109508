const getStripePublishableKey = (): string => {
  let stripePublishableKey;

  switch (window.location.origin) {
    case 'https://app.blockitnow.com':
      stripePublishableKey = 'pk_live_cbi8kQETcOSSASSArOYExk8b00Eeace7mg';
      break;
    case 'https://app.staging.blockit.cloud':
      stripePublishableKey = 'pk_test_C57gilUM3wyBPdI51lTsFlVn0036yHQSwt';
      break;
    case window.location.origin.match(/^https:\/\/app\..*\.staging\.blockit\.cloud/)?.input:
      stripePublishableKey = 'pk_test_C57gilUM3wyBPdI51lTsFlVn0036yHQSwt';
      break;
    default:
      stripePublishableKey = '';
  }

  return stripePublishableKey;
};

const config = {
  enableUserTracking: process.env.NODE_ENV === 'production',
  endpoint: process.env.REACT_APP_API_URI || window.location.origin.replace('app', 'api'),
  // endpoint: 'https://api.blockitnow.com',
  environment: window.location.origin.match('blockitnow.com') ? 'prod' : 'staging',
  intercomAppId: 's0ncud54',
  zendeskKey: 'b4a10e72-2fe3-40c5-a9d0-2a7006f20b4f',
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || getStripePublishableKey(),
};

export default config;
