import { useMutation, MutationTuple } from '@apollo/client';
import { gql } from '@apollo/client';

interface SuperuserUpdateOrganizationData {
  UpdateOrganization: Organization;
}

interface SuperuserUpdateOrganizationVariables {
  organizationId: string;
  superuserUpdateOrganizationInput: {
    featureFlagIds?: string[];
    regionId?: string;
    isActive?: boolean;
  };
}

interface Organization {
  id: string;
  name: string;
  profileImgUrl: string;
  slug: string | null;
  isActive: boolean;
  featureFlags: FeatureFlags[];
  region: Region;
}

interface Region {
  id: string;
  name: string;
}

interface FeatureFlags {
  id: string;
  name: string;
}

const SuperuserUpdateOrganizationMutation = gql`
  mutation SuperuserUpdateOrganization(
    $organizationId: ID!
    $superuserUpdateOrganizationInput: SuperuserUpdateOrganizationInput!
  ) {
    superuserUpdateOrganization(
      organizationId: $organizationId
      superuserUpdateOrganizationInput: $superuserUpdateOrganizationInput
    ) {
      id
      isActive
      featureFlags {
        id
        name
      }
      region {
        id
        name
      }
    }
  }
`;

type SuperuserUpdateOrganizationMutationResult = MutationTuple<
  SuperuserUpdateOrganizationData,
  SuperuserUpdateOrganizationVariables
>;

export const useSuperuserUpdateOrganizationMutation = (): SuperuserUpdateOrganizationMutationResult => {
  return useMutation<SuperuserUpdateOrganizationData, SuperuserUpdateOrganizationVariables>(
    SuperuserUpdateOrganizationMutation
  );
};
