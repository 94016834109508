import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { useCreateBillingPortalSessionQuery } from '../hooks/useCreateBillingPortalSessionQuery';
import { FreeOrganizationProfilesCount } from '../../profiles/components/FreeOrganizationProfilesCount';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  organizationId: string;
}

export const BillingSettingsCard: FC<Props> = ({ organizationId }): JSX.Element | null => {
  const { data, error, loading } = useCreateBillingPortalSessionQuery({
    variables: { organizationId: organizationId },
  });

  const { currentOrganization } = useAppContext();

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  const { createBillingPortalSession } = data;

  return (
    <Row gutter={[24, 24]}>
      <Col span={18} push={1}>
        {currentOrganization && (
          <FreeOrganizationProfilesCount
            organizationId={currentOrganization.id}
            isFree={currentOrganization.isFree}
            freePlanConfiguration={currentOrganization.freePlanConfiguration}
          />
        )}
        {createBillingPortalSession?.url ? (
          <Button htmlType="button" href={createBillingPortalSession.url} target="_blank" type="primary">
            View Invoices and Manage Payment Settings
          </Button>
        ) : (
          <>{!currentOrganization?.isFree && <p>Please contact Blockit Support for Billing Issues</p>}</>
        )}
      </Col>
    </Row>
  );
};
