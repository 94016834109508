import React, { FC, useContext, useState } from 'react';
import ReferralsTable from '../components/ReferralsTable/ReferralsTable';
import queryString from 'query-string';
import styled from 'styled-components';
import { AppContext } from '../../core/contexts/AppContext';
import { AuthorizeCSVExport } from '../../../components/AuthorizeCSVExport';
import { CSVDownloadButton } from '../components/CSVDownloadButton';
import { Col, PageHeader, Row } from 'antd';
import { DATE_RANGE, getDateRange, ReferralsDateRange } from '../components/ReferralsTable/ReferralsDateRange';
import { ListReferralsInterface, ListReferralsQuery } from '../../../GraphQL/ListReferrals.graphql';
import { Query } from '@apollo/client/react/components';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { SearchReferralPatients } from '../components/SearchReferralPatients';
import { getDefaultSendsOrReceives, SentRecDropDown } from '../../core/components/SentRecDropDown';
import { history } from '../../core/components/App/history';
import { useSentRecQueryStringHistoryEffect } from '../../core/components/UseSentRecQueryStringHistoryEffect';
import { deletePaginatedCacheItems } from '../../../helpers/cache';

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum TypeStatusEnum {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
}

export enum SortFieldEnum {
  INSERTED_AT = 'INSERTED_AT',
  APPT_TIME = 'APPT_TIME',
}

export interface QueryVariablesInterface {
  locationId: string | null;
  organizationId: string;
  patientId?: string;
  type: TypeStatusEnum;
  pageSize?: number;
  page?: number;
  sortField: SortFieldEnum;
  sortOrder: SortOrderEnum;
  filters?: string;
  startDate?: string;
  endDate?: string;
  dateRangeText?: string;
}

export interface QueryParamsInterface {
  locationId?: string;
  organizationId?: string;
  type?: TypeStatusEnum;
  pageSize?: string;
  page?: string;
  sortField?: SortFieldEnum;
  sortOrder?: SortOrderEnum;
  filters?: string;
  startDate?: string;
  endDate?: string;
  dateRangeText?: string;
}

interface QueryData {
  ListReferrals: ListReferralsInterface;
}

const Styles = styled.div`
  .above-table-btns {
    margin-bottom: 1rem;
  }

  .right-btns {
    display: flex;
    justify-content: flex-end;
  }

  .csv-btn {
    margin-left: 1rem;
  }

  .ant-page-header-heading-title {
    font-weight: normal;
  }
`;

const ReferralsContainer: FC<WithApolloClient<{}>> = ({ client }): JSX.Element => {
  const queryParams: QueryParamsInterface = queryString.parse(history.location.search);
  const { currentLocation, currentOrganization } = useContext(AppContext);

  const [patientId, setPatientId] = useState<string | undefined>();

  useSentRecQueryStringHistoryEffect();

  if (!currentLocation || !currentOrganization) {
    return <></>;
  }

  const initialQueryVariables: QueryVariablesInterface = {
    locationId: currentLocation.id,
    organizationId: currentOrganization.id,
    patientId,
    type: (queryParams.type as TypeStatusEnum) || getDefaultSendsOrReceives(currentLocation),
    page: queryParams.page ? parseInt(queryParams.page, 10) : 0,
    pageSize: queryParams.pageSize ? parseInt(queryParams.pageSize, 10) : 0,
    sortField: queryParams.sortField || SortFieldEnum.INSERTED_AT,
    sortOrder: queryParams.sortOrder || SortOrderEnum.DESC,
    filters: queryParams.filters ? queryParams.filters : '{}',
    startDate:
      queryParams.startDate ||
      getDateRange(currentOrganization?.defaultDateFilterRange || DATE_RANGE.LAST_30_DAYS).fromDate.toISOString(),
    endDate:
      queryParams.endDate ||
      getDateRange(currentOrganization?.defaultDateFilterRange || DATE_RANGE.LAST_30_DAYS).toDate.toISOString(),
    dateRangeText: queryParams.dateRangeText ? queryParams.dateRangeText : DATE_RANGE.LAST_90_DAYS,
  };

  const handleSelectPatient = (value: string | JSX.Element | undefined): void => {
    if (typeof value === 'string' || value === undefined) {
      setPatientId(value);
      if (client) {
        deletePaginatedCacheItems(client.cache, 'ListReferrals');
      }
    }
  };

  return (
    <Query<QueryData, QueryVariablesInterface> query={ListReferralsQuery} variables={initialQueryVariables}>
      {({ loading, error, data, variables }) => {
        if (error && error?.message) {
          return <div>{error.message.replace('GraphQL error:', '')}</div>;
        }

        if (error) return <div>Error</div>;

        const pageNumber = data && data.ListReferrals ? data.ListReferrals.pageNumber : 0;
        const totalEntries = data && data.ListReferrals ? data.ListReferrals.totalEntries : 0;
        const filters =
          data && data.ListReferrals
            ? data.ListReferrals.filters
            : {
                apptStatuses: [],
                providers: [],
                profiles: [],
                referralStatuses: [],
                procedures: [],
              };

        return (
          <Styles>
            <PageHeader
              title={
                <Row gutter={16} style={{ width: '600px' }}>
                  <Col span={8}>
                    <SentRecDropDown type={variables?.type || TypeStatusEnum.SENT} />
                  </Col>
                  <Col span={16}>
                    <SearchReferralPatients onSelect={handleSelectPatient} organizationId={currentOrganization.id} />
                  </Col>
                </Row>
              }
              extra={
                <Row type="flex">
                  <Col>
                    <ReferralsDateRange />
                  </Col>
                  <Col>
                    <AuthorizeCSVExport>
                      <div style={{ marginLeft: '1em' }}>
                        <CSVDownloadButton
                          organizationId={currentOrganization.id}
                          text="CSV Export"
                          variables={variables}
                        />
                      </div>
                    </AuthorizeCSVExport>
                  </Col>
                </Row>
              }
            />
            <ReferralsTable
              organizationId={currentOrganization.id}
              data={data}
              loading={loading}
              variables={variables}
              pageNumber={pageNumber}
              totalEntries={totalEntries}
              filters={filters}
            />
          </Styles>
        );
      }}
    </Query>
  );
};

export default withApollo(ReferralsContainer);
