import { gql } from '@apollo/client';

const GetProvider = gql`
  query GetProvider($GetProviderInput: GetProviderInput!) {
    GetProvider(GetProviderInput: $GetProviderInput) {
      id
      key
      name
      npi
      isActive
      location {
        id
        name
      }
    }
  }
`;

const UpdateProvider = gql`
  mutation UpdateProvider($UpdateProviderInput: UpdateProviderInput!) {
    UpdateProvider(UpdateProviderInput: $UpdateProviderInput) {
      id
    }
  }
`;

export { GetProvider, UpdateProvider };
