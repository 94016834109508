import * as Sentry from '@sentry/browser';
import { Button, Card, Col, Form, message, Row } from 'antd';
import { gql } from '@apollo/client';
import _ from 'lodash';
import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { deletePaginatedCacheItems, FeatureFlag, FeatureGate } from '../../../../helpers';
import config from '../../../../config';
import AddNewPatientForm from './AddNewPatientForm';
import DesiredNetworksField from './DesiredNetworksField';
import DesiredProcedureField from './DesiredProcedureField';
import DesiredSpecialtyField from './DesiredSpecialtyField';
import PatientSearchField from './PatientSearchField';
import PreAuthorizedField from './PreAuthorizedField';
import InsurancePayorField from './InsurancePayorField';
import InsurancePlanField from './InsurancePlanField';
import SelectProviderField from './SelectProviderField';

const CreateReferral = gql`
  mutation CreateReferral(
    $groupIds: [ID!]!
    $patientId: ID!
    $patientIsPreAuthorized: Boolean
    $procedureId: ID!
    $providerId: ID!
  ) {
    createReferral(
      groupIds: $groupIds
      patientId: $patientId
      patientIsPreAuthorized: $patientIsPreAuthorized
      procedureId: $procedureId
      providerId: $providerId
    ) {
      id
      showScreening
    }
  }
`;

const Styles = styled.div`
  margin-top: 2rem;

  .ant-form label.ant-checkbox-wrapper {
    line-height: 1.5 !important;
  }

  .main-card {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
`;

class SchedulingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDirty: false,
      preselectedPatient: props.preselectedPatient,
      newPatient: null,
      autoCompleteResult: [],
      showNewPatientForm: false,
    };
  }

  componentDidMount() {
    const { preselectedPatient } = this.state;
    const { setFieldsValue } = this.props.form;
    const queryParams = new URLSearchParams(this.props.location.search);

    if (preselectedPatient) {
      this.handleSetNewPatient(preselectedPatient);
    }

    const providerId = queryParams.get('providerId');
    const patientId = queryParams.get('patientId');
    const specialtyId = queryParams.get('specialtyId');
    const procedureId = queryParams.get('procedureId');
    const groupIdsString = queryParams.get('groupIds');
    const groupIds = groupIdsString ? groupIdsString.split(',').map(id => id.trim()) : [];

    const formValues = {
      ...(providerId && { sendingProvider: providerId }),
      ...(patientId && { patientSearchField: patientId }),
      ...(specialtyId && { specialty: specialtyId }),
      ...(procedureId && { procedure: procedureId }),
      ...(groupIds.length > 0 && { groupIds }),
    };

    if (Object.keys(formValues).length > 0) {
      setFieldsValue(formValues);
    }
  }

  handleShowNewPatientForm = () => this.setState({ showNewPatientForm: true });
  handleHideNewPatientForm = () => this.setState({ showNewPatientForm: false });

  handleSetNewPatient = patient => {
    const { setFieldsValue } = this.props.form;

    this.setState({ newPatient: patient });
    setFieldsValue({ patientSearchField: patient.id });
  };

  handleClearNewPatient = () => {
    this.setState({ newPatient: null });
  };

  createReferral = booked => {
    const { getFieldValue } = this.props.form;
    this.props.client
      .mutate({
        mutation: CreateReferral,
        variables: {
          groupIds: getFieldValue('groupIds') || [],
          patientId: getFieldValue('patientSearchField'),
          patientIsPreAuthorized: getFieldValue('patientPreAuthorized') || false,
          procedureId: getFieldValue('procedure'),
          providerId: this.props.defaultProviderId || getFieldValue('sendingProvider'),
        },
        update: cache => deletePaginatedCacheItems(cache, 'ListReferrals'),
      })
      .then(result => {
        if (this.props.onClickSearch) {
          this.props.onClickSearch();
        }

        if (booked === true) {
          this.props.history.push(`/organizations/${this.props.organization.id}/referrals/new`);
        } else {
          const queryParams = new URLSearchParams({
            providerId: getFieldValue('sendingProvider'),
            patientId: getFieldValue('patientSearchField'),
            specialtyId: getFieldValue('specialty'),
            procedureId: getFieldValue('procedure'),
            groupIds: getFieldValue('groupIds'),
          }).toString();

          const nextPage = result.data.createReferral.showScreening
            ? `${config.endpoint}/referrals/${result.data.createReferral.id}/screening?${queryParams}`
            : `/referrals/${result.data.createReferral.id}/search?${queryParams}`;

          if (result.data.createReferral.showScreening === true) {
            window.location.replace(nextPage);
          } else {
            this.props.history.push(nextPage);
          }
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        message.error('There was an error creating the referral. Please try again.');
      });
  };

  checkPreAuthorized = (selectedSpecialtyId, selectedProcedureId) => {
    if (!selectedSpecialtyId) return false;

    const { specialties, organization } = this.props;
    const p = organization.preAuthorizedProcedures;
    const s = organization.preAuthorizedSpecialties;

    if ((Array.isArray(p) && p.length > 0) || (Array.isArray(s) && s.length > 0)) {
      return (
        organization.preAuthorizedSpecialties.includes(selectedSpecialtyId) ||
        organization.preAuthorizedProcedures.includes(selectedProcedureId)
      );
    } else {
      const filterValues = specialties.filter(s => s.id === selectedSpecialtyId).map(s => s.name);

      return filterValues.includes('Imaging/Radiology');
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(err => {
      if (err) {
        message.error('Please fix form errors and resubmit');
      } else {
        this.createReferral(false);
      }
    });
  };

  render() {
    const { organization, specialties, payors, form } = this.props;
    const { groups } = organization;
    const { getFieldValue } = form;
    const selectedSpecialty = getFieldValue('specialty');
    let procedures = [];

    if (selectedSpecialty) {
      const matchingSpecialty = specialties.find(specialty => specialty.id === selectedSpecialty);
      procedures = matchingSpecialty ? matchingSpecialty.procedures : [];
    } else if (specialties.length > 0) {
      procedures = specialties[0].procedures || [];
    } else {
      procedures = [];
    }

    const selectedCarrier = getFieldValue('carrier');
    let plans = [];

    if (selectedCarrier) {
      plans = payors.filter(payor => payor.id === selectedCarrier)[0]['payorPlans'];
    }

    return (
      <Styles>
        <Row type="flex" justify="center">
          <Col xs={22} sm={16} md={12} lg={8} xl={8}>
            <Card title="Schedule a Patient" className="main-card">
              <AddNewPatientForm
                organization={organization}
                handleSetNewPatient={this.handleSetNewPatient}
                show={this.state.showNewPatientForm}
                handleHideNewPatientForm={this.handleHideNewPatientForm}
              />
              <Form onSubmit={this.handleSubmit}>
                <Row type="flex" justify="center">
                  <Col span={this.props.span || 24} offset={this.props.offset || 0} style={{ background: 'white' }}>
                    {this.props.defaultProviderId ? null : (
                      <SelectProviderField form={form} providers={organization.providers} />
                    )}
                    {this.state.hidePatientSearch ? null : (
                      <PatientSearchField
                        form={form}
                        organization={organization}
                        newPatient={this.state.newPatient}
                        handleClearNewPatient={this.handleClearNewPatient}
                      />
                    )}
                    <FeatureGate feature={FeatureFlag.ManuallyAddPatient}>
                      <Row className="text-center -mt-6 text-grey-dark">- OR -</Row>
                      <Form.Item>
                        <Button htmlType="button" onClick={this.handleShowNewPatientForm} block>
                          Add New Patient
                        </Button>
                      </Form.Item>
                    </FeatureGate>
                    <DesiredSpecialtyField form={form} specialties={specialties} />
                    <DesiredProcedureField form={form} procedures={procedures} />
                    {this.checkPreAuthorized(getFieldValue('specialty'), getFieldValue('procedure')) ? (
                      <div>
                        <PreAuthorizedField form={form} />
                      </div>
                    ) : null}
                    {organization.showInsuranceFiltersDuringScheduling ? (
                      <>
                        <InsurancePayorField form={form} payors={payors} />
                        <InsurancePlanField form={form} plans={plans} />
                      </>
                    ) : null}
                    <React.Fragment>
                      <DesiredNetworksField form={form} groups={groups} />
                      <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                          Search
                        </Button>
                      </Form.Item>
                    </React.Fragment>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Styles>
    );
  }
}

const Scheduling = _.flowRight(withApollo, Form.create({ name: 'Scheduling Form' }))(SchedulingForm);

export default Scheduling;
