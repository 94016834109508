import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { ProfileSortType } from '../../consumer/components/SelectProfilesSort';
import { Profile } from '../../consumer/hooks/useGetConsumerSchedulingProfileQuery';

const searchProfilesInOrganizationQuery = gql`
  query SearchProfilesInOrganizationQuery(
    $organizationId: ID!
    $page: Int
    $pageSize: Int
    $searchProfilesInput: SearchProfilesInput!
  ) {
    searchProfilesInOrganization(
      organizationId: $organizationId
      page: $page
      pageSize: $pageSize
      searchProfilesInput: $searchProfilesInput
    ) {
      id
      displayName
      prefix
      firstName
      LastName
      suffix
      phone
      email
      type
      profileImgUrl
      professionalStatementSafe
      boardCertificationsSafe
      educationTrainingSafe
      npiNumber
      specialty
      nextAvailability
      languages {
        code
        name
      }
      organization {
        id
        name
      }
      location {
        id
        name
        address1
        address2
        city
        state
        postalCode
        country
        distanceFromPatient
        distanceFromProvider
        latitude
        longitude
        phone
        timeZone
      }
      isIntegrated
      isSendToProvider
      isWaitList
    }
  }
`;

export interface SearchProfilesInput {
  displayName?: string;
  gender?: string;
  hasConsumerScheduling?: boolean;
  isActive?: boolean;
  isIntegrated?: boolean;
  languageCode?: string;
  zipcode?: string;
  latitude?: number;
  longitude?: number;
  locationId?: string;
  npiNumber?: string;
  organizationIsActive?: boolean;
  payorId?: string;
  payorPlanId?: string;
  procedureId?: string;
  specialtyId?: string;
  sort?: ProfileSortType;
}

interface Data {
  searchProfilesInOrganization: Profile[];
}

interface Variables {
  organizationId: string;
  page: number | null;
  pageSize: number | null;
  searchProfilesInput: SearchProfilesInput;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useSearchProfilesInOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(searchProfilesInOrganizationQuery, options);
};
