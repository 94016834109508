import { Button, Col, Empty, Icon, Input, Row, Select } from 'antd';
import { History } from 'history';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { DirectoryContext, Organization } from '../containers/DirectoryContext';
import { DirectoryOrganizationsHeader } from './DirectoryOrganizationsHeader';
import { OrganizationCard } from './OrganizationCard';

interface Props {
  history: History;
}

const { Option } = Select;

const Styles = styled.div`
  .all-profiles-button {
    margin-left: 16px;
  }

  .organization-card-col {
    margin-top: 16px;
  }
`;

const getOrganizationsByName = (organizations: Organization[], nameFilter: string): Organization[] => {
  if (!nameFilter) {
    return organizations;
  }

  return organizations.filter(o => o.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0);
};

const getOrganizationsBySpecialtyId = (organizations: Organization[], specialtyId: string): Organization[] => {
  if (!specialtyId) {
    return organizations;
  }

  return organizations.filter(o => o.specialties.filter(s => s.id === specialtyId).length >= 1);
};

const getFilteredOrganizations = (
  organizations: Organization[],
  nameFilter: string,
  specialtyId: string
): Organization[] => {
  return getOrganizationsBySpecialtyId(getOrganizationsByName(organizations, nameFilter), specialtyId);
};

export const DirectoryOrganizations: FC<Props> = ({ history }): JSX.Element => {
  const {
    directory: { organizations, specialties },
    organizationNameFilter,
    selectedSpecialtyId,
    setOrganizationNameFilter,
    setSelectedOrganizationId,
    setSelectedSpecialtyId,
  } = useContext(DirectoryContext);

  const [filteredOrganizations, setFilteredOrganizations] = useState(
    getFilteredOrganizations(organizations, organizationNameFilter, selectedSpecialtyId)
  );

  const handleAllProfilesButtonClick = (): void => {
    setOrganizationNameFilter('');
    setSelectedOrganizationId('');
    history.push('/directory/profiles');
  };

  const handleInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    const newFilteredOrganizations = getFilteredOrganizations(organizations, value, selectedSpecialtyId);
    setOrganizationNameFilter(value);

    setFilteredOrganizations(newFilteredOrganizations);
  };

  const handleSelectSpecialty = (value: string): void => {
    const newFilteredOrganizations = getFilteredOrganizations(organizations, organizationNameFilter, value);
    setSelectedSpecialtyId(value);

    setFilteredOrganizations(newFilteredOrganizations);
  };

  return (
    <Styles>
      <DirectoryOrganizationsHeader />
      <Input
        defaultValue={organizationNameFilter}
        onChange={handleInputChange}
        placeholder="Search for organizations..."
        size="large"
        style={{ width: 300 }}
        suffix={<Icon type="search" />}
      />
      <Select<string>
        defaultValue={selectedSpecialtyId ? selectedSpecialtyId : ''}
        onSelect={handleSelectSpecialty}
        size="large"
        style={{ paddingLeft: '16px', width: 300 }}
      >
        <Option value={''}>All Specialties</Option>
        {specialties.map(s => (
          <Option key={s.id} value={s.id}>
            {s.name}
          </Option>
        ))}
      </Select>
      <Button className="all-profiles-button" onClick={handleAllProfilesButtonClick} size="large" type="primary">
        See All Profiles
      </Button>
      {filteredOrganizations.length > 0 ? (
        <Row gutter={16}>
          {filteredOrganizations.map(o => (
            <Col key={o.id} span={8} className="organization-card-col">
              <OrganizationCard history={history} organization={o} />
            </Col>
          ))}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Styles>
  );
};
