import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TextArea } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputReferralSearchNotice: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="Referral Search Notice:"
    extra="Optional disclaimer that is displayed below this profile in the referral search results"
  >
    {form.getFieldDecorator('referralSearchNotice', { initialValue, rules: [{ required: false }] })(
      <TextArea allowClear placeholder="Referral Search Notice" rows={8} />
    )}
  </Form.Item>
);
