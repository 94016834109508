import React, { FC, useState } from 'react';
import { Button } from 'antd';
import { History } from 'history';
import { CreateGroupModal } from './CreateGroupModal';

interface Props {
  history: History;
  organizationId: string;
}

export const CreateGroup: FC<Props> = ({ history, organizationId }): JSX.Element => {
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);

  return (
    <>
      <Button htmlType="button" onClick={() => setShowCreateGroupModal(true)}>
        Create New Network Group
      </Button>
      <CreateGroupModal
        history={history}
        onCancel={() => setShowCreateGroupModal(false)}
        onOk={() => setShowCreateGroupModal(false)}
        organizationId={organizationId}
        visible={showCreateGroupModal}
      />
    </>
  );
};
