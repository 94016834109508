import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputSpecialty: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Specialty or Subspecialty">
    {form.getFieldDecorator('specialty', {
      initialValue,
      rules: [{ required: false }],
    })(<Input allowClear placeholder="Specialty" />)}
  </Form.Item>
);
