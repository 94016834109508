import GoogleMapReact, { Props as GoogleMapReactProps } from 'google-map-react';
import React, { FC } from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props {
  children: JSX.Element[];
}

export const GoogleMap: FC<Props & GoogleMapReactProps> = ({ children, ...props }) => (
  <Styles>
    <GoogleMapReact bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY || '' }} {...props}>
      {children}
    </GoogleMapReact>
  </Styles>
);
