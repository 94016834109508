import React, { FC } from 'react';
import { Input as AntInput } from 'antd';
import { InputProps, PasswordProps, SearchProps, TextAreaProps } from 'antd/lib/input';

const AntPassword = AntInput.Password;
const AntSearch = AntInput.Search;
const AntTextArea = AntInput.TextArea;

export const Input: FC<InputProps> = props => <AntInput {...props} />;

export const Password: FC<PasswordProps> = props => <AntPassword {...props} />;

export const Search: FC<SearchProps> = props => <AntSearch {...props} />;

export const TextArea: FC<TextAreaProps> = props => <AntTextArea {...props} />;
