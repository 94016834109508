import React from 'react';
import { Route } from 'react-router-dom';
import { ConsumerSchedulingPrintSuccessPage } from './pages/ConsumerSchedulingPrintSuccessPage';
import { ConsumerSchedulingRoutes } from './routes/ConsumerSchedulingRoutes';

export const routes = [
  <Route
    exact
    path="/consumer/:organizationId/print-success"
    key="route--consumer-scheduling-print-success"
    component={ConsumerSchedulingPrintSuccessPage}
  />,
  <Route path="/consumer/:organizationId" key="route--consumer-scheduling" component={ConsumerSchedulingRoutes} />,
];
