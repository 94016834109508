import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { gql } from '@apollo/client';
import queryString, { ParsedQuery } from 'query-string';
import React, { FC, useContext } from 'react';
import { Query } from '@apollo/client/react/components';
import { ProviderData } from '../../referrals/components/Referral/GetReferralQuery';
import { DisplayError } from '../../core/components/App/DisplayError';
import { history } from '../../core/components/App/history';
import { AppContext } from '../../core/contexts/AppContext';
import { isAdmin, isBlockitAdmin } from '../../core/components/PrivateRoute';
import { ProviderCrudActions } from './ProviderCrudActions';

const PaginatedProvidersQuery = gql`
  query(
    $organizationId: ID!
    $locationId: ID
    $pageNumber: Int
    $pageSize: Int
    $active: Boolean
    $nameFilter: String
  ) {
    PaginatedProviders(
      organizationId: $organizationId
      locationId: $locationId
      pageNumber: $pageNumber
      pageSize: $pageSize
      active: $active
      nameFilter: $nameFilter
    )
      @connection(
        key: "PaginatedProviders"
        filter: ["organizationId", "locationId", "pageSize", "pageNumber", "active", "nameFilter"]
      ) {
      totalEntries
      pageNumber
      pageSize
      totalPages
      entries {
        id
        name
        npi
        location {
          id
          name
        }
      }
    }
  }
`;

const columns = (canAccessActions: boolean): ColumnProps<ProviderData>[] => {
  const cols = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'NPI Number',
      key: 'npi',
      dataIndex: 'npi',
    },
    {
      title: 'Location',
      key: 'location_name',
      render: (record: ProviderData): string | JSX.Element => record.location.name,
    },
  ];

  if (canAccessActions) {
    cols.push({
      title: 'Actions',
      key: 'actions',
      render: (record: ProviderData): JSX.Element => <ProviderCrudActions row={record} />,
    });
  }

  return cols;
};

interface Payload {
  entries: ProviderData[];
  pageNumber: number;
  totalEntries: number;
  totalPages: number;
  pageSize: number;
}

interface QueryResponse {
  PaginatedProviders: Payload;
}

interface QueryVariables {
  organizationId: string;
  locationId?: string;
  pageNumber?: number;
  pageSize?: number;
  active?: boolean;
  nameFilter?: string;
}

const updateUrl = (pageNumber: number, pageSize: number): void => {
  const queryParams: ParsedQuery = queryString.parse(history.location.search);

  history.push(
    history.location.pathname +
      '?' +
      queryString.stringify({
        ...queryParams,
        pageSize,
        pageNumber,
      })
  );
};

interface ProvidersTableProps {
  active: boolean;
  nameFilter: string;
}

export const ProvidersTable: FC<ProvidersTableProps> = ({ active, nameFilter }: ProvidersTableProps): JSX.Element => {
  const { currentOrganization, currentLocation } = useContext(AppContext);

  const queryParams: ParsedQuery = queryString.parse(history.location.search);
  const additionalQueryVariables = {
    pageSize: queryParams.pageSize && typeof queryParams.pageSize == 'string' ? parseInt(queryParams.pageSize, 10) : 10,
    pageNumber:
      queryParams.pageNumber && typeof queryParams.pageNumber == 'string' ? parseInt(queryParams.pageNumber, 10) : 1,
  };

  return (
    <Query<QueryResponse, QueryVariables>
      query={PaginatedProvidersQuery}
      variables={{
        organizationId: currentOrganization?.id || '',
        locationId: currentLocation?.id,
        active,
        nameFilter,
        ...additionalQueryVariables,
      }}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data }) => {
        let response: Payload = {
          entries: [],
          pageNumber: 1,
          totalEntries: 0,
          totalPages: 0,
          pageSize: 10,
        };

        if (data && data.PaginatedProviders) {
          response = data && data.PaginatedProviders ? data.PaginatedProviders : response;
        }

        return (
          <>
            <DisplayError error={error} />
            <Table<ProviderData>
              loading={loading}
              dataSource={response.entries}
              columns={columns(isAdmin(currentOrganization) || isBlockitAdmin(currentOrganization))}
              rowKey={record => record.id}
              onChange={pagination => updateUrl(pagination.current || 1, pagination.pageSize || 10)}
              onRow={(record, _rowIndex) => {
                if (isAdmin(currentOrganization) || isBlockitAdmin(currentOrganization)) {
                  return {
                    onClick: event => {
                      event.preventDefault();
                      history.push(`/organizations/${currentOrganization?.id}/providers/${record.id}/edit`);
                    },
                  };
                }

                return {};
              }}
              pagination={{
                pageSize: response.pageSize,
                position: 'bottom',
                showSizeChanger: true,
                defaultCurrent: 0,
                current: response.pageNumber,
                total: response.totalEntries,
              }}
            />
          </>
        );
      }}
    </Query>
  );
};
