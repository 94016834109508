import React, { FC } from 'react';
import RadioGroup from 'antd/lib/radio/group';
import { Radio } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { ValidateQuestionnaire, Question } from './QuestionnaireForm';

interface Props extends FormComponentProps {
  question: Question;
  validator: ValidateQuestionnaire;
}

export const QuestionRadioVertical: FC<Props> = ({ form, question, validator }): JSX.Element => {
  let options = question.options;

  if (options === null) {
    options = ['Yes', 'No'];
  }

  return (
    <Form.Item label={question.text} key={question.id} extra={question.helpText}>
      {form.getFieldDecorator(`questionnaire[0].${question.text.replace(/\./g, ' ')}`, {
        rules: [{ required: question.required, message: 'required' }, { validator: validator }],
        initialValue: question.initialValue,
      })(
        <RadioGroup size="large">
          {options.map(value => (
            <Radio
              value={value}
              key={value}
              style={{
                display: 'block',
                marginBottom: '10px',
                padding: 8,
                border: '1px solid #ccc',
                width: '100%',
              }}
            >
              {value}
            </Radio>
          ))}
        </RadioGroup>
      )}
    </Form.Item>
  );
};
