import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';
import { validateAntEmail } from '../../../helpers';

interface Props {
  form: WrappedFormUtils;
}

export const InputUserEmail: FC<Props> = ({ form }): JSX.Element => (
  <Form.Item label="Email Address:">
    {form.getFieldDecorator('email', {
      rules: [{ required: true, message: 'Please enter your email.' }, { validator: validateAntEmail }],
    })(<Input allowClear type="email" />)}
  </Form.Item>
);
