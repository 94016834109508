import { Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Content } from './Content';

const DropDownContainer = styled.div`
  padding-right: 20px;
  position: relative;

  .toggleContainer {
    cursor: pointer;

    .toggle {
      margin-left: 10px;
    }
  }
`;

const DropDownContent = styled(Content)`
  z-index: 4487;
  max-height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
`;

interface State {
  open: boolean;
}

interface OptionProps {
  props: { onClick: () => void };
}

interface Props {
  options: OptionProps[];
  left?: boolean;
}

class DropDown extends React.Component<Props, State> {
  state = { open: false };
  node: HTMLDivElement | null = null;

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e: MouseEvent): void => {
    if (this.node && this.node.contains(e.target as Node)) {
      return;
    }

    this.state.open && this.toggle();
  };

  toggle = (): void => this.setState({ open: !this.state.open });

  onChildClick = (childClickHandler: () => void) => {
    return (e: React.MouseEvent<HTMLElement>) => {
      childClickHandler();
      if (e.target !== this.node) {
        this.toggle();
      }
    };
  };

  render(): JSX.Element {
    const { children, options = [], left = false } = this.props;

    return (
      <DropDownContainer ref={(node: HTMLDivElement | null) => (this.node = node)}>
        <span className="toggleContainer" onClick={this.toggle}>
          {children}
          <Icon type={this.state.open ? 'caret-down' : 'caret-up'} className="toggle" />
        </span>
        {this.state.open && <DropDownContent left={left} options={options} hijackOnClick={this.onChildClick} />}
      </DropDownContainer>
    );
  }
}

export * from './Option';
export { DropDown };
