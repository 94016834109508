import React from 'react';
import { PrivateRoute } from '../core';
import { Roles } from '../core/components/PrivateRoute';
import { AddUser } from './pages/AddUser';
import { EditUser } from './pages/EditUser';
import { Users } from './pages/Users';

export const routes = [
  <PrivateRoute exact path="/organizations/:id/users" key="route--users" component={Users} />,
  <PrivateRoute
    exact
    path="/organizations/:id/users/new"
    key="route--new-user"
    component={AddUser}
    minRole={Roles.ADMIN}
  />,
  <PrivateRoute
    exact
    path="/organizations/:id/users/:userId/edit"
    key="route--edit-user"
    component={EditUser}
    minRole={Roles.ADMIN}
  />,
];

export enum UserRole {
  Admin = 'ADMIN',
  BlockitAdmin = 'BLOCKIT_ADMIN',
  Scheduler = 'SCHEDULER',
}
