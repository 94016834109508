import React, { FC, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { SelectPayor } from './SelectPayor';
import { SelectPayorPlan } from './SelectPayorPlan';
import { Payor, PayorPlan, useGetAllPayorsAndPlansQuery } from '../hooks/useGetAllPayorsAndPlansQuery';
import { Col, Row } from 'antd';

interface Props {
  form: WrappedFormUtils;
  initialPayorId?: string;
  initialPayorPlanId?: string;
  required: boolean;
}

export const SelectPatientPayorAndPlan: FC<Props> = ({
  form,
  initialPayorId,
  initialPayorPlanId,
  required,
}): JSX.Element => {
  const [selectedPayor, setSelectedPayor] = useState<Payor | undefined>(undefined);

  const { data, error, loading } = useGetAllPayorsAndPlansQuery();

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  const { getAllPayorsAndPlans: payors } = data;

  const findPayor = (payorId: string | undefined): Payor | undefined => {
    return payors.find(payor => payor.id === payorId);
  };

  const findPayorPlan = (payorPlanId: string | undefined): PayorPlan | undefined => {
    if (selectedPayor) {
      return selectedPayor.payorPlans.find(payorPlan => payorPlan.id === payorPlanId);
    }

    return findPayor(initialPayorId)?.payorPlans.find(payorPlan => payorPlan.id === payorPlanId);
  };

  const handleSelectPayor = (payorId: string): void => {
    const newPayor = findPayor(payorId);
    if (newPayor?.id !== selectedPayor?.id) {
      form.setFieldsValue({ payorPlanId: undefined });
    }

    setSelectedPayor(findPayor(payorId));
  };

  let payorPlanRequired = required;

  if (selectedPayor?.name === 'Self Pay/No Insurance') {
    payorPlanRequired = false;
  }

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} lg={12}>
        <SelectPayor
          form={form}
          initialPayor={findPayor(initialPayorId)}
          onSelect={handleSelectPayor}
          payors={payors}
          required={required}
        />
      </Col>
      <Col xs={24} lg={12}>
        <SelectPayorPlan
          form={form}
          initialPayorPlan={findPayorPlan(initialPayorPlanId)}
          payorPlans={selectedPayor ? selectedPayor.payorPlans : findPayor(initialPayorId)?.payorPlans}
          required={payorPlanRequired}
        />
      </Col>
    </Row>
  );
};
