import { Alert, Button, Col, List, Row, Tag, Typography } from 'antd';
import React, { FC, useContext } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { formatApptRange } from '../../../../helpers';
import { AppContext } from '../../../core/contexts/AppContext';
import { CodeSystemLookup } from '../CodeSystemLookup';
import { CancelAppointment } from './CancelAppointment';
import { ConfirmAppointment } from './ConfirmAppointment';
import { ChangeAppointmentStatus } from './ChangeAppointmentStatusDropDown';
import { AppointmentData, ReferralData } from './GetReferralQuery';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

const { Title } = Typography;

export enum AppointmentDetailsDisplayType {
  Referral,
  ConsumerAppointment,
}

type AppointmentsDetailsCardType = WithApolloClient<{
  referral?: ReferralData;
  appointment?: AppointmentData;
  cardType: AppointmentDetailsDisplayType;
}>;

const AppointmentsDetailsCardComponent: FC<AppointmentsDetailsCardType> = ({
  referral,
  appointment,
  cardType,
  client,
}): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);
  const isReceiver = currentOrganization?.id === appointment?.profile.organization.id;
  const showButton = !currentOrganization?.referralSettings?.hideSchedulingButtons;
  // eslint-disable-next-line max-len
  const patientReferralLink = `/referrals/${referral?.id}/search/${currentOrganization?.id}/profiles/${referral?.patientReferral?.profile?.id}`;
  // eslint-disable-next-line max-len
  const profileReferralLink = `/referrals/${referral?.id}/search/${currentOrganization?.id}/profiles/${referral?.profileReferral?.profile?.id}`;
  let appointmentData: AppointmentData[] = [];
  if (appointment) {
    appointmentData = [appointment];
  }

  // TOOD: Clean up these helper functions
  const formatDayNumber = (appt: AppointmentData): string => {
    return new Date(Date.parse(appt.start)).toLocaleDateString('en-US', { month: 'long' });
  };

  const formatDateNumber = (appt: AppointmentData): number => {
    return new Date(Date.parse(appt.start)).getDate();
  };

  const formatDateWeekday = (appt: AppointmentData): string => {
    return new Date(Date.parse(appt.start)).toLocaleDateString('en-US', { weekday: 'long' });
  };

  const formatDateFull = (appt: { start: string }): string => {
    return new Date(Date.parse(appt.start)).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <Styles>
      {(appointment?.rescheduledTo || appointment?.rescheduledFrom) && (
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {appointment?.rescheduledTo && (
              <Alert
                message={
                  <p>
                    This appointment was rescheduled to{' '}
                    <b>
                      {formatDateFull(appointment.rescheduledTo)} at{' '}
                      {DateTime.fromISO(appointment.rescheduledTo.start).toFormat('hh:mma')}{' '}
                    </b>
                    <Link to={`/appointments/${appointment.rescheduledTo.id}`}>
                      <span style={{ float: 'right' }}>Details</span>
                    </Link>
                  </p>
                }
                type="info"
                showIcon
              />
            )}
          </Col>
          <Col span={24}>
            {appointment?.rescheduledFrom && (
              <Alert
                message={
                  <p>
                    This appointment was rescheduled from{' '}
                    <b>
                      {formatDateFull(appointment.rescheduledFrom)} at{' '}
                      {DateTime.fromISO(appointment.rescheduledFrom.start).toFormat('hh:mma')}{' '}
                    </b>
                    <Link to={`/appointments/${appointment.rescheduledFrom.id}`}>
                      <span style={{ float: 'right' }}>Details</span>
                    </Link>
                  </p>
                }
                type="info"
                showIcon
              />
            )}
          </Col>
        </Row>
      )}
      <List
        size="large"
        dataSource={appointmentData}
        locale={{ emptyText: 'No Scheduled Appointments' }}
        renderItem={(appt: AppointmentData) => (
          <List.Item>
            <Row type="flex">
              <Col style={{ width: '70px' }}>
                <div className="date-box">
                  <h5>{formatDateNumber(appt)}</h5>
                  <p>{formatDayNumber(appt)}</p>
                </div>
              </Col>
              <Col span={10} offset={1}>
                <h3>
                  {formatDateWeekday(appt)} @ {appt.profile.location.name}
                </h3>
                <div>
                  {formatDateFull(appt)} {formatApptRange(appt)}
                </div>
                <div className="address">
                  {appt.profile.location.address1} {appt.profile.location.city}, {appt.profile.location.state}{' '}
                  {appt.profile.location.postalCode}
                </div>
                <br />
                <Row type="flex" style={{ marginTop: '15px' }}>
                  <Col span={24} style={{ width: '90%' }}>
                    <div className="patient-instructions-title">Patient Instructions</div>
                    <div className="patient-instructions-box">
                      <div className="patient-instructions-content">
                        {appt.profile.generalPatientInstructions || ''}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                {appointment && appointment.status === 'pending' ? (
                  isReceiver ? (
                    <>
                      <div>
                        <ConfirmAppointment appointment={appointment} />
                      </div>
                      {showButton && (
                        <div style={{ marginTop: 8 }}>
                          <a href={appointment?.rescheduleLink} target="_new">
                            <Button>Reschedule Appointment</Button>
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>
                      Waiting for {appointment.profile.organization.name} to confirm appointment.
                    </span>
                  )
                ) : (cardType === AppointmentDetailsDisplayType.Referral && referral) ||
                  cardType === AppointmentDetailsDisplayType.ConsumerAppointment ? (
                  <ChangeAppointmentStatus
                    appointment={appointment}
                    referral={referral}
                    key="change-appointment-status"
                  />
                ) : null}
                {appointment &&
                  appointment.status !== 'cancelled' &&
                  appointment.status !== 'noshow' &&
                  appointment.status !== 'fulfilled' && (
                    <div style={{ marginTop: 8 }}>
                      <CancelAppointment appointment={appointment} referral={referral} />
                    </div>
                  )}
              </Col>
            </Row>
            <Row type="flex">
              <Col span={21} offset={3}>
                {referral && <CodeSystemLookup referralId={referral.id} profileId={appt.profile.id} />}
              </Col>
            </Row>
            {!referral && appointment && appointment.procedure && (
              <Row type="flex">
                <Col span={21} offset={0}>
                  <Title level={3}>{appointment.procedure.specialty.name}</Title>
                  <Tag>{appointment.procedure.name}</Tag>
                </Col>
              </Row>
            )}
          </List.Item>
        )}
      />
      {referral?.patientReferral && referral?.status === 'pending' && (
        <div style={{ textAlign: 'center' }}>
          <Link to={patientReferralLink}>
            <Button>Schedule Appointment</Button>
          </Link>
        </div>
      )}
      {referral?.profileReferral && referral?.status === 'pending' && (
        <div style={{ textAlign: 'center' }}>
          <Link to={profileReferralLink}>
            <Button>Schedule Appointment</Button>
          </Link>
        </div>
      )}
    </Styles>
  );
};

const Styles = styled.div`
  .ant-list-item {
    width: 100%;
    display: block;

    .date-box {
      border: 1px solid #2783c4;
      border-radius: 2px;
      text-align: center;

      h5 {
        font-size: 2.5em;
        font-weight: lighter;
        background-color: #fff;
        color: #222;
        padding: 0;
        margin: 0;
      }

      p {
        text-transform: uppercase;
        font-size: 0.85em;
        font-weight: bold;
        background-color: #2783c4;
        color: #fafafa;
        padding: 0;
        margin: 0;
      }
    }

    h3 {
      font-size: 1.35em;
    }

    .address {
      font-size: 12px;
    }

    .ant-row-flex {
      margin-top: 12px;
    }
  }

  .patient-instructions-title {
    font-size: 1.2em;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .patient-instructions-content {
    margin-top: 2%;
  }

  .patient-instructions-box {
    border: 1px solid #dae1e7;
    padding: 8px;
    min-height: 50px;
    border-radius: 1px;
  }
`;

export const AppointmentsDetailsCard = withApollo<AppointmentsDetailsCardType>(AppointmentsDetailsCardComponent);
