import { Card, Col, Icon, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { formatPhone } from '../../../../helpers';
import { AppointmentData, ProfileData, ProviderData, ReferralData, UserData } from './GetReferralQuery';
import { SentToPatient } from './SentToPatient';
import { SentToProvider } from './SentToProvider';

const { Text } = Typography;

// TODO
// 1. Break up into components
// 2. Tests. Test with null data. Etc. Better resiliancy
// 3. Clean up props - passing in the entire referral just to get the referral.id down below

interface SenderReceiverCardProps extends ProviderCardProps {
  appointment?: AppointmentData;
}

interface ProviderCardProps {
  creator: UserData;
  provider: ProviderData;
  referral: ReferralData;
}

interface ProfileCardProps {
  profile: ProfileData;
}

const SenderReceiverCard: FC<SenderReceiverCardProps> = ({ provider, creator, appointment, referral }): JSX.Element => {
  const profile = appointment
    ? appointment.profile
    : referral.patientReferral
    ? referral.patientReferral.profile
    : referral.profileReferral
    ? referral.profileReferral.profile
    : null;

  return (
    <Row gutter={8} type="flex">
      <Col span={12}>
        <ProviderCard creator={creator} provider={provider} referral={referral} />
      </Col>
      <Col span={12}>{profile ? <ProfileCard profile={profile} /> : <EmptyProfileCard referralId={referral.id} />}</Col>
    </Row>
  );
};

const EmptyProfileCard: FC<{ referralId: string }> = ({ referralId }): JSX.Element => (
  <Card style={{ minHeight: '100%' }}>
    <Row type="flex">
      <Col span={12} offset={6} style={{ textAlign: 'center', height: '100%' }}>
        <Icon type="calendar" style={{ fontSize: '4em', color: '#ccc' }} />
      </Col>
    </Row>
    <Row>
      <Col span={12} offset={6} style={{ textAlign: 'center', marginTop: 12, height: '100%' }}>
        <h3 style={{ color: '#ccc' }}>Unlisted Provider</h3>
      </Col>
    </Row>
  </Card>
);

const ProfileCard: FC<ProfileCardProps> = ({ profile }): JSX.Element => (
  <Card style={{ minHeight: '100%' }}>
    <Card.Meta
      style={{ lineHeight: '18px', marginBottom: 8 }}
      title={
        <>
          <div>{profile.displayName}</div>
          <div className="text-xs text-grey-dark">{profile.organization.name}</div>
        </>
      }
    />
    <Row gutter={[16, { xs: 8, sm: 8, md: 8 }]}>
      <Col md={24} lg={12}>
        <Row gutter={16}>
          <Col span={2}>
            <Icon type="phone" />
          </Col>
          <Col span={20}>
            <ul>
              <li>
                <Text>{formatPhone(profile.phone)}</Text>
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
      <Col md={24} lg={12}>
        <Row gutter={16}>
          <Col span={2}>
            <Icon type="environment" />
          </Col>
          <Col span={20}>
            <ul>
              <li>
                <Text>{profile.location.name}</Text>
              </li>
              <li>
                <Text>{profile.location.address1}</Text>
              </li>
              <li>
                <Text>{profile.location.address2}</Text>
              </li>
              <li>
                <Text>
                  {profile.location.city}, {profile.location.state} {profile.location.postalCode}
                </Text>
              </li>
              <li>
                <Text>{profile.location.distanceFromPatient}</Text>
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  </Card>
);

const ProviderCard: FC<ProviderCardProps> = ({ creator, provider, referral }): JSX.Element => (
  <Card style={{ minHeight: '100%' }}>
    <Card.Meta
      style={{ lineHeight: '18px', marginBottom: 8 }}
      title={
        <>
          <div>{provider.name}</div>
          <div className="text-xs text-grey-dark">{provider.organization.name}</div>
        </>
      }
    />
    <Row gutter={[16, { xs: 8, sm: 8, md: 8 }]}>
      <Col md={24} lg={12} className="redact">
        <Row gutter={16}>
          <Col span={2}>
            <Icon type="contacts" />
          </Col>
          <Col span={20}>
            <ul>
              <li>
                <Text>{creator.name}</Text>
              </li>
              <li className="text-xs">
                <Text>{creator.email}</Text>
              </li>
              {referral.patientReferral ? (
                <SentToPatient
                  patient={referral.patient}
                  patientReferral={referral.patientReferral}
                  hasAppointment={referral.appointment?.id !== undefined}
                />
              ) : null}
              {referral.profileReferral ? <SentToProvider profileReferral={referral.profileReferral} /> : null}
            </ul>
          </Col>
        </Row>
      </Col>
      <Col md={24} lg={12}>
        <Row gutter={16}>
          <Col span={2}>
            <Icon type="environment" />
          </Col>
          <Col span={20}>
            <ul>
              <li>
                <Text>{provider.location.name}</Text>
              </li>
              <li>
                <Text>{provider.location.address1}</Text>
              </li>
              <li>
                <Text>{provider.location.address2}</Text>
              </li>
              <li>
                <Text>
                  {provider.location.city}, {provider.location.state} {provider.location.postalCode}
                </Text>
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  </Card>
);

export default SenderReceiverCard;
