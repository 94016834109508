import { gql } from '@apollo/client';
import { useMutation, MutationHookOptions, MutationTuple } from '@apollo/client';

const uploadScheduleImage = gql`
  mutation UploadScheduleImageMutation($uploadScheduleImageInput: UploadScheduleImageInput!) {
    uploadScheduleImage(uploadScheduleImageInput: $uploadScheduleImageInput)
  }
`;

interface UploadScheduleImageInput {
  file: File;
}

interface Data {
  uploadScheduleImage: string;
}

interface Variables {
  uploadScheduleImageInput: UploadScheduleImageInput;
}

type Options = MutationHookOptions<Data, Variables>;

type Result = MutationTuple<Data, Variables>;

export const useUploadScheduleImageMutation = (options: Options): Result => {
  return useMutation<Data, Variables>(uploadScheduleImage, options);
};
