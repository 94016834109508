import { gql } from '@apollo/client';

export const CreateMessage = gql`
  mutation createMessage($createMessageInput: CreateMessageInput!) {
    createMessage(CreateMessageInput: $createMessageInput) {
      id
      body
      insertedAt
    }
  }
`;
