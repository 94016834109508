import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as smoothscroll from 'smoothscroll-polyfill';
import 'whatwg-fetch';

// kick off the smoothscroll polyfill!
smoothscroll.polyfill();

// Some versions of IE11 don't have window.location.origin, which we use for determining the API url.
// Polyfill it for now (https://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/)
if (!window.location.origin) {
  const { protocol, hostname, port } = window.location;
  (window.location.origin as string) = protocol + '//' + hostname + (port ? ':' + port : '');
}
