import { FC } from 'react';
import { useAppContext } from '../hooks';
import { FeatureFlag, hasFeature } from '../helpers';
import { Profile } from '../modules/profiles/index';

type ChildrenFunc = () => JSX.Element;

interface Props {
  profile: Profile;
  children: ChildrenFunc | JSX.Element;
  fallbackContent?: JSX.Element;
}

export const AuthorizeAvailability: FC<Props> = ({ profile, children, fallbackContent }): JSX.Element | null => {
  const { currentOrganization } = useAppContext();

  if (hasFeature(currentOrganization?.featureFlags, FeatureFlag.HasAvailability) || profile.isWaitList) {
    if (children instanceof Function) {
      return children();
    } else {
      return children as JSX.Element;
    }
  }

  return fallbackContent || null;
};
