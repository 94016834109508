import * as React from 'react';
import { Button, Row, Col, Progress, Typography, Icon } from 'antd';
import { useState, useEffect } from 'react';
import { SignupError } from './SignupError';

const { Title } = Typography;

interface CompleteSignupProps {
  onComplete: () => void;
  pelitas?: boolean;
  signupError: boolean;
}

const progressMessages = [
  'Deploying positronic neural network...',
  'Aligning flux capacitors...',
  'Refining dilithium crystals...',
  'Calibrating nanopulse gravitic resonator...',
];

export const CompleteSignup: React.FC<CompleteSignupProps> = ({ onComplete, pelitas, signupError }) => {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState(0);

  useEffect(() => {
    const progressUpdater = setInterval(() => {
      setProgress((prevProgress: number) => {
        if (prevProgress >= 110) {
          clearInterval(progressUpdater);
        }

        const newProgress = prevProgress + (Math.floor(Math.random() * 8) + 1);
        setMessage(Math.floor(newProgress / 25));
        return newProgress;
      });
    }, 100);

    return () => clearInterval(progressUpdater);
  }, []);

  if (signupError) {
    return <SignupError pelitas={pelitas} />;
  }

  return (
    <div style={{ paddingTop: '4rem' }}>
      {progress <= 110 ? (
        <>
          <Title level={2} id="title">
            Creating your Blockit account...
          </Title>
          <br />
          <Row type="flex" justify="center">
            <Col span={12}>
              <Progress percent={progress} showInfo={false} />
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col span={12}>
              <br />
              <p style={{ color: '#666' }}>{progressMessages[message]}</p>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row type="flex" justify="center">
            <Col span={24} style={{ textAlign: 'center' }}>
              <Icon
                type="check-circle"
                theme="twoTone"
                twoToneColor="#52c41a"
                style={{ fontSize: 72, textAlign: 'center' }}
              />
            </Col>
          </Row>
          <br />
          <Title level={2} id="title">
            Account Created!
          </Title>
          <br />
          <br />
          <Row type="flex" justify="center" align="middle">
            <Col xs={12} style={{ textAlign: 'center' }}>
              <Button type="primary" shape="round" size="large" onClick={onComplete}>
                Setup your account
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
