import { Button, Icon, Popconfirm } from 'antd';
import queryString from 'query-string';
import React, { FC, useRef } from 'react';
import config from '../../../config';
import { QueryVariablesInterface } from '../pages/ReferralsContainer';

interface CSVProps {
  organizationId: string;
  text: string;
  variables: QueryVariablesInterface | any;
}

export const CSVDownloadButton: FC<CSVProps> = ({ organizationId, text, variables }): JSX.Element => {
  const anchor = useRef<HTMLAnchorElement>(null);

  const handleNoFilters = (): void => {
    const queryVars = queryString.stringify({
      type: variables.type,
      startDate: variables.startDate,
      endDate: variables.endDate,
      organizationId: variables.organizationId,
      locationId: variables.locationId,
      filters: null,
    });

    if (anchor && anchor.current) {
      anchor.current.href = `${config.endpoint}/download/organizations/${organizationId}/referrals?${queryVars}`;
      anchor.current.click();
    }
  };

  const handleWithFilters = (): void => {
    const queryVars = queryString.stringify(variables);
    if (anchor && anchor.current) {
      anchor.current.href = `${config.endpoint}/download/organizations/${organizationId}/referrals?${queryVars}`;
      anchor.current.click();
    }
  };

  return (
    <a
      ref={anchor}
      href={`${config.endpoint}/download/organizations/${organizationId}/referrals`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <Popconfirm
        title="Export with filters?"
        cancelText="No"
        okText="Yes"
        onCancel={handleNoFilters}
        onConfirm={handleWithFilters}
      >
        <Button htmlType="button">
          {text} <Icon type="cloud-download" />
        </Button>
      </Popconfirm>
    </a>
  );
};
