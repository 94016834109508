import { gql } from '@apollo/client';
import { useMutation, MutationHookOptions, MutationTuple } from '@apollo/client';

const createConsumerSchedulingPatientMutation = gql`
  mutation CreateConsumerSchedulingPatientMutation($organizationId: ID!, $createPatientInput: CreatePatientInput!) {
    createConsumerSchedulingPatient(organizationId: $organizationId, createPatientInput: $createPatientInput) {
      id
    }
  }
`;

interface CreatePatientInput {
  givenName1: string;
  givenName2: string;
  familyName: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  sex: 'MALE' | 'FEMALE';
  dob: string;
  phone: string;
  email?: string;
  insuranceCarrier?: string;
  insurancePlan?: string;
  insuranceGroupNumber?: string;
  insurancePolicyNumber?: string;
}

interface Patient {
  id: string;
}

export interface CreateConsumerSchedulingPatientMutationData {
  createConsumerSchedulingPatient: Patient;
}

interface Variables {
  organizationId: string;
  createPatientInput: CreatePatientInput;
}

type Options = MutationHookOptions<CreateConsumerSchedulingPatientMutationData, Variables>;

type Result = MutationTuple<CreateConsumerSchedulingPatientMutationData, Variables>;

export const useCreateConsumerSchedulingPatientMutation = (options: Options = {}): Result => {
  return useMutation<CreateConsumerSchedulingPatientMutationData, Variables>(
    createConsumerSchedulingPatientMutation,
    options
  );
};
