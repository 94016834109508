import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { DateTime } from 'luxon';
import { DatesBar } from './DatesBar';
import { JumpToDate } from './JumpToDate';
import { ShowWeekends } from './ShowWeekends';

interface Props {
  dates: DateTime[];
  defaultShowWeekends: boolean;
  onClickFuture: () => void;
  onClickPast: () => void;
  onClickShowWeekends: (checked: boolean, event: MouseEvent) => void;
  onSelectStart: (date: DateTime) => void;
}

const Styles = styled.div`
  .jump-to-date,
  .show-weekends {
    margin-left: 20px;
    margin-bottom: 20px;
  }
`;

export const ProfileStepFilterBar: FC<Props> = ({
  dates,
  defaultShowWeekends,
  onClickFuture,
  onClickPast,
  onClickShowWeekends,
  onSelectStart,
}): JSX.Element => {
  return (
    <Styles>
      <Row type="flex" justify="center" align="middle">
        <Col xs={24} lg={10}>
          <Row gutter={16} type="flex" justify="start" align="middle">
            <Col xs={12}>
              <div className="jump-to-date">
                <JumpToDate onChange={onSelectStart} />
              </div>
            </Col>
            <Col xs={12}>
              <div className="show-weekends">
                <ShowWeekends defaultChecked={defaultShowWeekends} onClick={onClickShowWeekends} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={14}>
          <DatesBar dates={dates} onClickFuture={onClickFuture} onClickPast={onClickPast} showMap={false} />
        </Col>
      </Row>
    </Styles>
  );
};
