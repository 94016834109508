import { Icon, message } from 'antd';
import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { DeleteAvailability, ListAvailabilities } from '../../../../GraphQL';
import { Popconfirm } from 'antd';

const CloseIconWrapper = styled.div`
  position: absolute;
  right: 3px;
  top: 1px;
  cursor: pointer;
`;

class DeleteAvailabilityModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      loading: false,
    };
  }

  handleDeleteAvailability = async () => {
    this.setState({ loading: true });

    try {
      await this.props.client.mutate({
        mutation: DeleteAvailability,
        variables: {
          DeleteAvailabilityInput: { id: this.props.availability.id, scheduleId: this.props.scheduleId },
        },
        refetchQueries: [
          {
            query: ListAvailabilities,
            variables: {
              ListAvailabilitiesInput: { scheduleId: this.props.scheduleId },
            },
          },
        ],
      });
    } catch (e) {
      message.warn('An error occured. Please wait a moment and try again.');
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <CloseIconWrapper onClick={this.handleOpenModal}>
          <Popconfirm
            title="Are you sure that you want to remove this time block?"
            onConfirm={this.handleDeleteAvailability}
            okText="Yes"
            cancelText="No"
          >
            <Icon type="close" />
          </Popconfirm>
        </CloseIconWrapper>
      </React.Fragment>
    );
  }
}

export const DeleteAvailabilityModal = withApollo(DeleteAvailabilityModalComponent);
