import * as QueryString from 'query-string';
import React, { FC, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { ProfileStep } from '../components/ProfileStep';
import { useQueryParam, StringParam } from 'use-query-params';
import { InstantProfileStep } from '../components/InstantProfileStep';

interface MatchParams {
  profileId: string;
}

export const ConsumerSchedulingProfilePage: FC<RouteComponentProps<MatchParams>> = ({
  location,
  match: {
    params: { profileId },
  },
}): JSX.Element => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const params = QueryString.parse(location.search);
  const [qsApptType] = useQueryParam('apptType', StringParam);

  const apptType = qsApptType || 'scheduled';

  if (apptType === 'instant') {
    return <InstantProfileStep organization={organization} params={params} profileId={profileId} />;
  } else {
    return <ProfileStep organization={organization} params={params} profileId={profileId} />;
  }
};
