import { PrivateRoute } from '../core';
import React from 'react';
import { WorkflowQueueIndex } from './pages/WorkflowQueueIndex';

export const routes = [
  <PrivateRoute
    exact
    path="/organizations/:organizationId/workflows"
    key="route--workflows-index"
    component={WorkflowQueueIndex}
  />,
];
