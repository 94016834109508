import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string | null;
}

export const InputAddress2: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Address Line 2:">
    {form.getFieldDecorator('address2', {
      initialValue,
      rules: [{ required: false }],
    })(<Input allowClear placeholder="Address Line 2" />)}
  </Form.Item>
);
