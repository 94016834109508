import React, { FC } from 'react';
import { Card } from 'antd';
import { CreateGroupOrganization } from './CreateGroupOrganization';
import { GroupOrganizationsTable } from './GroupOrganizationsTable';

interface Props {
  groupId: string;
  organizations: { id: string; name: string }[];
}

export const GroupOrganizations: FC<Props> = ({ groupId, organizations }): JSX.Element => (
  <Card title="Organizations in Network Group" extra={<CreateGroupOrganization groupId={groupId} />}>
    <GroupOrganizationsTable groupId={groupId} organizations={organizations} />
  </Card>
);
