import React from 'react';
import ConfirmAppointment from './ConfirmAppointment';
import { ConfirmSendToExternal } from './ConfirmSendToExternal';
import { ConfirmSendToPatient } from './ConfirmSendToPatient';
import { ConfirmSendToProvider } from './ConfirmSendToProvider';

export const ConfirmStep = props => {
  if (props.sendToPatient) {
    return <ConfirmSendToPatient {...props} />;
  } else if (props.sendToProvider && props.timeSlot.profile) {
    return <ConfirmSendToProvider {...props} />;
  } else if (props.sendToProvider && props.timeSlot.profile === null) {
    return <ConfirmSendToExternal {...props} />;
  } else {
    return <ConfirmAppointment {...props} />;
  }
};
