import React, { FC } from 'react';
import { Option, Select } from '../../../system/components/Select';

export enum ProfileSortType {
  NextAvailability = 'NEXT_AVAILABILITY',
  Distance = 'DISTANCE',
}

interface Props {
  disableSortByDistance?: boolean;
  onSelect: (value: ProfileSortType) => void;
  sort: ProfileSortType;
}

export const SelectProfilesSort: FC<Props> = ({ disableSortByDistance = false, onSelect, sort }): JSX.Element => (
  <Select onSelect={onSelect} value={sort}>
    <Option value={ProfileSortType.NextAvailability}>Next Available</Option>
    <Option value={ProfileSortType.Distance} disabled={disableSortByDistance}>
      Distance
    </Option>
  </Select>
);
