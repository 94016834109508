import React, { FC, useContext } from 'react';
import { Button, Col, Divider, Form, Row, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { AppContext } from '../../core/contexts/AppContext';
import { InputDisplayName } from './InputDisplayName';
import { Spinner } from '../../../components';
import { SelectLocation } from './SelectLocation';
import { SelectType } from './SelectType';
import { Profile } from '../index';

interface Props extends FormComponentProps {
  form: WrappedFormUtils<ProfileFormData>;
  loading: boolean;
  onSubmit: (values: ProfileFormData) => void;
  profile?: Profile;
}

export interface ProfileFormData {
  displayName: string;
  prefix?: string;
  groupKey?: string;
  firstName?: string;
  lastName?: string;
  suffix?: string;
  phone: string;
  locationId: string;
  email?: string;
  npiNumber?: string;
  slug?: string;
  profileImgUrl?: string;
  specialty?: string;
  tags?: string;
  type: 'location' | 'practitioner' | 'device';
  boardCertifications?: string;
  educationTraining?: string;
  generalPatientInstructions?: string;
  referralSearchNotice?: string;
  professionalStatement?: string;
  languageCodes?: string[];
  hasConsumerScheduling?: boolean;
  hasConsumerSchedulingSlots?: boolean;
  hasReferralScheduling?: boolean;
  hasReferralSchedulingSlots?: boolean;
  hasAppointmentRequests?: boolean;
  sendOffers?: boolean;
  isActive?: boolean;
  isWaitList?: boolean;
  isGlobal?: boolean;
  isIntegrated?: boolean;
  isSendToPatient?: boolean;
  isSendToProvider?: boolean;
  onlyAllowsAgeMax?: number | '';
  onlyAllowsAgeMin?: number | '';
  slotStartBufferInM?: string;
}

export const ProfileFormComponent: FC<Props> = ({ form, loading, onSubmit, profile }): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);

  const handleProfileFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  if (!currentOrganization) {
    return <Spinner />;
  }

  return (
    <Form onSubmit={handleProfileFormSubmit}>
      <Typography.Paragraph>
        A profile is equivelent to a calendar or schedule for a provider, location, or machine.
      </Typography.Paragraph>
      <Typography.Paragraph type="secondary">
        After entering the basics below, you&rsquo;ll be able to manage your schedule templates, visit types, and allow
        patients to schedule online.
      </Typography.Paragraph>
      <Divider />
      <Row type="flex" gutter={[12, 12]}>
        <Col xs={11}>
          <InputDisplayName
            form={form}
            initialValue={profile?.displayName}
            extra={'The name of your profile / schedule.'}
          />
        </Col>
        <Col xs={9}>
          <SelectType
            form={form}
            initialValue={profile?.type || 'practitioner'}
            extra={'The type of resource this is.'}
          />
        </Col>
      </Row>

      <SelectLocation form={form} initialValue={profile?.location.id} organizationId={currentOrganization.id} />

      <Divider />

      <Row type="flex" justify="start">
        <Button htmlType="submit" type="primary" loading={loading}>
          Save Profile &amp; Continue
        </Button>
      </Row>
    </Form>
  );
};

export const ProfileForm = Form.create<Props>()(ProfileFormComponent);
