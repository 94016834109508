import { Tooltip, Icon } from 'antd';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Fieldset, FieldsetTooltip, MultiSelect, Spinner } from '../../../components';
import { GetLocation, UpdateLocation as UpdateLocationQuery } from '../../../GraphQL';
import { FeatureFlag, BlockRestrictedUsers, FeatureGate, formatPhone, formatEmail } from '../../../helpers';
import { AppContextConsumer } from '../../core/contexts/AppContext';
import { isBlockitAdmin } from '../../core/components/PrivateRoute';

const TIME_ZONES = {
  AMERICA_ANCHORAGE: 'America/Anchorage',
  AMERICA_CHICAGO: 'America/Chicago',
  AMERICA_DENVER: 'America/Denver',
  AMERICA_DETROIT: 'America/Detroit',
  AMERICA_LOS_ANGELES: 'America/Los Angeles',
  AMERICA_NEW_YORK: 'America/New York',
  AMERICA_PHOENIX: 'America/Phoenix',
  AMERICA_CURACAO: 'America/Curacao',
};

const LocationFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  displayName: Yup.string(),
  key: Yup.string(),
  slug: Yup.string(),
  phone: Yup.string().required('Required'),
  email: Yup.string().email('Invalid format'),
  address1: Yup.string().required('Required'),
  address2: Yup.string().nullable(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  hasAppointmentRequests: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.boolean().required(),
  }),
  timeZone: Yup.object()
    .required('Required')
    .shape({
      label: Yup.string().required('Required'),
      value: Yup.string().required('Required'),
    }),
});

const EditLocationComponent = ({ getLocation, updateLocationMutation, history }) => {
  if (getLocation.loading) {
    return <Spinner />;
  }

  const { GetLocation } = getLocation;

  if (!GetLocation) return <Spinner />;

  return (
    <AppContextConsumer>
      {({ currentOrganization }) => (
        <div>
          <Formik
            initialValues={{
              id: GetLocation && GetLocation.id ? GetLocation.id : '',
              key: GetLocation && GetLocation.key ? GetLocation.key : '',
              slug: GetLocation && GetLocation.slug ? GetLocation.slug : '',
              name: GetLocation && GetLocation.name ? GetLocation.name : '',
              displayName: GetLocation && GetLocation.displayName ? GetLocation.displayName : '',
              phone: GetLocation && GetLocation.phone ? formatPhone(GetLocation.phone) : '',
              email: GetLocation && GetLocation.email ? formatEmail(GetLocation.email) : '',
              address1: GetLocation && GetLocation.address1 ? GetLocation.address1 : '',
              address2: GetLocation && GetLocation.address2 ? GetLocation.address2 : '',
              city: GetLocation && GetLocation.city ? GetLocation.city : '',
              state: GetLocation && GetLocation.state ? GetLocation.state : '',
              postalCode: GetLocation && GetLocation.postalCode ? GetLocation.postalCode : '',
              country: GetLocation && GetLocation.country ? GetLocation.country : 'United States',
              hasAppointmentRequests:
                GetLocation && typeof GetLocation.hasAppointmentRequests === 'boolean'
                  ? {
                      value: GetLocation.hasAppointmentRequests,
                      label: GetLocation.hasAppointmentRequests ? 'Yes' : 'No',
                    }
                  : null,
              timeZone:
                GetLocation && GetLocation.timeZone
                  ? { value: GetLocation.timeZone, label: TIME_ZONES[GetLocation.timeZone] }
                  : null,
            }}
            validationSchema={LocationFormSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              try {
                const valuesCopy = _.cloneDeep(values);
                const cleanedUpValues = valuesCopy;
                cleanedUpValues.timeZone = valuesCopy.timeZone.value;
                cleanedUpValues.hasAppointmentRequests = valuesCopy.hasAppointmentRequests.value;

                await updateLocationMutation({
                  variables: {
                    UpdateLocationInput: cleanedUpValues,
                  },
                });

                history.push(`/organizations/${currentOrganization.id}/locations`);
              } catch (error) {
                console.error(error);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ errors, touched, isSubmitting, setFieldValue, setFieldTouched, handleSubmit, values }) => (
              <div className="flex flex-wrap">
                <div className="w-1/2 card">
                  <div className="card-header bg-grey-darkest text-grey-lightest font-bold rounded-t">
                    <span className="px-4 py-2 inline-block">
                      Edit Location&nbsp;
                      <Tooltip title={`${GetLocation.latitude}, ${GetLocation.longitude}`}>
                        <Icon type="pushpin" />
                      </Tooltip>
                    </span>
                  </div>
                  {isBlockitAdmin(currentOrganization) ? (
                    <FeatureGate feature={FeatureFlag.ConsumerScheduling}>
                      <div className="pt-2">Consumer Scheduling Link:</div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/consumer/${
                          currentOrganization.slug ? currentOrganization.slug : currentOrganization.id
                        }/search?locationId=${GetLocation.id}`}
                      >
                        {`/consumer/${
                          currentOrganization.slug ? currentOrganization.slug : currentOrganization.id
                        }/search?locationId=${GetLocation.id}`}
                      </a>
                    </FeatureGate>
                  ) : null}
                  <div className="p-4">
                    <Form className="w-full" onSubmit={handleSubmit} noValidate>
                      <Fieldset
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Name"
                        className="w-full input mt-2 border-1"
                      />
                      <FieldsetTooltip
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="displayName"
                        tooltip="This field should contain the name as you would have it appear on your Dashboard. The display name should be shorter in nature for more effective visibility in some tables."
                        label="Display Name"
                        placeholder="Display Name"
                        className="w-full input mt-2 border-1"
                      />
                      <Fieldset
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="key"
                        label="Key"
                        placeholder="Key"
                        className="w-full input mt-2 border-1"
                      />
                      <Fieldset
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="slug"
                        label="URL Slug"
                        placeholder="URL Slug"
                        className="w-full input mt-2 border-1"
                      />
                      <Fieldset
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="phone"
                        label="Phone"
                        placeholder="Phone Number"
                        className="w-full input mt-2 border-1"
                      />
                      <Fieldset
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        className="w-full input mt-2 border-1"
                      />
                      <Fieldset
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="address1"
                        label="Address"
                        placeholder="Address"
                        className="w-full input mt-2 border-1"
                      />
                      <Fieldset
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="address2"
                        label="Address 2"
                        placeholder="Address 2"
                        className="w-full input mt-2 border-1"
                      />
                      <Fieldset
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="city"
                        label="City"
                        placeholder="City"
                        className="w-full input mt-2 border-1"
                      />
                      <div className="flex">
                        <div className="w-1/2 mr-2">
                          <Fieldset
                            errors={errors}
                            touched={touched}
                            type="text"
                            name="state"
                            label="State"
                            placeholder="State"
                            className="w-full input mt-2 border-1"
                          />
                        </div>
                        <div className="w-1/2 ml-2">
                          <Fieldset
                            errors={errors}
                            touched={touched}
                            type="text"
                            name="postalCode"
                            label="Zip Code"
                            placeholder="Zip Code"
                            className="w-full input mt-2 border-1"
                          />
                        </div>
                      </div>
                      <MultiSelect
                        errors={errors}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched}
                        options={[
                          { value: true, label: 'Yes' },
                          { value: false, label: 'No' },
                        ]}
                        value={values.hasAppointmentRequests}
                        name="hasAppointmentRequests"
                        label="Has Appointment Requests"
                        className="w-full"
                        isMulti={false}
                      />
                      <MultiSelect
                        errors={errors}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched}
                        options={[
                          { value: 'AMERICA_ANCHORAGE', label: 'America/Anchorage' },
                          { value: 'AMERICA_CHICAGO', label: 'America/Chicago' },
                          { value: 'AMERICA_DENVER', label: 'America/Denver' },
                          { value: 'AMERICA_DETROIT', label: 'America/Detroit' },
                          { value: 'AMERICA_LOS_ANGELES', label: 'America/Los Angeles' },
                          { value: 'AMERICA_NEW_YORK', label: 'America/New York' },
                          { value: 'AMERICA_PHOENIX', label: 'America/Phoenix' },
                          { value: 'AMERICA_CURACAO', label: 'America/Curacao' },
                        ]}
                        value={values.timeZone}
                        name="timeZone"
                        label="Time Zone"
                        className="w-full"
                        isMulti={false}
                      />
                      <BlockRestrictedUsers>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="w-full flex-1 mt-4 btn btn-blue px-2 py-2"
                        >
                          Save {isSubmitting ? <Icon type="loading" /> : null}
                        </button>
                      </BlockRestrictedUsers>
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      )}
    </AppContextConsumer>
  );
};

export const EditLocation = _.flowRight(
  withRouter,
  graphql(UpdateLocationQuery, { name: 'updateLocationMutation' }),
  graphql(GetLocation, {
    name: 'getLocation',
    options: ownProps => ({
      fetchPolicy: 'no-cache',
      variables: { GetLocationInput: { id: ownProps.match.params.locationId } },
    }),
  })
)(EditLocationComponent);
