import React from 'react'; // Add ReactNode to your imports
import { GetReferralQueryRefetch } from '../components/Referral/GetReferralQuery';

interface ReferralRefetchInterface {
  refetch: GetReferralQueryRefetch;
}

const ReferralRefetchContext = React.createContext<ReferralRefetchInterface | undefined>(undefined);

const useReferralRefetchContext = (): ReferralRefetchInterface => {
  const context = React.useContext(ReferralRefetchContext);

  if (context === undefined) {
    throw new Error('useReferralRefetchContext must be used within a ReferralRefetchContext');
  }

  return context;
};

export { ReferralRefetchContext, useReferralRefetchContext };
