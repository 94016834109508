import React, { FC, FormEvent } from 'react';
import styled from 'styled-components';
import { Button, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InputOrganizationName } from './InputOrganizationName';

interface Props extends FormComponentProps {
  form: WrappedFormUtils<FreeFormData>;
  loading: boolean;
  onSubmit: (values: FreeFormData) => void;
}

export interface FreeFormData {
  organizationName: string;
}

const Styles = styled.div`
  .organization-name-column {
    padding-top: 20px;
  }
`;

const FreeFormComponent: FC<Props> = ({ form, loading, onSubmit }): JSX.Element => {
  const handleFreeFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Styles>
      <Form onSubmit={handleFreeFormSubmit}>
        <Row type="flex" justify="center">
          <Col xs={24} className="organization-name-column">
            <InputOrganizationName autofocus={true} form={form} />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={24}>
            <Button block htmlType="submit" loading={loading} size="large" type="primary">
              Signup
            </Button>
          </Col>
        </Row>
      </Form>
    </Styles>
  );
};

export const FreeForm = Form.create<Props>()(FreeFormComponent);
