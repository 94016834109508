import React from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateAntEmail } from '../../../../helpers';

export interface InviteUserFormData {
  name: string;
  email: string;
}

interface InviteUserComponentProps extends FormComponentProps {
  loading: boolean;
  sent: boolean;
  onSubmit: (arg0: InviteUserFormData) => void;
  form: WrappedFormUtils<InviteUserFormData>;
}

const InviteUserComponent: React.FC<InviteUserComponentProps> = ({ form, loading, sent, onSubmit }) => {
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };
  return (
    <Form onSubmit={handleFormSubmit}>
      <Row type="flex" justify="center" gutter={16}>
        <Col xs={24} md={10}>
          <Form.Item>
            {form.getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Please input an email address',
                },
                {
                  validator: validateAntEmail,
                },
              ],
            })(<Input placeholder="Email Address" disabled={sent} />)}
          </Form.Item>
        </Col>
        <Col xs={24} md={10}>
          <Form.Item>
            {form.getFieldDecorator('name', {
              rules: [
                {
                  min: 3,
                  required: true,
                  message: 'Please input a name',
                },
              ],
            })(<Input placeholder="Name" disabled={sent} />)}
          </Form.Item>
        </Col>
        <Col xs={24} md={4} style={{ paddingBottom: '2rem' }}>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={sent}
            icon={sent ? 'check' : undefined}
            block
          >
            {sent ? 'Sent' : 'Invite'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
export const InviteUserForm = Form.create<InviteUserComponentProps>()(InviteUserComponent);
