import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TextArea } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputTags: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="Tags:"
    extra={
      <>
        <b>IMPORTANT:</b>&nbsp;Enter as TAG_NAME:TAG_VALUE. Multiple tags must be separated by space. No spaces allowed
        in tag name or value
      </>
    }
  >
    {form.getFieldDecorator('tags', {
      initialValue,
      rules: [{ required: false }],
    })(<TextArea allowClear placeholder="Tags" style={{ fontFamily: 'monospace' }} />)}
  </Form.Item>
);
