import { Err, Ok, Result } from 'ts-results';
import config from '../../config';

export interface LoginError {
  status: number;
  message: string;
}

const endpoint = `${config.endpoint}/api/auth/token`;

export const exchangeCredentials = async (email: string, password: string): Promise<Result<string, LoginError>> => {
  const response = await login(email, password);

  if (!response.ok) {
    const message = await response.text();
    return Err({ status: response.status, message });
  }

  const payload = await response.json();
  return Ok(payload.data.token);
};

const login = async (email: string, password: string) =>
  fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ email, password }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  });
