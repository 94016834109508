import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  required?: boolean;
}

export const InputPatientAddress1: FC<Props> = ({ form, required = false }): JSX.Element => (
  <Form.Item label="Patient's Address">
    {form.getFieldDecorator('address1', {
      rules: [{ required: required, message: "Please enter patient's address." }],
    })(<Input allowClear />)}
  </Form.Item>
);
