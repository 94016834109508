import React, { FC } from 'react';
import { Icon } from 'antd';
import { InternalServerErrorAlert } from '../../../components';
import { useCountProfilesInOrganizationQuery } from '../hooks/useCountProfilesInOrganizationQuery';

interface Props {
  organizationId: string;
}

export const ActiveProfileCount: FC<Props> = ({ organizationId }): JSX.Element => {
  const { data, loading, error } = useCountProfilesInOrganizationQuery({
    variables: {
      organizationId,
      searchProfilesInput: {
        isActive: true,
      },
    },
  });

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Icon type="loading" />;
  }

  const { countProfilesInOrganization: count } = data;

  return <>{count}</>;
};
