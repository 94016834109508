import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputSlotDelayBuffer: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="Appointment minimum delay / buffer in minutes:"
    extra="Adds a minimum delay for slots shown when searching. For example, if you don't want appointments to be scheduled less than 24 hours in advance, set this to 1440 minutes (24 hours)."
  >
    {form.getFieldDecorator('slotStartBufferInM', {
      initialValue,
      rules: [{ required: false }],
    })(<Input allowClear placeholder="0" />)}
  </Form.Item>
);
