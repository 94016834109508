import { Alert, Button, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import AntDesignStyleOverrides from '../../core/components/App/AntDesignStyleOverrides';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { InputPatientAddress1 } from './InputPatientAddress1';
import { InputPatientAddress2 } from './InputPatientAddress2';
import { InputPatientCity } from './InputPatientCity';
import { InputPatientEmail } from './InputPatientEmail';
import { InputPatientFirstName } from './InputPatientFirstName';
import { InputPatientInsuranceGroupNumber } from './InputPatientInsuranceGroupNumber';
import { InputPatientInsurancePolicyNumber } from './InputPatientInsurancePolicyNumber';
import { InputPatientLastName } from './InputPatientLastName';
import { InputPatientMiddleName } from './InputPatientMiddleName';
import { InputPatientNotes } from './InputPatientNotes';
import { InputPatientPhone } from './InputPatientPhone';
import { InputPatientPostalCode } from './InputPatientPostalCode';
import { SelectPatientBirthDate } from './SelectPatientBirthDate';
import { SelectPatientGender } from './SelectPatientGender';
import { SelectPatientPayorAndPlan } from './SelectPatientPayorAndPlan';
import { SelectPatientState } from './SelectPatientState';
import { UploadAppointmentAttachment } from './UploadAppointmentAttachment';

export interface PatientFormData {
  questionnaire: {}[];
  givenName1: string;
  givenName2: string;
  familyName: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  sex: 'FEMALE' | 'MALE';
  birthDate: {
    day: number;
    month: number;
    year: number;
  };
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  phone: string;
  email?: string;
  payorId?: string;
  payorPlanId?: string;
  insuranceGroupNumber: string;
  insurancePolicyNumber: string;
  notes?: string;
  appointmentAttachments?: { fileList: FileList };
  questionnaireName?: string;
  questionnaireAnswerKeys?: [];
}

interface Props extends FormComponentProps {
  addAppointmentAttachment: (file: File) => void;
  disabled: boolean;
  form: WrappedFormUtils<PatientFormData>;
  initialPayorId?: string;
  initialPayorPlanId?: string;
  profile: Profile;
  onSubmit: (values: PatientFormData) => void;
  organization: Organization;
  removeAppointmentAttachment: (file: UploadFile) => boolean;
  prefillData: Omit<PatientFormData, 'questionnaire'> | null;
  children: (arg0: { form: WrappedFormUtils }) => JSX.Element;
}

const Styles = styled.div`
  .book-appointment-button {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    height: 38px;
    text-align: center;
    text-decoration: none solid;
    text-transform: uppercase;
    width: 100%;
  }
`;

const PatientFormComponent: FC<Props> = ({
  addAppointmentAttachment,
  children,
  disabled,
  form,
  initialPayorId,
  initialPayorPlanId,
  profile,
  onSubmit,
  organization,
  removeAppointmentAttachment,
  prefillData,
}): JSX.Element => {
  const {
    organization: { consumerSchedulingSettings },
    prefillPatient,
  } = useContext(ConsumerSchedulingContext);

  const [errors, setErrors] = useState(null);

  const handlePatientFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      } else {
        setErrors(err);
      }
    });
  };

  interface FormFieldSetting {
    field: string;
    required: boolean;
  }

  const required: { [key: string]: FormFieldSetting } = {};

  if (profile.isWaitList) {
    ['email', 'phone', 'address1', 'city', 'state', 'postalCode'].forEach(field => {
      required[field] = {
        field: field,
        required: true,
      };
    });
  } else {
    organization.consumerSchedulingSettings?.formFieldSettings.forEach((curr: FormFieldSetting) => {
      required[curr.field] = curr;
    });
  }

  return (
    <AntDesignStyleOverrides>
      <Styles>
        <Form onSubmit={handlePatientFormSubmit}>
          <Row gutter={[24, 0]}>
            <Col xs={24} lg={8}>
              <InputPatientFirstName form={form} initialValue={prefillPatient?.givenName1} />
            </Col>
            <Col xs={24} lg={8}>
              <InputPatientMiddleName form={form} required={required?.givenName2?.required} />
            </Col>
            <Col xs={24} lg={8}>
              <InputPatientLastName form={form} initialValue={prefillPatient?.familyName} />
            </Col>
            <Col xs={24} lg={12} id="patient-address-1">
              <InputPatientAddress1 form={form} required={required?.address1?.required} />
            </Col>
            <Col xs={24} lg={12} id="patient-address-2">
              <InputPatientAddress2 form={form} required={required?.address2?.required} />
            </Col>
            <Col xs={24} lg={12} id="blockit-patient-form-city">
              <InputPatientCity form={form} required={required?.city?.required} />
            </Col>
            <Col xs={12} lg={6} id="blockit-patient-form-state">
              <SelectPatientState form={form} required={required?.state?.required} />
            </Col>
            <Col xs={12} lg={6} id="blockit-patient-form-postalCode">
              <InputPatientPostalCode form={form} required={required?.postalCode?.required} />
            </Col>
            <Col xs={24} lg={12}>
              <SelectPatientGender initialValue={prefillPatient?.sex} form={form} />
            </Col>
            <Col xs={24} lg={12}>
              <SelectPatientBirthDate form={form} initialValue={prefillPatient?.birthDate} />
            </Col>
            <Col xs={24} lg={12} id="blockit-patient-form-phone">
              <InputPatientPhone form={form} required={required?.phone?.required} />
            </Col>
            <Col xs={24} lg={12} id="blockit-patient-form-email">
              <InputPatientEmail form={form} required={required?.email?.required} />
            </Col>
            <div id="blockit-patient-form-insurance">
              <Col xs={24}>
                <SelectPatientPayorAndPlan
                  form={form}
                  initialPayorId={initialPayorId}
                  initialPayorPlanId={initialPayorPlanId}
                  required={required?.payorPlanId?.required}
                />
              </Col>
              <Col xs={24} lg={12} id="blockit-patient-form-group-number">
                <InputPatientInsuranceGroupNumber form={form} required={required?.insuranceGroupNumber?.required} />
              </Col>
              <Col xs={24} lg={12} id="blockit-patient-form-policy-number">
                <InputPatientInsurancePolicyNumber form={form} required={required?.insurancePolicyNumber?.required} />
              </Col>
            </div>
            <Col xs={24}>{children({ form })}</Col>
            <Col xs={24} id="blockit-patient-form-notes">
              <InputPatientNotes form={form} isWaitList={profile.isWaitList} />
            </Col>
            {consumerSchedulingSettings?.allowAttachments ? (
              <Col xs={24}>
                <UploadAppointmentAttachment
                  addAppointmentAttachment={addAppointmentAttachment}
                  form={form}
                  removeAppointmentAttachment={removeAppointmentAttachment}
                />
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col span={24}>
              {errors && (
                <Alert
                  message="Form submission missing required fields, please scroll up and fix the highlighted fields."
                  type="error"
                  showIcon
                />
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <Button
                disabled={disabled}
                loading={disabled}
                className="book-appointment-button"
                type="primary"
                htmlType="submit"
              >
                Book Appointment
              </Button>
            </Col>
          </Row>
        </Form>
      </Styles>
    </AntDesignStyleOverrides>
  );
};

export const PatientForm = Form.create<Props>({
  // Needed this to support patient prefill, but causes form to go empty when atachments are added
  // mapPropsToFields(props) {
  //   if (props.prefillData !== null) {
  //     return {
  //       givenName1: Form.createFormField({ value: props.prefillData.givenName1 }),
  //       familyName: Form.createFormField({ value: props.prefillData.familyName }),
  //       address1: Form.createFormField({ value: props.prefillData.address1 }),
  //       address2: Form.createFormField({ value: props.prefillData.address2 }),
  //       city: Form.createFormField({ value: props.prefillData.city }),
  //       state: Form.createFormField({ value: props.prefillData.state }),
  //       postalCode: Form.createFormField({ value: props.prefillData.postalCode }),
  //       sex: Form.createFormField({ value: props.prefillData.sex }),
  //       birthDate: Form.createFormField({ value: props.prefillData.birthDate }),
  //       birthDay: Form.createFormField({ value: props.prefillData.birthDay }),
  //       birthMonth: Form.createFormField({ value: props.prefillData.birthMonth }),
  //       birthYear: Form.createFormField({ value: props.prefillData.birthYear }),
  //       phone: Form.createFormField({ value: props.prefillData.phone }),
  //       email: Form.createFormField({ value: props.prefillData.email }),
  //       payorId: Form.createFormField({ value: props.prefillData.payorId }),
  //       payorPlanId: Form.createFormField({ value: props.prefillData.payorPlanId }),
  //       insuranceGroupNumber: Form.createFormField({ value: props.prefillData.insuranceGroupNumber }),
  //       insurancePolicyNumber: Form.createFormField({ value: props.prefillData.insurancePolicyNumber }),
  //     };
  //   }
  // },
})(PatientFormComponent);
