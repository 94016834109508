import { Icon, Form } from 'antd';
import { gql } from '@apollo/client';
import _ from 'lodash';
import React, { FC, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { CreateProfileProcedure, GetProfile } from '../../../GraphQL';
import { DocumentNode } from 'graphql';
import { Option, Select } from '../../../system/components/Select';
import { Input } from '../../../system/components/Input';
import { WrappedFormUtils } from 'antd/lib/form/Form';

const DEFAULT_DURATION = 30;

const listSpecialtiesQuery = gql`
  query listSpecialtiesQuery($isActive: Boolean) {
    listSpecialties(isActive: $isActive) {
      id
      name
      procedures {
        id
        name
      }
    }
  }
`;

interface Specialty {
  id: string;
  name: string;
  procedures: {
    id: string;
    name: string;
  }[];
}

interface Props {
  profile: {
    isIntegrated: boolean;
    id: string;
    type: string;
  };
  createProfileProcedure: (arg0: {
    variables: { CreateProfileProcedureInput: CreateProfileProcedureInput };
    refetchQueries: { query: DocumentNode; variables: { profileId: string } }[];
  }) => void;
  closeModal: () => void;
  listSpecialtiesQuery: {
    listSpecialties: Specialty[];
  };
  form: WrappedFormUtils;
}

interface CreateProfileProcedureInput {
  profileId: string;
  cptCode: string;
  duration: number | undefined;
  procedureId: string | undefined;
}

const ProcedureFormEnhanced: FC<Props> = ({
  profile,
  createProfileProcedure,
  closeModal,
  listSpecialtiesQuery,
  form,
}) => {
  const initialValues: CreateProfileProcedureInput = {
    profileId: profile.id,
    cptCode: '',
    duration: profile.isIntegrated ? undefined : DEFAULT_DURATION,
    procedureId: undefined,
  };

  const [selectedSpecialty, setSelectedSpecialty] = useState<Specialty | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const specialties = listSpecialtiesQuery.listSpecialties?.filter(s => {
    if (s.name === 'Imaging/Radiology' && (profile.type === 'provider' || profile.type === 'location')) {
      return false;
    } else if (s.name === 'BEAUMONT' || s.name === '') {
      return false;
    } else {
      return true;
    }
  });

  const selectSpecialty = (): JSX.Element => (
    <div>
      <Form.Item label="Specialty" required>
        {form.getFieldDecorator('specialtyId', {
          rules: [{ required: true, message: 'Required' }],
        })(
          <Select
            showSearch
            placeholder="Select a specialty from this list"
            optionFilterProp="children"
            onChange={value => {
              form.setFieldsValue({ procedureId: undefined });
              setSelectedSpecialty(specialties?.find(s => s.id === value));
            }}
          >
            {(specialties || []).map(specialty => (
              <Option value={specialty.id} key={specialty.id}>
                {specialty.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </div>
  );

  const selectVisitType = (): JSX.Element => (
    <div>
      <Form.Item label="Visit Type">
        {form.getFieldDecorator('procedureId', {
          rules: [{ required: true, message: 'Required' }],
        })(
          <Select
            showSearch
            placeholder="Select a visit type from this list"
            optionFilterProp="children"
            onChange={value => {
              form.setFieldsValue({ procedureId: value });
            }}
          >
            {(selectedSpecialty?.procedures || []).map(procedure => (
              <Option value={procedure.id} key={procedure.id}>
                {procedure.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </div>
  );

  const enterAppointmentLength = (): JSX.Element => (
    <div>
      {typeof profile.isIntegrated === 'boolean' && profile.isIntegrated && (
        <Form.Item label="Appointment Length">
          {form.getFieldDecorator('duration', {
            initialValue: initialValues.duration,
            rules: [{ required: true, message: 'Required' }],
          })(
            <Input
              type="number"
              placeholder="Appointment Length in Minutes"
              onChange={e => {
                const value = parseInt(e.target.value, 10);
                if (!isNaN(value)) {
                  form.setFieldsValue({ duration: value });
                }
              }}
            />
          )}
        </Form.Item>
      )}
    </div>
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    let isFormValid = true;
    form.validateFields((err, values) => {
      if (err) {
        isFormValid = false;
        return;
      }
    });

    try {
      const values = {
        procedureId: form.getFieldValue('procedureId'),
        profileId: profile.id,
        cptCode: '',
        duration: parseInt(form.getFieldValue('duration'), 10),
      } as CreateProfileProcedureInput;

      setIsSubmitting(true);
      await createProfileProcedure({
        variables: {
          CreateProfileProcedureInput: values,
        },
        refetchQueries: [
          {
            query: GetProfile,
            variables: {
              profileId: profile.id,
            },
          },
        ],
      });
    } catch (error) {
      const graphQLErrors = (error as { graphQLErrors?: { message: string }[] }).graphQLErrors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        form.setFieldsValue({ cptCode: graphQLErrors[0].message });
      }
    } finally {
      setIsSubmitting(false);
      if (isFormValid) {
        closeModal();
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <hr />
      {selectSpecialty()}
      {selectVisitType()}
      {enterAppointmentLength()}
      <button type="submit" disabled={isSubmitting} className="w-full flex-1 mt-4 btn btn-blue px-2 py-2">
        Save {isSubmitting ? <Icon type="loading" /> : null}
      </button>
    </Form>
  );
};

export const WrappedProcedureFormEnhanced = Form.create<Props>()(ProcedureFormEnhanced);

interface ListSpecialtiesQueryData {
  listSpecialties: Specialty[];
}

export const ProcedureForm = _.flowRight(
  graphql<{}, CreateProfileProcedureInput>(CreateProfileProcedure, { name: 'createProfileProcedure' }),
  graphql<ListSpecialtiesQueryData, {}>(listSpecialtiesQuery, {
    name: 'listSpecialtiesQuery',
    options: ownProps => ({
      variables: { isActive: true },
    }),
  })
)(WrappedProcedureFormEnhanced);
