import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { FiltersInterface } from '../../referrals/components/ReferralsTable/ReferralsTable';

const listAppointmentsQuery = gql`
  query listAppointments(
    $organizationId: ID!
    $patientId: ID
    $locationId: ID
    $pageSize: Int
    $pageNumber: Int
    $startDate: String
    $endDate: String
    $filters: String
    $sortOrder: AppointmentOrdering
    $sortField: AppointmentSortColumn
  ) {
    listAppointments(
      organizationId: $organizationId
      patientId: $patientId
      locationId: $locationId
      pageSize: $pageSize
      pageNumber: $pageNumber
      startDate: $startDate
      endDate: $endDate
      filters: $filters
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      pageSize
      pageNumber
      totalEntries
      filters {
        appointmentStatuses {
          value
          text
        }
        profiles {
          value
          text
        }
        specialties {
          value
          text
        }
      }
      entries {
        id
        status
        insertedAt
        rescheduledTo {
          id
        }
        patient {
          id
          identifier
          givenName1
          familyName
        }
        start
        end
        procedure {
          id
          name
          display
          specialty {
            id
            name
          }
        }
        profile {
          id
          displayName
        }
        tags {
          key
          value
        }
      }
    }
  }
`;

export interface Appointment {
  id: string;
  status: string;
  comment: string;
  start: string;
  end: string;
  insertedAt: string;
  rescheduledTo?: {
    id: string;
    start: string;
  };
  rescheduledFrom?: {
    id: string;
    start: string;
  };
  rescheduleLink: string;
  profile: ProfileInterface;
  procedure: {
    id: string;
    code: string;
    display: string;
    isUnspecified: boolean;
    name: string;
    specialty: {
      id: string;
      name: string;
    };
  };
  referral?: {
    id: string;
    provider: {
      id: string;
    };
  };
  notes: {
    id: string;
    author: string;
    body: string;
    insertedAt: string;
  }[];
  patient: {
    id: string;
    identifier: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    postalCode: string | null;
    dob: string | null;
    email: string | null;
    familyName: string | null;
    givenName1: string | null;
    givenName2: string | null;
    givenName3: string | null;
    phone: string | null;
    sex: string | null;
    coverage?: {
      id?: string;
      groupId?: string;
      network?: string;
      planId?: string;
      planName?: string;
    };
  };
  appointmentAttachments: AppointmentAttachment[];
  tags: {
    key: string;
    value: string | null;
  }[];
}

export interface AppointmentAttachment {
  id: string;
  attachmentUrl: string;
  displayFilename: string;
}

// todo - refactor to pull from a consolidated profile definition
export interface ProfileInterface {
  id: string;
  displayName: string;
  generalPatientInstructions: string;
  phone: string;
  type: string;
  specialty?: string;
  isAccessCenter: boolean;
  profileImgUrl: string;
  location: {
    id: string;
    name: string;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    postalCode: string | null;
    distanceFromPatient: number | null;
  };
  organization: {
    id: string;
    name: string;
  };
}

interface Data {
  listAppointments: {
    entries: Appointment[];
    pageSize: number;
    pageNumber: number;
    totalEntries: number;
    filters: {
      appointmentStatuses: FiltersInterface[];
      profiles: FiltersInterface[];
      specialties: FiltersInterface[];
    };
  };
}

interface Variables {
  organizationId?: string;
  patientId?: string;
  locationId?: string;
  pageSize: number;
  pageNumber: number;
  startDate?: string;
  endDate?: string;
  filters?: string | string[];
  sortOrder?: string | string[] | null;
  sortField?: string | string[] | null;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useListAppointmentsQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(listAppointmentsQuery, options);
};
