import React, { FC } from 'react';
import { Form, Switch } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectWaitList: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Wait List:">
    {form.getFieldDecorator('isWaitList', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ message: `Please select your profile's wait list status.`, required: true }],
    })(<Switch />)}
  </Form.Item>
);
