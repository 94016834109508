import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';
import { Specialty } from '../hooks/useGetProcedureQuery';
import { Option, Select } from '../../../system/components/Select';

interface Props {
  form: WrappedFormUtils;
  initialSpecialty?: Specialty;
  onSelect: (value: string) => void;
  specialties: Specialty[];
}

export const SelectSpecialty: FC<Props> = ({ form, initialSpecialty, onSelect, specialties }): JSX.Element => (
  <Form.Item label="What is the desired specialty?">
    {form.getFieldDecorator('specialtyId', {
      initialValue: initialSpecialty?.id,
      rules: [
        {
          message: 'Please select a specialty',
          required: true,
        },
      ],
    })(
      <Select filterOption={caseInsensitiveSelectFilterOption} onSelect={onSelect} placeholder="Select a specialty">
        {specialties.map(specialty => (
          <Option value={specialty.id} key={specialty.id}>
            {specialty.name}
          </Option>
        ))}
      </Select>
    )}
  </Form.Item>
);
