import { ErrorMessage } from 'formik';
import React from 'react';
import Select from 'react-select';

/**
 * @deprecated
 */
export const MultiSelect = ({
  className,
  onChange,
  onBlur,
  options,
  value,
  name,
  label,
  touched,
  errors,
  isMulti,
  ...rest
}) => {
  name = `${name}`;
  return (
    <React.Fragment>
      <div className="mt-2">
        <label className="text-label" htmlFor={name}>
          {label}
        </label>
        <Select
          key={name}
          isMulti={isMulti || false}
          isSearchable
          onChange={val => onChange(name, val)}
          onBlur={() => onBlur(name, true)}
          value={value}
          options={options}
          name={name}
          className={className.concat(touched[name] && errors[name] ? ' border border-red' : '') + ' mt-2 rounded'}
          {...rest}
          style={{ ':focus': '#8795a1' }}
        />
        <div className="text-red text-xs">
          <ErrorMessage name={name}>{() => 'Required'}</ErrorMessage>
        </div>
      </div>
    </React.Fragment>
  );
};
