import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Password } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  validator: (rule: any, value: string, callback: (arg0?: string) => void) => void;
}

const passwordValidator = (form: WrappedFormUtils) => {
  return {
    validator(rule: any, value: string, callback: (arg0?: string) => void) {
      if (!value) {
        callback('Please enter your password.');
      } else if (value.length < 10) {
        callback('Password must be at least 10 characters.');
      } else if (!value.match(/(?=.*[A-Z])/)) {
        callback('Password must include at least one uppercase letter.');
      } else if (!value.match(/(?=.*[a-z])/)) {
        callback('Password must include at least one lowercase letter.');
      } else if (!value.match(/(?=.*[0-9])/)) {
        callback('Password must include at least one number.');
      } else if (!value.match(/(?=.*[!@#$%^&*])/)) {
        callback('Password must include at least one symbol (!@#$%^&*).');
      } else {
        // Assuming `username`, `firstName`, and `lastName` are accessible here, and you have a function to check for common passwords
        if (value.includes(form.getFieldValue('username'))) {
          callback('Password cannot contain your username.');
        } else if (value.includes(form.getFieldValue('firstName'))) {
          callback('Password cannot contain your first name.');
        } else if (value.includes(form.getFieldValue('lastName'))) {
          callback('Password cannot contain your last name.');
        } else {
          callback();
        }
      }
    },
  };
};

export const InputUserPassword: FC<Props> = ({ form }): JSX.Element => (
  <Form.Item label="Password:">
    {form.getFieldDecorator('password', {
      rules: [passwordValidator(form)],
    })(<Password visibilityToggle={true} />)}
  </Form.Item>
);
