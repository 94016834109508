import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const countLocationsInOrganizationQuery = gql`
  query CountLocationsInOrganizationQuery($organizationId: ID!) {
    countLocationsInOrganization(organizationId: $organizationId)
  }
`;

interface Data {
  countLocationsInOrganization: number;
}

interface Variables {
  organizationId: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useCountLocationsInOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(countLocationsInOrganizationQuery, options);
};
