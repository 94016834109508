import { Button, Col, Input, Row } from 'antd';
import { gql } from '@apollo/client';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { RouteComponentProps } from 'react-router-dom';
import { Spinner } from '../../../components';
import { AssignGroupLocations } from '../components/AssignGroupLocations';
import { GroupOrganizations } from '../components/GroupOrganizations';
import { GroupProfiles } from '../components/GroupProfiles';

interface Data {
  getGroup: Group;
}

interface Group {
  id: string;
  name: string;
  isAllLocations: boolean;
  organizations: Organization[];
  locations: Location[];
  profiles: Profile[];
}

interface Location {
  id: string;
  name: string;
}

interface MatchParams {
  groupId: string;
}

interface Organization {
  id: string;
  name: string;
}

interface Profile {
  id: string;
  displayName: string;
  location: Location;
  organization: Organization;
}

interface Variables {
  id: string;
}

const getGroupQuery = gql`
  query getGroupQuery($id: ID!) {
    getGroup(id: $id) {
      id
      name
      isReferralNetwork
      isActive
      isAllLocations
      organizations {
        id
        name
      }
      locations {
        id
        name
      }
      profiles {
        id
        displayName
        location {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

const updateGroupMutation = gql`
  mutation UpdateGroup($UpdateGroupInput: UpdateGroupInput!) {
    UpdateGroup(UpdateGroupInput: $UpdateGroupInput) {
      id
      name
      is_referral_network
      is_active
    }
  }
`;

interface UpdateGroupVariables {
  UpdateGroupInput: {
    id: string;
    isActive: boolean;
  };
}

const EditGroupName: FC<{ currentName: string; setIsEditing: Dispatch<SetStateAction<boolean>>; groupId: string }> = ({
  currentName,
  setIsEditing,
  groupId,
}): JSX.Element => {
  const [name, setName] = useState<string>(currentName);
  const [updateGroup] = useMutation<UpdateGroupVariables>(updateGroupMutation);

  const handleOnSave = (): void => {
    updateGroup({
      variables: {
        UpdateGroupInput: {
          id: groupId,
          name,
        },
      },
      // refetchQueries: [{ query: groupsQuery, variables: { organizationId } }],
    }).then(() => {
      setIsEditing(false);
    });
  };

  return (
    <div className="ant-page-header ant-page-header-ghost">
      <div className="ant-page-header-heading">
        <div className="ant-page-header-heading-title d-flex">
          <Input type="text" defaultValue={name} onChange={e => setName(e.target.value)} />
          <Button type="primary" size="small" style={{ marginLeft: '1em' }} onClick={handleOnSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export const UpdateGroup: FC<RouteComponentProps<MatchParams>> = (props): JSX.Element => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <Query<Data, Variables> query={getGroupQuery} variables={{ id: props.match.params.groupId }}>
      {({ loading, error, data }) => {
        if (loading) return <Spinner />;
        if (error || !data) return <div>Error</div>;

        const {
          getGroup: { id, name, organizations, locations, profiles, isAllLocations },
        } = data;

        return (
          <>
            {isEditing ? (
              <EditGroupName currentName={name} setIsEditing={setIsEditing} groupId={props.match.params.groupId} />
            ) : (
              <div className="ant-page-header ant-page-header-ghost">
                <div className="ant-page-header-heading">
                  <div className="ant-page-header-heading-title d-flex align-items-center">
                    {name}{' '}
                    <Button
                      htmlType="button"
                      icon="edit"
                      shape="circle"
                      size="large"
                      type="link"
                      onClick={() => setIsEditing(true)}
                    />
                  </div>
                </div>
              </div>
            )}
            <Row type="flex" justify="space-between">
              <Col sm={24} md={17}>
                <GroupOrganizations groupId={id} organizations={organizations} />
                <br />
                <GroupProfiles groupId={id} profiles={profiles} />
              </Col>
              <Col sm={24} md={6}>
                <AssignGroupLocations groupId={id} isAllLocations={isAllLocations} locations={locations} />
              </Col>
            </Row>
          </>
        );
      }}
    </Query>
  );
};
