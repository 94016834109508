import React, { FC } from 'react';
import styled from 'styled-components';
import { PublicProfile } from '../containers/DirectoryContext';
import { ProfileCardInfo } from './ProfileCardInfo';
import { ProfileCardName } from './ProfileCardName';

interface Props {
  profile: PublicProfile;
}

const Styles = styled.div`
  .profile-body {
    margin-bottom: -2px;
    margin-left: 16px;
    margin-top: -4px;
    max-width: 432px;
    min-width: 100%;
    padding-right: 16px;

    ul {
      margin-bottom: 0;
    }
  }

  width: 100%;
`;

export const ProfileCardBody: FC<Props> = ({ profile }): JSX.Element => (
  <Styles>
    <div className="profile-body">
      <ProfileCardName profile={profile} />
      <br />
      <ProfileCardInfo profile={profile} />
    </div>
  </Styles>
);
