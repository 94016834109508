import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';
import { Option, Select } from '../../../system/components/Select';
import { Procedure } from '../hooks/useGetProcedureQuery';

interface Props {
  form: WrappedFormUtils;
  initialProcedure?: Procedure;
  label?: string;
  procedures: Procedure[] | undefined;
}

export const SelectProcedure: FC<Props> = ({ form, initialProcedure, label, procedures }): JSX.Element => (
  <Form.Item label={label ? label : 'What is the desired visit type?'}>
    {form.getFieldDecorator('procedureId', {
      initialValue: initialProcedure?.id,
      rules: [
        {
          required: true,
          message: 'Please select a visit type',
        },
      ],
    })(
      <Select
        disabled={!procedures}
        filterOption={caseInsensitiveSelectFilterOption}
        placeholder="Select a visit type"
        dropdownMatchSelectWidth={false}
      >
        {procedures?.map(procedure => (
          <Option value={procedure.id} key={procedure.id}>
            {procedure.name}
          </Option>
        ))}
      </Select>
    )}
  </Form.Item>
);
