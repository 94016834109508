import { Avatar } from 'antd';
import React, { FC } from 'react';

interface Props {
  imgUrl: string;
}

export const ProfilePhoto: FC<Props> = ({ imgUrl, children }) => (
  <div className="flex flex-col">
    {imgUrl && imgUrl.length > 0 ? (
      <Avatar shape="square" size={128} src={imgUrl} />
    ) : (
      <Avatar shape="square" size={128} icon="user" />
    )}
    {children}
  </div>
);
