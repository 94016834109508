import * as Sentry from '@sentry/browser';
import { Button, message } from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { SentToProviderTag } from '../SentToProviderTag';
import { ProfileReferral } from './GetReferralQuery';

interface Data {
  updateProfileReferral: {
    id: string;
  };
}

interface Props {
  profileReferral: ProfileReferral;
}

interface Variables {
  id: string;
  updateProfileReferralInput: {};
}

const updateProfileReferralMutation = gql`
  mutation UpdateProfileReferral($id: ID!, $updateProfileReferralInput: UpdateProfileReferralInput!) {
    updateProfileReferral(id: $id, updateProfileReferralInput: $updateProfileReferralInput) {
      id
    }
  }
`;

const Styles = styled.div`
  .resend {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    margin-top: 4px;
  }
`;

export const SentToProvider: FC<Props> = ({ profileReferral }): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const [updateProfileReferral] = useMutation<Data, Variables>(updateProfileReferralMutation, {
    onCompleted: () => {
      setLoading(false);
      message.success(`Successfully resent referral to provider.`);
    },
    onError: error => {
      setLoading(false);
      message.error('There was an error resending this referral to the provider.  Please try again in a few minutes');
      Sentry.captureException(error);
    },
    variables: {
      id: profileReferral.id,
      updateProfileReferralInput: {},
    },
  });

  const handleResendToProfile = (): void => {
    setLoading(true);
    updateProfileReferral().then(() => {});
  };

  return (
    <Styles>
      <div className="resend">
        <SentToProviderTag profile={profileReferral.profile} />
        <Button size="small" type="primary" loading={loading} onClick={handleResendToProfile}>
          Resend
        </Button>
      </div>
    </Styles>
  );
};
