import { gql } from '@apollo/client';
import { useMutation, MutationHookOptions, MutationTuple } from '@apollo/client';

const deleteProfileProcedureMutation = gql`
  mutation DeleteProfileProcedureMutation($id: ID!) {
    deleteProfileProcedure(id: $id) {
      id
    }
  }
`;

export interface Data {
  id: string;
}

interface Variables {
  id: string;
}

type Options = MutationHookOptions<Data, Variables>;

type Result = MutationTuple<Data, Variables>;

export const useDeleteProfileProcedureMutation = (options: Options): Result => {
  return useMutation<Data, Variables>(deleteProfileProcedureMutation, options);
};
