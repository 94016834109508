import React, { FC, FocusEvent } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Password } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  validator: (rule: string, value: string, callback: (arg0?: string) => void) => void;
}

export const InputUserPasswordConfirmation: FC<Props> = ({ form, onBlur, validator }): JSX.Element => (
  <Form.Item label="Confirm Password:">
    {form.getFieldDecorator('passwordConfirmation', {
      rules: [{ message: 'Please confirm your password.', required: true }, { validator }],
    })(<Password onBlur={onBlur} />)}
  </Form.Item>
);
