import React, { FC } from 'react';
import { List, Button } from 'antd';
import styled from 'styled-components';
import {
  WorkflowQueueTask,
  useAcceptTaskMutation,
  TaskStatus,
  useFinishTaskMutation,
} from '../../../generated/graphql';
import { formatPhone } from '../../../helpers';
import { Link } from 'react-router-dom';

const Styles = styled.div``;

export const ApptWorkflowList: FC<{ tasks: WorkflowQueueTask[]; viewerWatching: boolean }> = ({
  tasks,
  viewerWatching,
}): JSX.Element => {
  return (
    <Styles>
      <List
        bordered={false}
        dataSource={tasks}
        renderItem={task => <ApptWorkflowListItem task={task} viewerWatching={viewerWatching} />}
      />
    </Styles>
  );
};

interface SBProps {
  id: string;
  viewerWatching: boolean;
  viewerIsOwner: boolean;
  task: WorkflowQueueTask;
}

const WorkflowTaskButton: FC<SBProps> = ({ id, viewerWatching, viewerIsOwner, task }): JSX.Element | null => {
  const [acceptTask] = useAcceptTaskMutation();
  const [finishTask] = useFinishTaskMutation();

  if (!viewerWatching) {
    return null;
  }

  if (task.status === TaskStatus.Ready)
    return (
      <>
        <Button
          type="primary"
          onClick={() => {
            acceptTask({
              variables: {
                taskId: id,
              },
            });
          }}
        >
          Accept
        </Button>
      </>
    );
  if (task.status === TaskStatus.Inprogress && viewerIsOwner)
    return (
      <Button
        type="primary"
        onClick={() => {
          finishTask({
            variables: {
              taskId: id,
            },
          });
        }}
      >
        Finish
      </Button>
    );
  if (task.status === TaskStatus.Inprogress && !viewerIsOwner) return <span>Accepted by {task.user?.name}</span>;

  if (task.status === TaskStatus.Finished) return <span>Finished</span>;

  return null;
};

const ApptWorkflowListItem: FC<{ task: WorkflowQueueTask; viewerWatching: boolean }> = ({
  task,
  viewerWatching,
}): JSX.Element | null => {
  if (!task) return null;

  const patient = task?.appointment?.patient;

  const phone = formatPhone(patient?.phone || '');
  let address = `${patient?.address1} ${patient?.city}, ${patient?.postalCode}`;

  if (!patient?.address1) {
    address = 'Unknown Address';
  }

  return (
    <List.Item
      actions={[
        <WorkflowTaskButton
          id={task.id}
          key="startBtn"
          viewerWatching={viewerWatching}
          task={task}
          viewerIsOwner={task.viewerIsOwner}
        />,
        <Link to={`/appointments/${task.appointment?.id}`} key="link">
          <Button>Details</Button>
        </Link>,
      ]}
      className="item"
    >
      <List.Item.Meta
        key={task.id}
        title={
          <span style={{ fontSize: 16 }}>
            {patient?.givenName1} {patient?.familyName} ({task?.appointment?.procedure?.name})
          </span>
        }
        description={`${phone || 'Unknown Phone'} / ${address}`}
      />
    </List.Item>
  );
};
