import { gql } from '@apollo/client';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';

const createUserMutation = gql`
  mutation CreateUserMutation($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
      email
    }
  }
`;

interface CreateUserInput {
  name: string;
  email: string;
  password: string;
  isActive?: boolean;
}

interface User {
  id: string;
  email: string;
}

export interface CreateUserMutationData {
  createUser: User;
}

interface Variables {
  createUserInput: CreateUserInput;
}

type Options = MutationHookOptions<CreateUserMutationData, Variables>;

type Result = MutationTuple<CreateUserMutationData, Variables>;

export const useCreateUserMutation = (options: Options = {}): Result => {
  return useMutation<CreateUserMutationData, Variables>(createUserMutation, options);
};
