import _ from 'lodash';
import { DateTime } from 'luxon';

export const generateDates = (start: DateTime, includeWeekends = true): DateTime[] => {
  const dates = [];
  for (let i = 0; i < 11; i++) {
    const date = _.cloneDeep(start).plus({ days: i });
    if (includeWeekends || parseInt(date.toFormat('c')) <= 5) {
      dates.push(date);
    }
  }

  return dates.slice(0, 7);
};

export const generateFutureDates = (start: DateTime, includeWeekends = true): DateTime[] => {
  let nextStart = start.plus({ days: 3 });

  if (!includeWeekends && parseInt(start.toFormat('c')) >= 3 && parseInt(start.toFormat('c')) <= 6) {
    nextStart = nextStart.plus({ days: 2 });
  }

  if (!includeWeekends && parseInt(start.toFormat('c')) === 7) {
    nextStart = nextStart.plus({ days: 1 });
  }

  return generateDates(nextStart, includeWeekends);
};

export const generatePastDates = (start: DateTime, includeWeekends = true): DateTime[] => {
  const now = DateTime.local();
  const today = DateTime.fromObject({ year: now.year, month: now.month, day: now.day });

  let nextStart = start.plus({ days: -3 });

  if (!includeWeekends && parseInt(start.toFormat('c')) <= 3) {
    nextStart = nextStart.plus({ days: -2 });
  }

  if (!includeWeekends && parseInt(start.toFormat('c')) === 7) {
    nextStart = nextStart.plus({ days: -1 });
  }

  if (nextStart.diff(today).milliseconds < 0) {
    nextStart = DateTime.local();
  }

  return generateDates(nextStart, includeWeekends);
};
