interface StringLabelOption {
  label: string;
  value: string;
}

interface NumberLabelOption {
  label: number;
  value: string;
}

interface StringNumberLabelOption {
  label: string;
  value: number;
}

export const stateNames = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const formikCalendarMonths = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const formikCalendarDays = (month: { value: string }): NumberLabelOption[] => {
  const dayOptions = [];
  let days = 0;
  const currentYear = parseInt(formikCalendarYears()[0].value, 10);

  switch (month.value) {
    case '02':
      days = isLeapYear(currentYear) ? 29 : 28;
      break;
    case '09':
    case '04':
    case '06':
    case '11':
      days = 30;
      break;
    default:
      days = 31;
  }

  for (let i = 1; i <= days; i++) {
    dayOptions.push({ label: i, value: `0${i}`.slice(-2) });
  }

  return dayOptions;
};

export const formikCalendarYears = (): StringLabelOption[] => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = 0; i < 10; i++) {
    const year = (currentYear + i).toString();
    years.push({ label: year, value: year });
  }

  return years;
};

export const availabilityHoursOfDay = (): StringLabelOption[] => {
  return [
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '00' },
  ];
};

export const availabilityMinutesOfDay = (): StringLabelOption[] => {
  return [
    { label: '00', value: '00' },
    { label: '05', value: '05' },
    { label: '10', value: '10' },
    { label: '15', value: '15' },
    { label: '20', value: '20' },
    { label: '25', value: '25' },
    { label: '30', value: '30' },
    { label: '35', value: '35' },
    { label: '40', value: '40' },
    { label: '45', value: '45' },
    { label: '50', value: '50' },
    { label: '55', value: '55' },
  ];
};

export const availabilityAmPm = (): StringNumberLabelOption[] => {
  return [
    { label: 'AM', value: 0 },
    { label: 'PM', value: 12 },
  ];
};

export const weekDays = (): StringLabelOption[] => {
  return [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
  ];
};
