import * as QueryString from 'query-string';
import React, { FC, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { SearchStep } from '../components/SearchStep';

export const ConsumerSchedulingSearchPage: FC<RouteComponentProps> = ({ location }): JSX.Element => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const params = QueryString.parse(location.search);

  return <SearchStep organization={organization} params={params} />;
};
