import React, { FC } from 'react';
import { Location } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { Option, Select } from '../../../system/components/Select';

interface Props {
  locationId: string;
  locations: Location[];
  onSelect: (value: string) => void;
}

export const SelectProfilesLocation: FC<Props> = ({ locationId, locations, onSelect }): JSX.Element => (
  <Select onSelect={onSelect} defaultValue={locationId} dropdownMatchSelectWidth={false}>
    <Option value="">All Locations</Option>
    {locations.map(location => (
      <Option value={location.id} key={location.id}>
        {location.name ? location.name : location.address1}
      </Option>
    ))}
  </Select>
);
