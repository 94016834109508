import { gql } from '@apollo/client';

const GetAllPayorsAndPlans = gql`
  query {
    getAllPayorsAndPlans {
      id
      name
      payorPlans {
        id
        name
      }
    }
  }
`;

export { GetAllPayorsAndPlans };
