import store from '../../localstore';

export type LoginToken = string | null;

export const storeLoginToken = (token: LoginToken): void => {
  store().setItem('token', token || '');
};

export const removeLoginToken = (): void => {
  store().removeItem('token');
};

export const getLoginToken = (): LoginToken => {
  return store().getItem('token');
};
