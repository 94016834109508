import { Button, Col, Icon, Row } from 'antd';
import React, { FC, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { formatPhone } from '../../../helpers';
import { defaultTheme } from '../../../themes/default';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { ProfileMoreInfoDrawer } from './ProfileMoreInfoDrawer';

interface Props {
  profile: Profile;
}

const Styles = styled.div`
  .show-more-button {
    color: ${({ theme }) => theme.primaryColor};
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    height: auto;
    padding-left: 0;
    text-decoration: none solid;
  }
`;

export const ProfileShowMore: FC<Props> = ({ profile }) => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [inlineVisible, setInlineVisible] = useState<boolean>(false);

  const handleShowMoreClick = (): void => {
    setDrawerVisible(true);
  };

  const handleShowMoreSmallClick = (): void => {
    setInlineVisible(!inlineVisible);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Row>
          <Col xs={0} lg={24}>
            <Button className="show-more-button" type="link" onClick={handleShowMoreClick}>
              Show More
            </Button>
            <ProfileMoreInfoDrawer profile={profile} setVisible={setDrawerVisible} visible={drawerVisible} />
          </Col>
          <Col xs={24} lg={0}>
            <Button className="show-more-button" type="link" onClick={handleShowMoreSmallClick}>
              {inlineVisible ? 'Show Less' : 'Show More'}
            </Button>
            {inlineVisible && (
              <div>
                <div className="location-section">
                  <div className="section-title">
                    <Icon type="environment" />
                    {profile.location.name}
                  </div>
                  <div className="section-content">{profile.location.address1}</div>
                  {profile.location.address2 ? (
                    <div className="section-content">{profile.location.address2}</div>
                  ) : null}
                  <div className="section-content">
                    {profile.location.city}, {profile.location.state} {profile.location.postalCode}
                  </div>
                  <div className="section-content">{formatPhone(profile.phone)}</div>
                </div>
                {profile.professionalStatementSafe ? (
                  <Row>
                    <Col xs={24}>
                      <div className="section-title">
                        <Icon type="user" />
                        About
                      </div>
                      <p
                        className="section-content"
                        dangerouslySetInnerHTML={{ __html: profile.professionalStatementSafe }}
                      />
                    </Col>
                  </Row>
                ) : null}
              </div>
            )}
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
