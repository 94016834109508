// This exists because people actually check the checkbox that says "Block all cookies (not recommended)"
interface Storage {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
}

class MockLocalStorage {
  data: Map<string, string>;

  constructor() {
    this.data = new Map();
  }

  getItem(key: string): string | null {
    return this.data.get(key) || null;
  }

  setItem(key: string, value: string): void {
    this.data.set(key, value);
  }

  removeItem(key: string): void {
    this.data.delete(key);
  }
}

const mockLocal = new MockLocalStorage();

class LocalStorage {
  getItem(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    window.sessionStorage.setItem(key, value);
    return window.localStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    window.sessionStorage.removeItem(key);
    window.localStorage.removeItem(key);
  }
}

const local = new LocalStorage();

const store = (): Storage => {
  try {
    if (window.localStorage) {
      // https://mathiasbynens.be/notes/localstorage-pattern
      // Existance of window.localStorage isn't good enough, need to test it works in order
      // to use it.
      window.localStorage.setItem('testForLS', 'val');
      window.localStorage.removeItem('testForLS');
      return local;
    } else {
      return mockLocal;
    }
  } catch (exception) {
    return mockLocal;
  }
};

export default store;
