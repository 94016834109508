import { Col, Form, Row, Steps } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import AddInfoStep from '../components/SearchWizard/pages/AddInfoStep';
import { ConfirmStep } from '../components/SearchWizard/pages/ConfirmStep';
import { SearchStep } from '../components/SearchWizard/pages/SearchStep';

const { Step } = Steps;

const Styles = styled.div`
  .steps-banner {
    margin-bottom: 2rem;
  }

  .steps-nav-buttons {
    margin-bottom: 4rem;
  }

  .steps-content {
    margin-top: 2rem;
  }
`;

const StepsWizard = props => {
  const [current, setCurrent] = useState(0);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [sendToPatient, setSendToPatient] = useState(false);
  const [sendToProvider, setSendToProvider] = useState(false);
  const [formValues, setFormValues] = useState({});

  const handleSelectTimeSlot = timeSlot => {
    setSelectedTimeSlot(timeSlot);
    setSendToPatient(false);
    setSendToProvider(false);
    next();
  };

  const handleSendToPatient = profile => {
    setSelectedTimeSlot({ profile });
    setSendToPatient(true);
    setSendToProvider(false);
    next();
  };

  const handleSendToProvider = profile => {
    setSelectedTimeSlot({ profile });
    setSendToPatient(false);
    setSendToProvider(true);
    next();
  };

  const next = () => {
    setCurrent(prev => prev + 1);
  };

  const prev = () => {
    setCurrent(prev => prev - 1);
  };

  const handleSelectNewTime = () => {
    setCurrent(0);
  };

  const steps = [
    {
      title: 'Search',
      content: () => (
        <SearchStep
          {...props}
          handleSelectTimeSlot={handleSelectTimeSlot}
          handleSendToPatient={handleSendToPatient}
          handleSendToProvider={handleSendToProvider}
        />
      ),
    },
    {
      title: 'Referral Information',
      content: state => (
        <AddInfoStep
          {...props}
          selectedTimeSlot={selectedTimeSlot}
          sendToPatient={sendToPatient}
          sendToProvider={sendToProvider}
          form={props.form}
          prev={prev}
          next={next}
          setFormValues={setFormValues}
          formValues={formValues}
        />
      ),
    },
    {
      title: 'Confirm',
      content: () => (
        <ConfirmStep
          {...props}
          timeSlot={selectedTimeSlot}
          sendToPatient={sendToPatient}
          sendToProvider={sendToProvider}
          formValues={formValues}
          prev={prev}
          handleSelectNewTime={handleSelectNewTime}
        />
      ),
    },
  ];

  return (
    <Styles>
      <Row type="flex" justify="center" className="steps-banner">
        <Col span={12}>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
      </Row>
      <div className="steps-content">{steps[current].content()}</div>
    </Styles>
  );
};

export default Form.create({ name: 'Create Appointment Form' })(StepsWizard);
