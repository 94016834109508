import React, { FC, useEffect } from 'react';
import { Col, Row, Button } from 'antd';
import { Engagement } from '../hooks/useCreateConsumerSchedulingEngagementMutation';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { StepTitle } from './StepTitle';
import { StepContainer } from './StepContainer';
import { Link } from 'react-router-dom';

interface Props {
  engagement: Engagement;
  organization: Organization;
}

export const SuccessStepInstant: FC<Props> = ({ engagement, organization }): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);

  return (
    <StepContainer maxWidth="924px">
      <Row gutter={[0, 36]} type="flex" justify="center">
        <Col span={4}>
          <Link to={`/telehealth/${engagement.id}`}>
            <StepTitle title=""></StepTitle>
            <Button type="primary">Start telehealth visit</Button>
          </Link>
        </Col>
      </Row>
    </StepContainer>
  );
};
