import * as QueryString from 'query-string';
import React, { FC, useContext } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { AppointmentStep } from '../components/AppointmentStep';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { Slot } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';

interface LocationState {
  payorId?: string;
  payorPlanId?: string;
  procedureId?: string;
  profile?: Profile;
  apptType?: string;
  slot?: Slot;
  order?: string;
}

export const ConsumerSchedulingAppointmentPage: FC<RouteComponentProps<{}, StaticContext, LocationState>> = ({
  location,
}): JSX.Element | null => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const params = QueryString.parse(location.search);

  const payorId = location?.state?.payorId;
  const payorPlanId = location?.state?.payorPlanId;
  const procedureId = location?.state?.procedureId;
  const profile = location?.state?.profile;
  const apptType = location?.state?.apptType || 'scheduled';
  const slot = location?.state?.slot;
  const order = location?.state?.order;

  if (!procedureId || !profile) {
    return <Redirect to={`/consumer/${organization.slug ? organization.slug : organization.id}${location.search}`} />;
  }

  return (
    <AppointmentStep
      organization={organization}
      params={params}
      payorId={payorId}
      payorPlanId={payorPlanId}
      procedureId={procedureId}
      profile={profile}
      slot={slot}
      apptType={apptType}
      order={order}
    />
  );
};
