/**
 * Checks if an email address is valid and returns true if valid, false is invalid
 */
const isValidEmail = (email: string | null): boolean => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email !== null) {
    return regex.test(email.toLowerCase().trim());
  }

  return false;
};

/**
 * Checks if an email is valid and either returns an empty string if it's valid or the string 'invalid email address'
 * if it is invalid. Weird.
 * @deprecated Use isValidEmail instead. Only keeping because it's used in some spots.
 */
const validateEmail = (email: string | null): string => {
  if (!isValidEmail(email)) {
    return 'invalid email address';
  }

  return '';
};

export { validateEmail, isValidEmail };
