import { Button, Icon } from 'antd';
import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import * as Yup from 'yup';
import { Modal, MultiSelect } from '../../../../components';
import { CreateAvailability, ListAvailabilities } from '../../../../GraphQL';
import {
  availabilityAmPm,
  availabilityHoursOfDay,
  availabilityMinutesOfDay,
  parseErrorMessage,
  weekDays,
} from '../../../../helpers';

const formatSubmittedFormValues = ({
  endAmPm,
  endHour,
  endMinute,
  startAmPm,
  startHour,
  startMinute,
  weekDay: weekDayParam,
}) => {
  const startTime =
    startAmPm.value === 12
      ? `${parseInt(startHour.value, 10) + startAmPm.value}:${startMinute.value}:00`
      : `${startHour.value}:${startMinute.value}:00`;
  const endTime =
    endAmPm.value === 12
      ? `${parseInt(endHour.value, 10) + endAmPm.value}:${endMinute.value}:00`
      : `${endHour.value}:${endMinute.value}:00`;
  const weekDay = weekDayParam.value;

  return {
    startTime,
    endTime,
    weekDay,
  };
};

const startTimeLessThanEndTime = ({ startTime, endTime }) => {
  const start = startTime.split(':');
  const end = endTime.split(':');
  const startVal = parseInt(start[0] + start[1], 10);
  const endVal = parseInt(end[0] + end[1], 10);

  return startVal >= endVal;
};

const CreateAvailabilitySchema = Yup.object().shape({
  startHour: Yup.string().required('Required'),
  startMinute: Yup.string().required('Required'),
  startAmPm: Yup.string().required('Required'),
  endHour: Yup.string().required('Required'),
  endMinute: Yup.string().required('Required'),
  endAmPm: Yup.string().required('Required'),
  weekDay: Yup.string().required('Required'),
});

class CreateAvailabilityModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }
  render() {
    return (
      <React.Fragment>
        <Button
          type="primary"
          block
          onClick={() => this.setState({ isOpen: true })}
          disabled={this.props.scheduleId === null}
        >
          Add Schedule Availability
          <Icon type="plus" className="ml-2" />
        </Button>
        <Modal title="New Availability" isOpen={this.state.isOpen} closeModal={() => this.setState({ isOpen: false })}>
          <p className="text-center text-grey-darker mb-4 w-3/4 mr-auto ml-auto">
            Create a new availability so that sending providers know when you are available to take appointments
          </p>
          <Formik
            initialValues={{
              endAmPm: { label: 'PM', value: 12 },
              endHour: { label: '05', value: '05' },
              endMinute: { label: '00', value: '00' },
              startAmPm: { label: 'AM', value: 0 },
              startHour: { label: '09', value: '09' },
              startMinute: { label: '00', value: '00' },
              weekDay: { label: 'Monday', value: 'monday' },
            }}
            validationSchema={CreateAvailabilitySchema}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              setSubmitting(true);

              try {
                const vals = formatSubmittedFormValues(values);

                // if (startTimeLessThanEndTime({ startTime: '04:00:00', endTime: vals.startTime })) {
                //   throw new Error('Start time cannot be before 5AM');
                // }

                // TODO check if start time greater than end time
                if (startTimeLessThanEndTime({ startTime: vals.startTime, endTime: vals.endTime })) {
                  throw new Error(
                    'Start Time is greater than or equal to the End Time. Please enter an End Time that occurs after the Start Time.'
                  );
                }
                // TODO check if availability overlaps with another availability
                if (startTimeLessThanEndTime({ startTime: vals.startTime, endTime: vals.endTime })) {
                  throw new Error(
                    'Start Time is greater than or equal to the End Time. Please enter an End Time that occurs after the Start Time.'
                  );
                }

                await this.props.client.mutate({
                  mutation: CreateAvailability,
                  variables: {
                    CreateAvailabilityInput: {
                      start: vals.startTime,
                      end: vals.endTime,
                      weekDay: vals.weekDay,
                      scheduleId: this.props.scheduleId,
                    },
                  },
                  refetchQueries: [
                    {
                      query: ListAvailabilities,
                      variables: {
                        ListAvailabilitiesInput: { scheduleId: this.props.scheduleId },
                      },
                    },
                  ],
                });

                // clear status
                setStatus('');

                // close modal
                this.setState({ loading: false, isOpen: false });
              } catch (error) {
                setStatus(parseErrorMessage(error));
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ errors, status, touched, isSubmitting, setFieldValue, setFieldTouched, handleSubmit, values }) => (
              <div className="p-4">
                {status && status.length > 0 ? (
                  <div className="text-red w-4/5 mr-auto ml-auto text-center">{status}</div>
                ) : null}
                <Form className="w-full" onSubmit={handleSubmit}>
                  <div className="flex">
                    <div className="w-1/4 flex items-center justify-end mr-4">
                      <span className="font-bold text-md text-grey-darker">Start Time:</span>
                    </div>
                    <div className="flex w-3/4">
                      <div className="flex-1 mr-2">
                        <MultiSelect
                          errors={errors}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          touched={touched}
                          options={availabilityHoursOfDay()}
                          value={values.startHour}
                          name="startHour"
                          placeholder="Hour"
                          className="w-full"
                          isMulti={false}
                        />
                      </div>
                      <div className="flex-1 mr-2">
                        <MultiSelect
                          errors={errors}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          touched={touched}
                          options={availabilityMinutesOfDay()}
                          value={values.startMinute}
                          name="startMinute"
                          placeholder="Minute"
                          className="w-full"
                          isMulti={false}
                        />
                      </div>
                      <div className="flex-1">
                        <MultiSelect
                          errors={errors}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          touched={touched}
                          options={availabilityAmPm()}
                          value={values.startAmPm}
                          name="startAmPm"
                          placeholder="AM/PM"
                          className="w-full"
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/4 flex items-center justify-end mr-4">
                      <span className="font-bold text-md text-grey-darker">End Time:</span>
                    </div>
                    <div className="w-3/4 flex">
                      <div className="flex-1 mr-2">
                        <MultiSelect
                          errors={errors}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          touched={touched}
                          options={availabilityHoursOfDay()}
                          value={values.endHour}
                          name="endHour"
                          placeholder="Hour"
                          className="w-full"
                          isMulti={false}
                        />
                      </div>
                      <div className="flex-1 mr-2">
                        <MultiSelect
                          errors={errors}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          touched={touched}
                          options={availabilityMinutesOfDay()}
                          value={values.endMinute}
                          name="endMinute"
                          placeholder="Minute"
                          className="w-full"
                          isMulti={false}
                        />
                      </div>
                      <div className="flex-1">
                        <MultiSelect
                          errors={errors}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          touched={touched}
                          options={availabilityAmPm()}
                          value={values.endAmPm}
                          name="endAmPm"
                          placeholder="AM/PM"
                          className="w-full"
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="w-1/4 flex items-center justify-end mr-4">
                      <span className="font-bold text-md text-grey-darker">Day:</span>
                    </div>
                    <div className="w-3/4 flex">
                      <div className="flex-1 mr-2">
                        <MultiSelect
                          errors={errors}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          touched={touched}
                          options={weekDays()}
                          value={values.weekDay}
                          name="weekDay"
                          placeholder="Week Day"
                          className="w-full"
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </div>

                  <button type="submit" disabled={isSubmitting} className="w-full flex-1 mt-8 btn btn-blue px-2 py-2">
                    Save {isSubmitting ? <Icon type="loading" /> : null}
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        </Modal>
      </React.Fragment>
    );
  }
}

export const CreateAvailabilityModal = withApollo(CreateAvailabilityModalComponent);
