import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  required?: boolean;
}

export const InputPatientEmail: FC<Props> = ({ form, required = false }): JSX.Element => (
  <Form.Item label="Email Address">
    {form.getFieldDecorator('email', {
      rules: [
        {
          type: 'email',
          message: 'Please enter a valid email address',
        },
        { required: required, message: "Please enter patient's email." },
      ],
    })(<Input allowClear />)}
  </Form.Item>
);
