import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getConsumerSchedulingProfileSlotsQuery = gql`
  query GetConsumerSchedulingProfileSlotsQuery($procedureId: ID!, $profileId: ID!, $start: String, $end: String) {
    getConsumerSchedulingProfileSlots(procedureId: $procedureId, profileId: $profileId, start: $start, end: $end) {
      id
      profileId
      start
      end
      status
      slotIdsForAppointment
    }
  }
`;

export interface Slot {
  id: string;
  profileId: string;
  start: string;
  end: string;
  status: string;
  slotIdsForAppointment: string[];
}

interface Data {
  getConsumerSchedulingProfileSlots: Slot[];
}

interface Variables {
  procedureId?: string;
  profileId: string;
  start: string;
  end: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetConsumerSchedulingProfileSlotsQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getConsumerSchedulingProfileSlotsQuery, options);
};
