import React, { FC, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Affix, Col, Icon, Row } from 'antd';
import { DateTime } from 'luxon';
import { defaultTheme } from '../../../themes/default';

const Styles = styled.div`
  .date-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .day-of-month {
    color: ${({ theme }) => theme.blueDarkest};
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    text-decoration: none solid;
    text-transform: uppercase;
    text-align: center;
  }

  .day-of-week {
    color: ${({ theme }) => theme.blueDarkest};
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-decoration: none solid;
    text-transform: uppercase;
  }

  .future-arrow,
  .past-arrow {
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 24px;
  }

  .affixed {
    background-color: white;
  }
`;

interface Props {
  dates: DateTime[];
  onClickFuture: () => void;
  onClickPast: () => void;
  showMap: boolean;
}

interface DateBarDateProps {
  date: DateTime;
}

interface DateBarDatesProps {
  dates: DateTime[];
  onClickFuture: () => void;
  onClickPast: () => void;
  showMap: boolean;
}

const DatesBarDate: FC<DateBarDateProps> = ({ date }): JSX.Element => (
  <div className="date-wrapper">
    <span className="day-of-week">{date.toFormat('ccc')}</span>
    <span className="day-of-month">{date.toFormat('LLL dd')}</span>
  </div>
);

const DatesBarDates: FC<DateBarDatesProps> = ({ dates, onClickFuture, onClickPast, showMap }): JSX.Element => (
  <>
    {dates[0].toLocaleString(DateTime.DATE_SHORT) === DateTime.local().toLocaleString(DateTime.DATE_SHORT) ? (
      <Col span={1} className="past-arrow" />
    ) : (
      <Col span={1} className="past-arrow" onClick={onClickPast}>
        <Icon type="left" />
      </Col>
    )}
    <Col xs={7} sm={5} md={4} lg={showMap ? 5 : 4} xl={showMap ? 4 : 3} xxl={3}>
      <DatesBarDate date={dates[0]} />
    </Col>
    <Col xs={7} sm={5} md={4} lg={showMap ? 5 : 4} xl={showMap ? 4 : 3} xxl={3}>
      <DatesBarDate date={dates[1]} />
    </Col>
    <Col xs={7} sm={5} md={4} lg={showMap ? 5 : 4} xl={showMap ? 4 : 3} xxl={3}>
      <DatesBarDate date={dates[2]} />
    </Col>
    <Col xs={0} sm={5} md={4} lg={showMap ? 5 : 4} xl={showMap ? 4 : 3} xxl={3}>
      <DatesBarDate date={dates[3]} />
    </Col>
    <Col xs={0} md={4} lg={showMap ? 0 : 4} xl={showMap ? 4 : 3} xxl={3}>
      <DatesBarDate date={dates[4]} />
    </Col>
    <Col xs={0} xl={showMap ? 0 : 3} xxl={3}>
      <DatesBarDate date={dates[5]} />
    </Col>
    <Col xs={0} xxl={3}>
      <DatesBarDate date={dates[6]} />
    </Col>
    <Col span={1} className="future-arrow" onClick={onClickFuture}>
      <Icon type="right" />
    </Col>
  </>
);

export const DatesBar: FC<Props> = ({ dates, onClickFuture, onClickPast, showMap }): JSX.Element => {
  const [affixed, setAffixed] = useState(false);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Row>
          <Col xs={24} lg={0}>
            <Row gutter={16} type="flex" justify="center" align="middle">
              <DatesBarDates dates={dates} onClickFuture={onClickFuture} onClickPast={onClickPast} showMap={showMap} />
            </Row>
          </Col>
          <Col xs={0} lg={24}>
            <Affix onChange={changeAffix => setAffixed(changeAffix ? true : false)}>
              <Row gutter={16} type="flex" justify="end" align="middle" className={affixed ? 'affixed' : ''}>
                <DatesBarDates
                  dates={dates}
                  onClickFuture={onClickFuture}
                  onClickPast={onClickPast}
                  showMap={showMap}
                />
              </Row>
            </Affix>
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
