import { gql } from '@apollo/client';

const AvailabilityFragment = gql`
  fragment AvailabilityFragment on Availability {
    id
    start
    end
    weekDay
    scheduleId
  }
`;

const BlackoutDateFragment = gql`
  fragment BlackoutDateFragment on BlackoutDate {
    id
    start
    startTime
    end
    endTime
    scheduleId
    reason
  }
`;

const CreateAvailability = gql`
  mutation CreateAvailability($CreateAvailabilityInput: CreateAvailabilityInput!) {
    CreateAvailability(CreateAvailabilityInput: $CreateAvailabilityInput) {
      ...AvailabilityFragment
    }
  }
  ${AvailabilityFragment}
`;

const DeleteAvailability = gql`
  mutation DeleteAvailability($DeleteAvailabilityInput: DeleteAvailabilityInput!) {
    DeleteAvailability(DeleteAvailabilityInput: $DeleteAvailabilityInput)
  }
`;

const ListAvailabilities = gql`
  query ListAvailabilities($ListAvailabilitiesInput: ListAvailabilitiesInput!) {
    ListAvailabilities(ListAvailabilitiesInput: $ListAvailabilitiesInput) {
      ...AvailabilityFragment
    }
  }
  ${AvailabilityFragment}
`;

const CreateBlackoutDate = gql`
  mutation CreateBlackoutDate($CreateBlackoutDateInput: CreateBlackoutDateInput!) {
    CreateBlackoutDate(CreateBlackoutDateInput: $CreateBlackoutDateInput) {
      ...BlackoutDateFragment
    }
  }
  ${BlackoutDateFragment}
`;

const DeleteBlackoutDate = gql`
  mutation DeleteBlackoutDate($DeleteBlackoutDateInput: DeleteBlackoutDateInput!) {
    DeleteBlackoutDate(DeleteBlackoutDateInput: $DeleteBlackoutDateInput)
  }
`;

const ListBlackoutDates = gql`
  query ListBlackoutDates($ListBlackoutDatesInput: ListBlackoutDatesInput!) {
    ListBlackoutDates(ListBlackoutDatesInput: $ListBlackoutDatesInput) {
      ...BlackoutDateFragment
    }
  }
  ${BlackoutDateFragment}
`;

const GetSlotDuration = gql`
  query GetSlotDuration($scheduleId: ID!) {
    GetSlotDuration(scheduleId: $scheduleId) {
      scheduleId
      durationInMins
    }
  }
`;

const UpsertSlotDuration = gql`
  mutation UpsertSlotDuration($UpsertSlotDurationInput: UpsertSlotDurationInput!) {
    UpsertSlotDuration(UpsertSlotDurationInput: $UpsertSlotDurationInput) {
      scheduleId
      durationInMins
    }
  }
`;

const GetAvailabilityLimitSummary = gql`
  query GetAvailabilityLimitSummary($organizationId: ID!, $profileId: ID!) {
    GetAvailabilityLimitSummary(organizationId: $organizationId, profileId: $profileId) {
      enabled
      currentAvailability
      pastLimits {
        id
        userId
        start
        end
        count
        note
      }
      futureLimits {
        id
        userId
        start
        end
        count
        note
      }
    }
  }
`;

const EnableProfileLimit = gql`
  mutation EnableProfileLimit($organizationId: ID!, $profileId: ID!, $type: String!) {
    EnableProfileLimit(organizationId: $organizationId, profileId: $profileId, type: $type) {
      id
      profileId
      type
      enabled
    }
  }
`;

const DisableProfileLimit = gql`
  mutation DisableProfileLimit($organizationId: ID!, $profileId: ID!) {
    DisableProfileLimit(organizationId: $organizationId, profileId: $profileId) {
      id
      profileId
      type
      enabled
    }
  }
`;

const CreateAvailabilityLimit = gql`
  mutation CreateAvailabilityLimit(
    $organizationId: ID!
    $profileId: ID!
    $start: NaiveDateTime!
    $end: NaiveDateTime
    $count: Number!
    $note: String
  ) {
    CreateAvailabilityLimit(
      organizationId: $organizationId
      profileId: $profileId
      start: $start
      end: $end
      count: $count
      note: $note
    ) {
      id
      start
      end
      count
      note
    }
  }
`;

const DeleteAvailabilityLimit = gql`
  mutation DeleteAvailabilityLimit($DeleteAvailabilityLimitInput: DeleteAvailabilityLimitInput!) {
    DeleteAvailabilityLimit(DeleteAvailabilityLimitInput: $DeleteAvailabilityLimitInput)
  }
`;

export {
  CreateAvailability,
  DeleteAvailability,
  ListAvailabilities,
  CreateBlackoutDate,
  ListBlackoutDates,
  DeleteBlackoutDate,
  GetSlotDuration,
  UpsertSlotDuration,
  GetAvailabilityLimitSummary,
  EnableProfileLimit,
  DisableProfileLimit,
  CreateAvailabilityLimit,
  DeleteAvailabilityLimit,
};
