import React from 'react';
import { useHistory } from 'react-router-dom';

type BackButtonProps = {
  searchParams: URLSearchParams;
  className?: string;
};

const BackButton: React.FC<BackButtonProps> = ({ searchParams, className }) => {
  const history = useHistory();

  const handleBackClick = (): void => {
    const searchQuery = searchParams.toString();
    const formPageUrl = `/?${searchQuery}`;

    history.push(formPageUrl);
  };

  return (
    <button className={className} onClick={handleBackClick}>
      Back to Schedule a Patient
    </button>
  );
};

export default BackButton;
