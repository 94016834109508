import { Form, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
const { Option } = Select;

interface Props {
  form: WrappedFormUtils;
  specialties: {
    id: string;
    name: string;
  }[];
}

export default class DesiredSpecialtyField extends React.Component<Props, {}> {
  render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form.Item label="What is the desired Specialty?">
        {getFieldDecorator('specialty', {
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ],
        })(
          <Select showSearch placeholder="Specialty" optionFilterProp="children">
            {this.props.specialties.map(specialty => (
              <Option value={specialty.id} key={specialty.id}>
                {specialty.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  }
}
