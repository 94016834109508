import { ErrorMessage, Field } from 'formik';
import React, { FC } from 'react';
import { Icon, Tooltip } from 'antd';

interface Props {
  name: string;
  tooltip: string;
  label: string;
  className: string;
  type?: string;
  component?: string;
  placeholder?: string;
  touched: { [key: string]: string | null | boolean | undefined | {} };
  errors: { [key: string]: string | null | boolean | undefined | {} };
}

export const FieldsetTooltip: FC<Props> = ({ name, tooltip, label, touched, errors, className, ...rest }) => (
  <div className="mt-2">
    <label className="text-label" htmlFor={name}>
      {label}&nbsp;
      <Tooltip title={tooltip}>
        <Icon type="info-circle" />
      </Tooltip>
    </label>
    <Field
      id={name}
      name={name}
      className={className.concat(touched[name] && errors[name] ? ' border-red' : '')}
      {...rest}
    />
    <div className="text-red text-xs">
      <ErrorMessage name={name} />
    </div>
  </div>
);
