import * as Sentry from '@sentry/browser';
import React, { FC, useState } from 'react';
import { UpdateReferralStatus } from '../../../../GraphQL';
import { Button, message, Modal } from 'antd';
import { deletePaginatedCacheItems } from '../../../../helpers/cache';
import { useMutation } from '@apollo/client';
import { ReferralData, QUERY } from './GetReferralQuery';
import { useAppContext } from '../../../../hooks';
import { LIST_AUDIT_LOG_QUERY } from './ListAuditLogQuery';
interface UpdateReferralStatusMutationVars {
  referralStatusInput: {
    id: string;
    status: string;
  };
}

interface UpdateReferralStatusMutationData {
  UpdateReferralStatus: {
    id: string;
    status: string;
  };
}

export const CompleteReferral: FC<{
  referral?: ReferralData;
  onComplete: () => void;
}> = ({ referral, onComplete }): JSX.Element | null => {
  const { currentOrganization } = useAppContext();
  const [visible, setVisible] = useState<boolean>(false);

  const [updateReferralStatusMutation, { error }] = useMutation<
    UpdateReferralStatusMutationData,
    UpdateReferralStatusMutationVars
  >(UpdateReferralStatus);

  if (error) {
    Sentry.captureException(error);
    message.error('Error complete referral');
  }

  const nextStatuses = referral?.nextStatuses as Array<string>;
  if (!referral || !nextStatuses.includes('completed')) {
    return null;
  }

  const onOk = (): void => {
    setVisible(false);

    updateReferralStatusMutation({
      variables: {
        referralStatusInput: {
          id: referral.id,
          status: 'completed',
        },
      },
      refetchQueries: [
        {
          query: QUERY,
          variables: {
            id: referral.id,
            organizationId: currentOrganization?.id as string,
          },
        },
        {
          query: LIST_AUDIT_LOG_QUERY,
          variables: {
            action: 'referral.status.updated',
            recordId: referral?.id,
            organizationId: currentOrganization?.id as string,
          },
        },
      ],
      update: cache => {
        message.success('Referral has been completed');
        deletePaginatedCacheItems(cache, 'ListReferrals');
        deletePaginatedCacheItems(cache, 'ListAppointments');
        onComplete();
      },
    });
  };

  return (
    <>
      <Button className="mt-1" onClick={() => setVisible(true)}>
        Complete Referral
      </Button>
      <Modal title="Complete Referral" visible={visible} onOk={() => onOk()} onCancel={() => setVisible(false)}>
        <p>Are you sure?</p>
      </Modal>
    </>
  );
};
