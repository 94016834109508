import React, { FC, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { defaultTheme } from '../../../themes/default';
import { formatPhone } from '../../../helpers';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { StepContainer } from './StepContainer';
import { StepTitle } from './StepTitle';

interface Props {
  organization: Organization;
  profile: Profile;
}

const { Text } = Typography;

const Styles = styled.div`
  .error-text {
    color: ${({ theme }) => theme.bodyTextColor};
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    line-height: 26px;
    text-decoration: none solid;
  }
`;

export const ErrorStep: FC<Props> = ({ organization, profile }): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <StepContainer maxWidth="932px">
          <Row gutter={[0, 36]}>
            <Col span={24}>
              <StepTitle title="Not Able to Schedule the Appointment" />
            </Col>
          </Row>
          <Row gutter={[0, 36]}>
            <Col span={24}>
              <Text className="error-text">
                {`We're sorry for the inconvenience,
                  please call ${formatPhone(profile.phone)} for assistance with scheduling your appointment.`}
              </Text>
            </Col>
          </Row>
        </StepContainer>
      </Styles>
    </ThemeProvider>
  );
};
