import React, { FC } from 'react';
import styled from 'styled-components';
import { PublicProfile } from '../containers/DirectoryContext';

interface Props {
  profile: PublicProfile;
}

const Styles = styled.div`
  .profile-name {
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: -0.2em;
  }

  .profile-organization-name,
  .specialty-name {
    font-style: italic;
    line-height: 1.1;
    font-size: 0.8em;
  }
`;

export const ProfileCardName: FC<Props> = ({ profile }): JSX.Element => (
  <Styles>
    <ul>
      <li className="profile-name">{profile.displayName}</li>
      {profile.specialty ? <li className="specialty-name">{profile.specialty}</li> : null}
      <li className="profile-organization-name">{profile.organization.name}</li>
    </ul>
  </Styles>
);
