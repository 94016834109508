import React, { FC } from 'react';
import { Button, Row } from 'antd';
import { IsOrgAdminOrBlockitAdmin } from '../../../components';
import { useCountProvidersInOrganizationQuery } from '../hooks/useCountProvidersInOrganizationQuery';
import { NavLink } from 'react-router-dom';
import { FreePlanConfiguration } from '../../../generated/graphql';

interface Props {
  organizationId: string;
  isFree: boolean;
  freePlanConfiguration: FreePlanConfiguration;
}

export const NewProviderButton: FC<Props> = ({ organizationId, isFree, freePlanConfiguration }): JSX.Element | null => {
  const { data, loading, error } = useCountProvidersInOrganizationQuery({
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  if (error) return null;

  let disabled = false;
  if (!loading && data) {
    const { countProvidersInOrganization: count } = data;

    if (
      isFree &&
      freePlanConfiguration &&
      freePlanConfiguration.providers &&
      count >= freePlanConfiguration.providers
    ) {
      disabled = true;
    }
  }

  return (
    <IsOrgAdminOrBlockitAdmin>
      <Row type="flex" justify="end">
        <NavLink
          to={{ pathname: `/organizations/${organizationId}/providers/new` }}
          className="text-grey-darkest no-underline"
        >
          <Button type="primary" disabled={disabled}>
            New Provider
          </Button>
        </NavLink>
      </Row>
    </IsOrgAdminOrBlockitAdmin>
  );
};
