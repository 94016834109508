import React, { FC } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Container } from '../Container';

interface ContentContainerProps {
  theme?: DefaultTheme;
  left?: boolean;
  bordered?: boolean;
  padding?: string;
}

const ContentContainer = styled(Container)`
  right: ${({ left = false }: ContentContainerProps) => (left ? 0 : 'auto')};
  margin-top: 10px;
  position: absolute;

  .option {
    cursor: pointer;
    padding: 10px;
    text-align: left;

    &:hover {
      color: ${({ theme }) => theme.accentColor};
      font-weight: 600;
    }
  }
`;

interface Props extends ContentContainerProps {
  hijackOnClick: (arg0: () => void) => void;
  options: OptionProps[];
}

interface OptionProps {
  props: { onClick: () => void };
}

export const Content: FC<Props> = ({ hijackOnClick, options, ...props }) => {
  return (
    <ContentContainer bordered padding="2x" {...props}>
      {options.map((option, index) => (
        <div className="option" key={`option--${index}`}>
          {React.cloneElement(option as React.ReactElement, {
            onClick: hijackOnClick(option.props.onClick),
          })}
        </div>
      ))}
    </ContentContainer>
  );
};
