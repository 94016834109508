import { Button } from 'antd';
import React, { FC, useState } from 'react';
import { DeleteGroupProfileModal } from './DeleteGroupProfileModal';

interface Props {
  groupId: string;
  profile: { id: string; displayName: string };
}

export const DeleteGroupProfile: FC<Props> = ({ groupId, profile }): JSX.Element => {
  const [showDeleteGroupProfileModal, setShowDeleteGroupProfileModal] = useState(false);

  return (
    <>
      <Button
        htmlType="button"
        icon="delete"
        shape="circle"
        size="large"
        type="link"
        onClick={() => setShowDeleteGroupProfileModal(true)}
      />
      <DeleteGroupProfileModal
        groupId={groupId}
        onCancel={() => setShowDeleteGroupProfileModal(false)}
        onOk={() => setShowDeleteGroupProfileModal(false)}
        profile={profile}
        visible={showDeleteGroupProfileModal}
      />
    </>
  );
};
