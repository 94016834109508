import * as Sentry from '@sentry/browser';
import { Avatar, Button, Card, Col, Divider, Icon, message, Row } from 'antd';
import { DateTime } from 'luxon';
import React, { useState, useContext } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { CreateAppointment } from '../../../../../GraphQL';
import { deletePaginatedCacheItems, formatApptRange } from '../../../../../helpers';
import { history } from '../../../../core/components/App/history';
import { CodeSystemLookup } from '../../CodeSystemLookup';
import SearchHeaderPatientCard from '../components/SearchHeaderPatientCard';
import { SearchWizardContainerContext } from '../../../pages/SearchWizardContainer';
import { useFinishTaskMutation } from '../../../../../generated/graphql';

const Styles = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  .headerSection {
    width: 100%;
    .ant-divider {
      margin-top: 0;
    }
  }

  .main-card {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .profile-image {
    border-radius: 0;
    height: 8rem;
    width: 200px;
    background-image: url(${props => props.profile.profileImgUrl});
    background-size: cover;
    margin-bottom: 1rem;
    max-width: 120px;
  }

  .section-body {
    margin-left: 2rem;
    margin-bottom: 20px;
  }

  .date-box {
    border: 1px solid #2783c4;
    border-radius: 2px;
    text-align: center;
    width: 70px;

    h5 {
      font-size: 2.5em;
      font-weight: lighter;
      background-color: #fff;
      color: #222;
      margin: 0;
    }

    p {
      text-transform: uppercase;
      font-size: 0.85em;
      font-weight: bold;
      background-color: #2783c4;
      color: #fafafa;
      padding: 0;
      margin: 0;
    }
  }

  h3 {
    font-size: 1.35em;
  }

  .address {
    font-size: 12px;
  }

  .ant-row-flex {
    margin-top: 12px;
  }

  .profile-name {
    font-weight: 800;
  }

  .name-section,
  .location-section,
  .about-section {
    margin-bottom: 1rem;
  }
`;

const formatDayNumber = timeSlot => {
  return new Date(Date.parse(timeSlot.start)).toLocaleDateString('en-US', { month: 'long' });
};

const formatDateNumber = timeSlot => {
  return new Date(Date.parse(timeSlot.start)).getDate();
};

const formatDateWeekday = timeSlot => {
  return new Date(Date.parse(timeSlot.start)).toLocaleDateString('en-US', { weekday: 'long' });
};

const formatDateFull = timeSlot => {
  return new Date(Date.parse(timeSlot.start)).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const Section = ({ title, children, icon }) => (
  <div className="headerSection">
    <Row type="flex" gutter={12}>
      <Col>
        <h3>
          <Icon type={icon} />
        </h3>
      </Col>
      <Col>
        <h3>{title}</h3>
      </Col>
    </Row>
    <Row type="flex" gutter={24}>
      <Col span={24}>
        <div className="section-body">{children}</div>
        <Divider />
      </Col>
    </Row>
  </div>
);

const ConfirmAppointment = ({
  timeSlot: { timeSlot, profile },
  getReferral,
  formValues,
  client,
  prev,
  handleSelectNewTime,
}) => {
  const [loading, setLoading] = useState(false);
  const [showSelectTime, setShowSelectTime] = useState(false);
  const [finishTask] = useFinishTaskMutation();
  const { taskId, bookedByProvider } = useContext(SearchWizardContainerContext);

  const handleConfirm = async () => {
    setLoading(true);

    let code = '';
    let system = '';

    if (formValues.serviceType) {
      const serviceTypeVal = JSON.parse(formValues.serviceType);
      code = serviceTypeVal.code;
      system = serviceTypeVal.system;
    }

    // todo - adding diagnosis info here is silly.  it should be added direct to referral in add info step
    try {
      const {
        data: { createAppointment },
      } = await client.mutate({
        mutation: CreateAppointment,
        variables: {
          profileId: profile.id,
          referralId: getReferral.id,
          slotIds: timeSlot.slotIdsForAppointment,
          start: timeSlot.start,
          end: timeSlot.end,
          serviceTypeCode: code,
          serviceTypeSystem: system,
          diagnoses: formValues.diagnoses || [],
          diagnosesDescription: formValues.notes || null,
          notificationEmail: formValues.patientEmail,
          questionnaireAnswerSets: formValues.questionnaireAnswerSets,
          bookedByProvider: bookedByProvider,
        },
        update: cache => deletePaginatedCacheItems(cache, 'ListReferrals'),
      });

      const action = createAppointment.status === 'pending' ? 'requested' : 'booked';

      if (taskId) {
        await finishTask({
          variables: {
            taskId: taskId,
          },
        });
      }

      await message.success(`Successfully ${action} the appointment`).then(() => {
        setLoading(false);
        history.replace(`/referrals/${getReferral.id}`);
      });
    } catch (error) {
      Sentry.captureException(error);
      message.destroy();
      if (error.message.includes('This timeslot is taken')) {
        message.error(error.message.replace(/GraphQL error: /g, ''), 0);
        setLoading(false);
        setShowSelectTime(true);
      } else {
        await message.error(error.message.replace(/GraphQL error: /g, '')).then(() => {
          setLoading(false);
        });
      }
    }
  };

  const selectNewTimeClick = () => {
    message.destroy();
    handleSelectNewTime();
  };

  return (
    <Styles profile={profile}>
      <Row type="flex" justify="space-around" gutter={24} style={{ marginBottom: '2rem' }}>
        <Col span={20}>
          <SearchHeaderPatientCard referral={getReferral} />
        </Col>
        <Col span={4}></Col>
      </Row>
      <Row type="flex" justify="center">
        <Col sm={20} md={20} lg={18} xl={14}>
          <Card title="Confirm Appointment" className="main-card">
            <Row type="flex" justify="center" gutter={24}>
              <Col>
                {profile.profileImgUrl ? (
                  <div className="profile-image" />
                ) : (
                  <Avatar shape="square" size={128} icon="user" />
                )}
              </Col>
              <Col span={16}>
                <div className="name-section">
                  <div className="profile-name">{profile.displayName}</div>
                  <div>{profile.specialty}</div>
                  <div>{profile.organization.name}</div>
                </div>
                <Row type="flex" justify="start" gutter={24}>
                  <Col>
                    <div className="date-box">
                      <h5>{formatDateNumber(timeSlot)}</h5>
                      <p>{formatDayNumber(timeSlot)}</p>
                    </div>
                  </Col>
                  <Col>
                    <h3>
                      {formatDateWeekday(timeSlot)} @ {profile.location.name}
                    </h3>
                    <div>
                      {formatDateFull(timeSlot)} at{' '}
                      {timeSlot.slotIdsForAppointment.length > 1
                        ? DateTime.fromISO(timeSlot.start).toFormat('hh:mm')
                        : formatApptRange(timeSlot)}
                    </div>
                    <div className="address">
                      {profile.location.address1} {profile.location.address2} {profile.location.city},{' '}
                      {profile.location.state} {profile.location.postalCode}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider />

            <CodeSystemLookup referralId={getReferral.id} profileId={profile.id} Wrapper={Section} />

            <Row type="flex" justify="end" gutter={24}>
              <Col>
                {showSelectTime ? (
                  <Button className="prev-button" onClick={selectNewTimeClick} block>
                    <Icon type="left" /> Select New Time
                  </Button>
                ) : (
                  <Button className="prev-button" onClick={prev} block>
                    <Icon type="left" /> Back
                  </Button>
                )}
              </Col>
              <Col>
                <Button className="next-button" onClick={handleConfirm} block type="primary" loading={loading}>
                  Confirm
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Styles>
  );
};

export default withApollo(ConfirmAppointment);
