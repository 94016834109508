import React, { FC } from 'react';
import { Payor, Plan } from '../types';
import { PlanItem } from './PlanItem';

const sortPlans = (a: Payor | Plan, b: Payor | Plan): number => {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();

  if (aLower < bLower) {
    return -1;
  } else if (aLower > bLower) {
    return 1;
  }
  return 0;
};

interface Props {
  payors: Payor[];
  selectedPayorId: string;
  handlePayorSelected: (id: string) => void;
  handlePlanAdd: () => void;
}

export const PayorList: FC<Props> = props => (
  <ul className="list-reset overflow-y-scroll" style={{ height: '80vh' }}>
    {props.payors
      .map(p => p)
      .sort(sortPlans)
      .map(payor => (
        <div key={payor.id}>
          <li
            key={payor.id}
            onClick={() => props.handlePayorSelected(payor.id)}
            className={`p-2 no-select hover:bg-grey-light cursor-pointer ${
              props.selectedPayorId === payor.id
                ? 'border-grey-light border-t-2 border-b-2 font-extrabold text-lg text-black px-4'
                : null
            }`}
          >
            {payor.name}
          </li>
          {props.selectedPayorId === payor.id ? (
            <ul className="list-reset no-select">
              {payor.payorPlans
                .map(p => p)
                .sort(sortPlans)
                .map(plan => (
                  <PlanItem key={plan.id} plan={plan} payor={payor} {...props} />
                ))}
            </ul>
          ) : null}
        </div>
      ))}
  </ul>
);
