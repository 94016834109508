import React, { FC } from 'react';
import { Form, Icon, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { WrappedFormUtils } from 'antd/lib/form/Form';

const { Dragger } = Upload;

interface Props {
  addAppointmentAttachment: (file: File) => void;
  form: WrappedFormUtils;
  removeAppointmentAttachment: (file: UploadFile) => boolean;
}

export const UploadAppointmentAttachment: FC<Props> = ({
  addAppointmentAttachment,
  form,
  removeAppointmentAttachment,
}): JSX.Element => {
  const { getFieldDecorator } = form;

  const beforeUpload = (file: RcFile): boolean => {
    addAppointmentAttachment(file);
    return false;
  };

  const onRemove = (file: UploadFile): boolean => {
    return removeAppointmentAttachment(file);
  };

  return (
    <Form.Item>
      {getFieldDecorator('appointmentAttachments', { rules: [{ required: false }] })(
        <Dragger multiple beforeUpload={beforeUpload} onRemove={onRemove}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload.</p>
          <p className="ant-upload-hint">Attach any required files to your appointment here.</p>
        </Dragger>
      )}
    </Form.Item>
  );
};
