import queryString, { ParsedQuery } from 'query-string';
import { useContext, useEffect } from 'react';
import { history } from './App/history';
import { AppContext } from '../contexts/AppContext';
import { getDefaultSendsOrReceives } from './SentRecDropDown';

const isUuid = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export function useSentRecQueryStringHistoryEffect(): void {
  const { currentLocation, currentOrganization } = useContext(AppContext);
  const queryParams: ParsedQuery = queryString.parse(history.location.search);

  const { locationId, organizationId } = queryParams;

  useEffect(() => {
    if (!queryParams.type || currentLocation?.id !== locationId || currentOrganization?.id !== organizationId) {
      const newQueryString = queryString.stringify({
        ...queryParams,
        type: getDefaultSendsOrReceives(currentLocation),
        organizationId: currentOrganization?.id,
        locationId: currentLocation?.id,
      });

      // ensure that the /organizations/:id is consistent with the current org here.
      const path = window.location.pathname;
      const parts = path.split('/');
      if (parts[1] === 'organizations' && parts[2] && parts[2].match(isUuid)) {
        parts[2] = currentOrganization?.id || '';
        const url = parts.join('/') + '?' + newQueryString;
        history.push(url);
      } else {
        history.push(history.location.pathname + '?' + newQueryString);
      }
    }
  }, [currentLocation, currentOrganization, locationId, organizationId, queryParams]);
}
