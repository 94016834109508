import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { gql } from '@apollo/client';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useContext, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { history } from '../../../../core/components/App/history';
import { SearchWizardContainerContext } from '../../../pages/SearchWizardContainer';

const GET_PAYOR_PLANS_BY_PAYOR_AND_PROFILES = gql`
  query GetPayorPlansByPayorAndProfiles($payorId: ID!, $profileIds: [ID]) {
    GetPayorPlansByPayorAndProfiles(payorId: $payorId, profileIds: $profileIds) {
      id
      name
    }
  }
`;

const GET_ALL_SCHEDULE_IDS = gql`
  query GetAllSchedules(
    $referralId: ID!
    $page: Int
    $filters: String
    $textFilter: String
    $sort: SchedulingSortEnum
    $distanceFromPatient: Int
  ) {
    GetAllSchedules(
      referralId: $referralId
      page: $page
      filters: $filters
      textFilter: $textFilter
      sort: $sort
      distanceFromPatient: $distanceFromPatient
    )
      @connection(
        key: "SearchSchedules"
        filter: ["referralId", "page", "filters", "textFilter", "distanceFromPatient", "sort"]
      ) {
      filters
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        profile {
          id
        }
      }
    }
  }
`;

const { Option } = Select;
const { Search } = Input;

const SORT_BY_DISTANCE = 'DISTANCE';
const FILTER_BY_AVAILABILITY = 'AVAILABILITY';
const FILTER_BY_NEXT_AVAILABILITY = 'NEXT_AVAILABILITY';

const MapToggleAndSort = ({ handleToggleMap, filters, showMap, showInsuranceFilters }) => {
  const {
    referralId,
    clearAlreadyRerendered,
    triggerRerender,
    isSortingByAvailability,
    textFilter,
    setTextFilter,
    distanceFromPatient,
    setDistanceFromPatient,
    defaultSort,
  } = useContext(SearchWizardContainerContext);
  const searchResultInput = useRef(false);

  useEffect(() => {
    searchResultInput.current.focus();
  }, [textFilter]);

  const queryParams = queryString.parse(history.location.search);
  const existingFilters = queryParams.filters ? JSON.parse(queryParams.filters) : {};
  const defaultLangVal = existingFilters.languageCode || 'all';
  const defaultPayorVal = existingFilters.payorId || 'all';
  const defaultPayorPlanVal = existingFilters.payorPlanId || 'all';
  const initialSort = queryParams.sort || defaultSort;
  const shouldSkipOrDisable = !existingFilters.payorId || existingFilters.payorId === 'all';

  const { loading: loadingGetAllSchedulesQuery, data: getAllSchedulesQueryData } = useQuery(GET_ALL_SCHEDULE_IDS, {
    skip: shouldSkipOrDisable,
    variables: { referralId: referralId },
  });

  let profileIds = [];
  if (!loadingGetAllSchedulesQuery && getAllSchedulesQueryData && getAllSchedulesQueryData.GetAllSchedules) {
    profileIds = getAllSchedulesQueryData.GetAllSchedules.entries.map(entry => entry.profile.id);
  }

  const { loading: loadingGetPayorPlansQuery, data: getPayorPlansQueryData } = useQuery(
    GET_PAYOR_PLANS_BY_PAYOR_AND_PROFILES,
    {
      skip: shouldSkipOrDisable,
      variables: { payorId: existingFilters.payorId, profileIds: profileIds },
    }
  );

  const updateFilterParams = params => {
    const updatedQueryParams = {
      ...queryParams,
      filters: JSON.stringify({ ...existingFilters, ...params }),
      page: 1,
    };

    history.push(history.location.pathname + '?' + queryString.stringify(updatedQueryParams));
  };

  const handleLanguageFilterChange = langCode => {
    updateFilterParams({ languageCode: langCode });
  };

  const handlePayorFilterChange = payorId => {
    // also resetting payorPlanId to all
    updateFilterParams({ payorId, payorPlanId: 'all' });
  };

  const handlePayorPlanFilterChange = payorPlanId => {
    updateFilterParams({ payorPlanId });
  };

  const handleSortByChange = val => {
    const updatedQueryParams = {
      ...queryParams,
      sort: val,
      page: 1,
    };

    history.push(history.location.pathname + '?' + queryString.stringify(updatedQueryParams));
  };

  const setTextFilterDebounce = _.debounce(setTextFilter, 1250);

  const moveCaretToEnd = e => {
    const temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
  };

  return (
    <Row gutter={16} type="flex" align="middle">
      <Col span={2}>
        <Form.Item label="Map">
          <Switch
            checked={showMap}
            onChange={checked => {
              if (isSortingByAvailability) {
                clearAlreadyRerendered();
                triggerRerender();
              }
              handleToggleMap(checked);
            }}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Refine results">
          <Search
            placeholder="Search within results"
            defaultValue={textFilter}
            ref={searchResultInput}
            onFocus={moveCaretToEnd}
            onChange={val => {
              setTextFilterDebounce(val.target.value);
            }}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Language">
          <Select
            defaultValue={defaultLangVal}
            showSearch
            placeholder="Language"
            optionFilterProp="children"
            onChange={val => {
              if (isSortingByAvailability) {
                clearAlreadyRerendered();
                triggerRerender();
              }
              handleLanguageFilterChange(val);
            }}
          >
            {
              <Option value="all" key={1}>
                All Languages
              </Option>
            }
            {(filters.languages || []).map(l => (
              <Option value={l.code} key={l.code}>
                {l.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {showInsuranceFilters && (
        <>
          <Col span={4}>
            <Form.Item label="Insurance Carrier">
              <Select
                defaultValue={defaultPayorVal}
                showSearch
                placeholder="Insurance Carrier"
                optionFilterProp="children"
                onChange={val => {
                  if (isSortingByAvailability) {
                    clearAlreadyRerendered();
                    triggerRerender();
                  }
                  handlePayorFilterChange(val);
                }}
              >
                {
                  <Option value="all" key={1}>
                    All Carriers
                  </Option>
                }
                {(filters.payors || []).map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Insurance Plan">
              <Select
                disabled={shouldSkipOrDisable}
                defaultValue={defaultPayorPlanVal}
                showSearch
                placeholder="Insurance Plan"
                optionFilterProp="children"
                onChange={val => {
                  if (isSortingByAvailability) {
                    clearAlreadyRerendered();
                    triggerRerender();
                  }
                  handlePayorPlanFilterChange(val);
                }}
              >
                {
                  <Option value="all" key={1}>
                    Any Plan
                  </Option>
                }
                {(
                  (!loadingGetPayorPlansQuery &&
                    getPayorPlansQueryData &&
                    getPayorPlansQueryData.GetPayorPlansByPayorAndProfiles) ||
                  []
                ).map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </>
      )}
      <Col span={3}>
        <Form.Item label="Sort / Filter">
          <Select
            defaultValue={initialSort}
            showSearch
            placeholder="Select a filter/sort"
            optionFilterProp="children"
            onChange={val => {
              if (val === FILTER_BY_AVAILABILITY) {
                clearAlreadyRerendered();
                triggerRerender();
              }
              handleSortByChange(val);
            }}
          >
            <Option value={SORT_BY_DISTANCE} key="sort-1">
              Distance
            </Option>
            <Option value={FILTER_BY_NEXT_AVAILABILITY} key="sort-3">
              Next Available
            </Option>
            <Option value={FILTER_BY_AVAILABILITY} key="sort-2">
              Most Availability
            </Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label="Distance from patient">
          <Select
            value={distanceFromPatient}
            defaultValue={distanceFromPatient}
            placeholder="Select a filter/sort"
            optionFilterProp="children"
            onChange={val => {
              setDistanceFromPatient(val);
            }}
          >
            <Option value={5} key="distance-5">
              5 Miles
            </Option>
            <Option value={15} key="distance-15">
              15 Miles
            </Option>
            <Option value={25} key="distance-25">
              25 Miles
            </Option>
            <Option value={50} key="distance-50">
              50 Miles
            </Option>
            <Option value={null} key="distance-any">
              Any Miles
            </Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default MapToggleAndSort;
