import { AppContext } from '../modules/core/contexts/AppContext';
import { FC, useContext } from 'react';
import { FeatureFlag, hasFeature } from '../helpers';
import { isAdmin, isBlockitAdmin } from '../modules/core/components/PrivateRoute';

interface Props {
  children: JSX.Element;
}

export const AuthorizeCSVExport: FC<Props> = ({ children }): JSX.Element | null => {
  const { currentOrganization } = useContext(AppContext);

  if (currentOrganization) {
    if (hasFeature(currentOrganization.featureFlags, FeatureFlag.RestrictCSVExportsForBlockitAdmins)) {
      if (isAdmin(currentOrganization)) {
        return children;
      }
    } else {
      if (isAdmin(currentOrganization) || isBlockitAdmin(currentOrganization)) {
        return children;
      }
    }
  }

  return null;
};
