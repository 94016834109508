import { Button } from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { Spinner } from '../../../components';
import { AppContext } from '../../core/contexts/AppContext';
import { CreateGroupOrganizationModal } from './CreateGroupOrganizationModal';

interface Data {
  getOrganizations: Organization[];
}

interface Variables {
  regionId: string;
  isActive: boolean;
}

interface Organization {
  id: string;
  name: string;
}

interface Props {
  groupId: string;
}

const getOrganizationsQuery = gql`
  query GetOrganizations($regionId: String, $isActive: Boolean) {
    getOrganizations(regionId: $regionId, isActive: $isActive) {
      id
      name
    }
  }
`;

export const CreateGroupOrganization: FC<Props> = ({ groupId }): JSX.Element => {
  const [showCreateGroupOrganizationModal, setShowCreateGroupOrganizationModal] = useState(false);
  const { currentOrganization } = useContext(AppContext);

  if (!currentOrganization) {
    return <></>;
  }

  return (
    <Query<Data, Variables>
      query={getOrganizationsQuery}
      variables={{ regionId: currentOrganization.region.id, isActive: true }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Spinner />;
        if (error || !data) return <div>Error</div>;

        const { getOrganizations } = data;

        return (
          <>
            <Button htmlType="button" onClick={() => setShowCreateGroupOrganizationModal(true)}>
              Add Organization
            </Button>
            <CreateGroupOrganizationModal
              groupId={groupId}
              onCancel={() => setShowCreateGroupOrganizationModal(false)}
              onOk={() => setShowCreateGroupOrganizationModal(false)}
              organizations={getOrganizations}
              visible={showCreateGroupOrganizationModal}
            />
          </>
        );
      }}
    </Query>
  );
};
