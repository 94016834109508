import { Alert, Button, Card, Col, Form, Input, message, Row } from 'antd';
import queryString from 'query-string';
import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { validateEmail } from '../../../helpers';
import { history } from '../../core/components/App/history';
import { PublicLayout } from '../../core/components/App/PublicLayout';
import { QuizForm } from '../components/QuizForm';
import { quiz1Questions } from '../quizes/quiz1Questions';

const Styles = styled.div`
  .ant-radio-wrapper {
    white-space: pre-wrap;
    display: block;

    span {
      text-transform: none;
    }
  }

  .error-missing-email-wrapper {
    height: 100vh;
  }
`;

export interface Question {
  question: string;
  answers: string[];
  selectedAnswer: number | undefined;
  correctAnswer: number;
  type: OrganizationType;
}

interface Props {
  id: string;
  type: string;
}

interface RenderQuizFormProps {
  quizId: string;
  type: OrganizationType;
  email: string;
}

export enum OrganizationType {
  SENDER = 'sender',
  RECEIVER = 'receiver',
  BOTH = 'both',
}

const filterOutIrrelvantQuestions = (type: OrganizationType, questions: Question[]): Question[] => {
  return questions.filter(q => {
    if (type === OrganizationType.BOTH || q.type === OrganizationType.BOTH || q.type === type) {
      return true;
    }
    return false;
  });
};

const RenderQuizForm: FC<RenderQuizFormProps> = ({ quizId, type, email }): JSX.Element => {
  switch (quizId) {
    case '1':
      return <QuizForm questions={filterOutIrrelvantQuestions(type, quiz1Questions)} email={email} />;
    default:
      return <QuizForm questions={filterOutIrrelvantQuestions(type, quiz1Questions)} email={email} />;
  }
};

const getTypeEnum = (type: string | null | undefined | string[]): OrganizationType | undefined => {
  if (type && typeof type === 'string') {
    switch (type.toLowerCase()) {
      case 'sender':
        return OrganizationType.SENDER;
      case 'receiver':
        return OrganizationType.RECEIVER;
      default:
        return OrganizationType.BOTH;
    }
  }

  return;
};

export const QuizContainer: FC<RouteComponentProps<Props>> = (props): JSX.Element => {
  const id = props.match.params.id;
  const { email, type } = queryString.parse(history.location.search);
  const typeEnum = getTypeEnum(type);
  const parsedEmail = typeof email === 'string' ? (email as string) : undefined;
  const [emailInput, setEmailInput] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string | undefined>(parsedEmail);

  const handleOnSubmitEmail = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (validateEmail(emailInput).length === 0) {
      setUserEmail(emailInput);
    } else {
      message.error('Please enter a valid email address.');
    }
  };

  if (!typeEnum) {
    return (
      <PublicLayout>
        <Styles>
          <Row type="flex" justify="center" align="middle" className="error-missing-email-wrapper">
            <Col span={12}>
              <Alert
                message="Error"
                description="There was a problem with the link you were given. Please contact Blockit to request a new link. Thank you."
                type="error"
                showIcon
              />
            </Col>
          </Row>
        </Styles>
      </PublicLayout>
    );
  }

  if (!userEmail) {
    return (
      <PublicLayout>
        <Styles>
          <Row type="flex" justify="center">
            <Col span={12}>
              <Card title="Learning Module Assessment Questions">
                <Row type="flex" justify="center">
                  <Col span={18}>
                    <Form>
                      <Form.Item label="Please Enter Your Email">
                        <Input
                          type="email"
                          placeholder="Name@Example.com"
                          value={emailInput}
                          onChange={e => setEmailInput(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" onClick={handleOnSubmitEmail} block>
                          Continue
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Styles>
      </PublicLayout>
    );
  }

  return (
    <PublicLayout>
      <Styles>
        <Row type="flex" justify="center">
          <Col span={12}>
            <Card title="Learning Module Assessment Questions">
              <RenderQuizForm quizId={id} type={typeEnum} email={userEmail} />
            </Card>
          </Col>
        </Row>
      </Styles>
    </PublicLayout>
  );
};
