import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../core/contexts/AppContext';
import { Spinner } from '../../../components';
import { useViewerQuery } from '../../../hooks/queries/useViewerQuery';

interface Props {
  params: Params;
}

interface Params {
  regionId?: string;
  product?: string;
}

export const LoginStep: FC<Props> = ({ params }): JSX.Element => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const { data, error, loading } = useViewerQuery({});

  /* todo - error handling:
    Unless hitting this page directly, an error here means a user was created but the viewer could not be fetched.
    Redirect to a temporary login page before continuing with signup flow?
   */
  if (data && !error && !loading) {
    appContext.reset();
    appContext.setViewer(data.viewer);
    appContext.setIsAuthenticated(true);

    let queryString = '';
    if (params.regionId) {
      queryString += `?regionId=${params.regionId}`;
      if (params.product) queryString += `&product=${params.product}`;
    } else {
      if (params.product) queryString += `?product=${params.product}`;
    }

    // todo - temporarily only use a modified free subscription page
    history.push(`/account-signup/subscribe/free${queryString}`);
  }

  return <Spinner />;
};
