import React, { FC } from 'react';
import { Form, Switch } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectIntegrated: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="This profile has scheduling:"
    extra="Enables and disables scheduling. Send-to-Provider is still allowed, if enabled"
  >
    {form.getFieldDecorator('isIntegrated', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Switch />)}
  </Form.Item>
);
