import { Popconfirm, Icon, message } from 'antd';
import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { DeleteAvailabilityLimit, GetAvailabilityLimitSummary } from '../../../../GraphQL';
import { formatBlackoutDate } from '../../../../helpers';

class AvailabilityLimitItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalOpen: false,
      loading: false,
      errorMessage: null,
    };
  }

  handleDeleteAvailabilityLimit = async () => {
    this.setState({ loading: true });

    try {
      await this.props.client.mutate({
        mutation: DeleteAvailabilityLimit,
        variables: {
          DeleteAvailabilityLimitInput: {
            organizationId: this.props.organizationId,
            profileId: this.props.profileId,
            limitId: this.props.availabilityLimitObject.id,
          },
        },
        refetchQueries: [
          {
            query: GetAvailabilityLimitSummary,
            variables: {
              organizationId: this.props.organizationId,
              profileId: this.props.profileId,
            },
          },
        ],
      });
      this.setState({ loading: false, deleteModalOpen: false });
    } catch (e) {
      message.warn('An error occured. Please wait a moment and try again.');
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const limit = this.props.availabilityLimitObject;
    return (
      <React.Fragment>
        <li className="pb-2 text-sm flex text-grey-darker w-full">
          <p>
            {formatBlackoutDate(limit.start)}
            {limit.end ? ` - ${formatBlackoutDate(limit.end)}` : null}
            {`: ${limit.count}`}
          </p>
          {this.props.authorizedToEdit ? (
            <span className="ml-auto cursor-pointer text-grey-dark">
              <Popconfirm
                title="Are you sure that you want to remove this availability limit?"
                onConfirm={this.handleDeleteAvailabilityLimit}
                okText="Yes"
                cancelText="No"
              >
                <Icon type="delete" />
              </Popconfirm>
            </span>
          ) : null}
        </li>
      </React.Fragment>
    );
  }
}

export default withApollo(AvailabilityLimitItem);
