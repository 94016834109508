import { Icon, Popconfirm, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { gql } from '@apollo/client';
import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Spinner } from '../../../components';

interface Data {
  getOrganization: { groups: Group[] };
}

interface Group {
  id: string;
  name: string;
  isReferralNetwork: boolean;
  locations: Location[];
}

interface Location {
  id: string;
  name: string;
}

interface Props {
  organizationId: string;
  canEdit: boolean;
}

interface Variables {
  organizationId: string;
}

const groupsQuery = gql`
  query groupsQuery($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      groups(isActive: true) {
        id
        name
        isReferralNetwork
        locations {
          id
          name
        }
      }
    }
  }
`;

const updateGroupMutation = gql`
  mutation UpdateGroup($UpdateGroupInput: UpdateGroupInput!) {
    UpdateGroup(UpdateGroupInput: $UpdateGroupInput) {
      id
      name
      is_referral_network
      is_active
    }
  }
`;

interface UpdateGroupVariables {
  UpdateGroupInput: {
    id: string;
    isActive: boolean;
  };
}

const StyledIcon = styled(Icon)`
  font-size: 1.2em;
  padding-left: 0.5em;
  padding-right: 1.5em;
`;

const StyledWarning = styled.div`
  font-weight: 600;
  color: #d97706;
`;

export const GroupsTable: FC<Props> = ({ organizationId, canEdit }): JSX.Element => {
  const [updateGroup] = useMutation<UpdateGroupVariables>(updateGroupMutation);

  const handleOnDelete = (id: string): void => {
    updateGroup({
      variables: {
        UpdateGroupInput: {
          id,
          isActive: false,
        },
      },
      refetchQueries: [{ query: groupsQuery, variables: { organizationId } }],
    });
  };

  const [visibleLocations, setVisibleLocations] = useState<{ [key: string]: boolean }>({});

  const toggleVisibility = (id: string) => {
    setVisibleLocations(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  return (
    <Query<Data, Variables>
      query={groupsQuery}
      variables={{ organizationId: organizationId }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) return <Spinner />;
        if (error || !data) return <div>Error</div>;

        const { getOrganization } = data;

        const columns: ColumnProps<Group>[] = [
          {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
              if (record.isReferralNetwork === false) {
                return (
                  <>
                    <span>{record.name}</span>
                    <StyledWarning className="visitTypeWarn">
                      <Icon type="warning" theme="filled" />
                      &nbsp;Restricted Config: Contact your blockit Administrator
                    </StyledWarning>
                  </>
                );
              } else {
                return <span>{record.name}</span>;
              }
            },
          },
          {
            title: 'Locations',
            dataIndex: 'name',
            width: '400px',
            render: (text, record) => {
              const firstThreeLocations = record.locations.slice(0, 3);
              const remainingLocations = record.locations.slice(3);
              const isVisible = visibleLocations[record.id];

              return (
                <>
                  {firstThreeLocations.map(location => (
                    <div key={location.id}>{location.name}</div>
                  ))}
                  {isVisible && (
                    <div>
                      {remainingLocations.map(location => (
                        <div key={location.id}>{location.name}</div>
                      ))}
                    </div>
                  )}
                  {remainingLocations.length > 0 && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a onClick={() => toggleVisibility(record.id)} href="#">
                      {isVisible ? 'Show less' : 'Additional locations'}
                    </a>
                  )}
                </>
              );
            },
          },
        ];

        if (canEdit) {
          columns.push({
            title: 'Actions',
            render: (text, record) => (
              <>
                <Link to={`/organizations/${organizationId}/groups/${record.id}/update`}>
                  <StyledIcon type="edit" />
                </Link>

                <Popconfirm
                  title="Sure you want to delete?"
                  cancelText="Cancel"
                  okText="Delete"
                  onCancel={e => console.log('CANCEL')}
                  onConfirm={e => handleOnDelete(record.id)}
                >
                  <Link to={`#`}>
                    <StyledIcon type="delete" />
                  </Link>
                </Popconfirm>
              </>
            ),
          });
        }

        return <Table<Group> columns={columns} dataSource={getOrganization.groups} pagination={false} rowKey="id" />;
      }}
    </Query>
  );
};
