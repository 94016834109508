import { Affix, Col, Icon, Row } from 'antd';
import moment from 'moment';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { SearchWizardContainerContext } from '../../../pages/SearchWizardContainer';

const Styles = styled.div`
  background-color: white;

  .day-of-week {
    font-weight: 600;
  }

  .times-wrapper {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .date-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-arrow,
  .right-arrow {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`;

interface SearchWizardContainerContextInterface {
  clearAlreadyRerendered: () => void;
  triggerRerender: () => void;
  isSortingByAvailability: boolean;
}

interface Props {
  showMap: boolean;
  arrowsColSpan: number;
  slotsColSpan: number;
  handleSearchFuture: () => void;
  handleSearchPast: () => void;
  dates: moment.Moment[];
}

const DatesBar: FC<Props> = ({
  showMap,
  arrowsColSpan,
  slotsColSpan,
  handleSearchFuture,
  handleSearchPast,
  dates,
}): JSX.Element => {
  const { clearAlreadyRerendered, triggerRerender, isSortingByAvailability } = useContext<
    SearchWizardContainerContextInterface
  >(SearchWizardContainerContext);
  return (
    <Styles>
      <Affix offsetTop={0}>
        <Row gutter={16}>
          <Col span={showMap ? 8 : 0} />
          <Col span={showMap ? 16 : 24} className="times-wrapper">
            <Row gutter={16} type="flex" justify="center" align="middle">
              <Col
                span={arrowsColSpan}
                className="left-arrow"
                onClick={() => {
                  if (isSortingByAvailability) {
                    clearAlreadyRerendered();
                    triggerRerender();
                  }
                  handleSearchPast();
                }}
              >
                {moment(dates[0]).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY') ? null : <Icon type="left" />}
              </Col>
              {dates.map(item => (
                <Col span={slotsColSpan} className="date-wrapper" key={item.format('MM/DD/YYYY')}>
                  <span className="day-of-week">{item.format('dddd')}</span>
                  <span>{item.format('MMM Do')}</span>
                </Col>
              ))}
              <Col
                span={arrowsColSpan}
                className="right-arrow"
                onClick={() => {
                  if (isSortingByAvailability) {
                    clearAlreadyRerendered();
                    triggerRerender();
                  }
                  handleSearchFuture();
                }}
              >
                <Icon type="right" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Affix>
    </Styles>
  );
};

export default DatesBar;
