import * as Sentry from '@sentry/browser';
import React, { Component, ErrorInfo } from 'react';

const initialState = {
  hasError: false,
};

interface State {
  hasError: boolean;
}

/**
 * React ErrorBoundary for all pages. This cannot be refactored to a functional component because (as of React 16.11),
 * componentDidCatch is not avaliable as a hook.
 * TODO: Refactor / Merge with ErrorBoundaryTimeSlot, as they are mostly duplicates.
 */
export class ErrorBoundaryMain extends Component<{}, State> {
  readonly state: State = initialState;

  static getDerivedStateFromError(error: Error | null): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error | null, info: ErrorInfo): void {
    Sentry.captureException(error);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className="flex justify-center items-center h-full text-grey-dark">
          <div className="card w-1/2">
            <div className="card-head">Error</div>
            <div className="p-4 text-lg">
              <h3>Please refresh the page to try again.</h3>

              <p>
                If refreshing doesn&apos;t help, please contact us via chat by clicking on the icon in the bottom right.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
