import { Table, Tag } from 'antd';
import { gql } from '@apollo/client';
import queryString, { ParsedQuery } from 'query-string';
import React, { FC, useContext } from 'react';
import { Query } from '@apollo/client/react/components';
import { IsOrgAdminOrBlockitAdmin } from '../../../components/IsOrgAdminOrBlockitAdmin';
import { DisplayError } from '../../core/components/App/DisplayError';
import { history } from '../../core/components/App/history';
import { AppContext } from '../../core/contexts/AppContext';
import { isAdminOrBlockitAdmin } from '../../core/components/PrivateRoute';
import { EmailNotificationCheckbox } from './EmailNotificationCheckbox';
import { SmsNotificationCheckbox } from './SmsNotificationCheckbox';
import { PasswordResetButton } from './PasswordResetButton';
import { UserActions } from './UserActions';

const PaginatedUsersQuery = gql`
  query(
    $organizationId: ID!
    $locationId: ID
    $pageNumber: Int
    $pageSize: Int
    $active: Boolean
    $nameFilter: String
  ) {
    PaginatedUsers(
      organizationId: $organizationId
      locationId: $locationId
      pageNumber: $pageNumber
      pageSize: $pageSize
      active: $active
      nameFilter: $nameFilter
    )
      @connection(
        key: "PaginatedUsers"
        filter: ["organizationId", "locationId", "pageSize", "pageNumber", "active", "nameFilter"]
      ) {
      totalEntries
      pageNumber
      pageSize
      totalPages
      entries {
        id
        name
        email
        phone
        isAdmin(organizationId: $organizationId)
        isEditor(organizationId: $organizationId)
        userOrganizations(organizationId: $organizationId) {
          id
          userId
          emailNotifications
          smsNotifications
          organizationId
        }
      }
    }
  }
`;

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
  },
  {
    title: 'Role',
    key: 'role',
    render: (record: EntriesInterface) => {
      if (record.isAdmin) {
        return <Tag>Admin</Tag>;
      } else if (record.isEditor) {
        return <Tag>Editor</Tag>;
      } else {
        return <Tag>Scheduler</Tag>;
      }
    },
  },
  {
    title: 'Reset Password',
    key: 'reset-password',
    render: (record: EntriesInterface) => <PasswordResetButton user={record} />,
  },
];

interface EntriesInterface {
  id: string;
  name: string;
  email: string;
  phone: string;
  isAdmin: boolean;
  isEditor: boolean;
  userOrganizations: {
    id: string;
    userId: string;
    organizationId: string;
    emailNotifications: boolean;
    is_active: boolean;
  }[];
}

interface Payload {
  entries: EntriesInterface[];
  pageNumber: number;
  totalEntries: number;
  totalPages: number;
  pageSize: number;
}

interface QueryResponse {
  PaginatedUsers: Payload;
}

interface QueryVariables {
  organizationId: string;
  locationId?: string;
  active: boolean;
  nameFilter?: string;
}

const updateUrl = (pageNumber: number, pageSize: number): void => {
  const queryParams: ParsedQuery = queryString.parse(history.location.search);

  history.push(
    history.location.pathname +
      '?' +
      queryString.stringify({
        ...queryParams,
        pageSize,
        pageNumber,
      })
  );
};

interface Props {
  active: boolean;
  nameFilter: string;
}

export const UsersTable: FC<Props> = ({ active, nameFilter }): JSX.Element => {
  const { currentOrganization, currentLocation } = useContext(AppContext);

  const queryParams: ParsedQuery = queryString.parse(history.location.search);
  const additionalQueryVariables = {
    pageSize: queryParams.pageSize && typeof queryParams.pageSize == 'string' ? parseInt(queryParams.pageSize, 10) : 10,
    pageNumber:
      queryParams.pageNumber && typeof queryParams.pageNumber == 'string' ? parseInt(queryParams.pageNumber, 10) : 1,
  };

  if (!currentLocation || !currentOrganization) {
    return <></>;
  }

  const adminColumns = [
    {
      title: 'Email Notifications',
      key: 'email-notification',
      render: (record: EntriesInterface) => (
        // TODO: This is broken and does not work for multi-user organizations
        <EmailNotificationCheckbox userOrganization={record.userOrganizations[0]} />
      ),
    },
    {
      title: 'SMS Notifications',
      key: 'sms-notification',
      render: (record: EntriesInterface) => {
        // TODO: This is broken and does not work for multi-user organizations
        if (record.phone) {
          return <SmsNotificationCheckbox userOrganization={record.userOrganizations[0]} />;
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Actions',
      key: 'user-actions',
      render: (record: EntriesInterface) => (
        <IsOrgAdminOrBlockitAdmin>
          <UserActions organization={currentOrganization} user={record} />
        </IsOrgAdminOrBlockitAdmin>
      ),
    },
  ];

  return (
    <Query<QueryResponse, QueryVariables>
      query={PaginatedUsersQuery}
      variables={{
        organizationId: currentOrganization.id,
        locationId: currentLocation.id,
        ...additionalQueryVariables,
        nameFilter,
        active,
      }}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data }) => {
        let response: Payload = {
          entries: [],
          pageNumber: 1,
          totalEntries: 0,
          totalPages: 0,
          pageSize: 10,
        };

        if (data && data.PaginatedUsers) {
          response = data && data.PaginatedUsers ? data.PaginatedUsers : response;
        }

        return (
          <>
            <DisplayError error={error} />
            <Table<EntriesInterface>
              loading={loading}
              dataSource={response.entries}
              columns={isAdminOrBlockitAdmin(currentOrganization) ? [...columns, ...adminColumns] : columns}
              rowKey={record => record.id}
              onChange={pagination => updateUrl(pagination.current || 1, pagination.pageSize || 10)}
              pagination={{
                pageSize: response.pageSize,
                position: 'bottom',
                showSizeChanger: true,
                defaultCurrent: 0,
                current: response.pageNumber,
                total: response.totalEntries,
              }}
            />
          </>
        );
      }}
    </Query>
  );
};
