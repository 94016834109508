import { gql } from '@apollo/client';

const IsEligible = gql`
  query isEligible($patientId: String!, $profileId: String!) {
    isEligible(patientId: $patientId, profileId: $profileId) {
      id
      eligible
      payload
    }
  }
`;

export { IsEligible };
