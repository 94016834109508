import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

export const questionnaireSaveAsPDF = (prefix?: string, id?: string): void => {
  const element = document.getElementById('questionnaire-answers');
  const button = document.querySelector('#questionnaire-answers button') as HTMLElement;

  if (element && button) {
    // Temporarily hide the button to hide it from the PDF
    button.style.display = 'none';

    html2canvas(element).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      // Construct the file name
      const fileName = prefix && id ? `${prefix}_${id}_QuestionnaireAnswers.pdf` : 'QuestionnaireAnswers.pdf';
      pdf.save(fileName);

      // Show the button again after the PDF is created
      button.style.display = 'block';
    });
  }
};
