import React, { FC, useEffect } from 'react';
import { Engagement } from '../hooks/useCreateConsumerSchedulingEngagementMutation';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { SuccessStepInstant } from './SuccessStepInstant';
import { SuccessStepScheduled } from './SuccessStepScheduled';

interface Props {
  engagement: Engagement;
  apptType: string;
  patientName: string;
  organization: Organization;
  embedded: boolean;
}

export const SuccessStep: FC<Props> = ({ engagement, apptType, patientName, organization, embedded }): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);

  useEffect(() => {
    if (window.parent) {
      window.parent.postMessage(JSON.stringify({ message: 'appointmentSuccess', appointmentId: engagement.id }), '*');
    }
  }, [engagement]);

  if (apptType === 'instant') return <SuccessStepInstant engagement={engagement} organization={organization} />;

  return (
    <SuccessStepScheduled
      engagement={engagement}
      patientName={patientName}
      organization={organization}
      embedded={embedded}
    />
  );
};
