import { Popconfirm, Icon, message } from 'antd';
import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { DeleteBlackoutDate, ListBlackoutDates } from '../../../../GraphQL';
import { formatBlackoutDate } from '../../../../helpers';

class BlackoutListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalOpen: false,
      loading: false,
      errorMessage: null,
    };
  }

  handleDeleteBlackoutDate = async () => {
    this.setState({ loading: true });

    try {
      await this.props.client.mutate({
        mutation: DeleteBlackoutDate,
        variables: {
          DeleteBlackoutDateInput: { id: this.props.blackoutDateObject.id, scheduleId: this.props.scheduleId },
        },
        refetchQueries: [
          {
            query: ListBlackoutDates,
            variables: {
              ListBlackoutDatesInput: { scheduleId: this.props.scheduleId, reason: 'OUT_OF_OFFICE' },
            },
          },
        ],
      });
      this.setState({ loading: false, deleteModalOpen: false });
    } catch (e) {
      message.warn('An error occured. Please wait a moment and try again.');
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const bo = this.props.blackoutDateObject;
    let date = null;

    if (bo.startTime && bo.endTime && bo.end) {
      date = `${formatBlackoutDate(bo.start)} - ${formatBlackoutDate(bo.end)} ${bo.startTime} - ${bo.endTime}`;
    } else {
      date = `${formatBlackoutDate(bo.start)} ${bo.end ? ` - ${formatBlackoutDate(bo.end)}` : null}`;
    }

    return (
      <React.Fragment>
        <li className="pb-2 text-sm flex text-grey-darker w-full">
          <p>{date}</p>
          {this.props.authorizedToEdit ? (
            <span className="ml-auto cursor-pointer text-grey-dark">
              <Popconfirm
                title="Are you sure that you want to remove this blackout date?"
                onConfirm={this.handleDeleteBlackoutDate}
                okText="Yes"
                cancelText="No"
              >
                <Icon type={this.state.loading ? 'loading' : 'delete'} />
              </Popconfirm>
            </span>
          ) : null}
        </li>
      </React.Fragment>
    );
  }
}

export default withApollo(BlackoutListItem);
