import { History } from 'history';
import React, { FC } from 'react';
import { PublicProfile } from '../containers/DirectoryContext';
import { DirectoryProfileBody } from './DirectoryProfileBody';
import { DirectoryProfileHeader } from './DirectoryProfileHeader';

interface Props {
  history: History;
  profile: PublicProfile;
}

export const DirectoryProfile: FC<Props> = ({ history, profile }): JSX.Element => (
  <>
    <DirectoryProfileHeader
      history={history}
      locationName={profile.location.name}
      name={profile.displayName}
      organizationName={profile.organization.name}
    />
    <DirectoryProfileBody profile={profile} />
  </>
);
