import { ReactElement } from 'react';
import { OptionProps } from 'antd/lib/select';

export const caseInsensitiveSelectFilterOption = (input: string, option: ReactElement<OptionProps>): boolean => {
  if (!!option && !!option.props && !!option.props.children && typeof option.props.children === 'string') {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return false;
};
