import _ from 'lodash';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { InternalServerErrorAlert } from '../../../components';
import { Option, Select } from '../../../system/components/Select';
import {
  Patient,
  useSearchConsumerSchedulingPatientsInOrganizationQuery,
} from '../hooks/useSearchConsumerSchedulingPatientsInOrganizationQuery';

interface Props {
  onSelect: (value: string | JSX.Element | undefined) => void;
  organizationId: string;
}

const createPatientOption = (patient: Patient): JSX.Element => {
  const dob = moment(patient.birthDate).format('MM/DD/YYYY');
  return (
    <Option key={patient.id} value={patient.id}>
      <div>
        {`${patient.givenName1} ${patient.familyName}`}
        <span style={{ fontSize: 12, paddingLeft: 8 }}>{dob}</span>
        {patient.identifier ? (
          <span style={{ fontSize: 12, color: '#aaa', float: 'right' }}>
            &nbsp;MRN: {patient.identifier.slice(0, 15)}
          </span>
        ) : null}
      </div>
    </Option>
  );
};

export const SearchConsumerSchedulingPatients: FC<Props> = ({ onSelect, organizationId }): JSX.Element => {
  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = (patientSearchText: string): void => {
    if (patientSearchText && patientSearchText.length > 2) {
      setSearching(true);
      setSearchText(patientSearchText);
    } else {
      setSearchText('');
    }
  };

  const debounceHandleSearch = _.debounce(handleSearch, 300);

  const handleChange = (value: string | JSX.Element | undefined): void => {
    if (value === undefined) {
      setPatient(undefined);
      setSearchText('');
      onSelect(undefined);
    }
  };

  const { data, error, loading } = useSearchConsumerSchedulingPatientsInOrganizationQuery({
    variables: { organizationId, searchText },
    onCompleted: () => setSearching(false),
    skip: searchText === '',
  });

  if (error) return <InternalServerErrorAlert error={error} />;

  const patients = data?.searchConsumerSchedulingPatientsInOrganization || [];

  return (
    <Select
      allowClear
      defaultActiveFirstOption={false}
      defaultValue={patient ? createPatientOption(patient) : searchText ? searchText : undefined}
      filterOption={false}
      loading={loading}
      notFoundContent={null}
      onChange={handleChange}
      onSearch={debounceHandleSearch}
      onSelect={onSelect}
      placeholder="Search for a patient."
      showSearch
    >
      {patients.length > 0 && !searching && searchText ? patients.map(patient => createPatientOption(patient)) : null}
    </Select>
  );
};
