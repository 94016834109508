import { gql } from '@apollo/client';

const GetUser = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
    }
  }
`;

// todo - cleanup for viewer
export { GetUser };
