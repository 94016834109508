import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  required?: boolean;
}

export const InputPatientPostalCode: FC<Props> = ({ form, required = false }): JSX.Element => (
  <Form.Item label="Postal Code">
    {form.getFieldDecorator('postalCode', {
      rules: [
        { required: required, message: "Please enter patient's postal code." },
        {
          len: 5,
          message: 'Please enter 5 digits',
        },
      ],
    })(<Input allowClear />)}
  </Form.Item>
);
