import _ from 'lodash';
import React, { FC, useState } from 'react';
import { Alert, Button, Card, Col, Icon, List, Popconfirm, Row } from 'antd';
import { getProfileQuery } from '../hooks/useGetProfileQuery';
import { Modal } from '../../../components';
import { Procedure, Profile } from '../index';
import { ProcedureForm } from './ProcedureForm';
import { useDeleteProfileProcedureMutation } from '../hooks/useDeleteProfileProcedureMutation';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props {
  profile: Profile;
}

interface FlattenedProcedure extends Procedure {
  durationInMinutes: number;
}

export const ProfileVisitTypesAlert: FC<Props> = ({ profile }): JSX.Element | null => {
  if (profile.profileProcedures.length === 0) {
    return (
      <Alert
        message="This profile has no configured visit types and will not be schedulable."
        type="warning"
        showIcon
      />
    );
  }

  return null;
};

export const ProfileVisitTypes: FC<Props> = ({ profile }): JSX.Element => {
  const [addCPTModalOpen, setAddCPTModalOpen] = useState<boolean>(false);

  const [deleteProfileProcedure] = useDeleteProfileProcedureMutation({});

  const handleCloseModals = (): void => {
    setAddCPTModalOpen(false);
  };

  const handleDeleteProfileProcedure = (id: string): void => {
    try {
      deleteProfileProcedure({
        variables: { id: id },
        refetchQueries: [{ query: getProfileQuery, variables: { id: profile.id } }],
      });
    } catch (error) {
      // todo - error handling
      console.error(error);
    }
  };

  const flattenedProcedures: FlattenedProcedure[] = profile.profileProcedures.map(pp => ({
    ...pp.procedure,
    id: pp.id,
    durationInMinutes: pp.durationInMinutes,
  }));

  return (
    <Card
      bordered={false}
      size="small"
      title="Visit Types"
      extra={
        <BlockRestrictedUsers>
          <Button size="small" onClick={() => setAddCPTModalOpen(true)}>
            <Icon type="plus" />
          </Button>
        </BlockRestrictedUsers>
      }
    >
      <Modal closeModal={handleCloseModals} isOpen={addCPTModalOpen} loading={false} title="Add Visit Type">
        <ProcedureForm profile={profile} closeModal={handleCloseModals} />
      </Modal>
      <List
        size="small"
        locale={{
          emptyText: <ProfileVisitTypesAlert profile={profile} />,
        }}
        dataSource={_.orderBy(flattenedProcedures, ['code', p => p.specialty.name, 'name'])}
        renderItem={procedure => (
          <List.Item
            extra={
              <BlockRestrictedUsers>
                <Popconfirm
                  title="Are you sure that you want to remove this visit type?"
                  onConfirm={() => {
                    handleDeleteProfileProcedure(procedure.id);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Icon type="delete" />
                </Popconfirm>
              </BlockRestrictedUsers>
            }
          >
            <Row>
              <Col span={24}>
                <span style={{ fontWeight: 800 }}>{procedure.display || procedure.name}</span>
                &nbsp;<i style={{ color: '#999' }}>({procedure.specialty.name})</i>
              </Col>
              <Col span={24}>{procedure.durationInMinutes || 0} minutes</Col>
            </Row>
          </List.Item>
        )}
      />
    </Card>
    //   </Col>
    //   <Col xs={6}>
    //     <Alert
    //       message="What are visit types?"
    //       description={
    //         <>
    //           <p>
    //             Visit types are the services that are offered by a particular schedule. Visit types describe <b>what</b>{' '}
    //             service is offered and how long, but not when.
    //           </p>
    //           <br />
    //           <p>
    //             For example, if your practice offered a 30-minute new patient appointment, and a 15-minute follow-up{' '}
    //             appointment, these would both be configured as visit types.
    //           </p>
    //         </>
    //       }
    //       type="info"
    //     />
    //   </Col>
    // </Row>
  );
};
