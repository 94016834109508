import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import packageInfo from '../package.json';
import config from './config';

const configSentry = (): void => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: 'https://9dcbb39bc8092ca88c46bbb582374fe6@sentry.blockit.cloud/3',
      environment: config.environment,
      release: packageInfo.version,
      integrations: [
        new CaptureConsole({
          levels: ['error'],
        }),
      ],
      blacklistUrls: [],
      whitelistUrls: ['app.blockitnow.com', 'blockit.cloud'],
      ignoreErrors: [
        'Network request failed',
        'Failed to fetch',
        'NetworkError',
        'Network error: cancelled',
        'The network connection was lost',
        'The Internet connection appears to be offline',
        'TypeError: Cancelled',
        'TypeError - cancelled',
        'SecurityError: Blocked a frame with origin "https://app.blockitnow.com" from accessing a cross-origin frame. Protocols, domains, and ports must match.',
        'TypeError - Origin https://app.blockitnow.com is not allowed by Access-Control-Allow-Origin',
        'withrealtime/messaging',
        'NS_ERROR_NOT_AVAILABLE',
      ],
    });
  }
};

export default configSentry();
