import React, { FC } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InputAddress1 } from './InputAddress1';
import { InputAddress2 } from './InputAddress2';
import { InputCity } from './InputCity';
import { InputName } from './InputName';
import { InputDisplayName } from './InputDisplayName';
import { InputPhone } from '../../profiles/components/InputPhone';
import { InputEmail } from '../../profiles/components/InputEmail';
import { InputPostalCode } from './InputPostalCode';
import { InputState } from './InputState';
import { Location, TimeZone } from '../index';
import { SelectTimeZone } from './SelectTimeZone';
import moment from 'moment';

interface Props extends FormComponentProps {
  form: WrappedFormUtils<LocationFormData>;
  loading: boolean;
  location?: Location;
  onSubmit: (values: LocationFormData) => void;
}

export interface LocationFormData {
  name: string;
  displayName?: string;
  phone?: string;
  email?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  timeZone: TimeZone;
}

const LocationFormComponent: FC<Props> = ({ form, location, loading, onSubmit }): JSX.Element => {
  const handleLocationFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  const guessUserTimezone = moment.tz
    .guess()
    .replace('/', '_')
    .toUpperCase();

  return (
    <Form onSubmit={handleLocationFormSubmit}>
      <Row gutter={[8, 8]}>
        <Col xs={12}>
          <InputName form={form} initialValue={location?.name} />
        </Col>
        <Col xs={12}>
          <InputDisplayName form={form} initialValue={location?.displayName || ''} />
        </Col>
        <Col xs={12}>
          <InputPhone form={form} initialValue={location?.phone} />
        </Col>
        <Col xs={12}>
          <InputEmail form={form} initialValue={location?.email} />
        </Col>
        <Col xs={12}>
          <InputAddress1 form={form} initialValue={location?.address1} />
        </Col>
        <Col xs={12}>
          <InputAddress2 form={form} initialValue={location?.address2} />
        </Col>
        <Col xs={12}>
          <InputCity form={form} initialValue={location?.city} />
        </Col>
        <Col xs={12}>
          <InputState form={form} initialValue={location?.state} />
        </Col>
        <Col xs={12}>
          <InputPostalCode form={form} initialValue={location?.postalCode} />
        </Col>

        <Col xs={12}>
          <SelectTimeZone form={form} initialValue={location?.timeZone || guessUserTimezone} />
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Button htmlType="submit" type="primary" loading={loading}>
          Add
        </Button>
      </Row>
    </Form>
  );
};

export const LocationForm = Form.create<Props>()(LocationFormComponent);
