import { Affix, Col, Icon, Row } from 'antd';
import * as moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useViewSlotsContainerContext } from './ViewSlotsContainer';

const Styles = styled.div`
  background-color: white;

  .day-of-week {
    font-weight: 600;
  }

  .times-wrapper {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .date-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left-arrow,
  .right-arrow {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`;

export const DatesBar: FC<{}> = (): JSX.Element => {
  const { dates, setDates, generateDates, arrowsColSpan, slotsColSpan } = useViewSlotsContainerContext();
  return (
    <Styles>
      <Affix offsetTop={0}>
        <Row gutter={16}>
          <Col span={0} />
          <Col span={24} className="times-wrapper">
            <Row gutter={16} type="flex" justify="center" align="middle">
              <Col
                span={arrowsColSpan}
                className="left-arrow"
                onClick={() => {
                  setDates(generateDates(moment.default(dates[0]).subtract(7, 'day')));
                }}
              >
                {moment.default(dates[0]).format('MM/DD/YYYY') === moment.default().format('MM/DD/YYYY') ? null : (
                  <Icon type="left" />
                )}
              </Col>
              {dates.map(
                (item: moment.Moment): JSX.Element => (
                  <Col span={slotsColSpan} className="date-wrapper" key={item.format('MM/DD/YYYY')}>
                    <span className="day-of-week">{item.format('dddd')}</span>
                    <span>{item.format('MMM Do')}</span>
                  </Col>
                )
              )}
              <Col
                span={arrowsColSpan}
                className="right-arrow"
                onClick={() => {
                  setDates(generateDates(moment.default(dates[0]).add(7, 'day')));
                }}
              >
                <Icon type="right" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Affix>
    </Styles>
  );
};
