import * as Sentry from '@sentry/browser';
import { Col, Row } from 'antd';
import React, { Component, ErrorInfo } from 'react';

const initialState = {
  hasError: false,
};

interface State {
  hasError: boolean;
}

/**
 * React ErrorBoundary for Time Slots. This cannot be refactored to a functional component because (as of React 16.11),
 * componentDidCatch is not avaliable as a hook.
 * TODO: Refactor / Merge with ErrorBoundaryMain, as they are mostly duplicates.
 */
export class ErrorBoundaryTimeSlots extends Component<{}, State> {
  readonly state: State = initialState;

  static getDerivedStateFromError(error: Error | null): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error | null, info: ErrorInfo): void {
    Sentry.captureException(error);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Row type="flex" justify="center" align="middle" style={{ height: '160px' }}>
          <Col>
            <h3 className="text-grey">Unable to Fetch Time Slots</h3>
          </Col>
        </Row>
      );
    } else {
      return this.props.children;
    }
  }
}
