import { ErrorMessage, Field } from 'formik';
import React, { FC } from 'react';

interface Props {
  name: string;
  label: string;
  className: string;
  type?: string;
  component?: string;
  placeholder?: string;
  touched: { [key: string]: string | null | boolean | undefined | {} };
  errors: { [key: string]: string | null | boolean | undefined | {} };
}

/**
 * @deprecated
 * @param param0 Props
 */
export const Fieldset: FC<Props> = ({ name, label, touched, errors, className, ...rest }) => (
  <div className="mt-2">
    <label className="text-label" htmlFor={name}>
      {label}
    </label>
    <Field
      id={name}
      name={name}
      className={className.concat(touched[name] && errors[name] ? ' border-red' : '')}
      {...rest}
    />
    <div className="text-red text-xs">
      <ErrorMessage name={name} />
    </div>
  </div>
);
