import React, { FC } from 'react';

const times = [
  '5:00am',
  '6:00am',
  '7:00am',
  '8:00am',
  '9:00am',
  '10:00am',
  '11:00am',
  '12:00pm',
  '1:00pm',
  '2:00pm',
  '3:00pm',
  '4:00pm',
  '5:00pm',
  '6:00pm',
  '7:00pm',
  '8:00pm',
  '9:00pm',
  '10:00pm',
  '11:00pm',
];

export const TableMainSection: FC = () => {
  return (
    <React.Fragment>
      {times.map(time => (
        <tr key={time}>
          <td>{time}</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
      ))}
    </React.Fragment>
  );
};
