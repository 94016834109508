import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getFeatureFlags = gql`
  query GetFeatureFlags {
    getFeatureFlags {
      id
      name
    }
  }
`;

interface FeatureFlags {
  id: string;
  name: string;
}

interface Data {
  getFeatureFlags: FeatureFlags[];
}

type Variables = {};

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetFeatureFlagsQuery = (options?: Options): Result => {
  return useQuery<Data, Variables>(getFeatureFlags, options);
};
