import { useMutation, MutationTuple } from '@apollo/client';
import { gql } from '@apollo/client';

interface CreateProfileProcedureData {
  CreateProfileProcedure: {
    id: string;
  };
}

interface CreateProfileProcedureVariables {
  CreateProfileProcedureInput: {
    profileId: string;
    duration: number;
    cptCode: string;
    procedureId: string;
  };
}

const CreateProfileProcedureMutation = gql`
  mutation CreateProfileProcedure($CreateProfileProcedureInput: CreateProfileProcedureInput!) {
    CreateProfileProcedure(CreateProfileProcedureInput: $CreateProfileProcedureInput) {
      id
    }
  }
`;

type CreateProfileProcedureMutationesult = MutationTuple<CreateProfileProcedureData, CreateProfileProcedureVariables>;

export const useCreateProfileProcedureMutation = (): CreateProfileProcedureMutationesult => {
  return useMutation<CreateProfileProcedureData, CreateProfileProcedureVariables>(CreateProfileProcedureMutation);
};
