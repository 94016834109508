import React, { FC } from 'react';
import styled from 'styled-components';
import styledMap from 'styled-map';

const paddingMap = {
  '1x': '5px',
  '2x': '10px',
  default: 0,
};

const containerColor = styledMap`
  blue: #2783c4;
  violet: #5661b3;
  default: #fff;
`;

const textColorForBgColor = styledMap`
  blue: #fff;
  violet: #fff;
  default: #000
`;

const borderMap = styledMap`
  bordered: 4px;
  default: 0;
`;

const ContainerStyles = styled.div`
  background-color: ${containerColor};
  border: 0px solid ${({ theme }) => theme.primaryColor};
  border-top-width: ${borderMap};
  box-shadow: 0px 0px 4px rgba(209, 209, 209, 0.5);
  box-sizing: border-box;
  color: ${textColorForBgColor};
  margin-bottom: 5px;

  .container--innerContainer {
    padding: ${styledMap('padding', paddingMap)};
  }
`;

interface Props {
  card?: JSX.Element;
}

export const Container: FC<Props> = ({ card, children, ...props }) => (
  <ContainerStyles {...props}>
    {card}
    <div className="container--innerContainer">{children}</div>
  </ContainerStyles>
);
