import { DataProxy, InMemoryCache } from '@apollo/client';

// TODO: Need to switch to using the public interface of InMemoryCache. This reaches into the private class members.
export const deletePaginatedCacheItems = (cache: DataProxy, cacheItemName: string): void => {
  // Loop through all the data in our cache
  // And delete any items that start with the cacheItemName
  // This empties the cache of all of our items matching regex
  const cacheItemNameRegex = new RegExp(`^${cacheItemName}`);

  if (cache instanceof InMemoryCache) {
    // This is a private instance method and we need to refactor this using the apollo-cache readCache APIs
    Object.keys(cache['data'].data.ROOT_QUERY).forEach((key): void => {
      if (key.match(cacheItemNameRegex)) {
        // This is a private instance method and we need to refactor this using the apollo-cache readCache APIs
        delete cache['data'].data.ROOT_QUERY[key];
      }
    });
  }
};
