import _ from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { Option, Select } from '../../../system/components/Select';
import {
  Patient,
  useSearchReferralPatientsInOrganizationQuery,
} from '../hooks/useSearchReferralPatientsInOrganizationQuery';
import queryString, { ParsedQuery } from 'query-string';
import { history } from '../../core/components/App/history';

interface Props {
  onSelect: (value: string | JSX.Element | undefined) => void;
  organizationId: string;
}

const formatPatientOption = (patient: Patient): JSX.Element => {
  const dob = moment(patient.birthDate).format('MM/DD/YYYY');
  return (
    <div>
      {`${patient.givenName1} ${patient.familyName}`}
      <span style={{ fontSize: 12, paddingLeft: 8 }}>{dob}</span>
      {patient.identifier ? (
        <span style={{ fontSize: 12, color: '#aaa', float: 'right' }}>
          &nbsp;MRN: {patient.identifier.slice(0, 15)}
        </span>
      ) : null}
    </div>
  );
};

const createPatientOption = (patient: Patient): JSX.Element => {
  return (
    <Option key={patient.id} value={patient.id}>
      {formatPatientOption(patient)}
    </Option>
  );
};

const getPatientFromQuery = (): Patient | undefined => {
  const queryParams: ParsedQuery = queryString.parse(history.location.search);
  if (!queryParams.patient) {
    return undefined;
  }
  return JSON.parse(queryParams.patient as string);
};

const updateURLWithPatient = (patient: Patient | undefined): void => {
  const queryParams: ParsedQuery = queryString.parse(history.location.search);
  if (!patient) {
    delete queryParams.patient;
  } else {
    queryParams.patient = JSON.stringify({
      id: patient.id,
      givenName1: patient.givenName1,
      familyName: patient.familyName,
      birthDate: patient.birthDate,
      identifier: patient.identifier,
    });
  }
  history.push(history.location.pathname + '?' + queryString.stringify(queryParams));
};

export const SearchReferralPatients: FC<Props> = ({ onSelect, organizationId }): JSX.Element | null => {
  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    const patientFromQuery = getPatientFromQuery();
    if (patientFromQuery) {
      onSelect(patientFromQuery.id);
      setPatient(patientFromQuery);
    }
  }, [onSelect]);

  const handleSearch = (patientSearchText: string): void => {
    if (patientSearchText && patientSearchText.length > 2) {
      setSearching(true);
      setSearchText(patientSearchText);
    } else {
      setSearchText('');
    }
  };

  const debounceHandleSearch = _.debounce(handleSearch, 300);

  const handleChange = (value: string | JSX.Element | undefined): void => {
    const selectedPatient = patients.find(patient => patient.id === value);
    if (selectedPatient) {
      updateURLWithPatient(selectedPatient);
      setPatient(selectedPatient);
    } else {
      setPatient(undefined);
      setSearchText('');
      updateURLWithPatient(undefined);
      onSelect(undefined);
    }
  };

  const { data, loading } = useSearchReferralPatientsInOrganizationQuery({
    variables: { organizationId, searchText },
    onCompleted: () => setSearching(false),
    skip: searchText === '',
  });

  const patients = data?.searchReferralPatientsInOrganization || [];

  return (
    <Select
      allowClear
      defaultActiveFirstOption={false}
      defaultValue={patient ? createPatientOption(patient) : searchText ? searchText : undefined}
      filterOption={false}
      loading={loading}
      notFoundContent={null}
      onChange={handleChange}
      onSearch={debounceHandleSearch}
      onSelect={onSelect}
      placeholder="Search for a patient."
      value={patient ? formatPatientOption(patient) : undefined}
      showSearch
    >
      {patients.length > 0 && !searching && searchText ? patients.map(createPatientOption) : null}
    </Select>
  );
};
