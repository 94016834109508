import React, { FC } from 'react';
import { PrivateRoute } from '../../';
import { FeatureFlag, FeatureGate } from '../../../../helpers';
import { ReferralsContainer, SchedulingContainer } from '../../../referrals/';

export const DefaultRoute: FC<{}> = (): JSX.Element => (
  <FeatureGate
    feature={FeatureFlag.HasSchedulePatient}
    fallbackContent={<PrivateRoute path="*" key="route--default" component={ReferralsContainer} />}
  >
    <PrivateRoute path="*" key="route--default" component={SchedulingContainer} />
  </FeatureGate>
);
