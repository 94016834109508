import React, { FC } from 'react';
import { Button, Card, Col, Divider, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FeatureFlag, BlockRestrictedUsers, FeatureGate } from '../../../helpers';
import { Profile } from '../index';
import { ProfileFormData } from './ProfileForm';
import { SelectMaxAgeAllowed } from './SelectMaxAgeAllowed';
import { SelectMinAgeAllowed } from './SelectMinAgeAllowed';
import { SelectSendToPatient } from './SelectSendToPatient';
import { SelectSendToProvider } from './SelectSendToProvider';
import { SelectJumpQ } from './SelectJumpQ';
import { InputSlotDelayBuffer } from './InputSlotDelayBuffer';
import { SelectReferralScheduling, SelectReferralSchedulingSlots } from './SelectReferralScheduling';

interface Props extends FormComponentProps {
  onSubmit: (values: ProfileFormData) => void;
  profile: Profile;
}

const ProfileSettingsAdvancedFormCardComponent: FC<Props> = ({ form, onSubmit, profile }): JSX.Element => {
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Row>
      <Col xs={18}>
        <Form onSubmit={handleFormSubmit}>
          <Card
            title="Advanced Settings"
            extra={
              <BlockRestrictedUsers>
                <Button htmlType="submit" type="primary">
                  Save Advanced Settings
                </Button>
              </BlockRestrictedUsers>
            }
          >
            <Row>
              <Col xs={12}>
                <SelectReferralScheduling form={form} initialValue={profile?.hasReferralScheduling} />
              </Col>
              <Col xs={12}>
                <SelectReferralSchedulingSlots form={form} initialValue={profile?.hasReferralSchedulingSlots} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FeatureGate feature={FeatureFlag.SendToPatient}>
                  <SelectSendToPatient form={form} initialValue={profile?.isSendToPatient} />
                </FeatureGate>
              </Col>
              <Col xs={12}>
                <FeatureGate feature={FeatureFlag.SendToProvider}>
                  <SelectSendToProvider form={form} initialValue={profile?.isSendToProvider} />
                </FeatureGate>
              </Col>
              <Col xs={12}>
                <FeatureGate feature={FeatureFlag.AppointmentAccelerator}>
                  <SelectJumpQ form={form} initialValue={profile?.sendOffers} />
                </FeatureGate>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col>
                <Row type="flex">
                  <Col xs={11}>
                    <SelectMinAgeAllowed form={form} initialValue={profile?.onlyAllowsAgeMin} />
                  </Col>
                  <Col xs={11} offset={2}>
                    <SelectMaxAgeAllowed form={form} initialValue={profile?.onlyAllowsAgeMax} />
                  </Col>
                </Row>
              </Col>
            </Row>
            {!profile.isWaitList ? (
              <Row>
                <Col>
                  <Row type="flex">
                    <Col xs={11}>
                      <InputSlotDelayBuffer form={form} initialValue={profile?.slotStartBufferInM} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export const ProfileSettingsAdvancedFormCard = Form.create<Props>({
  name: 'Domain Settings',
})(ProfileSettingsAdvancedFormCardComponent);
