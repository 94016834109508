import { Button, Card, Form, message, Input, Typography, Row, Col, Switch, Checkbox } from 'antd';
import React, { FC, FormEvent } from 'react';
import { getOrganization, useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { useUpdateOrganizationConsumerSchedulingSettingsMutation } from '../../../hooks/mutations/useUpdateOrganizationConsumerSchedulingSettingsMutation';
import { FormComponentProps } from 'antd/lib/form';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props extends FormComponentProps {
  organizationId: string;
}

interface ConsumerSettingsFormData {
  allowAttachments: boolean;
  searchInsurance: boolean;
  visitTypeFormLabel: string;
  showLanguages: boolean;
  searchProviders: boolean;
  showPatientPrefill: boolean;
  showMap: boolean;
  formFieldSettings: string[];
  noAvailabilityText: string;
  noAvailabilityAction: string;
}

const ConsumerWorkflowCardComponent: FC<Props> = ({ form, organizationId }): JSX.Element => {
  const [updateOrganizationConsumerSchedulingSettings] = useUpdateOrganizationConsumerSchedulingSettingsMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const organization = data?.getOrganization;

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields(async (err, values: ConsumerSettingsFormData) => {
      try {
        const formFields = values.formFieldSettings.map(v => {
          return { field: v, required: true };
        });

        await updateOrganizationConsumerSchedulingSettings({
          variables: {
            organizationId: organizationId,
            updateOrganizationConsumerSchedulingSettingsInput: {
              allowAttachments: values.allowAttachments,
              noAvailabilityText: values.noAvailabilityText ? values.noAvailabilityText : null,
              noAvailabilityAction: values.noAvailabilityAction ? values.noAvailabilityAction : null,
              searchInsurance: values.searchInsurance,
              visitTypeFormLabel: values.visitTypeFormLabel ? values.visitTypeFormLabel : null,
              showLanguages: values.showLanguages,
              searchProviders: values.searchProviders,
              showPatientPrefill: values.showPatientPrefill,
              showMap: values.showMap,
              formFieldSettings: formFields,
            },
          },
          refetchQueries: [{ query: getOrganization, variables: { organizationId: organizationId } }],
        });
        message.success('Your changes have been saved.');
      } catch (err) {
        message.error('An error occurred while saving your changes');
      }
    });
  };

  const formFieldSettings = Object.entries(
    data?.getOrganization.consumerSchedulingSettings?.formFieldSettings || {}
  ).map(([i, k], v) => {
    if (k.required) {
      return k.field;
    } else {
      return null;
    }
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Card
        title="Workflow Settings"
        extra={
          <BlockRestrictedUsers>
            <Button htmlType="submit" type="primary">
              Save Workflow Settings
            </Button>
          </BlockRestrictedUsers>
        }
      >
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Allow Attachments">
              {form.getFieldDecorator('allowAttachments')(
                <Switch defaultChecked={organization?.consumerSchedulingSettings?.allowAttachments} />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Allow a patient to upload attachments and documents to a consumer scheduling appointment.
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Search Insurance">
              {form.getFieldDecorator('searchInsurance')(
                <Switch
                  defaultChecked={
                    organization?.consumerSchedulingSettings === null ||
                    organization?.consumerSchedulingSettings?.searchInsurance
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Show the insurance carrier and insurance plan fields (allowing a patient to search them) on consumer
              scheduling search forms.
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Visit Type Form Label">
              {form.getFieldDecorator('visitTypeFormLabel', {
                initialValue: organization?.consumerSchedulingSettings?.visitTypeFormLabel,
              })(<Input placeholder="What is the desired visit type?" />)}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Customize the visit type form label for consumer scheduling search forms. Defaults to &quot;What is the
              desired visit type?&quot;.
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="No Availability Message">
              {form.getFieldDecorator('noAvailabilityText', {
                initialValue: organization?.consumerSchedulingSettings?.noAvailabilityText,
              })(<Input placeholder="Click to see future appointment dates" />)}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Customize the text that shows when there is no availability for a profile. Defaults to &quot;Click to see
              future appointment dates&quot;.
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="No Availability Link">
              {form.getFieldDecorator('noAvailabilityAction', {
                initialValue: organization?.consumerSchedulingSettings?.noAvailabilityAction,
              })(<Input placeholder="http://www.website.com/waitlist" />)}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Overide the action when clicking the &quot;No Availability&quot; button. By default, we will search the
              next 7 days, but if you enter a URL here the patient will be redirected to that page.
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Show Search Providers">
              {form.getFieldDecorator('searchProviders')(
                <Switch
                  defaultChecked={
                    organization?.consumerSchedulingSettings === null ||
                    organization?.consumerSchedulingSettings?.searchProviders
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">Show the search providers search box</Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Enable map">
              {form.getFieldDecorator('showMap')(
                <Switch
                  defaultChecked={
                    organization?.consumerSchedulingSettings === null ||
                    organization?.consumerSchedulingSettings?.showMap
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">Enables/disables the map from displaying</Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Show Languages Filter">
              {form.getFieldDecorator('showLanguages')(
                <Switch
                  defaultChecked={
                    organization?.consumerSchedulingSettings === null ||
                    organization?.consumerSchedulingSettings?.showLanguages
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">Show the languages filter</Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Form.Item label="Required Patient Demographics">
            <Col span={24}>
              {form.getFieldDecorator('formFieldSettings', {
                initialValue: formFieldSettings,
              })(
                <Checkbox.Group style={{ width: '100%' }}>
                  <Row>
                    <Col span={8}>
                      <Checkbox value="givenName2">Patient&apos;s Middle Name</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="address1">Patient&apos;s Address</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="address2">Address Line 2</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="city">City</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="state">State</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="postalCode">Postal Code</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="phone">Phone</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="email">Email</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="payorPlanId">Who is your insurance carrier/plan?</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="insuranceGroupNumber">Insurance Group Number</Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="insurancePolicyNumber">Insurance Policy Number</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              )}
            </Col>
          </Form.Item>
        </Row>
      </Card>
    </Form>
  );
};

export const ConsumerWorkflowCard = Form.create<Props>({ name: 'Workflow Settings' })(ConsumerWorkflowCardComponent);
