import { Card } from 'antd';
import { History } from 'history';
import React, { FC } from 'react';
import styled from 'styled-components';
import { PublicProfile } from '../containers/DirectoryContext';
import { ProfileCardBody } from './ProfileCardBody';
import { ProfileCardImage } from './ProfileCardImage';

interface Props {
  history: History;
  profile: PublicProfile;
}

const Styles = styled.div`
  .profile-card {
    display: flex;
    justify-content: flex-start;
  }
`;

export const ProfileCard: FC<Props> = ({ history, profile }): JSX.Element => (
  <Styles>
    <Card hoverable={true} onClick={() => history.push(`/directory/profiles/${profile.id}`)}>
      <div className="profile-card">
        <ProfileCardImage id={profile.id} name={profile.displayName} profileImgUrl={profile.profileImgUrl} />
        <ProfileCardBody profile={profile} />
      </div>
    </Card>
  </Styles>
);
