import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../../../../themes/default';
import AntDesignStyleOverrides from './AntDesignStyleOverrides';

const AppHeaderStyles = styled.div`
  box-shadow: 0px 1px 2px rgba(125, 167, 212, 1);
  font-family: 'Source Sans Pro', sans-serif;

  .app-header {
    align-items: flex-end;
    background-color: ${({ theme }) => theme.primaryColor};
    box-sizing: border-box;
    display: flex;
    height: 73px;
    padding: 0 20px;

    .logo {
      padding: 20px 0;
      text-align: left;

      img {
        width: 149px;
      }
    }
  }
`;

export const PublicLayout: FC<{}> = ({ children }): JSX.Element => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <AntDesignStyleOverrides>
        <AppHeaderStyles>
          <div className="app-header">
            <div className="logo">
              <img src="/images/logo.png" alt="Blockit" />
            </div>
          </div>
        </AppHeaderStyles>
        <div className={`flex justify-center font-source-sans-pro`} style={{ flex: 1, minHeight: '86vh' }}>
          <div className="py-4 flex-1 max-w-3xl min-h-100-vh flex flex-col">
            <div>{children}</div>
            <div className="px-4 pt-4 w-full text-grey-dark text-xs"></div>
          </div>
        </div>
      </AntDesignStyleOverrides>
    </ThemeProvider>
  );
};
