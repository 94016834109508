import React, { FC } from 'react';
import { SignupLayout } from './SignupLayout';
import { SimpleConsumerOnboarding } from '../components/SimpleConsumerOnboarding';
import { useParams } from 'react-router-dom';
import { SignupError } from '../components/signup/SignupError';

interface Props {
  pelitas?: boolean;
}

interface Params {
  organizationId: string;
}

export const OnboardingPage: FC<Props> = ({ pelitas }): JSX.Element => {
  const { organizationId } = useParams<Params>();

  return (
    <SignupLayout pelitas={pelitas}>
      {organizationId && <SimpleConsumerOnboarding organizationId={organizationId} />}
      {!organizationId && <SignupError />}
    </SignupLayout>
  );
};
