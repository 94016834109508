import { Avatar } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
  profileImgUrl: string;
}

const Styles = styled.div`
  .profile-image {
    border-radius: 0;
    height: 200px;
    max-width: 200px;
    width: auto;
  }
`;

export const DirectoryProfileImage: FC<Props> = ({ name, profileImgUrl }): JSX.Element => (
  <Styles>
    {profileImgUrl ? (
      <img src={profileImgUrl} alt={name} className="profile-image" />
    ) : (
      <Avatar size={128} shape="square" icon="user" />
    )}
  </Styles>
);
