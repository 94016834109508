import { gql } from '@apollo/client';
import { useMutation, MutationHookOptions, MutationTuple } from '@apollo/client';

const createScheduleForOrganizationMutation = gql`
  mutation CreateScheduleForOrganizationMutation($organizationId: ID!, $scheduleInput: ScheduleInput!) {
    createScheduleForOrganization(organizationId: $organizationId, scheduleInput: $scheduleInput) {
      id
    }
  }
`;

export interface ScheduleInput {
  displayName: string;
  prefix?: string;
  groupKey?: string;
  firstName?: string;
  lastName?: string;
  suffix?: string;
  locationId: string;
  email?: string;
  slug?: string;
  npiNumber?: string;
  phone?: string;
  profileImgUrl?: string;
  specialty?: string;
  tags?: string;
  type: 'device' | 'location' | 'practitioner';
  boardCertifications?: string;
  educationTraining?: string;
  generalPatientInstructions?: string;
  referralSearchNotice?: string;
  languageCodes?: string[];
  professionalStatement?: string;
  hasConsumerScheduling?: boolean;
  hasAppointmentRequests?: boolean;
  isActive?: boolean;
  isGlobal?: boolean;
  isIntegrated?: boolean;
  isSendToPatient?: boolean;
  isSendToProvider?: boolean;
  onlyAllowsAgeMin?: number | null;
  onlyAllowsAgeMax?: number | null;
  slotStartBufferInM?: string;
}

interface Profile {
  id: string;
}

export interface CreateScheduleForOrganizationMutationData {
  createScheduleForOrganization: Profile;
}

interface Variables {
  organizationId: string;
  scheduleInput: ScheduleInput;
}

type Options = MutationHookOptions<CreateScheduleForOrganizationMutationData, Variables>;

type Result = MutationTuple<CreateScheduleForOrganizationMutationData, Variables>;

export const useCreateScheduleForOrganizationMutation = (options: Options): Result => {
  return useMutation<CreateScheduleForOrganizationMutationData, Variables>(
    createScheduleForOrganizationMutation,
    options
  );
};
