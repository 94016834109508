interface ProfilePayorPlan {
  profileId: string;
  payorPlanId: string;
  payorPlan: PayorPlan;
}

interface PayorPlan {
  id: string;
  name: string;
  payor: Payor;
}

interface Payor {
  id: string;
  name: string;
}

export function groupPlansByPayorName(plans: ProfilePayorPlan[]): Array<Array<string | Array<ProfilePayorPlan>>> {
  const grouped: { [key: string]: ProfilePayorPlan[] } = {};

  plans.forEach(plan => {
    const key = plan.payorPlan.payor.name;

    if (!grouped[key]) {
      grouped[key] = [plan];
    } else {
      grouped[key].push(plan);
    }
  });

  return Object.keys(grouped)
    .sort()
    .map(v => [v, grouped[v]]);
}
