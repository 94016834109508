import React, { FC } from 'react';
import { Badge } from 'antd';

export const QueueTitle: FC<{ name: string | JSX.Element; color: string | null | undefined }> = ({
  name,
  color,
}): JSX.Element => {
  if (color) {
    return (
      <>
        <Badge color={'#' + color} />
        {name}
      </>
    );
  } else {
    return <>{name}</>;
  }
};
