interface UtmParameters {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_cmpid?: string;
  utm_term?: string;
  utm_content?: string;
}

export const generateUtmParametersQueryString = (params: UtmParameters, originalQueryString = ''): string => {
  let queryString = '';

  if (params.utm_source) {
    queryString +=
      queryString === '' && originalQueryString === ''
        ? `?utm_source=${params.utm_source}`
        : `&utm_source=${params.utm_source}`;
  }

  if (params.utm_medium) {
    queryString +=
      queryString === '' && originalQueryString === ''
        ? `?utm_medium=${params.utm_medium}`
        : `&utm_medium=${params.utm_medium}`;
  }

  if (params.utm_campaign) {
    queryString +=
      queryString === '' && originalQueryString === ''
        ? `?utm_campaign=${params.utm_campaign}`
        : `&utm_campaign=${params.utm_campaign}`;
  }

  if (params.utm_cmpid) {
    queryString +=
      queryString === '' && originalQueryString === ''
        ? `?utm_cmpid=${params.utm_cmpid}`
        : `&utm_cmpid=${params.utm_cmpid}`;
  }

  if (params.utm_term) {
    queryString +=
      queryString === '' && originalQueryString === ''
        ? `?utm_term=${params.utm_term}`
        : `&utm_term=${params.utm_term}`;
  }

  if (params.utm_content) {
    queryString +=
      queryString === '' && originalQueryString === ''
        ? `?utm_content=${params.utm_content}`
        : `&utm_content=${params.utm_content}`;
  }

  return queryString;
};
