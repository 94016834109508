import React, { FC, useContext, useState } from 'react';
import { Alert, Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FeatureFlag, BlockRestrictedUsers, FeatureGate } from '../../../helpers';
import { Profile } from '../index';
import { ProfileFormData } from './ProfileForm';
import { AppContext } from '../../core/contexts/AppContext';
import {
  CheckboxConsumerScheduling,
  CheckboxConsumerSchedulingSlots,
  CheckboxConsumerAppointmentRequests,
} from './SelectConsumerScheduling';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';
import { Select, Option } from '../../../system/components/Select';
import { Link } from 'react-router-dom';

interface Props extends FormComponentProps {
  onSubmit: (values: ProfileFormData) => void;
  profile: Profile;
}

const ProfileConsumerSettingsCard: FC<Props> = ({ onSubmit, profile, form }): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);
  const [selectProcedure, setSelectProcedure] = useState<string | null>(null);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  const flattenedProcedures: { id: string; name: string; specialtyId: string }[] = profile.profileProcedures.map(
    pp => ({
      id: pp.procedure.id,
      name: pp.procedure.name,
      specialtyId: pp.procedure.specialty.id,
    })
  );

  const slug = currentOrganization?.slug ? currentOrganization?.slug : currentOrganization?.id;
  const procedure = selectProcedure ? '?' + selectProcedure : '';
  const link = 'https://app.blockitnow.com/consumer/' + slug + '/provider/' + profile.id + procedure;
  const npiLink = 'https://app.blockitnow.com/consumer/' + slug + '/npi/' + profile.npiNumber + procedure;

  return (
    <Row>
      <Col xs={18}>
        <Form onSubmit={handleFormSubmit}>
          <Card
            title="Consumer Settings"
            extra={
              <BlockRestrictedUsers>
                <Button htmlType="submit" type="primary">
                  Save Consumer Settings
                </Button>
              </BlockRestrictedUsers>
            }
          >
            <Row>
              <Col xs={24}>
                <FeatureGate
                  feature={FeatureFlag.ConsumerScheduling}
                  fallbackContent={
                    <Alert
                      message="Feature not enabled"
                      showIcon
                      description="Consumer scheduling is not enabled for your Blockit account. This feature allows patients to directly schedule online. Please contact Blockit to enbale this feature."
                    ></Alert>
                  }
                >
                  <>
                    <Row>
                      <Col xs={12}>
                        <CheckboxConsumerScheduling form={form} initialValue={profile?.hasConsumerScheduling} />
                      </Col>
                      <Col span={10} push={1}>
                        <Typography.Text type="secondary">
                          This toggles the visibility of this profile in the self-schedule search results.
                        </Typography.Text>
                        <br />
                        <br />
                        <Typography.Text type="secondary">
                          Enabling consumer scheduling allows patients to self-schedule themselves online with this{' '}
                          {profile.type}.
                        </Typography.Text>
                        <br />
                        <br />
                        <Typography.Text type="secondary">
                          To deploy scheduling, you may share the link below for this specific {profile.type} or{' '}
                          <Link to={`/organizations/${currentOrganization?.id}/organization-settings?tab=consumer`}>
                            click here
                          </Link>{' '}
                          to view the link for all profiles.
                        </Typography.Text>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12}>
                        <CheckboxConsumerSchedulingSlots
                          form={form}
                          initialValue={profile?.hasConsumerSchedulingSlots}
                        />
                      </Col>
                      <Col span={10} push={1}>
                        <Typography.Text type="secondary">
                          This toggles whether this profile&#39s time slots are included in the self-schedule search
                          results.
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <CheckboxConsumerAppointmentRequests
                          form={form}
                          initialValue={profile?.hasAppointmentRequests}
                        />
                      </Col>
                      <Col span={10} push={1}>
                        <Typography.Text type="secondary">
                          This toggles whether this profile&#39s allows appointment requests without scheduling.
                        </Typography.Text>
                      </Col>
                    </Row>
                    <h3>
                      Direct Scheduling to this Profile{' '}
                      <a target="_blank" rel="noopener noreferrer" href={link}>
                        (Click to View)
                      </a>
                    </h3>
                    <br />
                    <Col span={12}>
                      <Form.Item label="Link with a preselected visit type">
                        <Select<string>
                          disabled={!flattenedProcedures}
                          filterOption={caseInsensitiveSelectFilterOption}
                          placeholder="Select a visit type"
                          dropdownMatchSelectWidth={false}
                          showSearch
                          onSelect={p => {
                            setSelectProcedure(p === 'none' ? null : p);
                          }}
                        >
                          <Option key={'none'}>No Visit Type Preselected</Option>
                          {flattenedProcedures?.map(procedure => (
                            <Option
                              value={'specialtyId=' + procedure.specialtyId + '&procedureId=' + procedure.id}
                              key={procedure.id}
                            >
                              {procedure.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Input.TextArea style={{ fontFamily: 'monospace', fontSize: 12 }} value={link}></Input.TextArea>
                    {profile.npiNumber && (
                      <>
                        <p>Or link to all profiles with this NPI Number:</p>
                        <br />
                        <Input.TextArea
                          style={{ fontFamily: 'monospace', fontSize: 12 }}
                          value={npiLink}
                        ></Input.TextArea>
                      </>
                    )}
                  </>
                </FeatureGate>
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export const ProfileConsumerSettingsFormCard = Form.create<Props>({ name: 'Profile Consumer Settings' })(
  ProfileConsumerSettingsCard
);
