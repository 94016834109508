import { Button, Col, Divider, Form, Icon, Input, List, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { gql } from '@apollo/client';
import moment from 'moment';
import React, { FC } from 'react';
import { Mutation } from '@apollo/client/react/components';
import config from '../../../../config';
import { useAppContext } from '../../../../hooks';
import ReferralUploadDrawer from '../SearchWizard/components/ReferralUploadDrawer';
import { ReferralData, ReferralOrderData } from './GetReferralQuery';

export const AppendReferralResult = gql`
  mutation($referralId: ID!, $result: String!) {
    appendReferralResult(referralId: $referralId, result: $result) {
      id
      result
    }
  }
`;

interface ResultsCardProps extends FormComponentProps {
  referral: ReferralData;
}

const ResultFormCard: FC<ResultsCardProps> = ({ form, referral }): JSX.Element => {
  const { viewer } = useAppContext();

  return (
    <Mutation<{}, { referralId: string; result: string }> mutation={AppendReferralResult}>
      {append => (
        <Form
          onSubmit={e => {
            e.preventDefault();
            form.validateFields((err, values) => {
              const result = `\n[${moment().format('MM/DD/YY hh:mmA')}]  ${viewer?.name}\n${values.result}`;
              if (!err) {
                append({
                  variables: {
                    referralId: referral.id,
                    result: result,
                  },
                });
              }
            });
          }}
        >
          <Row>
            <Col span={14}>
              <Form.Item>
                {form.getFieldDecorator('result', {
                  rules: [{ required: true, message: 'required' }],
                })(<Input.TextArea rows={6} placeholder="Enter any result details here..." />)}
              </Form.Item>

              <Button htmlType="submit" icon="check" type="primary" size="large">
                Save Results
              </Button>
            </Col>
            <Col span={8} push={1}>
              <ReferralUploadDrawer
                referralId={referral.id}
                isRequired={false}
                form={form}
                attachmentType="result"
                hint="Add results here to attach them to the referral."
              />
            </Col>
          </Row>
        </Form>
      )}
    </Mutation>
  );
};

export const ResultsCard: FC<{ referral: ReferralData }> = ({ referral }): JSX.Element => {
  const WrappedResultFormCard = Form.create<ResultsCardProps>({ name: 'results_form' })(ResultFormCard);

  return (
    <Row>
      <Col span={24}>
        <Divider orientation="left">Results</Divider>
        {referral.result ? (
          <Row>
            <Col span={14}>
              <h4>Details</h4>
              {referral.result.split('\n').map((item, key) => (
                <p key={key}>{item}</p>
              ))}
            </Col>
            <Col span={8} offset={1}>
              <h4>Result Attachments</h4>
              {referral.referralOrder.length ? (
                <List
                  size="small"
                  dataSource={referral.referralOrder}
                  locale={{ emptyText: ' ' }}
                  renderItem={(a: ReferralOrderData) => {
                    if (a.type !== 'result') {
                      return <></>;
                    }

                    return (
                      <List.Item>
                        <Icon type="file-pdf" theme="twoTone" />
                        <span className="text-xs" style={{ width: '100%' }}>
                          &nbsp;&nbsp;
                          <a href={`${config.endpoint}/download/referral/${a.id}/order`}>{a.displayFilename}</a>
                        </span>
                      </List.Item>
                    );
                  }}
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
        ) : null}
        <br />
        <WrappedResultFormCard referral={referral} />
      </Col>
    </Row>
  );
};
