import React, { FC, useContext } from 'react';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { Option, Select } from '../../../system/components/Select';
import { useGetProfileLanguagesInOrganizationQuery } from '../hooks/useGetProfileLanguagesInOrganizationQuery';

interface Props {
  defaultValue: string;
  onSelect: (value: string) => void;
}

export const SelectProfilesLanguage: FC<Props> = ({ defaultValue, onSelect }): JSX.Element => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const { data, error, loading } = useGetProfileLanguagesInOrganizationQuery({
    variables: { organizationId: organization.id },
  });

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  const { getProfileLanguagesInOrganization: languages } = data;

  return (
    <Select
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={false}
      onSelect={onSelect}
      optionFilterProp="children"
      showSearch
    >
      <Option value="">Any Language</Option>
      {languages.map(language => (
        <Option value={language.code} key={language.code}>
          {language.name}
        </Option>
      ))}
    </Select>
  );
};
