import { Button, Form, message, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { gql } from '@apollo/client';
import _ from 'lodash';
import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Question } from '../containers/QuizContainer';

const CreateQuizResultMutation = gql`
  mutation CreateQuizResult($totalCorrect: Int!, $totalIncorrect: Int!, $email: String!) {
    CreateQuizResult(totalCorrect: $totalCorrect, totalIncorrect: $totalIncorrect, email: $email)
  }
`;

const Styles = styled.div`
  span.correct {
    color: green;
  }

  span.incorrect {
    color: red;
  }
`;

interface MutationVariables {
  totalCorrect: number;
  totalIncorrect: number;
  email: string;
}

interface Props extends FormComponentProps {
  questions: Question[];
  email: string;
}

const getColorClassName = (current: number, selected: number | undefined, answer: number): string => {
  if (selected === undefined) {
    return '';
  } else if (current !== selected && current === answer) {
    return 'correct';
  } else if (current === selected && current !== answer) {
    return 'incorrect';
  } else if (current === selected && current === answer) {
    return 'correct';
  } else {
    return '';
  }
};

const QuizFormComponent: FC<Props & FormComponentProps> = ({ form, questions, email }): JSX.Element => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(questions);
  const [createQuizResultMutation] = useMutation<string, MutationVariables>(CreateQuizResultMutation);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    const totalCorrect = formValues.filter(i => i.selectedAnswer !== undefined && i.correctAnswer === i.selectedAnswer)
      .length;
    const totalIncorrect = formValues.filter(
      i => i.selectedAnswer !== undefined && i.correctAnswer !== i.selectedAnswer
    ).length;

    if (questions.length !== totalCorrect + totalIncorrect) {
      message.error('Please answer all questions before submitting', 3);
      setLoading(false);
      return;
    }

    try {
      await createQuizResultMutation({
        variables: {
          totalCorrect,
          totalIncorrect,
          email,
        },
      });
      form.resetFields();
      const clearedFormAnswers = formValues.map(fv => ({ ...fv, selectedAnswer: undefined }));
      setFormValues(clearedFormAnswers);

      window.scrollTo(0, 0);
      message.success('Thank you! Your answers have been submitted.', 5);
    } catch (err) {
      message.error('There was a problem submitting your answers.', 3);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Styles>
      <Form onSubmit={handleSubmit}>
        {formValues.map((item, itemIndex) => (
          <Form.Item
            key={item.question}
            label={`${itemIndex + 1}. ${item.question}`}
            labelCol={{ span: 24, offset: 0 }}
            wrapperCol={{ span: 20, offset: 1 }}
          >
            {getFieldDecorator(`${itemIndex}`, {
              rules: [
                {
                  required: true,
                  message: 'Required',
                },
              ],
            })(
              <Radio.Group disabled={item.selectedAnswer !== undefined ? true : false}>
                {item.answers.map((answerItem, answerIndex) => (
                  <Radio
                    onChange={e => {
                      const clonedFormedValues = _.cloneDeep(formValues);
                      const indexOfItem = _.indexOf(
                        clonedFormedValues.map(i => i.question),
                        item.question
                      );
                      clonedFormedValues[indexOfItem] = { ...item, selectedAnswer: e.target.value };
                      setFormValues(clonedFormedValues);
                    }}
                    key={answerItem}
                    value={answerIndex}
                  >
                    <span className={getColorClassName(answerIndex, item.selectedAnswer, item.correctAnswer)}>
                      {answerItem}
                    </span>
                  </Radio>
                ))}
              </Radio.Group>
            )}
          </Form.Item>
        ))}
        <Form.Item wrapperCol={{ span: 6, offset: 18 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Styles>
  );
};

export const QuizForm = Form.create<Props>({ name: 'QuizForm' })(QuizFormComponent);
