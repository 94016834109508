import { message } from 'antd';
import React, { FC, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { ErrorBoundaryMain } from '../../../components';
import { history } from './App/history';
import Layout from './App/Layout';
import { AppContext } from '../contexts/AppContext';

export const Roles = {
  BLOCKIT_ADMIN: 'BLOCKIT_ADMIN',
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  SCHEDULER: 'SCHEDULER',
};

interface RoleInterface {
  role?: string;
}

export const isBlockitAdmin = (currentOrganization: RoleInterface | null): boolean => {
  return !!(currentOrganization && currentOrganization.role === Roles.BLOCKIT_ADMIN);
};

export const isAdmin = (currentOrganization: RoleInterface | null): boolean => {
  return !!(currentOrganization && currentOrganization.role === Roles.ADMIN);
};

export const isEditor = (currentOrganization: RoleInterface | null): boolean => {
  return !!(currentOrganization && currentOrganization.role === Roles.EDITOR);
};

export const isScheduler = (currentOrganization: RoleInterface | null): boolean => {
  return !!(currentOrganization && currentOrganization.role === Roles.SCHEDULER);
};

export const isAdminOrBlockitAdmin = (currentOrganization: RoleInterface | null): boolean =>
  isBlockitAdmin(currentOrganization) || isAdmin(currentOrganization);

const hasMinRole = (org: RoleInterface, role: string): boolean => {
  if (isBlockitAdmin(org)) {
    return true;
  }

  switch (role) {
    case Roles.ADMIN:
      return isAdmin(org);
    case Roles.EDITOR:
      return isAdmin(org) || isEditor(org);
    case Roles.SCHEDULER:
      return isAdmin(org) || isEditor(org) || isScheduler(org);
    default:
      return false;
  }
};

interface Props {
  component: React.ElementType;
  minRole?: string;
  exact?: boolean;
  path: string;
}

const PrivateRoute: FC<Props> = ({ component: InnerComponent, ...rest }) => {
  const { currentOrganization, isAuthenticated } = useContext(AppContext);
  const { minRole } = rest;
  const lastLocation = useLastLocation();

  // return true if the api call for the org hasn't resolved just yet, because when it does it will do the checks
  const isAuthorized = minRole && currentOrganization ? hasMinRole(currentOrganization, minRole) : true;

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated && isAuthorized) {
          return (
            <Layout {...rest}>
              <ErrorBoundaryMain>
                <InnerComponent {...props} key={currentOrganization?.id} />
              </ErrorBoundaryMain>
            </Layout>
          );
        } else if (isAuthenticated && !isAuthorized) {
          message.error('Unauthorized to view that page', 5);

          return (
            <Redirect
              to={{
                pathname: lastLocation && lastLocation.pathname ? lastLocation.pathname : '/',
                state: { from: lastLocation && lastLocation.pathname ? lastLocation.pathname : '/' },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: history.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
