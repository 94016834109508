import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
}

export const InputUserName: FC<Props> = ({ form }): JSX.Element => (
  <Form.Item label="Full Name:">
    {form.getFieldDecorator('name', {
      rules: [
        { message: 'Please enter your full name.', required: true },
        { message: 'Full name must be at least 3 characters.', min: 3 },
      ],
    })(<Input allowClear />)}
  </Form.Item>
);
