import React, { FC, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Col, Form, Row } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { SelectPatientBirthDay } from './SelectPatientBirthDay';
import { SelectPatientBirthMonth } from './SelectPatientBirthMonth';
import { SelectPatientBirthYear } from './SelectPatientBirthYear';

interface Props {
  form: WrappedFormUtils;
  initialValue?: { birthDay?: number | null; birthMonth?: number | null; birthYear?: number | null };
}

interface Date {
  day?: number;
  month?: number;
  year?: number;
}
export const SelectPatientBirthDate: FC<Props> = ({ form, initialValue }): JSX.Element => {
  // If they exist, set initial values on mount
  useEffect(() => {
    if (initialValue !== undefined) {
      form.setFieldsValue({
        birthDate: {
          year: initialValue?.birthYear,
          month: initialValue?.birthMonth,
          day: initialValue?.birthDay,
        },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dateValidator = (rule: string, value: Date | undefined, cb: (arg0?: boolean) => void): void => {
    const year = form.getFieldValue('birthYear');
    const month = form.getFieldValue('birthMonth');
    const day = form.getFieldValue('birthDay');
    const date = DateTime.utc(year, month, day);

    if (year && month && day && date && date.isValid && date.diffNow().milliseconds <= 0) return cb();

    return cb(true);
  };

  const onSelect = (value: number, field: string): void => {
    // onSelect fires before form.getFieldValue updates, so must use param value
    // when called for selected field
    const year = field === 'year' ? value : form.getFieldValue('birthYear');
    const month = field === 'month' ? value : form.getFieldValue('birthMonth');
    const day = field === 'day' ? value : form.getFieldValue('birthDay');

    if (month && day && year) {
      form.setFieldsValue({ birthDate: { day, month, year } });
    }
  };

  return (
    <Form.Item label="Patient's Birth Date">
      {form.getFieldDecorator('birthDate', {
        rules: [
          {
            required: true,
            validator: dateValidator,
            message: 'Please select a valid date.',
          },
        ],
      })(
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <SelectPatientBirthMonth onSelect={onSelect} form={form} initialValue={initialValue?.birthMonth} />
          </Col>
          <Col span={8}>
            <SelectPatientBirthDay onSelect={onSelect} form={form} initialValue={initialValue?.birthDay} />
          </Col>
          <Col span={8}>
            <SelectPatientBirthYear onSelect={onSelect} form={form} initialValue={initialValue?.birthYear} />
          </Col>
        </Row>
      )}
    </Form.Item>
  );
};
