import { Select } from 'antd';
import queryString from 'query-string';
import React, { FC, useContext } from 'react';
import { TypeStatusEnum } from '../../referrals/pages/ReferralsContainer';
import { history } from './App/history';
import { AppContext } from '../contexts/AppContext';

const { Option } = Select;

export const getDefaultSendsOrReceives = (
  currentLocation: LocationInterface | null,
  currentStatus?: TypeStatusEnum
): TypeStatusEnum => {
  // check optional param
  if (currentStatus) {
    if (currentLocation?.sends && currentLocation?.receives) return currentStatus;
  }
  return (!currentLocation?.sends && !currentLocation?.receives) || currentLocation.sends
    ? TypeStatusEnum.SENT
    : TypeStatusEnum.RECEIVED;
};

interface LocationInterface {
  id: string;
  name: string;
  sends: boolean;
  receives: boolean;
}

interface SentRecDropDownProps {
  type: TypeStatusEnum;
}

export const SentRecDropDown: FC<SentRecDropDownProps> = ({ type }): JSX.Element | null => {
  const { currentLocation } = useContext(AppContext);

  return (
    <Select<TypeStatusEnum>
      onChange={val => {
        const queryParams = queryString.parse(history.location.search);
        history.push(
          history.location.pathname +
            '?' +
            queryString.stringify({
              ...queryParams,
              type: val,
            })
        );
      }}
      value={type}
    >
      <Option value="SENT" key="SENT" disabled={!currentLocation?.sends}>
        Sent Referrals
      </Option>
      <Option value="RECEIVED" key="RECEIVED" disabled={!currentLocation?.receives}>
        Received Referrals
      </Option>
    </Select>
  );
};
