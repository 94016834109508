import React, { FC, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ApolloError } from '@apollo/client';
import { Col, message, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import store from '../../../localstore';
import { AppContext } from '../../core/contexts/AppContext';
import { CreateOrganizationMutationData, useCreateOrganizationMutation } from '../hooks/useCreateOrganizationMutation';
import { defaultTheme } from '../../../themes/default';
import { FreeForm, FreeFormData } from './FreeForm';
import { useViewerQuery } from '../../../hooks/queries/useViewerQuery';

interface Props {
  params: Params;
}

interface Params {
  regionId?: string;
  product?: string;
}

const { Title } = Typography;

const Styles = styled.div`
  min-height: 100vh;
  height: 100%;

  .create-user-title-2 {
    color: ${props => props.theme.blueDarkest};
    font-weight: 400;
    text-align: center;
  }

  .create-user-title-4 {
    color: ${props => props.theme.blueDarkest};
    font-weight: 200;
    text-align: center;
  }
`;

export const SubscribeFreeStep: FC<Props> = ({ params }): JSX.Element => {
  const history = useHistory();
  const { refetch } = useViewerQuery({});
  const { init, setMode } = useContext(AppContext);

  const handleCreateOrganizationCompleted = async ({
    createOrganization,
  }: CreateOrganizationMutationData): Promise<void> => {
    const { id } = createOrganization;

    store().setItem('currentOrganizationId', id);
    await refetch().then(({ data }) => {
      if (data?.viewer) {
        init(data.viewer);
        setMode('admin');
        history.push(`/account-signup/finish`);
      }
    });
  };

  const handleCreateOrganizationError = (_error: ApolloError): void => {
    // todo - create organization error handling.
    message.error('Something went wrong!  Please try again or contact us if the problem persists.');
  };

  const [createOrganization, { loading }] = useCreateOrganizationMutation({
    onCompleted: handleCreateOrganizationCompleted,
    onError: handleCreateOrganizationError,
  });

  const handleSubmit = (values: FreeFormData): void => {
    createOrganization({
      variables: {
        createOrganizationInput: {
          isFree: true,
          name: values.organizationName,
          regionId: params.regionId,
        },
      },
    }).then();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Title level={2} className="create-user-title-2">
          Almost done!
        </Title>
        <Title level={4} className="create-user-title-4">
          Now, let&apos;s create your organization.
        </Title>
        <br />
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <FreeForm loading={loading} onSubmit={handleSubmit} />
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
