import { FC, useContext } from 'react';
import { AppContext } from '../modules/core/contexts/AppContext';
import { isAdmin, isBlockitAdmin } from '../modules/core/components/PrivateRoute';

interface Props {
  children: JSX.Element;
}

export const IsOrgAdminOrBlockitAdmin: FC<Props> = ({ children }): JSX.Element | null => {
  const { currentOrganization } = useContext(AppContext);

  return isAdmin(currentOrganization) || isBlockitAdmin(currentOrganization) ? children : null;
};
