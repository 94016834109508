import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import { Specialty } from './useGetProcedureQuery';

const getSpecialtiesWithProfilesInOrganizationQuery = gql`
  query GetSpecialtiesWithProfilesInOrganization($id: ID!) {
    getSpecialtiesWithProfilesInOrganization(id: $id) {
      id
      name
      procedures {
        id
        name
      }
    }
  }
`;

interface Data {
  getSpecialtiesWithProfilesInOrganization: Specialty[];
}

interface Variables {
  id: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetSpecialtiesWithProfilesInOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getSpecialtiesWithProfilesInOrganizationQuery, options);
};
