import { useMutation, MutationTuple } from '@apollo/client';
import { gql } from '@apollo/client';

interface UpsertSlotDurationData {
  UpsertSlotDuration: {
    scheduleId: string;
    durationInMins: string;
  };
}

interface UpsertSlotDurationVariables {
  UpsertSlotDurationInput: {
    scheduleId: string;
    durationInMins: number;
  };
}

const UpsertSlotDurationMutation = gql`
  mutation UpsertSlotDuration($UpsertSlotDurationInput: UpsertSlotDurationInput!) {
    UpsertSlotDuration(UpsertSlotDurationInput: $UpsertSlotDurationInput) {
      scheduleId
      durationInMins
    }
  }
`;

type UpsertSlotDurationMutationResult = MutationTuple<UpsertSlotDurationData, UpsertSlotDurationVariables>;

export const useUpsertSlotDurationMutation = (): UpsertSlotDurationMutationResult => {
  return useMutation<UpsertSlotDurationData, UpsertSlotDurationVariables>(UpsertSlotDurationMutation);
};
