import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const searchConsumerSchedulingPatientsInOrganizationQuery = gql`
  query SearchConsumerSchedulingPatientsInOrganizationQuery($organizationId: ID!, $searchText: String!) {
    searchConsumerSchedulingPatientsInOrganization(organizationId: $organizationId, searchText: $searchText) {
      id
      identifier
      givenName1
      familyName
      birthDate
    }
  }
`;

export interface Patient {
  id: string;
  identifier?: string;
  givenName1?: string;
  givenName2?: string;
  givenName3?: string;
  familyName?: string;
  birthDate: string;
}

interface Data {
  searchConsumerSchedulingPatientsInOrganization: Patient[];
}

interface Variables {
  organizationId: string;
  searchText: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useSearchConsumerSchedulingPatientsInOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(searchConsumerSchedulingPatientsInOrganizationQuery, options);
};
