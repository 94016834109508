import * as QueryString from 'query-string';
import React, { FC, useContext } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { PractitionerStep } from '../components/PractitionerStep';

interface MatchParams {
  npi: string;
}

export const ConsumerSchedulingPractitionerPage: FC<RouteComponentProps<MatchParams>> = ({
  location,
  match: {
    params: { npi },
  },
}): JSX.Element => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const params = QueryString.parse(location.search);

  const npiNumber = parseInt(npi);
  if (isNaN(npiNumber)) {
    return <Redirect to={`/consumer/${organization.slug ? organization.slug : organization.id}${location.search}`} />;
  }

  return <PractitionerStep organization={organization} params={params} npiNumber={npi} />;
};
