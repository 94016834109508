import { Alert, Button } from 'antd';
import React, { FC } from 'react';
import { FreePlanConfiguration } from '../../../generated/graphql';
import { ActiveProfileCount } from './ActiveProfileCount';

interface Props {
  organizationId: string;
  isFree: boolean;
  freePlanConfiguration: FreePlanConfiguration;
}

export const FreeOrganizationProfilesCount: FC<Props> = ({
  organizationId,
  isFree,
  freePlanConfiguration,
}): JSX.Element | null => {
  if (!isFree) return null;

  const count = (freePlanConfiguration && freePlanConfiguration.profiles) || 2;
  return (
    <Alert
      style={{ marginBottom: 8 }}
      message={
        <>
          Subscription Information{' '}
          <Button type="primary" style={{ float: 'right' }}>
            <a href="https://www.blockitnow.com/covid-upgrade">Click here to Upgrade</a>
          </Button>
        </>
      }
      description={
        <>
          You are currently using <ActiveProfileCount organizationId={organizationId} /> of {count} active profiles in
          your free subscription.
          <br />
        </>
      }
      type="info"
      showIcon
    />
  );
};
