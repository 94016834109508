import { Button, Col, Dropdown, Icon, Input, Menu, message, Modal, PageHeader, Row, Select } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import _ from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import {
  Questionnaire,
  QuestionnaireAnswerSet,
  useAddTaskMutation,
  useListWorkflowQueuesQuery,
} from '../../../generated/graphql';
import { CancelReferral } from '../../../GraphQL';
import { AppContext } from '../../core/contexts/AppContext';
import { QueueTitle } from '../../workflowQueues/components/QueueTitle';
import { GetReferralQueryData, GetReferralQueryVariables, QUERY } from '../components/Referral/GetReferralQuery';
import PatientCard from '../components/Referral/PatientCard';
import { QuestionnaireModalForm } from '../components/Referral/QuestionnaireModal';
import { ReferralTabCard } from '../components/Referral/ReferralTabCard';
import SenderReceiverCard from '../components/Referral/SenderReceiverCard';
import { ReferralRefetchContext } from '../contexts/ReferralRefetchContext';
import queryString from 'query-string';
import { ReferralTag } from '../components/ReferralTag';
import { useQuery } from '@apollo/client';

interface MatchParams {
  id: string;
}

const Referral: FC<WithApolloClient<RouteComponentProps<MatchParams>>> = ({ match, client, history }): JSX.Element => {
  const { currentOrganization, setCurrentOrganizationFromReferralUrl, viewer } = useContext(AppContext);
  const lastLocation = useLastLocation();

  const { taskId } = queryString.parse(history.location.search);

  const enableSearchLinkWhenCanceled = currentOrganization?.referralSettings?.enableSearchLinkWhenCanceled;
  const showButton = !currentOrganization?.referralSettings?.hideSchedulingButtons;
  const { loading, data, refetch } = useQuery<GetReferralQueryData, GetReferralQueryVariables>(QUERY, {
    variables: {
      id: match.params.id,
      organizationId: currentOrganization?.id || '',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const senderId = data?.getReferral?.provider?.organization?.id;
    const receiverId = data?.getReferral?.appointment?.profile.organization?.id;
    const isCurrentOrgSender = currentOrganization?.id === senderId;
    const isCurrentOrgReceiver = currentOrganization?.id === receiverId;

    if (viewer && !isCurrentOrgSender && !isCurrentOrgReceiver) {
      setCurrentOrganizationFromReferralUrl([senderId, receiverId], viewer);
    }
  }, [data, viewer, currentOrganization, setCurrentOrganizationFromReferralUrl]);

  if (!currentOrganization || loading || !data || !data.getReferral) {
    return <></>;
  }

  const referral = data.getReferral;
  const subtitle = <ReferralTag status={referral.status} />;

  const scheduleButton = (
    // Do a full page load when going through scheduling flow, because the filter ui state gets super
    // weird with filter pre-fill
    <a href={`/referrals/${referral.id}/search`} className="ant-btn ant-btn-primary">
      Schedule
    </a>
  );

  const ScheduleAnotherButton = (): JSX.Element | null => {
    if (
      referral.appointment ||
      referral.profileReferral ||
      referral.patientReferral ||
      referral.status === 'cancelled'
    ) {
      if (enableSearchLinkWhenCanceled && referral.status === 'cancelled') {
        return (
          <>
            {
              <Link to={`/referrals/new?patientId=${referral.patient.id}`} className="ant-btn ant-btn-primary">
                Create a new referral
              </Link>
            }
            {scheduleButton}
          </>
        );
      } else {
        if (showButton) {
          return (
            <Link to={`/referrals/new?patientId=${referral.patient.id}`} className="ant-btn ant-btn-primary">
              Schedule Another
            </Link>
          );
        } else {
          return null;
        }
      }
    } else if (referral.status !== 'cancelled') {
      return scheduleButton;
    } else {
      return null;
    }
  };

  const ReferralActionButton = (): JSX.Element | null => {
    const { data } = useListWorkflowQueuesQuery({
      variables: { organizationId: currentOrganization?.id || '' },
    });

    const [questionModal, setQuestionModal] = useState<
      [Questionnaire | undefined, (arg0: string, arg1: QuestionnaireAnswerSet[] | []) => void]
    >([undefined, _v => {}]);

    const reasons = currentOrganization?.referralCancelReasons;

    let defaultReason = null;
    if (reasons && reasons.length) {
      if (reasons[0].value) {
        defaultReason = reasons[0].value;
      } else {
        defaultReason = reasons[0].key;
      }
    }

    const [visible, setVisible] = useState(false);
    const [cancelReason, setCancelReason] = useState<null | string>(defaultReason);
    const [cancelNote, setCancelNote] = useState<null | string>(null);

    const [addTask] = useAddTaskMutation();

    const existingQueues = referral.tasks.map(v => {
      return v.workflowQueue.id;
    });

    const manualQueues = data?.listWorkflowQueues?.filter(q => {
      return q.manualTrigger && q.triggerType === 'referral';
    });

    const addTaskCallback = async (
      referralId: string,
      workflowQueueId: string,
      queueName: string,
      notes?: string,
      questionnaireAnswerSets?: QuestionnaireAnswerSet[]
    ): Promise<void> => {
      await addTask({
        variables: {
          referralId: referralId,
          workflowQueueId: workflowQueueId,
          notes: notes,
          taskId: taskId as string,
          questionnaireAnswerSets: questionnaireAnswerSets,
        },
      });
      await refetch();
      message.success(`Referral successfully sent to ${queueName}`);
    };

    async function handleMenuClick(e: ClickParam): Promise<void> {
      if (e.key === 'cancel') {
        setVisible(true);
      } else if (e.key === 'none') {
        return;
      } else {
        const [, id] = e.key.split(':');
        const queue = manualQueues?.find(q => q.id === id);

        if (queue) {
          if (queue.questionnaires && queue?.questionnaires[0] && queue?.questionnaires[0].items?.length !== 0) {
            setQuestionModal([
              queue.questionnaires[0],
              (notes: string, questionnaireAnswers: QuestionnaireAnswerSet[] | []) => {
                addTaskCallback(referral.id, id, queue.name, notes, questionnaireAnswers);
              },
            ]);
          } else {
            addTaskCallback(referral.id, id, queue.name);
          }
        }
      }
    }

    const canCancel =
      !referral.appointment &&
      !referral.profileReferral &&
      !referral.patientReferral &&
      referral.status !== 'cancelled';

    const menu = (
      <Menu onClick={handleMenuClick}>
        {canCancel && <Menu.Item key="cancel">Cancel Referral</Menu.Item>}
        {!canCancel && manualQueues?.length === 0 && (
          <Menu.Item key="none">
            <i>No Actions Available</i>
          </Menu.Item>
        )}
        {manualQueues?.length && <Menu.Divider />}
        {manualQueues?.map(
          q =>
            q.manualTrigger &&
            !existingQueues.includes(q.id) && (
              <Menu.Item key={`wq:${q.id}`}>
                <Icon type="arrow-right" />
                <QueueTitle name={`Send to ${q.name}`} color={q.color} />
              </Menu.Item>
            )
        )}
        {manualQueues?.map(
          q =>
            q.manualTrigger &&
            existingQueues.includes(q.id) && (
              <Menu.Item key="none">
                <Icon type="arrow-right" />
                <QueueTitle name={<i>{`Already sent to ${q.name}`}</i>} color={q.color} />
              </Menu.Item>
            )
        )}
      </Menu>
    );

    const [modalQuestionnaire, modalCallback] = questionModal;

    return (
      <>
        <QuestionnaireModalForm
          onCancel={() => setQuestionModal([undefined, () => {}])}
          onOk={modalCallback}
          questionnaire={modalQuestionnaire}
          visible={modalQuestionnaire !== undefined}
        />
        <Modal
          title="Cancel Referral"
          visible={visible}
          okText={'Cancel Referral'}
          okButtonProps={{ disabled: cancelReason === null }}
          cancelText={'Close'}
          onOk={async () => {
            await client?.mutate({
              mutation: CancelReferral,
              variables: {
                referralId: referral.id,
                cancelReason: cancelReason,
                cancelNote: cancelNote || cancelReason,
              },
            });
            setVisible(false);
            message.success('Referral successfully cancelled.');
          }}
          onCancel={() => {
            setCancelReason(null);
            setCancelNote(null);
            setVisible(false);
          }}
        >
          {reasons && reasons.length ? (
            <>
              <Select<string>
                placeholder="Choose reason for cancelling"
                defaultValue={reasons[0].key}
                size="large"
                style={{ width: '100%' }}
                onChange={val => setCancelReason(val)}
              >
                {reasons.map(r => (
                  <Select.Option key={r.key} value={r.value || r.key}>
                    {r.key}
                  </Select.Option>
                ))}
              </Select>
              {cancelReason === 'other' && (
                <Input
                  placeholder="Cancellation Note"
                  size="large"
                  style={{ marginTop: 8 }}
                  onChange={v => setCancelNote(v.currentTarget.value)}
                />
              )}
            </>
          ) : (
            <>
              <Select<string>
                placeholder="Choose reason for cancelling"
                size="large"
                style={{ width: '100%' }}
                onChange={v => setCancelReason(v)}
              >
                <Select.Option value="patient_declined">Patient Declined</Select.Option>
                <Select.Option value="patient_unreachable">Patient Unreachable</Select.Option>
                <Select.Option value="patient_self_scheduled">Patient Self-Scheduled</Select.Option>
                <Select.Option value="scheduled_other">Scheduled with another provider</Select.Option>
                <Select.Option value="other">Other</Select.Option>
              </Select>
              <Input
                placeholder="Cancellation Note"
                size="large"
                style={{ marginTop: 8 }}
                onChange={v => setCancelNote(v.currentTarget.value)}
              />
            </>
          )}
        </Modal>
        <Dropdown trigger={['click']} overlay={menu} placement="bottomRight">
          <Button>
            Actions <Icon type="down" />
          </Button>
        </Dropdown>
      </>
    );
  };

  return (
    <ReferralRefetchContext.Provider value={{ refetch }}>
      <PageHeader
        title="Referral"
        onBack={() => {
          const last =
            lastLocation && lastLocation.pathname
              ? lastLocation.pathname + lastLocation.search
              : `/organizations/${currentOrganization.id}/referrals`;

          // Redirect to referrals table to prevent user from double booking an appointment via search
          if (last.includes('/search') || last.includes('/login')) {
            history.replace(`/organizations/${currentOrganization.id}/referrals`);
          } else {
            history.push(last);
          }
        }}
        subTitle={subtitle}
        extra={[<ScheduleAnotherButton key="another" />, <ReferralActionButton key="cancel-referral" />]}
      />
      <Row gutter={16} type="flex" style={{ paddingLeft: '32px', paddingRight: '32px' }}>
        <Col span={6} style={{ height: '100%' }}>
          <PatientCard
            patient={referral.patient}
            coverage={referral.coverage}
            isPreauthorized={referral.patientIsPreAuthorized}
          />
        </Col>
        <Col span={18}>
          <Row gutter={8}>
            <SenderReceiverCard
              referral={referral}
              provider={referral.provider}
              creator={referral.createdByUser}
              appointment={referral.appointment}
            />
          </Row>
          <Row gutter={8} style={{ marginTop: 8 }}>
            <ReferralTabCard referral={referral} />
          </Row>
        </Col>
      </Row>
    </ReferralRefetchContext.Provider>
  );
};

export default _.flowRight(withRouter, withApollo)(Referral);
