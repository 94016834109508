import React from 'react';
import { PrivateRoute } from '../core';
import CreateProvider from './pages/CreateProvider';
import EditProvider from './pages/EditProvider';
import { Providers } from './pages/Providers';
import { Roles } from '../core/components/PrivateRoute';

export const routes = [
  <PrivateRoute exact path="/organizations/:organizationId/providers" key="route--providers" component={Providers} />,
  <PrivateRoute
    exact
    path="/organizations/:organizationId/providers/new"
    key="route--new-provider"
    component={CreateProvider}
    minRole={Roles.ADMIN}
  />,
  <PrivateRoute
    exact
    path="/organizations/:organizationId/providers/:providerId/edit"
    key="route--edit-provider"
    component={EditProvider}
    minRole={Roles.ADMIN}
  />,
];
