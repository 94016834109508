import { Row, Col, Input, Form, Button, Typography, Checkbox, Alert } from 'antd';
import React, { FC, useState } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateAntEmail } from '../../../../helpers';

const { Title } = Typography;

export interface SignupFormData {
  name: string;
  email: string;
  password: string;
  confirm: string;
  organizationName: string;
}

interface SignupFormProps extends FormComponentProps {
  onSubmit: (arg0: SignupFormData) => void;
  form: WrappedFormUtils<SignupFormData>;
}

const SignupFormComponent: FC<SignupFormProps> = ({ onSubmit, form }): JSX.Element => {
  const [pwConfirmDirty, setPwConfirmDirty] = useState(false);

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setPwConfirmDirty((prevVal: boolean) => {
      return prevVal || !!value;
    });
  };

  const compareToFirstPassword = (rule: string, value: string, callback: (arg0?: string) => void): void => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords must match');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: string, value: string, callback: () => void): void => {
    if (value && pwConfirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <>
      <Title level={2} id="title">
        COVID-19 Scheduling for Consumers &amp; Screening Staff!
      </Title>
      <Title level={4} id="title" style={{ fontWeight: 200, textAlign: 'center' }}>
        There is no charge or obligation upon signing up.
      </Title>
      <br />
      <Form onSubmit={handleFormSubmit}>
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <Form.Item label="Name">
              {form.getFieldDecorator('name', {
                rules: [
                  {
                    min: 3,
                    required: true,
                    message: 'Please input your name',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <Form.Item label="Work email">
              {form.getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your work email',
                    pattern: /^((?!(gmail\.com|yahoo\.com|aol\.com|hotmail\.com|outlook\.com|icloud\.com|me\.com)).)*$/,
                  },
                  {
                    validator: validateAntEmail,
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col xs={12} md={6}>
            <Form.Item label="Password">
              {form.getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    min: 8,
                    message: 'Please input your password!',
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Password (Again)">
              {form.getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    min: 8,
                    message: 'Please confirm your password!',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(<Input.Password onBlur={handleConfirmBlur} />)}
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <Form.Item label="Company Name">
              {form.getFieldDecorator('organizationName', {
                rules: [
                  {
                    required: true,
                    min: 3,
                    message: 'Please input your company name',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <Form.Item label="" style={{ marginBottom: 0 }}>
              {form.getFieldDecorator('termsOfService', {
                rules: [
                  {
                    required: true,
                    message: 'Please agree to continue',
                  },
                ],
                valuePropName: 'checked',
              })(
                <Checkbox>
                  I agree to the Blockit{' '}
                  <a href="https://www.blockitnow.com/terms" target="_blank" rel="noopener noreferrer">
                    Terms &amp; Conditions
                  </a>
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <Form.Item label="">
              {form.getFieldDecorator('termsOfUse', {
                rules: [
                  {
                    required: true,
                    message: 'Please agree to continue',
                  },
                ],
                valuePropName: 'checked',
              })(
                <Checkbox>
                  I agree to the Blockit{' '}
                  <a href="https://www.blockitnow.com/use" target="_blank" rel="noopener noreferrer">
                    Acceptable Use Policy
                  </a>
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <Alert
              message="This is NOT for patients attempting to schedule a test or vaccine!"
              description={
                <>
                  This registration is intended for healthcare providers to deliver COVID-19 testing and vaccination
                  services. <br />
                  <br />
                  <b>
                    Patient&apos;s attempting to receive COVID-19 services using this form may delay their own
                    scheduling and the scheduling of others.
                  </b>
                </>
              }
              type="warning"
              showIcon
            />
            <Form.Item label="">
              {form.getFieldDecorator('notPatient', {
                rules: [
                  {
                    required: true,
                    message: 'Please agree to continue',
                  },
                ],
                valuePropName: 'checked',
              })(
                <Checkbox>
                  I certify that I am signing up as a healthcare provider organization, not as a patient.
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <Button type="primary" htmlType="submit" size="large" block>
              Create Account
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const SignupForm = Form.create<SignupFormProps>()(SignupFormComponent);
