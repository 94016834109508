import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';
import { Option, Select } from '../../../system/components/Select';
import { Payor } from '../hooks/useGetAllPayorsAndPlansQuery';

interface Props {
  form: WrappedFormUtils;
  initialPayor?: Payor;
  onSelect: (value: string) => void;
  payors: Payor[];
  required?: boolean;
}

export const SelectPayor: FC<Props> = ({ form, initialPayor, onSelect, payors, required = false }): JSX.Element => (
  <Form.Item label="Who is your insurance carrier?">
    {form.getFieldDecorator('payorId', {
      initialValue: initialPayor?.id,
      rules: [
        {
          required: required,
          message: "Please enter patient's insurance carrier.",
        },
      ],
    })(
      <Select
        allowClear
        filterOption={caseInsensitiveSelectFilterOption}
        onChange={onSelect}
        onSelect={onSelect}
        placeholder="Select an insurance carrier"
        showSearch
      >
        {payors.map(payor => (
          <Option value={payor.id} key={payor.id}>
            {payor.name}
          </Option>
        ))}
      </Select>
    )}
  </Form.Item>
);
