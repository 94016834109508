import React, { FC } from 'react';
import styled from 'styled-components';
import { AppContextConsumer } from '../modules/core/contexts/AppContext';
interface StyledProps {
  withStyles: boolean;
}

const Styles = styled.div<StyledProps>`
  ${props =>
    props.withStyles &&
    `background-color: #ef5753;
  box-shadow: 0 0 5px 3px #ef5753;
`}
`;

export const IsSuperuser: FC<{ withStyles?: boolean }> = ({ children, withStyles = true }) => (
  <AppContextConsumer>
    {({ viewer }) => (viewer?.blockitAdmin ? <Styles withStyles={withStyles}>{children}</Styles> : null)}
  </AppContextConsumer>
);
