import { useEffect, useState } from 'react';

interface WindowSize {
  width: number;
  height: number;
}

// Hook
export const useWindowSize = (): WindowSize => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  useEffect(() => {
    const handler = (): void => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handler);

    // ✅  unsubscribes from the event listener
    return () => {
      window.removeEventListener('resize', handler);
    };
  });

  return dimensions;
};
