import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Col, Form, Row } from 'antd';
import { DateTime } from 'luxon';
import AntDesignStyleOverrides from '../../core/components/App/AntDesignStyleOverrides';
import { JumpToDate } from './JumpToDate';
import { Location } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { SearchProviderName } from './SearchProviderName';
import { SelectProfilesLanguage } from './SelectProfilesLanguage';
import { SelectProfilesLocation } from './SelectProfilesLocation';
import { SelectProfilesSort, ProfileSortType } from './SelectProfilesSort';
import { ShowWeekends } from './ShowWeekends';
import { ShowMap } from './ShowMap';

interface Props {
  defaultLanguageCode: string;
  defaultSearchProvider: string | undefined;
  defaultShowWeekends: boolean;
  disableSortByDistance?: boolean;
  disableSearchProviders?: boolean;
  disableShowLanguages?: boolean;
  disableShowMap?: boolean;
  locationId: string;
  locations: Location[];
  onClickShowMap: (checked: boolean, event: MouseEvent) => void;
  onClickShowWeekends: (checked: boolean, event: MouseEvent) => void;
  onSearchProvider: (value: string, event?: ChangeEvent | React.MouseEvent | KeyboardEvent) => void;
  onSelectLanguage: (value: string) => void;
  onSelectLocation: (value: string) => void;
  onSelectSort: (value: ProfileSortType) => void;
  onSelectStart: (date: DateTime) => void;
  showMap: boolean;
  sort: ProfileSortType;
}

const Styles = styled.div`
  .jump-to-date-container,
  .show-weekends-container {
    margin-bottom: 24px;
  }

  .label {
    width: auto;

    &.ant-form-item-label {
      width: auto;
    }
  }

  .select-profile-sort {
    min-width: 125px;
  }
`;

export const FilterForm: FC<Props> = ({
  defaultLanguageCode,
  defaultSearchProvider,
  defaultShowWeekends,
  disableSearchProviders = false,
  disableShowLanguages = false,
  disableSortByDistance = false,
  disableShowMap = false,
  locationId,
  locations,
  onClickShowMap,
  onClickShowWeekends,
  onSearchProvider,
  onSelectLanguage,
  onSelectLocation,
  onSelectSort,
  onSelectStart,
  showMap,
  sort,
}) => {
  return (
    <Styles>
      <AntDesignStyleOverrides>
        <Row>
          <Col xs={24} lg={0}>
            <Form>
              {disableSearchProviders && (
                <Form.Item className={'label'} label="Provider Name:">
                  <SearchProviderName defaultValue={defaultSearchProvider} onSearch={onSearchProvider} />
                </Form.Item>
              )}

              {disableShowLanguages && (
                <Form.Item className={'label'} label="Language:">
                  <SelectProfilesLanguage defaultValue={defaultLanguageCode} onSelect={onSelectLanguage} />
                </Form.Item>
              )}
              <Form.Item className={'label'} label="Sort By:">
                <SelectProfilesSort onSelect={onSelectSort} sort={sort} />
              </Form.Item>
              <div className="blockitConsumerLocationField">
                <Form.Item className={'label'} label="Show:">
                  <SelectProfilesLocation locationId={locationId} locations={locations} onSelect={onSelectLocation} />
                </Form.Item>
              </div>
              <div className="jump-to-date-container">
                <JumpToDate onChange={onSelectStart} />
              </div>
              <div className="show-weekends-container">
                <ShowWeekends defaultChecked={defaultShowWeekends} onClick={onClickShowWeekends} />
              </div>
              {!disableShowMap && <ShowMap checked={showMap} onClick={onClickShowMap} />}
            </Form>
          </Col>
          <Col xs={0} lg={24}>
            <Form layout={'inline'}>
              <Row>
                <Col xs={12} xl={12}>
                  <Form.Item className={'label'}>
                    <div className="select-profile-sort">
                      <SelectProfilesSort
                        disableSortByDistance={disableSortByDistance}
                        onSelect={onSelectSort}
                        sort={sort}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12} xl={12}>
                  <div className="blockitConsumerLocationField">
                    <Form.Item className={'label'}>
                      <SelectProfilesLocation
                        locationId={locationId}
                        locations={locations}
                        onSelect={onSelectLocation}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AntDesignStyleOverrides>
    </Styles>
  );
};
