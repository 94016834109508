import { Form, Input, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ModalProps } from 'antd/lib/modal';
import { gql } from '@apollo/client';
import { History } from 'history';
import React, { Component, FC, useState } from 'react';
import { Mutation } from '@apollo/client/react/components';

interface CreateGroupFormProps extends FormComponentProps {
  createGroup: (e: React.MouseEvent<HTMLElement>) => void;
}

interface CreateGroupFormRef {
  props: FormComponentProps<CreateGroupFormValues>;
}

interface CreateGroupFormValues {
  name: string;
}

interface CreateGroupModalProps extends ModalProps {
  history: History;
  organizationId: string;
}

interface Data {
  createGroup: { id: string };
}

interface Variables {
  createGroupInput: {
    organizationId: string;
    name: string;
    isReferralNetwork: true;
  };
}

const createGroupMutation = gql`
  mutation CreateGroup($createGroupInput: CreateGroupInput!) {
    createGroup(createGroupInput: $createGroupInput) {
      id
    }
  }
`;

class CreateGroupFormComponent extends Component<CreateGroupFormProps & ModalProps> {
  render(): JSX.Element {
    const { form, createGroup } = this.props;

    if (!form || typeof form.getFieldDecorator !== 'function') {
      return <></>;
    }

    const { getFieldDecorator } = form;

    return (
      <Modal {...this.props} onOk={createGroup} okText="Create Group" title="New Network Group" destroyOnClose={true}>
        <Form layout="vertical">
          <Form.Item label="What is the name of the network group?">
            {getFieldDecorator('name', { rules: [{ required: true, message: 'Required' }] })(
              <Input placeholder="Network Group Name" />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const CreateGroupForm = Form.create<CreateGroupFormProps>({ name: 'create-group-form' })(CreateGroupFormComponent);

export const CreateGroupModal: FC<CreateGroupModalProps> = (props): JSX.Element => {
  const [formRef, setFormRef] = useState(null);

  const { history, onOk, organizationId } = props;

  const onCompleted = ({ createGroup: { id } }: Data): void => {
    history.push(`/organizations/${organizationId}/groups/${id}/update`);
  };

  return (
    <Mutation<Data, Variables> mutation={createGroupMutation} onCompleted={onCompleted}>
      {createGroupMutation => {
        const handleCreate = (e: React.MouseEvent<HTMLElement>, formRef: CreateGroupFormRef | null): void => {
          if (!formRef) return;

          const form = formRef.props.form;

          form.validateFields((err, values) => {
            if (err || !onOk) return;

            createGroupMutation({
              variables: { createGroupInput: { organizationId, name: values.name, isReferralNetwork: true } },
            });

            form.resetFields();
            onOk(e);
          });
        };

        return (
          <CreateGroupForm
            {...props}
            wrappedComponentRef={setFormRef}
            createGroup={(e: React.MouseEvent<HTMLElement>) => handleCreate(e, formRef)}
          />
        );
      }}
    </Mutation>
  );
};
