import { Avatar, Button, Col, Drawer, Icon, List, Row, Collapse } from 'antd';
import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import styled from 'styled-components';
import { Spinner } from '../../../../../components';
import { formatPhone } from '../../../../../helpers';
import { getMoreInfoTitle } from '../../../../../helpers/profiles';
import { groupPlansByPayorName } from '../../../../../helpers/payors';

const MORE_INFO = gql`
  query($profileId: ID!) {
    getPayorsAndPlansByProfile(profileId: $profileId) {
      profileId
      payorPlanId
      payorPlan {
        id
        name
        payor {
          id
          name
        }
      }
    }
  }
`;

const Styles = styled.div`
  .profile-image {
    border-radius: 0;
    height: 200px;
    width: 100%;
    background-image: url(${props => props.profile.profileImgUrl});
    background-size: cover;
    margin-bottom: 1rem;
  }

  .section-title {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }

  i {
    margin-right: 0.25rem;
  }

  .profile-name {
    margin-bottom: -2px;
    font-size: 18px;
  }

  .org-name {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.5);
    margin-top: -5px;
  }

  .location-section {
    margin-top: 1rem;
  }

  .section {
    margin-bottom: 2rem;
    ul {
      padding-left: 0;
    }
    li {
      list-style-type: none;
    }
  }

  h4 {
    display: flex;
    align-items: center;

    i {
      margin-right: 0.5rem;
    }
  }

  .about-subsection {
    margin-bottom: 2rem;
  }
`;

const ProfileAbout = ({ profile, ...props }) => {
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <Button size="small" htmlType="button" onClick={() => setShow(true)} block>
        More Info
      </Button>

      <Drawer title={getMoreInfoTitle(profile.type)} width={620} onClose={() => setShow(false)} visible={show}>
        <Styles {...props} profile={profile}>
          <Query query={MORE_INFO} variables={{ profileId: profile.id }}>
            {({ loading, error, data }) => {
              if (error) {
                console.error(error);
              }

              return (
                <>
                  <Row gutter={24}>
                    <Col span={8}>
                      {profile.profileImgUrl ? (
                        <div className="profile-image" />
                      ) : (
                        <Avatar shape="square" size={128} icon="user" />
                      )}
                    </Col>
                    <Col span={16}>
                      <div className="name-section">
                        <div className="profile-name">{profile.displayName}</div>
                        <div className="org-name">{profile?.specialty}</div>
                        <div className="org-name">{profile.organization.name}</div>
                      </div>
                      <div className="location-section">
                        <div>{profile.location.name}</div>
                        <div>{profile.location.address1}</div>
                        <div>
                          {profile.location.city}, {profile.location.state} {profile.location.postalCode}
                        </div>
                        <div>{formatPhone(profile.phone)}</div>
                      </div>
                    </Col>
                  </Row>
                  {profile.professionalStatementSafe ? (
                    <Row gutter={24} className="section">
                      <Col>
                        <div className="section-title">
                          <Icon type="user" />
                          About
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: profile.professionalStatementSafe }} />
                      </Col>
                    </Row>
                  ) : null}
                  <Row gutter={24} className="section" type="flex">
                    {profile.boardCertificationsSafe ? (
                      <Col className="about-subsection">
                        <div className="section-title">
                          <Icon type="medicine-box" />
                          Board Certifications
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: profile.boardCertificationsSafe }} />
                      </Col>
                    ) : null}
                    {profile.educationTrainingSafe ? (
                      <Col className="about-subsection">
                        <div className="section-title">
                          <Icon type="solution" />
                          Education & Training
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: profile.educationTrainingSafe }} />
                      </Col>
                    ) : null}
                  </Row>
                  <Row gutter={24} className="section">
                    <Col>
                      <Collapse>
                        {profile.languages && profile.languages.length > 0 ? (
                          <Collapse.Panel
                            header={
                              <>
                                <Icon type="flag" /> Languages Spoken
                              </>
                            }
                          >
                            <List
                              size="small"
                              dataSource={profile.languages}
                              renderItem={lang => <List.Item>{lang.name}</List.Item>}
                            />
                          </Collapse.Panel>
                        ) : null}

                        {loading ? (
                          <Spinner />
                        ) : (
                          data &&
                          data.getPayorsAndPlansByProfile.length > 0 && (
                            <Collapse.Panel
                              header={
                                <>
                                  <Icon type="check-circle" /> In-Network Insurance
                                </>
                              }
                            >
                              <Collapse>
                                {groupPlansByPayorName(data.getPayorsAndPlansByProfile).map(([name, plans], index) => (
                                  <Collapse.Panel header={name} key={`plans-${index}`}>
                                    <List
                                      size="small"
                                      dataSource={plans}
                                      renderItem={plan => <List.Item>{plan.payorPlan.name}</List.Item>}
                                    />
                                  </Collapse.Panel>
                                ))}
                              </Collapse>
                            </Collapse.Panel>
                          )
                        )}
                      </Collapse>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Query>
        </Styles>
      </Drawer>
    </React.Fragment>
  );
};

export default ProfileAbout;
