import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getProfileQuestionnaireQuery = gql`
  query($profileId: ID!, $procedureId: ID!) {
    getConsumerSchedulingProfile(id: $profileId) {
      id
      prescreeningQuestionnaire(procedureId: $procedureId) {
        id
        name
        title
        type
        items {
          id
          text
          type
          key
          answerMaxLength
          required
          initialValue
          options
          uploadAcceptTypes
          helpText
          answerValidators {
            value
            questionnaireId
          }
        }
      }
    }
  }
`;

export interface Data {
  getConsumerSchedulingProfile: {
    prescreeningQuestionnaire?: GetProfileQuestionnaireQueryData[];
  };
}

export interface GetProfileQuestionnaireQueryData {
  id: string;
  type: string;
  name?: string;
  title?: string;
  items: {
    id: string;
    text: string;
    type: string;
    answerMaxLength?: number | null;
    required: boolean;
    initialValue: string | null;
    options: string[] | null;
    uploadAcceptTypes: string[] | null;
    helpText: string | null;
    answerValidators?: {
      value: string;
      questionnaireId: string;
    }[];
  }[];
}

interface Variables {
  profileId: string;
  procedureId: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetConsumerProfileQuestionnaireQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getProfileQuestionnaireQuery, options);
};
