import React, { FC } from 'react';
import { Question } from './QuestionnaireForm';

interface Props {
  question: Question;
}

export const QuestionHTML: FC<Props> = ({ question }): JSX.Element => (
  <div dangerouslySetInnerHTML={{ __html: question.text }} />
);
