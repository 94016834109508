import _ from 'lodash';
import React, { FC } from 'react';
import { Divider } from 'antd';
import { graphql } from '@apollo/client/react/hoc';
import { Spinner } from '../../../../components';
import { GetAvailabilityLimitSummary as GetAvailabilityLimitSummaryQuery } from '../../../../GraphQL';
import AvailabilityLimitItem from './AvailabilityLimitItem';

interface AvailabilityLimit {
  id: string;
  userId: string;
  start: string;
  end: string;
  count: number;
  note: string;
}

interface AvailabilityLimitSummary {
  enabled: boolean;
  currentAvailability: number;
  pastLimits: AvailabilityLimit[];
  futureLimits: AvailabilityLimit[];
}

interface Props {
  organizationId: string;
  profileId: string;
  GetAvailabilityLimitSummary: {
    loading: boolean;
    GetAvailabilityLimitSummary: AvailabilityLimitSummary;
  };
  authorizedToEdit: boolean;
}

const AvailabilityLimitSectionComponent: FC<Props> = ({
  organizationId,
  profileId,
  GetAvailabilityLimitSummary,
  authorizedToEdit,
}) => {
  if (GetAvailabilityLimitSummary.loading) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );
  }

  const { GetAvailabilityLimitSummary: limitSummary } = GetAvailabilityLimitSummary;

  if (limitSummary && limitSummary.enabled) {
    if (limitSummary.currentAvailability === null && limitSummary.futureLimits.length === 0) {
      return <p style={{ color: '#bbb', fontStyle: 'italic', textAlign: 'center' }}>No limits have been configured.</p>;
    } else {
      const currentAvailability = limitSummary.currentAvailability === null ? 0 : limitSummary.currentAvailability;
      return (
        <React.Fragment>
          <p>Current availability: {currentAvailability}</p>
          {limitSummary.pastLimits.length > 0 ? (
            <div>
              <Divider style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.65)' }}>Past Limits</Divider>
              <ul className="list-reset w-full">
                {limitSummary.pastLimits.map(limit => (
                  <AvailabilityLimitItem
                    availabilityLimitObject={limit}
                    key={limit.start}
                    organizationId={organizationId}
                    profileId={profileId}
                    authorizedToEdit={authorizedToEdit}
                  />
                ))}
              </ul>
            </div>
          ) : null}
          {limitSummary.futureLimits.length > 0 ? (
            <div>
              <Divider style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.65)' }}>Future Limits</Divider>
              <ul className="list-reset w-full">
                {limitSummary.futureLimits.map(limit => (
                  <AvailabilityLimitItem
                    availabilityLimitObject={limit}
                    key={limit.start}
                    organizationId={organizationId}
                    profileId={profileId}
                    authorizedToEdit={authorizedToEdit}
                  />
                ))}
              </ul>
            </div>
          ) : null}
        </React.Fragment>
      );
    }
  } else {
    return <p style={{ color: '#bbb', fontStyle: 'italic', textAlign: 'center' }}>Limits are disabled.</p>;
  }
};

export const AvailabilityLimitSection = _.flowRight(
  graphql<Props>(GetAvailabilityLimitSummaryQuery, {
    name: 'GetAvailabilityLimitSummary',
    options: ownProps => ({
      variables: { organizationId: ownProps.organizationId, profileId: ownProps.profileId },
    }),
  })
)(AvailabilityLimitSectionComponent);
