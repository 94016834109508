import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
  extra?: string;
}

export const InputDisplayName: FC<Props> = ({ form, initialValue, extra }): JSX.Element => (
  <Form.Item label="Profile Display Name:" extra={extra}>
    {form.getFieldDecorator('displayName', {
      initialValue,
      rules: [{ message: "Please enter the profile's display name.", required: true }],
    })(<Input allowClear placeholder="Display Name" />)}
  </Form.Item>
);
