import React, { FC, useState, useContext } from 'react';
import { Typography, Row, Col, Input, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import store from '../../../../localstore';
import { AppContext } from '../../../core/contexts/AppContext';
import { useViewerQuery } from '../../../../hooks/queries/useViewerQuery';

const { Title } = Typography;

interface ConsumerEmbedInstructionsProps {
  organizationId: string;
}

export const ConsumerEmbedInstructions: FC<ConsumerEmbedInstructionsProps> = ({ organizationId }) => {
  const { init, setMode } = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { refetch } = useViewerQuery({});

  const handleClickAdminPortal = async (): Promise<void> => {
    setLoading(true);

    store().setItem('currentOrganizationId', organizationId);
    await refetch().then(({ data }) => {
      if (data?.viewer) {
        init(data.viewer);
        setMode('admin');
      }
    });

    setLoading(false);
    history.push(`/organizations/${organizationId}/profiles`);
  };

  const consumerPortalLink = `https://app.blockitnow.com/consumer/${organizationId}`;

  return (
    <Row type="flex" justify="center">
      <Col xs={24} md={18} lg={18} xl={14}>
        <Title level={2} id="title">
          Setup complete!
        </Title>
        <br />
        <Typography.Paragraph>
          Your schedule is configured and ready for patients to begin booking appointments. You have two options to
          share your schedule with them—
        </Typography.Paragraph>

        <Title level={4} id="title">
          Direct Link
        </Title>
        <Typography.Paragraph>
          You can share a direct link to your secure scheduling portal with your patients. This page can also be linked
          to from your website or via email.
        </Typography.Paragraph>
        <Input size="small" defaultValue={consumerPortalLink} />
        <br />
        <br />
        <br />
        <Title level={4} id="title">
          Website Embed
        </Title>
        <Typography.Paragraph>
          Your secure scheduling portal may also be embedded on your website. Ask your web developer to paste the
          following HTML snippet into your website.
        </Typography.Paragraph>
        <Input.TextArea
          autoSize={{ minRows: 3, maxRows: 5 }}
          defaultValue={`<iframe src="${consumerPortalLink}" width=400 height=400></iframe>`}
        />
        <br />
        <br />
        <Button htmlType="button" loading={loading} onClick={handleClickAdminPortal} size="large" type="primary">
          Continue to Admin Portal
        </Button>
      </Col>
    </Row>
  );
};
