import _ from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';
import { Option, Select } from '../../../system/components/Select';

interface Props {
  form: WrappedFormUtils;
  onSelect: (value: number, field: string) => void;
  initialValue?: number | null;
}

const Styles = styled.div`
  .no-margin-bottom {
    margin-bottom: 0;
  }
`;

const monthFromNumber = (number: number): string => {
  return DateTime.fromFormat(`${number}`, 'M').toFormat('MMMM');
};

export const SelectPatientBirthMonth: FC<Props> = ({ form, onSelect, initialValue }): JSX.Element => (
  <Styles>
    <Form.Item className="no-margin-bottom">
      {form.getFieldDecorator('birthMonth', { initialValue: initialValue })(
        <Select
          filterOption={caseInsensitiveSelectFilterOption}
          placeholder="Month"
          onChange={(v: number) => onSelect(v, 'month')}
          showAction={['focus', 'click']}
          showSearch
        >
          {_.range(1, 13).map(month => (
            <Option value={month} key={`month-${month}`}>
              {monthFromNumber(month)}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  </Styles>
);
