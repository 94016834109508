import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TextArea } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputGeneralPatientInstructions: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="General Patient Instructions:"
    extra="Patient instructions are displayed upon appointment confirmation and at the bottom of the patient's confirmation email"
  >
    {form.getFieldDecorator('generalPatientInstructions', { initialValue, rules: [{ required: false }] })(
      <TextArea allowClear placeholder="General Patient Instructions" rows={8} />
    )}
  </Form.Item>
);
