import { gql } from '@apollo/client';

const GetLanguages = gql`
  query {
    getLanguages {
      code
      name
    }
  }
`;

export { GetLanguages };
