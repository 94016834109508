import { Button, Card, Col, Form, message, Row, Typography, InputNumber } from 'antd';
import React, { FC, FormEvent } from 'react';
import { getOrganization, useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { useUpdateOrganizationMutation } from '../../../hooks/mutations/useUpdateOrganization';
import { FormComponentProps } from 'antd/lib/form';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props extends FormComponentProps {
  organizationId: string;
}

export const SecuritySettingsCardComponent: FC<Props> = ({ form, organizationId }): JSX.Element => {
  const [updateMaxInactiveUserDays] = useUpdateOrganizationMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields(async (err, values: { maxInactiveUserDays: number }) => {
      try {
        await updateMaxInactiveUserDays({
          variables: {
            organizationId: organizationId,
            updateOrganizationInput: {
              maxInactiveUserDays: values.maxInactiveUserDays,
            },
          },
          refetchQueries: [{ query: getOrganization, variables: { organizationId: organizationId } }],
        });
        message.success('Your changes have been saved.');
      } catch (err) {
        message.error('An error occurred while saving your changes');
      }
    });
  };

  return (
    <Row>
      <Col span={18} push={1}>
        <Form onSubmit={handleSubmit}>
          <Card
            title="Security"
            extra={
              <BlockRestrictedUsers>
                <Button htmlType="submit" type="primary">
                  Save Changes
                </Button>
              </BlockRestrictedUsers>
            }
          >
            <Row>
              <Col span={12}>
                <Form.Item label="Maximum days of user inactivity">
                  {form.getFieldDecorator('maxInactiveUserDays', {
                    initialValue: data?.getOrganization?.maxInactiveUserDays,
                  })(<InputNumber placeholder="365" className="ant-input" />)}
                </Form.Item>
              </Col>
              <Col span={10} push={1}>
                <Typography.Text type="secondary">
                  The number of days a user can be inactive before their account will be deactivated. An organization
                  admin will need to reactivate the user if they become deactivated.
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export const SecuritySettingsCard = Form.create<Props>({ name: 'Security Settings' })(SecuritySettingsCardComponent);
