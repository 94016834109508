import { Button, Card, Typography } from 'antd';
import { History } from 'history';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { DirectoryContext, Organization } from '../containers/DirectoryContext';

interface Props {
  history: History;
  organization: Organization;
}

const { Text } = Typography;

const Styles = styled.div`
  .organization-card {
    display: flex;
    justify-content: center;
    height: 366px;
    width: 100%;
  }

  .organization-card-button {
    width: 100%;
  }

  .organization-card-name {
    align-items: center;
    display: flex;
    font-size: 32px;
    height: 100%;
    justify-content: center;
    padding: 12px;
    text-align: center;
    width: 100%;
  }
`;

export const OrganizationCard: FC<Props> = ({ history, organization }): JSX.Element => {
  const { setProfileNameFilter, setSelectedOrganizationId } = useContext(DirectoryContext);

  const handleButtonClick = (): void => {
    setProfileNameFilter('');
    setSelectedOrganizationId(organization.id);
    history.push(`/directory/profiles`);
  };

  return (
    <Styles>
      <Card
        bodyStyle={{
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          padding: '0',
          width: '100%',
        }}
        className="organization-card"
        hoverable={true}
        onClick={() => handleButtonClick()}
      >
        <Text className="organization-card-name">
          {organization.profileImgUrl ? (
            <img src={organization.profileImgUrl} alt={organization.name} />
          ) : (
            organization.name
          )}
        </Text>
        <Button className="organization-card-button" onClick={handleButtonClick} size="large" type="primary">
          See Organization Profiles
        </Button>
      </Card>
    </Styles>
  );
};
