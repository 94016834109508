import React, { FC, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ApolloError } from '@apollo/client';
import { Button, Col, Collapse, Form, message, Row, Typography } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CreateLocationMutationData, useCreateLocationMutation } from '../../locations/hooks/useCreateLocationMutation';
import { defaultTheme } from '../../../themes/default';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { getOrganization, useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { LocationForm, LocationFormData } from '../../locations/components/LocationForm';
import { Option, Select } from '../../../system/components/Select';
import { ProfileFormData } from './ProfileForm';

interface Props {
  form: WrappedFormUtils<ProfileFormData>;
  initialValue?: string;
  organizationId: string;
}

const { Panel } = Collapse;

const CollapseStyles = styled.div`
  .new-loc-button {
    color: ${props => props.theme.primaryColor};
    :hover {
      text-decoration: underline;
    }
  }
`;

export const SelectLocation: FC<Props> = ({ form, initialValue, organizationId }): JSX.Element => {
  const [activePanelKey, setActivePanelKey] = useState<string>('');

  const handleCreateLocationCompleted = ({ createLocation: location }: CreateLocationMutationData): void => {
    form.setFieldsValue({ locationId: location.id });
    setActivePanelKey('');
  };

  const handleLocationError = (error: ApolloError): void => {
    // todo - handle error
    message.error('Something went wrong!  Please try again or contact us if the problem persists.');
  };

  const [createLocation, { loading: createLocationLoading }] = useCreateLocationMutation({
    awaitRefetchQueries: true,
    onCompleted: handleCreateLocationCompleted,
    onError: handleLocationError,
    refetchQueries: [{ query: getOrganization, variables: { organizationId } }],
  });

  const handleLocationFormSubmit = (values: LocationFormData): void => {
    const createLocationInput = {
      organizationId,
      name: values.name,
      displayName: values?.displayName,
      phone: values.phone,
      email: values.email,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      country: 'United States',
      timeZone: values.timeZone,
      hasAppointmentRequests: false,
    };

    createLocation({ variables: { createLocationInput } }).then();
  };

  const handleSelectOnChange = (value: string): void => {
    if (value === 'new-location') {
      setActivePanelKey('new-location-panel');
    } else {
      setActivePanelKey('');
    }
  };

  const { data, error, loading } = useGetOrganizationQuery({ variables: { organizationId } });

  useEffect(() => {
    if (data && data.getOrganization.locations.length === 0) {
      handleSelectOnChange('new-location');
    }
  }, [data]);

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  let {
    getOrganization: { locations },
  } = data;

  locations = locations.filter(l => l.isActive);

  const hide = activePanelKey === 'new-location-panel' ? { display: 'none' } : {};

  const newLocButtonClick = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    form.setFieldsValue({ locationId: 'new-location' });
    handleSelectOnChange('new-location');
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CollapseStyles>
        <Row style={hide}>
          <Col xs={12}>
            <Form.Item
              label="Physical address:"
              extra={
                <>
                  Select an existing address or{' '}
                  <button onClick={newLocButtonClick} className="new-loc-button">
                    enter a new one
                  </button>
                  .
                </>
              }
            >
              {form.getFieldDecorator('locationId', {
                initialValue,

                rules: [
                  {
                    message: `Please select your profile's physical address.`,
                    required: true,
                  },
                  {
                    message: 'Please create a physical.',
                    validator: (rule: string, value: string | undefined, cb: (arg0?: boolean) => void): void => {
                      if (value === 'new-location') cb(true);
                      cb();
                    },
                  },
                ],
              })(
                <Select
                  allowClear
                  onChange={handleSelectOnChange}
                  dropdownMatchSelectWidth={false}
                  placeholder="Select a location."
                >
                  <Option value="new-location" key="new-location">
                    Create New Location...
                  </Option>
                  {locations.map(location => (
                    <Option value={location.id} key={location.id}>
                      <b>{location.name}</b> ({location.address1})
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8} push={1}>
            <Typography.Paragraph type="secondary">
              Profiles must be tied to a physical address (for example, an office location). You may either select a
              previously used location or add a new one.
            </Typography.Paragraph>
          </Col>
        </Row>
        {activePanelKey === 'new-location-panel' && (
          <Row>
            <Col span={22}>
              <Collapse activeKey={activePanelKey} destroyInactivePanel={true}>
                <Panel
                  header="Add an office address"
                  key="new-location-panel"
                  extra={
                    <Button
                      onClick={() => {
                        form.resetFields(['locationId']);
                        setActivePanelKey('');
                      }}
                      size="small"
                    >
                      Cancel
                    </Button>
                  }
                >
                  <>
                    <Typography.Paragraph type="secondary">
                      Profiles must be tied to a physical address (for example, an office location). Please fill in the
                      information below to add a new office address, or click the cancel button to choose a previously
                      created location.
                    </Typography.Paragraph>
                    <LocationForm loading={createLocationLoading} onSubmit={handleLocationFormSubmit} />
                  </>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
      </CollapseStyles>
    </ThemeProvider>
  );
};
