import { Spin } from 'antd';
import React, { FC } from 'react';

interface Props {
  padding?: number;
}

export const Spinner: FC<Props> = ({ padding = 8 }): JSX.Element => (
  <div className={`spinner text-center p-${padding + ''} w-full h-full flex justify-center items-center`}>
    <Spin />
  </div>
);
