import React, { FC } from 'react';
import { Form, Switch } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectSendToProvider: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="Allow referring offices to send unscheduled referrals:"
    extra="Previously known as Send-to-Provider."
  >
    {form.getFieldDecorator('isSendToProvider', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Switch />)}
  </Form.Item>
);
