import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { DateTime } from 'luxon';
import { defaultTheme } from '../../../themes/default';
import { formatPhone } from '../../../helpers';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { Slot } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';
import { useGetProcedureQuery } from '../hooks/useGetProcedureQuery';

interface Props {
  procedureId: string;
  profile: Profile;
  apptType: string;
  slot?: Slot;
  order?: string;
}

const { Text } = Typography;

const Styles = styled.div`
  .appointment-detail {
    color: ${({ theme }) => theme.bodyTextColor};
    font-family: Source Sans Pro, sans-serif;
    line-height: 26px;
    font-size: 18px;
    text-decoration: none solid;
    display: block;
  }

  .appointment-details-header {
    color: ${({ theme }) => theme.bodyTextColor};
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none solid;
    text-transform: uppercase;
  }

  .appointment-details-header-col {
    margin-bottom: 16px;
  }

  .address-section,
  .appointment-phone-number {
    margin-top: 16px;
  }

  .appointment-location-name {
    font-weight: 800;
  }
`;

export const AppointmentDetails: FC<Props> = ({ procedureId, profile, apptType, slot }): JSX.Element => {
  const { data, error, loading } = useGetProcedureQuery({ variables: { procedureId } });

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  const { getProcedure: procedure } = data;

  const start = DateTime.fromISO(slot?.start || '');

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Row>
          <Col className="appointment-details-header-col" span={24}>
            <Text className="appointment-details-header">Appointment Details</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="appointment-detail appointment-time">
              {slot && `${start.toLocaleString(DateTime.DATE_HUGE)}`} at {start.toLocaleString(DateTime.TIME_SIMPLE)}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="appointment-detail appointment-visit-type">{`${procedure.specialty.name} - ${procedure.name}`}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="appointment-detail appointment-profile-name">{`${profile.displayName}`}</Text>
            <Text className="appointment-detail appointment-phone-number">{`${formatPhone(profile.phone)}`}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {apptType !== 'instant' && (
              <div className="address-section">
                <Text className="appointment-detail appointment-location-name">{`${profile.location.name}`}</Text>
                <Text className="appointment-detail">{profile.location.address1}</Text>
                <Text className="appointment-detail">{profile.location.address2}</Text>
                <Text className="appointment-detail">
                  {profile.location.city}, {profile.location.state} {profile.location.postalCode}
                </Text>
              </div>
            )}
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
