import React, { FC } from 'react';
import RadioGroup from 'antd/lib/radio/group';
import { Radio } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { ValidateQuestionnaire, Question } from './QuestionnaireForm';

interface Props extends FormComponentProps {
  question: Question;
  validator: ValidateQuestionnaire;
}

export const QuestionBoolean: FC<Props> = ({ form, question, validator }): JSX.Element => (
  <Form.Item label={question.text} key={question.id} extra={question.helpText}>
    {form.getFieldDecorator(`questionnaire[0].${question.text.replace(/\./g, ' ')}`, {
      rules: [{ required: question.required, message: 'required' }, { validator: validator }],
      initialValue: question.initialValue,
    })(
      <RadioGroup>
        <Radio value={true} key={1}>
          Yes
        </Radio>
        <Radio value={false} key={2}>
          No
        </Radio>
      </RadioGroup>
    )}
  </Form.Item>
);
