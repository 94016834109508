import { FC, useEffect } from 'react';
import TagManager from 'react-gtm-module';

export const GoogleTagManager: FC<{ gtmId?: string | null }> = ({ gtmId }): null => {
  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId: gtmId });
    }
  }, [gtmId]);

  return null;
};
