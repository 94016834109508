import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { defaultTheme } from '../../../themes/default';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';

interface Props {
  organization: Organization;
}

interface OrganizationImgOrNameHeaderProps {
  organization: Organization;
  size: 'xs' | 'sm' | 'md' | 'lg';
}

const { Title } = Typography;

const StyledHr = styled.hr`
  background-color: #1d5297;
  background-size: cover;
  height: 4px;
  width: 100%;
`;

const Styles = styled.div<{ organization: Organization }>`
  .organization-img {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-decoration: none solid;
    width: 100%;

    &.organization-img-xs {
      margin-bottom: 20px;
      margin-top: 20px;
      max-height: 150px;
      max-width: 150px;
    }

    &.organization-img-sm {
      margin-bottom: 24px;
      margin-top: 24px;
      max-height: 175px;
      max-width: 175px;
    }

    &.organization-img-md {
      margin-bottom: 28px;
      margin-top: 28px;
      max-height: 190px;
      max-width: 190px;
    }

    &.organization-img-lg {
      margin-bottom: 32px;
      margin-top: 32px;
      max-height: 200px;
      max-width: 200px;
    }
  }

  .title {
    color: #1d5297;
    font-family: Source, serif;
    text-align: center;
    text-decoration: none solid;

    &.title-xs {
      font-size: 32px;
      margin-bottom: 24px;
      margin-top: 24px;
    }

    &.title-sm {
      font-size: 40px;
      margin-bottom: 32px;
      margin-top: 32px;
    }

    &.title-md {
      font-size: 44px;
      margin-bottom: 36px;
      margin-top: 36px;
    }

    &.title-lg {
      font-size: 48px;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }
`;

export const OrganizationImgOrNameHeader: FC<OrganizationImgOrNameHeaderProps> = ({
  organization,
  size,
}): JSX.Element => {
  return organization.profileImgUrl ? (
    <div className={`organization-img organization-img-${size}`}>
      <img src={organization.profileImgUrl} alt={organization.name} />
    </div>
  ) : (
    <Title className={`title title-${size}`}>{organization.name}</Title>
  );
};

export const Header: FC<Props> = ({ organization }): JSX.Element | null => {
  if (organization.consumerSchedulingSettings?.htmlHeader) {
    return <div dangerouslySetInnerHTML={{ __html: organization.consumerSchedulingSettings.htmlHeader }} />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles organization={organization}>
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} sm={0}>
            <OrganizationImgOrNameHeader size="xs" organization={organization} />
          </Col>
          <Col xs={0} sm={24} md={0}>
            <OrganizationImgOrNameHeader size="sm" organization={organization} />
          </Col>
          <Col xs={0} md={24} lg={0}>
            <OrganizationImgOrNameHeader size="md" organization={organization} />
          </Col>
          <Col xs={0} lg={24}>
            <OrganizationImgOrNameHeader size="lg" organization={organization} />
          </Col>
        </Row>
      </Styles>
      <StyledHr />
    </ThemeProvider>
  );
};
