import { gql } from '@apollo/client';

export interface OrganizationInterface {
  id: string;
  name: string;
}

export interface PatientInterface {
  givenName1: string;
  familyName: string;
  __typename: string;
  identifier?: string | null;
}

export interface PatientReferralInterface {
  id: string;
  patientEmail: string | null;
  profile: ProfileInterface;
}

export interface ProfileReferralInterface {
  id: string;
  profile: ProfileInterface;
}

export interface SpecialtyInterface {
  id: string;
  name: string;
  __typename: string;
}

export interface ProcedureInterface {
  id: string;
  name: string;
  display: string;
  specialty: SpecialtyInterface;
  __typename: string;
}

export interface ProfileInterface {
  id: string;
  displayName: string;
  isAccessCenter: boolean;
  organization: OrganizationInterface;
  __typename: string;
  profileProcedures: {
    id: string;
    durationInMinutes: number;
    procedure: ProcedureInterface;
  };
}

export interface AppointmentInterface {
  id: string;
  status: string;
  start: string;
  end: string;
  profile: ProfileInterface;
  __typename: string;
}

export interface ProviderInterface {
  id: string;
  name: string;
  organization: OrganizationInterface;
  __typename: string;
}

export interface CreatedByUserInterface {
  id: string;
  name: string;
}

export interface EntryTagsInterface {
  key: string;
  value: string;
}

export interface EntryInterface {
  id: string;
  status: string;
  insertedAt: string;
  createdByUser: CreatedByUserInterface;
  patient: PatientInterface;
  patientReferral: PatientReferralInterface | null;
  profileReferral: ProfileReferralInterface | null;
  procedure: ProcedureInterface;
  appointment: AppointmentInterface | null;
  provider: ProviderInterface;
  tags: [EntryTagsInterface] | null;
  referralOrder: ReferralOrderInterface[];
  __typename: string;
}

export interface ListReferralsInterface {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalEntries: number;
  entries: EntryInterface[];
  filters: {
    apptStatuses: [];
    providers: [];
    profiles: [];
    referralStatuses: [];
    procedures: [];
  };
  __typename: string;
}

export interface ReferralOrderInterface {
  id: string;
  type: string;
}

export const ListReferralsQuery = gql`
  query ListReferrals(
    $organizationId: ID!
    $patientId: ID
    $locationId: ID
    $type: ReferralViewType!
    $page: Int
    $pageSize: Int
    $filters: String
    $sortField: ReferralSortColumn
    $sortOrder: ReferralOrdering
    $startDate: String
    $endDate: String
  ) {
    ListReferrals(
      organizationId: $organizationId
      patientId: $patientId
      locationId: $locationId
      type: $type
      page: $page
      pageSize: $pageSize
      filters: $filters
      sortField: $sortField
      sortOrder: $sortOrder
      startDate: $startDate
      endDate: $endDate
    )
      @connection(
        key: "ListReferrals"
        filter: [
          "organizationId"
          "patientId"
          "locationId"
          "pageSize"
          "page"
          "sortField"
          "sortOrder"
          "filters"
          "type"
          "startDate"
          "endDate"
        ]
      ) {
      pageSize
      pageNumber
      totalPages
      totalEntries
      filters {
        providers {
          value
          text
        }
        profiles {
          value
          text
        }
        apptStatuses {
          value
          text
        }
        referralStatuses {
          value
          text
        }
        procedures {
          value
          text
        }
      }
      entries {
        id
        status
        insertedAt
        tags {
          key
          value
        }
        createdByUser {
          id
          name
        }
        patient {
          id
          givenName1
          familyName
          identifier
        }
        referralOrder {
          id
          type
        }
        patientReferral {
          id
          patientEmail
          profile {
            id
            displayName
            phone
            profileImgUrl
            isAccessCenter
            location {
              id
              name
              address1
              address2
              city
              state
              postalCode
              distanceFromPatient
            }
            organization {
              id
              name
            }
          }
        }
        profileReferral {
          id
          profile {
            id
            displayName
            phone
            profileImgUrl
            isAccessCenter
            location {
              id
              name
              address1
              address2
              city
              state
              postalCode
              distanceFromPatient
            }
            organization {
              id
              name
            }
          }
        }
        procedure {
          id
          name
          display
          specialty {
            id
            name
          }
        }
        appointment {
          id
          status
          start
          end
          profile {
            id
            displayName
            isAccessCenter
            organization {
              id
              name
            }
          }
        }
        provider {
          id
          name
          organization {
            id
            name
          }
        }
      }
    }
  }
`;
