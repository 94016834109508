import React, { FC, useContext, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { Procedure, Specialty } from '../hooks/useGetProcedureQuery';
import { SelectProcedure } from './SelectProcedure';
import { SelectSpecialty } from './SelectSpecialty';

interface Props {
  form: WrappedFormUtils;
  initialProcedureId?: string;
  initialSpecialtyId?: string;
  specialties: Specialty[];
}

export const SelectSpecialtyAndProcedure: FC<Props> = ({
  form,
  initialProcedureId,
  initialSpecialtyId,
  specialties,
}): JSX.Element => {
  const { organization, singleSpecialtyId, canChangeVisitType } = useContext(ConsumerSchedulingContext);

  const initialProcedure = (): Procedure | undefined => {
    return findProcedure(initialProcedureId);
  };

  const initialSpecialty = (): Specialty | undefined => {
    return findProcedureSpecialty(initialProcedureId)
      ? findProcedureSpecialty(initialProcedureId)
      : findSpecialty(initialSpecialtyId);
  };

  const findSpecialty = (specialtyId: string | undefined): Specialty | undefined => {
    return specialties.find(specialty => specialty.id === specialtyId);
  };

  const findProcedure = (procedureId: string | undefined): Procedure | undefined => {
    return findProcedureSpecialty(procedureId)?.procedures.find(procedure => procedure.id === procedureId);
  };

  const findProcedureSpecialty = (procedureId: string | undefined): Specialty | undefined => {
    return specialties.find(specialty => {
      return specialty.procedures.find(procedure => procedure.id === procedureId);
    });
  };

  const [selectedSpecialty, setSelectedSpecialty] = useState<Specialty | undefined>(findSpecialty(singleSpecialtyId));

  const handleSelectSpecialty = (specialtyId: string): void => {
    const newSpecialty = specialties.find(specialty => specialty.id === specialtyId);
    if (newSpecialty?.id !== selectedSpecialty?.id) {
      form.setFieldsValue({ procedureId: undefined });
    }

    setSelectedSpecialty(newSpecialty ? newSpecialty : undefined);
  };

  return (
    <>
      {singleSpecialtyId ? null : (
        <SelectSpecialty
          form={form}
          initialSpecialty={initialSpecialty()}
          onSelect={handleSelectSpecialty}
          specialties={specialties}
        />
      )}
      {!canChangeVisitType ? null : (
        <SelectProcedure
          form={form}
          initialProcedure={initialProcedure()}
          label={
            organization.consumerSchedulingSettings?.visitTypeFormLabel
              ? organization.consumerSchedulingSettings.visitTypeFormLabel
              : undefined
          }
          procedures={
            selectedSpecialty
              ? selectedSpecialty.procedures
              : initialProcedureId
              ? findProcedureSpecialty(initialProcedureId)?.procedures
              : findSpecialty(initialSpecialtyId)?.procedures
          }
        />
      )}
    </>
  );
};
