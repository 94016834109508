import { Empty, Form, Modal, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ModalProps } from 'antd/lib/modal';
import { gql } from '@apollo/client';
import * as _ from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { AppContext } from '../../core/contexts/AppContext';
import { useProfileSearch } from '../hooks/useProfileSearch';

interface CreateGroupProfileFormProps extends FormComponentProps {
  createGroupProfile: (profileId: string) => void;
}

interface CreateGroupProfileModalProps extends ModalProps {
  groupId: string;
}

interface Data {
  createGroupProfile: {
    id: string;
    name: string;
    profiles: {
      id: string;
      name: string;
      location: { id: string; name: string };
      organization: { id: string; name: string };
    }[];
  };
}

interface Profile {
  id: string;
  displayName: string;
  type: string;
  location: { id: string; name: string };
  organization: { id: string; name: string };
  profileProcedures: {
    id: string;
    procedure: { id: string; name: string; specialty: { id: string; name: string } };
  }[];
}

interface Variables {
  createGroupProfileInput: {
    groupId: string;
    profileId: string;
  };
}

interface UniqueSpecialties {
  [key: string]: Array<string>;
}

const createGroupProfileMutation = gql`
  mutation CreateGroupProfile($createGroupProfileInput: CreateGroupProfileInput!) {
    createGroupProfile(createGroupProfileInput: $createGroupProfileInput) {
      id
      name
      profiles {
        id
        displayName
        location {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

export const getProfilesQuery = gql`
  query GetProfiles($regionId: String, $nameFilter: String) {
    getProfiles(regionId: $regionId, nameFilter: $nameFilter) {
      id
      displayName
      specialty
      type
      location {
        id
        name
      }
      organization {
        id
        name
      }
      profileProcedures {
        id
        procedure {
          id
          name
          specialty {
            id
            name
          }
        }
      }
    }
  }
`;

interface QueryData {
  getProfiles: Profile[];
}

interface QueryVariables {
  regionId: string;
  nameFilter?: string;
}

const CreateGroupProfileFormComponent: FC<CreateGroupProfileFormProps & ModalProps> = (props): JSX.Element => {
  const { form, createGroupProfile } = props;

  const { currentOrganization } = useContext(AppContext);
  const { data: initialProfileResults } = useProfileSearch({
    variables: { regionId: currentOrganization?.region?.id || '' },
  });

  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null);
  const [selectedProcedure, setSelectedProcedure] = useState<string | null>(null);
  const [getProfiles, { loading, data }] = useLazyQuery<QueryData, QueryVariables>(getProfilesQuery);

  // set initial results
  useEffect(() => {
    if (initialProfileResults) {
      setProfiles(initialProfileResults.getProfiles);
    }
  }, [initialProfileResults]);

  // set results after typing
  useEffect(() => {
    if (data && data.getProfiles) {
      if (selectedProcedure) {
        setProfiles(
          data.getProfiles.filter(p =>
            p.profileProcedures
              .map(p => `${p.procedure.name}--${p.procedure.specialty.name}`)
              .includes(selectedProcedure)
          )
        );
      } else {
        setProfiles(data.getProfiles);
      }
    }
  }, [data, selectedProcedure]);

  if (!form || typeof form.getFieldDecorator !== 'function') {
    return <></>;
  }

  const { getFieldDecorator } = form;

  const resetProfiles = (): void => {
    if (initialProfileResults) setProfiles(initialProfileResults.getProfiles);
  };

  const searchProfiles = (val: string): void => {
    if (val && val.length > 0) {
      getProfiles({
        variables: { regionId: currentOrganization?.region.id || '', nameFilter: val },
      });
    } else if (val === '') {
      getProfiles({
        variables: { regionId: currentOrganization?.region.id || '' },
      });
    }
  };

  let uniqueSpecialties: UniqueSpecialties = {};
  if (initialProfileResults) {
    const uniqueProcedures = [
      ...new Set(initialProfileResults.getProfiles.flatMap(p => p.profileProcedures.map(p => p.procedure))),
    ];

    uniqueSpecialties = uniqueProcedures.reduce((acc: { [key: string]: Array<string> }, currentValue) => {
      const groupKey = currentValue.specialty.name;
      if (!acc[groupKey as keyof UniqueSpecialties]) {
        acc[groupKey as keyof UniqueSpecialties] = [];
      }
      acc[groupKey as keyof UniqueSpecialties].push(currentValue.name);
      return acc;
    }, {});
  }

  const filterByProcedure = (val: string): void => {
    setSelectedProcedure(val);

    if (val && val.length > 0 && initialProfileResults) {
      setProfiles(
        initialProfileResults.getProfiles.filter(p =>
          p.profileProcedures.map(p => `${p.procedure.name}--${p.procedure.specialty.name}`).includes(val)
        )
      );
    }
  };

  return (
    <Modal
      {...props}
      onOk={(e: React.MouseEvent<HTMLElement>) => {
        if (selectedProfileId) {
          createGroupProfile(selectedProfileId);
        }

        setSelectedProfileId(null);
        form.resetFields();
        if (initialProfileResults) setProfiles(initialProfileResults.getProfiles);
        if (props.onOk) props.onOk(e);
      }}
      onCancel={(e: React.MouseEvent<HTMLElement>) => {
        setSelectedProfileId(null);
        if (initialProfileResults) setProfiles(initialProfileResults.getProfiles);
        if (props.onCancel) props.onCancel(e);
      }}
      okText="Add Profile"
      title="Search Profiles"
      destroyOnClose={true}
      width={720}
    >
      <Form layout="vertical">
        <Form.Item label="Filter profiles by supported visit type">
          {getFieldDecorator('specialty')(
            <Select<string>
              onDeselect={(val: string) => resetProfiles}
              notFoundContent={
                loading ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Loading...'} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              showSearch
              placeholder="Select Visit Type"
              optionFilterProp="children"
              onSelect={(val: string) => filterByProcedure(val)}
              filterOption={(input, option) => {
                if (option.props.children && typeof option.props.children === 'string') {
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
              style={{ width: '100%' }}
            >
              {Object.keys(uniqueSpecialties).length !== 0 &&
                Object.entries(uniqueSpecialties).map(([specialty, procedures]) => (
                  <Select.OptGroup label={specialty} key={specialty}>
                    {procedures.map(p => (
                      <Select.Option
                        value={`${p}--${specialty}`}
                        key={`${p}--${specialty}`}
                        className="overflow-visible whitespace-normal"
                      >
                        {p}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="What profile would you like to add?">
          {getFieldDecorator('profileId', {
            rules: [
              {
                required: true,
                message: 'Required',
              },
            ],
          })(
            <Select<string>
              notFoundContent={
                loading ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Loading...'} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              showSearch
              placeholder="Select Profile"
              optionFilterProp="children"
              onSelect={(val: string) => setSelectedProfileId(val)}
              onSearch={_.debounce(searchProfiles, 500)}
              filterOption={(input, option) => {
                if (option.props.children && typeof option.props.children === 'string') {
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
              style={{ width: '100%' }}
            >
              {profiles.map(p => (
                <Select.Option value={p.id} key={p.id} className="overflow-visible whitespace-normal">
                  {`${p.displayName} - ${p.organization.name} - ${p.location.name} (${p.type})`}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CreateGroupProfileForm = Form.create<CreateGroupProfileFormProps>({
  name: 'create-group-profile-form',
})(CreateGroupProfileFormComponent);

export const CreateGroupProfileModal: FC<CreateGroupProfileModalProps> = (props): JSX.Element => {
  const { groupId } = props;

  return (
    <Mutation<Data, Variables> mutation={createGroupProfileMutation}>
      {createGroupProfileMutation => {
        return (
          <CreateGroupProfileForm
            {...props}
            createGroupProfile={(profileId: string) => {
              createGroupProfileMutation({
                variables: { createGroupProfileInput: { groupId, profileId } },
              });
            }}
          />
        );
      }}
    </Mutation>
  );
};
