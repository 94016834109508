import { Form, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
const { Option } = Select;

interface Props {
  form: WrappedFormUtils;
  providers: {
    id: string;
    name: string;
  }[];
}

export default class SelectProviderField extends React.Component<Props, {}> {
  render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    const options = {
      ...(this.props.providers.length === 1 && { initialValue: this.props.providers[0].id }),
      ...(true && { rules: [{ required: true, message: 'Required' }] }),
    };

    return (
      <Form.Item label="Who is the sender?">
        {getFieldDecorator(
          'sendingProvider',
          options
        )(
          <Select showSearch placeholder="Sending Provider" optionFilterProp="children">
            {this.props.providers.map(provider => (
              <Option value={provider.id} key={provider.id}>
                {provider.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  }
}
