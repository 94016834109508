import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';
import { Option, Select } from '../../../system/components/Select';
import { PayorPlan } from '../hooks/useGetAllPayorsAndPlansQuery';

interface Props {
  form: WrappedFormUtils;
  initialPayorPlan?: PayorPlan;
  payorPlans: PayorPlan[] | undefined;
  required?: boolean;
}

export const SelectPayorPlan: FC<Props> = ({ form, initialPayorPlan, payorPlans, required = false }): JSX.Element => (
  <Form.Item label="What is your insurance plan?">
    {form.getFieldDecorator('payorPlanId', {
      initialValue: initialPayorPlan?.id,
      rules: [
        {
          required: required,
          message: "Please enter patient's insurance plan.",
        },
      ],
    })(
      <Select
        allowClear
        disabled={!payorPlans}
        filterOption={caseInsensitiveSelectFilterOption}
        placeholder="Select an insurance plan"
        showSearch
      >
        {payorPlans?.map(payorPlan => (
          <Option value={payorPlan.id} key={payorPlan.id}>
            {payorPlan.name}
          </Option>
        ))}
      </Select>
    )}
  </Form.Item>
);
