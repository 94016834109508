import React, { FC } from 'react';
import { Button, Card, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Profile } from '../index';
import { ProfileFormData } from './ProfileForm';
import { SelectGlobal } from './SelectGlobal';
import { SelectIntegrated } from './SelectIntegrated';
import { InputTags } from './InputTags';

interface Props extends FormComponentProps {
  onSubmit: (values: ProfileFormData) => void;
  profile: Profile;
}

const ProfileSettingsAdminFormCardComponent: FC<Props> = ({ form, onSubmit, profile }): JSX.Element => {
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Row>
      <Col xs={18}>
        <Form onSubmit={handleFormSubmit}>
          <Card
            title="Blockit Admin Profile Settings"
            extra={
              <Button key="save" htmlType="submit" type="primary">
                Save Admin Settings
              </Button>
            }
          >
            <Row>
              <Col xs={8}>
                <SelectGlobal form={form} initialValue={profile?.isGlobal} />
              </Col>
              <Col xs={2}></Col>
              <Col xs={8}>
                <SelectIntegrated form={form} initialValue={profile?.isIntegrated} />
              </Col>
              <Col xs={24}>
                <InputTags form={form} initialValue={profile?.tags} />
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export const ProfileSettingsAdminFormCard = Form.create<Props>({ name: 'Domain Settings' })(
  ProfileSettingsAdminFormCardComponent
);
