import * as Sentry from '@sentry/browser';
import { Avatar, Button, Card, Col, Icon, message, Row } from 'antd';
import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { history } from '../../../../core/components/App/history';
import SearchHeaderPatientCard from '../components/SearchHeaderPatientCard';

const updateReferralMutation = gql`
  mutation updateReferral($referralInput: ReferralInput!) {
    updateReferral(ReferralInput: $referralInput) {
      id
      status
    }
  }
`;

const Styles = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  .main-card {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .profile-image {
    border-radius: 0;
    height: 8rem;
    width: 200px;
    background-size: cover;
    margin-bottom: 1rem;
    max-width: 120px;
  }

  .address {
    font-size: 12px;
  }

  .ant-row-flex {
    margin-top: 12px;
  }

  .profile-name {
    font-weight: 800;
  }

  .name-section {
    margin-bottom: 1rem;
  }
`;

export const ConfirmSendToExternal = ({ timeSlot: { profile }, getReferral, formValues, prev }) => {
  const [loading, setLoading] = useState(false);

  const [updateReferral] = useMutation(updateReferralMutation, {
    onCompleted: () => {
      message.success(`Successfully created referral.`).then(
        () => {
          setLoading(false);
          history.replace(`/referrals/${getReferral.id}`);
        },
        () => {}
      );
    },
    onError: error => {
      setLoading(false);
      Sentry.captureException(error);
      message.error('There was an error sending this referral to the profile.  Please try again in a few minutes');
    },
  });

  const handleConfirm = () => {
    setLoading(true);

    // todo - adding diagnosis info here is silly.  it should be added direct to referral in add info step
    updateReferral({
      variables: {
        referralInput: {
          id: getReferral.id,
          status: 'pending',
          description: formValues.notes || null,
        },
      },
    }).then(() => {});
  };

  return (
    <Styles>
      <Row type="flex" justify="space-around" gutter={24} style={{ marginBottom: '2rem' }}>
        <Col span={20}>
          <SearchHeaderPatientCard referral={getReferral} />
        </Col>
        <Col span={4}></Col>
      </Row>
      <Row type="flex" justify="center">
        <Col sm={20} md={20} lg={18} xl={14}>
          <Card title="Confirm Send to Unlisted Provider" className="main-card">
            <Row type="flex" justify="center" gutter={24}>
              <Col>
                <Avatar shape="square" size={128} icon="user" />
              </Col>
              <Col span={16}>
                <div className="name-section">
                  <div className="profile-name">{profile ? profile.displayName : 'Unlisted Provider'}</div>
                  <div>{profile ? profile.specialty : null}</div>
                  <div>{profile ? profile.organization.name : ''}</div>
                </div>

                <p>You are creating a referral to an unlisted provider that is not on the Blockit platform.</p>
                <p>The provider will not receive a notification about this referral.</p>
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={24}>
              <Col>
                <Button className="prev-button" onClick={prev} block>
                  <Icon type="left" /> Back
                </Button>
              </Col>
              <Col>
                <Button className="next-button" onClick={handleConfirm} block type="primary" loading={loading}>
                  Confirm
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Styles>
  );
};
