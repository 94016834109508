import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { Option, Select } from '../../../system/components/Select';
import { useGetLanguagesQuery } from '../hooks/useGetLanguagesQuery';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string[];
}

export const SelectLanguages: FC<Props> = ({ form, initialValue }): JSX.Element => {
  const { data, error, loading } = useGetLanguagesQuery({});

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  const { getLanguages: languages } = data;

  return (
    <Form.Item label="Languages Spoken by Provider or Staff">
      {form.getFieldDecorator('languageCodes', {
        initialValue,
        rules: [],
      })(
        <Select
          mode="multiple"
          allowClear
          placeholder="Select languages"
          filterOption={caseInsensitiveSelectFilterOption}
        >
          {languages.map(language => (
            <Option value={language.code} key={language.code}>
              {language.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};
