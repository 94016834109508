import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PublicProfile } from '../containers/DirectoryContext';

const Styles = styled.div`
  line-height: 1.1;

  .profile-link {
    margin-top: 1em;
  }

  .location-name {
    font-weight: bold;
  }

  .addressInfo {
    font-size: 0.9em;
  }
`;

interface Props {
  profile: PublicProfile;
}

export const ProfileCardAddress: FC<Props> = ({ profile }): JSX.Element => (
  <Styles>
    <ul>
      <li className="location-name">{profile.location.name}</li>
      <li className="addressInfo">{profile.location.address1}</li>
      {profile.location.address2 ? <li className="addressInfo">{profile.location.address2}</li> : null}
      <li className="addressInfo">{`${profile.location.city}, ${profile.location.state} ${profile.location.postalCode}`}</li>
      <li className="profile-link">
        <Link to={`/directory/profiles/${profile.id}`}>View Profile</Link>
      </li>
    </ul>
  </Styles>
);
