import React, { FC, useContext } from 'react';
import { ApolloError } from '@apollo/client';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../core/contexts/AppContext';
import {
  CreateScheduleForOrganizationMutationData,
  useCreateScheduleForOrganizationMutation,
} from '../hooks/useCreateScheduleForOrganizationMutation';
import { ProfileForm, ProfileFormData } from './ProfileForm';

export const CreateProfile: FC = (): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);
  const history = useHistory();

  const handleCreateScheduleForOrganizationCompleted = ({
    createScheduleForOrganization: { id },
  }: CreateScheduleForOrganizationMutationData): void => {
    if (currentOrganization) {
      history.push(`/organizations/${currentOrganization.id}/profiles/${id}/edit`);
    }
  };

  const handleCreateScheduleForOrganizationError = (_error: ApolloError): void => {
    // todo - handle error
    message.error('Something went wrong!  Please try again or contact us if the problem persists.');
  };

  const [createSchedule, { loading }] = useCreateScheduleForOrganizationMutation({
    onCompleted: handleCreateScheduleForOrganizationCompleted,
    onError: handleCreateScheduleForOrganizationError,
  });

  const handleSubmit = (values: ProfileFormData): void => {
    const scheduleInput = {
      displayName: values.displayName,
      locationId: values.locationId,
      hasConsumerScheduling: values.hasConsumerScheduling,
      type: values.type,
      isActive: values.isActive,
      isGlobal: true,
      isIntegrated: true,
      languageCodes: ['en'],
    };

    if (currentOrganization) {
      createSchedule({ variables: { organizationId: currentOrganization.id, scheduleInput } }).then(() => {
        window.scrollTo(0, 0);
        message.success('Success!  Your new profile has been created.', 10);
      });
    }
  };

  return <ProfileForm loading={loading} onSubmit={handleSubmit} />;
};
