import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const getConsumerSchedulingOrganizationQuery = gql`
  query GetConsumerSchedulingOrganizationQuery($id: ID!) {
    getConsumerSchedulingOrganization(id: $id) {
      id
      name
      profileImgUrl
      slug
      hasConsumerSchedulingShowWeekendsFeature
      locationsWithProfiles {
        id
        name
        address1
      }
      consumerSchedulingSettings {
        id
        allowAttachments
        searchInsurance
        visitTypeFormLabel
        noAvailabilityText
        noAvailabilityAction
        htmlHeader
        htmlFooter
        htmlHeadTags
        googleTagManagerId
        showLanguages
        showMap
        searchProviders
        showPatientPrefill
        formFieldSettings {
          field
          required
        }
      }
    }
  }
`;

export interface Location {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  distanceFromPatient: string;
  distanceFromProvider: string;
  latitude: number;
  longitude: number;
  phone: string;
  timeZone: string;
}

export interface Organization {
  id: string;
  name: string;
  profileImgUrl: string | null;
  slug: string | null;
  hasConsumerSchedulingShowWeekendsFeature: boolean;
  locationsWithProfiles: Location[];
  consumerSchedulingSettings?: OrganizationConsumerSchedulingSettings;
}

interface OrganizationConsumerSchedulingSettings {
  id: string;
  allowAttachments: boolean;
  searchInsurance: boolean;
  visitTypeFormLabel: string | null;
  noAvailabilityText: string | null;
  noAvailabilityAction: string | null;
  htmlHeader: string | null;
  htmlFooter: string | null;
  htmlHeadTags: string | null;
  googleTagManagerId: string | null;
  searchProviders: boolean;
  showLanguages: boolean;
  showPatientPrefill: boolean;
  showMap: boolean;
  formFieldSettings: [{ field: string; required: boolean }] | [];
}

interface Data {
  getConsumerSchedulingOrganization: Organization;
}

interface Variables {
  id: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetConsumerSchedulingOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getConsumerSchedulingOrganizationQuery, options);
};
