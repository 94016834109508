import { Col, Input, Row, Tabs } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { FreeOrganizationUserCount } from '../components/FreeOrganizationUserCount';
import { NewUserButton } from '../components/NewUserButton';
import { AppContext } from '../../core/contexts/AppContext';
import { UsersTable } from '../components/UsersTable';

const { Search } = Input;
const { TabPane } = Tabs;

export const Users: FC = (): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);
  const [nameFilter, setNameFilter] = useState('');

  if (!currentOrganization) {
    return <></>;
  }

  return (
    <Row type="flex">
      <Col span={24}>
        <FreeOrganizationUserCount
          organizationId={currentOrganization.id}
          isFree={currentOrganization.isFree}
          freePlanConfiguration={currentOrganization.freePlanConfiguration}
        />
        <Row type="flex" justify="end">
          <Search
            placeholder="Filter by name"
            onChange={value => setNameFilter(value.target.value)}
            style={{ width: 200, paddingRight: 20 }}
          />
          <NewUserButton
            organizationId={currentOrganization.id}
            isFree={currentOrganization.isFree}
            freePlanConfiguration={currentOrganization.freePlanConfiguration}
          />
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Active" key="1">
            <UsersTable active={true} nameFilter={nameFilter} />
          </TabPane>
          <TabPane tab="Inactive" key="2">
            <UsersTable active={false} nameFilter={nameFilter} />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};
