import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getProfileLanguagesInOrganizationQuery = gql`
  query GetProfileLanguagesInOrganizationQuery($organizationId: ID!) {
    getProfileLanguagesInOrganization(organizationId: $organizationId) {
      code
      name
    }
  }
`;

export interface Language {
  code: string;
  name: string;
}

interface Data {
  getProfileLanguagesInOrganization: Language[];
}

interface Variables {
  organizationId: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetProfileLanguagesInOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getProfileLanguagesInOrganizationQuery, options);
};
