import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const getAppointmentUrlQuery = gql`
  query getAppointmentUrl($id: ID!) {
    getAppointmentUrl(id: $id) {
      url
    }
  }
`;

interface AppointmentUrl {
  url: string;
}

interface Data {
  getAppointmentUrl: AppointmentUrl;
}

interface Variables {
  id?: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetAppointmentUrlQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getAppointmentUrlQuery, options);
};
