import { Button, Col, Divider, Pagination, Row, Skeleton } from 'antd';
import { gql } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import styled from 'styled-components';
import { ErrorBoundaryTimeSlots } from '../../../../../components';
import { FeatureFlag, FeatureGate } from '../../../../../helpers';
import { history } from '../../../../core/components/App/history';
import { SearchWizardContainerContext } from '../../../pages/SearchWizardContainer';
import DatesBar from '../components/DatesBar';
import { EmptyResults } from '../components/EmptyResults';
import MapToggleAndSort from '../components/MapToggleAndSort';
import ProfileCard from '../components/ProfileCard';
import ProfileGoogleMap from '../components/ProfileGoogleMap';
import { SearchHeaderExternalRefCard } from '../components/SearchHeaderExternalRefCard';
import SearchHeaderPatientCard from '../components/SearchHeaderPatientCard';
import SlotTimes from '../components/SlotTimes';
import { TagFilters } from '../components/TagFilters';
import BackButton from '../../Scheduling/BackButton';

const GUTTER_SIZE = 16;
const MIN_WIDTH_FOR_SHOW_MAP = 992;

const GET_SLOTS = gql`
  query($profileId: ID!, $referralId: ID!, $start: String, $end: String, $originator: String, $payorPlanId: String) {
    getSlots(
      profileId: $profileId
      referralId: $referralId
      start: $start
      end: $end
      originator: $originator
      payorPlanId: $payorPlanId
    ) {
      id
      status
      start
      end
      slotIdsForAppointment
      serviceType {
        coding {
          code
          display
          system
          version
        }
      }
    }
  }
`;

const Styles = styled.div`
  .profile-row {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    padding-right: 0;

    .ant-btn-primary {
      background-color: ${({ theme }) => theme.blueDarkest};
      border-color: ${({ theme }) => theme.blueDarkest};
    }
  }

  .back-button {
    color: ${({ theme }) => theme.primaryColor};
  }

  .profile-highlighted {
    background: #ececec;
  }

  .no-search-results {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scheduling-not-available {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  .send-to-provider {
    display: flex;
    justify-content: center;
    align-items: center;

    // todo - why doesn't this work without !important???
    .ant-btn-primary {
      background-color: ${props => props.theme.primaryColor} !important;
      border-color: ${props => props.theme.primaryColor} !important;

      :hover {
        background-color: #519ecf !important;
        border-color: #519ecf !important;
      }
    }
  }
`;

const generateDates = (startDate, showMap) => {
  const numberOfDates = showMap ? 5 : 7;
  const dates = [];

  for (let i = 0; i < numberOfDates; i++) {
    const date = _.cloneDeep(startDate);
    date.add(i, 'days');
    dates.push(date);
  }

  // update pointer to end date so we know where to toggle to
  return dates;
};

export const SearchStep = props => {
  const {
    searchSchedules,
    profilesWithUpdatedSlotCounts,
    isSortingByAvailability,
    triggerRerender,
    clearAlreadyRerendered,
    distanceFromPatient,
    setDistanceFromPatient,
    setTextFilter,
    textFilter,
    bufferInsurance,
    bookedByProvider,
  } = useContext(SearchWizardContainerContext);
  const [state, setState] = useState({
    originalStartDate: moment(),
    startDate: moment().startOf('minute'),
    dates: generateDates(moment(), true),
    highlightedProfileId: null,
    selectedOrgId: null,
    selectedLangCode: null,
    schedulesWithCounts: {},
    profiles: [],
  });

  const [showMap, setShowMap] = useState(false);
  // only runs on initial load
  useEffect(() => {
    setShowMap(window.innerWidth > MIN_WIDTH_FOR_SHOW_MAP);
  }, []);

  const handleMoveDateRange = searchFuture => {
    const days = showMap ? 5 : 7;
    const numDaysToIncrement = searchFuture ? days : -1 * days;
    const start = _.cloneDeep(state.startDate).add(numDaysToIncrement, 'days');
    const startDate = start.diff(state.originalStartDate) < 0 ? moment() : start;
    // prevent searching past time slots
    setState({
      ...state,
      startDate,
      dates: generateDates(startDate, showMap),
    });
  };

  const handleSearchFuture = () => {
    handleMoveDateRange(true);
  };

  const handleSearchPast = () => {
    handleMoveDateRange(false);
  };

  const handleSetSelectedOrgId = id => setState({ ...state, selectedOrgId: id });

  const handleSetSelectedLanguage = code => setState({ ...state, selectedLangCode: code });

  const handleToggleMap = checked => {
    setShowMap(checked);
    setState({ ...state, dates: generateDates(state.startDate, checked) });
  };

  const queryParams = queryString.parse(history.location.search);
  const existingFilters = queryParams.filters ? JSON.parse(queryParams.filters) : {};
  const showInsuranceMatch = !existingFilters.payorId || existingFilters.payorId === 'all';

  const changePage = pageNum => {
    const updatedQueryParams = {
      ...queryParams,
      page: pageNum,
    };

    history.push(history.location.pathname + '?' + queryString.stringify(updatedQueryParams));
  };

  const {
    currentOrganization,
    getReferral: { id: referralId, coverage },
    getReferral,
    handleSelectTimeSlot,
    handleSendToPatient,
    handleSendToProvider,
  } = props;

  // There is something utterly fundamentally broken about this whole page that causes it to basically rebuild
  // the entire component

  const [init, setInit] = useState(false);

  // This is garbage. The page tears down and re-builds everything, everytime you change the value of search within
  // textbox EXCEPT when it's blank. This is garbage.
  useEffect(() => {
    const group = getReferral && getReferral.tags && getReferral.tags.find(el => el.key === 'preferred_provider_group');
    if (textFilter !== '' && init === true) {
      return setTextFilter('');
    } else if (group && group.value !== '' && textFilter === '' && init === false) {
      setTextFilter(group.value);
      setInit(true);
    }
  }, [getReferral, setTextFilter, textFilter, init, setInit]);

  const filters = searchSchedules && searchSchedules.filters ? JSON.parse(searchSchedules.filters) : {};
  const { startDate, dates, originalStartDate, selectedOrgId, selectedLangCode } = state;
  const endDate = dates[dates.length - 1];
  const arrowsColSpan = showMap ? 2 : 1;
  const slotsColSpan = showMap ? 4 : 3;

  const profiles = profilesWithUpdatedSlotCounts.map(s => ({
    ...s.profile,
    insuranceNetwork: s.insuranceNetwork,
    insuranceConfidence: s.insuranceConfidence,
  }));

  // apply selected org filter
  let filteredProfiles = profiles.filter(p => {
    const languageCodes = p.languages.map(l => l.code);
    const isSelectedOrg = selectedOrgId === null ? true : p.organization.id === selectedOrgId;
    const isSelectedLang = selectedLangCode === null ? true : languageCodes.includes(selectedLangCode);

    return isSelectedLang && isSelectedOrg;
  });

  // if sorting by availability, we need to add the total available slots count to the profile so we can sort on it
  if (isSortingByAvailability) {
    // call a 5 second timeout to trigger a rerender with slot counts appended
    triggerRerender();
    filteredProfiles = _.sortBy(filteredProfiles, p => (p.isIntegrated ? p.slotCount : -1)).reverse();
  }

  const locations = _.uniq(filteredProfiles.map(fp => fp.location.id));
  const locationsWithMapNumbers = {};

  // number locations
  locations.forEach((l, index) => (locationsWithMapNumbers[l] = index + 1));

  // add map numbers to profiles
  const numberedAndFilteredProfiles = filteredProfiles.map(prof => {
    return { ...prof, mapNumber: locationsWithMapNumbers[prof.location.id] };
  });

  const searchParams = new URLSearchParams(window.location.search);

  return (
    <Styles>
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        {!bookedByProvider && (
          <Col span={24} style={{ textAlign: 'left' }}>
            <BackButton className="back-button" searchParams={searchParams} />
          </Col>
        )}
      </Row>
      <Row type="flex" justify="space-around" gutter={24} style={{ marginBottom: '2rem' }}>
        <Col span={20}>
          <SearchHeaderPatientCard referral={getReferral} />
        </Col>
        <Col span={4}>
          <SearchHeaderExternalRefCard
            referral={getReferral}
            handleSendToProvider={handleSendToProvider}
            accessCenterProfileId={currentOrganization.accessCenterProfileId}
          />
        </Col>
      </Row>
      <Row gutter={GUTTER_SIZE} type="flex" justify="space-between" align="middle" className="affixed-bar">
        <Col span={24}>
          <MapToggleAndSort
            referralId={referralId}
            showMap={showMap}
            showInsuranceFilters={currentOrganization.showInsuranceFilters}
            filters={filters}
            handleToggleMap={handleToggleMap}
            filteredProfiles={filteredProfiles}
            handleSetSelectedOrgId={handleSetSelectedOrgId}
            handleSetSelectedLanguage={handleSetSelectedLanguage}
          />
        </Col>
      </Row>
      <Row gutter={GUTTER_SIZE} type="flex" justify="space-between" align="middle" className="affixed-bar">
        <Col span={8} />
        <Col span={16}>
          <DatesBar
            showMap={showMap}
            dates={state.dates}
            arrowsColSpan={arrowsColSpan}
            slotsColSpan={slotsColSpan}
            handleSearchFuture={handleSearchFuture}
            handleSearchPast={handleSearchPast}
          />
        </Col>
      </Row>
      <Row gutter={GUTTER_SIZE}>
        {numberedAndFilteredProfiles && numberedAndFilteredProfiles.length > 0 ? (
          <>
            <Col span={showMap ? 8 : 0}>
              <FeatureGate feature={FeatureFlag.ReferralTagSearch}>
                <TagFilters filters={filters} />
              </FeatureGate>
              <Row>
                <ProfileGoogleMap profiles={numberedAndFilteredProfiles} />
              </Row>
            </Col>
            <Col span={showMap ? 16 : 24}>
              {numberedAndFilteredProfiles.map(profile => {
                if (!profile.isIntegrated) {
                  return (
                    <div key={profile.id}>
                      <Row gutter={GUTTER_SIZE} key={profile.id} className="profile-row" type="flex" align="middle">
                        <Col span={8}>
                          <ProfileCard
                            slots={[]}
                            currentOrganization={currentOrganization}
                            profile={profile}
                            coverage={coverage}
                            insuranceNetwork={profile.insuranceNetwork}
                            insuranceConfidence={profile.insuranceConfidence}
                            showMap={showMap}
                            handleSendToPatient={handleSendToPatient}
                            handleSendToProvider={handleSendToProvider}
                            showInsuranceMatch={showInsuranceMatch}
                          />
                        </Col>
                        {profile.isSendToProvider ? (
                          <Col span={16} className="send-to-provider">
                            <Button type="primary" onClick={() => handleSendToProvider(profile)}>
                              Send to Provider
                            </Button>
                          </Col>
                        ) : (
                          <Col span={16} className="scheduling-not-available">
                            <span>Provider Schedule Unavailable for Direct Online Booking</span>
                          </Col>
                        )}
                      </Row>
                      {profile.referralSearchNoticeSafe && (
                        <Row>
                          <Col span={24}>
                            <div
                              style={{
                                backgroundColor: '#F3F4F6',
                                color: '#4B5563',
                                paddingLeft: 32,
                                paddingRight: 32,
                                borderRadius: 2,
                                margin: 8,
                                overflow: 'auto',
                                maxHeight: 80,
                              }}
                            >
                              <p
                                style={{ padding: 16 }}
                                dangerouslySetInnerHTML={{ __html: profile.referralSearchNoticeSafe }}
                              />
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Divider />
                    </div>
                  );
                }

                return (
                  <div key={profile.id}>
                    <Row gutter={GUTTER_SIZE} key={profile.id} className="profile-row">
                      <Col span={8}>
                        <ProfileCard
                          currentOrganization={currentOrganization}
                          profile={profile}
                          coverage={coverage}
                          insuranceNetwork={profile.insuranceNetwork}
                          insuranceConfidence={profile.insuranceConfidence}
                          showMap={showMap}
                          handleSendToPatient={handleSendToPatient}
                          handleSendToProvider={handleSendToProvider}
                          showInsuranceMatch={showInsuranceMatch}
                        />
                      </Col>
                      <Col span={16}>
                        <ErrorBoundaryTimeSlots key={`errorBoundary-${profile.id}`}>
                          <Query
                            key={profile.id}
                            query={GET_SLOTS}
                            variables={{
                              profileId: profile.id,
                              referralId: referralId,
                              start: startDate.toISOString(true),
                              end: endDate,
                              originator: 'referral',
                              payorPlanId: bufferInsurance,
                            }}
                          >
                            {({ data, loading, error }) => {
                              if (error) return null;

                              const { getSlots } = data || [];
                              const slots = getSlots || [];
                              const nextAvailabilityDate = new Date(profile.nextAvailability);

                              if (loading) return <Skeleton active={true} />;

                              if (profile.maintenanceMode) {
                                return (
                                  <Col xs={22}>
                                    <Row justify="center" align="middle" type="flex" style={{ height: 200 }}>
                                      <Col span={20}>
                                        Online scheduling is undergoing maintenance and is currently unavailable. We
                                        apologize for the inconvenience.
                                      </Col>
                                    </Row>
                                  </Col>
                                );
                              }

                              if (slots.length === 0) {
                                return (
                                  <Col xs={22}>
                                    <Row justify="center" align="middle" type="flex" style={{ height: 200 }}>
                                      <Col span={8}>
                                        {!profile.nextAvailability ? (
                                          <Button type="primary" className="show-more-button">
                                            {currentOrganization?.consumerSchedulingSettings?.noAvailabilityText
                                              ? currentOrganization.consumerSchedulingSettings.noAvailabilityText
                                              : 'No Availability'}
                                          </Button>
                                        ) : nextAvailabilityDate <= endDate.toDate(true) ? (
                                          <Button
                                            type="primary"
                                            onClick={() => {
                                              if (isSortingByAvailability) {
                                                clearAlreadyRerendered();
                                                triggerRerender();
                                              }
                                              handleSearchFuture();
                                            }}
                                            className="show-more-button"
                                          >
                                            Show More
                                          </Button>
                                        ) : (
                                          <Button
                                            type="primary"
                                            onClick={() =>
                                              setState({
                                                ...state,
                                                startDate: moment(profile.nextAvailability),
                                                dates: generateDates(moment(profile.nextAvailability), showMap),
                                              })
                                            }
                                            className="show-more-button"
                                          >
                                            Next availability is on{' '}
                                            {moment(profile.nextAvailability).format('ddd, MMM Do')}
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                );
                              }

                              return (
                                <SlotTimes
                                  slots={slots}
                                  showMap={showMap}
                                  profile={profile}
                                  referralId={referralId}
                                  startDate={startDate ? startDate.toISOString() : originalStartDate.toISOString()}
                                  dates={state.dates}
                                  arrowsColSpan={arrowsColSpan}
                                  slotsColSpan={slotsColSpan}
                                  handleSelectTimeSlot={handleSelectTimeSlot}
                                />
                              );
                            }}
                          </Query>
                        </ErrorBoundaryTimeSlots>
                      </Col>
                    </Row>
                    {profile.referralSearchNoticeSafe && (
                      <Row>
                        <Col span={24}>
                          <div
                            style={{
                              backgroundColor: '#F3F4F6',
                              color: '#4B5563',
                              paddingLeft: 32,
                              paddingRight: 32,
                              borderRadius: 2,
                              margin: 8,
                              overflow: 'auto',
                              maxHeight: 80,
                            }}
                          >
                            <p
                              style={{ padding: 16 }}
                              dangerouslySetInnerHTML={{ __html: profile.referralSearchNoticeSafe }}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Divider />
                  </div>
                );
              })}
              <Pagination
                defaultCurrent={1}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                current={searchSchedules.pageNumber}
                className="profile-pagination"
                pageSize={searchSchedules.pageSize}
                total={searchSchedules.totalEntries}
                hideOnSinglePage={false}
                onChange={val => {
                  if (isSortingByAvailability) {
                    clearAlreadyRerendered();
                    triggerRerender();
                  }
                  changePage(val);
                }}
              />
            </Col>
          </>
        ) : (
          <FeatureGate
            feature={FeatureFlag.ReferralTagSearch}
            fallbackContent={
              <EmptyResults setDistanceFromPatient={setDistanceFromPatient} distanceFromPatient={distanceFromPatient} />
            }
          >
            <Row>
              <Col span={8}>
                <TagFilters filters={filters} />
              </Col>
              <Col span={16}>
                <EmptyResults
                  setDistanceFromPatient={setDistanceFromPatient}
                  distanceFromPatient={distanceFromPatient}
                />
              </Col>
            </Row>
          </FeatureGate>
        )}
      </Row>
    </Styles>
  );
};
