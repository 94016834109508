import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const Styles = styled.div`
  .empty-button {
    border: 0;
    box-shadow: none;
    font-weight: bold;
    height: 38px;
    margin-bottom: 4px;
    margin-top: 4px;
    text-align: center;
    text-decoration: none solid;
    text-transform: uppercase;
    width: 80px;
  }
`;

export const EmptySlotButton: FC = (): JSX.Element => (
  <Styles>
    <Button block className="empty-button">
      —
    </Button>
  </Styles>
);
