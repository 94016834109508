import { useMutation, MutationTuple } from '@apollo/client';
import { gql } from '@apollo/client';

interface UpsertScheduleData {
  UpsertSchedule: {
    id: string;
    scheduleId: string;
  };
}

interface UpsertScheduleVariables {
  UpsertScheduleInput: {
    organizationId: string;
    displayName: string;
    firstName: string;
    lastName: string;
    locationId: string;
    phone: string;
    type: string;
    languages: [];
    isActive: boolean;
    isIntegrated: boolean;
  };
}

const UpsertScheduleMutation = gql`
  mutation UpsertSchedule($UpsertScheduleInput: UpsertScheduleInput!) {
    UpsertSchedule(UpsertScheduleInput: $UpsertScheduleInput) {
      id
      scheduleId
    }
  }
`;

type UpsertScheduleMutationResult = MutationTuple<UpsertScheduleData, UpsertScheduleVariables>;

export const useUpsertScheduleMutation = (): UpsertScheduleMutationResult => {
  return useMutation<UpsertScheduleData, UpsertScheduleVariables>(UpsertScheduleMutation);
};
