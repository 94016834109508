import { createContext } from 'react';

export interface DirectoryContextInterface {
  directory: Directory;
  organizationNameFilter: string;
  profileNameFilter: string;
  selectedOrganizationId: string;
  selectedSpecialtyId: string;
  setOrganizationNameFilter: (filter: string) => void;
  setProfileNameFilter: (filter: string) => void;
  setSelectedOrganizationId: (id: string) => void;
  setSelectedSpecialtyId: (id: string) => void;
}

export interface Directory {
  organizations: Organization[];
  profiles: PublicProfile[];
  specialties: Specialty[];
}

export interface Location {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface Organization {
  id: string;
  name: string;
  profileImgUrl: string;
  specialties: Specialty[];
}

export interface PublicProfile {
  id: string;
  displayName: string;
  email?: string;
  phone?: string;
  specialty?: string;
  profileImgUrl: string;
  boardCertificationsSafe?: string;
  educationTrainingSafe?: string;
  professionalStatementSafe?: string;
  location: Location;
  organization: Organization;
  specialties: Specialty[];
}

export interface Specialty {
  id: string;
  name: string;
}

export const DirectoryContext = createContext<DirectoryContextInterface>({
  directory: { organizations: [], profiles: [], specialties: [] },
  organizationNameFilter: '',
  profileNameFilter: '',
  selectedOrganizationId: '',
  selectedSpecialtyId: '',
  setOrganizationNameFilter: () => {},
  setProfileNameFilter: () => {},
  setSelectedOrganizationId: () => {},
  setSelectedSpecialtyId: () => {},
});
