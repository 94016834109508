import React, { FC, useContext } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { ErrorStep } from '../components/ErrorStep';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';

interface LocationState {
  profile?: Profile;
}

export const ConsumerSchedulingErrorPage: FC<RouteComponentProps<{}, StaticContext, LocationState>> = ({
  location,
}): JSX.Element => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const profile = location?.state?.profile;

  if (!profile) {
    return <Redirect to={`/consumer/${organization.slug ? organization.slug : organization.id}${location.search}`} />;
  }

  return <ErrorStep organization={organization} profile={profile} />;
};
