import { gql } from '@apollo/client';

export const DeleteReferralOrder = gql`
  mutation deleteReferralOrder($id: ID!) {
    deleteReferralOrder(id: $id) {
      id
      displayFilename
    }
  }
`;
