import { Row, Col } from 'antd';
import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../../../themes/default';
import AntDesignStyleOverrides from '../../core/components/App/AntDesignStyleOverrides';

interface Props {
  pelitas?: boolean;
}

const Styles = styled.div`
  min-height: 100vh;
  height: 100%;

  .blockit-logo {
    margin-bottom: 2rem;
    width: 100%;
  }

  .logo-divider {
    color: #fff;
    font-size: 48px;
    margin-top: -40px;
    text-align: center;
  }

  .pelitas-logo {
    margin-bottom: 0;
    width: 100%;
  }

  #body {
    background-color: #ffffff;
    min-height: 100%;
    height: 100%;
    background-color: ${props => props.theme.primaryColor};
  }

  #left {
    padding: 2rem 1rem 2rem 1rem;
    background-color: ${props => props.theme.primaryColor};
  }

  @media only screen and (min-width: 768px) {
    #left {
      min-height: 100vh;
    }
  }

  #right {
    min-height: 100vh;
    height: 100%;
    padding: 4rem 1rem 4rem 1rem;
    background-color: #ffffff;
  }

  #title {
    color: #003754;
    font-weight: 400;
  }

  h2 {
    text-align: center;
  }

  h1 {
    color: #ffffff;
    text-align: center;
  }

  p {
    color: #d8e7f0;
    font-size: 18px;
    text-align: center;
  }
`;

export const SignupLayout: FC<Props> = ({ children, pelitas }): JSX.Element => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <AntDesignStyleOverrides>
        <Styles>
          <Row type="flex" id="body">
            <Col xs={24} sm={24} md={10} id="left">
              <Row type="flex" justify="center" align="middle" gutter={16}>
                <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                  <img src="/images/logo.png" alt="Blockit" className="blockit-logo" />
                </Col>
                {pelitas ? (
                  <>
                    <Col xl={2} className="logo-divider">
                      +
                    </Col>
                    <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                      <img src="/images/pelitas-logo.png" alt="Pelitas" className="pelitas-logo" />
                    </Col>
                  </>
                ) : null}
              </Row>

              <Row type="flex" justify="center" align="middle">
                <Col xs={0} sm={0} md={18} lg={18} xl={20} style={{ paddingTop: '2rem' }}>
                  <img src="/images/signup-hero.png" alt="banner" />
                </Col>
              </Row>

              <Row type="flex" justify="center" align="middle">
                <Col xs={0} sm={0} md={24} lg={24} xl={20} style={{ paddingTop: '4rem' }}>
                  <h1>No cost COVID-19 Scheduling</h1>
                  <p>
                    Deploy Blockit TODAY to efficiently route patients for COVID-19 testing and vaccination.
                    <br />
                    Go live in minutes at NO COST. HIPAA-compliant environment.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={14} id="right">
              {children}
            </Col>
          </Row>
        </Styles>
      </AntDesignStyleOverrides>
    </ThemeProvider>
  );
};
