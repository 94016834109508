import { Alert, Divider } from 'antd';
import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { Query } from '@apollo/client/react/components';
import { Spinner } from '../../../components';

export const CODE_SYSTEM_LOOKUP = gql`
  query($profileId: ID!, $referralId: ID!) {
    codeSystemLookup(profileId: $profileId, referralId: $referralId) {
      name
      valueString
    }
  }
`;

const renderInfo: FC<{ name: string; valueString: string }> = ({ name, valueString }) => (
  <div key={name} className="mb-2">
    <Alert
      message="Patient Instructions"
      description={
        <pre
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: '11px',
            fontFamily: '"Lucida Console", Monaco, monospace',
          }}
        >
          {valueString}
        </pre>
      }
      type="info"
      showIcon
    />
  </div>
);

const renderInfos: FC<Data> = ({ codeSystemLookup }) => {
  const children = codeSystemLookup.map(renderInfo);

  if (children.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="supporting-infos">{children}</div>
      <Divider />
    </>
  );
};

interface Props {
  profileId: string;
  referralId: string;
}

interface Data {
  codeSystemLookup: {
    name: string;
    valueString: string;
  }[];
}

export const CodeSystemLookup: FC<Props> = ({ profileId, referralId }) => (
  <Query<Data, Props>
    query={CODE_SYSTEM_LOOKUP}
    variables={{
      profileId: profileId,
      referralId: referralId,
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Spinner />;
      if (error || !data) return null;
      return renderInfos(data);
    }}
  </Query>
);
