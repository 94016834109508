import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Switch } from 'antd';
import { defaultTheme } from '../../../themes/default';

interface Props {
  defaultChecked: boolean;
  onClick: (checked: boolean, event: MouseEvent) => void;
}

const Styles = styled.div`
  .show-weekends-label {
    color: ${({ theme }) => theme.greyDarker};
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    padding-right: 8px;
    text-transform: uppercase;
  }
`;

export const ShowWeekends: FC<Props> = ({ defaultChecked, onClick }): JSX.Element => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <span className="show-weekends-label">Show Weekends:</span>
        <Switch defaultChecked={defaultChecked} onClick={onClick} />
      </Styles>
    </ThemeProvider>
  );
};
