import React, { FC } from 'react';
import { List, Button, Tag } from 'antd';
import styled from 'styled-components';
import {
  TaskStatus,
  useAcceptTaskMutation,
  useFinishTaskMutation,
  WorkflowQueueTask,
} from '../../../generated/graphql';
import { formatPhone } from '../../../helpers';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

const Styles = styled.div``;

export const ReferralWorkflowList: FC<{
  tasks: WorkflowQueueTask[];
  viewerWatching: boolean;
  showScheduleButton?: boolean;
  showAcceptButton?: boolean;
  showDetails?: boolean;
  showRelatedQueues?: boolean;
  header?: string | JSX.Element | null;
}> = ({
  tasks,
  viewerWatching,
  showScheduleButton = true,
  showAcceptButton = true,
  showRelatedQueues = true,
  showDetails = true,
  header = null,
}): JSX.Element => {
  return (
    <Styles>
      <List
        header={header}
        bordered={false}
        dataSource={tasks}
        renderItem={task => (
          <ReferralWorkflowListItem
            task={task}
            showScheduleButton={showScheduleButton}
            showAcceptButton={showAcceptButton}
            showRelatedQueues={showRelatedQueues}
            viewerWatching={viewerWatching}
            showDetails={showDetails}
          />
        )}
      />
    </Styles>
  );
};

interface SBProps {
  id: string;
  viewerWatching: boolean;
  viewerIsOwner: boolean;
  task: WorkflowQueueTask;
}

const WorkflowTaskButton: FC<SBProps> = ({ id, viewerWatching, viewerIsOwner, task }): JSX.Element | null => {
  const [acceptTask] = useAcceptTaskMutation();
  const [finishTask] = useFinishTaskMutation();

  if (task.status === TaskStatus.Ready)
    return (
      <>
        <Button
          type="primary"
          style={{ marginRight: 12 }}
          onClick={() => {
            acceptTask({
              variables: {
                taskId: id,
              },
            });
          }}
        >
          Accept
        </Button>
      </>
    );
  if (task.status === TaskStatus.Inprogress && viewerIsOwner)
    return (
      <Button
        type="primary"
        style={{ marginRight: 12 }}
        onClick={() => {
          finishTask({
            variables: {
              taskId: id,
            },
          });
        }}
      >
        Finish
      </Button>
    );
  if (task.status === TaskStatus.Inprogress && !viewerIsOwner) return <span>Accepted by {task.user?.name}</span>;

  if (task.status === TaskStatus.Finished) return <span>Finished</span>;

  return null;
};

const getReferredTo = (task: WorkflowQueueTask): string => {
  if (!task?.referral) return '';

  if (task.referral.appointment) {
    return `/ Referred to ${task.referral.appointment.profile.displayName}` || '';
  } else if (task.referral.patientReferral) {
    return `/ Referred to ${task.referral.patientReferral?.profile?.displayName || ''}`;
  } else if (task.referral.profileReferral) {
    return `/ Referred to ${task.referral.profileReferral?.profile?.displayName}` || '';
  } else if (task.referral.tags) {
    const preferred = task.referral?.tags?.find(item => item && item.key === 'preferred_provider');
    if (preferred) {
      return `/ Referred to ${preferred?.value}` || '';
    } else {
      return '';
    }
  } else {
    return '';
  }
};

const ReferralWorkflowListItem: FC<{
  task: WorkflowQueueTask;
  viewerWatching: boolean;
  showScheduleButton: boolean;
  showAcceptButton: boolean;
  showRelatedQueues: boolean;
  showDetails?: boolean;
}> = ({
  task,
  viewerWatching,
  showScheduleButton,
  showAcceptButton,
  showRelatedQueues,
  showDetails = true,
}): JSX.Element | null => {
  const [acceptTask] = useAcceptTaskMutation();
  const [finishTask] = useFinishTaskMutation();

  if (!task) return null;

  const patient = task?.referral?.patient;

  const phone = formatPhone(patient?.phone || '');

  const referredTo = getReferredTo(task);

  const ScheduleButton = (): JSX.Element | null => {
    return (
      <>
        {showAcceptButton && (
          <WorkflowTaskButton
            id={task.id}
            key="startBtn"
            viewerWatching={viewerWatching}
            task={task}
            viewerIsOwner={task.viewerIsOwner}
          />
        )}
        {showScheduleButton && task?.referral?.status === 'draft' && (
          <Button
            type="primary"
            onClick={async () => {
              await acceptTask({
                variables: {
                  taskId: task.id,
                },
              });
              await finishTask({
                variables: {
                  taskId: task.id,
                },
              });
              window.location.href = `/referrals/${task.referral?.id}/search`;
            }}
          >
            Schedule
          </Button>
        )}
      </>
    );
  };

  const actions = [<ScheduleButton key="schedule" />];

  if (showDetails)
    actions.push(
      <Link to={`/referrals/${task.referral?.id}`} key="link">
        <Button>Details</Button>
      </Link>
    );

  return (
    <List.Item actions={actions} className="item">
      <List.Item.Meta
        key={task.id}
        title={
          <>
            <div>
              <span style={{ fontSize: 16 }}>
                {patient?.givenName1} {patient?.familyName} ({task?.referral?.procedure?.name})
              </span>
              <span style={{ fontSize: 12, paddingLeft: 8 }}>
                {moment
                  .default(moment.utc(task.referral?.insertedAt || ''))
                  .local()
                  .format('MM/DD/YYYY @ h:mmA')}
              </span>
            </div>
          </>
        }
        description={
          <>
            <span>{`${phone || 'Unknown Phone'} / Referred by ${task.referral?.provider.name} ${referredTo}`}</span>{' '}
            <div style={{ paddingTop: 8 }}>
              <ul>
                {task.referral?.tasks?.map(relatedTask => {
                  if (relatedTask?.id === task.id || !showRelatedQueues) return null;
                  return (
                    <li key={relatedTask?.id} style={{ paddingTop: 1 }}>
                      <Tag>{relatedTask?.workflowQueue?.name}</Tag>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        }
      />
    </List.Item>
  );
};
