import React from 'react';
import { PrivateRoute } from '../core';
import Referral from './pages/Referral';
import ReferralsContainer from './pages/ReferralsContainer';
import SchedulingContainer from './pages/SchedulingContainer';
import { SearchWizardContainer } from './pages/SearchWizardContainer';

export { SchedulingContainer, ReferralsContainer };

// Note: React Router v6 does not support optional path parameters using a trailing '?'.
export const routes = [
  <PrivateRoute
    exact
    key="route--search-referrals"
    path="/referrals/:id/search/:organizationId?"
    component={SearchWizardContainer}
  />,
  <PrivateRoute
    exact
    key="route--search-referrals"
    path="/referrals/:id/search/:organizationId?/profiles/:profileId"
    component={SearchWizardContainer}
  />,
  <PrivateRoute exact key="route--org-referrals" path="/organizations/:id/referrals" component={ReferralsContainer} />,
  <PrivateRoute
    exact
    key="route--org-referrals-new"
    path="/organizations/:id/referrals/new"
    component={SchedulingContainer}
  />,
  <PrivateRoute exact path="/referrals/new" key="route--org-referrals-new" component={SchedulingContainer} />,

  <PrivateRoute exact path="/referrals/:id" key="route--referrals" component={Referral} />,
  // todo - fixme - why do we have two routes for the same component?
  <PrivateRoute path="/referrals" key="route--referrals-index" component={ReferralsContainer} />,
];
