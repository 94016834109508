import { Button, Col, Input, Row, Switch, Tree, Modal } from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { IsOrgAdminOrBlockitAdmin } from '../../../components/IsOrgAdminOrBlockitAdmin';
import { deletePaginatedCacheItems, formatPhone, validateEmail } from '../../../helpers';
import { AppContext } from '../../core/contexts/AppContext';
import { history } from '../../core/components/App/history';

const { TreeNode } = Tree;

const EditUser = gql`
  mutation EditUser(
    $organizationId: ID!
    $userId: ID!
    $name: String!
    $email: String!
    $phone: String
    $isAdmin: Boolean
    $isEditor: Boolean
    $isActive: Boolean
    $isActiveUserOrganization: Boolean!
    $hasAllLocations: Boolean!
    $locationIds: [String]
  ) {
    EditUser(
      organizationId: $organizationId
      userId: $userId
      name: $name
      email: $email
      phone: $phone
      isAdmin: $isAdmin
      isEditor: $isEditor
      isActive: $isActive
      isActiveUserOrganization: $isActiveUserOrganization
      hasAllLocations: $hasAllLocations
      locationIds: $locationIds
    ) {
      id
      name
      email
      phone
      isAdmin(organizationId: $organizationId)
      isEditor(organizationId: $organizationId)
      hasAllLocations(organizationId: $organizationId)
      isActive
      locations(organizationId: $organizationId) {
        id
        name
      }
    }
  }
`;

interface User {
  id: string;
  name: string;
  email: string;
  phone: string;
  hasAllLocations: boolean;
  isAdmin: boolean;
  isEditor: boolean;
  isActive: boolean;
  locations: {
    id: string;
    name: string;
  }[];
  userOrganizations: {
    organizationId: string;
  }[];
}

interface Props {
  user: User;
  organizationLocations: {
    id: string;
    name: string;
    address1: string;
  }[];
}

interface Variables {
  userId: string;
  name: string;
  email: string;
  phone: string;
  isAdmin: boolean;
  isEditor: boolean;
  isActive: boolean;
  organizationId: string;
  hasAllLocations: boolean;
  isActiveUserOrganization: boolean;
  locationIds: string[];
}

const getUserLocationIds = (
  allLocationIds: string[],
  userLocationIds: string[],
  hasAllLocations: boolean
): string[] => {
  if (hasAllLocations) {
    return allLocationIds;
  }

  return userLocationIds;
};

export const EditUserForm: FC<Props> = ({ user, organizationLocations }) => {
  const userLocationIds = user.locations.map(location => location.id) || [];
  const organizationLocationIds = organizationLocations.map(o => o.id);
  const initialUserLocations = getUserLocationIds(
    organizationLocationIds,
    userLocationIds,
    user.hasAllLocations || false
  );
  const { currentOrganization, viewer } = useContext(AppContext);
  const [updateUserMutation] = useMutation<User, Variables>(EditUser);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email.toLowerCase());
  const [phone, setPhone] = useState(user.phone ? formatPhone(user.phone) : '');
  const [isAdmin, setIsAdmin] = useState(user.isAdmin || false);
  const [isEditor, setIsEditor] = useState(user.isEditor || false);
  const [isActive, setIsActive] = useState(user.isActive);
  const [isActiveUserOrganization, setIsActiveUserOrganization] = useState(true);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const [busy, setBusy] = useState(false);
  const [selectedLocationIds, setSelectedLocationIds] = useState(initialUserLocations);
  const [errors, setErrors] = useState<string[]>([]);

  if (!currentOrganization) {
    return <></>;
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    if (!isAdmin && selectedLocationIds.length === 0) {
      setErrors(['User must be assigned to at least 1 location if they are not an admin.']);
      return;
    }

    if (validateEmail(email.valueOf()) !== '') {
      setErrors(['Email address invalid']);
      return;
    }

    if (name.length < 3) {
      setErrors(['Name must be at least 3 characters']);
      return;
    }

    setBusy(true);

    try {
      // greater than or equal since 'all locations' might be in the list and we don't count that
      const locationIds = selectedLocationIds;
      const hasAllLocations = selectedLocationIds.length >= organizationLocations.length;

      await updateUserMutation({
        variables: {
          userId: user.id,
          name,
          email,
          phone,
          isAdmin,
          isEditor,
          isActive,
          isActiveUserOrganization,
          organizationId: currentOrganization.id,
          hasAllLocations,
          locationIds,
        },
        update: cache => deletePaginatedCacheItems(cache, 'PaginatedUsers'),
      });
      setBusy(false);
      setErrors([]);
      history.push(`/organizations/${currentOrganization.id}/users`);
    } catch (error) {
      const graphQLErrors = (error as { graphQLErrors?: { message: string }[] }).graphQLErrors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        setErrors(graphQLErrors.map((gError: { message: string }) => gError.message));
      } else {
        setErrors(['Unknown network error, try again']);
      }
    }
  };

  return (
    <Styles>
      <form>
        <Row gutter={24}>
          <Col span={12}>
            {errors.length > 0
              ? errors.map(e => (
                  <div style={{ color: '#c53030' }} key={e}>
                    {e}
                  </div>
                ))
              : null}
            <div>
              <label htmlFor="name">Name</label>
              <Input name="name" type="text" value={name} onChange={e => setName(e.target.value)} />
            </div>
            <br />
            <div>
              <label htmlFor="email">Email</label>
              <Input name="email" type="email" value={email} onChange={e => setEmail(e.target.value.toLowerCase())} />
            </div>
            <br />
            <div>
              <label htmlFor="phone">Phone</label>
              <Input name="phone" type="text" value={phone} onChange={e => setPhone(e.target.value)} />
            </div>
            <br />
            {viewer?.blockitAdmin && user?.userOrganizations.length > 1 && (
              <div>
                <label htmlFor="isActiveUserOrg">Remove user from this organization</label>
                <Switch
                  style={{ marginLeft: '5px' }}
                  checked={!isActiveUserOrganization}
                  onChange={checked => {
                    if (checked) {
                      setIsConfirmModalVisible(true);
                    } else {
                      setIsActiveUserOrganization(!checked);
                    }
                  }}
                />

                <Modal
                  title="Confirm Deactivation"
                  visible={isConfirmModalVisible}
                  onOk={() => {
                    setIsActiveUserOrganization(false);
                    setIsConfirmModalVisible(false);
                  }}
                  onCancel={() => {
                    setIsConfirmModalVisible(false);
                    setIsActiveUserOrganization(true);
                  }}
                  okText="Remove User"
                  cancelText="Cancel"
                >
                  <p>Are you sure you want to deactivate this user from this specific organization?</p>
                </Modal>
              </div>
            )}
            <IsOrgAdminOrBlockitAdmin>
              <>
                <br />
                <div>
                  <label htmlFor="isActive">Activate/Inactivate user from ALL organizations</label>
                  <Switch style={{ marginLeft: '5px' }} checked={isActive} onChange={checked => setIsActive(checked)} />
                </div>
                <br />
                <div>
                  <label htmlFor="isAdmin">Is this user an admin?</label>
                  <Switch style={{ marginLeft: '5px' }} checked={isAdmin} onChange={checked => setIsAdmin(checked)} />
                </div>
                <br />
                <div>
                  <label htmlFor="isEditor">Is this user an editor?</label>
                  <Switch style={{ marginLeft: '5px' }} checked={isEditor} onChange={checked => setIsEditor(checked)} />
                </div>
              </>
            </IsOrgAdminOrBlockitAdmin>
            <br />

            <Button
              type="primary"
              onClick={event => {
                handleSubmit(event as React.MouseEvent<HTMLButtonElement>);
                if (!isActiveUserOrganization) {
                  history.push(`/organizations/${currentOrganization.id}/users`);
                }
              }}
              loading={busy}
            >
              Update User
            </Button>
          </Col>
          <Col span={12}>
            <div>
              <label htmlFor="locations">Locations</label>
              <Tree
                checkable
                autoExpandParent={true}
                defaultExpandAll={true}
                onCheck={checkedLocations => {
                  if (Array.isArray(checkedLocations)) setSelectedLocationIds(checkedLocations);
                }}
                checkedKeys={selectedLocationIds}
              >
                <TreeNode title="All Locations" key="allLocations">
                  {organizationLocations.map(location => {
                    return <TreeNode title={location.name + ' | ' + location.address1} key={location.id} />;
                  })}
                </TreeNode>
              </Tree>
            </div>
          </Col>
        </Row>
      </form>
    </Styles>
  );
};

const Styles = styled.div`
  label {
    color: #718096;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.025em;
  }
`;
