import React, { FC, useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { AppContext } from '../../core/contexts/AppContext';
import { InputEmail } from './InputEmail';
import { InputPhone } from './InputPhone';
import { Profile } from '../index';
import { ProfileFormData } from './ProfileForm';
import { SelectLocation } from './SelectLocation';
import { Spinner } from '../../../components';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props extends FormComponentProps {
  onSubmit: (values: ProfileFormData) => void;
  profile: Profile;
}

const ProfileSettingsContactFormCardComponent: FC<Props> = ({ form, onSubmit, profile }): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  if (!currentOrganization) {
    return <Spinner />;
  }

  return (
    <Row>
      <Col xs={18}>
        <Form onSubmit={handleFormSubmit}>
          <Card
            title="Contact Info"
            extra={
              <BlockRestrictedUsers>
                <Button htmlType="submit" type="primary">
                  Save Contact Info
                </Button>
              </BlockRestrictedUsers>
            }
          >
            <Row>
              <Col xs={12}>
                <InputPhone form={form} initialValue={profile?.phone} />
                <InputEmail form={form} initialValue={profile?.email} />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectLocation
                  form={form}
                  initialValue={profile?.location.id}
                  organizationId={currentOrganization?.id || ''}
                />
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export const ProfileSettingsContactFormCard = Form.create<Props>({ name: 'Domain Settings' })(
  ProfileSettingsContactFormCardComponent
);
