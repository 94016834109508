import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { ReferralWorkflowCard } from './ReferralWorkflowCard';

interface Props {
  organizationId: string;
}

export const ReferralSettingsCard: FC<Props> = ({ organizationId }): JSX.Element | null => {
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  if (!data?.getOrganization) return null;

  return (
    <Row gutter={[24, 24]}>
      <Col span={18} push={1}>
        <ReferralWorkflowCard organizationId={organizationId} />
      </Col>
    </Row>
  );
};
