import React, { FC } from 'react';
import { Select } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { ValidateQuestionnaire, Question } from './QuestionnaireForm';

interface Props extends FormComponentProps {
  question: Question;
  validator: ValidateQuestionnaire;
}

export const QuestionSelect: FC<Props> = ({ form, question, validator }): JSX.Element => (
  <Form.Item label={question.text} extra={question.helpText}>
    {form.getFieldDecorator(`questionnaire[0].${question.text.replace(/\./g, ' ')}`, {
      rules: [{ required: question.required, message: 'required' }, { validator: validator }],
      initialValue: question.initialValue,
    })(
      <Select dropdownMatchSelectWidth={false}>
        {question.options?.map(o => (
          <Select.Option key={o}>{o}</Select.Option>
        ))}
      </Select>
    )}
  </Form.Item>
);
