import { Button } from 'antd';
import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../../../themes/default';
import { Slot } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';

interface Props {
  disabled: boolean;
  onClick: () => void;
  slot?: Slot;
}

const Styles = styled.div`
  .more-slots-button {
    background-color: ${({ theme }) => theme.blueDarkest};
    background-size: cover;
    color: ${({ theme }) => theme.white};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    height: 38px;
    margin-bottom: 4px;
    margin-top: 4px;
    text-align: center;
    text-decoration: none solid;
    text-transform: uppercase;
    width: 80px;
  }
`;

export const MoreSlotsButton: FC<Props> = ({ disabled, onClick }): JSX.Element => (
  <ThemeProvider theme={defaultTheme}>
    <Styles>
      <Button className="more-slots-button" disabled={disabled} onClick={() => onClick()}>
        More
      </Button>
    </Styles>
  </ThemeProvider>
);
