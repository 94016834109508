import { Col, Collapse, Icon, Row } from 'antd';
import { DateTime } from 'luxon';
import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import AntDesignStyleOverrides from '../../core/components/App/AntDesignStyleOverrides';
import { defaultTheme } from '../../../themes/default';
import { Location, Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { ConsumerSchedulingMap } from './ConsumerSchedulingMap';
import { DatesBar } from './DatesBar';
import { FilterForm } from './FilterForm';
import { JumpToDate } from './JumpToDate';
import { SearchProviderName } from './SearchProviderName';
import { ProfileSortType } from './SelectProfilesSort';
import { SelectProfilesLanguage } from './SelectProfilesLanguage';
import { ShowMap } from './ShowMap';
import { ShowWeekends } from './ShowWeekends';

interface Props {
  dates: DateTime[];
  defaultLanguageCode: string;
  defaultSearchProvider: string | undefined;
  defaultShowWeekends: boolean;
  disableSortByDistance?: boolean;
  locationId: string;
  locations: Location[];
  onClickFuture: () => void;
  onClickPast: () => void;
  onClickShowMap: (checked: boolean, event: MouseEvent) => void;
  onClickShowWeekends: (checked: boolean, event: MouseEvent) => void;
  onSearchProvider: (value: string, event?: ChangeEvent | React.MouseEvent | KeyboardEvent) => void;
  onSelectLanguage: (value: string) => void;
  onSelectLocation: (value: string) => void;
  onSelectSort: (value: ProfileSortType) => void;
  onSelectStart: (date: DateTime) => void;
  organization: Organization;
  profiles: (Profile & { mapNumber: number })[];
  showMap: boolean | null;
  sort: ProfileSortType;
}

const { Panel } = Collapse;

const Styles = styled.div`
  .ant-collapse-extra {
    float: left !important;
  }

  .filter-collapse {
    background-color: ${({ theme }) => theme.white};
    padding-bottom: 20px;
  }

  .jump-to-date,
  .show-map,
  .show-weekends {
    padding-bottom: 20px;
    text-align: right;
  }

  .map {
    margin-bottom: 20px;
  }
`;

const FilterIcon = (): JSX.Element => <Icon type="filter" style={{ fontSize: '16px', paddingRight: '8px' }} />;

export const SearchStepFilterBar: FC<Props> = ({
  dates,
  defaultLanguageCode,
  defaultSearchProvider,
  defaultShowWeekends,
  disableSortByDistance = false,
  locationId,
  locations,
  onClickFuture,
  onClickPast,
  onClickShowMap,
  onClickShowWeekends,
  onSearchProvider,
  onSelectLanguage,
  onSelectLocation,
  onSelectSort,
  onSelectStart,
  organization,
  profiles,
  showMap,
  sort,
}): JSX.Element => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Row gutter={16} type="flex">
          <Col xs={0} lg={8}>
            <AntDesignStyleOverrides>
              {organization.consumerSchedulingSettings?.searchProviders && (
                <SearchProviderName defaultValue={defaultSearchProvider} onSearch={onSearchProvider} />
              )}
            </AntDesignStyleOverrides>
          </Col>
          <Col xs={0} lg={4}>
            <AntDesignStyleOverrides>
              {organization.consumerSchedulingSettings?.showLanguages && (
                <SelectProfilesLanguage defaultValue={defaultLanguageCode} onSelect={onSelectLanguage} />
              )}
            </AntDesignStyleOverrides>
          </Col>
          <Col xs={0} lg={4} className="jump-to-date">
            <JumpToDate onChange={onSelectStart} />
          </Col>
          <Col xs={0} lg={4} className="show-weekends">
            <ShowWeekends defaultChecked={defaultShowWeekends} onClick={onClickShowWeekends} />
          </Col>
          <Col xs={0} lg={4} className="show-map">
            {organization.consumerSchedulingSettings?.showMap && (
              <ShowMap checked={showMap === true || showMap === null} onClick={onClickShowMap} />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={0}>
            <Collapse bordered={false} className="filter-collapse">
              <Panel header="Filters" key="filters-panel" extra={FilterIcon()}>
                <FilterForm
                  defaultLanguageCode={defaultLanguageCode}
                  defaultSearchProvider={defaultSearchProvider}
                  defaultShowWeekends={defaultShowWeekends}
                  disableSearchProviders={organization.consumerSchedulingSettings?.searchProviders}
                  disableShowLanguages={organization.consumerSchedulingSettings?.showLanguages}
                  disableSortByDistance={disableSortByDistance}
                  disableShowMap={!organization.consumerSchedulingSettings?.showMap}
                  locationId={locationId}
                  locations={locations}
                  onClickShowMap={onClickShowMap}
                  onClickShowWeekends={onClickShowWeekends}
                  onSearchProvider={onSearchProvider}
                  onSelectLanguage={onSelectLanguage}
                  onSelectLocation={onSelectLocation}
                  onSelectSort={onSelectSort}
                  onSelectStart={onSelectStart}
                  showMap={showMap === true}
                  sort={sort}
                />
              </Panel>
            </Collapse>
          </Col>
          {showMap === true ? (
            <Col xs={24} lg={0} className="map">
              <ConsumerSchedulingMap profiles={profiles} />
            </Col>
          ) : null}
          <Col xs={24} lg={0}>
            <DatesBar
              dates={dates}
              onClickFuture={onClickFuture}
              onClickPast={onClickPast}
              showMap={showMap === true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={0} lg={6}>
            <FilterForm
              defaultLanguageCode={defaultLanguageCode}
              defaultSearchProvider={defaultSearchProvider}
              defaultShowWeekends={defaultShowWeekends}
              disableSearchProviders={organization.consumerSchedulingSettings?.searchProviders}
              disableShowLanguages={organization.consumerSchedulingSettings?.showLanguages}
              disableSortByDistance={disableSortByDistance}
              disableShowMap={!organization.consumerSchedulingSettings?.showMap}
              locationId={locationId}
              locations={locations}
              onClickShowMap={onClickShowMap}
              onClickShowWeekends={onClickShowWeekends}
              onSearchProvider={onSearchProvider}
              onSelectLanguage={onSelectLanguage}
              onSelectLocation={onSelectLocation}
              onSelectSort={onSelectSort}
              onSelectStart={onSelectStart}
              showMap={showMap === true || showMap === null}
              sort={sort}
            />
          </Col>
          <Col xs={0} lg={showMap === true || showMap === null ? 18 : 24}>
            <Row>
              <Col xs={0} lg={10} />
              <Col xs={24} lg={14}>
                <DatesBar
                  dates={dates}
                  onClickFuture={onClickFuture}
                  onClickPast={onClickPast}
                  showMap={showMap === true || showMap === null}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
