import React, { FC } from 'react';
import { Button } from 'antd';
import { IsOrgAdminOrBlockitAdmin } from '../../../components';
import { useCountProfilesInOrganizationQuery } from '../hooks/useCountProfilesInOrganizationQuery';
import { NavLink } from 'react-router-dom';
import { FreePlanConfiguration } from '../../../generated/graphql';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props {
  organizationId: string;
  isFree: boolean;
  freePlanConfiguration: FreePlanConfiguration;
}

export const NewProfileButton: FC<Props> = ({ organizationId, isFree, freePlanConfiguration }): JSX.Element | null => {
  const { data, loading, error } = useCountProfilesInOrganizationQuery({
    variables: {
      organizationId,
      searchProfilesInput: {
        isActive: true,
      },
    },
  });

  if (error) return null;

  let disabled = false;
  if (!loading && data) {
    const { countProfilesInOrganization: count } = data;

    const limit = (freePlanConfiguration && freePlanConfiguration.profiles) || 2;

    if (count >= limit && isFree) {
      disabled = true;
    }
  }

  return (
    <IsOrgAdminOrBlockitAdmin>
      <BlockRestrictedUsers>
        <NavLink
          to={{ pathname: `/organizations/${organizationId}/profiles/new` }}
          className="text-grey-darkest no-underline"
        >
          <Button type="primary" disabled={disabled}>
            New Profile
          </Button>
        </NavLink>
      </BlockRestrictedUsers>
    </IsOrgAdminOrBlockitAdmin>
  );
};
