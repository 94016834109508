import { Button, Icon, message, Popover, Switch } from 'antd';
import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Modal, MultiSelect } from '../../../../components';
import { Fieldset } from '../../../../components/Forms';
import { formikCalendarDays, formikCalendarMonths, formikCalendarYears, parseErrorMessage } from '../../../../helpers';
import { Spinner } from '../../../../components';
import {
  CreateAvailabilityLimit,
  DisableProfileLimit,
  EnableProfileLimit,
  GetAvailabilityLimitSummary,
} from '../../../../GraphQL';

const CreateAvailabilityLimitSchema = Yup.object().shape({
  start: Yup.object().shape({
    month: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    day: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    year: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
  }),
  count: Yup.number()
    .required('Required')
    .typeError('Must be a number'),
});

class AvailabilityLimitModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createIsOpen: false,
      createLoading: false,
      switchLoading: false,
    };
  }

  handleSwitchChange = async (checked, e) => {
    try {
      this.setState({ switchLoading: true });

      if (checked === true) {
        await this.props.client.mutate({
          mutation: EnableProfileLimit,
          variables: {
            organizationId: this.props.organizationId,
            profileId: this.props.profileId,
            type: 'inventory',
          },
          refetchQueries: [
            {
              query: GetAvailabilityLimitSummary,
              variables: {
                organizationId: this.props.organizationId,
                profileId: this.props.profileId,
              },
            },
          ],
        });
      } else if (checked === false) {
        await this.props.client.mutate({
          mutation: DisableProfileLimit,
          variables: {
            organizationId: this.props.organizationId,
            profileId: this.props.profileId,
          },
          refetchQueries: [
            {
              query: GetAvailabilityLimitSummary,
              variables: {
                organizationId: this.props.organizationId,
                profileId: this.props.profileId,
              },
            },
          ],
        });
      }
    } catch (e) {
      message.warn('An error occured. Please wait a moment and try again.');
    } finally {
      this.setState({ switchLoading: false });
    }
  };

  handleCreateSubmit = async ({ start, count }, { setSubmitting, setStatus }) => {
    setSubmitting(true);

    const startString = `${start.year.value}-${start.month.value}-${start.day.value}`;
    const startDate = new Date(startString);

    try {
      await this.props.client.mutate({
        mutation: CreateAvailabilityLimit,
        variables: {
          organizationId: this.props.organizationId,
          profileId: this.props.profileId,
          start: startDate,
          end: null,
          count: count,
          note: null,
        },
        refetchQueries: [
          {
            query: GetAvailabilityLimitSummary,
            variables: {
              organizationId: this.props.organizationId,
              profileId: this.props.profileId,
            },
          },
        ],
      });

      setStatus('');
      this.setState({ createLoading: false, createIsOpen: false });
    } catch (error) {
      setStatus(parseErrorMessage(error));
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    if (this.props.profileId === null) {
      return null;
    }

    return (
      <Query
        query={GetAvailabilityLimitSummary}
        variables={{ organizationId: this.props.organizationId, profileId: this.props.profileId }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Spinner size="2x" padding={0} />;
          if (error) return <div>Error</div>;

          const { GetAvailabilityLimitSummary: limitSummary } = data;

          if (limitSummary && limitSummary.enabled) {
            return (
              <React.Fragment>
                <span style={{ paddingRight: '20px' }}>
                  <Switch
                    checked={limitSummary.enabled}
                    disabled={!this.props.authorizedToEdit}
                    loading={this.state.switchLoading}
                    onChange={this.handleSwitchChange}
                    size="small"
                  />
                </span>
                <Button
                  className="cursor-pointer ml-auto"
                  onClick={() => this.setState({ createIsOpen: true })}
                  size="small"
                >
                  <Icon type="plus" />
                </Button>
                <Modal
                  title="Create availability limit"
                  isOpen={this.state.createIsOpen}
                  closeModal={() => this.setState({ createIsOpen: false })}
                >
                  <p className="text-center text-grey-darker mb-4 w-3/4 mr-auto ml-auto">
                    Enter the start date and amount of availability for the new limit.
                    <Popover
                      content={
                        <div>
                          <ul>
                            <li style={{ listStyle: 'square inside' }}>
                              Setting the start date to today and amount to greater than 0 will immediately increase the
                              current availability.
                            </li>
                            <li style={{ listStyle: 'square inside' }}>
                              Setting the start date to today and amount to less than 0 will immediately reduce the
                              current availability.
                            </li>
                            <li style={{ listStyle: 'square inside' }}>
                              Setting the start date in the future will add the amount to the current availability on
                              that day.
                            </li>
                          </ul>
                        </div>
                      }
                    >
                      {'  '}
                      <Icon type="question-circle" />
                    </Popover>
                  </p>
                  <Formik
                    initialValues={{
                      start: {
                        month: null,
                        day: null,
                        year: null,
                      },
                      count: 0,
                    }}
                    validationSchema={CreateAvailabilityLimitSchema}
                    onSubmit={this.handleCreateSubmit}
                  >
                    {({
                      errors,
                      status,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched,
                      handleSubmit,
                      values,
                    }) => (
                      <div className="p-4">
                        {status && status.length > 0 ? (
                          <div className="text-red w-4/5 mr-auto ml-auto text-center">{status}</div>
                        ) : null}
                        <Form className="w-full" onSubmit={handleSubmit}>
                          <div className="flex">
                            <div className="w-1/4 flex items-center justify-end mr-4">
                              <span className="font-bold text-md text-grey-darker">Start Date:</span>
                            </div>
                            <div className="flex w-3/4">
                              <div className="flex-1 mr-2">
                                <MultiSelect
                                  errors={errors}
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                  touched={touched}
                                  options={formikCalendarMonths}
                                  value={values.start.month}
                                  name="start.month"
                                  placeholder="Month"
                                  className="w-full"
                                  isMulti={false}
                                />
                              </div>
                              <div className="flex-1 mr-2">
                                <MultiSelect
                                  errors={errors}
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                  touched={touched}
                                  options={formikCalendarDays(values.start.month || '01')}
                                  value={values.start.day}
                                  name="start.day"
                                  placeholder="Day"
                                  className="w-full"
                                  isMulti={false}
                                />
                              </div>
                              <div className="flex-1">
                                <MultiSelect
                                  errors={errors}
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                  touched={touched}
                                  options={formikCalendarYears()}
                                  value={values.start.year}
                                  name="start.year"
                                  placeholder="Year"
                                  className="w-full"
                                  isMulti={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex">
                            <div className="w-1/4 flex items-center justify-end mr-4">
                              <span className="font-bold text-md text-grey-darker">Amount:</span>
                            </div>
                            <div className="flex w-3/4">
                              <div className="flex-1 mr-2">
                                <Fieldset
                                  errors={errors}
                                  touched={touched}
                                  type="number"
                                  name="count"
                                  placeholder="Number of appointments to make available"
                                  className="w-full input mt-2 border-1"
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="w-full flex-1 mt-8 btn btn-blue px-2 py-2"
                          >
                            Save {isSubmitting ? <Icon type="loading" /> : null}
                          </button>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </Modal>
              </React.Fragment>
            );
          } else {
            return (
              <Switch
                checked={limitSummary.enabled}
                disabled={!this.props.authorizedToEdit}
                loading={this.state.switchLoading}
                onChange={this.handleSwitchChange}
                size="small"
              />
            );
          }
        }}
      </Query>
    );
  }
}

export const AvailabilityLimitModal = withApollo(AvailabilityLimitModalComponent);
