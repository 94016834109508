import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { FC } from 'react';
import { DeleteGroupOrganization } from './DeleteGroupOrganization';

interface Organization {
  id: string;
  name: string;
}

interface Props {
  groupId: string;
  organizations: Organization[];
}

export const GroupOrganizationsTable: FC<Props> = ({ groupId, organizations }): JSX.Element => {
  const columns: ColumnProps<Organization>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      render: (text, record) => <DeleteGroupOrganization groupId={groupId} organization={record} />,
    },
  ];

  return <Table<Organization> columns={columns} dataSource={organizations} rowKey="id" pagination={false} />;
};
