import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { ConsumerEmbedInstructionsCard } from './ConsumerEmbedInstructionsCard';
import { ConsumerOtherCard } from './ConsumerOtherCard';
import { ConsumerTemplateCard } from './ConsumerTemplateCard';
import { DomainSettingsCard } from './DomainSettingsCards';
import { ConsumerWorkflowCard } from './ConsumerWorkflowCard';
import { useAppContext } from '../../../hooks';
import { FreeOrganizationProfilesCount } from '../../profiles/components/FreeOrganizationProfilesCount';

interface Props {
  organizationId: string;
}

export const ConsumerSettingsCard: FC<Props> = ({ organizationId }): JSX.Element | null => {
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });
  const { currentOrganization } = useAppContext();

  if (!data?.getOrganization) return null;

  const organization = data?.getOrganization;
  let consumerPortalLink = `https://app.blockitnow.com/consumer/${organizationId}`;

  if (organization?.slug) {
    consumerPortalLink = `https://app.blockitnow.com/consumer/${organization.slug}`;
  }

  return (
    <Row gutter={[24, 24]}>
      <Col span={18} push={1}>
        {currentOrganization && (
          <FreeOrganizationProfilesCount
            organizationId={currentOrganization.id}
            isFree={currentOrganization.isFree}
            freePlanConfiguration={currentOrganization.freePlanConfiguration}
          />
        )}
      </Col>
      <Col span={18} push={1}>
        <ConsumerEmbedInstructionsCard
          consumerPortalLink={consumerPortalLink}
          slugOrId={organization.slug ? organization.slug : organizationId}
        />
      </Col>
      <Col span={18} push={1}>
        <DomainSettingsCard organizationId={organizationId} />
      </Col>
      <Col span={18} push={1}>
        <ConsumerWorkflowCard organizationId={organizationId} />
      </Col>
      <Col span={18} push={1}>
        <ConsumerTemplateCard organizationId={organizationId} />
      </Col>
      <Col span={18} push={1}>
        <ConsumerOtherCard organizationId={organizationId} />
      </Col>
    </Row>
  );
};
