import React, { FC } from 'react';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import styled from 'styled-components';
import { Col, Row } from 'antd';

interface Props {
  organization: Organization;
}

const Styles = styled.div`
  .terms-container {
    color: #8795a1;
    font-size: 12px;
    padding: 16px;
    text-align: center;
    width: 100%;
  }

  .terms-link {
    color: #8795a1;
  }
`;

export const Footer: FC<Props> = ({ organization }): JSX.Element | null => {
  return (
    <Styles>
      <div className="terms-container">
        <Row>
          <Col xs={24} md={0}>
            <span>
              <a className="terms-link" href="https://www.blockitnow.com/terms">
                Terms of Service
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a className="terms-link" href="https://www.blockitnow.com/use">
                Acceptable Use Policy
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a className="terms-link" href="https://www.blockitnow.com/privacy">
                Privacy Policy
              </a>
            </span>
          </Col>
          <Col xs={24} md={0}>
            <span>&nbsp;&nbsp;© 2018-2020 Blockit, Inc. All Rights Reserved.</span>
          </Col>
          <Col xs={0} md={24}>
            <span>
              <a className="terms-link" href="https://www.blockitnow.com/terms">
                Terms of Service
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a className="terms-link" href="https://www.blockitnow.com/use">
                Acceptable Use Policy
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a className="terms-link" href="https://www.blockitnow.com/privacy">
                Privacy Policy
              </a>
              &nbsp;&nbsp;© 2018-2020 Blockit, Inc. All Rights Reserved.
            </span>
          </Col>
        </Row>
      </div>
      {organization.consumerSchedulingSettings?.htmlFooter ? (
        <div dangerouslySetInnerHTML={{ __html: organization.consumerSchedulingSettings.htmlFooter }} />
      ) : null}
    </Styles>
  );
};
