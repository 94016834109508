import React from 'react';
import { PrivateRoute } from '../core';
import { AppointmentPage } from './pages/AppointmentPage';
import { AppointmentsPage } from './pages/AppointmentsPage';

export const routes = [
  <PrivateRoute
    exact
    key="route--org-appointments"
    path="/organizations/:id/appointments"
    component={AppointmentsPage}
  />,
  <PrivateRoute exact path="/appointments/:id" key="route--appointments" component={AppointmentPage} />,
];
