import _ from 'lodash';
import { Form, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';

const { Option } = Select;

interface Props {
  form: WrappedFormUtils;
  plans: {
    id: string;
    name: string;
  }[];
  onChange: () => void;
}

export default class InsurancePlanField extends React.Component<Props, {}> {
  render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form.Item label="Select the insurance plan">
        {getFieldDecorator('insurancePlan', {
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ],
        })(
          <Select
            disabled={this.props.plans.length === 0}
            showSearch
            dropdownMatchSelectWidth={false}
            placeholder="Insurance Plan"
            optionFilterProp="children"
          >
            {_.sortBy(this.props.plans, ['name']).map(plan => (
              <Option value={plan.id} key={plan.id}>
                {plan.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  }
}
