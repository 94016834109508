import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const lookupCodeSystemForAppointmentQuery = gql`
  query LookupCodeSystemForAppointment($appointmentId: ID!) {
    lookupCodeSystemForAppointment(appointmentId: $appointmentId) {
      name
      valueString
    }
  }
`;

interface CodeSystemValue {
  name: string;
  valueString: string;
}

interface Data {
  lookupCodeSystemForAppointment: CodeSystemValue[];
}

interface Variables {
  appointmentId: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useLookupCodeSystemForAppointmentQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(lookupCodeSystemForAppointmentQuery, options);
};
