import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import config from '../../../config';
import { LoginToken } from '../loginToken';

interface Props {
  handleAuthChange: (arg0: LoginToken) => void;
}

const Logout: FC<Props> = props => {
  props.handleAuthChange(null);
  window.location.assign(`${config.endpoint}/sso/logout`);
  return <Redirect to="/signin" />;
};

export default Logout;
