import * as Sentry from '@sentry/browser';
import React, { FC, useState } from 'react';
import { gql } from '@apollo/client';
import { Alert, Button, Row, Col, Cascader, Form, message, Icon, Tag, Tooltip } from 'antd';
import { InternalServerErrorAlert, Spinner } from '../../../../components';
import { QUERY } from '../../../referrals/components/Referral/GetReferralQuery';
import { ReferralData } from './GetReferralQuery';
import { formatICD10Code } from '../../../../helpers';
import { useListSpecialtiesQuery } from '../../../consumer/hooks/useListSpecialtiesQuery';
import { useMutation } from '@apollo/client';
import { useAppContext } from '../../../../hooks';

const updateReferralMutation = gql`
  mutation updateReferral($referralInput: ReferralInput!) {
    updateReferral(ReferralInput: $referralInput) {
      id
      procedure_id
      procedure {
        id
      }
    }
  }
`;

export const VisitType: FC<{ referral: ReferralData }> = ({ referral }): JSX.Element | null => {
  const [showVisitTypeForm, setShowVisitTypeForm] = useState(false);
  const handleShowVisitTypeForm = (): void => setShowVisitTypeForm(!showVisitTypeForm);
  const procedure = referral.procedure;
  const specialty = referral.procedure.specialty;
  const { currentOrganization } = useAppContext();

  const [updateReferral] = useMutation(updateReferralMutation, {
    onCompleted: () => {
      message.success(`Successfully updated visit type.`);
    },
    onError: error => {
      Sentry.captureException(error);
      message.error('There was an error updating the visit type. Please try again in a few minutes');
    },
    refetchQueries: [
      {
        query: QUERY,
        variables: {
          id: referral.id,
          organizationId: currentOrganization?.id as string,
        },
      },
    ],
  });

  const onChange = (procedureId: string): void => {
    updateReferral({
      variables: {
        referralInput: {
          id: referral.id,
          procedureId: procedureId,
        },
      },
    });
  };

  const { data, error, loading } = useListSpecialtiesQuery({
    variables: { isActive: true },
  });
  if (error) return <InternalServerErrorAlert error={error} />;
  if (loading || !data) return <Spinner />;
  const { listSpecialties: specialties } = data;

  if (!specialty) {
    return null;
  }

  const current = [referral.procedure.specialty.id, referral.procedure.id];

  const options = specialties.map(specialty => ({
    value: specialty.id,
    label: specialty.name,
    children: specialty.procedures.map(procedure => ({
      value: procedure.id,
      label: procedure.name,
    })),
  }));

  return (
    <div>
      <Row>
        <Col span={24}>
          {!referral.appointment && referral.procedure.isUnspecified ? (
            <Alert message="Visit type is unspecified, and must be selected." type="warning" showIcon />
          ) : null}
        </Col>
      </Row>
      <Row style={{ marginTop: '6px' }}>
        <Col span={8}>
          <h3>{specialty.name}</h3>
          <div>
            <Tooltip title={procedure.display}>
              <Tag>
                {procedure.code || procedure.name}{' '}
                {procedure.display ? <Icon type="question-circle" style={{ color: '#ccc' }} /> : null}
              </Tag>
            </Tooltip>
          </div>
          <div style={{ marginTop: '8px' }}>
            {referral.diagnoses.map(d => (
              <Tooltip key={d.id} title={d.description}>
                <Tag>
                  {formatICD10Code(d.code)}&nbsp;
                  <Icon type="question-circle" style={{ color: '#ccc' }} />
                </Tag>
              </Tooltip>
            ))}
          </div>
          {!referral.appointment ? (
            <Button icon="edit" type="ghost" size="small" onClick={handleShowVisitTypeForm}>
              Edit
            </Button>
          ) : null}
        </Col>
        {!referral.appointment && showVisitTypeForm ? (
          <Col span={16}>
            <Form>
              <Form.Item>
                <Cascader
                  allowClear={false}
                  defaultValue={current}
                  options={options}
                  onChange={val => onChange(val[1])}
                />
              </Form.Item>
            </Form>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};
