import { Col, Input, Row, Tabs } from 'antd';
import debounce from 'lodash/debounce';
import React, { FC, useContext, useState } from 'react';
import { FreeOrganizationProviderCount } from '../components/FreeOrganizationProvidersCount';
import { NewProviderButton } from '../components/NewProviderButton';
import { AppContext } from '../../core/contexts/AppContext';
import { ProvidersTable } from '../components/ProvidersTable';

const { Search } = Input;
const { TabPane } = Tabs;

export const Providers: FC = (): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);
  const [nameFilter, setNameFilter] = useState('');

  if (!currentOrganization) {
    return <></>;
  }

  return (
    <Row type="flex">
      <Col span={24}>
        <FreeOrganizationProviderCount
          organizationId={currentOrganization.id}
          isFree={currentOrganization.isFree}
          freePlanConfiguration={currentOrganization.freePlanConfiguration}
        />
        <Row type="flex" justify="end">
          <Search
            placeholder="Filter by name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.persist();

              const debounceFunc = debounce(() => setNameFilter(e.target.value), 500);

              debounceFunc();
            }}
            style={{ width: 200, paddingRight: 20 }}
          />
          <NewProviderButton
            organizationId={currentOrganization.id}
            isFree={currentOrganization.isFree}
            freePlanConfiguration={currentOrganization.freePlanConfiguration}
          />
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Active" key="1">
            <ProvidersTable active={true} nameFilter={nameFilter} />
          </TabPane>
          <TabPane tab="Inactive" key="2">
            <ProvidersTable active={false} nameFilter={nameFilter} />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};
