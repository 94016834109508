import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { Questionnaire } from '../../../../../components/questionnaire/Questionnaire';

export const ReferralIdContext = React.createContext<string | null>(null);

interface Props {
  form: WrappedFormUtils;
  procedureId: string;
  profile?: {
    id: string;
    phone: string;
    organization: {
      name: string;
    };
  };
  referralId: string | null;
}

const AddInfoQuestionnaire: FC<Props> = ({ procedureId, profile, form, referralId }) => {
  if (!profile) return null;

  return (
    <ReferralIdContext.Provider value={referralId}>
      <Questionnaire profileId={profile.id} procedureId={procedureId} form={form} />
    </ReferralIdContext.Provider>
  );
};

export default AddInfoQuestionnaire;
