import React, { FC } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { IsOrgAdminOrBlockitAdmin } from '../../../components';
import { useCountUsersInOrganizationQuery } from '../hooks/useCountUsersInOrganizationQuery';
import { FreePlanConfiguration } from '../../../generated/graphql';

interface Props {
  organizationId: string;
  isFree: boolean;
  freePlanConfiguration: FreePlanConfiguration;
}

export const NewUserButton: FC<Props> = ({ organizationId, isFree, freePlanConfiguration }): JSX.Element | null => {
  const { data, loading, error } = useCountUsersInOrganizationQuery({
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  if (error) return null;

  let disabled = false;
  if (!loading && data) {
    const { countUsersInOrganization: count } = data;

    if (isFree && freePlanConfiguration && freePlanConfiguration.users && count >= freePlanConfiguration.users) {
      disabled = true;
    }
  }

  return (
    <IsOrgAdminOrBlockitAdmin>
      <Link to={{ pathname: `/organizations/${organizationId}/users/new`, state: { modal: true } }}>
        <Button type="primary" disabled={disabled}>
          New User
        </Button>
      </Link>
    </IsOrgAdminOrBlockitAdmin>
  );
};
