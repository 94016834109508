import { Switch } from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useState } from 'react';
import { Mutation } from '@apollo/client/react/components';

const updateUserOrganizationSmsNotificationMutation = gql`
  mutation($userId: ID!, $organizationId: ID!, $smsNotifications: Boolean!) {
    updateUserOrganizationSmsNotifications(
      userId: $userId
      organizationId: $organizationId
      smsNotifications: $smsNotifications
    ) {
      id
      smsNotifications
    }
  }
`;

interface UserOrganizationSmsNotificationInterface {
  id: string;
  smsNotifications?: boolean;
  userId: string;
  organizationId: string;
}

interface SmsNotificationCheckboxProps {
  userOrganization: UserOrganizationSmsNotificationInterface;
}

interface Data {
  smsNotifications: boolean;
}

interface Variables {
  userId: string;
  organizationId: string;
  smsNotifications: boolean;
}

export const SmsNotificationCheckbox: FC<SmsNotificationCheckboxProps> = ({
  userOrganization,
}: SmsNotificationCheckboxProps): JSX.Element => {
  console.log('userOrganization', userOrganization);
  const [smsEnabled, setSmsEnabled] = useState(userOrganization.smsNotifications);

  return (
    <Mutation<Data, Variables>
      mutation={updateUserOrganizationSmsNotificationMutation}
      onCompleted={() => setSmsEnabled(!smsEnabled)}
    >
      {(updateUserOrganizationSmsNotificationMutation, { loading }) => (
        <div>
          <Switch
            checked={smsEnabled}
            onChange={() => {
              updateUserOrganizationSmsNotificationMutation({
                variables: {
                  userId: userOrganization.userId,
                  organizationId: userOrganization.organizationId,
                  smsNotifications: !smsEnabled,
                },
              });
            }}
          />
        </div>
      )}
    </Mutation>
  );
};
