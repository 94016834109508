import * as QueryString from 'query-string';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { LandingLayout } from '../components/LandingLayout';
import { SubscribeFreeStep } from '../components/SubscribeFreeStep';

export const SubscribeFreePage: FC<RouteComponentProps> = ({ location }): JSX.Element => {
  const params = QueryString.parse(location.search);

  return (
    <LandingLayout>
      <SubscribeFreeStep params={params} />
    </LandingLayout>
  );
};
