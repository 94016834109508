import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const getWaitListAvailabilitiesQuery = gql`
  query GetWaitListAvailabilities($getWaitListAvailabilitiesInput: GetWaitListAvailabilitiesInput!) {
    getWaitListAvailabilities(getWaitListAvailabilitiesInput: $getWaitListAvailabilitiesInput) {
      profileId
      type
      availabilities {
        start
        end
        free_slots_count
      }
    }
  }
`;

interface GetWaitListAvailabilitiesInput {
  profileId: string;
  procedureId: string;
}

interface WaitListAvailability {
  start: string;
  end: string;
  free_slots_count: number;
}

export interface WaitListAvailabilities {
  profileId: string;
  type: string;
  availabilities: WaitListAvailability[];
}

interface Data {
  getWaitListAvailabilities: WaitListAvailabilities;
}

interface Variables {
  getWaitListAvailabilitiesInput: GetWaitListAvailabilitiesInput;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetWaitListAvailabilitiesQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getWaitListAvailabilitiesQuery, options);
};
