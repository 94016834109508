import React, { FC } from 'react';
import { Payor, Plan } from '../types';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props {
  payor: Payor;
  plan: Plan;
  handlePlanAdd: (arg0: { plan: Plan; payor: Payor }) => void;
}

export const PlanItem: FC<Props> = ({ payor, plan, handlePlanAdd }) => (
  <BlockRestrictedUsers
    fallbackContent={
      <li key={plan.id} className="px-6 py-2 bg-grey-lightest text-grey-dark no-select">
        {plan.name}
      </li>
    }
  >
    <li
      key={plan.id}
      className="px-6 py-2 bg-grey-lightest text-grey-dark hover:bg-grey-lightest hover:text-black no-select cursor-pointer"
      onClick={() => handlePlanAdd({ plan, payor })}
    >
      {plan.name} <i>(Click to add)</i>
    </li>
  </BlockRestrictedUsers>
);
