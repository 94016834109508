import { gql } from '@apollo/client';

export const QUERY = gql`
  query listMessages($referralId: ID!) {
    listMessages(referralId: $referralId) {
      id
      user {
        id
        name
        email
      }
      body
      insertedAt
    }
  }
`;

export interface UserData {
  id: string;
  name: string;
  email: string;
}

export interface MessagesData {
  id: string;
  body: string;
  user: UserData;
  insertedAt: string;
}

export interface ListMessagesQueryData {
  listMessages?: [MessagesData];
}

export interface ListMessagesVariables {
  referralId: string;
}
