import { Icon, Spin } from 'antd';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const DataTable = props => {
  const { loading, columns, rows, rowActions } = props;

  return (
    <div>
      <table className="table w-full">
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column.key}>{column.name}</th>
            ))}

            {rowActions ? <th>Actions</th> : null}
          </tr>
        </thead>
        <TableBody loading={loading} columns={props.columns} rows={rows} rowActions={props.rowActions} />
      </table>

      <TableSpinner loading={loading} />
    </div>
  );
};

const TableBody = props => {
  if (props.loading || props.error) {
    return null;
  }

  return (
    <tbody>
      {props.rows.map(row => (
        <DataRow key={row.id} columns={props.columns} row={row} rowActions={props.rowActions} url={row.pageUrl} />
      ))}
    </tbody>
  );
};

const TableSpinner = props => {
  if (!props.loading || props.error) {
    return null;
  }

  return (
    <div className="text-center p-8">
      <Spin size="large" />
    </div>
  );
};

const DataRow = withRouter(props => {
  const { columns, row, rowActions, url } = props;

  return (
    <tr className={url ? 'cursor-pointer' : ''}>
      {columns.map(column => (
        <td
          key={column.key}
          onClick={e => {
            e.stopPropagation();
            if (url) {
              props.history.push(url);
            }
          }}
        >
          {column.formatter ? column.formatter(row, column.key) : row[column.key]}
        </td>
      ))}
      {rowActions ? <ActionColumn row={row} rowActions={rowActions} url={url} /> : null}
    </tr>
  );
});

const ActionColumn = withRouter(props => {
  const { row, rowActions, history, url } = props;

  return (
    <td
      onClick={e => {
        e.preventDefault();

        if (url) {
          history.push(url);
        }
      }}
    >
      {rowActions(row)}
    </td>
  );
});

const RowLinkButton = withRouter(props => (
  <Link
    to={props.to}
    className="btn-icon text-grey-darker hover:text-white hover:bg-grey p-2 mx-1 rounded"
    disabled={props.disabled}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();

      if (props.to) {
        props.history.push(props.to);
      }
    }}
  >
    <Icon type={props.icon} />
  </Link>
));

const RowButton = props => (
  <button
    onClick={props.onClick}
    className="btn-icon text-grey-darker hover:text-white hover:bg-green p-2 mx-1 rounded"
    disabled={props.disabled}
  >
    <Icon type={props.icon} />
  </button>
);

export { DataTable, RowLinkButton, RowButton };
