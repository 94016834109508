import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TextArea } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputProfessionalStatement: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Professional Statement:">
    {form.getFieldDecorator('professionalStatement', { initialValue, rules: [{ required: false }] })(
      <TextArea allowClear placeholder="Professional Statement" rows={8} />
    )}
  </Form.Item>
);
