import { createContext } from 'react';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';

interface ConsumerSchedulingContextProps {
  organization: Organization;
  singleSpecialtyId?: string;
  procedureId?: string;
  canChangeVisitType?: boolean;
  prefillPatient?: {
    givenName1?: string | null;
    familyName?: string | null;
    birthDate?: {
      birthMonth?: number | null;
      birthDay?: number | null;
      birthYear?: number | null;
    };
    sex?: string | null;
  };
  tags?: { [key: string]: string };
}

export const ConsumerSchedulingContext = createContext<ConsumerSchedulingContextProps>({
  organization: {
    id: '',
    name: '',
    profileImgUrl: null,
    slug: null,
    hasConsumerSchedulingShowWeekendsFeature: false,
    locationsWithProfiles: [],
    consumerSchedulingSettings: {
      id: '',
      allowAttachments: false,
      searchInsurance: false,
      visitTypeFormLabel: '',
      noAvailabilityText: null,
      noAvailabilityAction: null,
      searchProviders: false,
      showLanguages: false,
      showMap: false,
      htmlHeader: null,
      htmlFooter: null,
      htmlHeadTags: null,
      googleTagManagerId: null,
      showPatientPrefill: false,
      formFieldSettings: [],
    },
  },
});
