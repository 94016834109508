import React, { FC } from 'react';
import { Form, Switch } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectSendToPatient: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Allow offices to send to a patient for scheduling:" extra="Previously known as Send-to-Patient.">
    {form.getFieldDecorator('isSendToPatient', {
      initialValue: initialValue,
      valuePropName: 'checked',

      rules: [{ required: false }],
    })(<Switch />)}
  </Form.Item>
);
