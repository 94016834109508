import { gql } from '@apollo/client';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';

const createOrganizationMutation = gql`
  mutation CreateOrganizationMutation($createOrganizationInput: CreateOrganizationInput!) {
    createOrganization(createOrganizationInput: $createOrganizationInput) {
      id
      name
    }
  }
`;

interface CreateOrganizationInput {
  isFree?: boolean;
  name: string;
  regionId?: string;
}

interface Organization {
  id: string;
  name: string;
}

export interface CreateOrganizationMutationData {
  createOrganization: Organization;
}

interface Variables {
  createOrganizationInput: CreateOrganizationInput;
}

type Options = MutationHookOptions<CreateOrganizationMutationData, Variables>;

type Result = MutationTuple<CreateOrganizationMutationData, Variables>;

export const useCreateOrganizationMutation = (options: Options = {}): Result => {
  return useMutation<CreateOrganizationMutationData, Variables>(createOrganizationMutation, options);
};
