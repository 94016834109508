import * as Sentry from '@sentry/browser';
import { Button, Col, Drawer, Form, Input, message, Row } from 'antd';
import { gql } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import AntDesignStyleOverrides from '../../../core/components/App/AntDesignStyleOverrides';
import { SelectPayorAndPlan } from './Insurance/SelectPayorAndPlan';

const CreatePatientCoverageMutation = gql`
  mutation CreatePatientCoverageMutation(
    $organizationId: ID!
    $patientId: ID!
    $createPatientCoverageInput: CreatePatientCoverageInput!
  ) {
    createPatientCoverage(
      organizationId: $organizationId
      patientId: $patientId
      createPatientCoverageInput: $createPatientCoverageInput
    ) {
      id
      network
      group_id
      plan_name
      plan_id
    }
  }
`;

const FIELD_NAMES = {
  INSURANCE_CARRIER: 'payorId',
  INSURANCE_PLAN: 'payorPlanId',
  INSURANCE_POLICY_NUMBER: 'insurancePolicyNumber',
  INSURANCE_GROUP_NUMBER: 'insuranceGroupNumber',
};

class AddPatientCoverageFormComponent extends React.Component {
  prepareFormData = values => {
    return {
      insuranceCarrier: values[FIELD_NAMES.INSURANCE_CARRIER],
      insurancePlan: values[FIELD_NAMES.INSURANCE_PLAN],
      insurancePolicyNumber: values[FIELD_NAMES.INSURANCE_POLICY_NUMBER],
      insuranceGroupNumber: values[FIELD_NAMES.INSURANCE_GROUP_NUMBER],
    };
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;

    const fields = Object.keys(FIELD_NAMES).map(value => FIELD_NAMES[value]);
    validateFieldsAndScroll(fields, {}, async (errors, values) => {
      if (errors) return;

      const hideLoadingMessage = message.loading('Saving Patient Coverage', 0);
      const formattedPayload = this.prepareFormData(values);

      try {
        const {
          data: { createPatientCoverage: coverage },
        } = await this.props.client.mutate({
          mutation: CreatePatientCoverageMutation,
          variables: {
            organizationId: this.props.organization.id,
            patientId: this.props.patient.id,
            createPatientCoverageInput: formattedPayload,
          },
        });

        this.props.handleCreatePatientCoverage(coverage);
        this.props.handleShowEditButton();
        hideLoadingMessage();
        message.success('Patient Coverage Saved');
        this.props.handleHidePatientCoverageForm();
      } catch (error) {
        hideLoadingMessage();
        message.error('There was an error while adding patient coverage. Please try again.');
        Sentry.captureException(error);
      }
    });
  };

  getFormRules = (requiredFields, field) => {
    if (requiredFields.includes(field)) {
      return [{ required: true, message: 'required' }];
    } else {
      return [];
    }
  };

  render() {
    const { show, handleHidePatientCoverageForm, organization } = this.props;
    if (!organization) {
      return null;
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Drawer title="Add Patient Coverage" width={720} onClose={handleHidePatientCoverageForm} visible={show}>
          <AntDesignStyleOverrides>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16}>
                <Col xs={24} lg={8}>
                  <SelectPayorAndPlan
                    form={this.props.form}
                    initialPayorId={'initialPayorId'}
                    initialPayorPlanId={'initialPayorPlanId'}
                  />
                </Col>
                <Col span={12}>
                  <Form.Item label="Insurance Group Number">
                    {getFieldDecorator(
                      FIELD_NAMES.INSURANCE_GROUP_NUMBER,
                      {}
                    )(<Input placeholder="Insurance Group Number" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Insurance Policy Number">
                    {getFieldDecorator(
                      FIELD_NAMES.INSURANCE_POLICY_NUMBER,
                      {}
                    )(<Input placeholder="Insurance Policy Number" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} type="flex" justify="start">
                <Col>
                  <Button htmlType="button" onClick={handleHidePatientCoverageForm}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </AntDesignStyleOverrides>
        </Drawer>
      </div>
    );
  }
}

export default _.flowRight(withApollo, Form.create({ name: 'Add Patient Coverage' }))(AddPatientCoverageFormComponent);
