import { Button, Card, Icon, message, Tooltip, Tree } from 'antd';
import React, { FC, useState } from 'react';
import { useUpdateGroupLocationsMutation } from '../../../hooks/mutations';
import { useAppContext } from '../../../hooks/useAppContext';

const { TreeNode } = Tree;

interface Props {
  groupId: string;
  isAllLocations: boolean;
  locations: { id: string; name: string }[];
}

export const AssignGroupLocations: FC<Props> = ({ groupId, isAllLocations, locations }): JSX.Element => {
  const { currentOrganization } = useAppContext();
  const [loading, setLoading] = useState(false);
  const orgLocations = currentOrganization?.locations?.filter(l => l.id);
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>(locations?.map(l => l.id));
  const [updateGroupLocationsMutation] = useUpdateGroupLocationsMutation();

  const handleOnSave = async (e: React.SyntheticEvent): Promise<void> => {
    setLoading(true);
    try {
      await updateGroupLocationsMutation({
        variables: {
          UpdateGroupLocationsInput: {
            groupId,
            locationIds: selectedLocationIds,
          },
        },
      });
      message.success('Group locations updated');
    } catch (e) {
      message.error('Failed to update group locations');
    }

    setLoading(false);
  };

  return (
    <Card
      title={
        <>
          Locations Assigned{' '}
          <Tooltip title="Select which locations will see this network group as an available filtering criteria when scheduling a patient.">
            <Icon type="question-circle" />
          </Tooltip>
        </>
      }
      extra={
        <Button type="primary" onClick={handleOnSave} loading={loading}>
          Save
        </Button>
      }
    >
      <Tree
        checkable
        autoExpandParent={true}
        defaultExpandAll={true}
        onCheck={checkedLocations => {
          if (Array.isArray(checkedLocations)) {
            setSelectedLocationIds(checkedLocations);
          }
        }}
        checkedKeys={selectedLocationIds}
      >
        <TreeNode title="All Locations" key="all_locations">
          {orgLocations &&
            orgLocations.map(location => {
              return <TreeNode title={location.name} key={location.id} />;
            })}
        </TreeNode>
      </Tree>
    </Card>
  );
};
