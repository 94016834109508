import { createContext } from 'react';

export interface AppointmentsContextInterface {
  endDate: string;
  filters: string | string[];
  pageNumber: number;
  pageSize: number;
  patientId: string | undefined;
  startDate: string;
}

export const AppointmentsContext = createContext<AppointmentsContextInterface>({
  endDate: '',
  filters: '{}',
  pageNumber: 1,
  pageSize: 10,
  patientId: undefined,
  startDate: '',
});
