import { ApolloError } from '@apollo/client';

export const parseErrorMessage = (error: ApolloError): string => {
  if (error.graphQLErrors) {
    // ensure there is at least 1 error and dont return 400 or 500, etc for the message
    return error.graphQLErrors.length > 0
      ? error.graphQLErrors[0].message
      : 'An error occured. Please wait a moment and try again.';
  }

  return error.message;
};
