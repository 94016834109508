import { Card } from 'antd';
import React, { FC } from 'react';
import { CreateGroupProfile } from './CreateGroupProfile';
import { GroupProfilesTable } from './GroupProfilesTable';

interface Location {
  id: string;
  name: string;
}

interface Organization {
  id: string;
  name: string;
}

interface Profile {
  id: string;
  displayName: string;
  location: Location;
  organization: Organization;
}

interface Props {
  groupId: string;
  profiles: Profile[];
}

export const GroupProfiles: FC<Props> = ({ groupId, profiles }): JSX.Element => (
  <Card title="Profiles in Network Group" extra={<CreateGroupProfile groupId={groupId} />}>
    <GroupProfilesTable groupId={groupId} profiles={profiles} />
  </Card>
);
