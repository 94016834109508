import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import InsuranceManagement from '../../organizations/pages/InsuranceManagement';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { ProfileContext } from '../contexts/ProfileContext';
import { ProfilePage } from '../pages/ProfilePage';
import { useGetProfileQuery } from '../hooks/useGetProfileQuery';

interface MatchParams {
  profileId: string;
}

export const ProfileRoutes: FC<RouteComponentProps<MatchParams>> = ({ match }): JSX.Element => {
  const { data, error, loading } = useGetProfileQuery({ variables: { id: match.params.profileId } });

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  const { getProfile: profile } = data;

  return (
    <ProfileContext.Provider value={{ profile }}>
      <Switch>
        <Route exact path={`${match.path}/edit`} key="route--profiles-profile-edit" component={ProfilePage} />
        <Route
          exact
          path={`${match.path}/insurance`}
          key="route--profiles-profile-insurance"
          component={InsuranceManagement}
        />
      </Switch>
    </ProfileContext.Provider>
  );
};
