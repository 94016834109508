import { gql } from '@apollo/client';
import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Spinner } from '../../../components';
import { AppContextConsumer } from '../../core/contexts/AppContext';
import Scheduling from '../components/Scheduling/Scheduling';
import { useQueryParam, StringParam } from 'use-query-params';
import { useAppContext } from '../../../hooks';
import { useGetPatientQuery } from '../../../generated/graphql';

const schedulingContainerQuery = gql`
  query schedulingContainerQuery($locationId: ID, $organizationId: ID!) {
    getCapabilityStatement(organizationId: $organizationId) {
      name
    }
    getOrganization(organizationId: $organizationId) {
      id
      name
      showInsuranceFiltersDuringScheduling
      providers(locationId: $locationId) {
        id
        name
        npi
        location {
          id
          name
        }
      }
      groups(locationId: $locationId, isReferralNetwork: true, isActive: true) {
        id
        name
      }
      consumerSchedulingSettings {
        id
        formFieldSettings {
          field
          required
        }
      }
      preAuthorizedSpecialties
      preAuthorizedProcedures
    }
    listSpecialtiesWithProfiles(organizationId: $organizationId) {
      id
      name
      procedures {
        id
        name
      }
      # Prevent GraphQL from sharing the object cache of these specialities / procedures, because the result of
      # the procedures list depends on if it's called with listSpecialtiesWithprofiles or listSpecialities and cause
      # the GraphQL cache to be incorrect depending on which query is called first. In the future, the lists should
      # have different field names or be a flag on the procedure so that the cache can be shared.
      __typename @skip(if: true)
    }
    getPayorsAndPlansByOrganization(organizationId: $organizationId) {
      id
      name
      payorPlans {
        id
        name
      }
    }
  }
`;

const SchedulingContainer = props => {
  const [preselectedPatientId] = useQueryParam('patientId', StringParam);
  const { currentOrganization } = useAppContext();

  const { data, loading } = useGetPatientQuery({
    variables: {
      id: preselectedPatientId,
      organizationId: currentOrganization.id,
    },
    skip: preselectedPatientId === undefined,
  });

  if (loading) return null;
  const patientData = data?.getPatient;

  return (
    <AppContextConsumer>
      {({ currentLocation, currentOrganization }) => (
        <Query
          query={schedulingContainerQuery}
          variables={{ locationId: currentLocation.id, organizationId: currentOrganization.id }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Spinner />;
            if (error) return <div>Error</div>;

            const {
              getOrganization,
              listSpecialtiesWithProfiles: specialties,
              getPayorsAndPlansByOrganization: payors,
            } = data;

            return (
              <Scheduling
                organization={getOrganization}
                specialties={specialties}
                payors={payors}
                featureFlags={currentOrganization.featureFlags}
                preselectedPatient={patientData}
                {...props}
              />
            );
          }}
        </Query>
      )}
    </AppContextConsumer>
  );
};

export default SchedulingContainer;
