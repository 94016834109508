import * as Sentry from '@sentry/browser';
import { Button, Col, Drawer, Form, Input, message, Row, Select } from 'antd';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import {
  stateNames,
  transformRawPhoneNumber,
  validateAntPhoneNumber,
  validateAntPostalCode,
} from '../../../../helpers';
import { SelectPatientBirthDate } from '../../../consumer/components/SelectPatientBirthDate';
import AntDesignStyleOverrides from '../../../core/components/App/AntDesignStyleOverrides';

const { Option } = Select;

const UpdatePatientDemographicMutation = gql`
  mutation UpdatePatientDemographicMutation(
    $organizationId: ID!
    $patientId: ID!
    $updatePatientDemographicInput: UpdatePatientDemographicInput!
  ) {
    updatePatientDemographic(
      organizationId: $organizationId
      patientId: $patientId
      updatePatientDemographicInput: $updatePatientDemographicInput
    ) {
      id
      address1
      address2
      city
      dob
      familyName
      sex
      givenName1
      givenName2
      givenName3
      phone
      state
      postalCode
      email
    }
  }
`;

const FIELD_NAMES = {
  PATIENT_FIRST_NAME: 'patientFirstName',
  PATIENT_MIDDLE_NAME: 'patientMiddleName',
  PATIENT_LAST_NAME: 'patientLastName',
  PATIENT_GENDER: 'patientGender',
  PATIENT_BIRTHDAY: 'birthDate',
  PATIENT_ADDRESS: 'patientAddress',
  PATIENT_ADDRESS_2: 'patientAddress2',
  PATIENT_CITY: 'patientCity',
  PATIENT_STATE: 'patientState',
  PATIENT_POSTAL_CODE: 'patientPostalCode',
  PATIENT_PHONE_NUMBER: 'patientPhoneNumber',
  PATIENT_EMAIL_ADDRESS: 'patientEmailAddress',
};

class AddEditPatientFormComponent extends React.Component {
  prepareFormData = values => {
    return {
      address1: values[FIELD_NAMES.PATIENT_ADDRESS],
      address2: values[FIELD_NAMES.PATIENT_ADDRESS_2] || '',
      city: values[FIELD_NAMES.PATIENT_CITY],
      dob: DateTime.utc(
        values[FIELD_NAMES.PATIENT_BIRTHDAY].year,
        values[FIELD_NAMES.PATIENT_BIRTHDAY].month,
        values[FIELD_NAMES.PATIENT_BIRTHDAY].day
      ).toISODate(),
      familyName: values[FIELD_NAMES.PATIENT_LAST_NAME],
      sex: values[FIELD_NAMES.PATIENT_GENDER],
      givenName1: values[FIELD_NAMES.PATIENT_FIRST_NAME],
      givenName2: values[FIELD_NAMES.PATIENT_MIDDLE_NAME],
      givenName3: '',
      phone: transformRawPhoneNumber(values[FIELD_NAMES.PATIENT_PHONE_NUMBER]),
      state: values[FIELD_NAMES.PATIENT_STATE],
      postalCode: values[FIELD_NAMES.PATIENT_POSTAL_CODE],
      email: values[FIELD_NAMES.PATIENT_EMAIL_ADDRESS] || '',
    };
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;

    const fields = Object.keys(FIELD_NAMES).map(value => FIELD_NAMES[value]);
    validateFieldsAndScroll(fields, {}, async (errors, values) => {
      if (errors) return;

      if (values.birthDate === null) {
        const patientDOB = new Date(this.props.patient.dob);
        values.birthDate = {
          year: patientDOB.getUTCFullYear(),
          month: patientDOB.getUTCMonth() + 1,
          day: patientDOB.getUTCDate(),
        };
      }

      const hideLoadingMessage = message.loading('Saving Patient', 0);
      const formattedPayload = this.prepareFormData(values);

      try {
        const {
          data: { updatePatientDemographic: patient },
        } = await this.props.client.mutate({
          mutation: UpdatePatientDemographicMutation,
          variables: {
            organizationId: this.props.organization.id,
            patientId: this.props.patient.id,
            updatePatientDemographicInput: formattedPayload,
          },
        });

        this.props.handleUpdatePatient(patient);
        hideLoadingMessage();
        message.success('Patient Saved');
        this.props.handleHidePatientForm();
      } catch (error) {
        hideLoadingMessage();
        message.error('There was an error while editing the patient. Please try again.');
        Sentry.captureException(error);
      }
    });
  };

  getFormRules = (requiredFields, field) => {
    if (requiredFields.includes(field)) {
      return [{ required: true, message: 'required' }];
    } else {
      return [];
    }
  };

  render() {
    const { show, handleHidePatientForm, patient, organization } = this.props;
    if (!organization) {
      return null;
    }

    const { getFieldDecorator } = this.props.form;
    const patientDOB = new Date(patient.dob);

    const formFieldSettings = Object.entries(organization.consumerSchedulingSettings?.formFieldSettings || {}).map(
      ([i, k], v) => {
        if (k.required) {
          return k.field;
        } else {
          return null;
        }
      }
    );

    return (
      <div>
        <Drawer title="Edit Patient" width={720} onClose={handleHidePatientForm} visible={show}>
          <AntDesignStyleOverrides>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="First Name">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_FIRST_NAME, {
                      initialValue: patient.givenName1,
                      rules: [{ required: true, message: 'required' }],
                    })(<Input placeholder="First Name" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Middle Name">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_MIDDLE_NAME, {
                      initialValue: patient.givenName2,
                      rules: this.getFormRules(formFieldSettings, 'givenName2'),
                    })(<Input placeholder="Given Name" />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Last Name">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_LAST_NAME, {
                      initialValue: patient.familyName,
                      rules: [{ required: true, message: 'required' }],
                    })(<Input placeholder="Last Name" />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Sex">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_GENDER, {
                      initialValue: patient.sex.toUpperCase(),
                      validateTrigger: 'onChange',
                      rules: [{ required: true, message: 'required' }],
                    })(
                      <Select placeholder="Select Sex" showAction={['focus', 'click']}>
                        <Option value="MALE">Male</Option>
                        <Option value="FEMALE">Female</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <SelectPatientBirthDate
                    form={this.props.form}
                    initialValue={{
                      birthYear: patientDOB.getUTCFullYear(),
                      birthMonth: patientDOB.getUTCMonth() + 1,
                      birthDay: patientDOB.getUTCDate(),
                    }}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Street Address">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_ADDRESS, {
                      initialValue: patient.address1,
                      rules: this.getFormRules(formFieldSettings, 'address1'),
                    })(<Input placeholder="Street Address" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Street Address Line 2">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_ADDRESS_2, {
                      initialValue: patient.address2,
                      rules: this.getFormRules(formFieldSettings, 'address2'),
                    })(<Input placeholder="Street Address Line 2" />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="City">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_CITY, {
                      initialValue: patient.city,
                      rules: this.getFormRules(formFieldSettings, 'city'),
                    })(<Input placeholder="City" />)}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="State">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_STATE, {
                      initialValue: patient.state,
                      rules: this.getFormRules(formFieldSettings, 'state'),
                    })(
                      <Select
                        showSearch
                        showAction={['focus', 'click']}
                        placeholder="Select State"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().startsWith(input.toLowerCase())
                        }
                      >
                        {stateNames.map(stateName => (
                          <Option value={stateName} key={stateName}>
                            {stateName}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Postal Code">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_POSTAL_CODE, {
                      initialValue: patient.postalCode,
                      rules: [
                        {
                          type: 'string',
                          validator: validateAntPostalCode,
                        },
                      ].concat(this.getFormRules(formFieldSettings, 'postalCode')),
                    })(<Input placeholder="12345" />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Phone Number">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_PHONE_NUMBER, {
                      initialValue: patient.phone,
                      rules: [
                        {
                          transform: transformRawPhoneNumber,
                          validator: validateAntPhoneNumber,
                          type: 'number',
                        },
                      ].concat(this.getFormRules(formFieldSettings, 'phone')),
                    })(<Input placeholder="(123) 123-1234" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Email Address">
                    {getFieldDecorator(FIELD_NAMES.PATIENT_EMAIL_ADDRESS, {
                      initialValue: patient.email,
                      rules: [
                        {
                          type: 'email',
                          message: 'must be a valid email',
                        },
                      ].concat(this.getFormRules(formFieldSettings, 'email')),
                    })(<Input placeholder="Email Address" />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} type="flex" justify="start">
                <Col>
                  <Button htmlType="button" onClick={handleHidePatientForm}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </AntDesignStyleOverrides>
        </Drawer>
      </div>
    );
  }
}

export default _.flowRight(withApollo, Form.create({ name: 'Edit Patient' }))(AddEditPatientFormComponent);
