import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import AntDesignStyleOverrides from '../../core/components/App/AntDesignStyleOverrides';
import { InputLocation } from './InputLocation';
import { SelectPayorAndPlan } from './SelectPayorAndPlan';
import { SelectSpecialtyAndProcedureForOrganization } from './SelectSpecialtyAndProcedureForOrganization';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';

export interface LandingFormData {
  location: string;
  payorId?: string;
  payorPlanId?: string;
  procedureId: string;
  specialtyId: string;
}

interface Props extends FormComponentProps {
  form: WrappedFormUtils<LandingFormData>;
  onSubmit: (values: LandingFormData) => void;
  setLatitude: (latitude: number | undefined) => void;
  setLongitude: (longitude: number | undefined) => void;
  embedded: boolean;
  initialProcedureId?: string;
  initialSpecialtyId?: string;
}

const Styles = styled.div`
  .search-button {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    height: 38px;
    text-align: center;
    text-decoration: none solid;
    text-transform: uppercase;
    width: 100%;
  }
`;

const LandingFormComponent: FC<Props> = ({
  form,
  onSubmit,
  setLatitude,
  setLongitude,
  embedded,
  initialProcedureId,
  initialSpecialtyId,
}): JSX.Element => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleLandingFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <AntDesignStyleOverrides>
      <Styles>
        <Form onSubmit={handleLandingFormSubmit}>
          <Row gutter={[24, 0]}>
            <Col xs={24} lg={8}>
              <SelectSpecialtyAndProcedureForOrganization
                form={form}
                initialProcedureId={initialProcedureId}
                initialSpecialtyId={initialSpecialtyId}
              />
            </Col>
            {organization.consumerSchedulingSettings === null || organization.consumerSchedulingSettings?.showMap ? (
              <Col xs={24} lg={8}>
                <InputLocation
                  form={form}
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                  embedded={embedded}
                  onLocationChange={valid => setDisabled(!valid)}
                />
              </Col>
            ) : null}

            <Col xs={24} lg={8}>
              {organization.consumerSchedulingSettings === null ||
              organization.consumerSchedulingSettings?.searchInsurance ? (
                <SelectPayorAndPlan form={form} />
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button className="search-button" type="primary" htmlType="submit" disabled={disabled}>
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Styles>
    </AntDesignStyleOverrides>
  );
};

export const LandingForm = Form.create<Props>()(LandingFormComponent);
