import React, { FC } from 'react';
import styled from 'styled-components';
import { Tag, Typography } from 'antd';
import { Specialty } from '../containers/DirectoryContext';

interface Props {
  specialties: Specialty[];
}

const { Text } = Typography;

const Styles = styled.div`
  .profile-specialties-header {
    font-style: italic;
    margin-bottom: 2px;
  }

  .profile-specialty {
    margin: 2px;
  }
`;

export const ProfileCardSpecialties: FC<Props> = ({ specialties }): JSX.Element => (
  <Styles>
    <Text className="profile-specialties-header">Specialties:</Text>
    <ul>
      {specialties.map(s => (
        <li className="profile-specialty" key={s.id}>
          <Tag>{s.name}</Tag>
        </li>
      ))}
    </ul>
  </Styles>
);
