import { Col, Empty, Row } from 'antd';
import React, { FC } from 'react';
import { history } from '../../core/components/App/history';
import { PublicProfile } from '../containers/DirectoryContext';
import { ProfileCard } from './ProfileCard';

interface Props {
  profiles: PublicProfile[];
}

export const DirectoryProfiles: FC<Props> = ({ profiles }): JSX.Element => {
  return (
    <React.Fragment>
      {profiles.length > 0 ? (
        <Row gutter={16}>
          {profiles.map(p => (
            <Col key={p.id} span={12} className="profile-card-col">
              <ProfileCard history={history} profile={p} />
            </Col>
          ))}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </React.Fragment>
  );
};
