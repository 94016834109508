import React, { FC, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InternalServerErrorAlert, Spinner } from '../../../../../components';
import { SelectPayor } from './SelectPayor';
import { SelectPayorPlan } from './SelectPayorPlan';
import { Payor, PayorPlan, useGetAllPayorsAndPlansQuery } from '../../../hooks/useGetAllPayorsAndPlansQuery';

interface Props {
  form: WrappedFormUtils;
  initialPayorId?: string;
  initialPayorPlanId?: string;
}

export const SelectPayorAndPlan: FC<Props> = ({ form, initialPayorId, initialPayorPlanId }): JSX.Element => {
  const [selectedPayor, setSelectedPayor] = useState<Payor | undefined>(undefined);

  const { data, error, loading } = useGetAllPayorsAndPlansQuery();

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  const { getAllPayorsAndPlans: payors } = data;

  const findPayor = (payorId: string | undefined): Payor | undefined => {
    return payors.find(payor => payor.id === payorId);
  };

  const findPayorPlan = (payorPlanId: string | undefined): PayorPlan | undefined => {
    if (selectedPayor) {
      return selectedPayor.payorPlans.find(payorPlan => payorPlan.id === payorPlanId);
    }

    return findPayor(initialPayorId)?.payorPlans.find(payorPlan => payorPlan.id === payorPlanId);
  };

  const handleSelectPayor = (payorId: string): void => {
    const newPayor = findPayor(payorId);
    if (newPayor?.id !== selectedPayor?.id) {
      form.setFieldsValue({ payorPlanId: undefined });
    }

    setSelectedPayor(findPayor(payorId));
  };

  return (
    <div id="blockitConsumerPayorPlanFields">
      <SelectPayor form={form} initialPayor={findPayor(initialPayorId)} onSelect={handleSelectPayor} payors={payors} />
      <SelectPayorPlan
        form={form}
        initialPayorPlan={findPayorPlan(initialPayorPlanId)}
        payorPlans={selectedPayor ? selectedPayor.payorPlans : findPayor(initialPayorId)?.payorPlans}
      />
    </div>
  );
};
