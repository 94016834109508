import React, { FC } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { ConsumerSchedulingPageLoader } from '../components/ConsmerSchedulingPageLoader';
import { Engagement } from '../hooks/useCreateConsumerSchedulingEngagementMutation';
import { PrintSuccessStep } from '../components/PrintSuccessStep';
import { useGetConsumerSchedulingOrganizationQuery } from '../hooks/useGetConsumerSchedulingOrganizationQuery';

interface LocationState {
  engagement?: Engagement;
  patientName?: string;
}

interface MatchParams {
  organizationId: string;
}

export const ConsumerSchedulingPrintSuccessPage: FC<RouteComponentProps<MatchParams, StaticContext, LocationState>> = ({
  location,
  match: {
    params: { organizationId },
  },
}): JSX.Element => {
  const { data, error, loading } = useGetConsumerSchedulingOrganizationQuery({ variables: { id: organizationId } });

  if (error || loading || !data) {
    return <ConsumerSchedulingPageLoader loading={loading} error={error} />;
  }

  const { getConsumerSchedulingOrganization: organization } = data;

  const engagement = location?.state?.engagement;
  const patientName = location?.state?.patientName || '';

  if (!engagement) {
    return <Redirect to={`/consumer/${organization.slug ? organization.slug : organization.id}${location.search}`} />;
  }

  return <PrintSuccessStep engagement={engagement} patientName={patientName} organization={organization} />;
};
