// Intercom API keys are in snake case
import * as Sentry from '@sentry/browser';
import React, { FC, useEffect } from 'react';
import Zendesk from 'react-zendesk';
import config from '../../../config';
import { useAppContext } from '../../../hooks';

export const UserTracker: FC<{}> = (): JSX.Element | null => {
  const { currentOrganization, isAuthenticated, viewer } = useAppContext();

  useEffect(() => {
    if (!window.Intercom) return;
    if (config.enableUserTracking && isAuthenticated && currentOrganization && viewer) {
      window.Intercom('boot', {
        app_id: config.intercomAppId,
        user_id: viewer.id,
        email: viewer.email,
        name: viewer.name,
        company: { id: currentOrganization.id, name: currentOrganization.name },
      });
    } else {
      window.Intercom('boot', {
        app_id: config.intercomAppId,
      });
    }
  }, [isAuthenticated, currentOrganization, viewer]);

  if (config.enableUserTracking && isAuthenticated && currentOrganization && viewer) {
    Sentry.setUser({
      id: viewer.id,
      email: viewer.email,
      name: viewer.name,
      currentOrganization: currentOrganization.name,
    });

    return <Zendesk zendeskKey={config.zendeskKey} />;
  } else {
    return null;
  }
};
