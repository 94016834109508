import React, { FC, useContext } from 'react';
import { Button, Col, Icon, Row } from 'antd';
import { DateTime } from 'luxon';
import { Spinner } from '../../../components';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { ProfileSlotsColumnButtons } from './ProfileSlotsColumnButtons';
import { Slot, useGetConsumerSchedulingProfileSlotsQuery } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';
import { DatesBar } from './DatesBar';
import { useWindowSize } from '../../../hooks/useWindowSize';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../../../themes/default';
import moment from 'moment';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';

interface Props {
  dates: DateTime[];
  onSelectSlot: (slot: Slot) => void;
  procedureId: string;
  profile: Profile;
  showMap: boolean;
  onClickFuture: () => void;
  onClickPast: () => void;
  handleJumpToDate: (arg0: DateTime) => void;
}

interface ProfileSlotsColumnsProps {
  dates: DateTime[];
  onSelectSlot: (slot: Slot) => void;
  slots: Slot[];
  showMap: boolean;
  onClickFuture: () => void;
  handleJumpToDate: (arg0: DateTime) => void;
  profile: Profile;
}

type DaysFromSlotsAccumulator = {
  ids: { [key: string]: string[] };
  slots: { [key: string]: Slot[] };
};

const Styles = styled.div`
  button {
    background-color: ${({ theme }) => theme.blueDarkest};
    color: ${({ theme }) => theme.white};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    margin: auto;
    border: 0;
  }
`;

const computeDaysFromSlots = (slots: Slot[]): DaysFromSlotsAccumulator => {
  const sortedSlots = slots.slice().sort((a, b) => {
    if (a.start < b.start) {
      return -1;
    }

    if (a.start > b.start) {
      return 1;
    }

    return 0;
  });

  return sortedSlots.reduce(
    (acc: DaysFromSlotsAccumulator, slot: Slot) => {
      const date: string = DateTime.fromISO(slot.start).toISODate();
      const ids = `__ids__${date}`;

      if (!(date in acc.slots)) {
        acc.slots[date] = [];
        acc.ids[ids] = [];
      }

      if (acc.ids[ids].indexOf(slot.id) === -1) {
        acc.slots[date].push(slot);
        acc.ids[ids].push(slot.id);
      }

      return acc;
    },
    { ids: {}, slots: {} }
  );
};

const ProfileSlotsColumns: FC<ProfileSlotsColumnsProps> = ({
  dates,
  onSelectSlot,
  showMap,
  slots,
  onClickFuture,
  handleJumpToDate,
  profile,
}): JSX.Element => {
  const dateIndexes = dates.map(date => date.toISODate());
  const slotDays = computeDaysFromSlots(slots);
  const windowSize = useWindowSize();

  let visibleDateIndex = [];

  const { organization } = useContext(ConsumerSchedulingContext);

  const noAvailabilityText = organization.consumerSchedulingSettings?.noAvailabilityText;
  const noAvailabilityAction = organization.consumerSchedulingSettings?.noAvailabilityAction;

  // This sucks, but no way to programmatically know how many dates we are showing because it's controlled by the
  // browser breakpoint
  if (windowSize.width < 576) {
    visibleDateIndex = [0, 1, 2];
  } else if (windowSize.width >= 576 && windowSize.width < 768) {
    visibleDateIndex = [0, 1, 2, 3];
  } else if (windowSize.width >= 768 && windowSize.width < 949) {
    visibleDateIndex = [0, 1, 2, 3, 4];
  } else if (windowSize.width >= 950 && windowSize.width < 1200) {
    if (showMap === true) {
      visibleDateIndex = [0, 1, 2, 3];
    } else {
      visibleDateIndex = [0, 1, 2, 3, 4];
    }
  } else if (windowSize.width >= 1200 && windowSize.width < 1600) {
    if (showMap === true) {
      visibleDateIndex = [0, 1, 2, 3, 4];
    } else {
      visibleDateIndex = [0, 1, 2, 3, 4, 5];
    }
  } else {
    visibleDateIndex = [0, 1, 2, 3, 4, 5, 6];
  }

  const slotsVisible = visibleDateIndex.filter(idx => {
    return (slotDays.slots[dateIndexes[idx]] || []).length !== 0;
  }).length;

  const handleNextAvailabilityClick = (): void => {
    if (noAvailabilityAction) {
      window.location.href = noAvailabilityAction;
    } else {
      onClickFuture();
    }
  };

  if (slotsVisible === 0) {
    let nextAvailability = profile.nextAvailability;
    if (slots.length !== 0) {
      nextAvailability = Object.keys(slotDays.slots)[0];
    }

    return (
      <Col xs={22}>
        <Row justify="center" align="middle" type="flex" style={{ height: 200 }}>
          <ThemeProvider theme={defaultTheme}>
            <Styles>
              <Col span={8}>
                {!nextAvailability && (
                  <Button type="primary" onClick={handleNextAvailabilityClick} className="show-more-button">
                    {organization.consumerSchedulingSettings?.noAvailabilityText && noAvailabilityText}
                    {!noAvailabilityText && 'No Availability'}
                  </Button>
                )}

                {nextAvailability && (
                  <Button
                    type="primary"
                    onClick={() => handleJumpToDate(DateTime.fromISO(nextAvailability || ''))}
                    className="show-more-button"
                  >
                    Next availability is on {moment(nextAvailability).format('ddd, MMM Do')}
                  </Button>
                )}
              </Col>
            </Styles>
          </ThemeProvider>
        </Row>
      </Col>
    );
  }

  return (
    <>
      <Col span={1} />
      <Col xs={7} sm={5} md={4} lg={showMap ? 5 : 4} xl={showMap ? 4 : 3} xxl={3}>
        <ProfileSlotsColumnButtons onSelectSlot={onSelectSlot} slots={slotDays.slots[dateIndexes[0]] || []} />
      </Col>
      <Col xs={7} sm={5} md={4} lg={showMap ? 5 : 4} xl={showMap ? 4 : 3} xxl={3}>
        <ProfileSlotsColumnButtons onSelectSlot={onSelectSlot} slots={slotDays.slots[dateIndexes[1]] || []} />
      </Col>
      <Col xs={7} sm={5} md={4} lg={showMap ? 5 : 4} xl={showMap ? 4 : 3} xxl={3}>
        <ProfileSlotsColumnButtons onSelectSlot={onSelectSlot} slots={slotDays.slots[dateIndexes[2]] || []} />
      </Col>
      <Col xs={0} sm={5} md={4} lg={showMap ? 5 : 4} xl={showMap ? 4 : 3} xxl={3}>
        <ProfileSlotsColumnButtons onSelectSlot={onSelectSlot} slots={slotDays.slots[dateIndexes[3]] || []} />
      </Col>
      <Col xs={0} md={4} lg={showMap ? 0 : 4} xl={showMap ? 4 : 3} xxl={3}>
        <ProfileSlotsColumnButtons onSelectSlot={onSelectSlot} slots={slotDays.slots[dateIndexes[4]] || []} />
      </Col>
      <Col xs={0} xl={showMap ? 0 : 3} xxl={3}>
        <ProfileSlotsColumnButtons onSelectSlot={onSelectSlot} slots={slotDays.slots[dateIndexes[5]] || []} />
      </Col>
      <Col xs={0} xxl={3}>
        <ProfileSlotsColumnButtons onSelectSlot={onSelectSlot} slots={slotDays.slots[dateIndexes[6]] || []} />
      </Col>
      <Col span={1} />
    </>
  );
};

interface SlotLoadingErrorProps {
  refetch: () => void;
}

const SlotLoadingError: FC<SlotLoadingErrorProps> = ({ refetch }) => {
  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24}>
        <h3 style={{ textAlign: 'center' }}>Unable to load schedule</h3>
        <p style={{ textAlign: 'center' }}>Please wait a moment and try again</p>
      </Col>
      <Col span={24}>
        <div style={{ textAlign: 'center', paddingTop: 10 }}>
          <Button
            onClick={() => {
              refetch();
            }}
          >
            Retry <Icon type="reload" />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export const ProfileSlots: FC<Props> = ({
  dates,
  onSelectSlot,
  procedureId,
  profile,
  showMap,
  onClickFuture,
  onClickPast,
  handleJumpToDate,
}): JSX.Element => {
  const { data, error, loading, refetch } = useGetConsumerSchedulingProfileSlotsQuery({
    variables: {
      profileId: profile.id,
      procedureId,
      start: dates[0].toISODate(),
      end: dates[dates.length - 1].toISODate(),
    },
  });

  if (error) return <SlotLoadingError refetch={refetch} />;

  if (loading || !data) {
    return (
      <div style={{ height: 296 }}>
        <Spinner />
      </div>
    );
  }

  const { getConsumerSchedulingProfileSlots: slots } = data;

  return (
    <Row>
      <Col xs={24} lg={0}>
        <Row>
          <DatesBar dates={dates} onClickFuture={onClickFuture} onClickPast={onClickPast} showMap={showMap === true} />
        </Row>
        <Row gutter={16} type="flex" justify="center" align="top">
          <ProfileSlotsColumns
            dates={dates}
            onSelectSlot={onSelectSlot}
            slots={slots}
            showMap={showMap}
            onClickFuture={onClickFuture}
            handleJumpToDate={handleJumpToDate}
            profile={profile}
          />
        </Row>
      </Col>
      <Col xs={0} lg={24}>
        <Row gutter={16} type="flex" justify="end" align="top">
          <ProfileSlotsColumns
            dates={dates}
            onSelectSlot={onSelectSlot}
            slots={slots}
            showMap={showMap}
            onClickFuture={onClickFuture}
            handleJumpToDate={handleJumpToDate}
            profile={profile}
          />
        </Row>
      </Col>
    </Row>
  );
};
