import { Button, Col, Form, Input, message, Row, Card, Avatar } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, FormEvent, useContext, useState } from 'react';
import FileReaderInput from 'react-file-reader-input';
import { AppContext } from '../../core/contexts/AppContext';
import { getOrganization, useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { useUpdateOrganizationMutation } from '../../../hooks/mutations/useUpdateOrganization';
import { FreeOrganizationProfilesCount } from '../../profiles/components/FreeOrganizationProfilesCount';
import { BlockRestrictedUsers } from '../../../helpers';

interface FormValues {
  name: string;
}

const EditOrganizationComponent: FC<FormComponentProps> = ({ form }): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const [updateOrganization] = useUpdateOrganizationMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: currentOrganization?.id || '' } });

  const handleUpdateOrganization = async (values: FormValues): Promise<void> => {
    try {
      await updateOrganization({
        variables: {
          organizationId: currentOrganization?.id || '',
          updateOrganizationInput: {
            name: values.name,
            profileImgFile: fileToUpload || null,
          },
        },
        refetchQueries: [{ query: getOrganization, variables: { organizationId: currentOrganization?.id } }],
      });
      message.success('Your changes have been saved.');
      setFileToUpload(null);
    } catch (err) {
      message.error('An error occurred while saving your changes');
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        message.error('Please fix the form errors and try again.');
      } else {
        handleUpdateOrganization(values as FormValues).then();
      }
    });
  };

  const organization = data?.getOrganization;
  const { getFieldDecorator } = form;

  const orgLogoUrl = fileToUpload ? URL.createObjectURL(fileToUpload) : organization?.profileImgUrl || undefined;

  const handleFileUploadChange = (event: React.SyntheticEvent, results: FileReaderInput.Result[]): void => {
    setFileToUpload(results[0][1]);
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={18} push={1}>
        {currentOrganization && (
          <FreeOrganizationProfilesCount
            organizationId={currentOrganization.id}
            isFree={currentOrganization.isFree}
            freePlanConfiguration={currentOrganization.freePlanConfiguration}
          />
        )}
      </Col>
      <Col span={18} push={1}>
        <Form onSubmit={handleSubmit}>
          <Card
            title="Organization"
            extra={
              <BlockRestrictedUsers>
                <Button type="primary" htmlType="submit">
                  Save Changes
                </Button>
              </BlockRestrictedUsers>
            }
          >
            <Row>
              <Col span={8}>
                <Form.Item>
                  {orgLogoUrl ? '' : <Avatar icon="home" shape="square" size={192} src={orgLogoUrl} />}
                  {orgLogoUrl && <img src={orgLogoUrl} alt="orgLogo" />}

                  <BlockRestrictedUsers>
                    <FileReaderInput onChange={(event, results) => handleFileUploadChange(event, results)}>
                      <Button size="small">Change Logo</Button>
                    </FileReaderInput>
                  </BlockRestrictedUsers>
                </Form.Item>
              </Col>
              <Col span={11} push={1}>
                <Form.Item label="Organization Name">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Required' }],
                    initialValue: organization?.name,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export const EditOrganization = Form.create({ name: 'Edit Organization' })(EditOrganizationComponent);
