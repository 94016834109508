import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';

interface Props {
  maxWidth: string;
}

const Styles = styled.div<Props>`
  .step-container {
    max-width: ${props => props.maxWidth};

    &.step-container-xs {
      padding: 24px 8px;
    }

    &.step-container-sm {
      padding: 28px 8px;
    }

    &.step-container-md {
      padding: 32px 8px;
    }

    &.step-container-lg {
      padding: 36px 8px;
    }
  }
`;

export const StepContainer: FC<Props> = ({ children, maxWidth }): JSX.Element => {
  return (
    <Styles maxWidth={maxWidth}>
      <Row type="flex" justify="center" align="middle">
        <Col className="step-container step-container-xs" xs={24} sm={0}>
          {children}
        </Col>
        <Col className="step-container step-container-sm" xs={0} sm={24} md={0}>
          {children}
        </Col>
        <Col className="step-container step-container-md" xs={0} md={24} lg={0}>
          {children}
        </Col>
        <Col className="step-container step-container-lg" xs={0} lg={24}>
          {children}
        </Col>
      </Row>
    </Styles>
  );
};
