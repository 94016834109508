import { gql } from '@apollo/client';
import { MutationTuple, useMutation } from '@apollo/client';

export interface UpdateGroupLocationsData {
  id: string;
  locations: {
    id: string;
    name: string;
  }[];
}

export interface UpdateGroupLocationsVariables {
  UpdateGroupLocationsInput: {
    groupId: string;
    locationIds: string[];
  };
}

type UpdateGroupLocationsMutationResult = MutationTuple<UpdateGroupLocationsData, UpdateGroupLocationsVariables>;

const UpdateGroupLocationsMutation = gql`
  mutation updateGroupLocations($UpdateGroupLocationsInput: UpdateGroupLocationsInput!) {
    updateGroupLocations(UpdateGroupLocationsInput: $UpdateGroupLocationsInput) {
      id
    }
  }
`;

export const useUpdateGroupLocationsMutation = (): UpdateGroupLocationsMutationResult => {
  return useMutation<UpdateGroupLocationsData, UpdateGroupLocationsVariables>(UpdateGroupLocationsMutation);
};
