import React, { FC, useContext } from 'react';
import { Card } from 'antd';
import { History } from 'history';
import { AppContext } from '../../core/contexts/AppContext';
import { CreateGroup } from '../components/CreateGroup';
import { GroupsTable } from '../components/GroupsTable';
import { isAdmin, isBlockitAdmin } from '../../core/components/PrivateRoute';

interface GroupsPageProps {
  history: History;
}

export const Groups: FC<GroupsPageProps> = ({ history }): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);

  if (!currentOrganization) {
    return <></>;
  }

  const isAuthorized = isAdmin(currentOrganization) || isBlockitAdmin(currentOrganization);

  return (
    <Card
      title="Network Groups"
      extra={isAuthorized ? <CreateGroup organizationId={currentOrganization.id} history={history} /> : null}
    >
      <GroupsTable organizationId={currentOrganization.id} canEdit={isAuthorized} />
    </Card>
  );
};
