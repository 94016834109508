import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getAllPayorsAndPlansQuery = gql`
  query GetAllPayorsAndPlansQuery {
    getAllPayorsAndPlans {
      id
      name
      payorPlans {
        id
        name
      }
    }
  }
`;

export interface Payor {
  id: string;
  name: string;
  payorPlans: PayorPlan[];
}

export interface PayorPlan {
  id: string;
  name: string;
}

interface Data {
  getAllPayorsAndPlans: Payor[];
}

type Options = QueryHookOptions<Data>;

type Result = QueryResult<Data>;

export const useGetAllPayorsAndPlansQuery = (options: Options = {}): Result => {
  return useQuery<Data, {}>(getAllPayorsAndPlansQuery, options);
};
