import React, { FC, useContext } from 'react';
import { Button, Card, Col, Form, Row, Typography, Divider, Popconfirm, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { InputBoardCertifications } from './InputBoardCertifications';
import { InputDisplayName } from './InputDisplayName';
import { InputEducationTraining } from './InputEducationTraining';
import { InputFirstName } from './InputFirstName';
import { InputGeneralPatientInstructions } from './InputGeneralPatientInstructions';
import { InputLastName } from './InputLastName';
import { InputNPINumber } from './InputNPINumber';
import { InputPrefix } from './InputPrefix';
import { InputProfessionalStatement } from './InputProfessionalStatement';
import { InputSpecialty } from './InputSpecialty';
import { InputSuffix } from './InputSuffix';
import { Profile } from '../index';
import { ProfileFormData } from './ProfileForm';
import { SelectLanguages } from './SelectLanguages';
import { SelectType } from './SelectType';
import { UploadProfilePhoto } from './UploadProfilePhoto';
import { SelectActive } from './SelectActive';
import { SelectWaitList } from './SelectWaitList';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { AppContext } from '../../core/contexts/AppContext';
import { history } from '../../core/components/App/history';
import { InputReferralSearchNotice } from './InputReferralSearchNotice';
import { FeatureFlag, BlockRestrictedUsers, FeatureGate } from '../../../helpers';
import { InputSlug } from './InputSlug';
import { InputGroupKey } from './InputGroupKey';

interface Props extends FormComponentProps {
  onSubmit: (values: ProfileFormData) => void;
  profile: Profile;
}

interface Data {
  cloneProfile: {
    id: string;
  };
}

interface Variables {
  cloneProfileInput: {
    profileId: string;
  };
}

const cloneProfileMutation = gql`
  mutation CloneProfile($cloneProfileInput: CloneProfileInput!) {
    cloneProfile(cloneProfileInput: $cloneProfileInput) {
      id
    }
  }
`;

const ProfileSettingsInfoFormCardComponent: FC<Props> = ({ form, onSubmit, profile }): JSX.Element => {
  const [cloneProfile] = useMutation<Data, Variables>(cloneProfileMutation, {
    variables: {
      cloneProfileInput: { profileId: profile.id },
    },
  });

  const { currentOrganization } = useContext(AppContext);

  const confirm = async (): Promise<void> => {
    const data = await cloneProfile();
    message.success(`Successfully cloned profile`);
    history.push('/organizations/' + currentOrganization?.id + '/profiles/' + data?.data?.cloneProfile?.id + '/edit');
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Row>
      <Col xs={18}>
        <Form onSubmit={handleFormSubmit}>
          <Card
            title="Profile Info"
            extra={[
              <BlockRestrictedUsers key="clone">
                <Popconfirm
                  title="Are you sure you want to clone this profile?"
                  onConfirm={confirm}
                  okText="Clone"
                  cancelText="Cancel"
                  key="clone"
                >
                  <Button style={{ marginRight: 4 }}>Clone</Button>
                </Popconfirm>
              </BlockRestrictedUsers>,
              <BlockRestrictedUsers key="submit">
                <Button key="submit" htmlType="submit" type="primary">
                  Save Profile Info
                </Button>
              </BlockRestrictedUsers>,
            ]}
          >
            <Row>
              <Col xs={10}>
                <UploadProfilePhoto form={form} initialValue={profile?.profileImgUrl} />
              </Col>
              <Col xs={14}>
                <Row>
                  <Col xs={19}>
                    <SelectType form={form} initialValue={profile?.type} />
                  </Col>
                  <Col xs={5}>
                    <SelectActive form={form} initialValue={profile?.isActive} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FeatureGate feature={FeatureFlag.HasWaitList}>
                      <SelectWaitList form={form} initialValue={profile?.isWaitList} />
                    </FeatureGate>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col xs={12}>
                <InputDisplayName form={form} initialValue={profile?.displayName} />
              </Col>
              <Col span={10} push={1}>
                <Typography.Text type="secondary">
                  How customers further identify this profile within your organization. Can be location name, provider
                  name or machine name. For example, &quot;Dr. Susan&quot; or &quot;Beagle Street Clinic&quot;
                </Typography.Text>
              </Col>
            </Row>
            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <p style={{ fontSize: 18 }}>Titles and Name</p>
              <Typography.Text type="secondary">
                These fields are optional and are only used for searching and filtering.
              </Typography.Text>
            </div>{' '}
            <Row gutter={12}>
              <Col xs={12}>
                <InputPrefix form={form} initialValue={profile?.prefix} />
              </Col>
              <Col xs={12}>
                <InputFirstName form={form} initialValue={profile?.firstName} />
              </Col>
              <Col xs={12}>
                <InputLastName form={form} initialValue={profile?.lastName} />
              </Col>
              <Col xs={12}>
                <InputSuffix form={form} initialValue={profile?.suffix} />
              </Col>
            </Row>
            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <p style={{ fontSize: 18 }}>Searching and Filtering</p>
              <Typography.Text type="secondary">
                These fields are optional and used to enable searching and refinement when you have many profiles.
              </Typography.Text>
            </div>
            <Row gutter={12}>
              <Col xs={12}>
                <InputNPINumber form={form} initialValue={profile?.npiNumber} />
              </Col>
              <Col xs={12}>
                <InputSpecialty form={form} initialValue={profile?.specialty} />{' '}
              </Col>
              <Col xs={12}>
                <InputGroupKey form={form} initialValue={profile?.groupKey} />{' '}
              </Col>
              <Col xs={12}>
                <InputSlug form={form} initialValue={profile?.slug} />{' '}
              </Col>
              <Col xs={24}>
                <SelectLanguages form={form} initialValue={profile?.languages.map(l => l.code)} />
              </Col>
            </Row>
            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <p style={{ fontSize: 18 }}>Professional Info &amp; Instructions</p>
              <Typography.Text type="secondary">
                These fields are optional and used to display more information to patient about the profile. HTML is
                allowed.
              </Typography.Text>
            </div>
            <Row>
              <Col>
                <InputReferralSearchNotice form={form} initialValue={profile?.referralSearchNotice} />
                <InputGeneralPatientInstructions form={form} initialValue={profile?.generalPatientInstructions} />
                <InputProfessionalStatement form={form} initialValue={profile?.professionalStatement} />
                <InputEducationTraining form={form} initialValue={profile?.educationTraining} />
                <InputBoardCertifications form={form} initialValue={profile?.boardCertifications} />
              </Col>
            </Row>
            <Divider />
            <BlockRestrictedUsers>
              <Button htmlType="submit" type="primary" style={{ float: 'right' }}>
                Save Profile Info
              </Button>
            </BlockRestrictedUsers>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export const ProfileSettingsInfoFormCard = Form.create<Props>({ name: 'Domain Settings' })(
  ProfileSettingsInfoFormCardComponent
);
