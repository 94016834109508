import { gql } from '@apollo/client';
import { ScheduleInput } from './useCreateScheduleForOrganizationMutation';
import { useMutation, MutationHookOptions, MutationTuple } from '@apollo/client';

const updateScheduleMutation = gql`
  mutation UpdateScheduleMutation($scheduleId: ID!, $scheduleInput: ScheduleInput!) {
    updateSchedule(scheduleId: $scheduleId, scheduleInput: $scheduleInput) {
      id
    }
  }
`;

interface Profile {
  id: string;
}

export interface UpdateOrganizationMutationData {
  updateSchedule: Profile;
}

interface Variables {
  scheduleId: string;
  scheduleInput: ScheduleInput;
}

type Options = MutationHookOptions<UpdateOrganizationMutationData, Variables>;

type Result = MutationTuple<UpdateOrganizationMutationData, Variables>;

export const useUpdateScheduleMutation = (options: Options): Result => {
  return useMutation<UpdateOrganizationMutationData, Variables>(updateScheduleMutation, options);
};
