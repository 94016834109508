import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const countProvidersInOrganizationQuery = gql`
  query CountProvidersInOrganizationQuery($organizationId: ID!) {
    countProvidersInOrganization(organizationId: $organizationId)
  }
`;

interface Data {
  countProvidersInOrganization: number;
}

interface Variables {
  organizationId: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useCountProvidersInOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(countProvidersInOrganizationQuery, options);
};
