import { gql } from '@apollo/client';

const UpdateReferralStatus = gql`
  mutation UpdateReferralStatus($referralStatusInput: referralStatusInput!) {
    UpdateReferralStatus(referralStatusInput: $referralStatusInput) {
      id
      status
    }
  }
`;

export { UpdateReferralStatus };
