import { Button } from 'antd';
import React, { FC, useState } from 'react';
import { CreateGroupProfileModal } from './CreateGroupProfileModal';

interface Props {
  groupId: string;
}

export const CreateGroupProfile: FC<Props> = ({ groupId }): JSX.Element => {
  const [showCreateGroupProfileModal, setShowCreateGroupProfileModal] = useState(false);

  return (
    <>
      <Button htmlType="button" onClick={() => setShowCreateGroupProfileModal(true)}>
        Add Profile
      </Button>

      <CreateGroupProfileModal
        groupId={groupId}
        onCancel={() => setShowCreateGroupProfileModal(false)}
        onOk={() => setShowCreateGroupProfileModal(false)}
        visible={showCreateGroupProfileModal}
      />
    </>
  );
};
