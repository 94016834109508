import styled, { DefaultTheme } from 'styled-components';

interface Props {
  theme: DefaultTheme;
  selected: boolean;
}

export const DropDownOption = styled.span`
  color: ${({ theme, selected }: Props) => (selected ? theme.accentColor : 'inherit')};
  cursor: pointer;
  padding: 10px;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.accentColor};
    font-weight: normal;
  }
`;
