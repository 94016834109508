import { Icon } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  organization: { id: string };
  user: { id: string };
}

export const UserActions: FC<Props> = ({ organization, user }): JSX.Element => {
  return (
    <Link to={`/organizations/${organization.id}/users/${user.id}/edit`}>
      <Icon type="edit" />
    </Link>
  );
};
