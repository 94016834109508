import FileReaderInput from 'react-file-reader-input';
import React, { FC, SyntheticEvent, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Button, Icon, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { defaultTheme } from '../../../themes/default';
import { ProfilePhoto } from './ProfilePhoto';
import { useUploadScheduleImageMutation } from '../hooks/useUploadScheduleImageMutation';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

const Styles = styled.div`
  padding-bottom: 16px;
  .upload-button {
    margin-top: 10px;
  }
`;

export const UploadProfilePhoto: FC<Props> = ({ form, initialValue }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadScheduleImage] = useUploadScheduleImageMutation({});

  const handleFileReaderInputChange = async (
    event: SyntheticEvent,
    results: FileReaderInput.Result[]
  ): Promise<void> => {
    setLoading(true);
    const [result] = results;
    const file = result[1];

    try {
      const { data } = await uploadScheduleImage({ variables: { uploadScheduleImageInput: { file } } });

      if (data?.uploadScheduleImage) {
        form.setFieldsValue({ profileImgUrl: data?.uploadScheduleImage });
      }
    } catch (error) {
      const graphQLErrors = (error as { graphQLErrors?: { message: string }[] }).graphQLErrors;
      // todo - do some real error handling here
      if (graphQLErrors && graphQLErrors.length > 0) {
        message.error(graphQLErrors[0].message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {form.getFieldDecorator('profileImgUrl', {
        initialValue,
        rules: [{ required: false }],
      })(
        <ThemeProvider theme={defaultTheme}>
          <Styles>
            <ProfilePhoto imgUrl={form.getFieldValue('profileImgUrl')}>
              <FileReaderInput onChange={handleFileReaderInputChange}>
                <Button className="upload-button">Browse {loading ? <Icon type="loading" /> : '...'}</Button>
              </FileReaderInput>
            </ProfilePhoto>
          </Styles>
        </ThemeProvider>
      )}
    </>
  );
};
