import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';
import { Option, Select } from '../../../system/components/Select';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string | null;
}

export const SelectPatientGender: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Patient's Sex">
    {form.getFieldDecorator('sex', {
      initialValue: initialValue,
      rules: [
        {
          required: true,
          message: "Please select patient's sex.",
        },
      ],
    })(
      <Select filterOption={caseInsensitiveSelectFilterOption} placeholder="Select sex" showSearch>
        <Option value="FEMALE" key="FEMALE">
          Female
        </Option>
        <Option value="MALE" key="MALE">
          Male
        </Option>
      </Select>
    )}
  </Form.Item>
);
