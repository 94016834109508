import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  required?: boolean;
}

export const InputPatientInsurancePolicyNumber: FC<Props> = ({ form, required = false }): JSX.Element => (
  <Form.Item label="Insurance Policy Number">
    {form.getFieldDecorator('insurancePolicyNumber', { rules: [{ required: required }] })(<Input allowClear />)}
  </Form.Item>
);
