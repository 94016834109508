import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { Mutation } from '@apollo/client/react/components';

interface Data {
  deleteGroupProfile: {
    id: string;
    displayName: string;
    profiles: { id: string; displayName: string }[];
  };
}

interface Props extends ModalProps {
  groupId: string;
  onOk: (e: React.MouseEvent) => void;
  profile: { id: string; displayName: string };
}

interface Variables {
  deleteGroupProfileInput: {
    groupId: string;
    profileId: string;
  };
}

const deleteGroupProfileMutation = gql`
  mutation DeleteGroupProfile($deleteGroupProfileInput: DeleteGroupProfileInput!) {
    deleteGroupProfile(deleteGroupProfileInput: $deleteGroupProfileInput) {
      id
      name
      profiles {
        id
        displayName
      }
    }
  }
`;

export const DeleteGroupProfileModal: FC<Props> = (props): JSX.Element => {
  const { groupId, profile, onOk } = props;

  return (
    <Mutation<Data, Variables> mutation={deleteGroupProfileMutation}>
      {deleteGroupProfileMutation => {
        const handleOk = (e: React.MouseEvent): void => {
          deleteGroupProfileMutation({
            variables: { deleteGroupProfileInput: { groupId, profileId: profile.id } },
          });

          onOk(e);
        };

        return (
          <Modal {...props} closable={false} onOk={handleOk} okText="Remove Profile" destroyOnClose={true}>
            {`Are you sure you want to remove ${profile.displayName} from this network group?`}
          </Modal>
        );
      }}
    </Mutation>
  );
};
