import React, { useState } from 'react';
import { useCreateUserInOrganizationMutation } from '../../../../hooks';
import { InviteUserFormData, InviteUserForm } from './InviteUserForm';

interface InviteUserInlineProps {
  organizationId: string;
}

export const InviteUserInline: React.FC<InviteUserInlineProps> = ({ organizationId }) => {
  const [createUserInOrganization, { loading }] = useCreateUserInOrganizationMutation();
  const [sent, setSent] = useState(false);

  const handleSubmit = async (values: InviteUserFormData): Promise<void> => {
    await createUserInOrganization({
      variables: {
        name: values.name,
        email: values.email,
        organizationId: organizationId,
        isAdmin: true,
        isActive: true,
        hasAllLocations: true,
        userLocationIdsToAdd: [],
      },
    });

    setSent(true);
  };

  return <InviteUserForm loading={loading} sent={sent} onSubmit={handleSubmit} />;
};
