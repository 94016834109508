import { gql } from '@apollo/client';
import { useMutation, MutationHookOptions, MutationTuple } from '@apollo/client';

const updateOrganizationConsumerSchedulingSettingsMutation = gql`
  mutation UpdateOrganizationConsumerSchedulingSettings(
    $organizationId: ID!
    $updateOrganizationConsumerSchedulingSettingsInput: UpdateOrganizationConsumerSchedulingSettingsInput!
  ) {
    updateOrganizationConsumerSchedulingSettings(
      organizationId: $organizationId
      updateOrganizationConsumerSchedulingSettingsInput: $updateOrganizationConsumerSchedulingSettingsInput
    ) {
      id
      allowAttachments
      searchInsurance
      visitTypeFormLabel
      htmlHeader
      htmlFooter
      htmlHeadTags
      googleTagManagerId
      showLanguages
      searchProviders
      showPatientPrefill
      showMap
      noAvailabilityText
      noAvailabilityAction
      formFieldSettings {
        field
        required
      }
    }
  }
`;

interface OrganizationConsumerSchedulingSettings {
  id: string;
  allowAttachments: boolean;
  searchInsurance: boolean;
  visitTypeFormLabel: string | null;
  htmlHeader: string | null;
  htmlFooter: string | null;
  htmlHeadTags: string | null;
  googleTagManagerId: string | null;
  showLanguages: boolean;
  searchProviders: boolean;
  showPatientPrefill: boolean;
  showMap: boolean;
  noAvailabilityText: string | null;
  noAvailabilityAction: string | null;
}

interface UpdateOrganizationConsumerSchedulingSettingsInput {
  allowAttachments?: boolean;
  searchInsurance?: boolean;
  visitTypeFormLabel?: string | null;
  htmlHeader?: string | null;
  htmlFooter?: string | null;
  htmlHeadTags?: string | null;
  googleTagManagerId?: string | null;
  showLanguages?: boolean;
  searchProviders?: boolean;
  showPatientPrefill?: boolean;
  showMap?: boolean;
  formFieldSettings?: { field: string; required: boolean }[];
  noAvailabilityText?: string | null;
  noAvailabilityAction?: string | null;
}

interface Data {
  updateOrganizationConsumerSchedulingSettings: OrganizationConsumerSchedulingSettings;
}

interface Variables {
  organizationId: string;
  updateOrganizationConsumerSchedulingSettingsInput: UpdateOrganizationConsumerSchedulingSettingsInput;
}

type Options = MutationHookOptions<Data, Variables>;

type Result = MutationTuple<Data, Variables>;

export const useUpdateOrganizationConsumerSchedulingSettingsMutation = (options: Options = {}): Result => {
  return useMutation<Data, Variables>(updateOrganizationConsumerSchedulingSettingsMutation, options);
};
