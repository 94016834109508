import React, { FC } from 'react';
import { Button } from 'antd';
import { useCountLocationsInOrganizationQuery } from '../hooks/useCountLocationsInOrganizationQuery';
import { NavLink } from 'react-router-dom';
import { FreePlanConfiguration } from '../../../generated/graphql';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props {
  organizationId: string;
  isFree: boolean;
  freePlanConfiguration: FreePlanConfiguration;
}

export const NewLocationButton: FC<Props> = ({ organizationId, isFree, freePlanConfiguration }): JSX.Element | null => {
  const { data, loading, error } = useCountLocationsInOrganizationQuery({
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  if (error) return null;

  let disabled = false;
  if (!loading && data) {
    const { countLocationsInOrganization: count } = data;

    if (
      isFree &&
      freePlanConfiguration &&
      freePlanConfiguration.locations &&
      count >= freePlanConfiguration.locations
    ) {
      disabled = true;
    }
  }

  return (
    <BlockRestrictedUsers>
      <NavLink
        to={{ pathname: `/organizations/${organizationId}/locations/new` }}
        className="text-grey-darkest flex items-center no-underline"
      >
        <Button type="primary" disabled={disabled}>
          New Location
        </Button>
      </NavLink>
    </BlockRestrictedUsers>
  );
};
