import * as Sentry from '@sentry/browser';
import { Form, Icon, message, Upload } from 'antd';
import { gql } from '@apollo/client';
import React from 'react';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';

import { GetReferralOrders } from '../../../../../GraphQL';

const { Dragger } = Upload;

const UploadReferralOrder = gql`
  mutation uploadReferralOrder($file: Upload!, $referralId: ID!, $type: String!) {
    uploadReferralOrder(file: $file, referralId: $referralId, type: $type)
  }
`;

const DeleteReferralOrder = gql`
  mutation deleteReferralOrder($id: ID!) {
    deleteReferralOrder(id: $id) {
      id
      displayFilename
    }
  }
`;

const ReferralUploadDrawer = ({ client, referralId, isRequired, form, attachmentType, hint }) => {
  const { getFieldDecorator } = form;

  const customRequest = ({ file: { file }, refetch }) => {
    let aborted = false;

    client
      .mutate({
        mutation: UploadReferralOrder,
        variables: { file, referralId, type: attachmentType },
      })
      .then(() => {
        if (!aborted) {
          message.success(`Successfully uploaded ${file.name}`);
          refetch();
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        message.error(`Error uploading ${file.name}`);
      });

    // DO NOT REMOVE, WILL BREAK UPLOAD - https://github.com/ant-design/ant-design/issues/10122
    return {
      abort() {
        aborted = true;
        console.log('aborted');
      },
    };
  };

  const handleRemove = ({ file, refetch }) => {
    return client
      .mutate({
        mutation: DeleteReferralOrder,
        variables: { id: file.uid },
      })
      .then(() => {
        message.success(`Successfully deleted ${file.name}`);
        refetch();
      })
      .catch(err => {
        Sentry.captureException(err);
        message.error(`Error deleting ${file.name}`);
      });
  };

  const formatFileList = referralOrders => {
    return referralOrders.map(ro => {
      return {
        uid: ro.id,
        name: ro.displayFilename,
        status: 'done',
      };
    });
  };

  const handleChange = ({ fileList }) => {
    console.log(fileList);
  };

  return (
    <Query query={GetReferralOrders} variables={{ referralId, type: attachmentType }}>
      {({ loading, error, data, refetch }) => {
        // Do not handle loading case, otherwise deleting uploads is glitchy and sucks
        if (error) return <div>Error</div>;
        if (!data) return <></>;

        const { getReferralOrders: referralOrders } = data;

        return (
          <>
            <Form.Item>
              {getFieldDecorator('dragger', {
                rules: isRequired ? [{ required: true, message: 'required' }] : [],
              })(
                <Dragger
                  name="file"
                  multiple
                  fileList={formatFileList(referralOrders || [])}
                  onChange={handleChange}
                  customRequest={file => customRequest({ file, refetch })}
                  onRemove={file => handleRemove({ file, refetch })}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">{hint}</p>
                </Dragger>
              )}
            </Form.Item>
          </>
        );
      }}
    </Query>
  );
};

export default withApollo(ReferralUploadDrawer);
