import * as React from 'react';
import { useState } from 'react';
import { SimpleConsumerOnboardingForm, SimpleConsumerOnboardingFormData } from './onboarding/ConsumerOnboardingForm';
import { ConsumerEmbedInstructions } from './onboarding/ConsumerEmbedInstructions';
import { ApolloProvider } from '@apollo/client';
import { makeClient } from '../../../graphqlclient';
import { InviteUsersOnboarding } from './InviteUsersOnboarding';
import { useQueryParam, NumberParam } from 'use-query-params';
import {
  useCreateLocationMutation,
  useUpsertScheduleMutation,
  useUpsertSlotDurationMutation,
  useCreateAvailabilityMutation,
  useCreateProfileProcedureMutation,
} from '../../../hooks';

interface SimpleConsumerOnboardingProps {
  organizationId: string;
  allowSkip?: boolean;
  onFinish?: () => void;
  title?: string;
}

export const SimpleConsumerOnboarding: React.FC<SimpleConsumerOnboardingProps> = ({
  organizationId,
  title,
  allowSkip = true,
  onFinish,
}) => {
  // Keep query string step in sync with internal state
  const [stepQuery = 1, setQueryStep] = useQueryParam('step', NumberParam);
  const [step, setStep] = useState(stepQuery);

  if (stepQuery !== step) {
    setQueryStep(step);
  }

  const [createLocation] = useCreateLocationMutation();
  const [upsertSchedule] = useUpsertScheduleMutation();
  const [createProfileProcedure] = useCreateProfileProcedureMutation();
  const [upsertSlotDuration] = useUpsertSlotDurationMutation();
  const [createAvailability] = useCreateAvailabilityMutation();
  const handleCreateFirstProfile = async (values: SimpleConsumerOnboardingFormData): Promise<void> => {
    const locationResult = await createLocation({
      variables: {
        organizationId: organizationId,
        CreateLocationInput: {
          organizationId: organizationId,
          name: values.displayName,
          address1: values.address1,
          city: values.city,
          state: values.state,
          phone: values.phone,
          postalCode: values.postalCode,
          country: 'United States',
          timeZone: values.timeZone,
          hasAppointmentRequests: false,
        },
      },
    });

    const locationId = locationResult.data?.CreateLocation.id;

    if (!locationId) {
      return;
    }

    const profileResult = await upsertSchedule({
      variables: {
        UpsertScheduleInput: {
          organizationId: organizationId,
          displayName: values.displayName,
          firstName: values.displayName,
          lastName: '',
          locationId: locationId,
          phone: values.phone,
          type: 'location',
          languages: [],
          isActive: true,
          isIntegrated: true,
        },
      },
    });

    const profileId = profileResult.data?.UpsertSchedule.id;
    const scheduleId = profileResult.data?.UpsertSchedule.scheduleId;

    if (!profileId || !scheduleId) {
      return;
    }

    await createProfileProcedure({
      variables: {
        CreateProfileProcedureInput: {
          profileId: profileId,
          duration: values.duration,
          cptCode: '',
          procedureId: values.procedureId,
        },
      },
    });

    await upsertSlotDuration({
      variables: {
        UpsertSlotDurationInput: {
          scheduleId: scheduleId,
          durationInMins: values.duration,
        },
      },
    });

    await values.days.map(day => {
      return createAvailability({
        variables: {
          CreateAvailabilityInput: {
            scheduleId: scheduleId,
            weekDay: day,
            start: `${values.start}:00:00`,
            end: `${values.end}:00:00`,
          },
        },
      });
    });

    onFinish ? onFinish() : setStep(2);
  };

  return (
    <ApolloProvider client={makeClient(() => {})}>
      {step === 1 && (
        <SimpleConsumerOnboardingForm
          title={title}
          onSubmit={handleCreateFirstProfile}
          onFinish={() => (onFinish ? onFinish() : setStep(2))}
          allowSkip={allowSkip}
        />
      )}
      {step === 2 && (
        <InviteUsersOnboarding organizationId={organizationId} onFinish={() => (onFinish ? onFinish() : setStep(3))} />
      )}
      {step === 3 && <ConsumerEmbedInstructions organizationId={organizationId} />}
    </ApolloProvider>
  );
};
