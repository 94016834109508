import * as Sentry from '@sentry/browser';
import React, { FC, useState } from 'react';
import { UpdateReferralStatus } from '../../../../GraphQL';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import { deletePaginatedCacheItems } from '../../../../helpers/cache';
import { useMutation } from '@apollo/client';
import { ReferralData, QUERY } from './GetReferralQuery';
import { useAppContext } from '../../../../hooks';
import { LIST_AUDIT_LOG_QUERY } from './ListAuditLogQuery';

const { Option } = Select;

interface UpdateReferralStatusMutationVars {
  referralStatusInput: {
    id: string;
    status: string;
    cancelReason: string;
    cancelNote: string;
  };
}

interface UpdateReferralStatusMutationData {
  UpdateReferralStatus: {
    id: string;
    status: string;
  };
}

export const CancelReferral: FC<{
  referral?: ReferralData;
  onComplete: () => void;
}> = ({ referral, onComplete }): JSX.Element | null => {
  const { currentOrganization } = useAppContext();

  const reasons = currentOrganization?.referralCancelReasons;

  let defaultReason = 'rescheduled';
  if (reasons && reasons.length) {
    if (reasons[0].value) {
      defaultReason = reasons[0].value;
    } else {
      defaultReason = reasons[0].key;
    }
  }

  const [visible, setVisible] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(defaultReason);
  const [reasonText, setReasonText] = useState<string | null>(null);

  const [updateReferralStatusMutation, { error }] = useMutation<
    UpdateReferralStatusMutationData,
    UpdateReferralStatusMutationVars
  >(UpdateReferralStatus);

  if (error) {
    Sentry.captureException(error);
    message.error('Error cancelling referral');
  }

  const nextStatuses = referral?.nextStatuses as Array<string>;
  if (!referral || !nextStatuses.includes('cancelled')) {
    return null;
  }

  const onOk = (): void => {
    setVisible(false);

    updateReferralStatusMutation({
      variables: {
        referralStatusInput: {
          id: referral.id,
          status: 'cancelled',
          cancelReason: reason,
          cancelNote: reasonText || reason,
        },
      },
      refetchQueries: [
        {
          query: QUERY,
          variables: {
            id: referral.id,
            organizationId: currentOrganization?.id as string,
          },
        },
        {
          query: LIST_AUDIT_LOG_QUERY,
          variables: {
            action: 'referral.status.updated',
            recordId: referral?.id,
            organizationId: currentOrganization?.id as string,
          },
        },
      ],
      update: cache => {
        message.success('Referral has been cancelled');
        deletePaginatedCacheItems(cache, 'ListReferrals');
        deletePaginatedCacheItems(cache, 'ListAppointments');
        onComplete();
      },
    });
  };

  return (
    <>
      <Button className="mt-1" onClick={() => setVisible(true)}>
        Cancel Referral
      </Button>
      <Modal title="Cancel Referral" visible={visible} onOk={() => onOk()} onCancel={() => setVisible(false)}>
        <Form>
          <Form.Item label="Reason for cancellation">
            {reasons && reasons.length ? (
              <Select<string> defaultValue={reasons[0].key} size="small" style={{}} onChange={val => setReason(val)}>
                {reasons.map(r => (
                  <Option key={r.key} value={r.value || r.key}>
                    {r.key}
                  </Option>
                ))}
              </Select>
            ) : (
              <Select<string>
                size="small"
                defaultValue={'Rescheduled'}
                placeholder="Choose reason"
                style={{}}
                onChange={val => setReason(val)}
              >
                <Option value="rescheduled" key="Rescheduled">
                  Rescheduled
                </Option>
                <Option value="patient_cancelled" key="Patient Cancelled">
                  Patient Cancelled
                </Option>
                <Option value="provider_cancelled" key="Provider Cancelled">
                  Provider Cancelled
                </Option>
                <Option value="entered_in_error" key="Entered in Error">
                  Entered in Error
                </Option>
                <Option value="other" key="Other">
                  Other
                </Option>
              </Select>
            )}
            {reason === 'other' && (
              <Input
                maxLength={128}
                onChange={e => setReasonText(e.target.value)}
                placeholder="Additional detail"
                size="small"
              ></Input>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
