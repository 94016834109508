import React, { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { ConsumerSchedulingPageNotFoundErrorAlert } from './ConsumerSchedulingPageNotFoundErrorAlert';
import { Spinner } from '../../../components';
import { parseErrorMessage } from '../../../helpers';
import { Button, Icon, Result } from 'antd';

interface Props {
  error?: ApolloError;
  loading: boolean;
}

export const ConsumerSchedulingPageLoader: FC<Props> = ({ error, loading }): JSX.Element | null => {
  if (error) {
    if (parseErrorMessage(error) === 'Not Found') {
      return <ConsumerSchedulingPageNotFoundErrorAlert />;
    }
    return (
      <Result
        title="We are currently experiencing a surge"
        subTitle="Please wait a moment and try again."
        extra={
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Retry <Icon type="reload" />
          </Button>
        }
      />
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return null;
};
