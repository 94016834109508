import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getConsumerQuestionnaire = gql`
  query($questionnaireId: ID!) {
    getQuestionnaire(questionnaireId: $questionnaireId) {
      id
      title
      type
      items {
        id
        text
        type
        answerMaxLength
        required
        initialValue
        options
        uploadAcceptTypes
        helpText
        answerValidators {
          value
          questionnaireId
        }
      }
    }
  }
`;

interface Data {
  getQuestionnaire: QuestionnaireData;
}

export interface QuestionnaireData {
  id: string;
  type: string;
  items: {
    id: string;
    text: string;
    type: string;
    answerMaxLength?: number | null;
    required: boolean;
    initialValue: string | null;
    options: string[] | null;
    helpText: string | null;
    uploadAcceptTypes: string[] | null;
    answerValidators?: {
      value: string;
      questionnaireId: string;
    }[];
  }[];
}

interface Variables {
  questionnaireId: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetConsumerQuestionnaire = (options: Options): Result => {
  return useQuery<Data, Variables>(getConsumerQuestionnaire, options);
};
