import _ from 'lodash';
import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Option, Select } from '../../../system/components/Select';

interface Props {
  form: WrappedFormUtils;
  initialValue?: number;
}

export const SelectMaxAgeAllowed: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="Maximum patient age (referral platform only)"
    extra="Sets a maximum age restriction of the patient. This profile will not appear in search results if the patient's age exceeds the maximum age restriction."
  >
    {form.getFieldDecorator('onlyAllowsAgeMax', {
      initialValue: initialValue ? initialValue : '',
      rules: [],
    })(
      <Select allowClear>
        <Option value={''} key="maxAge-noMax">
          No Max
        </Option>
        {_.range(0, 99).map(age => (
          <Option value={age} key={`maxAge-${age}`}>
            {age.toString()}
          </Option>
        ))}
      </Select>
    )}
  </Form.Item>
);
