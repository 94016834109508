import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string | null;
}

export const InputPhone: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Phone Number:">
    {form.getFieldDecorator('phone', {
      initialValue,
    })(<Input allowClear placeholder="(800) 555-5555" />)}
  </Form.Item>
);
