import { Col, Drawer, Icon, Row } from 'antd';
import _ from 'lodash';
import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { formatPhone } from '../../../helpers';
import { getMoreInfoTitle } from '../../../helpers/profiles';
import { defaultTheme } from '../../../themes/default';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { ProfileImage } from './ProfileImage';

interface Props {
  profile: Profile;
  setVisible: (visible: boolean) => void;
  visible: boolean;
}

interface ProfileMoreInfoDrawerContentProps {
  profile: Profile;
}

const Styles = styled.div`
  .distance-from-patient {
    font-style: italic;
    font-size: 12px;
    color: ${({ theme }) => theme.bodyTextColor};
  }

  .drawer-content {
    width: 100%;

    &.drawer-content-xs {
      min-width: 272px;
    }

    &.drawer-content-sm {
      min-width: 512px;
    }

    &.drawer-content-lg {
      min-width: 576px;
    }
  }

  .profile-name {
    color: ${({ theme }) => theme.blueDarkest};
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none solid;
  }

  .organization-name {
    font-size: 12px;
    color: ${({ theme }) => theme.bodyTextColor};
  }

  .section-content {
    font-size: 14px;
    color: ${({ theme }) => theme.bodyTextColor};
  }

  .section-title {
    font-style: italic;
    font-size: 14px;
    color: ${({ theme }) => theme.bodyTextColor};
    padding-top: 16px;

    i {
      margin-right: 6px;
    }
  }
`;

const ProfileMoreInfoDrawerContent: FC<ProfileMoreInfoDrawerContentProps> = ({ profile }): JSX.Element => {
  return (
    <>
      <Row>
        <Col xs={7} sm={5} lg={7}>
          <ProfileImage profile={profile} />
        </Col>
        <Col xs={17} sm={19} lg={17}>
          <div className="profile-name">{profile.displayName}</div>
          <div className="organization-name">{profile.specialty}</div>
          <div className="organization-name">{profile.organization.name}</div>
          {profile.location.distanceFromPatient ? (
            <div className="distance-from-patient">{`${_.round(
              parseFloat(profile.location.distanceFromPatient),
              1
            )} miles away`}</div>
          ) : null}
          <Row>
            <Col xs={0} lg={24}>
              <div className="section-title">
                <Icon type="environment" />
                {profile.location.name}
              </div>
              {profile.location.address2 ? <div className="section-content">{profile.location.address2}</div> : null}
              <div className="section-content">{profile.location.address1}</div>
              <div className="section-content">
                {profile.location.city}, {profile.location.state} {profile.location.postalCode}
              </div>
              <div className="section-content">{formatPhone(profile.phone)}</div>
            </Col>
          </Row>
        </Col>
        <Row>
          <Col xs={24} lg={0}>
            <div className="section-title">
              <Icon type="environment" />
              {profile.location.name}
            </div>
            {profile.location.address2 ? <div className="section-content">{profile.location.address2}</div> : null}
            <div className="section-content">{profile.location.address1}</div>
            <div className="section-content">
              {profile.location.city}, {profile.location.state} {profile.location.postalCode}
            </div>
            <div className="section-content">{formatPhone(profile.phone)}</div>
          </Col>
        </Row>
      </Row>
      {profile.professionalStatementSafe ? (
        <Row>
          <Col xs={24}>
            <div className="section-title">
              <Icon type="user" />
              About
            </div>
            <p className="section-content" dangerouslySetInnerHTML={{ __html: profile.professionalStatementSafe }} />
          </Col>
        </Row>
      ) : null}
      {profile.languages && profile.languages.length > 0 ? (
        <Row>
          <Col xs={24}>
            <div className="section-title">
              <Icon type="flag" />
              Languages
            </div>
            <div className="section-content">{profile.languages.map(language => language.name).join(', ')}.</div>
          </Col>
        </Row>
      ) : null}
      {profile.boardCertificationsSafe ? (
        <Row>
          <Col xs={24}>
            <div className="section-title">
              <Icon type="medicine-box" />
              Board Certifications
            </div>
            <p className="section-content" dangerouslySetInnerHTML={{ __html: profile.boardCertificationsSafe }} />
          </Col>
        </Row>
      ) : null}
      {profile.educationTrainingSafe ? (
        <Row>
          <Col xs={24}>
            <div className="section-title">
              <Icon type="solution" />
              Education & Training
            </div>
            <p className="section-content" dangerouslySetInnerHTML={{ __html: profile.educationTrainingSafe }} />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export const ProfileMoreInfoDrawer: FC<Props> = ({ profile, setVisible, visible }) => {
  const handleOnClose = (): void => {
    setVisible(false);
  };

  return (
    <Drawer
      title={getMoreInfoTitle(profile.type)}
      drawerStyle={{ maxWidth: '624px', width: '100%' }}
      width={'auto'}
      onClose={handleOnClose}
      visible={visible}
    >
      <ThemeProvider theme={defaultTheme}>
        <Styles>
          <Row>
            <Col xs={24} sm={0} className="drawer-content drawer-content-xs">
              <ProfileMoreInfoDrawerContent profile={profile} />
            </Col>
            <Col xs={0} sm={24} lg={0} className="drawer-content drawer-content-sm">
              <ProfileMoreInfoDrawerContent profile={profile} />
            </Col>
            <Col xs={0} lg={24} className="drawer-content drawer-content-lg">
              <ProfileMoreInfoDrawerContent profile={profile} />
            </Col>
          </Row>
        </Styles>
      </ThemeProvider>
    </Drawer>
  );
};
