import React, { FC } from 'react';
import { useAppContext } from '../../../hooks';
import { useListWorkflowQueuesQuery } from '../../../generated/graphql';
import { Empty, PageHeader, Tabs } from 'antd';
import { WorkflowQueue } from '../components/WorkflowQueue';
import { Spinner } from '../../../components';
import { useQueryParam, StringParam } from 'use-query-params';
import { QueueTitle } from '../components/QueueTitle';

export const WorkflowQueueIndex: FC<{}> = (): JSX.Element | null => {
  const { currentOrganization } = useAppContext();
  const { data } = useListWorkflowQueuesQuery({
    variables: { organizationId: currentOrganization?.id || '' },
  });

  const [tab, setTab] = useQueryParam('tab', StringParam);
  const defaultTab = tab || undefined;

  if (!data || !data.listWorkflowQueues) return <Spinner />;

  return (
    <>
      <PageHeader title="Workflow Queues">
        {data.listWorkflowQueues.length >= 1 && (
          <Tabs tabPosition="left" defaultActiveKey={defaultTab} onChange={setTab}>
            {data.listWorkflowQueues.map(queue => {
              if (queue.isInternal) return null;

              return (
                <Tabs.TabPane key={queue.id} tab={<QueueTitle name={queue.name} color={queue.color} />}>
                  <WorkflowQueue id={queue.id} />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        )}

        {data.listWorkflowQueues.length === 0 && (
          <Empty
            style={{ border: '1px solid #eee', padding: 16, borderRadius: 4 }}
            description={
              <p style={{ fontSize: 18, color: '#666' }}>
                Your organization does not have any workflow queues configured. Contact Blockit to enable this feature.
              </p>
            }
          ></Empty>
        )}
      </PageHeader>
    </>
  );
};
