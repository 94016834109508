import React, { FC } from 'react';
import { LandingLayout } from '../components/LandingLayout';
import { FinishStep } from '../components/FinishStep';

export const FinishPage: FC = (): JSX.Element => {
  return (
    <LandingLayout>
      <FinishStep />
    </LandingLayout>
  );
};
