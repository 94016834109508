import { gql } from '@apollo/client';

const GetLocation = gql`
  query GetLocation($GetLocationInput: GetLocationInput!) {
    GetLocation(GetLocationInput: $GetLocationInput) {
      id
      key
      slug
      name
      displayName
      phone
      email
      address1
      address2
      city
      state
      postalCode
      country
      latitude
      longitude
      timeZone
      hasAppointmentRequests
    }
  }
`;

const UpdateLocation = gql`
  mutation UpdateLocation($UpdateLocationInput: UpdateLocationInput!) {
    UpdateLocation(UpdateLocationInput: $UpdateLocationInput) {
      id
    }
  }
`;

export { GetLocation, UpdateLocation };
