import { Col, Empty, Row, Select } from 'antd';
import { gql } from '@apollo/client';
import * as _ from 'lodash';
import * as moment from 'moment';
import React, { FC, useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Spinner } from '../../../../components';
import { ProcedureInterface } from '../../../../GraphQL/ListReferrals.graphql';
import { Location, Organization, Specialty } from '../../../directory/containers/DirectoryContext';
import { SlotsContainer, SearchType } from './SlotsContainer';
import { FeatureFlag, hasFeature } from '../../../../helpers';
import { ViewerOrganization } from '../../../viewer';

const { Option } = Select;

const GET_PROFILE_QUERY = gql`
  query GetProfile($profileId: ID!) {
    GetProfile(id: $profileId) {
      id
      displayName
      specialty
      profileImgUrl
      phone
      isIntegrated
      type
      location {
        id
        name
        address1
        city
        state
        postalCode
      }
      organization {
        id
        name
      }
      profileProcedures {
        id
        durationInMinutes
        procedure {
          id
          name
          system
          version
          code
          display
          specialty {
            id
            name
          }
        }
      }
    }
  }
`;

export interface GetProfileInterface {
  id: string;
  displayName: string;
  phone: string;
  profileImgUrl: string;
  location: Location;
  organization: Organization;
  specialties: Specialty[];
  __typename: string;
  profileProcedures: ProfileProcedureInterface[];
  type: string;
  isIntegrated: boolean;
  groupKey: string | null;
}

interface Data {
  GetProfile: GetProfileInterface;
}

interface Variables {
  profileId: string;
}

export interface ProfileProcedureInterface {
  id: string;
  durationInMinutes: number;
  procedure: ProcedureInterface;
  specialty: {
    id: string;
    name: string;
  };
}

const Styles = styled.div`
  .empty {
    padding: 100px;
  }

  .dropdowns .ant-select {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const generateDates = (startDate: moment.Moment): moment.Moment[] => {
  const dates = [];

  // generate 7 days worth of dates
  for (let i = 0; i < 7; i++) {
    const date = _.cloneDeep(startDate);
    date.add(i, 'days');
    dates.push(date);
  }

  // update pointer to end date so we know where to toggle to
  return dates;
};

interface Props {
  profileId: string;
  scheduleId: string;
  organization: ViewerOrganization | null;
}

const MIN_NUMBER_OF_TIMES = 4;

interface ViewSlotsContainerContextInterface {
  dates: moment.Moment[];
  setDates: React.Dispatch<React.SetStateAction<moment.Moment[]>>;
  arrowsColSpan: number;
  slotsColSpan: number;
  maxTimes: number;
  setMaxTimes: React.Dispatch<React.SetStateAction<number>>;
  generateDates: (startDate: moment.Moment) => moment.Moment[];
}

export const ViewSlotsContainerContext = React.createContext<ViewSlotsContainerContextInterface | undefined>(undefined);

export const useViewSlotsContainerContext = (): ViewSlotsContainerContextInterface => {
  const context = React.useContext(ViewSlotsContainerContext);

  if (context === undefined) {
    throw new Error('useViewSlotsContainerContext must be used within a ViewSlotsContainerContext');
  }

  return context;
};

export const ViewSlotsContainer: FC<Props> = ({ profileId, scheduleId, organization }): JSX.Element => {
  const [selectedProcedureId, setSelectedProcedureId] = useState('');
  const [selectedSearchType, setSelectedSearchType] = useState('');
  const [dates, setDates] = useState(generateDates(moment.default()));
  const [maxTimes, setMaxTimes] = useState(MIN_NUMBER_OF_TIMES);

  const { loading, error, data } = useQuery<Data, Variables>(GET_PROFILE_QUERY, {
    variables: { profileId: profileId },
  });

  const profile = data && data.GetProfile;
  const { profileProcedures, spanSize } = useMemo(() => {
    const procedures = profile && profile.profileProcedures ? profile.profileProcedures : [];
    const averageNameLength =
      procedures.reduce((acc, pp) => acc + pp.procedure.name.length + pp.procedure.specialty.name.length, 0) /
      (procedures.length || 1);

    let spanSize;
    if (averageNameLength < 30) {
      spanSize = { searchType: 3, emptySpace: 8, visitType: 13 };
    } else if (averageNameLength < 60) {
      spanSize = { searchType: 3, emptySpace: 6, visitType: 15 };
    } else {
      spanSize = { searchType: 3, emptySpace: 4, visitType: 17 };
    }

    const sortedProcedures = [...procedures].sort((a, b) => a.procedure.name.localeCompare(b.procedure.name));

    return { profileProcedures: sortedProcedures, spanSize };
  }, [profile]);

  useEffect(() => {
    if (profileProcedures.length > 0 && profileProcedures[0]) {
      setSelectedProcedureId(profileProcedures[0].procedure.id);
    }
  }, [profileProcedures]);

  if (loading || !data || !data.GetProfile) return <Spinner />;
  if (error) return <div>Error</div>;

  const contextValue = {
    dates,
    setDates,
    arrowsColSpan: 1,
    slotsColSpan: 3,
    maxTimes,
    setMaxTimes,
    generateDates,
  };

  const getSearchType = (searchType: string): SearchType => {
    return Object.values(SearchType).find(x => x === searchType) || SearchType.All;
  };

  return (
    <ViewSlotsContainerContext.Provider value={contextValue}>
      <Styles>
        <Row justify="end" type="flex" gutter={24} className="dropdowns">
          <Col span={spanSize.searchType}>
            <Select<string>
              showSearch
              placeholder="Select a search type"
              optionFilterProp="children"
              onChange={(searchType): void => {
                setMaxTimes(MIN_NUMBER_OF_TIMES);
                setSelectedSearchType(searchType);
              }}
              defaultValue="All"
            >
              <Option key="all" value="ALL">
                All
              </Option>
              <Option key="consumer" value="CONSUMER">
                Consumer
              </Option>
              <Option key="referral" value="REFERRAL">
                Referral
              </Option>
            </Select>
          </Col>
          <Col span={spanSize.emptySpace} />
          <Col span={spanSize.visitType}>
            <Select<string>
              showSearch
              placeholder="Select a Visit Type"
              optionFilterProp="children"
              onChange={(procedureId): void => {
                setMaxTimes(MIN_NUMBER_OF_TIMES);
                setSelectedProcedureId(procedureId);
              }}
              defaultValue={profileProcedures[0]?.procedure.id || undefined}
              filterOption={(input, option) => {
                if (option.props.children && typeof option.props.children == 'string') {
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                } else {
                  return false;
                }
              }}
            >
              {(profileProcedures || []).map((pp: ProfileProcedureInterface) => {
                return (
                  <Option key={pp.procedure.id} value={pp.procedure.id}>
                    {pp.procedure.specialty.name} - {pp.procedure.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row type="flex" className="time-slots">
          <Col span={24} style={{ marginTop: 12 }}>
            {selectedProcedureId ? (
              <SlotsContainer
                profileId={profileId}
                procedureId={selectedProcedureId}
                scheduleId={scheduleId}
                searchType={getSearchType(selectedSearchType)}
                showToggle={
                  profile?.type !== 'group' &&
                  hasFeature(organization?.featureFlags, FeatureFlag.HasAvailability) &&
                  hasFeature(organization?.featureFlags, FeatureFlag.NativeSlotBlock)
                }
              />
            ) : (
              <Empty image={null} className="empty" description={<i>No visit type or slots configured</i>} />
            )}
          </Col>
        </Row>
      </Styles>
    </ViewSlotsContainerContext.Provider>
  );
};
