import React, { Component } from 'react';
import { createGlobalStyle } from 'styled-components';
import { NormalizedCacheObject, ApolloClient, ApolloProvider } from '@apollo/client';
import { makeClient } from '../../../../graphqlclient';
import { AppContext } from '../../contexts/AppContext';
import { UserTracker } from '../UserTracker';
import Router from './Router';
import { storeLoginToken, removeLoginToken, getLoginToken } from '../../../auth';
import { LoginToken } from '../../../auth/loginToken';

const AppStyles = createGlobalStyle`
  .bodyContainer {
    min-height: 100vh;
  }

  .layoutContainer {
    min-width: 760px;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

interface State {
  client: ApolloClient<NormalizedCacheObject>;
  isAuthenticated: boolean;
}

class App extends Component<{}, State> {
  static contextType = AppContext;

  constructor(props: {}) {
    super(props);

    this.state = {
      client: makeClient(() => this.handleAuthChange(null)),
      isAuthenticated: false,
    };
  }

  componentDidMount = (): void => {
    const token = getLoginToken();
    if (token !== null && token !== '' && token !== undefined) {
      this.handleAuthChange(token);
    }
  };

  handleAuthChange = (token: LoginToken): void => {
    const isAuthenticated = !!token;

    if (token === null) {
      this.context.reset();
      removeLoginToken();
      this.state.client.resetStore().then();
    } else {
      storeLoginToken(token);
    }

    this.context.setIsAuthenticated(isAuthenticated);
    this.setState({ isAuthenticated });
  };

  render(): JSX.Element {
    return (
      <ApolloProvider client={this.state.client}>
        <AppStyles />
        <Router handleAuthChange={this.handleAuthChange} isAuthenticated={this.context.isAuthenticated}>
          <UserTracker />
        </Router>
      </ApolloProvider>
    );
  }
}

export default App;
