import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TextArea } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputEducationTraining: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Education & Training:">
    {form.getFieldDecorator('educationTraining', { initialValue, rules: [{ required: false }] })(
      <TextArea allowClear placeholder="Education & Training" rows={2} />
    )}
  </Form.Item>
);
