import React, { useEffect, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { Spinner } from '../../../../components';
import { defaultTheme } from '../../../../themes/default';
import { AppContext } from '../../contexts/AppContext';
import AntDesignStyleOverrides from './AntDesignStyleOverrides';
import { AppHeader } from './AppHeader'; // FIXME: everything under src/App should be core
import { useQuery } from '@apollo/client';
import { GetSimplifiedViewer } from '../../../../hooks/queries/useGetViewerOrganizationQuery';
import { useViewerQuery } from '../../../../hooks/queries/useViewerQuery';
const Layout = ({ children }) => {
  const ORGANIZATION_LIMIT = 1;
  const { currentLocation, currentOrganization, viewer, init } = useContext(AppContext);
  const { data: simplifiedViewer, loading: loadingSimplifiedViewer } = useQuery(GetSimplifiedViewer, {
    variables: { skip_locations: true },
  });
  const { data: viewerData, loading: loadingViewerData } = useViewerQuery({
    variables: { limit: ORGANIZATION_LIMIT },
  });

  useEffect(() => {
    if (simplifiedViewer && viewerData && !loadingSimplifiedViewer && !loadingViewerData) {
      const newOrgs = [
        ...viewerData.viewer.organizations.slice(0, ORGANIZATION_LIMIT),
        ...simplifiedViewer.viewer.organizations.slice(ORGANIZATION_LIMIT),
      ];

      const updatedData = {
        viewer: {
          ...viewerData.viewer,
          organizations: newOrgs,
        },
      };
      init(updatedData.viewer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simplifiedViewer, viewerData, loadingSimplifiedViewer, loadingViewerData]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <AntDesignStyleOverrides>
        <div className="layoutContainer">
          <AppHeader />
          {currentLocation && currentOrganization && viewer ? (
            <div className={`flex justify-center font-source-sans-pro`} style={{ flex: 1, minHeight: '86vh' }}>
              <div className="py-4 w-full max-w-3xl min-h-100-vh flex flex-col">
                <div>{children}</div>
                <div className="px-4 pt-4 w-full text-grey-dark text-xs text-center">
                  <span>
                    <a className="text-grey-dark" href="https://www.blockitnow.com/terms">
                      Terms of Service
                    </a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a className="text-grey-dark" href="https://www.blockitnow.com/use">
                      Acceptable Use Policy
                    </a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a className="text-grey-dark" href="https://www.blockitnow.com/privacy">
                      Privacy Policy
                    </a>
                    &nbsp;&nbsp;© 2018-2020 Blockit, Inc. All Rights Reserved.{' '}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </AntDesignStyleOverrides>
    </ThemeProvider>
  );
};

// needed because the All Locations location of every organization has
// a null id, therefore sends and receives fields go off of whatever is in the
// cache, so we need to update the sends and receives fields for each
// All Location based on the other locations

export const updateSendsAndReceivesForAllLocationsLocation = ({ viewer }) => {
  return {
    ...viewer,
    organizations: [
      ...viewer.organizations.map(org => {
        if (!org.locations) {
          return org;
        }
        const allLocations = org.locations[0];
        const otherLocations = org.locations.slice(1, org.locations.length);
        const sendsAndReceives = otherLocations.reduce((acc, loc) => {
          return {
            sends: acc.sends || loc.sends,
            receives: acc.receives || loc.receives,
          };
        }, {});

        const updateLocations = [{ ...allLocations, ...sendsAndReceives }, ...otherLocations];
        return { ...org, locations: [...updateLocations] };
      }),
    ],
  };
};

export default Layout;
