import React, { FC } from 'react';
import { Switch, Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectJumpQ: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Use the JumpQ?">
    {form.getFieldDecorator('sendOffers', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Switch />)}
  </Form.Item>
);
