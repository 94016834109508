import React, { FC, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Button, DatePicker, Icon } from 'antd';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import { defaultTheme } from '../../../themes/default';

interface Props {
  onChange: (date: DateTime) => void;
}

const Styles = styled.div`
  .jump-to-date-button {
    color: ${({ theme }) => theme.primaryColor};
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    height: auto;
    padding-left: 0;
    text-decoration: none solid;
  }
`;

export const JumpToDate: FC<Props> = ({ onChange }): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (date: Moment | null, dateString: string): void => {
    if (date) {
      onChange(DateTime.fromISO(date.toISOString()));
      return;
    }

    onChange(DateTime.local());
  };

  const handleDisabledDate = (current: Moment | null): boolean => {
    if (!current) return false;

    const date = DateTime.fromISO(current.toISOString());
    const diff = date.diff(DateTime.fromObject({ hour: 23, minute: 59, second: 59 }), ['days']).toObject().days;
    if (diff !== undefined) {
      return diff <= -1;
    }

    return true;
  };

  const handleOpenChange = (status: boolean): void => {
    if (!status) {
      setOpen(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        {!open && (
          <Button className="jump-to-date-button" type="link" onClick={() => setOpen(true)}>
            <Icon type="calendar" />
            Jump To Date
          </Button>
        )}
        {open ? (
          <DatePicker
            allowClear={false}
            disabledDate={handleDisabledDate}
            format="MM/DD/YYYY"
            onChange={handleChange}
            onOpenChange={handleOpenChange}
            open={open}
            placeholder="Jump To Date"
            size="small"
          />
        ) : null}
      </Styles>
    </ThemeProvider>
  );
};
