import { gql } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AppContext } from '../../core/contexts/AppContext';
import { Spinner } from '../../../components';
import { DirectoryOrganizationsPage } from '../pages/DirectoryOrganizationsPage';
import { DirectoryProfilePage } from '../pages/DirectoryProfilePage';
import { DirectoryProfilesPage } from '../pages/DirectoryProfilesPage';
import { Directory, DirectoryContext } from './DirectoryContext';

interface Data {
  getDirectory: Directory;
}

interface Variables {
  organizationId: string;
}

const getDirectoryQuery = gql`
  query getDirectoryQuery($organizationId: ID!) {
    getDirectory(organizationId: $organizationId) {
      organizations {
        id
        name
        profileImgUrl
        specialties {
          id
          name
        }
      }
      specialties {
        id
        name
      }
    }
  }
`;

export const DirectoryRoutes: FC<RouteComponentProps> = ({ match }): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);

  const [organizationNameFilter, setOrganizationNameFilter] = useState('');
  const [profileNameFilter, setProfileNameFilter] = useState('');
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');
  const [selectedSpecialtyId, setSelectedSpecialtyId] = useState('');

  const { data, error, loading } = useQuery<Data, Variables>(getDirectoryQuery, {
    variables: { organizationId: currentOrganization?.id || '' },
  });

  if (loading) return <Spinner />;
  if (error || !data) return <div>Error</div>;

  return (
    <DirectoryContext.Provider
      value={{
        directory: data.getDirectory,
        organizationNameFilter,
        profileNameFilter,
        selectedOrganizationId,
        selectedSpecialtyId,
        setOrganizationNameFilter,
        setProfileNameFilter,
        setSelectedOrganizationId,
        setSelectedSpecialtyId,
      }}
    >
      <Switch>
        <Route exact path={match.path} key="route--directory" component={DirectoryOrganizationsPage} />
        <Route
          exact
          path={`${match.path}/profiles`}
          key="route--directory-profiles"
          component={DirectoryProfilesPage}
        />

        <Route
          exact
          path={`${match.path}/profiles/:profileId`}
          key="route--directory-profiles-profile"
          component={DirectoryProfilePage}
        />
      </Switch>
    </DirectoryContext.Provider>
  );
};
