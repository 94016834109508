/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import config from '../../../config';
import { ApolloError, useQuery } from '@apollo/client';
import { Card, Col, Icon, message, Row, Tabs } from 'antd';
import { StringParam, useQueryParam } from 'use-query-params';
import { AppContext } from '../../core/contexts/AppContext';
import { getProfileQuery } from '../hooks/useGetProfileQuery';
import { isBlockitAdmin } from '../../core/components/PrivateRoute';
import { Profile } from '../index';
import { ProfileFormData } from './ProfileForm';
import { ProfileSettingsAdmin } from './ProfileSettingsAdmin';
import { ProfileSettingsAdvanced } from './ProfileSettingsAdvanced';
import { ProfileSettingsContact } from './ProfileSettingsContact';
import { ProfileSettingsInfo } from './ProfileSettingsInfo';
import { UpdateOrganizationMutationData, useUpdateScheduleMutation } from '../hooks/useUpdateScheduleMutation';
import { ProfileVisitTypes, ProfileVisitTypesAlert } from './ProfileVisitTypes';
import { ViewSlotsContainer } from './ViewSlots/ViewSlotsContainer';
import InsuranceManagement from '../../organizations/pages/InsuranceManagement';
import SlotManagementComponent from '../../slotManagement/pages/AvailabilityManagement';
import { ProfileConsumerSettingsFormCard } from './ProfileConsumerSettingsCard';
import { ProfileTable } from './ProfileTable';
import { gql } from '@apollo/client';
import { Spinner } from '../../../components';
import _ from 'lodash';

interface Props {
  profile: Profile;
}

const { TabPane } = Tabs;

const Styles = styled.div`
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    padding-left: 8px;
    text-align: left;
  }
`;

const getOrganizationQuery = gql`
  query($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      profiles {
        id
        displayName
        phone
        type
        scheduleId
        profileImgUrl
        isActive
        isWaitList
        profileProcedures {
          id
        }
        location {
          id
          name
          address1
          address2
          city
          state
          postalCode
          latitude
          longitude
        }
      }
    }
  }
`;

export const ProfileSettings: FC<Props> = ({ profile }): JSX.Element => {
  const [settingsTab, setSettingsTab] = useQueryParam('settingsTab', StringParam);
  const { currentOrganization } = useContext(AppContext);

  const handleUpdateScheduleCompleted = ({ updateSchedule: { id } }: UpdateOrganizationMutationData): void => {
    window.scrollTo(0, 0);
    message.success('Success!  Your profile has been updated.', 5);
  };

  const { data, error, loading } = useQuery(getOrganizationQuery, {
    variables: {
      organizationId: currentOrganization?.id,
    },
  });

  const handleUpdateScheduleError = (error: ApolloError): void => {
    // I can't believe this is the best way to rewrite errors
    if (error.message === 'GraphQL error: slug: has already been taken') {
      message.error('The slug has already been taken! It must be a unique value.');
    } else if (error.message === 'GraphQL error: npi_number: has invalid format') {
      message.error('The NPI number should only contain digits.');
    } else {
      message.error('Something went wrong! Please try again or contact us if the problem persists.');
    }
  };

  const [updateSchedule] = useUpdateScheduleMutation({
    onCompleted: handleUpdateScheduleCompleted,
    onError: handleUpdateScheduleError,
    refetchQueries: [{ query: getProfileQuery, variables: { id: profile.id } }],
  });

  const handleSubmit = (values: ProfileFormData): void => {
    const profileLanguageCodes: string[] = [];
    profile.languages.map(l => profileLanguageCodes.push(l.code));

    const undefinedDefault = (v: string | null | undefined, fallback: string): string => {
      if (v === undefined || v === null) return fallback;
      return v;
    };

    const scheduleInput = {
      displayName: undefinedDefault(values.displayName, profile.displayName),
      prefix: undefinedDefault(values.prefix, profile.prefix),
      firstName: undefinedDefault(values.firstName, profile.firstName),
      lastName: undefinedDefault(values.lastName, profile.lastName),
      suffix: undefinedDefault(values.suffix, profile.suffix),
      locationId: values.locationId || profile.location.id,
      email: undefinedDefault(values.email, profile.email),
      npiNumber: undefinedDefault(values.npiNumber, profile.npiNumber),
      slug: undefinedDefault(values.slug, profile.slug),
      groupKey: undefinedDefault(values.groupKey, profile.groupKey),
      phone: undefinedDefault(values.phone, profile.phone),
      profileImgUrl: undefinedDefault(values.profileImgUrl, profile.profileImgUrl),
      specialty: undefinedDefault(values.specialty, profile.specialty),
      tags: undefinedDefault(values.tags, profile.tags),
      type: values.type || profile.type,
      boardCertifications: undefinedDefault(values.boardCertifications, profile.boardCertifications),
      educationTraining: undefinedDefault(values.educationTraining, profile.educationTraining),
      generalPatientInstructions: undefinedDefault(
        values.generalPatientInstructions,
        profile.generalPatientInstructions
      ),
      referralSearchNotice: undefinedDefault(values.referralSearchNotice, profile.referralSearchNotice),
      languageCodes:
        values.languageCodes !== undefined ? (values.languageCodes ? values.languageCodes : []) : profileLanguageCodes,
      professionalStatement: undefinedDefault(values.professionalStatement, profile.professionalStatement),
      hasConsumerScheduling:
        values.hasConsumerScheduling === undefined ? profile.hasConsumerScheduling : values.hasConsumerScheduling,
      hasAppointmentRequests:
        values.hasAppointmentRequests === undefined ? profile.hasAppointmentRequests : values.hasAppointmentRequests,
      hasConsumerSchedulingSlots:
        values.hasConsumerSchedulingSlots === undefined
          ? profile.hasConsumerSchedulingSlots
          : values.hasConsumerSchedulingSlots,
      hasReferralScheduling:
        values.hasReferralScheduling === undefined ? profile.hasReferralScheduling : values.hasReferralScheduling,
      hasReferralSchedulingSlots:
        values.hasReferralSchedulingSlots === undefined
          ? profile.hasReferralSchedulingSlots
          : values.hasReferralSchedulingSlots,
      sendOffers: values.sendOffers === undefined ? profile.sendOffers : values.sendOffers,
      isActive: values.isActive === undefined ? profile.isActive : values.isActive,
      isWaitList: values.isWaitList === undefined ? profile.isWaitList : values.isWaitList,
      isGlobal: values.isGlobal === undefined ? profile.isGlobal : values.isGlobal,
      isIntegrated: values.isIntegrated === undefined ? profile.isIntegrated : values.isIntegrated,
      isSendToPatient: values.isSendToPatient === undefined ? profile.isSendToPatient : values.isSendToPatient,
      isSendToProvider: values.isSendToProvider === undefined ? profile.isSendToProvider : values.isSendToProvider,
      onlyAllowsAgeMin: values.onlyAllowsAgeMin === '' ? null : values.onlyAllowsAgeMin || profile.onlyAllowsAgeMin,
      onlyAllowsAgeMax: values.onlyAllowsAgeMax === '' ? null : values.onlyAllowsAgeMax || profile.onlyAllowsAgeMax,
      slotStartBufferInM: undefinedDefault(values.slotStartBufferInM, profile.slotStartBufferInM),
    };

    if (currentOrganization) {
      updateSchedule({
        variables: { scheduleId: profile.id, scheduleInput },
      }).then();
    }
  };

  const [innerTab, setInnerTab] = useState('template');

  if (loading) return <Spinner />;
  if (data) {
    let organization = data.getOrganization;

    // it's possible that he current profile is not in the organization.profiles list due to a hard limit of 5000
    let profiles = [profile, ...organization.profiles];
    profiles = _.uniqBy(profiles, p => p.id);

    organization = { ...organization, profiles: profiles };

    return (
      <Styles>
        <Tabs
          key={settingsTab}
          defaultActiveKey={settingsTab || 'settings'}
          onChange={setSettingsTab}
          size="small"
          tabBarGutter={0}
          tabBarStyle={{ textAlign: 'left' }}
          tabPosition="left"
          onTabClick={(key: string) => {
            setInnerTab('template');
            if (key === 'admin') {
              window.open(`${config.endpoint}/admin/profile/${profile.id}`, '_blank');
            }
          }}
        >
          <TabPane tab="Profile Info" key="profile">
            <Row>
              <Col span={18} style={{ marginBottom: 8 }}>
                <ProfileVisitTypesAlert profile={profile} />
              </Col>
            </Row>
            <ProfileSettingsInfo onSubmit={handleSubmit} profile={profile} />
          </TabPane>
          <TabPane tab="Contact Info" key="contact">
            <ProfileSettingsContact onSubmit={handleSubmit} profile={profile} />
          </TabPane>

          <TabPane tab="Schedule & Visit Types" key="slots">
            <Card
              title="Schedule & Visit Types"
              tabList={[
                { key: 'template', tab: 'Template' },
                { key: 'slots', tab: 'Time Slots' },
              ]}
              onTabChange={key => setInnerTab(key)}
            >
              {innerTab === 'slots' && (
                <ViewSlotsContainer
                  profileId={profile.id}
                  scheduleId={profile.scheduleId}
                  organization={currentOrganization}
                />
              )}
              {innerTab === 'template' && profile.type !== 'group' && (
                <>
                  <SlotManagementComponent
                    {...{
                      scopedProfile: profile.scheduleId,
                      scopedProfileId: profile.id,
                      currentOrganization: currentOrganization,
                      profiles: organization.profiles,
                    }}
                  >
                    <ProfileVisitTypes profile={profile} />
                  </SlotManagementComponent>
                </>
              )}
              {innerTab === 'template' && profile.type === 'group' && (
                <Row gutter={12}>
                  <Col span={18}>
                    <ProfileTable active={true} nameFilter={`group_key:${profile.groupKey}`} />
                  </Col>
                  <Col span={6}>
                    <ProfileVisitTypes profile={profile} />
                  </Col>
                </Row>
              )}
            </Card>
          </TabPane>
          <TabPane tab="Consumer Settings" key="consumer">
            <ProfileConsumerSettingsFormCard onSubmit={handleSubmit} profile={profile} />
          </TabPane>
          <TabPane tab="Insurance Management" key="insurance">
            <InsuranceManagement {...profile} />
          </TabPane>
          <TabPane tab="Advanced Settings" key="advanced">
            <ProfileSettingsAdvanced onSubmit={handleSubmit} profile={profile} />
          </TabPane>
          {isBlockitAdmin(currentOrganization) && <Tabs.TabPane key="empty-tab" tab={<></>} />}
          {isBlockitAdmin(currentOrganization) && (
            <Tabs.TabPane
              tab={
                <>
                  <Icon type="lock" />
                  Admin Config
                </>
              }
              key="admin-config"
            >
              <ProfileSettingsAdmin onSubmit={handleSubmit} profile={profile} />
            </Tabs.TabPane>
          )}
          {isBlockitAdmin(currentOrganization) && (
            <Tabs.TabPane
              tab={
                <>
                  <Icon type="lock" />
                  Admin Console
                </>
              }
              key="admin"
            ></Tabs.TabPane>
          )}
        </Tabs>
      </Styles>
    );
  } else {
    return <p>Error: {error}</p>;
  }
};
