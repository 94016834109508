import { FC } from 'react';
import { FeatureFlag as FeatureFlagInterface } from '../modules/organizations';
import { isBlockitAdmin } from '../modules/core/components/PrivateRoute';
import { useAppContext } from '../hooks';

export enum FeatureFlag {
  AppointmentAccelerator = 'APPOINTMENT_ACCELERATOR',
  AppointmentConfirmations = 'APPOINTMENT_CONFIRMATIONS',
  ConsumerScheduling = 'CONSUMER_SCHEDULING',
  ConsumerSchedulingShowWeekends = 'CONSUMER_SCHEDULING_SHOW_WEEKENDS',
  EditPatient = 'EDIT_PATIENT',
  EditPatientCoverage = 'EDIT_PATIENT_COVERAGE',
  EmrApptNotesEmpty = 'EMR_APPT_NOTES_EMPTY',
  EmrApptNotesLinkOnly = 'EMR_APPT_NOTES_LINK_ONLY',
  EmrApptNotesPatientCoverage = 'EMR_APPT_NOTES_PATIENT_COVERAGE',
  HasAvailability = 'HAS_AVAILABILITY',
  HasAvailabilityLimits = 'HAS_AVAILABILITY_LIMITS',
  HasNetworkGroups = 'HAS_NETWORK_GROUPS',
  HasPatientDirectory = 'HAS_PATIENT_DIRECTORY',
  HasPatientItinerary = 'HAS_PATIENT_ITINERARY',
  HasProviderDirectory = 'HAS_PROVIDER_DIRECTORY',
  HasSchedulePatient = 'HAS_SCHEDULE_PATIENT',
  HasSendToAccessCenter = 'HAS_SEND_TO_ACCESS_CENTER',
  HasUnlistedProvider = 'HAS_UNLISTED_PROVIDER',
  HasWaitList = 'HAS_WAIT_LIST',
  HidePatientItineraryAppointmentTime = 'HIDE_PATIENT_ITINERARY_APPOINTMENT_TIME',
  ManuallyAddPatient = 'MANUALLY_ADD_PATIENT',
  Notifications = 'NOTIFICATIONS_BETA',
  PhreesiaSMS = 'PHREESIA_SMS',
  ProviderManagement = 'PROVIDER_MANAGEMENT',
  ReferralSearchWorkflowQueue = 'REFERRAL_SEARCH_WORKFLOW_QUEUE',
  ReferralTagSearch = 'REFERRAL_TAG_SEARCH',
  RestrictCSVExportsForBlockitAdmins = 'RESTRICT_CSV_EXPORTS_FOR_BLOCKIT_ADMINS',
  RestrictUsersProfileManagement = 'RESTRICT_USERS_PROFILE_MANAGEMENT',
  SendToPatient = 'SEND_TO_PATIENT',
  SendToProvider = 'SEND_TO_PROVIDER',
  ShowInsuranceMatch = 'SHOW_INSURANCE_MATCH',
  ShowMRN = 'SHOW_MRN',
  WorkflowQueuesV2 = 'WORKFLOW_QUEUES_V2',
  HideReferralStatusColumn = 'HIDE_REFERRAL_STATUS_COLUMN',
  DataAnalytics = 'DATA_ANALYTICS',
  CustomerDataImport = 'CUSTOMER_DATA_IMPORT',
  NativeSlotBlock = 'NATIVE_SLOT_BLOCK',
  EnablePdmV2 = 'ENABLE_PDM_V2',
}

export const FeatureFlagHints: { [key: string]: { name: string; text: string; warning?: string } } = {
  [FeatureFlag.ReferralSearchWorkflowQueue]: {
    name: 'Referral Search Workflow Queue Button',
    text: 'Enables "Send to Workflow" button in Referral Search',
  },

  [FeatureFlag.ManuallyAddPatient]: {
    name: 'Manually add patients',
    text: 'Enables manually creation of patients from Schedule a Patient workflow',
  },

  [FeatureFlag.EditPatient]: {
    name: 'Edit patients',
    text: 'Enables editing of patients from UI',
  },

  [FeatureFlag.EditPatientCoverage]: {
    name: 'Add patient coverage (referral and appointment page)',
    text: 'Enables adding missing patient coverage',
  },

  [FeatureFlag.HasAvailability]: {
    name: 'Availability Management',
    text: 'Enables the availability page for self-service schedule management',
  },

  [FeatureFlag.HasAvailabilityLimits]: {
    name: 'Availability Limits',
    text: 'Enables setting availability limits',
  },

  [FeatureFlag.ShowInsuranceMatch]: {
    name: 'Insurance Matching',
    text: 'Enables Insurance Matching on the search results Page (Sender Only)',
  },

  [FeatureFlag.HasUnlistedProvider]: {
    name: 'Send to Unlisted Provider',
    text: 'Enables the unlisted provider button on the search results Page (Sender Only)',
    warning: 'NO LONGER USE, Send to WorkQueue should be utilized instead.',
  },

  [FeatureFlag.HasSendToAccessCenter]: {
    name: 'Send to Access Center',
    text: 'Enables the access center button on the search results Page (Sender Only)',
    warning: 'NO LONGER USE, Send to WorkQueue should be utilized instead.',
  },

  [FeatureFlag.SendToProvider]: {
    name: 'Enable Profile Send to Provider',
    text:
      'Allows receiving org to toggle send to provider per profile. Needs to be enabled per-profile (Receiver Only)',
  },

  [FeatureFlag.SendToPatient]: {
    name: 'Enable Profile Send to Patient',
    text: 'Allow receiving org to toggle send to patient per profile. Needs to be enabled per-profile (Receiver Only)',
  },

  [FeatureFlag.ConsumerScheduling]: {
    name: 'Consumer Scheduling',
    text: 'Enables the organization for consumer scheduling',
  },

  [FeatureFlag.ConsumerSchedulingShowWeekends]: {
    name: 'Consumer Scheduling - Show Weekends',
    text: 'Show weekends by default on consumer scheduling search results.',
  },

  [FeatureFlag.HasNetworkGroups]: {
    name: 'Enable Network Groups',
    text: 'Enables management of network groups (will not disable existing network groups)',
  },

  [FeatureFlag.HasProviderDirectory]: {
    name: 'Enable Provider Directory',
    text: 'Enables access to the provider directory. Will still be visible in other accounts provider directory',
  },

  [FeatureFlag.HasSchedulePatient]: {
    name: 'Enable Schedule a Patient',
    text: 'Enables access to the Schedule a Patient workflow',
  },

  [FeatureFlag.EmrApptNotesLinkOnly]: {
    name: 'EMR Appt Notes - Only write link',
    text: 'If enabled, only write the link to blockit appointment in the EMR instead of referral information',
  },

  [FeatureFlag.EmrApptNotesEmpty]: {
    name: 'EMR Appt Notes - Leave Empty',
    text: 'If enabled, we will not include a blockit appointment link or referral information',
  },

  [FeatureFlag.ShowMRN]: {
    name: 'Show Sender MRN in Appointment/Referral Table',
    text: 'Shows Sender MRN in Appointment/Referral table',
  },

  [FeatureFlag.Notifications]: {
    name: 'Notifications (Beta)',
    text: 'Enable SMS Notifications',
  },

  [FeatureFlag.PhreesiaSMS]: {
    name: 'Phreesia SMS (NLUC ONLY CURRENTLY)',
    text: 'Send Patient a text from Phreesia for registration forms. Only for NLUC.',
  },

  [FeatureFlag.ReferralTagSearch]: {
    name: 'Referral Tag Search',
    text: 'Enable tag searching within referral search',
  },

  [FeatureFlag.HasPatientDirectory]: {
    name: 'Patient Directory',
    text: 'Enable patient directory tab',
  },

  [FeatureFlag.AppointmentConfirmations]: {
    name: 'Appointment Confirmations',
    text: 'If enabled, new appointments will stay pending until they are confirmed by the organization',
  },

  [FeatureFlag.AppointmentAccelerator]: {
    name: 'Appointment Accelerator',
    text: 'If enabled, cancelled appointments will be offered to other patients with later appointments via SMS',
  },

  [FeatureFlag.EmrApptNotesPatientCoverage]: {
    name: 'Patient Coverage in notes',
    text: 'If enabled, new consumer appointments will include the coverage information in the appointment notes',
  },

  [FeatureFlag.HasPatientItinerary]: {
    name: 'Enable patient itinerary',
    text: 'If enabled, organization admins can view all patient itineraries on a specific date',
  },

  [FeatureFlag.HidePatientItineraryAppointmentTime]: {
    name: 'Hide patient itinerary appointment time',
    text: 'Only enable if the appointment note is expected to contain the appointment time',
  },

  [FeatureFlag.WorkflowQueuesV2]: {
    name: 'Enable workflow queues v2',
    text: 'If enabled, organization will have access to the new version of workflow queues',
  },

  [FeatureFlag.HasWaitList]: {
    name: 'Enable Get-in-Line profiles',
    text: 'If enabled, organization admins can configure profiles to support Get-in-Line',
  },

  [FeatureFlag.ProviderManagement]: {
    name: 'Enable Provider Self Sign Up',
    text: 'If enabled, organization can access Provider Management dashboad and send sign up links',
  },
  [FeatureFlag.RestrictCSVExportsForBlockitAdmins]: {
    name: 'Restrict CSV exports for Blockit admins',
    text: 'If enabled, Blockit admins cannot export appointment, referral, and WorkQ CSVs',
  },
  [FeatureFlag.RestrictUsersProfileManagement]: {
    name: 'Restricts users from making profile/location updates',
    text: 'MHHS Specific user limitation, removes write access from profile/location records',
  },
  [FeatureFlag.HideReferralStatusColumn]: {
    name: 'Hides the referral status column',
    text: 'If enabled, the referral table will not include the referral status column',
  },
  [FeatureFlag.DataAnalytics]: {
    name: 'Enable Dashboard',
    text: 'Enables the dashboard',
  },
  [FeatureFlag.CustomerDataImport]: {
    name: 'Customer Data Import',
    text: 'Enables the profile importer to organization admins',
    warning: 'Customer specific, check with engineering before enabling',
  },

  [FeatureFlag.NativeSlotBlock]: {
    name: 'Native Slot Block',
    text: 'Enables the native slot toggles',
  },

  [FeatureFlag.EnablePdmV2]: {
    name: 'Enable PDM v2',
    text: 'If enabled, organization will have access to the new version of Profiles & Locations',
  },
};

type ChildrenFunc = () => JSX.Element;

interface FeatureGateProps {
  feature: FeatureFlag | FeatureFlag[];
  children: ChildrenFunc | JSX.Element;
  fallbackContent?: JSX.Element;
}

export const FeatureGate: FC<FeatureGateProps> = ({ feature, children, fallbackContent }): JSX.Element | null => {
  const { currentOrganization } = useAppContext();

  if (hasFeature(currentOrganization?.featureFlags, feature)) {
    if (children instanceof Function) {
      return children();
    } else {
      return children as JSX.Element;
    }
  }

  return fallbackContent || null;
};

interface BlockRestrictedUsersProps {
  children: ChildrenFunc | JSX.Element;
  fallbackContent?: JSX.Element;
}

export const BlockRestrictedUsers: FC<BlockRestrictedUsersProps> = ({
  children,
  fallbackContent,
}): JSX.Element | null => {
  const { currentOrganization } = useAppContext();

  if (
    !hasFeature(currentOrganization?.featureFlags, FeatureFlag.RestrictUsersProfileManagement) ||
    isBlockitAdmin(currentOrganization)
  ) {
    if (children instanceof Function) {
      return children();
    } else {
      return children as JSX.Element;
    }
  }

  return fallbackContent || null;
};

export const hasFeature = (
  featureFlags: FeatureFlagInterface[] | undefined,
  flagName: FeatureFlag | FeatureFlag[]
): boolean => {
  const flags = featureFlags || [];

  if (Array.isArray(flagName)) {
    return flagName.some(flag => hasFeature(featureFlags, flag));
  } else {
    return flags.filter(flag => flag.name === flagName).length > 0;
  }
};
