import React, { FC } from 'react';
import { Checkbox, Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
}

export const CheckboxTos: FC<Props> = ({ form }): JSX.Element => {
  const tosValidator = (rule: string, value: boolean | undefined, callback: (arg0?: string) => void): void => {
    if (value === false) {
      callback('Please agree to continue.');
    } else {
      callback();
    }
  };

  return (
    <Form.Item label="">
      {form.getFieldDecorator('tos', {
        rules: [{ message: 'Please agree to continue.', required: true }, { validator: tosValidator }],
        valuePropName: 'checked',
      })(
        <Checkbox>
          I agree to the Blockit&nbsp;
          <a href="https://www.blockitnow.com/terms" target="_blank" rel="noopener noreferrer">
            Terms &amp; Conditions
          </a>
          &nbsp;and&nbsp;
          <a href="https://www.blockitnow.com/use" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
        </Checkbox>
      )}
    </Form.Item>
  );
};
