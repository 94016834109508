import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string | null;
}

export const InputPatientLastName: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Patient's Legal Last Name">
    {form.getFieldDecorator('familyName', {
      initialValue: initialValue,
      rules: [
        {
          required: true,
          message: "Please enter patient's last name.",
        },
      ],
    })(<Input allowClear />)}
  </Form.Item>
);
