import * as Sentry from '@sentry/browser';
import { Avatar, Button, Card, Col, Divider, Icon, message, Row } from 'antd';
import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { history } from '../../../../core/components/App/history';
import { CodeSystemLookup } from '../../CodeSystemLookup';
import SearchHeaderPatientCard from '../components/SearchHeaderPatientCard';

const createProfileReferralMutation = gql`
  mutation CreateProfileReferral($createProfileReferralInput: CreateProfileReferralInput!) {
    createProfileReferral(createProfileReferralInput: $createProfileReferralInput) {
      id
    }
  }
`;

const Styles = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  .main-card {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .profile-image {
    border-radius: 0;
    height: 8rem;
    width: 200px;
    background-image: url(${props => props.profile.profileImgUrl});
    background-size: cover;
    margin-bottom: 1rem;
    max-width: 120px;
  }

  .address {
    font-size: 12px;
  }

  .ant-row-flex {
    margin-top: 12px;
  }

  .profile-name {
    font-weight: 800;
  }

  .name-section {
    margin-bottom: 1rem;
  }
`;

export const ConfirmSendToProvider = ({ timeSlot: { profile }, getReferral, formValues, prev }) => {
  const [loading, setLoading] = useState(false);

  const [createProfileReferral] = useMutation(createProfileReferralMutation, {
    onCompleted: () => {
      message.success(`Successfully sent referral to profile.`).then(
        () => {
          setLoading(false);
          history.replace(`/referrals/${getReferral.id}`);
        },
        () => {}
      );
    },
    onError: error => {
      setLoading(false);
      Sentry.captureException(error);
      message.error('There was an error sending this referral to the profile.  Please try again in a few minutes');
    },
  });

  const handleConfirm = () => {
    setLoading(true);

    let code = '';
    let system = '';

    if (formValues.serviceType) {
      const serviceTypeVal = JSON.parse(formValues.serviceType);
      code = serviceTypeVal.code;
      system = serviceTypeVal.system;
    }

    // todo - adding diagnosis info here is silly.  it should be added direct to referral in add info step
    createProfileReferral({
      variables: {
        createProfileReferralInput: {
          patientEmail: formValues.patientEmail || null,
          profileId: profile.id,
          referralId: getReferral.id,
          diagnoses: formValues.diagnoses || [],
          diagnosesDescription: formValues.notes || null,
          serviceTypeCode: code,
          serviceTypeSystem: system,
        },
      },
    }).then(() => {});
  };

  return (
    <Styles profile={profile}>
      <Row type="flex" justify="space-around" gutter={24} style={{ marginBottom: '2rem' }}>
        <Col span={20}>
          <SearchHeaderPatientCard referral={getReferral} />
        </Col>
        <Col span={4}></Col>
      </Row>
      <Row type="flex" justify="center">
        <Col sm={20} md={20} lg={18} xl={14}>
          <Card title="Confirm Send To Profile" className="main-card">
            <Row type="flex" justify="center" gutter={24}>
              <Col>
                {profile.profileImgUrl ? (
                  <div className="profile-image" />
                ) : (
                  <Avatar shape="square" size={128} icon="user" />
                )}
              </Col>
              <Col span={16}>
                <div className="name-section">
                  <div className="profile-name">{profile.displayName}</div>
                  <div>{profile.specialty}</div>
                  <div>{profile.organization.name}</div>
                </div>
                <Row type="flex" justify="start" gutter={24}>
                  <Col>
                    <div className="address">{`${profile.location.name}`}</div>
                    <div className="address">{`${profile.location.address1}`}</div>
                    {profile.location.address2 ? <div className="address">{`${profile.location.address2}`}</div> : null}
                    <div className="address">
                      {`${profile.location.city}, ${profile.location.state} ${profile.location.postalCode}`}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <CodeSystemLookup referralId={getReferral.id} profileId={profile.id} />
            <Row type="flex" justify="end" gutter={24}>
              <Col>
                <Button className="prev-button" onClick={prev} block>
                  <Icon type="left" /> Back
                </Button>
              </Col>
              <Col>
                <Button className="next-button" onClick={handleConfirm} block type="primary" loading={loading}>
                  Confirm
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Styles>
  );
};
