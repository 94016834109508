import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import { Specialty } from './useGetProcedureQuery';

const listSpecialtiesQuery = gql`
  query listSpecialtiesQuery($isActive: Boolean) {
    listSpecialties(isActive: $isActive) {
      id
      name
      procedures {
        id
        name
      }
    }
  }
`;

interface Data {
  listSpecialties: Specialty[];
}

interface Variables {
  isActive: boolean;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useListSpecialtiesQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(listSpecialtiesQuery, options);
};
