import React, { Component } from 'react';
import { Alert, Row, Col, Card, Typography } from 'antd';
import Select from 'react-select';
import { AuthorizeAvailability } from '../../../components/AuthorizeAvailability';
import { isAdmin, isBlockitAdmin, isEditor } from '../../core/components/PrivateRoute';
import {
  AvailabilitiesCalendar,
  AvailabilityLimitModal,
  AvailabilityLimitSection,
  BlackoutDateSection,
  CreateAvailabilityModal,
  CreateBlackoutDateModal,
  TableMainSection,
} from '../components/availabilityManagement';
import SlotDurationDropDown from '../components/availabilityManagement/SlotDurationDropDown';
import {
  CalendarAndLabelsWrapper,
  CalendarWrapper,
  DayOfWeekLabel,
  DaysOfWeekLabels,
  Table,
} from '../styles/calendar.styled';
import { FeatureGate, FeatureFlag, hasFeature } from '../../../helpers';
import _ from 'lodash';

const AlertAvailEMR = () => {
  return (
    <Alert
      showIcon
      description="Great news! Blockit is integrated with your EHR and your schedule template is managed natively. Any changes in your EMR will automatically be reflected in Blockit!"
    />
  );
};

class SlotManagementComponent extends Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();

    this.state = {
      selectedProfile: null,
      scopedProfile: props.scopedProfile || null,
      scopedProfileId: props.scopedProfileId || null,
      loading: false,
      availabilities: [],
      setAvailCount: null,
      profiles: props.profiles || [],
      currentOrganization: props.currentOrganization,
    };
  }

  handleSelectProfile = async val => {
    this.setState({ selectedProfile: val });
  };

  render() {
    const copiedProfiles = _.cloneDeep(this.state.profiles);
    const sortedProfiles = copiedProfiles.sort((a, b) => {
      if (a.isActive === b.isActive) {
        if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) return 1;
        if (a.displayName.toUpperCase() < b.displayName.toUpperCase()) return -1;
      } else {
        if (a.isActive) return -1;
        if (b.isActive) return 1;
      }

      return 0;
    });

    const profileDropDownOptions = sortedProfiles.map(profile => ({
      value: profile.scheduleId,
      isWaitList: profile.isWaitList,
      label: profile.isActive ? profile.displayName : `${profile.displayName} (Inactive)`,
      profileId: profile.id,
    }));
    const authorizedToEdit =
      isAdmin(this.state.currentOrganization) ||
      isEditor(this.state.currentOrganization) ||
      isBlockitAdmin(this.state.currentOrganization);

    let selectedProfile = this.state.selectedProfile || profileDropDownOptions[0];

    if (this.props.scopedProfile) {
      selectedProfile = profileDropDownOptions.filter(p => {
        return p.value === this.props.scopedProfile;
      })[0];
    }

    return (
      <Row gutter={12}>
        <Col span={18}>
          {selectedProfile ? (
            <AuthorizeAvailability
              profile={selectedProfile}
              fallbackContent={
                <>
                  <AlertAvailEMR /> {this.props.children}
                </>
              }
            >
              {this.state.setAvailCount === 0 && (
                <Row>
                  <Col span={24}>
                    <Alert
                      style={{ marginBottom: 12 }}
                      type="error"
                      showIcon
                      message="No schedule template availability"
                      description="Your schedule template currently has no time blocks configured and will not be available for online booking."
                    />
                  </Col>
                </Row>
              )}
              {!this.props.scopedProfile && (
                <>
                  <Alert
                    type="info"
                    message="This page is moving! You can now access each schedule directly from the Profiles Management page."
                  />
                  <br />
                </>
              )}
              <Row>
                <Col span={16}>
                  {!this.props.scopedProfile && (
                    <Select
                      value={selectedProfile}
                      onChange={this.handleSelectProfile}
                      options={profileDropDownOptions}
                      isMulti={false}
                      className="w-2/5"
                    />
                  )}
                </Col>

                {authorizedToEdit ? (
                  <Col span={7}>
                    <CreateAvailabilityModal scheduleId={selectedProfile ? selectedProfile.value : null} />
                  </Col>
                ) : null}
              </Row>
              <br />
              <CalendarAndLabelsWrapper>
                <DaysOfWeekLabels>
                  <DayOfWeekLabel />
                  <DayOfWeekLabel>MON</DayOfWeekLabel>
                  <DayOfWeekLabel>TUE</DayOfWeekLabel>
                  <DayOfWeekLabel>WED</DayOfWeekLabel>
                  <DayOfWeekLabel>THU</DayOfWeekLabel>
                  <DayOfWeekLabel>FRI</DayOfWeekLabel>
                  <DayOfWeekLabel>SAT</DayOfWeekLabel>
                  <DayOfWeekLabel>SUN</DayOfWeekLabel>
                </DaysOfWeekLabels>
                <CalendarWrapper ref={this.calendarRef}>
                  {selectedProfile ? (
                    <AvailabilitiesCalendar
                      scheduleId={selectedProfile.value}
                      calendarRef={this.calendarRef}
                      setAvailCount={count => {
                        if (count !== this.state.setAvailCount) {
                          this.setState({ setAvailCount: count });
                        }
                      }}
                    />
                  ) : null}
                  <Table>
                    <tbody>
                      <TableMainSection />
                    </tbody>
                  </Table>
                </CalendarWrapper>
              </CalendarAndLabelsWrapper>
            </AuthorizeAvailability>
          ) : null}
        </Col>
        {selectedProfile &&
        !hasFeature(this.state.currentOrganization?.featureFlags, FeatureFlag.HasAvailability) &&
        selectedProfile.isWaitList ? (
          <Col span={6}>
            {this.props.children}
            <Card title="Template Configuration" bordered={false} size="small">
              <p>Slot Length</p>
              <br />
              <Row>
                <Col span={24}>
                  {selectedProfile ? (
                    <SlotDurationDropDown
                      scheduleId={selectedProfile.value}
                      authorizedToEdit={authorizedToEdit}
                      profileId={selectedProfile.profileId}
                    />
                  ) : null}
                </Col>
                <Col span={24}>
                  <Typography.Paragraph type="secondary">
                    The length of each appointment slot in minutes. For example, if you set the length as 10
                    availability from 9AM-10AM, the block will be chunked into 10 minutes slots- 9-9:10, 9:10-9:20, etc.
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Card>
          </Col>
        ) : (
          <Col span={6}>
            <FeatureGate feature={FeatureFlag.HasAvailability}>
              {this.props.children}
              <Card
                title="Out-of-office Dates"
                bordered={false}
                size="small"
                extra={
                  selectedProfile && selectedProfile.value && authorizedToEdit ? (
                    <CreateBlackoutDateModal scheduleId={selectedProfile.value} />
                  ) : null
                }
              >
                {selectedProfile ? (
                  <BlackoutDateSection scheduleId={selectedProfile.value} authorizedToEdit={authorizedToEdit} />
                ) : (
                  <i>No dates are configured.</i>
                )}
              </Card>
              <FeatureGate feature={FeatureFlag.HasAvailabilityLimits}>
                {this.state.scopedProfileId && authorizedToEdit ? (
                  <Card
                    title="Availability Limits"
                    bordered={false}
                    size="small"
                    extra={
                      <AvailabilityLimitModal
                        authorizedToEdit={authorizedToEdit}
                        organizationId={this.state.currentOrganization.id}
                        profileId={this.state.scopedProfileId}
                      />
                    }
                  >
                    <AvailabilityLimitSection
                      authorizedToEdit={authorizedToEdit}
                      organizationId={this.state.currentOrganization.id}
                      profileId={this.state.scopedProfileId}
                    />
                  </Card>
                ) : null}
              </FeatureGate>
              <Card title="Template Configuration" bordered={false} size="small">
                <p>Slot Length</p>
                <br />
                <Row>
                  <Col span={24}>
                    {selectedProfile ? (
                      <SlotDurationDropDown
                        scheduleId={selectedProfile.value}
                        authorizedToEdit={authorizedToEdit}
                        profileId={selectedProfile.profileId}
                      />
                    ) : null}
                  </Col>
                  <Col span={24}>
                    <Typography.Paragraph type="secondary">
                      The length of each appointment slot in minutes. For example, if you set the length as 10
                      availability from 9AM-10AM, the block will be chunked into 10 minutes slots- 9-9:10, slots-
                      9-9:10, 9:10-9:20, etc.
                    </Typography.Paragraph>
                  </Col>
                </Row>
              </Card>
            </FeatureGate>
          </Col>
        )}
      </Row>
    );
  }
}

export default SlotManagementComponent;
