import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import { SearchProfilesInput } from './useSearchProfilesInOrganizationQuery';

const countProfilesInOrganizationQuery = gql`
  query CountProfilesInOrganizationQuery($organizationId: ID!, $searchProfilesInput: SearchProfilesInput!) {
    countProfilesInOrganization(organizationId: $organizationId, searchProfilesInput: $searchProfilesInput)
  }
`;

interface Data {
  countProfilesInOrganization: number;
}

interface Variables {
  organizationId: string;
  searchProfilesInput: SearchProfilesInput;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useCountProfilesInOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(countProfilesInOrganizationQuery, options);
};
