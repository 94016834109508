import { Checkbox, Form, Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { Component } from 'react';

const CheckboxGroup = Checkbox.Group;

interface Props {
  form: WrappedFormUtils;
  groups: {
    name: string;
    id: string;
  }[];
}

interface State {
  groupIds: CheckboxValueType[];
}

export default class DesiredNetworksField extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      groupIds: [],
    };
  }

  onChange = (groupIds: CheckboxValueType[]): void => {
    this.setState({ groupIds });
  };

  render(): JSX.Element | null {
    const { getFieldDecorator } = this.props.form;

    const options = this.props.groups.map(group => ({ label: group.name, value: group.id }));

    return options && options.length > 0 ? (
      <Form.Item label="Search the following network groups:">
        {getFieldDecorator('groupIds', {
          rules: [{ required: true, message: 'At least one network group is required.' }],
          initialValue: options.length === 1 ? [options[0].value] : [],
        })(
          <CheckboxGroup onChange={this.onChange}>
            {options.map(option => (
              <Row key={option.value}>
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </Row>
            ))}
          </CheckboxGroup>
        )}
      </Form.Item>
    ) : null;
  }
}
