import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import { Profile } from '../';

export const getProfileQuery = gql`
  query GetProfileQuery($id: ID!) {
    getProfile(id: $id) {
      id
      slug
      groupKey
      displayName
      prefix
      firstName
      lastName
      suffix
      phone
      email
      scheduleId
      type
      profileImgUrl
      professionalStatement
      boardCertifications
      educationTraining
      npiNumber
      specialty
      isActive
      isGlobal
      isIntegrated
      onlyAllowsAgeMin
      onlyAllowsAgeMax
      isSendToProvider
      isSendToPatient
      hasConsumerScheduling
      hasConsumerSchedulingSlots
      hasReferralScheduling
      hasReferralSchedulingSlots
      hasAppointmentRequests
      sendOffers
      generalPatientInstructions
      referralSearchNotice
      slotStartBufferInM
      tags
      isWaitList
      languages {
        code
        name
      }
      location {
        id
        name
        address1
        address2
        city
        state
        postalCode
      }
      profileProcedures {
        id
        durationInMinutes
        procedure {
          id
          name
          system
          version
          code
          display
          specialty {
            id
            name
          }
        }
      }
    }
  }
`;

interface Data {
  getProfile: Profile;
}

interface Variables {
  id: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetProfileQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getProfileQuery, options);
};
