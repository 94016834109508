import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Col, Collapse, Icon, Row } from 'antd';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { ConsumerSchedulingProfile } from './ConsumerSchedulingProfile';
import { defaultTheme } from '../../../themes/default';
import { generateDates, generateFutureDates, generatePastDates } from '../helpers/dates';
import { generateUtmParametersQueryString } from '../helpers/utm';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { Profile, useGetConsumerSchedulingProfileQuery } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { ProfileSearchForm, ProfileSearchFormData } from './ProfileSearchForm';
import { ProfileStepFilterBar } from './ProfileStepFilterBar';
import { Slot } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';
import { StepContainer } from './StepContainer';
import { StepTitle } from './StepTitle';

interface Props {
  organization: Organization;
  params: Params;
  profileId: string;
}

interface Params {
  embedded?: string;
  latitude?: string;
  longitude?: string;
  location?: string;
  payorId?: string;
  payorPlanId?: string;
  procedureId?: string;
  specialtyId?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_cmpid?: string;
  utm_term?: string;
  utm_content?: string;
}

const { Panel } = Collapse;

const Styles = styled.div`
  .ant-collapse-extra {
    float: left !important;
  }

  .profile-step-profile-row {
    border-top: 1px solid #d1d1d1;
    margin-top: 20px;
    padding-top: 24px;
  }

  .search-collapse {
    background-color: ${({ theme }) => theme.white};
    padding-bottom: 20px;
  }
`;

const SearchIcon = (): JSX.Element => <Icon type="search" style={{ fontSize: '16px', paddingRight: '8px' }} />;

export const ProfileStep: FC<Props> = ({ organization, params, profileId }): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);
  const { singleSpecialtyId } = useContext(ConsumerSchedulingContext);

  const history = useHistory();

  const [dates, setDates] = useState<DateTime[]>(
    generateDates(DateTime.local(), organization.hasConsumerSchedulingShowWeekendsFeature)
  );
  const [showWeekends, setShowWeekends] = useState<boolean>(organization.hasConsumerSchedulingShowWeekendsFeature);

  const [latitude, setLatitude] = useState<number | undefined>(
    params?.latitude ? parseFloat(params.latitude) : undefined
  );
  const [longitude, setLongitude] = useState<number | undefined>(
    params?.longitude ? parseFloat(params.longitude) : undefined
  );

  const [searchPanel, setSearchPanel] = useState<string | string[]>(params.procedureId ? '' : 'search-panel');

  const handleClickShowWeekends = (checked: boolean, event: MouseEvent): void => {
    event.stopPropagation();
    setShowWeekends(checked);

    const newDates = generateDates(dates[0], checked);
    setDates(newDates);
  };

  const handleCollapseChange = (key: string | string[]): void => {
    setSearchPanel(key);
  };

  const handleJumpToDate = (date: DateTime): void => {
    const newDates = generateDates(date, showWeekends);
    setDates(newDates);
  };

  const handleProfileSearchFormSubmit = (values: ProfileSearchFormData): void => {
    setSearchPanel('');

    const specialtyIdQueryString = singleSpecialtyId
      ? `singleSpecialtyId=${singleSpecialtyId}`
      : `specialtyId=${values.specialtyId}`;

    let queryString = `?${specialtyIdQueryString}&procedureId=${values.procedureId}`;
    if (values.payorId) queryString += `&payorId=${values.payorId}`;
    if (values.payorPlanId) queryString += `&payorPlanId=${values.payorPlanId}`;
    if (latitude && longitude) queryString += `&latitude=${latitude}&longitude=${longitude}`;
    if (values.location) queryString += `&location=${encodeURIComponent(values.location)}`;
    if (params.embedded === 'true') queryString += '&embedded=true';
    queryString += generateUtmParametersQueryString(params, queryString);

    history.replace(
      `/consumer/${organization.slug ? organization.slug : organization.id}/provider/${profileId}${queryString}`
    );
  };

  const handleSelectProfileSlot = (profile: Profile, slot: Slot): void => {
    let queryString = params.embedded === 'true' ? '?embedded=true' : '';
    queryString += generateUtmParametersQueryString(params, queryString);

    history.push(`/consumer/${organization.slug ? organization.slug : organization.id}/confirm${queryString}`, {
      payorId: params.payorId ? params.payorId : undefined,
      payorPlanId: params.payorPlanId ? params.payorPlanId : undefined,
      procedureId: params.procedureId,
      profile,
      slot,
    });
  };

  const { data, error, loading } = useGetConsumerSchedulingProfileQuery({
    variables: {
      id: profileId,
      latitude: params?.latitude ? parseFloat(params.latitude) : undefined,
      longitude: params?.longitude ? parseFloat(params.longitude) : undefined,
    },
  });

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Spinner />;
  }

  const { getConsumerSchedulingProfile: profile } = data;

  if (!profile) return <Spinner />;

  const onClickFuture = (): void => setDates(generateFutureDates(dates[0], showWeekends));
  const onClickPast = (): void => setDates(generatePastDates(dates[0], showWeekends));

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <StepContainer maxWidth="1248px">
          <Row gutter={[0, { xs: 24, sm: 28, md: 32, lg: 36 }]}>
            <Col span={24}>
              <StepTitle title="Schedule an Appointment" />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Collapse
                activeKey={searchPanel}
                bordered={false}
                className="search-collapse"
                onChange={handleCollapseChange}
              >
                <Panel header="Search For Appointment Times" key="search-panel" extra={SearchIcon()}>
                  <ProfileSearchForm
                    profile={profile}
                    initialLocation={params?.location}
                    initialPayorId={params?.payorId}
                    initialPayorPlanId={params?.payorPlanId}
                    initialProcedureId={params?.procedureId}
                    initialSpecialtyId={params?.specialtyId}
                    onSubmit={handleProfileSearchFormSubmit}
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                    embedded={params.embedded === 'true'}
                  />
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ProfileStepFilterBar
                dates={dates}
                defaultShowWeekends={organization.hasConsumerSchedulingShowWeekendsFeature}
                onClickFuture={onClickFuture}
                onClickPast={onClickPast}
                onClickShowWeekends={handleClickShowWeekends}
                onSelectStart={handleJumpToDate}
              />
            </Col>
          </Row>
          <Row className="profile-step-profile-row" key={profile.id}>
            <Col span={24}>
              <ConsumerSchedulingProfile
                dates={dates}
                onSelectProfileSlot={handleSelectProfileSlot}
                procedureId={params.procedureId}
                profile={profile}
                showMap={false}
                onClickFuture={onClickFuture}
                onClickPast={onClickPast}
                handleJumpToDate={handleJumpToDate}
              />
            </Col>
          </Row>
        </StepContainer>
      </Styles>
    </ThemeProvider>
  );
};
