import { Icon } from 'antd';
import { Form, Formik } from 'formik';
import { gql } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Fieldset, FieldsetTooltip, MultiSelect } from '../../../components';
import { removeEmptyObjFields } from '../../../helpers';
import { AppContextConsumer } from '../../core/contexts/AppContext';

const createLocationMutation = gql`
  mutation CreateLocation($CreateLocationInput: CreateLocationInput!) {
    CreateLocation(CreateLocationInput: $CreateLocationInput) {
      id
    }
  }
`;

const LocationFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  displayName: Yup.string().nullable(),
  key: Yup.string(),
  slug: Yup.string(),
  phone: Yup.string().required('Required'),
  email: Yup.string().email('Invalid format'),
  address1: Yup.string().required('Required'),
  address2: Yup.string().nullable(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  hasAppointmentRequests: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.boolean().required(),
  }),
  timeZone: Yup.object()
    .required('Required')
    .shape({
      label: Yup.string().required('Required'),
      value: Yup.string().required('Required'),
    }),
});

const CreateLocationComponent = ({ createLocationMutation, history }) => (
  <AppContextConsumer>
    {({ currentOrganization }) => (
      <div>
        <Formik
          initialValues={{
            name: '',
            displayName: '',
            key: '',
            slug: '',
            phone: '',
            email: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: 'United States',
            timeZone: '',
            hasAppointmentRequests: false,
          }}
          validationSchema={LocationFormSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
              const valuesCopy = _.cloneDeep(values);
              const cleanedUpValues = removeEmptyObjFields(valuesCopy);
              cleanedUpValues.timeZone = valuesCopy.timeZone.value;
              cleanedUpValues.hasAppointmentRequests = valuesCopy.hasAppointmentRequests.value;

              await createLocationMutation({
                variables: {
                  CreateLocationInput: { organizationId: currentOrganization.id, ...cleanedUpValues },
                },
              });

              history.push(`/organizations/${currentOrganization.id}/locations`);
            } catch (error) {
              console.error(error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, isSubmitting, setFieldValue, setFieldTouched, handleSubmit }) => (
            <div className="flex flex-wrap">
              <div className="w-1/2 card">
                <div className="card-header bg-grey-darkest text-grey-lightest font-bold">
                  <span className="px-4 py-2 inline-block">Create Location</span>
                </div>
                <div className="p-4">
                  <Form className="w-full" onSubmit={handleSubmit} noValidate>
                    <Fieldset
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="name"
                      label="Name"
                      placeholder="Name"
                      className="w-full input mt-2 border-1"
                    />
                    <FieldsetTooltip
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="displayName"
                      tooltip="This field should contain the name as you would have it appear on your Dashboard. The display name should be shorter in nature for more effective visibility in some tables."
                      label="Display Name"
                      placeholder="Display Name"
                      className="w-full input mt-2 border-1"
                    />
                    <Fieldset
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="key"
                      label="Key"
                      placeholder="Key"
                      className="w-full input mt-2 border-1"
                    />
                    <Fieldset
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="slug"
                      label="URL Slug"
                      placeholder="URL Slug"
                      className="w-full input mt-2 border-1"
                    />
                    <Fieldset
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="phone"
                      label="Phone"
                      placeholder="Phone Number"
                      className="w-full input mt-2 border-1"
                    />
                    <Fieldset
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="email"
                      label="Email"
                      placeholder="Email"
                      className="w-full input mt-2 border-1"
                    />
                    <Fieldset
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="address1"
                      label="Address"
                      placeholder="Address"
                      className="w-full input mt-2 border-1"
                    />
                    <Fieldset
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="address2"
                      label="Address 2"
                      placeholder="Address 2"
                      className="w-full input mt-2 border-1"
                    />
                    <Fieldset
                      errors={errors}
                      touched={touched}
                      type="text"
                      name="city"
                      label="City"
                      placeholder="City"
                      className="w-full input mt-2 border-1"
                    />
                    <div className="flex">
                      <div className="w-1/2 mr-2">
                        <Fieldset
                          errors={errors}
                          touched={touched}
                          type="text"
                          name="state"
                          label="State"
                          placeholder="State"
                          className="w-full input mt-2 border-1"
                        />
                      </div>
                      <div className="w-1/2 ml-2">
                        <Fieldset
                          errors={errors}
                          touched={touched}
                          type="text"
                          name="postalCode"
                          label="Zip Code"
                          placeholder="Zip Code"
                          className="w-full input mt-2 border-1"
                        />
                      </div>
                    </div>
                    <MultiSelect
                      errors={errors}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      touched={touched}
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                      ]}
                      name="hasAppointmentRequests"
                      label="Has Appointment Requests"
                      className="w-full"
                      isMulti={false}
                    />
                    <MultiSelect
                      errors={errors}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      touched={touched}
                      options={[
                        { value: 'AMERICA_ANCHORAGE', label: 'America/Anchorage' },
                        { value: 'AMERICA_CHICAGO', label: 'America/Chicago' },
                        { value: 'AMERICA_DENVER', label: 'America/Denver' },
                        { value: 'AMERICA_DETROIT', label: 'America/Detroit' },
                        { value: 'AMERICA_LOS_ANGELES', label: 'America/Los Angeles' },
                        { value: 'AMERICA_NEW_YORK', label: 'America/New York' },
                        { value: 'AMERICA_PHOENIX', label: 'America/Phoenix' },
                        { value: 'AMERICA_CURACAO', label: 'America/Curacao' },
                      ]}
                      name="timeZone"
                      label="Time Zone"
                      className="w-full"
                      isMulti={false}
                    />
                    <button type="submit" disabled={isSubmitting} className="w-full flex-1 mt-4 btn btn-blue px-2 py-2">
                      Save {isSubmitting ? <Icon type="loading" /> : null}
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    )}
  </AppContextConsumer>
);

export const CreateLocation = _.flowRight(
  withRouter,
  graphql(createLocationMutation, { name: 'createLocationMutation' })
)(CreateLocationComponent);
