import React, { FC } from 'react';
import { Form, Switch } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectGlobal: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="Global:"
    extra="Allows profile to ignore network group settings and always be visible in referral search results, even if it is not part of the searched network group"
  >
    {form.getFieldDecorator('isGlobal', {
      initialValue: initialValue,
      valuePropName: 'checked',

      rules: [{ required: false }],
    })(<Switch />)}
  </Form.Item>
);
