import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { gql } from '@apollo/client';
import _ from 'lodash';
import React, { FC, useContext, useState } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { AppContext } from '../../core/contexts/AppContext';

const ChangePasswordMutation = gql`
  mutation changePassword(
    $userId: ID!
    $currentPassword: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    changePassword(
      userId: $userId
      currentPassword: $currentPassword
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    ) {
      id
    }
  }
`;

interface ServerErrorType {
  message: string;
}
type UserProps = WithApolloClient<FormComponentProps>;

const ChangePasswordComponent: FC<UserProps> = ({ form, client }): JSX.Element => {
  const { viewer } = useContext(AppContext);
  const splitName = viewer?.name?.split(' ') || [];
  const firstName = splitName[0] || '';
  const lastName = splitName[splitName.length - 1];
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator, getFieldValue } = form;

  if (!viewer) {
    return <></>;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setLoading(true);

    form.validateFields(async (err, values) => {
      if (err) {
        message.error('Please fix the form errors and try again.');
        setLoading(false);
      } else {
        try {
          await client?.mutate({
            mutation: ChangePasswordMutation,
            variables: {
              userId: viewer.id,
              currentPassword: values.currentPassword,
              newPassword: values.newPassword1,
              newPasswordConfirmation: values.newPassword2,
            },
          });

          message.success(`Password Updated`);
          form.resetFields();
        } catch (error) {
          const graphQLErrors = (error as { graphQLErrors?: { message: string }[] }).graphQLErrors;
          if (graphQLErrors) {
            message.error(
              graphQLErrors.map(({ message: errorMessage }: ServerErrorType, i: number) => (
                <span key={i}>{errorMessage}</span>
              ))
            );
          }
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const validateNewPassword = (rule: string, value: string, callback: (text?: string) => void): void => {
    if (!value) {
      callback('Please enter your password.');
    } else if (value.length < 10) {
      callback('Password must be at least 10 characters.');
    } else if (!value.match(/(?=.*[A-Z])/)) {
      callback('Password must include at least one uppercase letter.');
    } else if (!value.match(/(?=.*[a-z])/)) {
      callback('Password must include at least one lowercase letter.');
    } else if (!value.match(/(?=.*[0-9])/)) {
      callback('Password must include at least one number.');
    } else if (!value.match(/(?=.*[!@#$%^&*])/)) {
      callback('Password must include at least one symbol (!@#$%^&*).');
    } else if (value.includes(viewer.email)) {
      callback('Password cannot contain your username.');
    } else if (value.includes(firstName)) {
      callback('Password cannot contain your first name.');
    } else if (value.includes(lastName)) {
      callback('Password cannot contain your last name.');
    } else {
      callback();
    }
  };

  const validateConfirmPassword = (rule: string, value: string, callback: (text?: string) => void): void => {
    if (!value) {
      callback('Please confirm your password.');
    } else if (value !== getFieldValue('newPassword1')) {
      callback('The two passwords that you entered do not match.');
    } else {
      callback();
    }
  };

  return (
    <Row gutter={24}>
      <Col xs={24} sm={20} md={12} lg={8} xl={8}>
        <Card title="Change Password" size="small">
          <Form onSubmit={handleSubmit}>
            <Form.Item label="Current Password">
              {getFieldDecorator('currentPassword', {
                rules: [{ required: true, message: 'required' }],
              })(<Input placeholder="Current Password" type="password" />)}
            </Form.Item>
            <Form.Item label="New Password">
              {getFieldDecorator('newPassword1', {
                rules: [
                  { required: true, message: 'Please input your new password.' },
                  { validator: validateNewPassword },
                ],
              })(<Input placeholder="New Password" type="password" />)}
            </Form.Item>
            <Form.Item label="Confirm New Password">
              {getFieldDecorator('newPassword2', {
                rules: [
                  { required: true, message: 'Please confirm your new password.' },
                  { validator: validateConfirmPassword },
                ],
              })(<Input placeholder="Confirm New Password" type="password" />)}
            </Form.Item>
            <Button htmlType="submit" type="primary" style={{ width: '100%' }} loading={loading}>
              Submit
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export const ChangePassword = _.flowRight(withApollo)(Form.create()(ChangePasswordComponent));
