import React from 'react';
import { PrivateRoute } from '../core';
import { CreateLocation } from './pages/CreateLocation';
import { EditLocation } from './pages/EditLocation';
import LocationManagement from './pages/LocationManagementPage';
import { Roles } from '../core/components/PrivateRoute';

export const routes = [
  <PrivateRoute
    exact
    path="/organizations/:organizationId/locations"
    key="route--locations"
    component={LocationManagement}
  />,
  <PrivateRoute
    exact
    path="/organizations/:organizationId/locations/new"
    key="route--new-location"
    component={CreateLocation}
    minRole={Roles.ADMIN}
  />,
  <PrivateRoute
    exact
    path="/organizations/:organizationId/locations/:locationId/edit"
    key="route--edit-location"
    component={EditLocation}
    minRole={Roles.ADMIN}
  />,
];

export type TimeZone =
  | 'AMERICA_CHICAGO'
  | 'AMERICA_DENVER'
  | 'AMERICA_DETROIT'
  | 'AMERICA_LOS_ANGELES'
  | 'AMERICA_NEW_YORK';

export interface Location {
  id: string;
  name: string;
  displayName: string | null;
  phone: string | null;
  email: string | null;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
  timeZone: TimeZone;
  distanceFromPatient: number | null;
  distanceFromProvider: number | null;
  organizationId: string;
}
