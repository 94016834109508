import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const getProfileQuestionnaireQuery = gql`
  query($profileId: ID!, $procedureId: ID!, $sendingOrganizationId: ID) {
    getProfile(id: $profileId) {
      id
      prescreeningQuestionnaire(procedureId: $procedureId, sendingOrganizationId: $sendingOrganizationId) {
        id
        title
        name
        items {
          id
          text
          type
          key
          answerMaxLength
          required
          initialValue
          options
          uploadAcceptTypes
          helpText
        }
      }
    }
  }
`;

interface Data {
  getProfile: {
    prescreeningQuestionnaire?: {
      id: string;
      title: string | null;
      name: string | null;
      items: {
        id: string;
        text: string;
        type: string;
        key: string | null;
        answerMaxLength?: number | null;
        required: boolean;
        initialValue: string | null;
        uploadAcceptTypes: string[] | null;
        helpText: string | null;
        options: string[] | null;
      }[];
    }[];
  };
}

interface Variables {
  profileId: string;
  procedureId: string;
  sendingOrganizationId: string | null;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetProfileQuestionnaireQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getProfileQuestionnaireQuery, options);
};
