import React from 'react';
import { PrivateRoute } from '../core';
import { ProfilesRoutes } from './routes/ProfilesRoutes';
import { Roles } from '../core/components/PrivateRoute';

export const routes = [
  <PrivateRoute
    path="/organizations/:organizationId/profiles"
    key="route--profiles"
    component={ProfilesRoutes}
    minRole={Roles.EDITOR}
  />,
];

interface Language {
  code: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface Procedure {
  id: string;
  name: string;
  system: string;
  version: string;
  code: string;
  display: string;
  specialty: Specialty;
}

interface ProfileProcedure {
  id: string;
  durationInMinutes: number;
  procedure: Procedure;
}

interface Specialty {
  id: string;
  name: string;
}

export interface Profile {
  id: string;
  slug: string;
  groupKey: string;
  displayName: string;
  prefix: string;
  firstName: string;
  lastName: string;
  suffix: string;
  phone: string;
  email: string;
  scheduleId: string;
  type: 'device' | 'location' | 'practitioner' | 'group';
  profileImgUrl: string;
  professionalStatement: string;
  boardCertifications: string;
  educationTraining: string;
  slotStartBufferInM: string;
  npiNumber: string;
  specialty: string;
  isActive: boolean;
  isGlobal: boolean;
  isIntegrated: boolean;
  onlyAllowsAgeMin: number;
  onlyAllowsAgeMax: number;
  isSendToProvider: boolean;
  isSendToPatient: boolean;
  hasConsumerScheduling: boolean;
  hasConsumerSchedulingSlots: boolean;
  hasReferralScheduling: boolean;
  hasReferralSchedulingSlots: boolean;
  hasAppointmentRequests: boolean;
  sendOffers: boolean;
  generalPatientInstructions: string;
  referralSearchNotice: string;
  tags: string;
  languages: Language[];
  location: Location;
  profileProcedures: ProfileProcedure[];
  isWaitList: boolean;
}
