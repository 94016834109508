import { useMutation, MutationTuple } from '@apollo/client';
import { gql } from '@apollo/client';

interface CreateLocationData {
  CreateLocation: {
    id: string;
  };
}

interface CreateLocationVariables {
  organizationId: string;
  CreateLocationInput: {
    organizationId: string;
    name: string;
    address1: string;
    city: string;
    state: string;
    phone: string;
    postalCode: string;
    country: string;
    timeZone: string;
    hasAppointmentRequests: boolean;
  };
}

type CreateLocationMutationResult = MutationTuple<CreateLocationData, CreateLocationVariables>;

const CreateLocationMutation = gql`
  mutation CreateLocation($CreateLocationInput: CreateLocationInput!, $organizationId: String) {
    CreateLocation(CreateLocationInput: $CreateLocationInput, organizationId: $organizationId) {
      id
    }
  }
`;

export const useCreateLocationMutation = (): CreateLocationMutationResult => {
  return useMutation<CreateLocationData, CreateLocationVariables>(CreateLocationMutation);
};
