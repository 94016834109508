import React, { FC } from 'react';
import { ApptWorkflowList } from './ApptWorkflowList';
import { Row, Col, Button, Card, Icon } from 'antd';
import { useGetWorkflowQueueQuery, useWatchQueueMutation, WorkflowQueueTask } from '../../../generated/graphql';
import { ReferralWorkflowList } from './ReferralWorkflowList';
import { QueueTitle } from './QueueTitle';
import { ReferralWorkflowListStaging } from './ReferralWorkflowListStaging';

export const WatchButton: FC<{
  watching: boolean;
  setWatchQueue: () => void;
}> = ({ watching, setWatchQueue }): JSX.Element | null => {
  if (!watching)
    return (
      <Button onClick={() => setWatchQueue()} size="large" type="default">
        <Icon type="star" theme="filled" style={{ color: '#D1D5DB' }} /> Watch
      </Button>
    );

  return (
    <Button onClick={() => setWatchQueue()} size="large" type="default">
      <Icon type="star" theme="filled" style={{ color: '#FBBF24' }} /> Unwatch
    </Button>
  );
};

export const WorkflowQueue: FC<{ id: string }> = ({ id }): JSX.Element | null => {
  const [setWatchQueueMutation] = useWatchQueueMutation();
  const { data } = useGetWorkflowQueueQuery({
    variables: { id: id || '' },
    pollInterval: 30000,
    fetchPolicy: 'cache-and-network',
  });

  if (!id || !data?.getWorkflowQueue) {
    return null;
  }

  const setWatchQueue = (): void => {
    setWatchQueueMutation({
      variables: {
        workflowQueueId: id,
      },
    });
  };

  const workflow = data.getWorkflowQueue;

  return (
    <>
      <Row>
        <Col span={22} push={1}>
          <Card
            title={<QueueTitle name={workflow.name} color={workflow.color} />}
            extra={<WatchButton watching={workflow.viewerWatching} setWatchQueue={setWatchQueue} />}
          >
            <Row>
              <Col span={24}>
                {workflow.template === 'appointment' && (
                  <ApptWorkflowList
                    tasks={workflow.tasks as WorkflowQueueTask[]}
                    viewerWatching={workflow.viewerWatching}
                  />
                )}
                {workflow.template === 'referral' && (
                  <ReferralWorkflowList
                    tasks={workflow.tasks as WorkflowQueueTask[]}
                    viewerWatching={workflow.viewerWatching}
                    showAcceptButton={workflow.showAcceptButton || false}
                    showScheduleButton={workflow.showScheduleButton || false}
                    showRelatedQueues={workflow.showRelatedQueues || false}
                  />
                )}
                {workflow.template === 'referral_staging' && (
                  <ReferralWorkflowListStaging
                    tasks={workflow.tasks as WorkflowQueueTask[]}
                    viewerWatching={workflow.viewerWatching}
                    showAcceptButton={workflow.showAcceptButton || false}
                    showScheduleButton={workflow.showScheduleButton || false}
                    showRelatedQueues={workflow.showRelatedQueues || false}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
