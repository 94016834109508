import * as Sentry from '@sentry/browser';
import { Form, Icon, message, Upload } from 'antd';
import React from 'react';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import { GetReferralDocuments } from '../../GraphQL';

const { Dragger } = Upload;

const UploadReferralDocument = gql`
  mutation uploadReferralDocument($file: Upload, $referralId: ID!, $type: String) {
    uploadReferralDocument(file: $file, referralId: $referralId, type: $type)
  }
`;

export const DeleteReferralDocument = gql`
  mutation deleteReferralDocument($documentUrl: String, $referralId: ID!) {
    deleteReferralDocument(documentUrl: $documentUrl, referralId: $referralId) {
      documentUrl
      displayFilename
    }
  }
`;

const QuestionUpload = ({ client, referralId, isRequired, form, attachmentType, helpText, question }) => {
  const { getFieldDecorator } = form;

  const getFileExtension = filename => {
    const parts = filename.split('.');
    return parts.length > 1 ? '.' + parts.pop() : '';
  };
  const validateFileType = file => {
    const fileExtension = getFileExtension(file.name);

    if (fileExtension && question.uploadAcceptTypes.includes(fileExtension)) {
      return true;
    } else {
      message.error(`File type not allowed. Allowed types: ${question.uploadAcceptTypes.join(', ')}`);
      return false;
    }
  };
  const customRequest = async ({ file: { file }, refetch }) => {
    if (!validateFileType(file)) {
      return;
    }

    try {
      await client.mutate({
        mutation: UploadReferralDocument,
        variables: { file, referralId, type: attachmentType },
      });

      message.success(`Successfully uploaded ${file.name}`);
      await refetch();
    } catch (err) {
      console.log('err', err);
      Sentry.captureException(err);
      message.error(`Error uploading ${file.name}`);
    }

    // DO NOT REMOVE, WILL BREAK UPLOAD - https://github.com/ant-design/ant-design/issues/10122
    return {
      abort() {
        console.log('Aborting Upload');
      },
    };
  };

  const handleRemove = ({ file, refetch }) => {
    return client
      .mutate({
        mutation: DeleteReferralDocument,
        variables: { documentUrl: file.uid, referralId: referralId },
      })
      .then(() => {
        message.success(`Successfully deleted ${file.name}`);
        refetch();
      })
      .catch(err => {
        Sentry.captureException(err);
        message.error(`Error deleting ${file.name}`);
      });
  };

  const formatFileList = ReferralDocuments => {
    return ReferralDocuments.map(rd => {
      return {
        uid: rd.documentUrl,
        name: rd.displayFilename,
        status: 'done',
        referralId: rd.referralId,
      };
    });
  };

  const handleChange = ({ fileList }) => {};

  return (
    <Query query={GetReferralDocuments} variables={{ referralId, type: attachmentType }}>
      {({ loading, error, data, refetch }) => {
        // Do not handle loading case, otherwise deleting uploads is glitchy and sucks
        if (error) return <div>Error</div>;
        if (!data) return <></>;

        const { getReferralDocuments: ReferralDocuments } = data;

        return (
          <>
            <Form.Item>
              {getFieldDecorator('dragger', {
                rules: isRequired ? [{ required: true, message: 'required' }] : [],
              })(
                <Dragger
                  name="file"
                  multiple
                  fileList={formatFileList(ReferralDocuments || [])}
                  onChange={handleChange}
                  customRequest={file => customRequest({ file, refetch })}
                  onRemove={file => handleRemove({ file, refetch })}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">{helpText}</p>
                </Dragger>
              )}
            </Form.Item>
          </>
        );
      }}
    </Query>
  );
};

export default withApollo(QuestionUpload);
