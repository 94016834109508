import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Form, Radio, Typography } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { defaultTheme } from '../../../themes/default';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
  extra?: string;
}

const Styles = styled.div``;

export const SelectType: FC<Props> = ({ form, initialValue, extra }): JSX.Element => {
  const handleChange = (e: RadioChangeEvent): void => {
    form.setFieldsValue({ type: e.target.value });
  };

  return (
    <Form.Item label="Profile Type:">
      {form.getFieldDecorator('type', {
        initialValue,
        rules: [{ message: `Please select your profile's type.`, required: true }],
      })(
        <ThemeProvider theme={defaultTheme}>
          <Styles>
            <Radio.Group
              className="radio-group"
              defaultValue={initialValue}
              onChange={handleChange}
              buttonStyle="solid"
            >
              <Radio.Button value="practitioner" className="radio-button">
                Provider
              </Radio.Button>

              <Radio.Button value="location" className="radio-button">
                Location
              </Radio.Button>

              <Radio.Button value="device" className="radio-button">
                Machine
              </Radio.Button>
              <Radio.Button value="group" className="radio-button">
                Group
              </Radio.Button>
            </Radio.Group>
            {extra && <Typography.Paragraph type="secondary">{extra}</Typography.Paragraph>}
          </Styles>
        </ThemeProvider>
      )}
    </Form.Item>
  );
};
