import React, { FC } from 'react';
import styled from 'styled-components';
import { Profile } from '../index';
import { ProfileFormData } from './ProfileForm';
import { ProfileSettingsAdminFormCard } from './ProfileSettingsAdminFormCard';

interface Props {
  onSubmit: (values: ProfileFormData) => void;
  profile: Profile;
}

const Styles = styled.div``;

export const ProfileSettingsAdmin: FC<Props> = ({ onSubmit, profile }): JSX.Element => {
  return (
    <Styles>
      <div className="admin-settings-card">
        <ProfileSettingsAdminFormCard onSubmit={onSubmit} profile={profile} />
      </div>
    </Styles>
  );
};
