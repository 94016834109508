import { Button, Icon } from 'antd';
import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import * as Yup from 'yup';
import { Modal, MultiSelect } from '../../../../components';
import { CreateBlackoutDate, ListBlackoutDates } from '../../../../GraphQL';
import {
  formikCalendarDays,
  formikCalendarMonths,
  formikCalendarYears,
  parseErrorMessage,
  availabilityHoursOfDay,
  availabilityMinutesOfDay,
  availabilityAmPm,
} from '../../../../helpers';

const CreateBlackoutDateSchema = Yup.object().shape({
  start: Yup.object().shape({
    month: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    day: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    year: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    hour: Yup.object()
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    minute: Yup.object()
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    ampm: Yup.object()
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
  }),
  end: Yup.object().shape({
    month: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    day: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    year: Yup.object()
      .required('Required')
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    hour: Yup.object()
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    minute: Yup.object()
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
    ampm: Yup.object()
      .nullable()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
      }),
  }),
});

class CreateBlackoutDateModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      loading: false,
      isAddingTimes: false,
    };
  }

  renderOptionalStartAndEndTimes({ errors, touched, setFieldValue, setFieldTouched, values }) {
    if (this.state.isAddingTimes) {
      return (
        <div>
          <div className="flex mt-2">
            <div className="w-1/4 flex items-top justify-end mr-4 mt-4">
              <span className="font-bold text-md text-grey-darker">Start Time:</span>
            </div>
            <div className="w-3/4">
              <div className="flex">
                <div className="flex-1 mr-2">
                  <MultiSelect
                    errors={errors}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched}
                    options={availabilityHoursOfDay()}
                    value={values.start.hour}
                    name="start.hour"
                    placeholder="Hour"
                    className="w-full"
                    isMulti={false}
                  />
                </div>
                <div className="flex-1 mr-2">
                  <MultiSelect
                    errors={errors}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched}
                    options={availabilityMinutesOfDay()}
                    value={values.start.minute}
                    name="start.minute"
                    placeholder="Minute"
                    className="w-full"
                    isMulti={false}
                  />
                </div>
                <div className="flex-1">
                  <MultiSelect
                    errors={errors}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched}
                    options={availabilityAmPm()}
                    value={values.start.ampm}
                    name="start.ampm"
                    placeholder="AM/PM"
                    className="w-full"
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/4 flex items-top justify-end mr-4 mt-4">
              <span className="font-bold text-md text-grey-darker">End Time:</span>
            </div>
            <div className="w-3/4">
              <div className="flex">
                <div className="flex-1 mr-2">
                  <MultiSelect
                    errors={errors}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched}
                    options={availabilityHoursOfDay()}
                    value={values.end.hour}
                    name="end.hour"
                    placeholder="Hour"
                    className="w-full"
                    isMulti={false}
                  />
                </div>
                <div className="flex-1 mr-2">
                  <MultiSelect
                    errors={errors}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched}
                    options={availabilityMinutesOfDay()}
                    value={values.end.minute}
                    name="end.minute"
                    placeholder="Minute"
                    className="w-full"
                    isMulti={false}
                  />
                </div>
                <div className="flex-1">
                  <MultiSelect
                    errors={errors}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched}
                    options={availabilityAmPm()}
                    value={values.end.ampm}
                    name="end.ampm"
                    placeholder="AM/PM"
                    className="w-full"
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="text-gray-400 cursor-pointer text-right pt-4"
          onClick={() => this.setState({ isAddingTimes: true })}
        >
          Add an optional start and end time.
        </div>
      );
    }
  }

  render() {
    if (this.props.scheduleId === null) {
      return null;
    }

    return (
      <React.Fragment>
        <Button
          size="small"
          className="cursor-pointer ml-auto"
          onClick={() => this.setState({ isOpen: true, isAddingTimes: false })}
        >
          <Icon type="plus" />
        </Button>
        <Modal title="Out of Office" isOpen={this.state.isOpen} closeModal={() => this.setState({ isOpen: false })}>
          <p className="text-center text-grey-darker mb-4 w-3/4 mr-auto ml-auto">
            Enter the dates you will be out of office
          </p>
          <Formik
            initialValues={{
              start: {
                month: null,
                day: null,
                year: null,
                hour: null,
                minute: null,
                ampm: null,
              },
              end: {
                month: null,
                day: null,
                year: null,
                hour: null,
                minute: null,
                ampm: null,
              },
            }}
            validationSchema={CreateBlackoutDateSchema}
            onSubmit={async ({ start, end }, { setSubmitting, setStatus }) => {
              setSubmitting(true);

              let startTime = null;
              let endTime = null;

              try {
                startTime =
                  `${start.ampm.value}` === '12'
                    ? `${parseInt(start.hour.value, 10) + parseInt(start.ampm.value, 10)}:${start.minute.value}:00`
                    : `${start.hour.value}:${start.minute.value}:00`;
              } catch (error) {
                startTime = null;
              }

              try {
                endTime =
                  `${end.ampm.value}` === '12'
                    ? `${parseInt(end.hour.value, 10) + parseInt(end.ampm.value, 10)}:${end.minute.value}:00`
                    : `${end.hour.value}:${end.minute.value}:00`;
              } catch (error) {
                endTime = null;
              }

              const startString = `${start.year.value}-${start.month.value}-${start.day.value}`;
              const endString = `${end.year.value}-${end.month.value}-${end.day.value}`;

              const startDate = new Date(startString);
              const endDate = new Date(endString);

              try {
                if (startDate > endDate) {
                  throw new Error('End Date must be greater than or equal to Start Date');
                }

                await this.props.client.mutate({
                  mutation: CreateBlackoutDate,
                  variables: {
                    CreateBlackoutDateInput: {
                      start: startString,
                      startTime: startTime,
                      end: endString,
                      endTime: endTime,
                      scheduleId: this.props.scheduleId,
                      reason: 'OUT_OF_OFFICE',
                    },
                  },
                  refetchQueries: [
                    {
                      query: ListBlackoutDates,
                      variables: {
                        ListBlackoutDatesInput: { scheduleId: this.props.scheduleId, reason: 'OUT_OF_OFFICE' },
                      },
                    },
                  ],
                });

                // clear status
                setStatus('');

                // close modal
                this.setState({ loading: false, isOpen: false });
              } catch (error) {
                setStatus(parseErrorMessage(error));
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {form => {
              const {
                errors,
                status,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                values,
              } = form;
              return (
                <div className="p-4">
                  {status && status.length > 0 ? (
                    <div className="text-red w-4/5 mr-auto ml-auto text-center">{status}</div>
                  ) : null}
                  <Form className="w-full" onSubmit={handleSubmit}>
                    <div className="flex">
                      <div className="w-1/4 flex items-top justify-end mr-4 mt-4">
                        <span className="font-bold text-md text-grey-darker">Start Date:</span>
                      </div>
                      <div className="w-3/4">
                        <div className="flex">
                          <div className="flex-1 mr-2">
                            <MultiSelect
                              errors={errors}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              touched={touched}
                              options={formikCalendarMonths}
                              value={values.start.month}
                              name="start.month"
                              placeholder="Month"
                              className="w-full"
                              isMulti={false}
                            />
                          </div>
                          <div className="flex-1 mr-2">
                            <MultiSelect
                              errors={errors}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              touched={touched}
                              options={formikCalendarDays(values.start.month || '01')}
                              value={values.start.day}
                              name="start.day"
                              placeholder="Day"
                              className="w-full"
                              isMulti={false}
                            />
                          </div>
                          <div className="flex-1">
                            <MultiSelect
                              errors={errors}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              touched={touched}
                              options={formikCalendarYears()}
                              value={values.start.year}
                              name="start.year"
                              placeholder="Year"
                              className="w-full"
                              isMulti={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-1/4 flex items-top justify-end mr-4 mt-4">
                        <span className="font-bold text-md text-grey-darker">End Date:</span>
                      </div>
                      <div className="w-3/4">
                        <div className="flex">
                          <div className="flex-1 mr-2">
                            <MultiSelect
                              errors={errors}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              touched={touched}
                              options={formikCalendarMonths}
                              value={values.end.month}
                              name="end.month"
                              placeholder="Month"
                              className="w-full"
                              isMulti={false}
                            />
                          </div>
                          <div className="flex-1 mr-2">
                            <MultiSelect
                              errors={errors}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              touched={touched}
                              options={formikCalendarDays(values.end.month || '01')}
                              value={values.end.day}
                              name="end.day"
                              placeholder="Day"
                              className="w-full"
                              isMulti={false}
                            />
                          </div>
                          <div className="flex-1">
                            <MultiSelect
                              errors={errors}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              touched={touched}
                              options={formikCalendarYears()}
                              value={values.end.year}
                              name="end.year"
                              placeholder="Year"
                              className="w-full"
                              isMulti={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.renderOptionalStartAndEndTimes(form)}

                    <button type="submit" disabled={isSubmitting} className="w-full flex-1 mt-8 btn btn-blue px-2 py-2">
                      Save {isSubmitting ? <Icon type="loading" /> : null}
                    </button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </Modal>
      </React.Fragment>
    );
  }
}

export const CreateBlackoutDateModal = withApollo(CreateBlackoutDateModalComponent);
