import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TextArea } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  isWaitList: boolean;
}

export const InputPatientNotes: FC<Props> = ({ form, isWaitList }): JSX.Element => (
  <React.Fragment>
    {isWaitList ? (
      <Form.Item label="Reason for visit">
        {form.getFieldDecorator('notes', { rules: [{ required: true }] })(
          <TextArea allowClear placeholder="Reason for visit" rows={4} />
        )}
      </Form.Item>
    ) : (
      <Form.Item label="Notes">
        {form.getFieldDecorator('notes', { rules: [{ required: false }] })(
          <TextArea allowClear placeholder="Additional notes about your appointment" rows={4} />
        )}
      </Form.Item>
    )}
  </React.Fragment>
);
