import { Button, Icon, List, Row } from 'antd';
import config from '../../../config';
import React, { FC } from 'react';
import { Appointment, AppointmentAttachment } from '../hooks/useListAppointmentsQuery';
import Title from 'antd/lib/typography/Title';
import FileReaderInput from 'react-file-reader-input';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const uploadAttachmentMutation = gql`
  mutation uploadAttachment($file: Upload!, $appointmentId: ID!) {
    uploadAppointmentAttachment(file: $file, appointmentId: $appointmentId) {
      id
      appointmentAttachments {
        id
        attachmentUrl
        displayFilename
      }
    }
  }
`;

interface Variables {
  file: File;
  appointmentId: string;
}

export const AppointmentAttachmentList: FC<{ appt: Appointment }> = ({ appt }): JSX.Element => {
  const [uploadAttachment] = useMutation<Appointment, Variables>(uploadAttachmentMutation);
  return (
    <>
      <Row>
        <Title style={{ display: 'inline' }} level={4}>
          Attachments
        </Title>
        <span style={{ float: 'right' }}>
          <FileReaderInput
            as="buffer"
            onChange={(event, results) => {
              const [result] = results;
              const file = result[1];
              uploadAttachment({ variables: { file: file, appointmentId: appt.id } });
            }}
          >
            <Button>
              <Icon type="plus" />
            </Button>
          </FileReaderInput>
        </span>
      </Row>
      <List
        size="default"
        dataSource={appt.appointmentAttachments}
        locale={{
          emptyText: <span style={{ color: '#bbb', fontStyle: 'italic' }}>No attachments were added</span>,
        }}
        renderItem={(a: AppointmentAttachment) => (
          <List.Item>
            <span style={{ fontSize: '14px' }}>
              <a href={`${config.endpoint}/download/appointment/${a.id}/attachment`}>{a.displayFilename}</a>
            </span>
          </List.Item>
        )}
      />
    </>
  );
};
