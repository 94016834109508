import { createContext } from 'react';
import { Profile } from '../index';

export interface ProfileContextInterface {
  profile: Profile;
}

export const ProfileContext = createContext<ProfileContextInterface>({
  profile: {
    id: '',
    slug: '',
    groupKey: '',
    displayName: '',
    prefix: '',
    firstName: '',
    lastName: '',
    suffix: '',
    phone: '',
    email: '',
    scheduleId: '',
    type: 'practitioner',
    profileImgUrl: '',
    professionalStatement: '',
    boardCertifications: '',
    educationTraining: '',
    npiNumber: '',
    specialty: '',
    isActive: false,
    isGlobal: false,
    isIntegrated: false,
    onlyAllowsAgeMin: 0,
    onlyAllowsAgeMax: 100,
    isSendToProvider: false,
    isSendToPatient: false,
    hasConsumerScheduling: false,
    hasConsumerSchedulingSlots: true,
    hasReferralScheduling: true,
    hasReferralSchedulingSlots: true,
    hasAppointmentRequests: false,
    sendOffers: true,
    referralSearchNotice: '',
    generalPatientInstructions: '',
    slotStartBufferInM: '0',
    tags: '',
    isWaitList: false,
    languages: [],
    location: {
      id: '',
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
    },
    profileProcedures: [],
  },
});
