import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { makeClient } from '../../../graphqlclient';
import { Row, Col, Typography, Button } from 'antd';
import { InviteUserInline } from './onboarding/InviteUserInline';

const { Title } = Typography;

interface InviteUsersOnboardingProps {
  organizationId: string;
  onFinish: () => void;
}

export const InviteUsersOnboarding: React.FC<InviteUsersOnboardingProps> = ({ organizationId, onFinish }) => {
  const [invites, setInvites] = useState([
    <InviteUserInline key="1" organizationId={organizationId} />,
    <InviteUserInline key="2" organizationId={organizationId} />,
  ]);

  const addMoreInvites = (): void => {
    setInvites([...invites, <InviteUserInline key={invites.length + 1} organizationId={organizationId} />]);
  };

  return (
    <ApolloProvider client={makeClient(() => {})}>
      <Row type="flex" justify="center">
        <Col xs={24} md={18} lg={18} xl={16}>
          <Title level={2} id="title" style={{ paddingBottom: '2rem' }}>
            Invite users to your account
          </Title>
          {invites}
          <Row style={{ paddingTop: '1rem' }}>
            <Col xs={4} push={19}>
              <Button type="link" onClick={addMoreInvites}>
                Add more...
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center" style={{ paddingTop: '2rem' }}>
        <Col xs={24} md={18} lg={18} xl={16}>
          <Button type="primary" onClick={onFinish} size="large">
            Continue
          </Button>
          <Button size="large" onClick={onFinish} style={{ marginLeft: 8 }}>
            Skip
          </Button>
        </Col>
      </Row>
    </ApolloProvider>
  );
};
