import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { defaultTheme } from '../../../themes/default';

interface Props {
  title: string;
}

const { Title } = Typography;

const Styles = styled.div`
  .step-title {
    color: ${({ theme }) => theme.blueDarkest};
    font-family: Source Sans Pro, sans-serif;
    font-weight: normal;
    text-decoration: none solid;

    &.step-title-xs {
      font-size: 18px;
    }

    &.step-title-sm {
      font-size: 20px;
    }

    &.step-title-md {
      font-size: 22px;
    }
  }
`;

export const StepTitle: FC<Props> = ({ title }): JSX.Element => (
  <ThemeProvider theme={defaultTheme}>
    <Styles>
      <Row>
        <Col xs={24} sm={0}>
          <Title className="step-title step-title-xs" level={2}>
            {title}
          </Title>
        </Col>
        <Col xs={0} sm={24} md={0}>
          <Title className="step-title step-title-sm" level={2}>
            {title}
          </Title>
        </Col>
        <Col xs={0} md={24}>
          <Title className="step-title step-title-md" level={2}>
            {title}
          </Title>
        </Col>
      </Row>
    </Styles>
  </ThemeProvider>
);
