import * as moment from 'moment';
import { Button, Icon, Popconfirm } from 'antd';
import queryString, { ParsedQuery } from 'query-string';
import React, { FC, useContext } from 'react';
import config from '../../../config';
import { history } from '../../core/components/App/history';
import { AppContext } from '../../core/contexts/AppContext';

interface QueryVariablesInterface {
  startDate: string | undefined;
  endDate: string | undefined;
  filters: string | string[] | undefined;
  locationId: string | undefined;
}

export const AppointmentOffersCSVDownload: FC = (): JSX.Element => {
  const { currentOrganization, currentLocation } = useContext(AppContext);

  const getQueryVariables = (): QueryVariablesInterface => {
    const queryParams: ParsedQuery = queryString.parse(history.location.search);
    return {
      startDate:
        queryParams.startDate && typeof queryParams.startDate == 'string'
          ? queryParams.startDate
          : moment
              .default()
              .subtract(2, 'years')
              .toISOString(),

      endDate:
        queryParams.endDate && typeof queryParams.endDate == 'string'
          ? queryParams.endDate
          : moment
              .default()
              .add(2, 'years')
              .toISOString(),
      filters: queryParams.filters ? queryParams.filters : '{}',
      locationId: currentLocation?.id,
    };
  };

  const handleWithoutFilters = (): void => {
    const queryVars = getQueryVariables();
    queryVars.filters = undefined;

    const queryVarsString = queryString.stringify(queryVars, {
      skipNull: true,
    });

    openTab(queryVarsString);
  };

  const handleWithFilters = (): void => {
    const queryVars = getQueryVariables();
    const queryVarsString = queryString.stringify(queryVars, {
      skipNull: true,
    });
    openTab(queryVarsString);
  };

  const openTab = (params: string): void => {
    window.open(`${config.endpoint}/download/organizations/${currentOrganization?.id}/appointment_offers?${params}`);
  };

  return (
    <div>
      <Popconfirm
        title="Export with filters?"
        cancelText="No"
        okText="Yes"
        onCancel={handleWithoutFilters}
        onConfirm={handleWithFilters}
      >
        <Button htmlType="button">
          CSV JumpQ Export <Icon type="cloud-download" />
        </Button>
      </Popconfirm>
    </div>
  );
};
