import { Col, PageHeader, Row } from 'antd';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateProfile } from '../components/CreateProfile';

export const CreateProfilePage: FC = (): JSX.Element => {
  const history = useHistory();

  return (
    <Row>
      <Row type="flex" justify="center">
        <Col span={18}>
          <PageHeader
            title="Create a new profile"
            onBack={() => {
              history.goBack();
            }}
          />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col span={16}>
          <CreateProfile />
        </Col>
      </Row>
    </Row>
  );
};
