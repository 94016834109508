import _ from 'lodash';
import React, { FC } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Spinner } from '../../../../components';
import { ListBlackoutDates as ListBlackoutDatesQuery } from '../../../../GraphQL';
import BlackoutListItem from './BlackoutListItem';

interface Props {
  scheduleId: string;
  ListBlackoutDates: {
    loading: boolean;
    ListBlackoutDates: {
      blackoutDates: {
        id: string;
        start: string;
      };
    };
  };
  authorizedToEdit: boolean;
}

const BlackoutDateSectionComponent: FC<Props> = ({ scheduleId, ListBlackoutDates, authorizedToEdit }) => {
  if (ListBlackoutDates.loading) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );
  }

  const { ListBlackoutDates: blackoutDates } = ListBlackoutDates;
  const sortedBlackoutDates = _.sortBy(blackoutDates, bo => bo.start);

  if (sortedBlackoutDates && sortedBlackoutDates.length > 0) {
    return (
      <ul className="list-reset w-full">
        {sortedBlackoutDates.map(bo => (
          <BlackoutListItem
            blackoutDateObject={bo}
            key={bo.id}
            scheduleId={scheduleId}
            authorizedToEdit={authorizedToEdit}
          />
        ))}
      </ul>
    );
  } else {
    return <p style={{ color: '#bbb', fontStyle: 'italic', textAlign: 'center' }}>No dates have been configured</p>;
  }
};

export const BlackoutDateSection = _.flowRight(
  graphql<Props>(ListBlackoutDatesQuery, {
    name: 'ListBlackoutDates',
    options: ownProps => ({
      variables: { ListBlackoutDatesInput: { scheduleId: ownProps.scheduleId, reason: 'OUT_OF_OFFICE' } },
    }),
  })
)(BlackoutDateSectionComponent);
