import React, { FC, useContext } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { useGetSpecialtiesWithProfilesInOrganizationQuery } from '../hooks/useGetSpecialtiesWithProfilesInOrganizationQuery';
import { SelectSpecialtyAndProcedure } from './SelectSpecialtyAndProcedure';

interface Props {
  form: WrappedFormUtils;
  initialProcedureId?: string;
  initialSpecialtyId?: string;
}

export const SelectSpecialtyAndProcedureForOrganization: FC<Props> = ({
  form,
  initialProcedureId,
  initialSpecialtyId,
}): JSX.Element => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const { data, error, loading } = useGetSpecialtiesWithProfilesInOrganizationQuery({
    variables: { id: organization.id },
  });

  if (error) return <InternalServerErrorAlert error={error} />;
  if (loading || !data) return <Spinner />;

  const { getSpecialtiesWithProfilesInOrganization: specialties } = data;

  return (
    <SelectSpecialtyAndProcedure
      form={form}
      initialProcedureId={initialProcedureId}
      initialSpecialtyId={initialSpecialtyId}
      specialties={specialties}
    />
  );
};
