import { gql } from '@apollo/client';

const GetProfile = gql`
  query GetProfile($profileId: ID!) {
    getProfile(id: $profileId) {
      id
      displayName
      prefix
      firstName
      lastName
      suffix
      phone
      email
      scheduleId
      type
      profileImgUrl
      professionalStatement
      boardCertifications
      educationTraining
      npiNumber
      specialty
      isActive
      isGlobal
      isIntegrated
      onlyAllowsAgeMin
      onlyAllowsAgeMax
      isSendToProvider
      isSendToPatient
      hasConsumerScheduling
      hasConsumerSchedulingSlots
      hasReferralScheduling
      hasReferralSchedulingSlots
      sendOffers
      generalPatientInstructions
      tags
      languages {
        code
        name
      }
      location {
        id
        name
        address1
        address2
        city
        state
        postalCode
      }
      profileProcedures {
        id
        durationInMinutes
        procedure {
          id
          name
          system
          version
          code
          display
          specialty {
            id
            name
          }
        }
      }
    }
  }
`;

const CreateProfileProcedure = gql`
  mutation CreateProfileProcedure($CreateProfileProcedureInput: CreateProfileProcedureInput!) {
    CreateProfileProcedure(CreateProfileProcedureInput: $CreateProfileProcedureInput) {
      id
    }
  }
`;

const DeleteProfileProcedure = gql`
  mutation DeleteProfileProcedure($id: ID!) {
    DeleteProfileProcedure(id: $id) {
      id
    }
  }
`;

const UploadScheduleImage = gql`
  mutation UploadScheduleImage($UploadScheduleImageInput: UploadScheduleImageInput!) {
    UploadScheduleImage(UploadScheduleImageInput: $UploadScheduleImageInput)
  }
`;

export { GetProfile, CreateProfileProcedure, DeleteProfileProcedure, UploadScheduleImage };
