import { Button } from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationFunction } from '@apollo/client/react/types/types';

const RESET_PASSWORD = gql`
  mutation createPasswordReset($userId: ID!) {
    createPasswordReset(userId: $userId) {
      id
    }
  }
`;

interface Props {
  user: {
    id: string;
  };
}

interface Result {
  id: string;
}

interface Variables {
  userId: string;
}

export const PasswordResetButton: FC<Props> = ({ user }): JSX.Element => {
  const [isSent, setIsSent] = useState(false);

  const handleClick = (resetPassword: MutationFunction<Result, Variables>, user: { id: string }) => async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (isSent) {
      return;
    }

    await resetPassword({ variables: { userId: user.id } });
    setIsSent(true);
  };

  return (
    <Mutation<Result, Variables> mutation={RESET_PASSWORD}>
      {resetPassword => (
        <Button type="dashed" size="small" onClick={handleClick(resetPassword, user)}>
          {isSent ? 'Delivered' : <span>Send Email</span>}
        </Button>
      )}
    </Mutation>
  );
};
