import * as QueryString from 'query-string';
import React, { FC, useContext } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { Engagement } from '../hooks/useCreateConsumerSchedulingEngagementMutation';
import { SuccessStep } from '../components/SuccessStep';

interface LocationState {
  engagement?: Engagement;
  apptType?: string;
  patientName?: string;
}

export const ConsumerSchedulingSuccessPage: FC<RouteComponentProps<{}, StaticContext, LocationState>> = ({
  location,
}): JSX.Element => {
  const { organization } = useContext(ConsumerSchedulingContext);
  const engagement = location?.state?.engagement;
  const apptType = location?.state?.apptType;
  const patientName = location?.state?.patientName || '';

  const params = QueryString.parse(location.search);

  if (!engagement || !apptType) {
    return <Redirect to={`/consumer/${organization.slug ? organization.slug : organization.id}${location.search}`} />;
  }

  return (
    <SuccessStep
      engagement={engagement}
      apptType={apptType}
      patientName={patientName}
      organization={organization}
      embedded={params.embedded === 'true'}
    />
  );
};
