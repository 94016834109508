import _ from 'lodash';
import { DateTime } from 'luxon';

/**
 * Formats a 10-digit phone number with area code.
 * @param phoneNumber Phone number to format
 * @returns Formatted phone number
 */
const formatPhone = (phoneNumber: string | null): string => {
  if (phoneNumber === null) {
    return '';
  }

  const cleanedNumber = phoneNumber.replace(/\D+/g, '');

  if (cleanedNumber.length !== 10) {
    return cleanedNumber;
  }

  return `(${cleanedNumber.substr(0, 3)}) ${cleanedNumber.substr(3, 3)}-${cleanedNumber.substr(6, 4)}`;
};

/**
 * Used somewhere for old forms. Don't use it anymore I think. The types are weird.
 * @param obj Object to remove empty fields from
 * @deprecated Probably don't use this anymore
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeEmptyObjFields = <T extends { [key: string]: any }>(obj: T): T => {
  const clonedObj = _.cloneDeep(obj);
  const keys = Object.keys(clonedObj) as (keyof T)[];

  // recursively search through object deleting empty fields
  keys.forEach(key => {
    if (clonedObj[key] && typeof clonedObj[key] === 'object') removeEmptyObjFields(clonedObj[key]);
    else if (clonedObj[key] === null || clonedObj[key] === undefined || clonedObj[key] === '') delete clonedObj[key];
  });

  return clonedObj;
};

/**
 * Formats a date string as DATE_SHORT
 * @param date Date string to format
 * @returns Formatted date string
 */
const formatBlackoutDate = (date: string): string => {
  const rawDate = DateTime.fromISO(date);
  return rawDate.toLocaleString(DateTime.DATE_SHORT);
};

/**
 * Formats an ICD-10 code for display
 * @param code ICD-10 code
 */
const formatICD10Code = (code: string): string => {
  if (code.length > 3) {
    return code.substr(0, 3) + '.' + code.substr(3);
  }

  return code;
};

/**
 * Converts an all caps string to title case
 * @param str String to format in title case
 */
const formatTitleCase = (str: string | null): string | null => {
  if (str !== null && /^[^a-z]*$/.test(str)) {
    return str
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
      .join(' ');
  } else {
    return str;
  }
};

/**
 * Formats an all caps to email string to lowercase
 * @param str Email string to format
 */
const formatEmail = (str: string | null): string | null => {
  if (str !== null && /^[^a-z]*$/.test(str)) {
    return str.toLowerCase();
  } else {
    return str;
  }
};

export { formatPhone, formatEmail, formatTitleCase, removeEmptyObjFields, formatBlackoutDate, formatICD10Code };
