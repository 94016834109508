import React, { FC } from 'react';
import { Icon } from 'antd';
import { InternalServerErrorAlert } from '../../../components';
import { useCountProvidersInOrganizationQuery } from '../hooks/useCountProvidersInOrganizationQuery';

interface Props {
  organizationId: string;
}

export const ActiveProviderCount: FC<Props> = ({ organizationId }): JSX.Element => {
  const { data, loading, error } = useCountProvidersInOrganizationQuery({
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Icon type="loading" />;
  }

  const { countProvidersInOrganization: count } = data;

  return <>{count}</>;
};
