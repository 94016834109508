import React, { FC } from 'react';
import { Payor, Plan } from '../types';
import { SelectedPlanList } from './SelectedPlanList';

const sortPayors = (a: Payor, b: Payor): number => {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();

  if (aLower < bLower) {
    return -1;
  } else if (aLower > bLower) {
    return 1;
  }
  return 0;
};

interface Props {
  selectedPayorsAndPlans: Payor[];
  handlePlanRemove: (arg0: Plan) => void;
}

const SelectedPayorList: FC<Props> = props => {
  const { selectedPayorsAndPlans, handlePlanRemove } = props;
  const payorsRenderList = selectedPayorsAndPlans.sort(sortPayors).map(item => (
    <div key={item.name}>
      <li
        className="p-2 no-select border-grey-light border-t-2 border-b-2 font-extrabold text-lg text-black px-4"
        key={item.name}
      >
        {item.name}
      </li>
      <SelectedPlanList key={`${item.name}-plans`} plans={item.plans} handlePlanRemove={handlePlanRemove} />
    </div>
  ));

  return (
    <div>
      {selectedPayorsAndPlans.length > 0 ? (
        <ul className="list-reset overflow-y-scroll" style={{ height: '80vh' }}>
          {payorsRenderList}
        </ul>
      ) : (
        <div style={{ height: '80vh' }} className="w-100 flex items-center justify-center">
          <span className="text-2xl text-grey-light">No Insurance Plans</span>
        </div>
      )}
    </div>
  );
};

export { SelectedPayorList };
