import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { Query } from '@apollo/client/react/components';
import { RouteComponentProps } from 'react-router-dom';
import { Spinner } from '../../../components';
import { DirectoryProfile } from '../components/DirectoryProfile';
import { PublicProfile } from '../containers/DirectoryContext';

interface Data {
  getPublicProfile: PublicProfile;
}

interface MatchParams {
  profileId: string;
}

interface Variables {
  id: string;
}

const getPublicProfileQuery = gql`
  query getPublicProfileQuery($id: ID!) {
    getPublicProfile(id: $id) {
      id
      displayName
      email
      phone
      profileImgUrl
      professionalStatementSafe
      boardCertificationsSafe
      educationTrainingSafe
      specialty
      location {
        id
        name
        address1
        address2
        city
        state
        postalCode
      }
      organization {
        id
        name
      }
    }
  }
`;

export const DirectoryProfilePage: FC<RouteComponentProps<MatchParams>> = ({ history, match }): JSX.Element => (
  <Query<Data, Variables> query={getPublicProfileQuery} variables={{ id: match.params.profileId }}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />;
      if (error || !data) return <div>Error</div>;

      const { getPublicProfile } = data;

      return <DirectoryProfile history={history} profile={getPublicProfile} />;
    }}
  </Query>
);
