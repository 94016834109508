import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { Modal } from 'antd';
import { Mutation } from '@apollo/client/react/components';
import { ModalProps } from 'antd/lib/modal';

interface Data {
  deleteGroupOrganization: {
    id: string;
    name: string;
    organizations: { id: string; name: string }[];
  };
}

interface Props extends ModalProps {
  groupId: string;
  onOk: (e: React.MouseEvent) => void;
  organization: { id: string; name: string };
}

interface Variables {
  deleteGroupOrganizationInput: {
    groupId: string;
    organizationId: string;
  };
}

const deleteGroupOrganizationMutation = gql`
  mutation DeleteGroupOrganization($deleteGroupOrganizationInput: DeleteGroupOrganizationInput!) {
    deleteGroupOrganization(deleteGroupOrganizationInput: $deleteGroupOrganizationInput) {
      id
      name
      organizations {
        id
        name
      }
    }
  }
`;

export const DeleteGroupOrganizationModal: FC<Props> = (props): JSX.Element => {
  const { groupId, organization, onOk } = props;

  return (
    <Mutation<Data, Variables> mutation={deleteGroupOrganizationMutation}>
      {deleteGroupOrganizationMutation => {
        const handleOk = (e: React.MouseEvent): void => {
          deleteGroupOrganizationMutation({
            variables: { deleteGroupOrganizationInput: { groupId, organizationId: organization.id } },
          });

          onOk(e);
        };

        return (
          <Modal {...props} closable={false} onOk={handleOk} okText="Remove Organization" destroyOnClose={true}>
            {`Are you sure you want to remove ${organization.name} from this network group?`}
          </Modal>
        );
      }}
    </Mutation>
  );
};
