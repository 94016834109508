import * as React from 'react';
import { Typography, Form, Row, Col, Input, Select, Checkbox, Radio, Button, Tooltip, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { stateNames } from '../../../../helpers';
import moment from 'moment-timezone';
import { FC } from 'react';

const { Title } = Typography;

interface SimpleConsumerOnboardingFormProps extends FormComponentProps {
  onSubmit: (arg0: SimpleConsumerOnboardingFormData) => void;
  onFinish: () => void;
  form: WrappedFormUtils<SimpleConsumerOnboardingFormData>;
  allowSkip: boolean;
  title?: string;
}

export interface SimpleConsumerOnboardingFormData {
  displayName: string;
  address1: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
  timeZone: string;
  duration: number;
  days: string[];
  procedureId: string;
  start: string;
  end: string;
}

const defaultTimeZone = moment.tz
  .guess()
  .toUpperCase()
  .replace('/', '_');

const DisplayNameLabel: FC = (): JSX.Element => (
  <>
    Profile Display Name:
    <Tooltip
      title="This name is how customers identify this profile within your organization. It can be a location name,
      provider name, or machine name. - e.g. Dr. Susan or Main Street Clinic."
    >
      <Icon style={{ verticalAlign: 'middle', paddingBottom: '4px', paddingLeft: '8px' }} type="question-circle" />
    </Tooltip>
  </>
);

const SimpleConsumerOnboarding: React.FC<SimpleConsumerOnboardingFormProps> = ({
  form,
  onSubmit,
  onFinish,
  allowSkip,
  title = 'Create your first schedule',
}) => {
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  const validateStartLessThanEnd = (rule: string, value: string, callback: (arg0?: string) => void): void => {
    const start = parseInt(form.getFieldValue('start'));
    const end = parseInt(form.getFieldValue('end'));

    if (start >= end) {
      callback('Start time must be before end time');
    } else {
      callback();
    }
  };

  return (
    <Row type="flex" justify="center">
      <Col xs={24} md={18} lg={18} xl={14}>
        <Title level={2} id="title">
          {title}
        </Title>
        <Form onSubmit={handleFormSubmit}>
          <Row type="flex" justify="center" gutter={8}>
            <Col xs={24}>
              <Form.Item label={<DisplayNameLabel />}>
                {form.getFieldDecorator('displayName', {
                  rules: [
                    {
                      min: 3,
                      required: true,
                      message: 'Please input the display name',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Address">
                {form.getFieldDecorator('address1', {
                  rules: [
                    {
                      min: 3,
                      required: true,
                      message: 'Please input the address',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="City">
                {form.getFieldDecorator('city', {
                  rules: [
                    {
                      min: 3,
                      required: true,
                      message: 'Please input the city',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label="State">
                {form.getFieldDecorator('state', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input the state',
                    },
                  ],
                })(
                  <Select<string>
                    showSearch
                    showAction={['focus', 'click']}
                    placeholder="State"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option.props.children && typeof option.props.children == 'string') {
                        return option.props.children.toLowerCase().startsWith(input.toLowerCase());
                      } else {
                        return false;
                      }
                    }}
                  >
                    {stateNames.map(stateName => (
                      <Select.Option value={stateName} key={stateName}>
                        {stateName}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label="Zipcode">
                {form.getFieldDecorator('postalCode', {
                  rules: [
                    {
                      min: 5,
                      required: true,
                      message: 'Please input the zipcode',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Phone Number">
                {form.getFieldDecorator('phone', {
                  rules: [
                    {
                      min: 10,
                      required: true,
                      message: 'Please input the phone',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Time Zone">
                {form.getFieldDecorator('timeZone', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input the time zone',
                    },
                  ],
                  initialValue: defaultTimeZone,
                })(
                  <Select<string>
                    showSearch
                    showAction={['focus', 'click']}
                    placeholder="Select Timezone"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option.props.children && typeof option.props.children == 'string') {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      } else {
                        return false;
                      }
                    }}
                  >
                    <Select.Option value="AMERICA_ANCHORAGE">America/Anchorage (AKST)</Select.Option>
                    <Select.Option value="AMERICA_CHICAGO">America/Chicago (CDT)</Select.Option>
                    <Select.Option value="AMERICA_DENVER">America/Denver (MDT)</Select.Option>
                    <Select.Option value="AMERICA_DETROIT">America/Detroit (EDT)</Select.Option>
                    <Select.Option value="AMERICA_LOS_ANGELES">America/Los Angeles (PDT)</Select.Option>
                    <Select.Option value="AMERICA_NEW_YORK">America/New York (EDT)</Select.Option>
                    <Select.Option value="AMERICA_PHOENIX">America/Phoenix</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24}>
              <Title level={3} id="title">
                Schedule Template
              </Title>
              <Typography.Paragraph>
                Template rules can be adjusted at any time. Once created, you may adjust daily start and end times as
                well as add out-of-office dates.
              </Typography.Paragraph>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Visit Type">
                {form.getFieldDecorator('procedureId', {
                  rules: [
                    {
                      required: true,
                      message: 'Visit type is required',
                    },
                  ],
                  initialValue: '1bea26e3-3567-433c-a321-72eb4f4d71d9',
                })(
                  <Select>
                    <Select.Option value="1bea26e3-3567-433c-a321-72eb4f4d71d9">COVID-19 Onsite Testing</Select.Option>
                    <Select.Option value="d012eb83-164e-4c28-83b1-0133b218a3ed">
                      COVID-19 Drive-Thru Testing
                    </Select.Option>
                    <Select.Option value="08902fd3-2c13-4b66-a52b-ec5938c8b178">
                      COVID-19 Onsite Vaccination
                    </Select.Option>
                    <Select.Option value="13b9e709-25e6-445d-8299-96f871978918">
                      COVID-19 Drive-Thru Vaccination
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item label="Daily Start Time">
                {form.getFieldDecorator('start', {
                  rules: [
                    {
                      required: true,
                      message: 'Start time must be before end time',
                      validator: validateStartLessThanEnd,
                    },
                  ],
                  initialValue: '09',
                })(
                  <Select>
                    <Select.Option value="07">07:00AM</Select.Option>
                    <Select.Option value="08">08:00AM</Select.Option>
                    <Select.Option value="09">09:00AM</Select.Option>
                    <Select.Option value="10">10:00AM</Select.Option>
                    <Select.Option value="11">11:00AM</Select.Option>
                    <Select.Option value="12">12:00AM</Select.Option>
                    <Select.Option value="13">01:00PM</Select.Option>
                    <Select.Option value="14">02:00PM</Select.Option>
                    <Select.Option value="15">03:00PM</Select.Option>
                    <Select.Option value="16">04:00PM</Select.Option>
                    <Select.Option value="17">05:00PM</Select.Option>
                    <Select.Option value="18">06:00PM</Select.Option>
                    <Select.Option value="19">07:00PM</Select.Option>
                    <Select.Option value="20">08:00PM</Select.Option>
                    <Select.Option value="21">09:00PM</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item label="Daily End Time">
                {form.getFieldDecorator('end', {
                  rules: [
                    {
                      required: true,
                      message: 'End time must be after start time',
                      validator: validateStartLessThanEnd,
                    },
                  ],
                  initialValue: '17',
                })(
                  <Select>
                    <Select.Option value="07">07:00AM</Select.Option>
                    <Select.Option value="08">08:00AM</Select.Option>
                    <Select.Option value="09">09:00AM</Select.Option>
                    <Select.Option value="10">10:00AM</Select.Option>
                    <Select.Option value="11">11:00AM</Select.Option>
                    <Select.Option value="12">12:00AM</Select.Option>
                    <Select.Option value="13">01:00PM</Select.Option>
                    <Select.Option value="14">02:00PM</Select.Option>
                    <Select.Option value="15">03:00PM</Select.Option>
                    <Select.Option value="16">04:00PM</Select.Option>
                    <Select.Option value="17">05:00PM</Select.Option>
                    <Select.Option value="18">06:00PM</Select.Option>
                    <Select.Option value="19">07:00PM</Select.Option>
                    <Select.Option value="20">08:00PM</Select.Option>
                    <Select.Option value="21">09:00PM</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Appointment Length">
                {form.getFieldDecorator('duration', { initialValue: 15 })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value={5}>5 min</Radio.Button>
                    <Radio.Button value={10}>10 min</Radio.Button>
                    <Radio.Button value={15}>15 min</Radio.Button>
                    <Radio.Button value={20}>20 min</Radio.Button>
                    <Radio.Button value={30}>30 min</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Days available">
                {form.getFieldDecorator('days', {
                  initialValue: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
                })(
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row>
                      <Col span={6}>
                        <Checkbox value="monday">Mon</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="tuesday">Tue</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="wednesday">Wed</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="thursday">Thu</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="friday">Fri</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="saturday">Sat</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="sunday">Sun</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={24} style={{ paddingTop: '2rem' }}>
              <Button type="primary" htmlType="submit" size="large">
                Create
              </Button>
              {allowSkip && (
                <Button size="large" onClick={onFinish} style={{ marginLeft: 8 }}>
                  Skip
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export const SimpleConsumerOnboardingForm = Form.create<SimpleConsumerOnboardingFormProps>()(SimpleConsumerOnboarding);
