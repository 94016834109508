import React, { FC } from 'react';
import { Checkbox, Row, Col, Form, Card } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { history } from '../../../../core/components/App/history';
import queryString from 'query-string';

export const TagFilters: FC<{ filters: { tags: { [key: string]: string[] } } }> = ({ filters }): JSX.Element | null => {
  if (!filters.tags) return null;

  const queryParams = queryString.parse(history.location.search);
  const queryFilters =
    queryParams.filters && !Array.isArray(queryParams.filters) ? JSON.parse(queryParams.filters) : {};

  const onChange = (groupKey: string, checkedValues: CheckboxValueType[]): void => {
    if (!queryFilters.tags) {
      queryFilters.tags = {};
    }
    queryFilters.tags[groupKey] = checkedValues;

    const updatedQueryParams = {
      ...queryParams,
      filters: JSON.stringify({ ...queryFilters }),
      page: 1,
    };

    history.push(history.location.pathname + '?' + queryString.stringify(updatedQueryParams));
  };

  const alreadyChecked = queryFilters.tags || {};

  return (
    <Row>
      <Card title="Filters" size="small" style={{ marginBottom: 12 }}>
        <Form>
          {Object.entries(filters.tags).map(([k, v]) => (
            <Form.Item key={k} label={k.replace(/_/g, ' ')} style={{ padding: 0, margin: 0 }}>
              <Checkbox.Group
                style={{ width: '100%' }}
                defaultValue={alreadyChecked[k]}
                onChange={values => onChange(k, values)}
              >
                <Row>
                  {v.map(item => (
                    <Col key={item} span={v.length <= 2 ? 8 : 24}>
                      <Checkbox value={`${k}:${item}`}>{item.replace(/_/g, ' ')}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          ))}
        </Form>
      </Card>
    </Row>
  );
};
