import { Avatar } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  id: string;
  name: string;
  profileImgUrl: string;
}

const Styles = styled.div`
  .profile-image {
    width: 150px;

    img {
      border-radius: 0;
      height: 150px;
      max-width: 150px;
      width: auto;
    }
  }
`;

export const ProfileCardImage: FC<Props> = ({ id, name, profileImgUrl }): JSX.Element => (
  <Styles>
    {profileImgUrl ? (
      <div className="profile-image">
        <img src={profileImgUrl} alt={name} className="profile-image" />
      </div>
    ) : (
      <Avatar size={128} shape="square" icon="user" />
    )}
  </Styles>
);
