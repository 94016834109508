import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { QueryParamProvider } from 'use-query-params';
import { routes as appointmentRoutes } from '../../../appointments';
import { Login, Logout, SSO } from '../../../auth';
import { routes as consumerSchedulingRoutes } from '../../../consumer';
import { routes as covidRoutes } from '../../../covid';
import { routes as directoryRoutes } from '../../../directory';
import { routes as groupRoutes } from '../../../groups';
import { routes as locationRoutes } from '../../../locations';
import { routes as organizationRoutes } from '../../../organizations';
import { routes as profileRoutes } from '../../../profiles';
import { routes as providerRoutes } from '../../../providers';
import { routes as quizRoutes } from '../../../quiz';
import { routes as referralRoutes } from '../../../referrals';
import { routes as settingsRoutes } from '../../../settings';
import { routes as signupRoutes } from '../../../signup';
import { routes as userRoutes } from '../../../users';
import { routes as workflowQueuesRoutes } from '../../../workflowQueues';

import { DefaultRoute } from './DefaultRoute';
import { history } from './history';

const DefaultRouter = ({ handleAuthChange, isAuthenticated, children }) => (
  <Router history={history}>
    <QueryParamProvider ReactRouterRoute={Route}>
      <LastLocationProvider>
        <Switch>
          {consumerSchedulingRoutes}
          {covidRoutes}
          {directoryRoutes}
          {groupRoutes}
          {userRoutes}
          {profileRoutes}
          {locationRoutes}
          {organizationRoutes}
          {providerRoutes}
          {quizRoutes}
          {settingsRoutes}
          {signupRoutes}
          {appointmentRoutes}
          {workflowQueuesRoutes}
          <Route
            path="/patient/referrals/:id"
            render={props => {
              window.location = 'https://api.blockitnow.com/consumer/referrals/redirect/' + props.match.params.id;
            }}
          />
          <Route
            path="/logout"
            render={props => (
              <Logout handleAuthChange={handleAuthChange} isAuthenticated={isAuthenticated} {...props} />
            )}
          />
          <Route
            path="/login"
            render={props => <Login handleAuthChange={handleAuthChange} isAuthenticated={isAuthenticated} {...props} />}
          />
          <Route path="/sso" render={props => <SSO handleAuthChange={handleAuthChange} {...props} />} />
          <Route path="/auth" render={props => <SSO handleAuthChange={handleAuthChange} {...props} />} />
          {referralRoutes}
          <DefaultRoute />
        </Switch>
        {children}
      </LastLocationProvider>
    </QueryParamProvider>
  </Router>
);

export default DefaultRouter;
