import React, { FC } from 'react';
import styled from 'styled-components';
import { ProfileCardAddress } from './ProfileCardAddress';
import { ProfileCardSpecialties } from './ProfileCardSpecialties';
import { PublicProfile } from '../containers/DirectoryContext';

interface Props {
  profile: PublicProfile;
}

const Styles = styled.div`
  .profile-info {
    display: flex;
    justify-content: space-between;
  }
`;

export const ProfileCardInfo: FC<Props> = ({ profile }): JSX.Element => (
  <Styles>
    <div className="profile-info">
      <ProfileCardAddress profile={profile} />
      {profile.specialties.length > 0 ? <ProfileCardSpecialties specialties={profile.specialties} /> : null}
    </div>
  </Styles>
);
