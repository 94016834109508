import _ from 'lodash';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { EmptySlotButton } from './EmptySlotButton';
import { MoreSlotsButton } from './MoreSlotsButton';
import { Slot } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';
import { SlotButton } from './SlotButton';

interface Props {
  onSelectSlot: (slot: Slot) => void;
  slots: Slot[];
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MIN_SLOT_TIMES_PER_COLUMN = 4;

export const ProfileSlotsColumnButtons: FC<Props> = ({ onSelectSlot, slots }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const SLOT_TIMES_TO_SHOW =
    slots.length > MIN_SLOT_TIMES_PER_COLUMN && showMore ? slots.length : MIN_SLOT_TIMES_PER_COLUMN;

  return (
    <Styles className="column-wrapper">
      {_.range(0, SLOT_TIMES_TO_SHOW).map(index => {
        if (slots[index]) {
          return <SlotButton disabled={false} onClick={onSelectSlot} slot={slots[index]} key={index} />;
        } else {
          return <EmptySlotButton key={index} />;
        }
      })}
      {slots.length > MIN_SLOT_TIMES_PER_COLUMN && !showMore ? (
        <MoreSlotsButton disabled={false} onClick={() => setShowMore(true)} />
      ) : null}
    </Styles>
  );
};
