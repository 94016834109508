import { lighten } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  position: absolute;
  top: 50%;
  width: 25px;
  height: 25px;
  background-color: ${props => lighten(0.1, props.theme.primaryColor)};
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  color: white;
  font-size: 1.2em;
  transform: translate(-50%, -50%);
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
    background-color: ${props => props.theme.primaryColor};
  }

  .displayText {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface Props {
  number: number;
  lat: number;
  lng: number;
  label: string;
}

export const GoogleMapsMarker: FC<Props> = ({ number, lat, lng, label }) => (
  <Styles>
    <div className="displayText">
      <span>{number}</span>
    </div>
  </Styles>
);
