import { Icon, Tag, Tooltip } from 'antd';
import React, { FC } from 'react';
import { EntryInterface } from '../../../GraphQL/ListReferrals.graphql';
import moment from 'moment';

interface Props {
  profile?: {
    isAccessCenter: boolean;
  };
  status?: string;
  referral?: EntryInterface;
}

const isStaleSentToProvider = (referral?: EntryInterface): boolean => {
  if (!referral) return false;

  const hasNoAppointment = !referral.appointment;
  const isProfileReferral = !!referral.profileReferral;
  const daysSinceInsertion = moment().diff(moment(referral.insertedAt), 'days');
  const isStale = daysSinceInsertion >= 1;

  return hasNoAppointment && isProfileReferral && isStale;
};

export const SentToProviderTag: FC<Props> = ({ profile, status, referral }): JSX.Element => {
  if (profile?.isAccessCenter) {
    return (
      <Tooltip title="Referral has been sent to the access / call center" placement="right">
        <Tag color="green">Sent To Access Center</Tag>
      </Tooltip>
    );
  }

  if (status === 'cancelled') {
    return (
      <Tooltip title="Appointment has been cancelled" placement="right">
        <Tag>Cancelled</Tag>
      </Tooltip>
    );
  }

  if (status === 'pending') {
    return (
      <div>
        <Tooltip title="Appointment information has been sent to provider for scheduling" placement="right">
          <Tag color="orange">Sent To Provider</Tag>
        </Tooltip>
        {isStaleSentToProvider(referral) ? <Icon type="warning" style={{ color: '#F6812C' }} /> : null}
      </div>
    );
  }

  return (
    <Tooltip title="Appointment information has been sent to provider for scheduling" placement="right">
      <Tag color="green">Sent To Provider</Tag>
    </Tooltip>
  );
};
