import React, { FC, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Col, Row, Button } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { defaultTheme } from '../../../themes/default';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { Profile, useGetConsumerSchedulingProfileQuery } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { StepContainer } from './StepContainer';
import { useQueryParam, StringParam } from 'use-query-params';

interface Props {
  organization: Organization;
  params: Params;
  profileId: string;
}

interface Params {
  embedded?: string;
  latitude?: string;
  longitude?: string;
  location?: string;
  payorId?: string;
  payorPlanId?: string;
  procedureId?: string;
  specialtyId?: string;
}

export const InstantProfileStep: FC<Props> = ({ organization, params, profileId }): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);

  const history = useHistory();
  const [, setQsApptType] = useQueryParam('apptType', StringParam);

  const handleGetInLine = (profile: Profile): void => {
    const queryString = params.embedded === 'true' ? '?embedded=true' : '';

    history.push(`/consumer/${organization.slug ? organization.slug : organization.id}/confirm${queryString}`, {
      payorId: params.payorId ? params.payorId : undefined,
      payorPlanId: params.payorPlanId ? params.payorPlanId : undefined,
      procedureId: params.procedureId,
      profile,
      apptType: 'instant',
    });
  };

  const { data, error, loading } = useGetConsumerSchedulingProfileQuery({
    variables: {
      id: profileId,
      latitude: params?.latitude ? parseFloat(params.latitude) : undefined,
      longitude: params?.longitude ? parseFloat(params.longitude) : undefined,
    },
  });

  if (error) {
    return <InternalServerErrorAlert error={error} />;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const { getConsumerSchedulingProfile: profile } = data;

  return (
    <ThemeProvider theme={defaultTheme}>
      <StepContainer maxWidth="1248px">
        <Row type="flex" justify="center">
          <Col span={4}>
            <Button type="primary" size="large" block onClick={() => handleGetInLine(profile)}>
              Get in line
            </Button>
          </Col>
          <Col span={2} style={{ textAlign: 'center', fontSize: 22 }}>
            or
          </Col>
          <Col span={4}>
            <Link to="#">
              <Button type="primary" size="large" block onClick={() => setQsApptType('scheduled')}>
                Schedule a time
              </Button>
            </Link>
          </Col>
        </Row>
      </StepContainer>
    </ThemeProvider>
  );
};
