import React, { FC } from 'react';
import { Button, Col, Icon, Row } from 'antd';
import { Spinner } from '../../../components';
import { DateTime } from 'luxon';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { Slot, useGetConsumerSchedulingProfileSlotsQuery } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';
import { useGetWaitListAvailabilitiesQuery, WaitListAvailabilities } from '../hooks/useGetWaitListAvailabilitiesQuery';

interface Props {
  dates: DateTime[];
  procedureId: string;
  onSelectSlot: (slot: Slot) => void;
  profile: Profile;
  showMap: boolean;
}

interface SlotLoadingErrorProps {
  refetch: () => void;
}

const SlotLoadingError: FC<SlotLoadingErrorProps> = ({ refetch }) => {
  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={24}>
        <h3 style={{ textAlign: 'center' }}>Unable to load schedule</h3>
        <p style={{ textAlign: 'center' }}>Please wait a moment and try again</p>
      </Col>
      <Col span={24}>
        <div style={{ textAlign: 'center', paddingTop: 10 }}>
          <Button
            onClick={() => {
              refetch();
            }}
          >
            Retry <Icon type="reload" />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

interface RenderProps {
  waitList: WaitListAvailabilities;
  slots: Slot[];
  onSelectSlot: (slot: Slot) => void;
}

const RenderFullDay: FC<RenderProps> = ({ waitList, slots, onSelectSlot }) => {
  return (
    <>
      {waitList.availabilities[0].free_slots_count > 0 ? (
        <Button type="primary" onClick={() => onSelectSlot(slots[0])}>
          Get-in-line
        </Button>
      ) : (
        <Button type="primary" disabled>
          Get-in-line
        </Button>
      )}

      <p className="mt-3">{waitList.availabilities[0].free_slots_count} spots remaining</p>
    </>
  );
};

const RenderTimeBound: FC<RenderProps> = ({ waitList, slots, onSelectSlot }) => {
  const firstSlotOfHour: { [index: string]: Slot } = {};

  slots.forEach(slot => {
    const hour = String(new Date(slot.start).getHours()).padStart(2, '0');
    const hourStr = `${hour}:00:00`;

    if (!(hourStr in firstSlotOfHour)) {
      firstSlotOfHour[hourStr] = slot;
    }
  });

  const availabilities = waitList.availabilities.map(a => {
    return {
      start: a.start,
      end: a.end,
      freeSlotsCount: a.free_slots_count,
      slot: firstSlotOfHour[a.start],
    };
  });

  return (
    <>
      {availabilities.map(a => (
        <React.Fragment key={a.start}>
          {a.freeSlotsCount > 0 ? (
            <Button className="h-auto m-2 p-2" type="primary" onClick={() => onSelectSlot(a.slot)}>
              <p>
                {DateTime.fromISO(a.start).toLocaleString(DateTime.TIME_SIMPLE)} -{' '}
                {DateTime.fromISO(a.end).toLocaleString(DateTime.TIME_SIMPLE)}
              </p>
              <p className="italic">{a.freeSlotsCount} spots remaining</p>
            </Button>
          ) : (
            <Button className="h-auto m-2 p-2" type="primary" disabled>
              <p>
                {DateTime.fromISO(a.start).toLocaleString(DateTime.TIME_SIMPLE)} -{' '}
                {DateTime.fromISO(a.end).toLocaleString(DateTime.TIME_SIMPLE)}
              </p>
              <p className="italic">0 spots remaining</p>
            </Button>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export const ProfileWaitListSlots: FC<Props> = ({ dates, onSelectSlot, profile, procedureId }): JSX.Element => {
  const {
    data: slotData,
    error: slotError,
    loading: slotLoading,
    refetch: slotRefetch,
  } = useGetConsumerSchedulingProfileSlotsQuery({
    variables: {
      profileId: profile.id,
      procedureId,
      start: dates[0].toISODate(),
      end: dates[1].toISODate(),
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: waitListData,
    error: waitListError,
    loading: waitListLoading,
    refetch: waitListRefetch,
  } = useGetWaitListAvailabilitiesQuery({
    variables: {
      getWaitListAvailabilitiesInput: {
        profileId: profile.id,
        procedureId: procedureId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (slotError) return <SlotLoadingError refetch={slotRefetch} />;

  if (slotLoading || !slotData) {
    return (
      <div style={{ height: 296 }}>
        <Spinner />
      </div>
    );
  }

  if (waitListError) return <SlotLoadingError refetch={waitListRefetch} />;

  if (waitListLoading || !waitListData) {
    return (
      <div style={{ height: 296 }}>
        <Spinner />
      </div>
    );
  }

  const { getConsumerSchedulingProfileSlots: slots } = slotData;

  const { getWaitListAvailabilities: waitList } = waitListData;

  const today = new Date().toDateString();

  return (
    <div className="justify-center p-4 bg-grey-lighter">
      <div className="text-center">
        <h3 className="mb-3">Same day ({today})</h3>

        {waitList.type === 'full_day' ? (
          <RenderFullDay waitList={waitList} slots={slots} onSelectSlot={onSelectSlot} />
        ) : (
          <RenderTimeBound waitList={waitList} slots={slots} onSelectSlot={onSelectSlot} />
        )}
      </div>
    </div>
  );
};
