import { Avatar, Button, Col, Icon, Row } from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { formatPhone } from '../../../../../helpers';
import { FeatureFlag, FeatureGate } from '../../../../../helpers/featureFlags';
import ProfileAbout from './ProfileAbout';
import { SearchWizardContainerContext } from '../../../pages/SearchWizardContainer';

const Styles = styled.div`
  i {
    margin-right: 0.25rem;
  }

  .profile-image {
    border-radius: 0;
    height: 125px;
    width: 100%;
    max-width: 120px;
    background-image: url(${props => props.profile.profileImgUrl});
    background-size: cover;
  }

  .about-section {
    margin-bottom: 0.25rem;
  }

  .map-number {
    position: absolute;
    background-color: ${props => props.theme.primaryColor};
    height: 24px;
    width: 24px;
    border-radius: 100%;
    color: white;
    top: -8px;
    left: -2px;
    border: 2px solid white;
    z-index: 2;

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .profile-name {
    font-weight: 800;
  }

  // todo - why doesn't this work without !important???
  .send-to-patient,
  .send-to-provider {
    .ant-btn-primary {
      background-color: ${props => props.theme.primaryColor} !important;
      border-color: ${props => props.theme.primaryColor} !important;

      :hover {
        background-color: #519ecf !important;
        border-color: #519ecf !important;
      }
    }
  }

  .send-to-provider {
    margin-top: 4px;
  }

  .location-section,
  .about-section {
    margin-top: 1rem;
    font-size: 12px;
    line-height: 1.2;
  }

  .name-section {
    .subtitles {
      font-size: 12px;
      line-height: 1.2;
    }
  }

  .text-red-light {
    color: #de2d1d;
  }

  .text-green-light {
    color: #13a161;
  }

  .insurance-match,
  .miles {
    font-size: 12px;
  }
`;

const matchTypes = {
  unknown: {
    text: 'Unknown Insurance',
    className: 'text-grey-dark',
    icon: 'question-circle',
  },
  out_of_network: {
    text: 'Out of Network',
    className: 'text-red-light',
    icon: 'close-circle',
  },
  unknown_plan: {
    text: "Doesn't take plan",
    className: 'text-orange-light',
    icon: 'close-circle',
  },
  plan_match: {
    text: 'Takes insurance plan',
    className: 'text-green-light',
    icon: 'check-circle',
  },
};

const acceptsInsurance = matchType => {
  if (!matchTypes[matchType]) return null;

  // Disable insurance for time being
  return (
    <div className="insurance-match">
      <span className={matchTypes[matchType].className}>
        <Icon type={matchTypes[matchType].icon} className={matchTypes[matchType].className} />
        {matchTypes[matchType].text}
      </span>
      <br />
    </div>
  );
};

const ProfileCard = ({
  currentOrganization,
  profile,
  showMap,
  coverage = {},
  insuranceNetwork,
  insuranceConfidence,
  handleSendToPatient,
  handleSendToProvider,
  showInsuranceMatch = true,
  ...props
}) => {
  const { bookedByProvider } = useContext(SearchWizardContainerContext);

  return (
    <Styles {...props} profile={profile}>
      <Row gutter={16}>
        <Col span={showMap ? 12 : 8}>
          <div className="map-number">
            <div>{profile.mapNumber}</div>
          </div>
          {profile.profileImgUrl ? <div className="profile-image" /> : <Avatar shape="square" size={120} icon="user" />}
          <div>
            {profile.location.distanceFromPatient !== null || profile.location.distanceFromProvider !== null ? (
              <div className="miles text-grey-dark">
                <Icon type="compass" />
                {(profile.location.distanceFromPatient || profile.location.distanceFromProvider).toFixed(0)}mi from{' '}
                {profile.location.distanceFromPatient ? 'patient' : 'office'}
              </div>
            ) : (
              <div className="miles" />
            )}
            {showInsuranceMatch && coverage.id && (
              <FeatureGate feature={FeatureFlag.ShowInsuranceMatch}>{acceptsInsurance(insuranceNetwork)}</FeatureGate>
            )}
            <div className="about-section">
              <ProfileAbout profile={profile} />
            </div>
            {!bookedByProvider && (
              <div className="send-buttons">
                {profile.isSendToPatient && currentOrganization.showSendToPatient ? (
                  <div className="send-to-patient">
                    <Button type="primary" block onClick={() => handleSendToPatient(profile)}>
                      Send to Patient
                    </Button>
                  </div>
                ) : null}
                {profile.isSendToProvider && profile.isIntegrated ? (
                  <div className="send-to-provider">
                    <Button type="primary" size="small" block onClick={() => handleSendToProvider(profile)}>
                      Send to Provider
                    </Button>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </Col>

        <Col span={showMap ? 12 : 16}>
          <div className="name-section">
            <div className="profile-name">{profile.displayName}</div>
            <div className="subtitles">
              <div>{profile.specialty}</div>
              <div>{profile.organization.name}</div>
            </div>
          </div>
          <div className="location-section">
            <div>
              <b>{profile.location.name}</b>
            </div>
            <div>{profile.location.address1}</div>
            <div>{profile.location.address2}</div>
            <div>
              {profile.location.city}, {profile.location.state} {profile.location.postalCode}
            </div>
            <div>{formatPhone(profile.phone) || formatPhone(profile.location.phone)}</div>
          </div>
        </Col>
      </Row>
    </Styles>
  );
};

export default ProfileCard;
