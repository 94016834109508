import React, { FC, useContext } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { CreateProfilePage } from '../pages/CreateProfilePage';
import { ProfileRoutes } from './ProfileRoutes';
import { Profiles } from '../pages/Profiles';
import { SimpleConsumerOnboarding } from '../../covid/components/SimpleConsumerOnboarding';
import { AppContext } from '../../core/contexts/AppContext';
import { useHistory } from 'react-router-dom';
import { Col, PageHeader, Row } from 'antd';

export const ProfilesRoutes: FC<RouteComponentProps> = ({ match }): JSX.Element | null => {
  const { currentOrganization } = useContext(AppContext);
  const history = useHistory();

  if (!currentOrganization) return null;

  return (
    <Switch>
      <Route exact path={match.path} key="route--profiles-profiles" component={Profiles} />
      <Route exact path={`${match.path}/new`} key="route--profiles-new" component={CreateProfilePage} />
      <Route
        exact
        path={`${match.path}/new-covid`}
        key="route--profiles-new"
        render={() => (
          <Row type="flex">
            <Col span={24}>
              <PageHeader title="Add a new profile" onBack={() => history.goBack()}>
                <SimpleConsumerOnboarding
                  organizationId={currentOrganization?.id}
                  title={''}
                  allowSkip={false}
                  onFinish={() => history.push(`/organizations/${currentOrganization?.id}/profiles`)}
                />
              </PageHeader>
            </Col>
          </Row>
        )}
      />

      <Route path={`${match.path}/:profileId`} key="route--profiles-profile" component={ProfileRoutes} />
    </Switch>
  );
};
