import React, { FC } from 'react';
import { Form, Switch } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectActive: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Active:">
    {form.getFieldDecorator('isActive', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ message: `Please select your profile's active status.`, required: true }],
    })(<Switch />)}
  </Form.Item>
);
