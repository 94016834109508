import _ from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { caseInsensitiveSelectFilterOption } from '../../../helpers/caseInsensitiveSelectFilterOption';
import { Option, Select } from '../../../system/components/Select';

interface Props {
  form: WrappedFormUtils;
  onSelect: (value: number, field: string) => void;
  initialValue?: number | null;
}

const Styles = styled.div`
  .no-margin-bottom {
    margin-bottom: 0;
  }
`;

export const SelectPatientBirthYear: FC<Props> = ({ form, onSelect, initialValue }): JSX.Element => (
  <Styles>
    <Form.Item className="no-margin-bottom">
      {form.getFieldDecorator('birthYear', { initialValue: initialValue })(
        <Select
          filterOption={caseInsensitiveSelectFilterOption}
          placeholder="Year"
          onChange={(v: number) => onSelect(v, 'year')}
          showAction={['focus', 'click']}
          showSearch
        >
          {_.range(1900, DateTime.utc().year + 1)
            .reverse()
            .map(year => (
              <Option value={year} key={`year-${year}`}>
                {year.toString()}
              </Option>
            ))}
        </Select>
      )}
    </Form.Item>
  </Styles>
);
