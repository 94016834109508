import { OrganizationType, Question } from '../containers/QuizContainer';

export const quiz1Questions: Question[] = [
  {
    question: 'Which of the following is the benefit of using Blockit?',
    answers: [
      'Increase patient follow through.',
      'Increase patient satisfaction.',
      'Automate burdensome administrative tasks.',
      'All of the above.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 3,
    type: 'both' as OrganizationType,
  },
  {
    question: 'Blockit requires sending and receiving providers to have the same EMR?',
    answers: [
      'True – all providers must be on the same EMR',
      'False – Blockit is “EMR Agnostic” and therefore integrates different EMRs',
    ],
    selectedAnswer: undefined,
    correctAnswer: 1,
    type: 'both' as OrganizationType,
  },
  {
    question: 'The best way to check the status of a referral created in Blockit would be.',
    answers: [
      'Call receiving provider',
      'Fax a status update form to receiving provider',
      'Verify patient status real time in Blockit',
    ],
    selectedAnswer: undefined,
    correctAnswer: 2,
    type: 'both' as OrganizationType,
  },
  {
    question: 'You can not attach documents or add notes to a Blockit referral.',
    answers: [
      'True - additional information must be sent via fax.',
      'False – Blockit allows providers to attach any information required to be attached to the referral.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 1,
    type: 'both' as OrganizationType,
  },
  {
    question: 'Blockit On Call is.',
    answers: [
      'The support function in Blockit that is accessed by clicking the Blockit log at the lower right of the screen.',
      'A pizza delivery service',
      'Provides access to useful information and real time support staff ready to help.',
      'Both A & C',
    ],
    selectedAnswer: undefined,
    correctAnswer: 3,
    type: 'both' as OrganizationType,
  },
  {
    question: 'Which of the following is the process for resetting/changing your password?',
    answers: [
      'Via the settings tab within the platform.',
      'Contact an administrator within my company.',
      'Contacting Blockit via in-app support.',
      'All of the above.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 3,
    type: 'both' as OrganizationType,
  },
  {
    question: 'When you would use in-app chat?',
    answers: [
      'To ask a question for the sending physician about the referral.',
      'If you think the referral is missing required attachments.',
      'If you want to notify the sending physician about details of the encounter with the patient. ',
      'All of the above.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 3,
    type: 'both' as OrganizationType,
  },
  {
    question: 'The Goals established for successful utilization of the Blockit platform for a referral is.',
    answers: [
      '5 phone / fax calls, 40% patient follow through and 20 minutes to complete',
      '0 phone / fax calls, 85% patient follow through and 2 minutes to complete',
      'As long as it takes',
    ],
    selectedAnswer: undefined,
    correctAnswer: 1,
    type: 'sender' as OrganizationType,
  },
  {
    question: 'How can I search for a patient when scheduling a referral?',
    answers: ['By first name.', 'By last name.', 'By a combination of either first or last name in any order.'],
    selectedAnswer: undefined,
    correctAnswer: 2,
    type: 'sender' as OrganizationType,
  },
  {
    question: 'Why are network groups important?',
    answers: [
      'We like to click more boxes.',
      'They help increase your Linkedin network.',
      'Network groups help focus search results.',
      'Network groups are not important',
    ],
    selectedAnswer: undefined,
    correctAnswer: 2,
    type: 'sender' as OrganizationType,
  },
  {
    question: 'What is the best way to send a referral?',
    answers: ['Via fax.', 'Via phone.', 'Give the patient a business card.', 'Via Blockit.'],
    selectedAnswer: undefined,
    correctAnswer: 3,
    type: 'sender' as OrganizationType,
  },
  {
    question: 'What do I do if the provider specified isn’t showing up in Blockit.',
    answers: [
      'Forget about it and grab the old phone.',
      'Utilize the non process established for referrals out of Blockit.',
      'Notify Blockit via Blockit on call chat and submit missing provider information',
      'Both B and C',
    ],
    selectedAnswer: undefined,
    correctAnswer: 3,
    type: 'sender' as OrganizationType,
  },
  {
    question:
      'After you have searched for an available time, which of the following is a way to further sort/filter your results?',
    answers: [
      'By Organization.',
      'By distance from the patient.',
      'By language spoken by the receiving Physician.',
      'All of the above.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 3,
    type: 'sender' as OrganizationType,
  },
  {
    question:
      'Where can you access your available times that sending organizations see when they search for receiving physicians?',
    answers: [
      'Within the availability tab.',
      'Within the network groups tab.',
      'Within the slots tab when viewing a physician profile.',
      'Within the locations tab.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 2,
    type: 'receiver' as OrganizationType,
  },
  {
    question: 'When you receive a received referral notification via email, where does clicking the link take you?',
    answers: [
      'To the received referrals tab.  ',
      'To the referral details page.',
      'To the schedule a patient tab.',
      'To the referral status page.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 2,
    type: 'receiver' as OrganizationType,
  },
  {
    question: 'If you have a question about a received referral, how can you contact the sending physician?',
    answers: [
      'Via email by viewing the sending provider’s information on the referral details page.',
      'Via phone by viewing the sending provider’s information on the referral details page.',
      'Via chat within the referral details page.',
      'All of the above.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 3,
    type: 'receiver' as OrganizationType,
  },
  {
    question: 'Blockit will require another program to manage and monitor in order to receive referrals.',
    answers: [
      'True – yet another program is added to the workflow',
      'False – Blockit is integrated into my EMR and referral information will go directly into my EMR without intervention.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 1,
    type: 'receiver' as OrganizationType,
  },
  {
    question: 'What should you do when you receive a referral?',
    answers: [
      'In addition to what you’ve learned about Blockit, follow the normal process when accepting a patient. ',
      'If the referral was received via fax or phone, tell my supervisor to contact the sender about using Blockit. ',
      'All of the above.',
    ],
    selectedAnswer: undefined,
    correctAnswer: 2,
    type: 'receiver' as OrganizationType,
  },
];
