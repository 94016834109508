import { Avatar, Badge, Icon, Popover, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { gql } from '@apollo/client';
import queryString, { ParsedQuery } from 'query-string';
import React, { FC, useContext } from 'react';
import { Query } from '@apollo/client/react/components';
import { DisplayError } from '../../core/components/App/DisplayError';
import { history } from '../../core/components/App/history';
import { AppContext } from '../../core/contexts/AppContext';
import { isAdmin, isBlockitAdmin } from '../../core/components/PrivateRoute';
import { ProfileData } from '../../referrals/components/Referral/GetReferralQuery';
import styled from 'styled-components';

const Styles = styled.div`
  .table-row {
    cursor: pointer;
  }

  .names {
    margin-left: 24px;
  }

  .displayName {
    font-size: 16px;
    display: block;
    color: #333;
    font-weight: 800;
  }

  .locationName {
    color: #a1a1aa;
  }

  .badge {
    vertical-align: center;
  }

  .rowItem {
    display: flex;
    flex-direction: row;
  }

  .visitTypeWarn {
    font-weight: 600;
    color: #d97706;
    background-color: #fef3c7;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
  }

  .visitTypeInfo {
    font-weight: 600;
    color: #1f2937;
    background-color: #f3f4f6;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
  }
`;

const ListProfilesQuery = gql`
  query(
    $organizationId: ID!
    $locationId: ID
    $pageNumber: Int
    $pageSize: Int
    $active: Boolean
    $nameFilter: String
  ) {
    ListProfiles(
      organizationId: $organizationId
      locationId: $locationId
      pageNumber: $pageNumber
      pageSize: $pageSize
      active: $active
      nameFilter: $nameFilter
    )
      @connection(
        key: "PaginatedProfiles"
        filter: ["organizationId", "locationId", "pageSize", "pageNumber", "active", "nameFilter"]
      ) {
      totalEntries
      pageNumber
      pageSize
      totalPages
      entries {
        id
        isActive
        displayName
        phone
        type
        scheduleId
        profileImgUrl
        profileProcedures {
          id
          procedure {
            id
            name
            specialty {
              id
              name
            }
          }
        }
        location {
          id
          name
          address1
          address2
          city
          state
          postalCode
          latitude
          longitude
        }
      }
    }
  }
`;

const columns = (canAccessActions: boolean): ColumnProps<ProfileData>[] => {
  const cols = [
    {
      title: 'Active',
      width: 10,
      key: 'active',
      render: (record: ProfileData) => {
        if (record.isActive) {
          return <Badge status="success" />;
        } else {
          return <Badge status="default" />;
        }
      },
    },
    {
      title: 'Name',
      key: 'displayName',
      render: (record: ProfileData) => {
        return (
          <div className="rowItem">
            {record.profileImgUrl ? (
              <Avatar size="large" src={record.profileImgUrl} shape="square" />
            ) : (
              <Avatar size="large" icon="user" shape="square" />
            )}
            <div className="names">
              <span className="displayName">
                {record.type === 'group' ? 'Group:' : ''} {record.displayName}
              </span>
              <span className="locationName">{record.location.name}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Location',
      key: 'location',
      render: (record: ProfileData) => {
        return (
          <div>
            <div>{record.location.address1}</div>
            <div>
              {record.location.city}, {record.location.state} {record.location.postalCode}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Config',
      key: 'config',
      render: (record: ProfileData) => {
        const content = record.profileProcedures?.map(pp => {
          return (
            <p key={pp.id}>
              {pp?.procedure?.specialty.name} &gt; {pp?.procedure?.name}
            </p>
          );
        });

        return (
          <div>
            {record.profileProcedures?.length === 0 && (
              <span className="visitTypeWarn">
                <Icon type="warning" theme="filled" />
                &nbsp;No Visit Types Configured
              </span>
            )}

            {record.profileProcedures?.length !== 0 && (
              <Popover content={content}>
                <span className="visitTypeInfo">
                  <Icon type="info" theme="filled" />
                  &nbsp;{record.profileProcedures?.length} Visit Type Configured
                </span>
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  if (canAccessActions) {
    cols.push({
      title: 'Actions',
      key: 'actions',
      render: (record: ProfileData) => {
        return <Icon type="right" />;
      },
    });
  }

  return cols;
};

interface Payload {
  entries: ProfileData[];
  pageNumber: number;
  totalEntries: number;
  totalPages: number;
  pageSize: number;
}

interface QueryResponse {
  ListProfiles: Payload;
}

interface QueryVariables {
  organizationId: string;
  locationId?: string;
  pageNumber?: number;
  pageSize?: number;
  active?: boolean;
  nameFilter?: string;
}

const updateUrl = (pageNumber: number, pageSize: number): void => {
  const queryParams: ParsedQuery = queryString.parse(history.location.search);

  history.push(
    history.location.pathname +
      '?' +
      queryString.stringify({
        ...queryParams,
        pageSize,
        pageNumber,
      })
  );
};
interface ProfileTableProps {
  active: boolean;
  nameFilter: string;
}

export const ProfileTable: FC<ProfileTableProps> = ({ active, nameFilter }: ProfileTableProps): JSX.Element => {
  const { currentOrganization, currentLocation } = useContext(AppContext);

  const queryParams: ParsedQuery = queryString.parse(history.location.search);
  const additionalQueryVariables: {
    [key: string]: string | boolean | number;
  } = {
    pageSize: queryParams.pageSize && typeof queryParams.pageSize == 'string' ? parseInt(queryParams.pageSize, 10) : 10,
    pageNumber:
      queryParams.pageNumber && typeof queryParams.pageNumber == 'string' ? parseInt(queryParams.pageNumber, 10) : 1,
  };

  if (active) {
    additionalQueryVariables['active'] = true;
  }

  return (
    <Query<QueryResponse, QueryVariables>
      query={ListProfilesQuery}
      fetchPolicy="cache-and-network"
      variables={{
        organizationId: currentOrganization?.id || '',
        locationId: currentLocation?.id,
        nameFilter,
        ...additionalQueryVariables,
      }}
    >
      {({ loading, error, data }) => {
        let response: Payload = {
          entries: [],
          pageNumber: 1,
          totalEntries: 0,
          totalPages: 0,
          pageSize: 10,
        };

        if (data && data.ListProfiles) {
          response = data && data.ListProfiles ? data.ListProfiles : response;
        }

        return (
          <Styles>
            <DisplayError error={error} />
            <Table<ProfileData>
              rowClassName={() => 'table-row'}
              loading={loading}
              showHeader={false}
              dataSource={response.entries}
              columns={columns(isAdmin(currentOrganization) || isBlockitAdmin(currentOrganization))}
              rowKey={record => record.id}
              onChange={pagination => updateUrl(pagination.current || 1, pagination.pageSize || 10)}
              onRow={(record, _rowIndex) => {
                return {
                  onClick: event => {
                    event.preventDefault();
                    history.push(`/organizations/${currentOrganization?.id}/profiles/${record.id}/edit`);
                  },
                };
              }}
              pagination={{
                pageSize: response.pageSize,
                position: 'bottom',
                showSizeChanger: true,
                defaultCurrent: 0,
                current: response.pageNumber,
                total: response.totalEntries,
              }}
            />
          </Styles>
        );
      }}
    </Query>
  );
};
