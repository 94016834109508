import _ from 'lodash';
import { Form, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';

const { Option } = Select;

interface Props {
  form: WrappedFormUtils;
  procedures: {
    id: string;
    name: string;
  }[];
}

export default class DesiredProcedureField extends React.Component<Props, {}> {
  render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form.Item label="What is the desired Visit Type?">
        {getFieldDecorator('procedure', {
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ],
        })(
          <Select
            disabled={this.props.procedures.length === 0}
            showSearch
            dropdownMatchSelectWidth={false}
            placeholder="Visit Type"
            optionFilterProp="children"
          >
            {_.sortBy(this.props.procedures, ['name']).map(procedure => (
              <Option value={procedure.id} key={procedure.id}>
                {procedure.name}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  }
}
