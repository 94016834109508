import React, { FC, useContext } from 'react';
import { PageHeader } from 'antd';
import { ProfileContext } from '../contexts/ProfileContext';
import { ProfileSettings } from '../components/ProfileSettings';
import { useHistory } from 'react-router-dom';

export const ProfilePage: FC = (): JSX.Element => {
  const { profile } = useContext(ProfileContext);
  const history = useHistory();

  return (
    <PageHeader
      title={profile.displayName}
      subTitle={profile.type !== 'location' && profile.location.name}
      onBack={() => {
        history.goBack();
      }}
    >
      <ProfileSettings profile={profile} />
    </PageHeader>
  );
};
