import React from 'react';
import { PrivateRoute } from '../core';
import { Groups } from './pages/Groups';
import { UpdateGroup } from './pages/UpdateGroup';
import { Roles } from '../core/components/PrivateRoute';

export const routes = [
  <PrivateRoute exact path="/organizations/:organizationId/groups" key="route--groups" component={Groups} />,
  <PrivateRoute
    exact
    path="/organizations/:organizationId/groups/:groupId/update"
    key="route--update-group"
    component={UpdateGroup}
    minRole={Roles.ADMIN}
  />,
];
