import { Form, Radio } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
const RadioGroup = Radio.Group;

interface Props {
  form: WrappedFormUtils;
  onChange: () => void;
}

export default class PreAuthorizedField extends React.Component<Props, {}> {
  render(): JSX.Element {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form.Item label="Is the Patient Pre-Authorized?">
        {getFieldDecorator('patientPreAuthorized', {
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ],
        })(
          <RadioGroup>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </RadioGroup>
        )}
      </Form.Item>
    );
  }
}
