import React, { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import { Engagement } from '../hooks/useCreateConsumerSchedulingEngagementMutation';
import { EngagementDetails } from './EngagementDetails';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { StepContainer } from './StepContainer';
import { OrganizationImgOrNameHeader } from './Header';

interface Props {
  engagement: Engagement;
  patientName: string;
  organization: Organization;
}

export const PrintSuccessStep: FC<Props> = ({ engagement, patientName, organization }): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);

  useEffect(() => {
    try {
      if (window.print) {
        window.print();
      }
    } catch (e) {
      return;
    }
  }, []);

  return (
    <StepContainer maxWidth="924px">
      <Row gutter={[0, 36]}>
        <Col span={24}>
          <OrganizationImgOrNameHeader organization={organization} size="lg" />
          <EngagementDetails
            patientName={patientName}
            engagement={engagement}
            organization={organization}
            printing={true}
          />
        </Col>
      </Row>
    </StepContainer>
  );
};
