import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { CreateUserPage } from '../pages/CreateUserPage';
import { FinishPage } from '../pages/FinishPage';
import { LoginPage } from '../pages/LoginPage';
import { SubscribeFreePage } from '../pages/SubscribeFreePage';
// import { SubscribePage } from '../pages/SubscribePage';

export const SignupRoutes: FC<RouteComponentProps> = ({ match: { path } }): JSX.Element => {
  return (
    <Switch>
      <Route exact path={path} key="route--signup-landing" component={CreateUserPage} />
      <Route exact path={`${path}/login`} key="route--signup-login" component={LoginPage} />
      <Route exact path={`${path}/finish`} key="route--signup-finish" component={FinishPage} />
      {/*<Route exact path={`${path}/subscribe`} key="route--signup-subscribe" component={SubscribePage} />*/}
      <Route exact path={`${path}/subscribe`} key="route--signup-subscribe" component={SubscribeFreePage} />
      <Route exact path={`${path}/subscribe/free`} key="route--signup-subscribe-free" component={SubscribeFreePage} />
    </Switch>
  );
};
