import { Icon } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { PublicProfile } from '../containers/DirectoryContext';
import { DirectoryProfileContactInfo } from './DirectoryProfileContactInfo';
import { DirectoryProfileImage } from './DirectoryProfileImage';

interface Props {
  profile: PublicProfile;
}

const Styles = styled.div<Props>`
  i {
    margin-right: 8px;
  }

  .about-subsection {
    margin-bottom: 24px;
  }

  .profile-card {
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }

  .profile-card-flex {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;
  }

  .section-title {
    font-size: 16px;
    margin-bottom: 2px;
  }
`;

export const DirectoryProfileBody: FC<Props> = ({ profile }): JSX.Element => (
  <Styles profile={profile}>
    <div className="profile-card">
      <div className="profile-card-flex">
        <DirectoryProfileImage name={profile.displayName} profileImgUrl={profile.profileImgUrl} />
        <DirectoryProfileContactInfo profile={profile} />
      </div>
      {profile.professionalStatementSafe ? (
        <div className="about-subsection">
          <div className="section-title">
            <Icon type="user" />
            About
          </div>
          <p dangerouslySetInnerHTML={{ __html: profile.professionalStatementSafe }} />
        </div>
      ) : null}
      {profile.educationTrainingSafe ? (
        <div className="about-subsection">
          <div className="section-title">
            <Icon type="solution" />
            Education & Training
          </div>
          <p dangerouslySetInnerHTML={{ __html: profile.educationTrainingSafe }} />
        </div>
      ) : null}
      {profile.boardCertificationsSafe ? (
        <div className="about-subsection">
          <div className="section-title">
            <Icon type="medicine-box" />
            Board Certifications
          </div>
          <p dangerouslySetInnerHTML={{ __html: profile.boardCertificationsSafe }} />
        </div>
      ) : null}
    </div>
  </Styles>
);
