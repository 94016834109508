import { gql } from '@apollo/client';
import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

const countUsersInOrganizationQuery = gql`
  query CountUsersInOrganizationQuery($organizationId: ID!) {
    countUsersInOrganization(organizationId: $organizationId)
  }
`;

interface Data {
  countUsersInOrganization: number;
}

interface Variables {
  organizationId: string;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useCountUsersInOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(countUsersInOrganizationQuery, options);
};
