import { gql } from '@apollo/client';

const CreateReferral = gql`
  mutation CreateReferral(
    $patientId: ID!
    $providerId: ID!
    $procedureId: ID!
    $insuranceGroupNumber: String
    $insurancePolicyNumber: String
    $patientIsPreAuthorized: Boolean
  ) {
    createReferral(
      patientId: $patientId
      providerId: $providerId
      procedureId: $procedureId
      insuranceGroupNumber: $insuranceGroupNumber
      insurancePolicyNumber: $insurancePolicyNumber
      patientIsPreAuthorized: $patientIsPreAuthorized
    ) {
      id
    }
  }
`;

const CreateAppointment = gql`
  mutation CreateAppointment(
    $profileId: ID!
    $referralId: ID!
    $slotIds: [ID!]!
    $start: NaiveDatetime!
    $end: NaiveDatetime!
    $diagnoses: [ID]
    $diagnosesDescription: String
    $serviceTypeCode: String
    $serviceTypeSystem: String
    $notificationEmail: String
    $questionnaireAnswerSets: [QuestionnaireAnswerSetInput!]
    $bookedByProvider: Boolean
  ) {
    createAppointment(
      profileId: $profileId
      referralId: $referralId
      slotIds: $slotIds
      start: $start
      end: $end
      diagnoses: $diagnoses
      diagnosesDescription: $diagnosesDescription
      serviceTypeCode: $serviceTypeCode
      serviceTypeSystem: $serviceTypeSystem
      notificationEmail: $notificationEmail
      questionnaireAnswerSets: $questionnaireAnswerSets
      bookedByProvider: $bookedByProvider
    ) {
      id
      status
    }
  }
`;

const UploadReferralOrder = gql`
  mutation uploadReferralOrder($file: Upload!, $referralId: ID!, $type: String!) {
    uploadReferralOrder(file: $file, referralId: $referralId, type: $type)
  }
`;

const CancelReferral = gql`
  mutation CancelReferral($referralId: ID!, $cancelReason: String!, $cancelNote: String) {
    cancelReferral(referralId: $referralId, cancelReason: $cancelReason, cancelNote: $cancelNote) {
      id
      status
      tags {
        key
        value
      }
    }
  }
`;

const GetReferralOrders = gql`
  query($referralId: ID!, $type: String!) {
    getReferralOrders(referralId: $referralId, type: $type) {
      id
      type
      displayFilename
      orderUrl
    }
  }
`;

const GetReferralDocuments = gql`
  query($referralId: ID, $type: String) {
    getReferralDocuments(referralId: $referralId, type: $type) {
      referralId
      type
      displayFilename
      documentUrl
    }
  }
`;

const UploadReferralDocument = gql`
  mutation uploadReferralDocument($file: Upload, $referralId: ID, $type: String) {
    uploadReferralDocument(file: $file, referralId: $referralId, type: $type)
  }
`;
export {
  CreateReferral,
  CreateAppointment,
  UploadReferralOrder,
  CancelReferral,
  GetReferralOrders,
  GetReferralDocuments,
  UploadReferralDocument,
};
