import { Select } from 'antd';
import _ from 'lodash';
import React, { FC, useContext, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../contexts/AppContext';
import { isBlockitAdmin, isAdminOrBlockitAdmin } from '../PrivateRoute';
import { Viewer, ViewerOrganization } from '../../../viewer';
import { useHistory } from 'react-router-dom';
import config from '../../../../config';
import { useLazyQuery } from '@apollo/client';
import { GetViewerOrganization } from '../../../../hooks/queries/useGetViewerOrganizationQuery';
import { updateSendsAndReceivesForAllLocationsLocation } from './Layout';

const UserMenuStyles = styled.div`
  align-items: center;
  background-color: #12283a;
  color: #fff;
  display: flex;
  font-size: 16px;
  height: 50px;
  padding: 0 20px;

  .ant-select-selection,
  .ant-select-arrow {
    background-color: transparent;
    color: #fff;
  }

  .ant-select-selection {
    margin-right: 2em;
    border-bottom: 0;
    font-size: 16px;
  }

  .ant-select {
    width: auto;
    min-width: 200px;
    max-width: 500px;
  }

  .ant-select-selection-selected-value {
    margin-right: 0.5em;
  }

  .currentOrg {
    padding-right: 42px;
  }

  .border-mimic {
    box-shadow: inset 0 -2px 0 0 white;
  }

  .nav {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    > div {
      display: flex;
      justify-content: space-between;

      > div > div:not(:last-child) {
        margin-right: 2rem; // adjust as necessary
      }

      > *:not(:last-child) {
        margin-right: 2.5rem; // adjust as necessary
      }
    }

    a,
    a:visited,
    .fakeLink {
      color: #fff;
      cursor: pointer;
      text-decoration: none;
    }
  }
`;

function canSwitchLocations(currentOrganization: ViewerOrganization | null): boolean {
  return !!currentOrganization && currentOrganization.locations && currentOrganization.locations.length > 1;
}

function canSwitchOrganizations(viewer: Viewer | null): boolean {
  return !!viewer && viewer?.organizations && viewer.organizations.length > 1;
}

interface MenuSelectProps {
  ariaLabel: string;
  defaultValue: string;
  id: string;
  placeholder: string;
  setCurrent: (org: any) => void;
  options: ViewerOrganization[];
  key: string;
}

const MenuSelect: FC<MenuSelectProps> = ({
  ariaLabel,
  defaultValue,
  id,
  placeholder,
  setCurrent,
  options,
}): JSX.Element => {
  const history = useHistory();

  const handleSelect = (val: string) => {
    const organization = options.find(o => o.id === val);
    if (organization) {
      setCurrent(organization);
    }
    history.push('/');
  };

  return (
    <Select<string>
      id={id}
      showSearch
      defaultValue={defaultValue}
      dropdownMatchSelectWidth={false}
      placeholder={placeholder}
      optionFilterProp="children"
      onSelect={handleSelect}
      filterOption={(input, option) => {
        if (option.props.children && typeof option.props.children === 'string') {
          return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return false;
      }}
      aria-label={ariaLabel}
    >
      {options.map(org => (
        <Select.Option key={org.id} value={org.id} style={org.isActive ? {} : { background: '#f7f7f7', color: '#ccc' }}>
          {`${org.name}`}
        </Select.Option>
      ))}
    </Select>
  );
};

const sortViewerOrganizations = (orgs: ViewerOrganization[] | undefined): ViewerOrganization[] => {
  if (!orgs) {
    return [];
  }

  const activeOrgs = _.sortBy(
    orgs.filter(o => o.isActive),
    'name'
  );
  const inactiveOrgs = _.sortBy(
    orgs.filter(o => !o.isActive),
    'name'
  );

  return [...activeOrgs, ...inactiveOrgs];
};

type Portal = {
  id: 'admin' | 'referral';
  name: string;
};

const portals: Portal[] = [
  { id: 'admin', name: 'Admin' },
  { id: 'referral', name: 'Referral' },
];

function getActiveClasses(mode: string, portalId: string): string {
  if (mode === portalId) {
    return 'font-bold border-mimic';
  }
  return ''; // Return an empty string if it's not active.
}

export const UserMenu: FC = (): JSX.Element => {
  const {
    currentLocation,
    currentOrganization,
    mode,
    setCurrentLocation,
    setCurrentOrganization,
    switchMode,
    viewer,
    setViewer,
  } = useContext(AppContext);
  const [lazyGetViewerOrganization] = useLazyQuery(GetViewerOrganization);
  const previousOrgIdRef = useRef<string | null>(null);

  useEffect(() => {
    const fetchCurrentOrganization = async () => {
      if (currentOrganization) {
        const { data } = await lazyGetViewerOrganization({ variables: { organizationId: currentOrganization.id } });
        if (data && data.viewerOrganization && viewer) {
          const updatedOrgs = viewer.organizations.map(org =>
            org.id === data.viewerOrganization.id ? data.viewerOrganization : org
          );
          const updatedViewer = {
            ...viewer,
            organizations: updatedOrgs,
          };
          setViewer(updateSendsAndReceivesForAllLocationsLocation({ viewer: updatedViewer }));
        }
      }
    };

    if (currentOrganization && previousOrgIdRef.current !== currentOrganization.id) {
      fetchCurrentOrganization();
      previousOrgIdRef.current = currentOrganization.id;
    }
  }, [currentOrganization, lazyGetViewerOrganization, viewer, setViewer]);

  const history = useHistory();

  useEffect(() => {
    if (mode === 'admin' && !isAdminOrBlockitAdmin(currentOrganization)) {
      switchMode('referral');
    }
  }, [currentOrganization, mode, switchMode]);

  useEffect(() => {
    if (mode === 'admin' && isAdminOrBlockitAdmin(currentOrganization)) {
      switchMode('admin');
    }
  }, [currentOrganization, mode, switchMode]);

  return (
    <UserMenuStyles>
      {canSwitchOrganizations(viewer) ? (
        <MenuSelect
          id="organization-select"
          key={currentOrganization?.id || ''}
          defaultValue={currentOrganization?.id || ''}
          placeholder="Search for Organization"
          options={sortViewerOrganizations(viewer?.organizations)}
          setCurrent={setCurrentOrganization}
          ariaLabel="Organization Selector"
        />
      ) : (
        <span className="currentOrg">{currentOrganization && currentOrganization.name}</span>
      )}
      {canSwitchLocations(currentOrganization) ? (
        <Select<string>
          showSearch
          value={currentLocation?.id}
          dropdownMatchSelectWidth={false}
          placeholder="Search for Location"
          optionFilterProp="children"
          onSelect={(val: string) =>
            setCurrentLocation(currentOrganization?.locations.filter(l => l.id === val)[0] || null)
          }
          // onSearch={_.debounce(searchLocations, 500)}
          filterOption={(input, option) => {
            if (option.props.children && typeof option.props.children === 'string') {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            return false;
          }}
          aria-label="Location Selector"
        >
          {currentOrganization?.locations.map(l => (
            <Select.Option value={l.id} key={l.id}>
              {`${l.name}`}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <span>{currentLocation && currentLocation.name}</span>
      )}
      {viewer && (
        <div className="nav">
          <div className="flex justify-end space-x-10 items-center text-white text-base whitespace-nowrap">
            {isBlockitAdmin(currentOrganization) && (
              <a href={`${config.endpoint}/admin`} target="_blank" rel="noopener noreferrer">
                Superadmin
              </a>
            )}
            {isAdminOrBlockitAdmin(currentOrganization) && (
              <div className="flex">
                {portals.map(portal => {
                  const classes = `cursor-pointer select-none py-3 pt-4 ${getActiveClasses(mode, portal.id)}`;
                  return (
                    <div
                      className={classes}
                      onClick={() => {
                        switchMode(portal.id);
                        if (portal.id === 'admin') {
                          history.push(`/organizations/${currentOrganization?.id}/profiles?=admin`);
                        } else {
                          history.push(`/`);
                        }
                      }}
                      key={portal.id}
                    >
                      {portal.name} Portal
                    </div>
                  );
                })}
              </div>
            )}
            <NavLink to={`/organizations/${currentOrganization?.id}/settings`}>Settings</NavLink>
            <Link to="/logout">Sign Out</Link>
          </div>
        </div>
      )}
    </UserMenuStyles>
  );
};
