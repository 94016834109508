import { Icon } from 'antd';
import { Form, Formik } from 'formik';
import { gql } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Fieldset, MultiSelect, Spinner } from '../../../components';
import { GetProvider, UpdateProvider as UpdateProviderQuery } from '../../../GraphQL';
import { deletePaginatedCacheItems, removeEmptyObjFields } from '../../../helpers';
import { AppContextConsumer } from '../../core/contexts/AppContext';

const getOrganizationQuery = gql`
  query($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      locations {
        id
        name
        address1
        city
        state
        postalCode
        latitude
        longitude
      }
    }
  }
`;

const ProviderFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  key: Yup.string(),
  npi: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  location: Yup.object()
    .required('Required')
    .nullable()
    .shape({
      label: Yup.string().required('Required'),
      value: Yup.string().required('Required'),
    }),
  isActive: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.boolean().required(),
  }),
});

const EditProviderComponent = ({ GetProviderQuery, updateProviderMutation, history }) => {
  if (GetProviderQuery.loading) {
    return <Spinner />;
  }

  const { GetProvider: provider } = GetProviderQuery;

  return (
    <AppContextConsumer>
      {({ currentOrganization }) => (
        <Query query={getOrganizationQuery} variables={{ organizationId: currentOrganization.id }}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner />;
            if (error) return <div>Error</div>;

            const {
              getOrganization: { locations },
            } = data;

            return (
              <div>
                <Formik
                  initialValues={{
                    id: provider && provider.id ? provider.id : '',
                    name: provider && provider.name ? provider.name : '',
                    key: provider && provider.key ? provider.key : '',
                    npi: provider && provider.npi ? provider.npi : null,
                    isActive:
                      provider && typeof provider.isActive === 'boolean'
                        ? {
                            value: provider.isActive,
                            label: provider.isActive ? 'Yes' : 'No',
                          }
                        : null,
                    location:
                      provider && provider.location
                        ? {
                            value: provider.location.id,
                            label: provider.location.name,
                          }
                        : null,
                  }}
                  validationSchema={ProviderFormSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    try {
                      const valuesCopy = _.cloneDeep(values);
                      const cleanedUpValues = removeEmptyObjFields(valuesCopy);
                      cleanedUpValues.isActive = valuesCopy.isActive.value;

                      // check if location is null before formatting since it isn't non_null
                      if (valuesCopy.location) {
                        cleanedUpValues.locationId = valuesCopy.location.value;
                      }

                      delete cleanedUpValues.location;

                      await updateProviderMutation({
                        variables: {
                          UpdateProviderInput: cleanedUpValues,
                        },
                        // refetch provider if it is an edit
                        refetchQueries:
                          values && values.id
                            ? [{ query: GetProvider, variables: { GetProviderInput: { id: values.id } } }]
                            : [],
                        update: cache => deletePaginatedCacheItems(cache, 'PaginatedProviders'),
                      });

                      history.push(`/organizations/${currentOrganization.id}/providers`);
                    } catch (error) {
                      console.error(error);
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                  }) => (
                    <div className="flex flex-wrap">
                      <div className="w-1/2 card">
                        <div className="card-header bg-grey-darkest text-grey-lightest font-bold">
                          <span className="px-4 py-2 inline-block">Edit Provider</span>
                        </div>
                        <div className="p-4">
                          <Form className="w-full" onSubmit={handleSubmit}>
                            <Fieldset
                              errors={errors}
                              touched={touched}
                              type="text"
                              name="name"
                              label="Name"
                              placeholder="Name"
                              className="w-full input mt-2 border-1"
                            />
                            <Fieldset
                              errors={errors}
                              touched={touched}
                              type="text"
                              name="key"
                              label="Key"
                              placeholder="Key"
                              className="w-full input mt-2 border-1"
                            />
                            <MultiSelect
                              errors={errors}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              touched={touched}
                              options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                              ]}
                              value={values.isActive}
                              name="isActive"
                              label="Active"
                              className="w-full"
                              isMulti={false}
                            />
                            <Fieldset
                              errors={errors}
                              touched={touched}
                              type="text"
                              name="npi"
                              label="Npi Number"
                              placeholder="Npi Number"
                              className="w-full input mt-2 border-1"
                            />
                            <MultiSelect
                              errors={errors}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              touched={touched}
                              options={locations.map(l => ({
                                value: l.id,
                                label: l.name,
                              }))}
                              value={values.location}
                              name="location"
                              label="Location"
                              placeholder="Location"
                              className="w-full"
                              isMulti={false}
                            />
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="w-full flex-1 mt-4 btn btn-blue px-2 py-2"
                            >
                              Save {isSubmitting ? <Icon type="loading" /> : null}
                            </button>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            );
          }}
        </Query>
      )}
    </AppContextConsumer>
  );
};

export default _.flowRight(
  withRouter,
  graphql(UpdateProviderQuery, { name: 'updateProviderMutation' }),
  graphql(GetProvider, {
    name: 'GetProviderQuery',
    options: ownProps => ({
      variables: { GetProviderInput: { id: ownProps.match.params.providerId } },
    }),
  })
)(EditProviderComponent);
