import AddToCalendar from 'react-add-to-calendar';
import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Button, Col, Icon, Row, Typography } from 'antd';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';
import { AppointmentInstructions } from './AppointmentInstructions';
import { defaultTheme } from '../../../themes/default';
import { Engagement } from '../hooks/useCreateConsumerSchedulingEngagementMutation';
import { formatPhone } from '../../../helpers';
import { Location, Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import moment from 'moment';

interface Props {
  engagement: Engagement;
  patientName: string;
  organization: Organization;
  printing?: boolean;
  embedded?: boolean;
}

const { Text } = Typography;

const Styles = styled.div`
  .add-to-calendar {
    color: ${({ theme }) => theme.primaryColor};
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;

    a:hover {
      color: #60b7f4;
    }

    .add-to-calendar-dropdown {
      ul {
        list-style: none;
        margin-bottom: 12px;
        margin-left: 12px;

        li {
          a {
            i {
              padding-right: 12px;
            }
          }
        }
      }
    }
  }

  .engagement-detail {
    color: ${({ theme }) => theme.bodyTextColor};
    font-family: Source Sans Pro, sans-serif;
    line-height: 26px;
    font-size: 18px;
    text-decoration: none solid;
    display: block;
  }

  .engagement-details-header {
    color: ${({ theme }) => theme.bodyTextColor};
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none solid;
    text-transform: uppercase;
  }

  .engagement-details-header-col {
    margin-bottom: 16px;
  }

  .print-details {
    border: none;
    color: ${({ theme }) => theme.primaryColor};
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    height: auto;
    padding: 0;
  }
  .appointment-details-header-col {
    margin-bottom: 16px;
  }

  .address-section,
  .appointment-phone-number {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .appointment-location-name {
    font-weight: 800;
  }
`;

const formatAddressString = (location: Location): string => {
  let string = location.address1;

  if (location.address2) {
    string += `, ${location.address2}`;
  }

  string += `, ${location.city}, ${location.state} ${location.postalCode}`;

  return string;
};

export const EngagementDetails: FC<Props> = ({
  engagement,
  patientName,
  organization,
  printing = false,
  embedded = false,
}): JSX.Element => {
  const history = useHistory();

  const { location } = engagement.profile;
  const calendarEvent = {
    title: `Appointment with ${engagement.profile.displayName} - ${engagement.profile.organization.name}`,
    description: '',
    location: formatAddressString(location),
    startTime: moment(engagement.start)
      .utc()
      .toISOString(),
    endTime: moment(engagement.end)
      .utc()
      .toISOString(),
  };

  const start = DateTime.fromISO(engagement.start);

  const handlePrintDetails = (): void => {
    history.push(`/consumer/${organization.slug ? organization.slug : organization.id}/print-success`, {
      engagement,
      patientName,
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Row>
          <Col className="engagement-details-header-col" span={24}>
            <Text className="engagement-details-header">Appointment Details</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="engagement-detail appointment-time">Patient: {patientName}</Text>
            <Text className="engagement-detail appointment-time">
              {`${start.toLocaleString(DateTime.DATE_HUGE)}`} at {start.toLocaleString(DateTime.TIME_SIMPLE)}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {engagement.procedure?.specialty && (
              <Text className="engagement-detail">
                {`${engagement.procedure.specialty.name}`} - {`${engagement.procedure.name}`}
              </Text>
            )}
            <Text className="engagement-detail appointment-profile-name">{`${engagement.profile.displayName}`}</Text>
            <Text className="engagement-detail appointment-phone-number">{`${formatPhone(
              engagement.profile.phone
            )}`}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="address-section">
              <Text className="engagement-detail appointment-location-name">{`${engagement.profile.location.name}`}</Text>
              <Text className="engagement-detail">{engagement.profile.location.address1}</Text>
              <Text className="engagement-detail">{engagement.profile.location.address2}</Text>
              <Text className="engagement-detail">
                {engagement.profile.location.city}, {engagement.profile.location.state}{' '}
                {engagement.profile.location.postalCode}
              </Text>
            </div>
          </Col>
        </Row>
        {engagement.profile?.generalPatientInstructionsSafe && (
          <>
            <Row>
              <Col span={24}>
                <p
                  className="engagement-detail"
                  dangerouslySetInnerHTML={{ __html: engagement.profile.generalPatientInstructionsSafe }}
                />
                <br />
                <br />
              </Col>
            </Row>
          </>
        )}
        {!embedded && !printing ? (
          <Row>
            <Col xs={12} sm={6} md={5} lg={4}>
              <Button className="print-details" onClick={handlePrintDetails} type="link">
                <Icon type="printer" />
                Print Details
              </Button>
            </Col>
            <Col xs={12} sm={18} md={19} lg={20}>
              <AddToCalendar
                event={calendarEvent}
                buttonClassClosed="add-to-calendar"
                buttonClassOpen="add-to-calendar"
                buttonLabel="Add to Calendar"
                buttonTemplate={{ 'calendar-plus-o': 'left' }}
                buttonWrapperClass="add-to-calendar"
                dropdownClass="add-to-calendar-dropdown"
                listItems={[
                  { google: 'Google' },
                  { apple: 'Apple Calendar' },
                  { outlookcom: 'Outlook.com' },
                  { outlook: 'Outlook' },
                  { yahoo: 'Yahoo' },
                ]}
                rootClass="add-to-calendar"
              />
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col span={24}>
            <AppointmentInstructions engagementId={engagement.id} />
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
