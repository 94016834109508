import React, { ChangeEvent, FC, KeyboardEvent, MouseEvent } from 'react';
import { Search } from '../../../system/components/Input';

interface Props {
  defaultValue: string | undefined;
  onSearch: (value: string, event?: ChangeEvent | MouseEvent | KeyboardEvent) => void;
}

export const SearchProviderName: FC<Props> = ({ defaultValue, onSearch }): JSX.Element => (
  <Search allowClear defaultValue={defaultValue} onSearch={onSearch} placeholder="Search for provider" />
);
