import React, { FC } from 'react';
import { DatePicker } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { ValidateQuestionnaire, Question } from './QuestionnaireForm';

interface Props extends FormComponentProps {
  question: Question;
  validator: ValidateQuestionnaire;
}

export const QuestionDate: FC<Props> = ({ form, question, validator }): JSX.Element => (
  <Form.Item label={question.text} key={question.id} extra={question.helpText}>
    {form.getFieldDecorator(`questionnaire[0].${question.text.replace(/\./g, ' ')}-date`, {
      rules: [
        {
          required: question.required,
          message: 'required',
        },

        { validator: validator },
      ],
      initialValue: question.initialValue,
    })(<DatePicker format="MM-DD-YYYY" />)}
  </Form.Item>
);
