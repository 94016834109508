import { useMutation, MutationTuple } from '@apollo/client';
import { gql } from '@apollo/client';

interface CreateAvailabilityData {
  CreateAvailability: {
    id: string;
  };
}

interface CreateAvailabilityVariables {
  CreateAvailabilityInput: {
    scheduleId: string;
    weekDay: string;
    start: string;
    end: string;
  };
}

const CreateAvailabilityMutation = gql`
  mutation CreateAvailability($CreateAvailabilityInput: CreateAvailabilityInput!) {
    CreateAvailability(CreateAvailabilityInput: $CreateAvailabilityInput) {
      id
    }
  }
`;

type useCreateAvailabilityMutationResult = MutationTuple<CreateAvailabilityData, CreateAvailabilityVariables>;

export const useCreateAvailabilityMutation = (): useCreateAvailabilityMutationResult => {
  return useMutation<CreateAvailabilityData, CreateAvailabilityVariables>(CreateAvailabilityMutation);
};
