import { Form, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import React, { FC } from 'react';
import { Question, QuestionnaireFormComponent } from '../../../../components/questionnaire/QuestionnaireForm';
import { Questionnaire, QuestionnaireAnswerSet, QuestionnaireAnswer } from '../../../../generated/graphql';

interface Props extends FormComponentProps {
  questionnaire: Questionnaire | undefined;
  visible: boolean;
  onCancel: () => void;
  onOk: (arg0: string, arg1: QuestionnaireAnswerSet[] | []) => void;
}

const QuestionnaireModal: FC<Props> = ({ questionnaire, visible, form, onCancel, onOk }): JSX.Element | null => {
  if (!questionnaire || !questionnaire.items) return null;

  const formatAnswers = (values: {
    questionnaire: Array<string | boolean>;
  }): [string, QuestionnaireAnswerSet[] | []] => {
    const answers: string[] = [];
    const questionnaireItems = questionnaire.items;
    const questionnaireAnswers: QuestionnaireAnswer[] = [];

    for (const [questionKey, answer] of Object.entries(values.questionnaire[0])) {
      let formattedQuestionKey = questionKey;
      let formattedAnswer = '';

      if (answer === true) formattedAnswer = 'Yes';
      else if (answer === false) formattedAnswer = 'No';
      else if (moment.isMoment(answer)) {
        if (questionKey.endsWith('-date')) {
          formattedQuestionKey = questionKey.replace('-date', '');
          formattedAnswer = answer.format('MMM D, YYYY');
        } else if (questionKey.endsWith('-time')) {
          formattedQuestionKey = questionKey.replace('-time', '');
          formattedAnswer = answer.format('hh:mm A');
        }
      } else {
        formattedAnswer = (answer as any)?.toString() || '';
      }

      answers.push(`${formattedQuestionKey}: ${formattedAnswer}`);

      let answerKey = '';

      if (questionnaireItems) {
        const foundItem = questionnaireItems.find(item => item && item.text === formattedQuestionKey);
        if (foundItem && foundItem.key) {
          answerKey = foundItem.key;
        }
      }

      questionnaireAnswers.push({
        text: formattedQuestionKey,
        value: formattedAnswer,
        key: answerKey,
      });
    }

    const notes = '\n\nQuestionnaire Answers:\n' + answers.join('\n');

    const answerSets: QuestionnaireAnswerSet[] = [
      {
        questionnaireName: questionnaire.title || questionnaire.name || 'Questionnaire',
        questionnaireAnswers: questionnaireAnswers,
        questionnaireId: questionnaire.id,
      },
    ];

    return [notes, answerSets];
  };

  const handleOk = (): void => {
    form.validateFields((err, values) => {
      if (!err) {
        const [notes, answerSets] = formatAnswers(values);
        onOk(notes, answerSets);
      }
    });
  };

  return (
    <Modal
      title="Workflow Questionnaire"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      style={{ width: 'auto', inlineSize: 'min-content' }}
    >
      <QuestionnaireFormComponent questions={questionnaire.items as Question[]} span={24} form={form} />
    </Modal>
  );
};

export const QuestionnaireModalForm = Form.create<Props>({ name: 'Questionnaire' })(QuestionnaireModal);
