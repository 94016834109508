import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const createBillingPortalSessionQuery = gql`
  query CreateBillingPortalSession($organizationId: ID!) {
    createBillingPortalSession(organizationId: $organizationId) {
      id
      url
    }
  }
`;

interface BillingPortalSession {
  id: string;
  url: string;
}

interface Data {
  createBillingPortalSession?: BillingPortalSession;
}

type Variables = {
  organizationId: string;
};

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useCreateBillingPortalSessionQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(createBillingPortalSessionQuery, options);
};
