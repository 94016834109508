import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { Location, Organization } from './useGetConsumerSchedulingOrganizationQuery';
import { Specialty } from './useGetProcedureQuery';

const getConsumerSchedulingProfileQuery = gql`
  query GetConsumerSchedulingProfileQuery($id: ID!, $latitude: Float, $longitude: Float) {
    getConsumerSchedulingProfile(id: $id, latitude: $latitude, longitude: $longitude) {
      id
      displayName
      prefix
      firstName
      LastName
      suffix
      phone
      email
      type
      profileImgUrl
      professionalStatementSafe
      boardCertificationsSafe
      educationTrainingSafe
      npiNumber
      specialty
      nextAvailability
      languages {
        code
        name
      }
      organization {
        id
        name
      }
      location {
        id
        name
        address1
        address2
        city
        state
        postalCode
        country
        distanceFromPatient
        distanceFromProvider
        latitude
        longitude
        phone
        timeZone
      }
      isIntegrated
      isSendToProvider
      isWaitList
      specialties {
        id
        name
        procedures {
          id
          name
        }
      }
    }
  }
`;

export interface Profile {
  id: string;
  displayName: string;
  prefix: string;
  firstName: string;
  lastName: string;
  suffix: string;
  phone: string;
  email: string;
  specialty?: string;
  type: string;
  profileImgUrl: string;
  professionalStatementSafe: string;
  boardCertificationsSafe: string;
  educationTrainingSafe: string;
  npiNumber: string;
  languages: Language[];
  organization: Organization;
  location: Location;
  generalPatientInstructionsSafe?: string;
  prescreeningQuestionnaire?: {
    items: {
      id: string;
      text: string;
      type: string;
      answerMaxLength?: number | null;
      required: boolean;
      initialValue: string | null;
      uploadAcceptTypes: string[] | null;
      helpText: string | null;
      options: string[] | null;
    }[];
  };
  isIntegrated: boolean;
  isSendToProvider: boolean;
  isAccessCenter: boolean;
  specialties: Specialty[];
  nextAvailability: string | null;
  isWaitList: boolean;
}

interface Language {
  code: string;
  name: string;
}

interface Data {
  getConsumerSchedulingProfile: Profile;
}

interface Variables {
  id: string;
  latitude?: number;
  longitude?: number;
}

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetConsumerSchedulingProfileQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getConsumerSchedulingProfileQuery, options);
};
