import React, { FC, FocusEvent, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InputUserPassword } from './InputUserPassword';
import { InputUserPasswordConfirmation } from './InputUserPasswordConfirmation';

interface Props {
  form: WrappedFormUtils;
}

export const InputUserPasswords: FC<Props> = ({ form }): JSX.Element => {
  const [reValidatePasswords, setReValidatePasswords] = useState<boolean>(false);

  const handlePasswordConfirmationBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setReValidatePasswords((previousValue: boolean): boolean => {
      return previousValue || !!value;
    });
  };

  const passwordsMustMatchValidator = (rule: string, value: string, callback: (arg0?: string) => void): void => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords must match.');
    } else {
      callback();
    }
  };

  const reValidatePasswordsValidator = (rule: string, value: string, callback: (arg0?: string) => void): void => {
    if (value && reValidatePasswords) {
      form.validateFields(['passwordConfirmation'], { force: true });
    }
    callback();
  };

  return (
    <>
      <InputUserPassword form={form} validator={reValidatePasswordsValidator} />
      <InputUserPasswordConfirmation
        form={form}
        onBlur={handlePasswordConfirmationBlur}
        validator={passwordsMustMatchValidator}
      />
    </>
  );
};
