import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { ListAvailabilities as ListAvailabilitiesQuery } from '../../../../GraphQL';
import { TableOverlay } from '../../styles/calendar.styled';
import { DeleteAvailabilityModal } from './index';

class AvailabilitiesCalendarComponent extends Component {
  constructor(props) {
    super(props);

    this.setAvailCount = props.setAvailCount;
    this.rowHeightInPixels = 80;
  }

  scrollToFirstTimeSlot = availabilities => {
    // run this method to execute scrolling.
    const earliestAvailability = availabilities.map(a => a.start.split(':')[0]).sort()[0];
    const scrollOffset = earliestAvailability * this.rowHeightInPixels;

    if (this.props.calendarRef.current) {
      this.props.calendarRef.current.scrollTo({
        top: scrollOffset,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const { ListAvailabilities } = this.props;

    if (ListAvailabilities.loading) {
      return null;
    }

    const { ListAvailabilities: availabilities } = ListAvailabilities;

    if (availabilities && availabilities.length > 0) {
      this.scrollToFirstTimeSlot(availabilities);
    } else {
      this.scrollToFirstTimeSlot([{ start: '09:00' }]);
    }

    // Guard against no availabilities
    if (availabilities) {
      this.setAvailCount(availabilities.length);
    }

    return (
      <React.Fragment>
        {availabilities && availabilities.length > 0
          ? availabilities.map(avail => (
              <TableOverlay
                key={avail.start + avail.weekDay}
                start={avail.start}
                end={avail.end}
                weekDay={avail.weekDay}
              >
                {`${DateTime.fromISO(avail.start).toLocaleString(DateTime.TIME_SIMPLE)} - ${DateTime.fromISO(
                  avail.end
                ).toLocaleString(DateTime.TIME_SIMPLE)}`}
                <DeleteAvailabilityModal availability={avail} scheduleId={this.props.scheduleId} />
              </TableOverlay>
            ))
          : null}
      </React.Fragment>
    );
  }
}

export const AvailabilitiesCalendar = _.flowRight(
  graphql(ListAvailabilitiesQuery, {
    name: 'ListAvailabilities',
    options: ownProps => ({
      variables: { ListAvailabilitiesInput: { scheduleId: ownProps.scheduleId } },
    }),
  })
)(AvailabilitiesCalendarComponent);
