import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { generateUtmParametersQueryString } from '../helpers/utm';
import { LandingForm, LandingFormData } from './LandingForm';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { StepContainer } from './StepContainer';
import { StepTitle } from './StepTitle';

interface Props {
  organization: Organization;
  params: Params;
}

interface Params {
  embedded?: string;
  order?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_cmpid?: string;
  utm_term?: string;
  utm_content?: string;
  procedureId?: string;
  specialtyId?: string;
}

export const LandingStep: FC<Props> = ({ organization, params }): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);
  const { singleSpecialtyId, procedureId } = useContext(ConsumerSchedulingContext);

  const history = useHistory();
  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);

  const handleLandingFormSubmit = (values: LandingFormData): void => {
    const specialtyIdQueryString = singleSpecialtyId
      ? `singleSpecialtyId=${singleSpecialtyId}`
      : `specialtyId=${values.specialtyId}`;

    let queryString = `?${specialtyIdQueryString}&procedureId=${procedureId || values.procedureId}`;
    if (values.payorId) queryString += `&payorId=${values.payorId}`;
    if (values.payorPlanId) queryString += `&payorPlanId=${values.payorPlanId}`;
    if (latitude && longitude) queryString += `&latitude=${latitude}&longitude=${longitude}`;
    if (values.location) queryString += `&location=${encodeURIComponent(values.location)}`;
    if (params.order) queryString += `&order=${params.order}`;
    if (params.embedded === 'true') queryString += '&embedded=true';
    queryString += generateUtmParametersQueryString(params, queryString);

    history.push(`/consumer/${organization.slug ? organization.slug : organization.id}/search${queryString}`);
  };

  return (
    <StepContainer maxWidth="1400px">
      <Row gutter={[0, 36]}>
        <Col span={24}>
          <StepTitle title="Schedule an Appointment with a Provider Near You" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <LandingForm
            onSubmit={handleLandingFormSubmit}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            embedded={params.embedded === 'true'}
            initialProcedureId={params.procedureId}
            initialSpecialtyId={params.specialtyId}
          />
        </Col>
      </Row>
    </StepContainer>
  );
};
