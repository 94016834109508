import React, { FC } from 'react';
import { Plan } from '../types';
import { BlockRestrictedUsers } from '../../../helpers';

const sortPlans = (a: Plan, b: Plan): number => {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();

  if (aLower < bLower) {
    return -1;
  } else if (aLower > bLower) {
    return 1;
  }
  return 0;
};

interface Props {
  plans: Plan[];
  handlePlanRemove: (arg0: Plan) => void;
}

export const SelectedPlanList: FC<Props> = ({ plans, handlePlanRemove }) => {
  const planListItems = plans.sort(sortPlans).map(item => (
    <BlockRestrictedUsers
      fallbackContent={
        <li className="px-6 py-2 bg-grey-lightest text-grey-dark no-select" key={item.name}>
          {item.name}
        </li>
      }
      key={item.name}
    >
      <li
        className="px-6 py-2 bg-grey-lightest text-grey-dark hover:bg-grey-lightest hover:text-black no-select cursor-pointer"
        key={item.name}
        onClick={() => handlePlanRemove(item)}
      >
        {item.name} <i>(Click to remove)</i>
      </li>
    </BlockRestrictedUsers>
  ));

  return (
    <ul className="list-reset" key={Math.random()}>
      {planListItems}
    </ul>
  );
};
