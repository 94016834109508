import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { FeatureFlagCard } from '../components/FeatureFlagCard';
import { useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';

interface Props {
  organizationId: string;
}

export const SuperuserOrgSettingsContainer: FC<Props> = ({ organizationId }): JSX.Element | null => {
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const organization = data?.getOrganization;

  return (
    <Row>
      <Col span={18} push={1}>
        <FeatureFlagCard organizationId={organizationId} enabledFlags={organization?.featureFlags} />
      </Col>
    </Row>
  );
};
