import { Avatar, Col, Row } from 'antd';
import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../../../themes/default';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';

interface Props {
  profile: Profile;
}

const Styles = styled.div`
  .profile-image {
    border-radius: 0;
    height: auto;
    width: auto;

    &.profile-image-xs {
      max-height: 76px;
      max-width: 68px;
    }

    &.profile-image-lg {
      max-height: 152px;
      max-width: 136px;
    }
  }
`;

export const ProfileImage: FC<Props> = ({ profile }): JSX.Element => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Row>
          <Col xs={24} lg={0}>
            {profile.profileImgUrl ? (
              <div>
                <img src={profile.profileImgUrl} alt={profile.displayName} className="profile-image profile-image-xs" />
              </div>
            ) : (
              <Avatar shape="square" size={68} icon="user" />
            )}
          </Col>
          <Col xs={0} lg={24}>
            {profile.profileImgUrl ? (
              <div>
                <img src={profile.profileImgUrl} alt={profile.displayName} className="profile-image profile-image-lg" />
              </div>
            ) : (
              <Avatar shape="square" size={136} icon="user" className="profile-image-lg-icon" />
            )}
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
