import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { LoginToken } from '../loginToken';

interface Props {
  handleAuthChange: (arg0: LoginToken) => void;
  location: {
    search: string;
  };
}

const SSO: FC<Props> = ({ location, handleAuthChange }) => {
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  handleAuthChange(token);
  return <Redirect to="/" />;
};

export default SSO;
