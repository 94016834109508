import React from 'react';
import { Route } from 'react-router-dom';
import { OnboardingPage } from './pages/OnboardingPage';
import { PelitasOnboardingPage } from './pages/PelitasOnboardingPage';
import { PelitasSignupPage } from './pages/PelitasSignupPage';
import { SignupPage } from './pages/SignupPage';

export const routes = [
  <Route exact key="route--covid" path="/covid-signup" component={SignupPage} />,
  <Route exact key="route--covid-pelitas" path="/covid-pelitas" component={PelitasSignupPage} />,
  <Route
    exact
    key="route--covid-onboarding"
    path="/organizations/:organizationId/onboarding"
    component={OnboardingPage}
  />,
  <Route
    exact
    key="route--covid-onboarding-pelitas"
    path="/organizations/:organizationId/onboarding-pelitas"
    component={PelitasOnboardingPage}
  />,
];
