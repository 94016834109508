import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  autofocus?: boolean;
  form: WrappedFormUtils;
}

export const InputOrganizationName: FC<Props> = ({ autofocus, form }): JSX.Element => (
  <Form.Item label="Organization Name:">
    {form.getFieldDecorator('organizationName', {
      rules: [
        { message: `Please enter your organization's name.`, required: true },
        { message: 'Organization name must be at least 3 characters.', min: 3 },
      ],
    })(<Input autoFocus={!!autofocus} allowClear placeholder="Enter organization name." />)}
  </Form.Item>
);
