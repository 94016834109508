import * as Sentry from '@sentry/browser';
import React, { FC, useState, useContext } from 'react';
import { AppContext } from '../../../core/contexts/AppContext';
import { AppointmentData, ReferralData, QUERY } from './GetReferralQuery';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import { deletePaginatedCacheItems } from '../../../../helpers/cache';
import { InMemoryCache, useMutation, gql } from '@apollo/client';
const { Option } = Select;

interface CancelApptMutationVars {
  UpdateAppointmentInput: {
    id: string;
    status: string;
    tags: [TagInput];
  };
}

interface CanelApptMutationData {
  UpdateAppointment: {
    id: string;
    status: string;
    tags: [TagInput];
  };
}
interface TagInput {
  key: string;
  value: string;
}

export const CancelAppointment: FC<{ appointment?: AppointmentData; referral?: ReferralData }> = ({
  appointment,
  referral,
}): JSX.Element | null => {
  const { currentOrganization } = useContext(AppContext);
  const reasons = currentOrganization?.cancelReasons;

  let defaultReason = 'Rescheduled';
  if (reasons && reasons.length) {
    if (reasons[0].value) {
      defaultReason = reasons[0].value;
    } else {
      defaultReason = reasons[0].key;
    }
  }

  const [visible, setVisible] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(defaultReason);
  const [reasonText, setReasonText] = useState<string>('');

  const UpdateAppointment = gql`
    mutation UpdateAppointment($UpdateAppointmentInput: UpdateAppointmentInput!) {
      UpdateAppointment(UpdateAppointmentInput: $UpdateAppointmentInput) {
        id
        status
        tags {
          key
          value
        }
      }
    }
  `;

  const [cancelAppointmentMutation, { error }] = useMutation<CanelApptMutationData, CancelApptMutationVars>(
    UpdateAppointment
  );

  if (error) {
    Sentry.captureException(error);
    message.error('Error cancelling appointment');
  }

  if (!appointment) {
    return null;
  }

  const onOk = (): void => {
    setVisible(false);

    let reasonValue = reason;
    if (reason === 'Other' && reasonText !== '') {
      reasonValue = reasonText;
    }

    let config = {};
    if (referral) {
      config = {
        variables: {
          UpdateAppointmentInput: {
            id: appointment.id,
            status: 'cancelled',
            tags: [
              {
                key: 'cancel_reason',
                value: reasonValue,
              },
            ],
          },
        },
        refetchQueries: [{ query: QUERY, variables: { id: referral?.id } }],
        update: (cache: InMemoryCache) => {
          message.success('Appointment has been cancelled');
          deletePaginatedCacheItems(cache, 'ListReferrals');
          deletePaginatedCacheItems(cache, 'ListAppointments');
        },
      };
    } else {
      config = {
        variables: {
          UpdateAppointmentInput: {
            id: appointment.id,
            status: 'cancelled',
            tags: [
              {
                key: 'cancel_reason',
                value: reasonValue,
              },
            ],
          },
        },
        update: (cache: InMemoryCache) => {
          message.success('Appointment has been cancelled');
          deletePaginatedCacheItems(cache, 'ListReferrals');
          deletePaginatedCacheItems(cache, 'ListAppointments');
        },
      };
    }

    cancelAppointmentMutation(config);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>Cancel Appointment</Button>
      <Modal title="Cancel Appointment" visible={visible} onOk={() => onOk()} onCancel={() => setVisible(false)}>
        <Form>
          <Form.Item label="Reason for cancellation">
            {reasons && reasons.length ? (
              <Select<string> defaultValue={reasons[0].key} size="small" style={{}} onChange={val => setReason(val)}>
                {reasons.map(r => (
                  <Option key={r.key} value={r.value || r.key}>
                    {r.key}
                  </Option>
                ))}
              </Select>
            ) : (
              <Select<string>
                size="small"
                defaultValue={'Rescheduled'}
                placeholder="Choose reason"
                style={{}}
                onChange={val => setReason(val)}
              >
                <Option value="Rescheduled" key="Rescheduled">
                  Rescheduled
                </Option>
                <Option value="Patient Cancelled" key="Patient Cancelled">
                  Patient Cancelled
                </Option>
                <Option value="Provider Cancelled" key="Provider Cancelled">
                  Provider Cancelled
                </Option>
                <Option value="Entered in Error" key="Entered in Error">
                  Entered in Error
                </Option>
                <Option value="Other" key="Other">
                  Other
                </Option>
              </Select>
            )}
            {reason === 'Other' && (
              <Input
                maxLength={128}
                onChange={e => setReasonText(e.target.value)}
                placeholder="Additional detail"
                size="small"
              ></Input>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
