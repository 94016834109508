import React, { FC, FormEvent } from 'react';
import { Form, Checkbox, Row, Col, Typography, Card, Button } from 'antd';
import { FeatureFlag } from '../index';
import { FeatureFlagHints } from '../../../helpers';
import { FormComponentProps } from 'antd/lib/form';
import { useGetFeatureFlagsQuery } from '../../../hooks/queries/useGetFeatureFlagsQuery';

interface Props extends FormComponentProps {
  enabledFlags?: FeatureFlag[];
  onSubmit: (arg0: string[]) => void;
}

const FeatureFlagFormComponent: FC<Props> = ({ form, enabledFlags, onSubmit }): JSX.Element => {
  const { data } = useGetFeatureFlagsQuery();
  const featureFlags = data?.getFeatureFlags;

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields((err, values: { featureFlags: string[] }) => {
      onSubmit(values.featureFlags);
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card
        title="Feature Flags"
        extra={
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        }
      >
        <Form.Item label="">
          {form.getFieldDecorator('featureFlags', {
            initialValue: enabledFlags?.map(flag => flag.id),
          })(
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                {featureFlags?.map(flag => (
                  <Col span={24} key={flag.id} style={{ paddingBottom: 4 }}>
                    <Checkbox value={flag.id} style={{ width: '100%' }}>
                      {FeatureFlagHints[flag.name] ? (
                        <>
                          {FeatureFlagHints[flag.name].name}
                          <Typography.Text code style={{ float: 'right' }}>
                            {flag.name}
                          </Typography.Text>
                        </>
                      ) : (
                        <>{flag.name}</>
                      )}
                    </Checkbox>
                    <p style={{ fontSize: 12, paddingLeft: 24, color: '#888' }}>{FeatureFlagHints[flag.name]?.text}</p>
                    <p style={{ fontSize: 12, paddingLeft: 24, color: '#d97706' }}>
                      {FeatureFlagHints[flag.name]?.warning}
                    </p>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          )}
        </Form.Item>
      </Card>
    </Form>
  );
};

export const FeatureFlagForm = Form.create<Props>({ name: 'Feature Flags' })(FeatureFlagFormComponent);
