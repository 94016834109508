import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string | null;
}

const validateMultipleEmails = async (ignore: string, value: string) => {
  if (!value) {
    return Promise.resolve();
  }

  const emails = value.split(' ').map(email => email.trim());
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const allValid = emails.every(email => emailRegex.test(email));

  if (allValid) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error('Please enter a valid email address'));
  }
};

export const InputEmail: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Email Address:" extra="This email address will receive appointment notifications.">
    {form.getFieldDecorator('email', {
      initialValue,
      rules: [
        {
          required: false,
          message: 'Please enter a valid email address',
          validator: validateMultipleEmails,
        },
      ],
      validateTrigger: 'onBlur',
    })(<Input allowClear placeholder="doc@example.com" />)}
  </Form.Item>
);
