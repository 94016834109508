import React, { useContext } from 'react';
import moment from 'moment';
import * as _ from 'lodash';

import styled from 'styled-components';
import { Row, Col } from 'antd';
import SlotsForColumn from '../components/SlotsForColumn';
import { SearchWizardContainerContext } from '../../../pages/SearchWizardContainer';

const DATE_FORMAT = 'MM/DD/YYYY';

function computeDaysFromSlots(slots) {
  const sortedSlots = slots.slice().sort((a, b) => {
    if (a.start < b.start) {
      return -1;
    }

    if (a.start > b.start) {
      return 1;
    }

    return 0;
  });

  return sortedSlots.reduce((acc, slot) => {
    const date = moment(slot.start).format(DATE_FORMAT);
    const ids = `__ids__${date}`;

    if (!(date in acc)) {
      acc[date] = [];
      acc[ids] = [];
    }

    if (acc[ids].indexOf(slot.id) === -1) {
      acc[date].push(slot);
      acc[ids].push(slot.id);
    }

    return acc;
  }, {});
}

const Styles = styled.div`
  .row-wrapper.ant-row-flex {
    padding-top: 0;
  }

  .time-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;

    &.dash-btn {
      border: 0;
      font-weight: bold;
      box-shadow: none;
    }
  }
`;

const slotsBetweenDates = (dates, slots) => {
  const startDate = _.cloneDeep(dates[0]);
  const endDate = _.cloneDeep(dates[dates.length - 1]);

  return slots.filter(s => moment(s.start).isBetween(startDate.startOf('day'), endDate.endOf('day')));
};

const SlotTimes = ({ slots, profile, arrowsColSpan, slotsColSpan, dates, handleSelectTimeSlot }) => {
  const { updateProfileSlotCount, isSortingByAvailability } = useContext(SearchWizardContainerContext);
  const formattedDates = dates.map(d => d.format(DATE_FORMAT));
  const days = slots ? computeDaysFromSlots(slots) : [];

  const filteredSlots = slotsBetweenDates(dates, slots);
  if (isSortingByAvailability) updateProfileSlotCount(profile.id, filteredSlots.length);

  return (
    <Styles>
      <Row gutter={16} type="flex" justify="center" align="top" className="row-wrapper">
        <Col span={arrowsColSpan} className="left-arrow" />
        {formattedDates.map(date => {
          const times = days[date] || [];

          return (
            <Col key={date} span={slotsColSpan} className="date-wrapper">
              <SlotsForColumn times={times} handleSelectTimeSlot={handleSelectTimeSlot} profile={profile} />
            </Col>
          );
        })}
        <Col span={arrowsColSpan} className="right-arrow" />
      </Row>
    </Styles>
  );
};

export default SlotTimes;
