import { Form, Modal, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ModalProps } from 'antd/lib/modal';
import { gql } from '@apollo/client';
import React, { Component, FC, useState } from 'react';
import { Mutation } from '@apollo/client/react/components';

interface CreateGroupOrganizationFormProps extends FormComponentProps {
  createGroupOrganization: (e: React.MouseEvent<HTMLElement>) => void;
  organizations: Organization[];
}

interface CreateGroupOrganizationFormRef {
  props: FormComponentProps<CreateGroupOrganizationFormValues>;
}

interface CreateGroupOrganizationFormValues {
  organizationId: string;
}

interface CreateGroupOrganizationModalProps extends ModalProps {
  groupId: string;
  organizations: Organization[];
}

interface Data {
  createGroupOrganization: {
    id: string;
    name: string;
    organizations: {
      id: string;
      name: string;
    }[];
  };
}

interface Organization {
  id: string;
  name: string;
}

interface Variables {
  createGroupOrganizationInput: {
    groupId: string;
    organizationId: string;
  };
}

const createGroupOrganizationMutation = gql`
  mutation CreateGroupOrganization($createGroupOrganizationInput: CreateGroupOrganizationInput!) {
    createGroupOrganization(createGroupOrganizationInput: $createGroupOrganizationInput) {
      id
      name
      organizations {
        id
        name
      }
    }
  }
`;

class CreateGroupOrganizationFormComponent extends Component<CreateGroupOrganizationFormProps & ModalProps> {
  render(): JSX.Element {
    const { form, createGroupOrganization, organizations } = this.props;

    if (!form || typeof form.getFieldDecorator !== 'function') {
      return <></>;
    }

    const { getFieldDecorator } = form;

    return (
      <Modal
        {...this.props}
        onOk={createGroupOrganization}
        okText="Add Organization"
        title="Search Organizations"
        destroyOnClose={true}
      >
        <Form layout="vertical">
          <Form.Item label="What organization would you like to add?">
            {getFieldDecorator('organizationId', {
              rules: [
                {
                  required: true,
                  message: 'Required',
                },
              ],
            })(
              <Select<string>
                showSearch
                placeholder="Select Organization"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option.props.children && typeof option.props.children === 'string') {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  return false;
                }}
                style={{ width: '100%' }}
              >
                {organizations.map(o => (
                  <Select.Option value={o.id} key={o.id}>
                    {o.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const CreateGroupOrganizationForm = Form.create<CreateGroupOrganizationFormProps>({
  name: 'create-group-organization-form',
})(CreateGroupOrganizationFormComponent);

export const CreateGroupOrganizationModal: FC<CreateGroupOrganizationModalProps> = (props): JSX.Element => {
  const [formRef, setFormRef] = useState(null);
  const { groupId, onOk } = props;

  return (
    <Mutation<Data, Variables> mutation={createGroupOrganizationMutation}>
      {createGroupOrganizationMutation => {
        const handleCreate = (
          e: React.MouseEvent<HTMLElement>,
          formRef: CreateGroupOrganizationFormRef | null
        ): void => {
          if (!formRef) return;

          const form = formRef.props.form;

          form.validateFields((err, values) => {
            if (err || !onOk) return;

            createGroupOrganizationMutation({
              variables: { createGroupOrganizationInput: { groupId, organizationId: values.organizationId } },
            });

            form.resetFields();
            onOk(e);
          });
        };

        return (
          <CreateGroupOrganizationForm
            {...props}
            wrappedComponentRef={setFormRef}
            createGroupOrganization={(e: React.MouseEvent<HTMLElement>) => handleCreate(e, formRef)}
          />
        );
      }}
    </Mutation>
  );
};
