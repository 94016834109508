import React, { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import { Engagement } from '../hooks/useCreateConsumerSchedulingEngagementMutation';
import { EngagementDetails } from './EngagementDetails';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { Spinner } from '../../../components';
import { StepContainer } from './StepContainer';
import { StepTitle } from './StepTitle';
import { useGetAppointmentUrlQuery } from '../../appointments/hooks/useGetAppointmentUrlQuery';

interface Props {
  engagement: Engagement;
  patientName: string;
  organization: Organization;
  embedded: boolean;
}

export const SuccessStepScheduled: FC<Props> = ({
  engagement,
  patientName,
  organization,
  embedded,
}): JSX.Element | null => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);

  const { data, error, loading } = useGetAppointmentUrlQuery({ variables: { id: engagement.id } });

  if (loading) return <Spinner />;
  if (error || !data) return <div>Error</div>;

  const { getAppointmentUrl: appointmentUrl } = data;
  const { url } = appointmentUrl;

  if (engagement.profile.isWaitList) {
    window.location.replace(url);

    return null;
  } else {
    return (
      <StepContainer maxWidth="924px">
        <Row gutter={[0, 36]}>
          <Col span={24}>
            <StepTitle
              title={`Appointment Successfully ${engagement.status === 'pending' ? 'Requested' : 'Scheduled'}`}
            />
          </Col>
        </Row>
        <Row gutter={[0, 36]}>
          <Col span={24}>
            <EngagementDetails
              patientName={patientName}
              engagement={engagement}
              organization={organization}
              embedded={embedded}
            />
          </Col>
        </Row>
      </StepContainer>
    );
  }
};
