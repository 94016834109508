import { DefaultTheme } from 'styled-components';

const bodyTextColor = '#666';

const accentColor = '#5661b3';
const dangerColor = '#de2d1d';
const disabledColor = '#bbb';
const inactiveColor = '#abb1b8';
const successColor = '#34b364';
const primaryColor = '#2783c4';
const warningColor = '#f6b926';
const blueDarkest = '#12283a';

const black = '#22292f';
const greyDarkest = '#3d4852';
const greyDarker = '#606f7b';
const greyDark = '#8795a1';
const grey = '#b8c2cc';
const greyLight = '#dae1e7';
const greyLighter = '#f1f5f8';
const greyLightest = '#f8fafc';
const white = '#ffffff';

const chartColors = [
  accentColor,
  '#d13b65',
  successColor,
  '#f06d3a',
  warningColor,
  primaryColor,
  inactiveColor,
  '#d1d1d1',
];

export const defaultTheme: DefaultTheme = {
  bodyTextColor,
  chartColors,
  accentColor,
  dangerColor,
  disabledColor,
  inactiveColor,
  successColor,
  primaryColor,
  warningColor,
  blueDarkest,
  black,
  greyDarkest,
  greyDarker,
  greyDark,
  grey,
  greyLight,
  greyLighter,
  greyLightest,
  white,
};
