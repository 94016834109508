import Modal from 'react-modal';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/core/components/App/App';
import { AppState } from './modules/core/components/AppState';
import './custom.css';
import './index.css';
import './polyfills';
import './sentry';

// Render the app after the google maps api is loaded
Modal.setAppElement('#root');
ReactDOM.render(
  <AppState>
    <App />
  </AppState>,
  document.getElementById('root')
);
