import { formatTitleCase } from './formatters';

interface PatientNameInput {
  givenName1?: string | null;
  givenName2?: string | null;
  givenName3?: string | null;
  familyName?: string | null;
}

/**
 * Formats a patient name into a string for display
 */
export const formatName = (p: PatientNameInput): string => {
  if (p == null) {
    return 'N/A';
  }
  return [p.givenName1, p.givenName2, p.givenName3, p.familyName]
    .filter(n => n)
    .map(n => {
      if (typeof n != 'string') return null;
      return formatTitleCase(n);
    })
    .join(' ');
};

/**
 * Formats an ISO8601 birth date into a string for display
 * @todo Refactor to use Date.toLocaleDateString()
 */
export const formatDob = (dob: string | null): string => {
  if (!dob) return '';

  const splitDate = dob.split('-');
  if (splitDate.length === 3) {
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }

  return dob;
};
