import React, { FC } from 'react';
import { Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputName: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Office Name:">
    {form.getFieldDecorator('name', {
      initialValue,
      rules: [{ message: 'Please enter the name of the location.', required: true }],
    })(<Input allowClear placeholder={'Name of this office location, e.g. "Beagle Street Clinic"'} />)}
  </Form.Item>
);
