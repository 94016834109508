import { Button, Icon, Result } from 'antd';
import React, { FC } from 'react';

interface Props {
  distanceFromPatient: string;
  setDistanceFromPatient: (distance: number | null) => void;
}

export const EmptyResults: FC<Props> = ({ distanceFromPatient, setDistanceFromPatient }): JSX.Element => {
  return (
    <Result
      icon={<Icon type="search" style={{ color: '#666' }} />}
      title="No providers found"
      subTitle="Try modifying the filters or increasing the search radius."
      extra={
        distanceFromPatient && (
          <Button
            type="primary"
            onClick={() => {
              setDistanceFromPatient(null);
            }}
          >
            Increase Search Radius
          </Button>
        )
      }
    />
  );
};
