import _ from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Button, Form, Icon, Typography } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { defaultTheme } from '../../../themes/default';
import { Input } from '../../../system/components/Input';
import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';

interface Props {
  form: WrappedFormUtils;
  initialLocation?: string;
  setLatitude: (latitude: number | undefined) => void;
  setLongitude: (longitude: number | undefined) => void;
  embedded: boolean;
  onLocationChange?: (valid: boolean) => void;
}

type GeocoderRequest = google.maps.GeocoderRequest;
type GeocoderResult = google.maps.GeocoderResult;
type GeocoderStatus = google.maps.GeocoderStatus;

const { Text } = Typography;

const Styles = styled.div`
  .location-icon {
    margin-right: 8px;
  }

  .location-string {
    color: ${({ theme }) => theme.successColor};
    display: block;
    line-height: 1.5;
    padding-left: 4px;
  }

  .location-string-error {
    color: ${({ theme }) => theme.dangerColor};
    display: block;
    line-height: 1.5;
    padding-left: 4px;
  }
`;

const guessGeocodeQuery = gql`
  query GuessGeocodeQuery($searchTerm: String!) {
    guessGeocode(searchTerm: $searchTerm) {
      lat
      lng
      location
    }
  }
`;

export const InputLocation: FC<Props> = ({
  form,
  initialLocation,
  setLatitude,
  setLongitude,
  embedded,
  onLocationChange = () => {},
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [locationString, setLocationString] = useState<string | null>(null);
  const [locationStringError, setLocationStringError] = useState<string | null>(null);
  const [navigatorGeolocationSupported, setNavigatorGeolocationSupported] = useState<boolean>(
    !!navigator && !!navigator.geolocation && !!navigator.geolocation.getCurrentPosition && !embedded
  );

  const client = useApolloClient();

  const navigatorGeolocationErrorCallback = (_positionError: GeolocationPositionError): void => {
    setNavigatorGeolocationSupported(false);
    setLoading(false);
  };

  const navigatorGeolocationSuccessCallback = (position: GeolocationPosition): void => {
    const geocoder = new google.maps.Geocoder();
    const reverseGeocodeRequest: GeocoderRequest = {
      location: { lat: position.coords.latitude, lng: position.coords.longitude },
    };

    geocoder.geocode(reverseGeocodeRequest, handleReverseGeocodeResponse);
  };

  const handleReverseGeocodeResponse = (results: GeocoderResult[], status: GeocoderStatus): void => {
    if (status === 'OK' && results && results.length > 0) {
      const streetAddressResults: GeocoderResult[] = results.filter((result: GeocoderResult) => {
        return result.types && result.types.includes('street_address');
      });

      const bestResult = streetAddressResults.length > 0 ? streetAddressResults[0] : results[0];

      if (bestResult.address_components) {
        const postalCodeComponents = bestResult.address_components.filter(addressComponent => {
          return addressComponent.types && addressComponent.types.includes('postal_code');
        });

        if (postalCodeComponents.length > 0) {
          const bestAddressComponent = postalCodeComponents[0];
          if (
            bestAddressComponent.short_name &&
            bestResult.formatted_address &&
            bestResult.geometry.location?.lat() &&
            bestResult.geometry.location?.lng()
          ) {
            form.setFieldsValue({ location: bestAddressComponent.short_name });
            setLocationString(bestResult.formatted_address);
            setLatitude(bestResult.geometry.location.lat());
            setLongitude(bestResult.geometry.location.lng());
          } else {
            // todo - what if address and result properties are missing?
          }
        } else {
          // todo - what if there are no postal code components?
        }
      } else {
        // todo - what if the result has no address components?
      }

      setLoading(false);
    } else {
      setLocationStringError('Could not determine current location.');
      setLoading(false);
    }
  };

  const handleUseMyCurrentLocationButton = (_e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    form.setFieldsValue({ location: undefined });
    setLoading(true);
    setLocationString(null);
    setLocationStringError(null);
    setLatitude(undefined);
    setLongitude(undefined);
    onLocationChange(true);
    navigator.geolocation.getCurrentPosition(navigatorGeolocationSuccessCallback, navigatorGeolocationErrorCallback);
  };

  const guessGeocode = useCallback(
    async (searchTerm: string): Promise<void> => {
      await client
        .query({
          query: guessGeocodeQuery,
          variables: { searchTerm: searchTerm },
        })
        .then(value => {
          const geocode = value.data.guessGeocode;

          if (geocode) {
            setLocationString(geocode.location);
            setLatitude(geocode.lat);
            setLongitude(geocode.lng);
            setLoading(false);
            onLocationChange(true);
          } else {
            setLocationStringError('Could not determine current location.');
            setLoading(false);
            onLocationChange(false);
          }
        });
    },
    [client, onLocationChange, setLatitude, setLongitude]
  );

  const handleDebouncedInputChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      form.setFieldsValue({ location: undefined });
      setLocationString(null);
      setLocationStringError(null);
      setLatitude(undefined);
      setLongitude(undefined);
      return;
    }

    if (e.target.value.length <= 3) return;

    setLoading(true);
    setLocationString(null);
    setLocationStringError(null);
    setLatitude(undefined);
    setLongitude(undefined);

    guessGeocode(e.target.value);
  };

  const handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    form.setFieldsValue({ location: e.target.value });
    e.persist();

    if (e.target.value === '') {
      onLocationChange(true);
    } else {
      onLocationChange(false);
    }

    handleInputChangedDebounce(e);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChangedDebounce = useCallback(_.debounce(handleDebouncedInputChanged, 500), []);

  useEffect(() => {
    if (initialLocation === form.getFieldValue('location') && initialLocation !== undefined) {
      guessGeocode(initialLocation);
    }
  }, [initialLocation, guessGeocode, form]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Form.Item label="What is your location?">
          {form.getFieldDecorator('location', {
            initialValue: initialLocation,
            rules: [{ required: false }],
          })(
            <>
              <Input
                allowClear={!loading}
                onChange={handleInputChanged}
                placeholder="Enter postal code or city"
                suffix={loading ? <Icon type="loading" /> : null}
                value={form.getFieldValue('location')}
              />
              {locationString ? (
                <Text className="location-string">
                  <Icon className="location-icon" type="check-circle" />
                  {locationString}
                </Text>
              ) : null}
              {locationStringError ? (
                <Text className="location-string-error">
                  <Icon className="location-icon" type="close-circle" />
                  {locationStringError}
                </Text>
              ) : null}
              {navigatorGeolocationSupported ? (
                <Button
                  style={{ padding: '4px' }}
                  disabled={loading}
                  type="link"
                  onClick={handleUseMyCurrentLocationButton}
                >
                  <Icon type="environment" />
                  {'Use My Current Location'}
                </Button>
              ) : null}
            </>
          )}
        </Form.Item>
      </Styles>
    </ThemeProvider>
  );
};
