import React, { FC } from 'react';
import { Form, Icon, Tooltip } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Input } from '../../../system/components/Input';

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
}

export const InputDisplayName: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label={
      <span>
        Display Name&nbsp;
        <Tooltip title="This field should contain the name as you would have it appear on your Dashboard. The display name should be shorter in nature for more effective visibility in some tables.">
          <Icon type="info-circle" />
        </Tooltip>
      </span>
    }
  >
    {form.getFieldDecorator('displayName', {
      initialValue,
      rules: [{ message: 'Please enter the display name of the location.', required: false }],
    })(<Input allowClear placeholder={'Display Name, e.g. "Beagle"'} />)}
  </Form.Item>
);
