import React, { FC, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ApolloError } from '@apollo/client';
import { message, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { CreateUserForm, CreateUserFormData } from './CreateUserForm';
import { CreateUserMutationData, useCreateUserMutation } from '../hooks/useCreateUserMutation';
import { defaultTheme } from '../../../themes/default';
import { exchangeCredentials, removeLoginToken, storeLoginToken } from '../../auth';

interface Props {
  params: Params;
}

interface Params {
  regionId?: string;
  product?: string;
}

const { Title } = Typography;

const Styles = styled.div`
  min-height: 100vh;
  height: 100%;

  .create-user-title-2 {
    color: ${props => props.theme.blueDarkest};
    font-weight: 400;
    text-align: center;
  }

  .create-user-title-4 {
    color: ${props => props.theme.blueDarkest};
    font-weight: 200;
    text-align: center;
  }
`;

export const CreateUserStep: FC<Props> = ({ params }): JSX.Element => {
  const history = useHistory();
  const [password, setPassword] = useState<string>('');

  const handleCreateUserCompleted = async ({ createUser: { email } }: CreateUserMutationData): Promise<void> => {
    const response = await exchangeCredentials(email, password);
    setPassword('');

    // todo - error handling - what if we can't get a token for the newly created user?
    if (!response.err) {
      removeLoginToken();
      storeLoginToken(response.val);
    }

    let queryString = '';
    if (params.regionId) {
      queryString += `?regionId=${params.regionId}`;
      if (params.product) queryString += `&product=${params.product}`;
    } else {
      if (params.product) queryString += `?product=${params.product}`;
    }

    history.push(`/account-signup/login${queryString}`);
  };

  const handleCreateUserError = (error: ApolloError): void => {
    if (error.message.includes('has already been taken')) {
      message.warning('That email address has already been taken.  Please use a different email address.', 10);
    } else {
      // todo - handle error
      message.error('Something went wrong!  Please try again or contact us if the problem persists.');
    }
  };

  const [createUser, { loading }] = useCreateUserMutation({
    onCompleted: handleCreateUserCompleted,
    onError: handleCreateUserError,
  });

  const handleSubmit = (values: CreateUserFormData): void => {
    setPassword(values.password);

    const createUserInput = {
      name: values.name,
      email: values.email,
      password: values.password,
      isActive: true,
    };

    createUser({ variables: { createUserInput } }).then();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Title level={2} className="create-user-title-2">
          Get started today!
        </Title>
        <Title level={4} className="create-user-title-4">
          First, let&apos;s create your account.
        </Title>
        <br />
        <CreateUserForm disabled={loading} onSubmit={handleSubmit} />
      </Styles>
    </ThemeProvider>
  );
};
