import { isValidEmail } from '.';

type antCallback = (arg0?: string) => void;

export const validateAntPostalCode = (rule: string, value: string, callback: antCallback): void => {
  if (!value) {
    callback();
  } else if (isNaN(parseInt(value)) || !/^\d+$/.test(value)) {
    callback('must be a number');
  } else if (value.length < 5) {
    callback('minimum length of 5');
  } else if (value.length > 5) {
    callback('maximum length of 5');
  } else if (value.length === 5) {
    callback();
  } else {
    callback('unknown error');
  }
};

export const validateAntPhoneNumber = (rule: string, value: string, callback: antCallback): void => {
  const len = value.toString().length;

  if (len > 0 && len !== 10) {
    callback('must be 10 digits');
  } else {
    callback();
  }
};

export const validateAntEmail = (rule: string, value: string, callback: antCallback): void => {
  if (value && !isValidEmail(value)) {
    callback('Please input an email address');
  }
  callback();
};
