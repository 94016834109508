import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { FC } from 'react';
import { DeleteGroupProfile } from './DeleteGroupProfile';

interface Location {
  id: string;
  name: string;
}

interface Organization {
  id: string;
  name: string;
}

interface Profile {
  id: string;
  displayName: string;
  location: Location;
  organization: Organization;
}

interface Props {
  groupId: string;
  profiles: Profile[];
}

export const GroupProfilesTable: FC<Props> = ({ groupId, profiles }): JSX.Element => {
  const columns: ColumnProps<Profile>[] = [
    {
      title: 'Name',
      dataIndex: 'displayName',
    },
    {
      title: 'Organization',
      dataIndex: 'organization.name',
    },
    {
      title: 'Location',
      dataIndex: 'location.name',
    },
    {
      title: 'Actions',
      render: (text, record) => <DeleteGroupProfile groupId={groupId} profile={record} />,
    },
  ];

  return <Table<Profile> columns={columns} dataSource={profiles} rowKey="id" pagination={false} />;
};
