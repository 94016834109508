import { useMutation, MutationTuple } from '@apollo/client';
import { gql } from '@apollo/client';

export interface CreateUserInOrganizationData {
  id: string;
  name: string;
  email: string;
  phone: string;
  hasAllLocations: boolean;
  isAdmin: boolean;
  isActive: boolean;
  locations: {
    id: string;
    name: string;
  }[];
}

export interface CreateUserInOrganizationVariables {
  name: string;
  email: string;
  phone?: string;
  isAdmin: boolean;
  isActive: boolean;
  organizationId: string;
  hasAllLocations: boolean;
  userLocationIdsToAdd: string[];
}

type CreateUserMutationResult = MutationTuple<CreateUserInOrganizationData, CreateUserInOrganizationVariables>;

const CreateUserMutation = gql`
  mutation createUserInOrganization(
    $name: String!
    $email: String!
    $phone: String
    $organizationId: ID!
    $isAdmin: Boolean
    $isActive: Boolean
    $hasAllLocations: Boolean!
    $userLocationIdsToAdd: [String]
  ) {
    createUserInOrganization(
      name: $name
      email: $email
      phone: $phone
      organizationId: $organizationId
      hasAllLocations: $hasAllLocations
      isAdmin: $isAdmin
      isActive: $isActive
      userLocationIdsToAdd: $userLocationIdsToAdd
    ) {
      id
      name
      email
      phone
      hasAllLocations(organizationId: $organizationId)
      isAdmin(organizationId: $organizationId)
      isActive
      locations(organizationId: $organizationId) {
        id
        name
      }
    }
  }
`;

export const useCreateUserInOrganizationMutation = (): CreateUserMutationResult => {
  return useMutation<CreateUserInOrganizationData, CreateUserInOrganizationVariables>(CreateUserMutation);
};
