import React, { FC } from 'react';
import { Icon } from 'antd';
import { InternalServerErrorAlert } from '../../../components';
import { useCountUsersInOrganizationQuery } from '../hooks/useCountUsersInOrganizationQuery';

interface Props {
  organizationId: string;
}

export const ActiveUserCount: FC<Props> = ({ organizationId }): JSX.Element => {
  const { data, loading, error } = useCountUsersInOrganizationQuery({
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
  });

  if (error) return <InternalServerErrorAlert error={error} />;

  if (loading || !data) {
    return <Icon type="loading" />;
  }

  const { countUsersInOrganization: count } = data;

  return <>{count}</>;
};
