import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApolloError } from '@apollo/client';
import { Button, Col, message, Row } from 'antd';
import { DateTime } from 'luxon';
import { UploadFile } from 'antd/lib/upload/interface';
import { useHistory } from 'react-router-dom';
import { AppointmentDetails } from './AppointmentDetails';
import {
  CreateConsumerSchedulingEngagementMutationData,
  useCreateConsumerSchedulingEngagementMutation,
} from '../hooks/useCreateConsumerSchedulingEngagementMutation';
import {
  CreateConsumerSchedulingPatientMutationData,
  useCreateConsumerSchedulingPatientMutation,
} from '../hooks/useCreateConsumerSchedulingPatientMutation';
import {
  ConsumerQuestionnaireBefore,
  ConsumerQuestionnaireInline,
} from '../../../components/questionnaire/Questionnaire';
import { generateUtmParametersQueryString } from '../helpers/utm';
import { InternalServerErrorAlert, Spinner } from '../../../components';
import { Organization } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { PatientForm, PatientFormData } from './PatientForm';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { Slot } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';
import { StepContainer } from './StepContainer';
import { StepTitle } from './StepTitle';
import { useGetProcedureQuery } from '../hooks/useGetProcedureQuery';
import moment from 'moment';
import {
  useReserveSlotsMutation,
  useFreeSlotsMutation,
  QuestionnaireAnswerSet,
  QuestionnaireAnswer,
} from '../../../generated/graphql';
// import { usePatientPrefill } from './PatientPrefill';

interface Props {
  organization: Organization;
  params: Params;
  payorId?: string;
  payorPlanId?: string;
  procedureId: string;
  profile: Profile;
  apptType: string;
  slot?: Slot;
  order?: string;
}

interface Params {
  embedded?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_cmpid?: string;
  utm_term?: string;
  utm_content?: string;
}

const Styles = styled.div`
  .change-appointment-button {
    border: none;
    box-shadow: none;
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    height: 38px;
    text-align: center;
    text-decoration: none solid;
    text-transform: uppercase;
    width: 100%;
    color: #2783c4;
    line-height: 26px;
  }
`;

export const AppointmentStep: FC<Props> = ({
  organization,
  params,
  payorId,
  payorPlanId,
  procedureId,
  profile,
  apptType,
  slot,
  order,
}): JSX.Element => {
  useEffect(() => {
    document.title = `${organization.name} - Scheduling`;
  }, [organization.name]);

  const history = useHistory();

  const [reserveSlotsMutation] = useReserveSlotsMutation();
  const [freeSlotsMutation] = useFreeSlotsMutation();

  const [token, setToken] = useState<string | null>(null);
  const [patientName, setPatientName] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState(0);

  // Reserve slots when the component is mounted
  useEffect(() => {
    if (slot?.slotIdsForAppointment.length === 0) return;

    reserveSlotsMutation({
      variables: {
        organizationId: organization.id,
        slotIds: slot?.slotIdsForAppointment || [],
        resourceId: slot?.profileId || profile.id,
        token: token,
      },
    })
      .then(data => {
        if (data.data && data.data.reserveSlots) setToken(data.data?.reserveSlots.token);
      })
      .catch(error => {
        const slotTakenMessage = (): void => {
          message.warning('The selected time is no longer available. Please select a different appointment time.', 10);
        };

        slotTakenMessage();
        history.goBack();
      });

    const timer = setInterval(() => {
      setRefreshToken(refreshToken + 1);
    }, 45000);

    return () => {
      clearInterval(timer);
    };
  }, [organization, slot, profile, token, freeSlotsMutation, reserveSlotsMutation, history, refreshToken]);

  // Free reserved slots when the component unmounts
  useEffect(() => {
    return () => {
      if (token === null) return;
      freeSlotsMutation({
        variables: {
          slotIds: slot?.slotIdsForAppointment || [],
          resourceId: slot?.profileId || profile.id,
          token: token,
        },
      });
    };
  }, [slot, profile, token, freeSlotsMutation]);

  const [appointmentAttachments, setAppointmentAttachments] = useState<File[]>([]);
  const [patientNotes, setPatientNotes] = useState<string | null>(null);
  const [questionAnswers, setQuestionAnswers] = useState<string | null>(null);
  const [questionnaireAnswerSets, setQuestionnaireAnswerSets] = useState<QuestionnaireAnswerSet[]>([]);

  // const [patientPrefillInput, prefillData, savePrefillPatient] = usePatientPrefill();

  const { data: procedureData, error: procedureError, loading: procedureLoading } = useGetProcedureQuery({
    variables: { procedureId },
  });

  const addAppointmentAttachment = (file: File): void => {
    setAppointmentAttachments([...appointmentAttachments, file]);
  };

  const removeAppointmentAttachment = (file: UploadFile): boolean => {
    if (file.originFileObj && 'name' in file.originFileObj) {
      const index = appointmentAttachments.indexOf(file.originFileObj);
      const newFileList = appointmentAttachments.slice();

      newFileList.splice(index, 1);
      setAppointmentAttachments(newFileList);

      return true;
    }

    return false;
  };

  const handleCreateEngagementCompleted = (data: CreateConsumerSchedulingEngagementMutationData): void => {
    const { createConsumerSchedulingEngagement: engagement } = data;

    let queryString =
      `?practitioner=${encodeURIComponent(profile.displayName)}` +
      `&location=${encodeURIComponent(profile.location.name)}`;

    if (procedureData?.getProcedure.specialty.name) {
      queryString += `&specialty=${encodeURIComponent(procedureData?.getProcedure.specialty.name)}`;
    }

    if (procedureData?.getProcedure.name) {
      queryString += `&visitType=${encodeURIComponent(procedureData?.getProcedure.name)}`;
    }

    if (params.embedded === 'true') queryString += '&embedded=true';

    queryString += generateUtmParametersQueryString(params, queryString);

    history.push(`/consumer/${organization.slug ? organization.slug : organization.id}/success${queryString}`, {
      engagement,
      apptType,
      patientName,
    });
  };

  const handleCreateEngagementError = (error: ApolloError): void => {
    if (error.message.includes('SLOT_TAKEN')) {
      const slotTakenMessage = (): void => {
        message.warning('The selected time is no longer available. Please select a different appointment time.', 10);
      };

      slotTakenMessage();
      history.goBack();
    } else {
      handleMutationError();
    }
  };

  const handleCreatePatientCompleted = (data: CreateConsumerSchedulingPatientMutationData): void => {
    const {
      createConsumerSchedulingPatient: { id: patientId },
    } = data;

    let comment = '';
    if (patientNotes) {
      comment += `${patientNotes}`;
    }

    if (questionAnswers) {
      comment += `\n\nQuestionnaire Answers:\n ${questionAnswers}\n\n`;
    }

    if (apptType === 'instant') {
      const createConsumerSchedulingEngagementInstantInput = {
        patientId,
        procedureId,
        profileId: profile.id,
        comment: comment !== '' ? comment : undefined,
        order,
      };

      createEngagement({
        variables: { organizationId: organization.id, createConsumerSchedulingEngagementInstantInput },
      }).then();
    } else if (slot) {
      const createConsumerSchedulingEngagementInput = {
        patientId,
        procedureId,
        profileId: slot.profileId,
        slotIds: slot.slotIdsForAppointment,
        start: slot.start,
        end: slot.end,
        comment: comment !== '' ? comment : undefined,
        order,
        tags: {
          utmSource: params.utm_source,
          utmMedium: params.utm_medium,
          utmCampaign: params.utm_campaign,
          utmCmpid: params.utm_cmpid,
          utmTerm: params.utm_term,
          utmContent: params.utm_content,
        },
        appointmentAttachments: appointmentAttachments ? appointmentAttachments : undefined,
        questionnaireAnswerSets: questionnaireAnswerSets,
      };

      createEngagement({
        variables: { organizationId: organization.id, createConsumerSchedulingEngagementInput },
      }).then();
    }
  };

  const handleCreatePatientError = (_error: ApolloError): void => {
    handleMutationError();
  };

  const handleMutationError = (): void => {
    let queryString = params.embedded === 'true' ? '?embedded=true' : '';
    queryString += generateUtmParametersQueryString(params, queryString);

    history.push(`/consumer/${organization.slug ? organization.slug : organization.id}/error${queryString}`, {
      profile,
    });
  };

  const [
    createPatient,
    { called: createPatientCalled, loading: createPatientLoading },
  ] = useCreateConsumerSchedulingPatientMutation({
    onCompleted: handleCreatePatientCompleted,
    onError: handleCreatePatientError,
  });

  const [
    createEngagement,
    { called: createEngagementCalled, loading: createEngagementLoading },
  ] = useCreateConsumerSchedulingEngagementMutation({
    onCompleted: handleCreateEngagementCompleted,
    onError: handleCreateEngagementError,
  });

  const handleChangeAppointmentClick = (): void => {
    history.goBack();
  };

  const handleSetQuestions = (values: {
    questionnaire: {}[];
    questionnaireAnswerKeys?: { [key: string]: string }[];
    questionnaireName?: string;
    questionnaireId?: string;
  }): void => {
    const answers: string[] = [];
    const questionnaireAnswers: QuestionnaireAnswer[] = [];
    const questionnaireAnswerKeys = values.questionnaireAnswerKeys;

    for (const [questionKey, answer] of Object.entries(values.questionnaire[0])) {
      let formattedQuestionKey = questionKey;
      let formattedAnswer = '';

      if (answer === true) formattedAnswer = 'Yes';
      else if (answer === false) formattedAnswer = 'No';
      else if (moment.isMoment(answer)) {
        if (questionKey.endsWith('-date')) {
          formattedQuestionKey = questionKey.replace('-date', '');
          formattedAnswer = answer.format('MMM D, YYYY');
        } else if (questionKey.endsWith('-time')) {
          formattedQuestionKey = questionKey.replace('-time', '');
          formattedAnswer = answer.format('hh:mm A');
        }
      } else {
        formattedAnswer = (answer as any)?.toString() || '';
      }

      answers.push(`${formattedQuestionKey}: ${formattedAnswer}`);

      let answerKey = '';

      if (questionnaireAnswerKeys && questionnaireAnswerKeys.length > 0) {
        answerKey = questionnaireAnswerKeys[0][formattedQuestionKey] || '';
      }

      questionnaireAnswers.push({
        text: formattedQuestionKey,
        value: formattedAnswer,
        key: answerKey,
      });
    }

    const answerSets: QuestionnaireAnswerSet[] = [
      {
        questionnaireName: values.questionnaireName || 'Questionnaire',
        questionnaireAnswers: questionnaireAnswers,
        questionnaireId: values.questionnaireId || '',
      },
    ];

    const notes = answers.join('\n');
    setQuestionAnswers(a => `${a || ''}\n${notes}`);
    setQuestionnaireAnswerSets(answerSets);
  };

  const handlePatientFormSubmit = (values: PatientFormData): void => {
    const createPatientInput = {
      familyName: values.familyName,
      givenName1: values.givenName1,
      givenName2: values.givenName2,
      address1: values.address1 ? values.address1 : undefined,
      address2: values.address2 ? values.address2 : undefined,
      city: values.city ? values.city : undefined,
      state: values.state ? values.state : undefined,
      postalCode: values.postalCode ? values.postalCode : undefined,
      dob: DateTime.utc(values.birthYear, values.birthMonth, values.birthDay).toISODate(),
      sex: values.sex,
      phone: values.phone ? values.phone.trim() : '',
      email: values.email ? values.email.toLowerCase().trim() : undefined,
      insuranceCarrier: values.payorId ? values.payorId : undefined,
      insurancePlan: values.payorPlanId ? values.payorPlanId : undefined,
      insuranceGroupNumber: values.insuranceGroupNumber ? values.insuranceGroupNumber : undefined,
      insurancePolicyNumber: values.insurancePolicyNumber ? values.insurancePolicyNumber : undefined,
    };

    setPatientName(values.givenName1 + ' ' + values.familyName);

    // todo - remove once logging is complete
    const today = DateTime.local().toISODate();
    if (createPatientInput.dob === today) {
      Sentry.captureMessage(
        `Consumer birthdate maybe set to today.  Given values: ` +
          `${values.birthDate.year}-${values.birthDate.month}-${values.birthDate.day} vs. ` +
          `${values.birthYear}-${values.birthMonth}-${values.birthDay}.`,
        Severity.Error
      );
    }

    if (values.notes) {
      setPatientNotes(values.notes);
    }

    if (values.questionnaire) {
      // Hack to shove the question answers into the notes field until there is a
      // dedicated api for it.
      handleSetQuestions(values);
    }

    if (organization.consumerSchedulingSettings?.showPatientPrefill) {
      // savePrefillPatient(values);
    }

    createPatient({ variables: { createPatientInput, organizationId: organization.id } }).then();
  };

  const disabled = createPatientLoading || createPatientCalled || createEngagementCalled || createEngagementLoading;

  if (procedureError) return <InternalServerErrorAlert error={procedureError} />;

  if (procedureLoading || !procedureData) {
    return <Spinner />;
  }

  return (
    <Styles>
      <StepContainer maxWidth="932px">
        <ConsumerQuestionnaireBefore
          profileId={profile?.id}
          procedureId={procedureId}
          span={12}
          handleSetQuestions={handleSetQuestions}
        >
          <Row gutter={[0, 36]}>
            <Col span={24}>
              <StepTitle title="Next Step &mdash; Complete Fields Below" />
            </Col>
          </Row>
          <Row gutter={[0, 36]}>
            <Col span={24}>
              <AppointmentDetails
                procedureId={procedureId}
                profile={profile}
                slot={slot}
                apptType={apptType}
                order={order}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <PatientForm
                addAppointmentAttachment={addAppointmentAttachment}
                disabled={disabled}
                initialPayorId={payorId}
                initialPayorPlanId={payorPlanId}
                profile={profile}
                onSubmit={handlePatientFormSubmit}
                organization={organization}
                prefillData={null}
                removeAppointmentAttachment={removeAppointmentAttachment}
              >
                {({ form }) => (
                  <ConsumerQuestionnaireInline
                    key={1}
                    profileId={profile?.id}
                    procedureId={procedureId}
                    span={12}
                    form={form}
                  />
                )}
              </PatientForm>
            </Col>
            {!disabled ? (
              <Col span={24}>
                <Button className="change-appointment-button" onClick={handleChangeAppointmentClick} type="link">
                  Change Appointment
                </Button>
              </Col>
            ) : null}
          </Row>
        </ConsumerQuestionnaireBefore>
      </StepContainer>
    </Styles>
  );
};
