import { Alert, Button } from 'antd';
import React, { FC } from 'react';
import { FreePlanConfiguration } from '../../../generated/graphql';
import { ActiveUserCount } from './ActiveUserCount';

interface Props {
  organizationId: string;
  isFree: boolean;
  freePlanConfiguration: FreePlanConfiguration;
}

export const FreeOrganizationUserCount: FC<Props> = ({
  organizationId,
  isFree,
  freePlanConfiguration,
}): JSX.Element | null => {
  if (!isFree) return null;

  if (!freePlanConfiguration) {
    return null;
  }

  return (
    <Alert
      style={{ marginBottom: 16 }}
      message={
        <>
          Subscription Information{' '}
          <Button type="primary" style={{ float: 'right' }}>
            <a href="https://www.blockitnow.com/covid-upgrade">Click here to Upgrade</a>
          </Button>
        </>
      }
      description={
        <>
          You are currently using <ActiveUserCount organizationId={organizationId} /> of {freePlanConfiguration.users}{' '}
          active users in your free subscription.
          <br />
        </>
      }
      type="info"
      showIcon
    />
  );
};
