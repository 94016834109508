import * as Sentry from '@sentry/browser';
import { Button, Form, message, Select } from 'antd';
import React, { FC, useContext } from 'react';
import { MutationFunctionOptions, useMutation, InMemoryCache } from '@apollo/client';
import { IsSuperuser } from '../../../../components/IsSuperuser';
import { UpdateAppointment } from '../../../../GraphQL';
import { deletePaginatedCacheItems } from '../../../../helpers/cache';
import { capitalize } from '../../../../helpers/stringHelpers';
import { AppointmentData, ReferralData, QUERY } from './GetReferralQuery';
import { AppContext } from '../../../core/contexts/AppContext';
import { LIST_AUDIT_LOG_QUERY } from './ListAuditLogQuery';
const { Option } = Select;

interface UpdateApptMutationVars {
  UpdateAppointmentInput: {
    id: string;
    status: string;
  };
}

interface UpdateApptMutationData {
  UpdateAppointment: {
    id: string;
    status: string;
  };
}

export const ChangeAppointmentStatus: FC<{
  appointment?: AppointmentData;
  referral?: ReferralData;
}> = ({ appointment, referral }): JSX.Element | null => {
  const [updateAppointmentMutation, { error }] = useMutation<UpdateApptMutationData, UpdateApptMutationVars>(
    UpdateAppointment
  );

  const { currentOrganization } = useContext(AppContext);

  if (error) {
    Sentry.captureException(error);
    message.error('Error updating appointment status');
  }

  const enableCancelAndRescheduleButton = currentOrganization?.referralSettings?.enableCancelAndRescheduleButton;
  const showButton = !currentOrganization?.referralSettings?.hideSchedulingButtons;

  const mutationVariables = (val: string): MutationFunctionOptions<UpdateApptMutationData, UpdateApptMutationVars> => {
    let config = {};
    if (referral) {
      config = {
        variables: {
          UpdateAppointmentInput: { id: appointment?.id, status: val },
        },
        refetchQueries: [
          {
            query: QUERY,
            variables: {
              id: referral?.id,
              organizationId: currentOrganization?.id as string,
            },
          },
          {
            query: LIST_AUDIT_LOG_QUERY,
            variables: {
              action: 'referral.status.updated',
              recordId: referral?.id,
              organizationId: currentOrganization?.id as string,
            },
          },
        ],
        update: (cache: InMemoryCache) => {
          message.success('Appointment status updated');
          deletePaginatedCacheItems(cache, 'ListReferrals');
          deletePaginatedCacheItems(cache, 'ListAppointments');
        },
      };
    } else {
      config = {
        variables: {
          UpdateAppointmentInput: { id: appointment?.id, status: val },
        },
        update: (cache: InMemoryCache) => {
          message.success('Appointment status updated');
          deletePaginatedCacheItems(cache, 'ListReferrals');
          deletePaginatedCacheItems(cache, 'ListAppointments');
        },
      };
    }
    return config;
  };

  const CancelThenReschedule = (): JSX.Element => {
    const handleClick = () => {
      if (referral !== undefined) {
        updateAppointmentMutation(mutationVariables('cancelled')).then(() => {
          window.location.href = `/referrals/${referral.id}/search`;
        });
      }
    };

    if (referral) {
      return (
        <Button className="mt-2" onClick={handleClick}>
          Cancel Existing/Create New Appt with Alternate Provider
        </Button>
      );
    } else {
      return <React.Fragment />;
    }
  };

  const DropDown = (): JSX.Element => (
    <Form>
      <Form.Item label="Change Status">
        <Select<string>
          size="small"
          defaultValue={appointment ? capitalize(appointment.status) : 'Booked'}
          placeholder="Update Status"
          style={{}}
          onChange={val =>
            appointment
              ? updateAppointmentMutation(mutationVariables(val))
              : message.error('Unable to update appointment status')
          }
        >
          <Option value="booked" key="booked">
            Booked
          </Option>
          <Option value="fulfilled" key="fulfilled">
            Fulfilled
          </Option>
          <Option value="noshow" key="noshow">
            No Show
          </Option>
        </Select>
      </Form.Item>
      {showButton && (appointment?.status === 'booked' || appointment?.status === 'pending') && (
        <a href={appointment?.rescheduleLink} target="_new">
          <Button>Reschedule Appointment</Button>
        </a>
      )}
      {enableCancelAndRescheduleButton && (appointment?.status === 'booked' || appointment?.status === 'pending') && (
        <CancelThenReschedule key="cancel-then-reschedule" />
      )}
    </Form>
  );

  if (appointment && appointment.status === 'proposed') {
    return (
      <IsSuperuser>
        <DropDown />
      </IsSuperuser>
    );
  } else if (appointment) {
    return <DropDown />;
  }
  return null;
};
