import { Card, Checkbox, Col, Input, Row } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../core/contexts/AppContext';
import { FreeOrganizationProfilesCount } from '../components/FreeOrganizationProfilesCount';
import { ProfileTable } from '../components/ProfileTable';
import { NewProfileButton } from '../components/NewProfileButton';
import queryString, { ParsedQuery } from 'query-string';
import { history } from '../../core/components/App/history';

const { Search } = Input;

const updateURL = (activeOnly: boolean, nameFilter: string): void => {
  const queryParams: ParsedQuery = queryString.parse(history.location.search);
  history.push(
    history.location.pathname +
      '?' +
      queryString.stringify({
        ...queryParams,
        activeOnly,
        nameFilter,
      })
  );
};

const getQueryVariables = (): { activeOnly: boolean; nameFilter: string } => {
  const queryParams: ParsedQuery = queryString.parse(history.location.search);
  return {
    activeOnly: queryParams.activeOnly ? queryParams.activeOnly.toString() === 'true' : true,
    nameFilter: queryParams.nameFilter ? queryParams.nameFilter.toString() : '',
  };
};

export const Profiles: FC = (): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);
  const { activeOnly: initialActiveOnly, nameFilter: initialNameFilter } = getQueryVariables();
  const [nameFilter, setNameFilter] = useState(initialNameFilter);
  const [activeOnly, setActiveOnly] = useState(initialActiveOnly);

  useEffect(() => {
    updateURL(activeOnly, nameFilter);
  }, [activeOnly, nameFilter]);

  const handleCheckboxChange = (e: any): void => {
    const updatedActiveOnly = e.target.checked;
    setActiveOnly(updatedActiveOnly);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const updatedNameFilter = e.target.value;
    setNameFilter(updatedNameFilter);
  };

  if (!currentOrganization) {
    return <></>;
  }

  return (
    <Row>
      <Row type="flex">
        <Col span={24}>
          <FreeOrganizationProfilesCount
            organizationId={currentOrganization.id}
            isFree={currentOrganization.isFree}
            freePlanConfiguration={currentOrganization.freePlanConfiguration}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            title="Profiles"
            extra={[
              <Checkbox key="inactive" onChange={handleCheckboxChange} checked={activeOnly}>
                Active Only
              </Checkbox>,
              <Search
                key="search"
                defaultValue={nameFilter}
                placeholder="Filter by name"
                onChange={handleSearchChange}
                style={{ width: 200, paddingRight: 20 }}
              />,
              <NewProfileButton
                key="btn"
                organizationId={currentOrganization.id}
                isFree={currentOrganization.isFree}
                freePlanConfiguration={currentOrganization.freePlanConfiguration}
              />,
            ]}
          >
            <ProfileTable active={activeOnly} nameFilter={nameFilter} />
          </Card>
        </Col>
      </Row>
    </Row>
  );
};
