import * as QueryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch, useHistory } from 'react-router-dom';
import { ConsumerSchedulingPageLoader } from '../components/ConsmerSchedulingPageLoader';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { ConsumerSchedulingContext } from '../contexts/ConsumerSchedulingContext';
import { useGetConsumerSchedulingOrganizationQuery } from '../hooks/useGetConsumerSchedulingOrganizationQuery';
import { useHeadTags } from '../hooks/useHeadTags';
import { ConsumerSchedulingAppointmentPage } from '../pages/ConsumerSchedulingAppointmentPage';
import { ConsumerSchedulingErrorPage } from '../pages/ConsumerSchedulingErrorPage';
import { ConsumerSchedulingLandingPage } from '../pages/ConsumerSchedulingLandingPage';
import { ConsumerSchedulingPractitionerPage } from '../pages/ConsumerSchedulingPractitionerPage';
import { ConsumerSchedulingProfilePage } from '../pages/ConsumerSchedulingProfilePage';
import { ConsumerSchedulingSearchPage } from '../pages/ConsumerSchedulingSearchPage';
import { ConsumerSchedulingSuccessPage } from '../pages/ConsumerSchedulingSuccessPage';
import { ScrollToTop } from '../../../components/ScrollToTop';
import { GoogleTagManager } from '../components/GoogleTagManager';
import useResizeObserver from '@react-hook/resize-observer';
import { useGoogleMaps } from '../hooks/useGoogleMaps';
import qs from 'qs';

interface PatientPrefill {
  givenName1?: string | null;
  familyName?: string | null;
  birthDate: {
    birthMonth?: number | null;
    birthDay?: number | null;
    birthYear?: number | null;
  };
  sex?: string | null;
}

interface MatchParams {
  organizationId: string;
}

export const ConsumerSchedulingRoutes: FC<RouteComponentProps<MatchParams>> = ({
  location,
  match: {
    params: { organizationId },
    path,
  },
}): JSX.Element => {
  const { data, error, loading } = useGetConsumerSchedulingOrganizationQuery({ variables: { id: organizationId } });
  useHeadTags({ organization: data?.getConsumerSchedulingOrganization });
  const target = React.useRef(null);
  const queryParams = QueryString.parse(location.search);
  const googleMapsLoaded = useGoogleMaps();

  // QueryString doesn't parse query string arrays and doesn't have a compatible API. Yay.
  const queryParamsExtra = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [prefillPatient, setPrefillPatient] = useState<PatientPrefill>({
    givenName1: null,
    familyName: null,
    sex: null,
    birthDate: { birthMonth: null, birthDay: null, birthYear: null },
  });

  const history = useHistory();

  useEffect(() => {
    if (!queryParamsExtra.patient) return;

    if (queryParams['patient[givenName1]'] && typeof queryParams['patient[givenName1]'] == 'string') {
      prefillPatient.givenName1 = queryParams['patient[givenName1]'];
    }

    if (queryParams['patient[familyName]'] && typeof queryParams['patient[familyName]'] == 'string') {
      prefillPatient.familyName = queryParams['patient[familyName]'];
    }

    if (queryParams['patient[sex]'] && typeof queryParams['patient[sex]'] == 'string') {
      prefillPatient.sex = queryParams['patient[sex]'];
    }

    if (queryParams['patient[birthDate][birthDay]'] && typeof queryParams['patient[birthDate][birthDay]'] == 'string') {
      prefillPatient.birthDate.birthDay = parseInt(queryParams['patient[birthDate][birthDay]']);
    }

    if (
      queryParams['patient[birthDate][birthMonth]'] &&
      typeof queryParams['patient[birthDate][birthMonth]'] == 'string'
    ) {
      prefillPatient.birthDate.birthMonth = parseInt(queryParams['patient[birthDate][birthMonth]']);
    }

    if (
      queryParams['patient[birthDate][birthYear]'] &&
      typeof queryParams['patient[birthDate][birthYear]'] == 'string'
    ) {
      prefillPatient.birthDate.birthYear = parseInt(queryParams['patient[birthDate][birthYear]']);
    }

    delete queryParamsExtra.patient;
    history.replace(location.pathname + '?' + QueryString.stringify(queryParamsExtra));

    setPrefillPatient(prefillPatient);
  }, [prefillPatient, setPrefillPatient, queryParams, queryParamsExtra, location, history]);

  // const [tags, setTags] = useState({});

  // useEffect(() => {
  //   if (!queryParamsExtra.tags) return;
  //   setTags(queryParamsExtra.tags);
  // }, [queryParamsExtra, setTags]);

  // console.log(tags);

  useResizeObserver(target, entry => {
    window.requestAnimationFrame(() => {
      if (queryParams?.embedded && window.parent.postMessage) {
        window.parent.postMessage(JSON.stringify({ message: 'height', height: entry.contentRect.height }), '*');
      }
    });
  });

  const showLoader = !googleMapsLoaded || error || loading || !data;
  const organization = data?.getConsumerSchedulingOrganization;

  const singleSpecialtyId =
    typeof queryParams?.singleSpecialtyId === 'string' ? queryParams.singleSpecialtyId : undefined;

  const procedureId = typeof queryParams?.procedureId === 'string' ? queryParams.procedureId : undefined;

  const canChangeVisitType =
    typeof queryParams?.canChangeVisitType === 'string' ? queryParams.canChangeVisitType === 'true' : true;

  return (
    <div ref={target}>
      {showLoader && <ConsumerSchedulingPageLoader loading={loading} error={error} />}
      {!showLoader && organization && (
        <ConsumerSchedulingContext.Provider
          value={{ organization: organization, singleSpecialtyId, procedureId, canChangeVisitType, prefillPatient }}
        >
          <GoogleTagManager gtmId={organization.consumerSchedulingSettings?.googleTagManagerId} />
          <ScrollToTop />
          {queryParams?.embedded === 'true' ? null : <Header organization={organization} />}
          <div id="blockit-consumer">
            <Switch>
              <Route
                exact
                path={path}
                key="route--consumer-scheduling-landing"
                component={ConsumerSchedulingLandingPage}
              />
              <Route
                exact
                path={`${path}/confirm`}
                key="route--consumer-scheduling-appointment"
                component={ConsumerSchedulingAppointmentPage}
              />
              <Route
                exact
                path={`${path}/error`}
                key="route--consumer-scheduling-error"
                component={ConsumerSchedulingErrorPage}
              />
              <Route
                exact
                path={`${path}/search`}
                key="route--consumer-scheduling-search"
                component={ConsumerSchedulingSearchPage}
              />
              <Route
                exact
                path={`${path}/success`}
                key="route--consumer-scheduling-success"
                component={ConsumerSchedulingSuccessPage}
              />
              <Route
                exact
                path={`${path}/npi/:npi`}
                key="route--consumer-scheduling-practitioner"
                component={ConsumerSchedulingPractitionerPage}
              />
              <Route
                exact
                path={`${path}/provider/:profileId`}
                key="route--consumer-scheduling-profile"
                component={ConsumerSchedulingProfilePage}
              />
            </Switch>
          </div>
          {queryParams?.embedded === 'true' ? null : <Footer organization={organization} />}
        </ConsumerSchedulingContext.Provider>
      )}
    </div>
  );
};
