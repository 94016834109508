import * as Sentry from '@sentry/browser';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, message } from 'antd';
import { useMutation } from '@apollo/client';
import { UpdateAppointment } from '../../../../GraphQL';
import { deletePaginatedCacheItems } from '../../../../helpers/cache';
import { AppointmentData } from './GetReferralQuery';

interface UpdateApptMutationVars {
  UpdateAppointmentInput: {
    id: string;
    status: string;
  };
}

interface UpdateApptMutationData {
  UpdateAppointment: {
    id: string;
    status: string;
  };
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const ConfirmAppointment: FC<{ appointment?: AppointmentData }> = ({ appointment }): JSX.Element | null => {
  const [updateAppointmentMutation, { error }] = useMutation<UpdateApptMutationData, UpdateApptMutationVars>(
    UpdateAppointment
  );

  if (error) {
    Sentry.captureException(error);
    message.error('Error confirming appointment');
  }

  if (!appointment) {
    return null;
  }

  const handleClick = (): void => {
    updateAppointmentMutation({
      variables: {
        UpdateAppointmentInput: {
          id: appointment.id,
          status: 'booked',
        },
      },
      update: cache => {
        message.success('Appointment has been confirmed');
        deletePaginatedCacheItems(cache, 'ListReferrals');
        deletePaginatedCacheItems(cache, 'ListAppointments');
      },
    });
  };

  return (
    <Wrapper>
      <Button type="primary" onClick={() => handleClick()}>
        Confirm Appointment
      </Button>
    </Wrapper>
  );
};
