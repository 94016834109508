import React, { FC } from 'react';
import { Alert } from 'antd';

interface Props {
  banner?: boolean;
  description?: string;
  message: string;
}

export const ErrorAlert: FC<Props> = ({ banner = true, description, message }): JSX.Element => (
  <Alert message={message} description={description} banner={banner} closable={true} showIcon={true} type="error" />
);
