import React, { FC } from 'react';
import { Checkbox, Form, Switch } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectConsumerScheduling: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Available for consumer scheduling:">
    {form.getFieldDecorator('hasConsumerScheduling', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Switch />)}
  </Form.Item>
);

export const CheckboxConsumerScheduling: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Make Visible in Consumer Directory">
    {form.getFieldDecorator('hasConsumerScheduling', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Checkbox />)}
  </Form.Item>
);

export const CheckboxConsumerAppointmentRequests: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Allow appointment requests">
    {form.getFieldDecorator('hasAppointmentRequests', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Checkbox />)}
  </Form.Item>
);

export const CheckboxConsumerSchedulingSlots: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item label="Make slots available for patient self-scheduling?">
    {form.getFieldDecorator('hasConsumerSchedulingSlots', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Checkbox />)}
  </Form.Item>
);
