interface ProfileTitles {
  [key: string]: string;
}

const titles: ProfileTitles = {
  practitioner: 'Provider Information',
  location: 'Location Information',
  device: 'Machine Information',
};

export function getMoreInfoTitle(profileType: string): string {
  return titles[profileType] || 'More Information';
}
