import * as _ from 'lodash';
import * as moment from 'moment';
import queryString, { ParsedQuery } from 'query-string';
import React, { FC, useContext, useState } from 'react';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import { Table, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { Appointment, ProfileInterface, useListAppointmentsQuery } from '../hooks/useListAppointmentsQuery';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { capitalize, FeatureFlag, FeatureGate, formatName } from '../../../helpers';
import { ColorTag, FiltersInterface, getFilterValues } from '../../referrals/components/ReferralsTable/ReferralsTable';
import { Spinner } from '../../../components';
import { useAppContext } from '../../../hooks';

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortFieldEnum {
  INSERTED_AT = 'INSERTED_AT',
  APPT_TIME = 'APPT_TIME',
}

interface TableFiltersInterface {
  patient?: string[];
  profile?: string[];
  id?: string[];
  status?: string[];
  comment?: string[];
  start?: string[];
  end?: string[];
  insertedAt?: string[];
}

const transformNoShowText = (str: string): string => {
  if (str === 'Noshow') return 'No Show';
  if (str === 'noshow') return 'No Show';
  return str;
};

const getTag = (
  tags: {
    key: string;
    value: string | null;
  }[],
  key: string
): string | null => {
  const matching = tags.filter((item, idx) => item.key === key);
  if (matching.length > 0) return matching[0].value;
  return null;
};

const SourceTag: FC<{ source: string | null }> = ({ source }): JSX.Element | null => {
  const className = 'px-2 py-0 rounded-full text-xs font-bold bg-grey-lighter text-grey-darkest';
  if (source === 'consumer') return <span className={className}>Consumer</span>;
  if (source === 'referral') return <span className={className}>Referral</span>;
  if (source === 'api') return <span className={className}>API</span>;
  return null;
};

export const AppointmentsTable: FC = (): JSX.Element => {
  const history = useHistory();
  const { currentOrganization, currentLocation } = useAppContext();
  const { endDate, filters, pageNumber, pageSize, patientId, startDate } = useContext(AppointmentsContext);
  const { sortField, sortOrder } = queryString.parse(history.location.search);

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: TableFiltersInterface,
    sorter: SorterResult<Appointment>
  ): void => {
    const queryParams: ParsedQuery = queryString.parse(history.location.search);
    const sortOrder = getSortOrder(sorter.order);

    setSortedField(sorter.columnKey as SortFieldEnum);
    setSortedOrder(sortOrder as SortOrderEnum);
    history.push(
      history.location.pathname +
        '?' +
        queryString.stringify({
          ...queryParams,
          sortOrder: sortOrder as SortOrderEnum,
          sortField: sorter.columnKey as SortFieldEnum,
          pageSize: pagination.pageSize || 10,
          pageNumber: pagination.current || 1,
          filters: JSON.stringify(filters),
        })
    );
  };

  const { data, error, loading } = useListAppointmentsQuery({
    variables: {
      endDate,
      locationId: currentLocation?.id,
      organizationId: currentOrganization?.id,
      pageNumber,
      pageSize,
      patientId,
      startDate,
      filters,
      sortOrder: sortOrder || SortOrderEnum.DESC,
      sortField: sortField || 'INSERTED_AT',
    },
  });

  const [sortedOrder, setSortedOrder] = useState(sortOrder || SortOrderEnum.DESC);
  const [sortedField, setSortedField] = useState(sortField || 'INSERTED_AT');
  const getSortOrder = (order: string | null): string | undefined => {
    if (order) {
      return order === 'ascend' ? SortOrderEnum.ASC : SortOrderEnum.DESC;
    }

    return undefined;
  };

  type SortOrderType = boolean | 'ascend' | 'descend' | undefined;
  type SortOrderInput = 'ASC' | 'DESC' | string | string[] | null | undefined;
  type SortColumnType = 'INSERTED_AT' | 'APPT_TIME' | string | string[] | null | undefined;
  type ColumnType = 'INSERTED_AT' | 'APPT_TIME';

  const getSorterValue = (sortOrder: SortOrderInput, sortColumn: SortColumnType, column: ColumnType): SortOrderType => {
    if (!sortOrder || !sortColumn) return false;
    if (sortColumn !== column) return false;
    if (sortOrder === 'ASC' || sortOrder === 'DESC') {
      if (sortOrder === 'ASC') {
        return 'ascend';
      } else {
        return 'descend';
      }
    }

    return false;
  };

  if (loading) return <Spinner />;

  if (error && error?.message) {
    return <div>{error.message.replace('GraphQL error:', '')}</div>;
  }

  if (!data || !data?.listAppointments.entries) return <div>Error</div>;
  const titleWithClassName = (title: string, columnName: string): JSX.Element => {
    return <span className={sortedField === columnName ? 'text-blue' : ''}>{title}</span>;
  };
  const columns = [
    {
      title: titleWithClassName('Created', 'INSERTED_AT'),
      key: 'INSERTED_AT',
      sorter: true,
      render: (appt: Appointment): JSX.Element => {
        return (
          <>
            <div className="main-text">
              <SourceTag source={getTag(appt.tags, 'source')} />

              <div>
                <Tooltip
                  title={moment
                    .default(moment.utc(appt.insertedAt))
                    .tz(moment.tz.guess())
                    .format('h:mmA z')}
                  placement="right"
                >
                  {moment
                    .default(moment.utc(appt.insertedAt))
                    .tz(moment.tz.guess())
                    .format('MM/DD/YYYY')}
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortOrder: getSorterValue(sortedOrder, sortedField, 'INSERTED_AT'),
    },
    {
      title: 'Patient Name',
      dataIndex: 'patient',
      key: 'PATIENT_NAME',
      render: (patient: { givenName1: string; familyName: string; identifier: string | undefined }): JSX.Element => {
        const showMRN = patient.identifier && patient.identifier.length < 36;

        return (
          <>
            <div className="main-text">{formatName(patient)}</div>
            {showMRN && (
              <FeatureGate feature={FeatureFlag.ShowMRN}>
                <div className="sub-text">MRN: {patient.identifier}</div>
              </FeatureGate>
            )}
          </>
        );
      },
    },
    {
      title: 'Appointment Type',
      key: 'SPECIALTIES',
      filters: _.sortBy(
        data.listAppointments.filters.specialties,
        (item: FiltersInterface) => item.text
      ).map((item: FiltersInterface) => ({ ...item, text: item.text })),
      filteredValue: getFilterValues(filters, 'SPECIALTIES'),
      render: (appt: Appointment): JSX.Element => {
        return (
          <>
            <div className="main-text">{appt.procedure?.specialty?.name}</div>
            <div className="sub-text">{appt.procedure?.display || appt.procedure?.name}</div>
          </>
        );
      },
    },
    {
      title: 'Profile',
      dataIndex: 'profile',
      key: 'PROFILES',
      filters: _.sortBy(
        data.listAppointments.filters.profiles,
        (item: FiltersInterface) => item.text
      ).map((item: FiltersInterface) => ({ ...item, text: item.text })),
      filteredValue: getFilterValues(filters, 'PROFILES'),
      render: (profile: ProfileInterface): JSX.Element => {
        return <div className="main-text">{profile.displayName}</div>;
      },
    },
    {
      title: titleWithClassName('Appt Time', 'APPT_TIME'),
      key: 'APPT_TIME',
      sorter: true,
      render: (appt: Appointment): JSX.Element => {
        return (
          <>
            <div className="main-text">{moment.default(appt.start).format('LL')}</div>
            <div className="sub-text">{moment.default(appt.start).format('h:mmA')}</div>
          </>
        );
      },
      sortOrder: getSorterValue(sortedOrder, sortedField, 'APPT_TIME'),
    },
    {
      title: 'Status',
      key: 'APPT_STATUSES',
      filters: _.sortBy(
        data.listAppointments.filters.appointmentStatuses,
        (item: FiltersInterface) => item.text
      ).map((item: FiltersInterface) => ({ ...item, text: capitalize(transformNoShowText(item.text)) })),
      filteredValue: getFilterValues(filters, 'APPT_STATUSES'),
      render: (appt: Appointment): JSX.Element => {
        return (
          <div style={{ textTransform: 'capitalize' }}>
            {!appt.rescheduledTo && <ColorTag status={appt.status} />}
            {appt.rescheduledTo && <ColorTag status="Rescheduled" />}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      sortDirections={['ascend', 'descend', 'ascend']}
      columns={columns}
      dataSource={data.listAppointments.entries}
      onRow={record => {
        return {
          onClick: event => {
            event.preventDefault();
            history.push(`/appointments/${record.id}`);
          },
        };
      }}
      rowKey={(record): string => record.id}
      onChange={handleTableChange}
      pagination={{
        pageSize: data.listAppointments.pageSize,
        position: 'bottom',
        showSizeChanger: true,
        defaultCurrent: 0,
        current: data.listAppointments.pageNumber,
        total: data.listAppointments.totalEntries,
        showTotal: total => {
          return `${total} items`;
        },
      }}
    />
  );
};
