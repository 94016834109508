import { gql } from '@apollo/client';

const SearchDiagnoses = gql`
  query($filter: String!) {
    searchDiagnoses(filter: $filter) {
      id
      code
      description
    }
  }
`;

export default SearchDiagnoses;
