import { useEffect } from 'react';
import { Organization } from './useGetConsumerSchedulingOrganizationQuery';

export const useHeadTags = ({ organization }: { organization: Organization | undefined }): void => {
  useEffect(() => {
    if (organization?.consumerSchedulingSettings?.htmlHeadTags) {
      const headTag = document.querySelector('head');
      const parsedDom = new DOMParser().parseFromString(
        organization.consumerSchedulingSettings.htmlHeadTags,
        'text/html'
      )?.documentElement?.firstElementChild?.children;

      if (parsedDom && parsedDom.length > 0) {
        Array.from(parsedDom).forEach(elem => {
          headTag?.appendChild(elem);
        });
      }
    }
  }, [organization]);
};
