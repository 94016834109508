import { message } from 'antd';
import { ApolloError } from '@apollo/client';
import * as _ from 'lodash';
import { FC } from 'react';

interface DisplayErrorInterface {
  error?: ApolloError;
}

const showError = (msg: string): void => {
  message.error(msg, 5);
};

const debounceShowError = _.debounce(showError, 500);

export const DisplayError: FC<DisplayErrorInterface> = ({ error }): null => {
  if (!error || !error.message) return null;

  if (error.networkError && error.graphQLErrors && error.graphQLErrors.length) {
    error.graphQLErrors.map(error =>
      error && error.message
        ? debounceShowError(error.message.replace('GraphQL error: ', ''))
        : debounceShowError('Unknown Error')
    );
  }

  debounceShowError(error.message.replace('GraphQL error: ', ''));
  return null;
};
