import { Switch } from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useState } from 'react';
import { Mutation } from '@apollo/client/react/components';

const updateUserOrganizationEmailNotificationMutation = gql`
  mutation($userId: ID!, $organizationId: ID!, $emailNotifications: Boolean!) {
    updateUserOrganizationEmailNotifications(
      userId: $userId
      organizationId: $organizationId
      emailNotifications: $emailNotifications
    ) {
      id
      emailNotifications
    }
  }
`;

interface UserOrganizationInterface {
  id: string;
  emailNotifications: boolean;
  userId: string;
  organizationId: string;
}

interface EmailNotificationCheckboxProps {
  userOrganization: UserOrganizationInterface;
}

interface Data {
  emailNotifications: boolean;
}

interface Variables {
  userId: string;
  organizationId: string;
  emailNotifications: boolean;
}

export const EmailNotificationCheckbox: FC<EmailNotificationCheckboxProps> = ({
  userOrganization,
}: EmailNotificationCheckboxProps): JSX.Element => {
  const [emailsEnabled, setEmailsEnabled] = useState(userOrganization.emailNotifications);

  return (
    <Mutation<Data, Variables>
      mutation={updateUserOrganizationEmailNotificationMutation}
      onCompleted={() => setEmailsEnabled(!emailsEnabled)}
    >
      {(updateUserOrganizationEmailNotificationMutation, { loading }) => (
        <div>
          <Switch
            checked={emailsEnabled}
            onChange={() => {
              updateUserOrganizationEmailNotificationMutation({
                variables: {
                  userId: userOrganization.userId,
                  organizationId: userOrganization.organizationId,
                  emailNotifications: !emailsEnabled,
                },
              });
            }}
          />
        </div>
      )}
    </Mutation>
  );
};
