import { Button, Card, Col, Form, message, Input, Row, Typography, Divider } from 'antd';
import React, { FC, FormEvent } from 'react';
import { getOrganization, useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { useUpdateOrganizationMutation } from '../../../hooks/mutations/useUpdateOrganization';
import { FormComponentProps } from 'antd/lib/form';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props extends FormComponentProps {
  organizationId: string;
}

const DomainSettingsCardComponent: FC<Props> = ({ form, organizationId }): JSX.Element => {
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields(async (err, values: { slug: string }) => {
      try {
        await updateOrganization({
          variables: {
            organizationId: organizationId,
            updateOrganizationInput: {
              slug: values.slug,
            },
          },
          refetchQueries: [{ query: getOrganization, variables: { organizationId: organizationId } }],
        });
        message.success('Your changes have been saved.');
      } catch (err) {
        message.error('An error occurred while saving your changes');
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card
        title="Domain Settings"
        extra={
          <BlockRestrictedUsers>
            <Button htmlType="submit" type="primary">
              Save Domain Settings
            </Button>
          </BlockRestrictedUsers>
        }
      >
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Custom Domain / CNAME (Disabled)">
              <Input placeholder="scheduling.mydomain.com" disabled />
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              By enabling a custom domain, you can use your own domain or subdomain to link to your consumer scheduling
              page. You must login to your domain registrar or hosting company and create a CNAME record pointing to{' '}
              <span style={{ fontFamily: 'monospace', fontSize: 11 }}>embed.blockitnow.com</span>
            </Typography.Text>
          </Col>
        </Row>
        <Divider />
        <Row align="top">
          <Col span={12}>
            <Form.Item label="URL Friendly Slug">
              {form.getFieldDecorator('slug', {
                rules: [
                  {
                    transform: (val: string) => {
                      if (val) {
                        const transformedVal = val
                          .toLowerCase()
                          .replace(/[^a-z0-9\s-]/g, '')
                          .replace(/(\s|-)+/g, '-');

                        form.setFieldsValue({ slug: transformedVal });
                        return transformedVal;
                      }
                    },
                  },
                ],
                initialValue: data?.getOrganization.slug,
              })(<Input placeholder="my-company-name" />)}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              If you won&#39;t be using a custom domain, you can set a URL-friendly identifier that will take the place
              of your id, <span style={{ fontFamily: 'monospace', fontSize: 11 }}>{organizationId}</span>, in the links
              below.
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export const DomainSettingsCard = Form.create<Props>({ name: 'Domain Settings' })(DomainSettingsCardComponent);
