import { Icon, Input, Row, Spin, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { gql } from '@apollo/client';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { FreeOrganizationLocationCount } from '../components/FreeOrganizationLocationCount';
import { NewLocationButton } from '../components/NewLocationButton';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { IsOrgAdminOrBlockitAdmin } from '../../../components/IsOrgAdminOrBlockitAdmin';
import { formatPhone } from '../../../helpers';
import { useAppContext } from '../../../hooks/useAppContext';
import { isAdmin, isBlockitAdmin } from '../../core/components/PrivateRoute';

const { Search } = Input;

const GetPaginatedLocationsQuery = gql`
  query GetPaginatedLocations($organizationId: ID!, $pageSize: Int, $pageNumber: Int, $searchFilter: String) {
    GetPaginatedLocations(
      organizationId: $organizationId
      pageSize: $pageSize
      pageNumber: $pageNumber
      searchFilter: $searchFilter
    ) @connection(key: "PaginatedLocations", filter: ["organizationId", "pageSize", "pageNumber", "searchFilter"]) {
      totalEntries
      pageNumber
      pageSize
      totalPages
      entries {
        id
        name
        address1
        city
        state
        postalCode
        latitude
        longitude
        phone
      }
    }
  }
`;

interface LocationData {
  id: string;
  name: string;
  address1: string;
  city: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  phone: string;
}

interface LocationActionsInterface {
  row: LocationData;
}

const LocationActions: React.FC<LocationActionsInterface> = ({ row }): JSX.Element => {
  const { currentOrganization } = useAppContext();

  return (
    <Link to={`/organizations/${currentOrganization?.id}/locations/${row.id}/edit`}>
      <Icon type="edit" />
    </Link>
  );
};

const columns = (canAccessActions: boolean): ColumnProps<LocationData>[] => {
  const cols = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address1',
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (row: LocationData) => (
        <span>{row.phone && row.phone.length > 0 ? formatPhone(row.phone) : 'No Phone Number'}</span>
      ),
    },
    {
      title: 'City',
      key: 'city',
      dataIndex: 'city',
    },
    {
      title: 'State',
      key: 'state',
      dataIndex: 'state',
    },
    {
      title: 'Zip',
      key: 'zip',
      dataIndex: 'postalCode',
    },
  ];

  if (canAccessActions) {
    cols.push({
      title: 'Actions',
      key: 'actions',
      render: (record: LocationData): JSX.Element => <LocationActions row={{ ...record }} />,
    });
  }

  return cols;
};

interface LocationQueryData {
  GetPaginatedLocations: {
    entries: LocationData[];
    pageSize: number;
    pageNumber: number;
    totalEntries: number;
    totalPages: number;
  };
}

interface LocationQueryVariables {
  organizationId: string | undefined;
  searchFilter: string;
  pageNumber: number;
  pageSize: number;
}

const LocationManagementPage = (): JSX.Element => {
  const { currentOrganization } = useAppContext();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const { data, error, loading } = useQuery<LocationQueryData, LocationQueryVariables>(GetPaginatedLocationsQuery, {
    variables: { organizationId: currentOrganization?.id, searchFilter, pageNumber: currentPage, pageSize },
    fetchPolicy: 'no-cache',
  });

  if (!currentOrganization) return <Spin />;
  if (error) {
    return <div>Error</div>;
  }

  const locations = data?.GetPaginatedLocations?.entries || [];

  return (
    <>
      <IsOrgAdminOrBlockitAdmin>
        <div>
          <FreeOrganizationLocationCount
            organizationId={currentOrganization.id}
            isFree={currentOrganization.isFree}
            freePlanConfiguration={currentOrganization.freePlanConfiguration}
          />
          <Row type="flex" justify="end" style={{ marginBottom: '1em' }}>
            <Search
              placeholder="Filter by name"
              onChange={e => {
                e.persist();

                const debounceFunc = debounce(() => {
                  setSearchFilter(e.target.value);
                }, 500);

                debounceFunc();
              }}
              defaultValue={searchFilter}
              style={{ width: 200, paddingRight: 20 }}
            />
            <NewLocationButton
              organizationId={currentOrganization.id}
              isFree={currentOrganization.isFree}
              freePlanConfiguration={currentOrganization.freePlanConfiguration}
            />
          </Row>
        </div>
      </IsOrgAdminOrBlockitAdmin>
      <Table<LocationData>
        loading={loading}
        dataSource={locations}
        columns={columns((currentOrganization && isAdmin(currentOrganization)) || isBlockitAdmin(currentOrganization))}
        rowKey={record => record.id}
        onChange={pagination => {
          setCurrentPage(pagination.current || 1);
          setPageSize(pagination.pageSize || 10);
        }}
        pagination={{
          pageSize: data?.GetPaginatedLocations?.pageSize || 10,
          position: 'bottom',
          showSizeChanger: true,
          defaultCurrent: 1,
          current: data?.GetPaginatedLocations?.pageNumber || 1,
          total: data?.GetPaginatedLocations?.totalEntries,
        }}
      />
    </>
  );
};

export default LocationManagementPage;
