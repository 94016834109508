import React, { FC, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppNav } from './AppNav';
import { UserMenu } from './UserMenu';
import { AppContext } from '../../contexts/AppContext';
import { isAdminOrBlockitAdmin } from '../PrivateRoute';

const AppHeaderStyles = styled.div`
  box-shadow: 0 1px 2px rgba(125, 167, 212, 1);
  font-family: 'Source Sans Pro', sans-serif;

  .app-header {
    display: flex;
    align-items: flex-end;
    background-color: ${({ theme }) => theme.primaryColor};
    box-sizing: border-box;
    height: 73px;
    padding: 0 20px;
    white-space: nowrap;

    .logo {
      padding: 20px 0;
      text-align: left;

      img {
        width: 149px;
      }
    }
  }
`;

export const AppHeader: FC<{}> = (): JSX.Element => {
  const { currentOrganization, mode, switchMode } = useContext(AppContext);

  useEffect(() => {
    if (mode === 'admin' && !isAdminOrBlockitAdmin(currentOrganization)) {
      switchMode('referral');
    }
  }, [currentOrganization, mode, switchMode]);

  return (
    <AppHeaderStyles>
      <UserMenu />
      <div className="app-header">
        <div className="logo">
          <Link to="/">
            <img src="/images/logo.png" alt="Blockit" />
          </Link>
        </div>
        <AppNav />
      </div>
    </AppHeaderStyles>
  );
};
