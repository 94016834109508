import { Icon } from 'antd';
import { Form, Formik } from 'formik';
import { gql } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Fieldset, MultiSelect, Spinner } from '../../../components';
import { removeEmptyObjFields } from '../../../helpers';
import { AppContextConsumer } from '../../core/contexts/AppContext';

const getOrganizationQuery = gql`
  query($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      locations {
        id
        name
        address1
        city
        state
        postalCode
        latitude
        longitude
      }
    }
  }
`;

const createProviderMutation = gql`
  mutation CreateProvider($CreateProviderInput: CreateProviderInput!) {
    CreateProvider(CreateProviderInput: $CreateProviderInput) {
      id
    }
  }
`;

const ProviderFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  key: Yup.string(),
  npi: Yup.string()
    .matches(/^[0-9]+$/, 'Must be a number')
    .required('Required'),
  location: Yup.object()
    .required('Required')
    .nullable()
    .shape({
      label: Yup.string().required('Required'),
      value: Yup.string().required('Required'),
    }),
});

const CreateProviderComponent = ({ createProviderMutation, history }) => (
  <AppContextConsumer>
    {({ currentOrganization }) => (
      <Query query={getOrganizationQuery} variables={{ organizationId: currentOrganization.id }}>
        {({ loading, error, data }) => {
          if (loading) return <Spinner />;
          if (error) return <div>Error</div>;

          const organizationId = currentOrganization.id;

          const {
            getOrganization: { locations },
          } = data;

          return (
            <div>
              <Formik
                initialValues={{
                  name: '',
                  npi: '',
                  location: null,
                }}
                validationSchema={ProviderFormSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  try {
                    const valuesCopy = _.cloneDeep(values);
                    const cleanedUpValues = removeEmptyObjFields(valuesCopy);
                    cleanedUpValues.locationId = valuesCopy.location.value;
                    delete cleanedUpValues.location;

                    await createProviderMutation({
                      variables: {
                        CreateProviderInput: { organizationId, isActive: true, ...cleanedUpValues },
                      },
                    });

                    history.push(`/organizations/${organizationId}/providers`);
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ values, errors, status, touched, isSubmitting, setFieldValue, setFieldTouched, handleSubmit }) => (
                  <div className="flex flex-wrap">
                    <div className="w-1/2 card">
                      <div className="card-header bg-grey-darkest text-grey-lightest font-bold">
                        <span className="px-4 py-2 inline-block">Create Provider</span>
                      </div>
                      <div className="p-4">
                        <Form className="w-full" onSubmit={handleSubmit}>
                          <Fieldset
                            errors={errors}
                            touched={touched}
                            type="text"
                            name="name"
                            label="Name"
                            placeholder="Name"
                            className="w-full input mt-2 border-1"
                          />
                          <Fieldset
                            errors={errors}
                            touched={touched}
                            type="text"
                            name="key"
                            label="Key"
                            placeholder="Key"
                            className="w-full input mt-2 border-1"
                          />
                          <Fieldset
                            errors={errors}
                            touched={touched}
                            type="text"
                            name="npi"
                            label="Npi Number"
                            placeholder="Npi Number"
                            className="w-full input mt-2 border-1"
                          />
                          <MultiSelect
                            errors={errors}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched}
                            options={locations.map(l => ({
                              value: l.id,
                              label: l.name,
                            }))}
                            value={values.location}
                            name="location"
                            label="Location"
                            placeholder="Location"
                            className="w-full"
                            isMulti={false}
                          />
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="w-full flex-1 mt-4 btn btn-blue px-2 py-2"
                          >
                            Save {isSubmitting ? <Icon type="loading" /> : null}
                          </button>
                        </Form>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          );
        }}
      </Query>
    )}
  </AppContextConsumer>
);

export default _.flowRight(
  withRouter,
  graphql(createProviderMutation, { name: 'createProviderMutation' })
)(CreateProviderComponent);
