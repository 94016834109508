import { DateTime } from 'luxon';

/**
 * Formats an appointment into a string time range for display.
 */
const formatApptRange = (appt: { start: string; end: string } | null): string | null => {
  if (appt) {
    return DateTime.fromISO(appt.start).toFormat('hh:mm') + ' - ' + DateTime.fromISO(appt.end).toFormat('hh:mma');
  } else {
    return null;
  }
};

export { formatApptRange };
