import * as Sentry from '@sentry/browser';
import { Button, Col, Drawer, Form, Input, message, Row, Select } from 'antd';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import { Spinner } from '../../../../components';
import {
  stateNames,
  transformRawPhoneNumber,
  validateAntPhoneNumber,
  validateAntPostalCode,
} from '../../../../helpers';
import { SelectPatientBirthDate } from '../../../consumer/components/SelectPatientBirthDate';
import AntDesignStyleOverrides from '../../../core/components/App/AntDesignStyleOverrides';

const { Option } = Select;

const CreatePatientMutation = gql`
  mutation CreatePatientMutation($organizationId: ID!, $createPatientInput: CreatePatientInput!) {
    createPatient(organizationId: $organizationId, createPatientInput: $createPatientInput) {
      id
      address1
      address2
      city
      dob
      familyName
      sex
      givenName1
      givenName2
      givenName3
      phone
      state
      postalCode
      email
    }
  }
`;

const FIELD_NAMES = {
  PATIENT_FIRST_NAME: 'patientFirstName',
  PATIENT_LAST_NAME: 'patientLastName',
  PATIENT_GENDER: 'patientGender',
  PATIENT_BIRTHDAY: 'birthDate',
  PATIENT_ADDRESS: 'patientAddress',
  PATIENT_ADDRESS_2: 'patientAddress2',
  PATIENT_CITY: 'patientCity',
  PATIENT_STATE: 'patientState',
  PATIENT_POSTAL_CODE: 'patientPostalCode',
  PATIENT_PHONE_NUMBER: 'patientPhoneNumber',
  PATIENT_EMAIL_ADDRESS: 'patientEmailAddress',
  PATIENT_INSURANCE_CARRIER: 'patientInsuranceCarrier',
  PATIENT_INSURANCE_PLAN: 'patientInsurancePlan',
  PATIENT_INSURANCE_GROUP_NUMBER: 'patientInsuranceGroupNumber',
  PATIENT_INSURANCE_POLICY_NUMBER: 'patientInsurancePolicyNumber',
};

const PayorsAndPlansQuery = gql`
  query {
    getAllPayorsAndPlans {
      id
      name
      payorPlans {
        id
        name
      }
    }
  }
`;

class AddNewPatientFormComponent extends React.Component {
  prepareFormData = values => {
    return {
      address1: values[FIELD_NAMES.PATIENT_ADDRESS],
      address2: values[FIELD_NAMES.PATIENT_ADDRESS_2] || '',
      city: values[FIELD_NAMES.PATIENT_CITY],
      dob: DateTime.utc(
        values[FIELD_NAMES.PATIENT_BIRTHDAY].year,
        values[FIELD_NAMES.PATIENT_BIRTHDAY].month,
        values[FIELD_NAMES.PATIENT_BIRTHDAY].day
      ).toISODate(),
      familyName: values[FIELD_NAMES.PATIENT_LAST_NAME],
      sex: values[FIELD_NAMES.PATIENT_GENDER],
      givenName1: values[FIELD_NAMES.PATIENT_FIRST_NAME],
      givenName2: '',
      givenName3: '',
      phone: transformRawPhoneNumber(values[FIELD_NAMES.PATIENT_PHONE_NUMBER]),
      state: values[FIELD_NAMES.PATIENT_STATE],
      postalCode: values[FIELD_NAMES.PATIENT_POSTAL_CODE],
      email: values[FIELD_NAMES.PATIENT_EMAIL_ADDRESS] || '',
      insuranceCarrier: values[FIELD_NAMES.PATIENT_INSURANCE_CARRIER] || '',
      insurancePlan: values[FIELD_NAMES.PATIENT_INSURANCE_PLAN] || '',
      insuranceGroupNumber: values[FIELD_NAMES.PATIENT_INSURANCE_GROUP_NUMBER] || '',
      insurancePolicyNumber: values[FIELD_NAMES.PATIENT_INSURANCE_POLICY_NUMBER] || '',
    };
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { validateFieldsAndScroll } = this.props.form;

    const fields = Object.keys(FIELD_NAMES).map(value => FIELD_NAMES[value]);
    validateFieldsAndScroll(fields, {}, async (errors, values) => {
      if (errors) return;

      const hideLoadingMessage = message.loading('Saving Patient', 0);
      const formattedPayload = this.prepareFormData(values);

      try {
        const {
          data: { createPatient: patient },
        } = await this.props.client.mutate({
          mutation: CreatePatientMutation,
          variables: {
            organizationId: this.props.organization.id,
            createPatientInput: formattedPayload,
          },
        });

        this.props.handleSetNewPatient(patient);
        hideLoadingMessage();
        message.success('Patient Saved');
        this.props.handleHideNewPatientForm();
      } catch (error) {
        hideLoadingMessage();
        message.error('There was an error while creating the patient. Please try again.');
        Sentry.captureException(error);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue, resetFields } = this.props.form;
    const { show, handleHideNewPatientForm } = this.props;

    const required = {};

    if (this.props.organization?.consumerSchedulingSettings?.formFieldSettings) {
      this.props.organization.consumerSchedulingSettings.formFieldSettings.forEach(curr => {
        required[curr.field] = curr;
      });
    }

    return (
      <div>
        <Drawer title="Create a New Patient" width={720} onClose={handleHideNewPatientForm} visible={show}>
          <AntDesignStyleOverrides>
            <Query query={PayorsAndPlansQuery}>
              {({ data, error, loading }) => {
                if (loading) return <Spinner />;
                if (error) return <div>Error</div>;

                const { getAllPayorsAndPlans: payors } = data;

                return (
                  <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="First Name">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_FIRST_NAME, {
                            rules: [{ required: true, message: 'required' }],
                          })(<Input placeholder="First Name" />)}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Last Name">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_LAST_NAME, {
                            rules: [{ required: true, message: 'required' }],
                          })(<Input placeholder="Last Name" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="Sex">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_GENDER, {
                            validateTrigger: 'onChange',
                            rules: [{ required: true, message: 'required' }],
                          })(
                            <Select placeholder="Select Sex" showAction={['focus', 'click']}>
                              <Option value="MALE">Male</Option>
                              <Option value="FEMALE">Female</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <SelectPatientBirthDate form={this.props.form} />
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="Street Address">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_ADDRESS, {
                            rules: [{ required: required?.address1?.required, message: 'required' }],
                          })(<Input placeholder="Street Address" />)}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Street Address Line 2">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_ADDRESS_2, {
                            rules: [{ required: required?.address2?.required, message: 'required' }],
                          })(<Input placeholder="Street Address Line 2" />)}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="City">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_CITY, {
                            rules: [{ required: required?.city?.required, message: 'required' }],
                          })(<Input placeholder="City" />)}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="State">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_STATE, {
                            rules: [{ required: required?.state?.required, message: 'required' }],
                          })(
                            <Select
                              showSearch
                              showAction={['focus', 'click']}
                              placeholder="Select State"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().startsWith(input.toLowerCase())
                              }
                            >
                              {stateNames.map(stateName => (
                                <Option value={stateName} key={stateName}>
                                  {stateName}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="Postal Code">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_POSTAL_CODE, {
                            rules: [
                              {
                                type: 'string',
                                validator: validateAntPostalCode,
                              },
                              {
                                required: required?.postalCode?.required,
                                message: 'required',
                              },
                            ],
                          })(<Input placeholder="12345" />)}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="Phone Number">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_PHONE_NUMBER, {
                            rules: [
                              {
                                required: required?.phone?.required,
                                message: 'required',
                              },
                              {
                                transform: transformRawPhoneNumber,
                                validator: validateAntPhoneNumber,
                                type: 'number',
                              },
                            ],
                          })(<Input placeholder="(123) 123-1234" />)}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Email Address">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_EMAIL_ADDRESS, {
                            rules: [
                              { required: required?.email?.required, message: 'required' },
                              {
                                type: 'email',
                                message: 'must be a valid email',
                              },
                            ],
                          })(<Input placeholder="Email Address" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="Insurance Carrier">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_INSURANCE_CARRIER, {
                            rules: [{ required: required?.payorPlanId?.required, message: 'required' }],
                          })(
                            <Select
                              showSearch
                              placeholder="Select Insurance Carrier"
                              onChange={() => resetFields([FIELD_NAMES.PATIENT_INSURANCE_PLAN])}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {payors.map(payor => (
                                <Option value={payor.id} key={payor.name}>
                                  {payor.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Insurance Plan">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_INSURANCE_PLAN, {
                            rules: [{ required: required?.payorPlanId?.required, message: 'required' }],
                          })(
                            <Select
                              disabled={!getFieldValue(FIELD_NAMES.PATIENT_INSURANCE_CARRIER)}
                              showSearch
                              placeholder="Select Insurance Plan"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {getFieldValue(FIELD_NAMES.PATIENT_INSURANCE_CARRIER) &&
                                payors
                                  .filter(p => p.id === getFieldValue(FIELD_NAMES.PATIENT_INSURANCE_CARRIER))[0]
                                  .payorPlans.map(plan => (
                                    <Option value={plan.id} key={plan.name}>
                                      {plan.name}
                                    </Option>
                                  ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="Insurance Group Number">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_INSURANCE_GROUP_NUMBER, {
                            rules: [{ required: required?.insuranceGroupNumber?.required, message: 'required' }],
                          })(<Input placeholder="Insurance Group Number" />)}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Insurance Policy Number">
                          {getFieldDecorator(FIELD_NAMES.PATIENT_INSURANCE_POLICY_NUMBER, {
                            rules: [{ required: required?.insurancePolicyNumber?.required, message: 'required' }],
                          })(<Input placeholder="Insurance Policy Number" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} type="flex" justify="start">
                      <Col>
                        <Button htmlType="button" onClick={handleHideNewPatientForm}>
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button htmlType="submit" type="primary">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Query>
          </AntDesignStyleOverrides>
        </Drawer>
      </div>
    );
  }
}

export default _.flowRight(withApollo, Form.create({ name: 'Add Patient' }))(AddNewPatientFormComponent);
