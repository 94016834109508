import { Col, Icon, Row, Typography } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { formatPhone } from '../../../helpers';
import { PublicProfile } from '../containers/DirectoryContext';

const { Text } = Typography;

interface Props {
  profile: PublicProfile;
}

const Styles = styled.div`
  .profile-info {
    margin-bottom: 4px;
    margin-left: 16px;
    max-width: 400px;
    width: 100%;
    line-height: 1.1;
    font-size: 12px;

    ul {
      margin-bottom: 0;
    }
  }

  .profile-name {
    font-size: 16px;
    font-weight: bold;
  }

  .specialty-name {
    font-style: italic;
    font-size: 12px;
    line-height: 1.1;
  }

  .profile-organization-name {
    font-style: italic;
    font-size: 12px;
    line-height: 1.1;
    margin-bottom: 1em;
  }

  .location-name {
    font-weight: bold;
  }
`;

export const DirectoryProfileContactInfo: FC<Props> = ({ profile }): JSX.Element => (
  <Styles>
    <div className="profile-info">
      <ul>
        <li className="profile-name">{profile.displayName}</li>
        {profile.specialty ? <li className="specialty-name">{profile.specialty}</li> : null}
        <li className="profile-organization-name">{profile.organization.name}</li>
      </ul>
    </div>
    <Row className="profile-info">
      <Col>
        <ul>
          <li className="location-name">{profile.location.name}</li>
          <li>{profile.location.address1}</li>
          {profile.location.address2 ? <li>{profile.location.address2}</li> : null}
          <li>{`${profile.location.city}, ${profile.location.state} ${profile.location.postalCode}`}</li>
        </ul>
      </Col>
    </Row>
    {profile.phone ? (
      <Row className="profile-info">
        <Col span={2}>
          <Icon type="phone" />
        </Col>
        <Col span={22}>
          <Text>{formatPhone(profile.phone)}</Text>
        </Col>
      </Row>
    ) : null}
    {profile.email ? (
      <Row className="profile-info">
        <Col>
          <Text>{profile.email}</Text>
        </Col>
      </Row>
    ) : null}
  </Styles>
);
