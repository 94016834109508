import React, { FC } from 'react';
import { Switch, Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  initialValue?: boolean;
}

export const SelectReferralScheduling: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="Make available for referral scheduling?"
    extra="Enables and disables profile for referral scheduling."
  >
    {form.getFieldDecorator('hasReferralScheduling', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Switch />)}
  </Form.Item>
);

export const SelectReferralSchedulingSlots: FC<Props> = ({ form, initialValue }): JSX.Element => (
  <Form.Item
    label="Include slots in referral scheduling results?"
    extra="Enables and disables profile's slots in search result."
  >
    {form.getFieldDecorator('hasReferralSchedulingSlots', {
      initialValue: initialValue,
      valuePropName: 'checked',
      rules: [{ required: false }],
    })(<Switch />)}
  </Form.Item>
);
