import { gql } from '@apollo/client';
import { useMutation, MutationHookOptions, MutationTuple } from '@apollo/client';
import { Location, TimeZone } from '../index';

const createLocationMutation = gql`
  mutation CreateLocationMutation($createLocationInput: CreateLocationInput!) {
    createLocation(createLocationInput: $createLocationInput) {
      id
      name
      address1
      address2
      city
      state
      postalCode
      country
    }
  }
`;

export interface CreateLocationInput {
  organizationId: string;
  name: string;
  displayName?: string;
  phone?: string;
  email?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  latitude?: number;
  longitude?: number;
  timeZone: TimeZone;
  hasAppointmentRequests?: boolean;
}

export interface CreateLocationMutationData {
  createLocation: Location;
}

interface Variables {
  createLocationInput: CreateLocationInput;
}

type Options = MutationHookOptions<CreateLocationMutationData, Variables>;

type Result = MutationTuple<CreateLocationMutationData, Variables>;

export const useCreateLocationMutation = (options: Options): Result => {
  return useMutation<CreateLocationMutationData, Variables>(createLocationMutation, options);
};
