import React from 'react';
import { PrivateRoute } from '../core';
import { OrgSettingsPage } from './containers/OrgSettingsPage';

export const routes = [
  <PrivateRoute
    exact
    path="/organizations/:id/organization-settings"
    key="route--organization-settings"
    component={OrgSettingsPage}
  />,
];

export interface FeatureFlag {
  id: string;
  name: string;
}

export interface Region {
  id: string;
  name: string;
}
